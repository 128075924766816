/**
 * Copyright 2022 Design Barn Inc.
 */

import { cloneDeep } from 'lodash-es';
import type React from 'react';
import { useEffect, useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { setupExistingProject } from './shared';

import { WORKFLOW_DASHBOARD } from '~/config';
// eslint-disable-next-line no-restricted-imports
import { uploadDotLottie } from '~/features/upload/components/helper';
// eslint-disable-next-line no-restricted-imports
import { executeUpload, exportDotCreatorToJSON } from '~/features/upload/components/middleware';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { stateHistory, toolkit } from '~/lib/toolkit';
import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';

interface CurrentData {
  fileKey: string;
  fileName: string;
  fileVersionId: string;
  folderId: string;
  projectId: string;
  workspaceId: string;
}
export const FetchExistingFile: React.FC = () => {
  const [currentFileId, token] = useCreatorStore((state) => [state.project.fetchExistingFile.fileId, state.user.token]);
  const { getFileById } = useProjectAPI(token);
  const handleError = useErrorHandler();

  const importToCanvasFromCreator = useCallback(({ current, json }: { current: CurrentData; json: unknown }) => {
    stateHistory.offTheRecord(() => {
      const setInfo = useCreatorStore.getState().project.setInfo;

      const toolkitJSON = json?.state?.toolkit;

      if (toolkitJSON) {
        // Hydrate all toolkit states (all scenes)
        toolkit.fromJSON(toolkitJSON);

        emitter.emit(EmitterEvent.TOOLKIT_STATE_HYDRATED);

        const { fileKey, fileName, fileVersionId, folderId, projectId, workspaceId } = current;

        // Update existing directory for update
        setupExistingProject({
          workspaceId,
          projectId,
          folderId,
        });

        // Update existing api key for update
        setInfo({
          name: fileName,
          fileSubscribeKey: fileKey,
          fileVersionId,
        });

        useCreatorStore.getState().ui.resetSelection();

        // Reset layer map
        useCreatorStore.getState().ui.resetLayerUI();
        useCreatorStore.getState().timeline.clearTabState();
        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);

        // // End of fetch
        // setFetchExistingFile({ status: false, fileId: null });
      }
    });
  }, []);

  const parsingExistingFile = useCallback(
    async (fileId: string) => {
      const setLoader = useCreatorStore.getState().ui.setLoader;
      const setFetchExistingFile = useCreatorStore.getState().project.setFetchExistingFile;

      try {
        const result = await getFileById(fileId);

        if (result) {
          const creatorFileUrl = result.fileObject.url;
          const currentFileKey = result.fileObject.key;
          const currentFileVersionId = result.fileObject.versionId;

          const currentFilename = result.name;
          const currentWorkspaceId = result.project.workspaceId;
          const currentProjectId = result.projectId;
          const currentFolderId = result.folderId;

          // From Workflow File API
          // dotLottie/lottie/json: 'application/zip'
          // dotCreator: 'application/json'
          const isDotCreator = Boolean(result.fileObject.attributes?.contentType === 'application/json');

          if (creatorFileUrl && currentWorkspaceId) {
            const creatorFile = await fetch(creatorFileUrl);

            let creatorFileJSON = null;
            let tempData = null;

            let isDotLottie = false;

            if (isDotCreator) {
              // dotCreator files opened in Workflow
              creatorFileJSON = await creatorFile.json();

              tempData = await exportDotCreatorToJSON(cloneDeep(creatorFileJSON?.state?.toolkit));
            } else if (creatorFile.body) {
              // lottie/json files opened in Workflow

              // Convert Readablestream to Uint8Array
              const reader = creatorFile.body.getReader();
              const readerResult = await reader.read();
              const dotLottieStream = new Uint8Array(readerResult.value.buffer);

              tempData = cloneDeep(dotLottieStream);
              isDotLottie = true;
            }

            if (!tempData) return;

            // End of fetch
            setFetchExistingFile({ status: false, fileId: null });

            if (creatorFileJSON) {
              // tempData: Lottie JSON
              // creatorFileJSON: JSON (state.toolkit)
              await executeUpload({
                revertLabel: 'Back to workspace',
                data: tempData,
                execute: () => {
                  importToCanvasFromCreator({
                    json: creatorFileJSON as unknown,
                    current: {
                      workspaceId: currentWorkspaceId,
                      fileKey: currentFileKey,
                      fileVersionId: currentFileVersionId,
                      fileName: currentFilename,
                      projectId: currentProjectId,
                      folderId: currentFolderId,
                    },
                  });
                },
                revert: () => {
                  window.location.href = `${WORKFLOW_DASHBOARD}`;
                },
              });
            } else if (isDotLottie) {
              // tempData: Uint8Array
              await executeUpload({
                revertLabel: 'Back to workspace',
                type: 'dotLottie',
                data: tempData,
                execute: async () => uploadDotLottie(tempData, currentFilename),
                revert: () => {
                  window.location.href = `${WORKFLOW_DASHBOARD}`;
                },
              });
            }
          }
        }
      } catch (error) {
        handleError(error);
      }

      setLoader({
        isLoading: false,
      });
    },
    [getFileById, handleError, importToCanvasFromCreator],
  );

  useEffect(() => {
    if (currentFileId) {
      parsingExistingFile(currentFileId);
    }
  }, [currentFileId, parsingExistingFile]);

  return null;
};
