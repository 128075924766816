/**
 * Copyright 2021 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useRef, useState, useCallback } from 'react';

import { Search, Cross } from '~/assets/icons';

interface SearchBarProps {
  isError?: boolean;
  onSubmit: (text: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ isError = false, onSubmit }) => {
  const [searchText, setSearchText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const performSearch = useCallback((): void => {
    setIsFocus(false);
    inputRef.current?.blur();
    onSubmit(searchText);
  }, [searchText, onSubmit]);

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (evt.key === 'Enter') {
        performSearch();
      }
    },
    [performSearch],
  );

  const handleOnChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(evt.target.value);
  }, []);

  const handleOnMouseDown = useCallback((evt) => {
    // To handle onBlur and onClick ordering issue, mousedown will trigger onblur by default, and hence we need to disable it
    evt.preventDefault();
  }, []);

  const clearText = useCallback(() => {
    setSearchText('');
  }, []);

  return (
    <div className="relative ml-3 mr-4 dark:text-white" data-testid="search-container">
      <Search
        className="absolute top-[1px] mx-2 my-1 h-5 w-5 cursor-pointer stroke-current dark:text-white"
        onClick={() => performSearch()}
        data-testid="search-icon"
      />
      <input
        className={clsx(
          'w-full rounded-md border bg-gray-600 py-[2px] pl-8 pr-7 text-xs focus:border-lf-teal focus:ring-0',
          {
            'border-red-500': isError,
            'border-transparent': !isError,
          },
        )}
        type="text"
        placeholder="Search"
        ref={inputRef}
        value={searchText}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        data-testid="search-input"
      ></input>
      {searchText && isFocus && (
        <Cross
          className="absolute right-0 top-[1px] my-1 mr-1 h-5 w-6 cursor-pointer fill-current dark:text-white"
          onMouseDown={handleOnMouseDown}
          onClick={() => clearText()}
          data-testid="cross-icon"
        ></Cross>
      )}
    </div>
  );
};
