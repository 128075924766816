/**
 * Copyright 2022 Design Barn Inc.
 */

import type { LayerJSON } from '@lottiefiles/toolkit-js';
import React from 'react';

import { TimelineItem } from './TimelineItem';

interface AnimationTimelinePanelProps {
  layers: LayerJSON[];
}

export const TimelineContent: React.FC<AnimationTimelinePanelProps> = React.memo(({ layers }) => {
  return (
    <>
      {layers.length > 0 ? (
        layers.map((layer) => {
          const layerId = layer.id;

          return (
            (layer.type === 'SHAPE' ||
              layer.type === 'PRECOMPOSITION' ||
              layer.type === 'SOLID' ||
              layer.type === 'IMAGE' ||
              layer.type === 'GROUP') && <TimelineItem key={layerId} layer={layer} />
          );
        })
      ) : (
        <div className="flex h-full select-none items-center justify-center text-[12px] font-normal leading-[15px]">
          <span className="px-[30px] text-center text-gray-300">
            A new timeline will appear here for every object you insert and asset you upload.
          </span>
        </div>
      )}
    </>
  );
});
