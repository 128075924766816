/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { StartSaveFileUpload } from '../../ProjectListener';

import { SaveToDraft } from '.';
import { useCreatorStore } from '~/store';
import { SavingState } from '~/store/projectSlice';

export const ExistingFile: React.FC = () => {
  const [isDraft, savingState, madeFirstChange, token, saveFromPopup] = useCreatorStore(
    (state) => [
      state.project.info.isDraft,
      state.project.info.savingState,
      state.project.madeFirstChange,
      state.user.token,
      state.project.info.saveFromPopup,
    ],
    shallow,
  );

  const saveDraftCount = useCreatorStore.getState().project.info.saveDraftCount || 0;

  const alreadySavedToDraft = (isDraft && Number(saveDraftCount) > 0) || !isDraft;

  const ableToSave = Boolean(alreadySavedToDraft && token);

  if (savingState === SavingState.SAVING && ableToSave) {
    // Perform saving
    return (
      <>
        <StartSaveFileUpload />
      </>
    );
  } else if (!saveFromPopup && madeFirstChange && token && !isDraft && Number(saveDraftCount) === 0) {
    // Save draft for one time
    return <SaveToDraft createNew={true} />;
  }

  return null;
};
