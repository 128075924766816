/**
 * Copyright 2021 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { PropertyRow } from './ui/PropertyRow';

import { Duration as DurationIcon } from '~/assets/icons';
import { defaultStyle, NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { Select } from '~/components/Elements/Select';
import type { Option } from '~/components/Elements/Select';
import { TooltipWrapper } from '~/components/Elements/Tooltip/TooltipWrapper';
import { MAX_TIME_SEC, MIN_TIME_SEC } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setDuration, setPrecompDuration, setPrecompFPS, setSceneFPS, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const defaultFrameOption = {
  label: '24 fps',
  value: 24,
};

const FrameRateOptions: Option[] = [
  defaultFrameOption,
  {
    label: '30 fps',
    value: 30,
  },
  {
    label: '60 fps',
    value: 60,
  },
];

const Duration: React.FC = () => {
  const [
    sceneIndex,
    currentFrameRate,
    duration,
    currentPrecompJson,
    precompFrameRate,
    precompDuration,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.sceneIndex,
      state.toolkit.json?.timeline.properties.fr as number,
      state.toolkit.json?.timeline.duration as number,
      state.toolkit.selectedPrecompositionJson,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
      state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
    ],
    shallow,
  );
  const frameRateOptionsRef = useRef(FrameRateOptions as Option[]);

  const fpsIndex = FrameRateOptions.findIndex((frameRateOpt) => frameRateOpt.value === currentFrameRate);
  const currentFPSIndex = fpsIndex > -1 ? fpsIndex : 0;
  const [frameRate, setFrameRate] = useState(frameRateOptionsRef.current[currentFPSIndex]);

  const [frameRateDisplay, setFrameRateDisplay] = useState(currentPrecompJson ? precompFrameRate : frameRate);
  const [durationDisplay, setDurationDisplay] = useState(currentPrecompJson ? precompDuration : duration);
  const [sceneId, setSceneID] = useState('main');

  const handleOnChangeDuration = useCallback(
    (result: NumberResult) => {
      stateHistory.beginAction();
      const currentScene = toolkit.scenes[sceneIndex] as Scene;

      if (currentPrecompJson) {
        setPrecompDuration(currentScene, currentPrecompJson.id, result.trueValue);
      } else {
        setDuration(currentScene, result.trueValue);
      }

      setDurationDisplay(result.trueValue);
      emitter.emit(EmitterEvent.TIMELINE_DURATION_UPDATED, result);
      stateHistory.endAction();
    },
    [currentPrecompJson, sceneIndex],
  );

  const handleOnChangeFrameRate = useCallback(
    (option: Option) => {
      setFrameRate(option);
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) return;

      if (currentPrecompJson) {
        setPrecompFPS(scene, currentPrecompJson.id, option.value as number);
      } else {
        setSceneFPS(scene, option.value as number);
      }

      setFrameRateDisplay(option);
      emitter.emit(EmitterEvent.TIMELINE_FPS_UPDATED);
    },
    [currentPrecompJson, sceneIndex],
  );

  const handleOnInputFrameRate = useCallback(
    (result: NumberResult) => {
      setSceneFPS(toolkit.scenes[sceneIndex] as Scene, result.trueValue as number);
      emitter.emit(EmitterEvent.TIMELINE_FPS_UPDATED);
    },
    [sceneIndex],
  );

  useEffect(() => {
    if (currentPrecompJson) {
      setFrameRateDisplay({
        label: `${precompFrameRate} fps`,
        value: precompFrameRate as number,
      });

      setDurationDisplay(precompDuration);
      setSceneID(`${currentPrecompJson.id}-${precompDuration}`);
    } else {
      setFrameRateDisplay({
        label: `${currentFrameRate} fps`,
        value: currentFrameRate as number,
      });

      setDurationDisplay(duration);
      setSceneID(`main-${duration}`);
    }
  }, [
    currentFrameRate,
    currentPrecompJson,
    duration,
    durationDisplay,
    handleOnChangeDuration,
    handleOnChangeFrameRate,
    precompDuration,
    precompFrameRate,
  ]);

  const handleOnBlur = useCallback(() => {
    setDurationDisplay(currentPrecompJson ? precompDuration : duration);
  }, [currentPrecompJson, duration, precompDuration]);

  return (
    <div className="flex items-center">
      <TooltipWrapper offset={{ mainAxis: 7, crossAxis: 0 }} label="Duration">
        <div className="w-[86px] rounded-l">
          <NumberInput
            key={sceneId}
            name="duration"
            value={durationDisplay}
            min={MIN_TIME_SEC}
            max={MAX_TIME_SEC}
            label={<DurationIcon className="mx-0 h-4 w-4" />}
            onBlur={handleOnBlur}
            precision={2}
            onChange={handleOnChangeDuration}
            styleClass={{
              label: `${defaultStyle.label} rounded-r-none`,
            }}
            timerSetting={{
              fps: precompDuration ? (precompFrameRate as number) : currentFrameRate,
            }}
            testID="timer-input"
          />
        </div>
      </TooltipWrapper>

      <div className="ml-0.5 w-[86px]">
        <Select
          options={FrameRateOptions}
          selected={frameRateDisplay as Option}
          onChange={handleOnChangeFrameRate}
          input={{
            onInputChange: handleOnInputFrameRate,
            inputOption: {
              name: 'fpsInput',
              max: 999,
              postFix: 'fps',
              precision: 3,
            },
          }}
          styleClass={{
            button: `rounded-l-none`,
          }}
        />
      </div>
    </div>
  );
};

export const TimeProperty: React.FC = () => {
  return (
    <PropertyRow title="Time">
      <Duration />
    </PropertyRow>
  );
};
