/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-use-before-define */
import type { Intersection, OrthographicCamera } from 'three';
import { EventDispatcher, Matrix4, Plane, Raycaster, Vector2, Vector3 } from 'three';

import type { CObject3D } from '~/features/canvas';

const _plane = new Plane();
const _raycaster = new Raycaster();

const _pointer = new Vector2();
const _offset = new Vector3();
const _intersection = new Vector3();
const _worldPosition = new Vector3();
const _inverseMatrix = new Matrix4();

export class DragControls extends EventDispatcher {
  public enabled = true;

  public transformGroup = false;

  public constructor(_objects: CObject3D[], _camera: OrthographicCamera, _domElement: HTMLElement) {
    super();

    _domElement.style.touchAction = 'none';

    let _selected: CObject3D | undefined | null;
    let _hovered: CObject3D | undefined | null;

    const _intersections: Intersection[] = [];

    const activate = (): void => {
      _domElement.addEventListener('pointermove', onPointerMove);
      _domElement.addEventListener('pointerdown', onPointerDown);
      _domElement.addEventListener('pointerup', onPointerCancel);
      _domElement.addEventListener('pointerleave', onPointerCancel);
    };

    // const deactivate = (): void => {
    //   _domElement.removeEventListener('pointermove', onPointerMove);
    //   _domElement.removeEventListener('pointerdown', onPointerDown);
    //   _domElement.removeEventListener('pointerup', onPointerCancel);
    //   _domElement.removeEventListener('pointerleave', onPointerCancel);
    // };

    // const dispose = (): void => {
    //   deactivate();
    // };

    // const getObjects = (): CObject3D[] => {
    //   return _objects;
    // };

    // const getRaycaster = (): Raycaster => {
    //   return _raycaster;
    // };

    const onPointerMove = (event: PointerEvent): void => {
      if (this.enabled === false) return;

      updatePointer(event);

      _raycaster.setFromCamera(_pointer, _camera);

      if (_selected) {
        if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
          _selected.position.copy(_intersection.sub(_offset).applyMatrix4(_inverseMatrix));
        }

        this.dispatchEvent({ type: 'drag', object: _selected });

        return;
      }

      // hover support

      if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
        _intersections.length = 0;

        _raycaster.setFromCamera(_pointer, _camera);
        _raycaster.intersectObjects(_objects, true, _intersections);

        if (_intersections.length > 0) {
          const object = _intersections[0]?.object;

          if (object)
            _plane.setFromNormalAndCoplanarPoint(
              _camera.getWorldDirection(_plane.normal),
              _worldPosition.setFromMatrixPosition(object.matrixWorld),
            );

          if (_hovered !== object && _hovered) {
            this.dispatchEvent({ type: 'hoveroff', object: _hovered });

            _hovered = null;
          }

          if (_hovered !== object) {
            this.dispatchEvent({ type: 'hoveron', object });

            _hovered = object as CObject3D | null;
          }
        } else if (_hovered !== null) {
          this.dispatchEvent({ type: 'hoveroff', object: _hovered });

          _hovered = null;
        }
      }
    };

    const onPointerDown = (event: PointerEvent): void => {
      if (this.enabled === false) return;

      updatePointer(event);

      _intersections.length = 0;

      _raycaster.setFromCamera(_pointer, _camera);
      _raycaster.intersectObjects(_objects, true, _intersections);

      if (_intersections.length > 0) {
        _selected = this.transformGroup === true ? _objects[0] : (_intersections[0]?.object as CObject3D | null);

        if (_selected)
          _plane.setFromNormalAndCoplanarPoint(
            _camera.getWorldDirection(_plane.normal),
            _worldPosition.setFromMatrixPosition(_selected.matrixWorld),
          );

        if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
          if (_selected?.parent) {
            _inverseMatrix.copy(_selected.parent.matrixWorld).invert();
            _offset.copy(_intersection).sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
          }
        }

        this.dispatchEvent({ type: 'dragstart', object: _selected });
      }
    };

    const onPointerCancel = (): void => {
      if (this.enabled === false) return;

      if (_selected) {
        this.dispatchEvent({ type: 'dragend', object: _selected });

        _selected = null;
      }
    };

    const updatePointer = (event: PointerEvent): void => {
      const rect = _domElement.getBoundingClientRect();

      _pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      _pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
    };

    activate();
  }
}
