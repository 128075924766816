/**
 * Copyright 2022 Design Barn Inc.
 */

import { DoubleSide, MeshLambertMaterial } from 'three';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

import { StrokeMaterial } from './StrokeMaterial';

export * from './anchorPoint';
export * from './pathShape';

export const lineMaterial = new LineMaterial({
  color: 0xff0000,
  linewidth: 0.01,
  vertexColors: false,
  dashed: false,
  alphaToCoverage: true,
  transparent: true,
});

export const shapeMaterial = new MeshLambertMaterial({
  color: 0x00ffff,
  transparent: true,
  opacity: 1,
  side: DoubleSide,
});

export const strokeMaterial = new StrokeMaterial({
  color: 0x00ffff,
  vertexColors: false,
  worldUnits: true,
  dashed: true,
  dashSize: 2,
  gapSize: 1,
  alphaToCoverage: true,
});

export const anchorMaterial = new MeshLambertMaterial({
  color: 0x00b6fe,
  transparent: true,
  depthTest: false,
  side: DoubleSide,
});
