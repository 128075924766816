/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  PointerSensorOptions,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { DndContext, useSensor, useSensors, MeasuringStrategy } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type { LayerJSON } from '@lottiefiles/toolkit-js';
import { inRange } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getFlattenLayers } from '../../helper';

import { CustomMouseSensor, CustomPointerSensor, CustomTouchSensor } from './customDnd';
import { FolderTreeItem } from './FolderTreeItem';
import { SortableTreeItemLayer } from './SortableTreeItemLayer';
import type { FlattenedItem, SensorContext } from './types';
import { flattenTree, getProjection } from './utilities';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { LAYER_TYPES } from '~/lib/toolkit/constant';
import { useCreatorStore } from '~/store';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const defaultPointerSensorOptions: PointerSensorOptions = {
  activationConstraint: {
    distance: 10,
  },
};

interface DraggableWrapperProp {
  layers: LayerJSON[];
}

export const DraggableWrapper: React.FC<DraggableWrapperProp> = ({ layers }) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const [deltaMouseY, setDeltaMouseY] = useState<number | null>(null);
  const [lastLayerDragDeltaY, setLastLayerDragDeltaY] = useState<number | null>(null);

  const items = layers;

  //   const { disableSorting, indentationWidth } = useCreatorStore((state) => state.timeline.layerConfiguration);
  //   const setLayerConfiguration = useCreatorStore.getState().timeline.setLayerConfiguration;
  //   const hoverIsTop = useCreatorStore((state) => state.timeline.layerConfiguration.hoverIsTop);

  //   const hoverIsTop = true;
  const [disableSorting, indentationWidth, setLayerConfiguration, hoverIsTop] = useCreatorStore(
    (state) => [
      state.timeline.layerConfiguration.disableSorting,
      state.timeline.layerConfiguration.indentationWidth,
      state.timeline.setLayerConfiguration,
      state.timeline.layerConfiguration.hoverIsTop,
    ],
    shallow,
  );

  const flattenedItems = useMemo(
    () => getFlattenLayers(items, activeId as string), // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeId, items, items.length],
  );

  const projected = getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth ?? 0);
  const sensorContext: SensorContext<TreeItemData> = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(CustomPointerSensor, defaultPointerSensorOptions),
    useSensor(CustomMouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(CustomTouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 0,
      },
    }),
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }: { id: string }) => id), [flattenedItems]);

  const itemsRef = useRef(items);

  itemsRef.current = items;

  useEffect(() => {
    emitter.on(EmitterEvent.TIMELINE_LAYER_MOVE_UP, () => {
      const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

      if (!selectedNodeIds.length) return;
      const moveLayerDrawOrder = useCreatorStore.getState().toolkit.moveLayerDrawOrder;

      moveLayerDrawOrder(selectedNodeIds, false);
      emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
    });
    emitter.on(EmitterEvent.TIMELINE_LAYER_MOVE_DOWN, () => {
      const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

      if (!selectedNodeIds.length) return;
      const moveLayerDrawOrder = useCreatorStore.getState().toolkit.moveLayerDrawOrder;

      moveLayerDrawOrder(selectedNodeIds, true);
      emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
    });
  }, []);

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const resetState = useCallback(() => {
    setLayerConfiguration({ hoverOverId: null, hoverIsTop: true });

    setDeltaMouseY(null);
    setLastLayerDragDeltaY(null);
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty('cursor', '');
  }, [setLayerConfiguration, setDeltaMouseY, setLastLayerDragDeltaY]);

  const handleDragStart = useCallback(
    (dragParams: DragStartEvent): void => {
      const newActiveId = dragParams.active.id;

      setActiveId(newActiveId);
      setOverId(newActiveId);
      setLayerConfiguration({ hoverOverId: null });

      document.body.style.setProperty('cursor', 'grabbing');
    },
    [setLayerConfiguration],
  );

  const getCurrentLastLayerId = useCallback(() => {
    let lastLayerId = null;
    const flattenedItemsSHAPE = flattenedItems.filter((fItem) => fItem.type === 'SHAPE');

    if (flattenedItemsSHAPE.length > 0) {
      const lastLayerItem = flattenedItemsSHAPE.find((_fItem) => _fItem.isLast);

      if (lastLayerItem) {
        lastLayerId = lastLayerItem.id as string;
      }
    }

    return lastLayerId;
  }, [flattenedItems]);

  const handleDragMove = useCallback(
    (event: DragMoveEvent): void => {
      // set once only
      const { delta } = event;
      const dragOverId = event.over?.id ?? null;

      if (deltaMouseY === null) {
        setDeltaMouseY(delta.y);
      } else if (deltaMouseY === delta.y) return;

      const lastLayerId = getCurrentLastLayerId();

      if (dragOverId === lastLayerId && deltaMouseY !== null) {
        let _lastLayerDragDeltaY = 0;

        if (lastLayerDragDeltaY === null) {
          _lastLayerDragDeltaY = delta.y;

          setLastLayerDragDeltaY(delta.y);
        } else {
          _lastLayerDragDeltaY = lastLayerDragDeltaY;
        }

        const lastLayerOffsetY = delta.y - _lastLayerDragDeltaY;

        if (lastLayerOffsetY > 0 && inRange(lastLayerOffsetY, 0, 21)) {
          // show top
          setLayerConfiguration({ hoverOverId: dragOverId as string | null, hoverIsTop: true });

          setOverId(dragOverId);
        } else if (lastLayerOffsetY > 0) {
          // show bottom
          setLayerConfiguration({ hoverOverId: dragOverId as string | null, hoverIsTop: false });

          setOverId(dragOverId);
        }
      }

      setOffsetLeft(delta.x);
    },
    [
      setDeltaMouseY,
      deltaMouseY,
      lastLayerDragDeltaY,
      setLayerConfiguration,
      getCurrentLastLayerId,
      setLastLayerDragDeltaY,
    ],
  );

  const handleDragOver = useCallback(
    (event: DragMoveEvent): void => {
      const { over } = event;

      const dragOverId = over?.id ?? null;

      const lastLayerId = getCurrentLastLayerId();

      // if dragOverId is last layer?
      if (dragOverId === lastLayerId && deltaMouseY !== null) {
        const lastLayerOffsetY = event.delta.y - deltaMouseY;

        // Each layer height = 24px
        if (lastLayerOffsetY > 0 && inRange(lastLayerOffsetY, 0, 21)) {
          // show top
          setLayerConfiguration({ hoverOverId: dragOverId as string | null, hoverIsTop: true });

          setOverId(dragOverId);
        } else if (lastLayerOffsetY > 0) {
          // show bottom
          setLayerConfiguration({ hoverOverId: dragOverId as string | null, hoverIsTop: false });

          setOverId(dragOverId);
        }
      } else {
        setLayerConfiguration({ hoverOverId: dragOverId as string | null, hoverIsTop: true });

        setOverId(dragOverId);
      }
    },
    [setLayerConfiguration, getCurrentLastLayerId, deltaMouseY],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent): void => {
      resetState();
      if (projected && over) {
        const { depth, parentId } = projected;

        const clonedItems: Array<FlattenedItem<TreeItemData>> = flattenTree(items);

        const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
        const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
        const activeTreeItem = clonedItems[activeIndex];

        clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

        const draggedFromParent = activeTreeItem.parent;

        const lastLayerRow = clonedItems[overIndex].isLast;

        let updatedOverIndex = 0;

        if (!hoverIsTop && lastLayerRow && overIndex > 0) {
          // for the last layer, hover bottom
          updatedOverIndex = overIndex;
        } else if (hoverIsTop && overIndex > 0) {
          updatedOverIndex = overIndex - 1;
        }

        const sortedItems = arrayMove(clonedItems, activeIndex, updatedOverIndex);

        const newActiveItem = sortedItems.find((x) => x.id === active.id)!;
        const currentParent = newActiveItem.parentId ? sortedItems.find((x) => x.id === newActiveItem.parentId)! : null;

        const isRootLevelDrag = Boolean(LAYER_TYPES.includes(newActiveItem.type));

        if (
          isRootLevelDrag ||
          [newActiveItem.parent.id, currentParent?.id].filter(Boolean).includes(draggedFromParent.id)
        ) {
          // Drag at same level
          const newActiveId = active.id as string;

          let newParentChilds = null;
          let activeParentId: string | null = null;

          if (!isRootLevelDrag) {
            if (currentParent?.id && draggedFromParent?.id === currentParent?.id) {
              // between layer with layer
              activeParentId = currentParent.id;
              newParentChilds = sortedItems.filter((item) => item.parentId === activeParentId);
            } else if (draggedFromParent?.id === newActiveItem?.parent?.id) {
              // between layer group with layer group
              activeParentId = newActiveItem.parent.id;
              newParentChilds = sortedItems.filter((item) => [item.parent?.id, item.parentId].includes(activeParentId));
            }
          }

          if (!isRootLevelDrag && newParentChilds && newParentChilds.length > 0) {
            const newIndex = newParentChilds.findIndex((parentChild) => parentChild.id === newActiveId);

            if (newIndex > -1 && activeParentId) {
              const setLayerShapeIndex = useCreatorStore.getState().toolkit.setLayerShapeIndex;

              setLayerShapeIndex(newIndex, newActiveId, activeParentId);
              emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
            }
          } else if (isRootLevelDrag) {
            const newIndex = sortedItems
              .filter((item) => LAYER_TYPES.includes(item.type))
              .findIndex((_item) => _item.id === activeId);

            if (newIndex !== newActiveItem.index && newIndex > -1) {
              const setLayerDrawOrder = useCreatorStore.getState().toolkit.setLayerDrawOrder;

              setLayerDrawOrder(newIndex, newActiveId);
              emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
            }
          }
        } else {
          // TODO: Drag from one level to other level
        }
      }
    },
    [items, projected, resetState, activeId, hoverIsTop],
  );

  const handleDragCancel = useCallback((): void => {
    resetState();
  }, [resetState]);

  return (
    <>
      {
        <DndContext
          measuring={measuring}
          modifiers={[restrictToVerticalAxis]}
          {...(disableSorting === false
            ? {
                sensors,
                onDragStart: handleDragStart,
                onDragMove: handleDragMove,
                onDragOver: handleDragOver,
                onDragEnd: handleDragEnd,
                onDragCancel: handleDragCancel,
              }
            : {})}
        >
          <SortableContext
            items={sortedIds}
            {...(disableSorting === false
              ? {
                  strategy: verticalListSortingStrategy,
                }
              : {})}
          >
            <>
              {flattenedItems.map((layer) => {
                return <SortableTreeItemLayer key={layer.id} layer={layer} TreeItemComponent={FolderTreeItem} />;
              })}
            </>
          </SortableContext>
        </DndContext>
      }
    </>
  );
};
