/**
 * Copyright 2021 Design Barn Inc.
 */

import { useJitsu } from '@jitsu/react';
import type { SizeJSON } from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import { Circle, Polygon, Square, Star } from '~/assets/icons';
import { EventType, AssetSource } from '~/data/eventStore';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { Scalar2D, ShapeOption } from '~/lib/toolkit';
import { toolkit, createShape, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { ShapeTypes } from '~/store/constant';
import { GenericPayload } from '~/utils';

const ShapeTopList = [
  {
    type: ShapeTypes.Square,
    icon: <Square />,
  },
  {
    type: ShapeTypes.Ellipse,
    icon: <Circle />,
  },
  {
    type: ShapeTypes.Polygon,
    icon: <Polygon />,
  },
  {
    type: ShapeTypes.Star,
    icon: <Star />,
  },
];

export const rectangleOption: ShapeOption = {
  type: ShapeType.RECTANGLE,
  fill: [255, 142, 236, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Rectangle',
  rotation: 0,
  position: [0, 0],
  rectangle: { size: [100, 100], roundness: 0 },
};

export const ellipseOption: ShapeOption = {
  type: ShapeType.ELLIPSE,
  fill: [58, 134, 255, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Ellipse',
  rotation: 0,
  position: [0, 0],
  ellipse: { size: [100, 100] },
};

const polygonOption: ShapeOption = {
  type: ShapeType.POLYGON,
  fill: [156, 115, 248, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Polygon',
  rotation: 0,
  position: [0, 0],
  polystar: { points: 3, outerRadius: 50, type: 2 },
};

const starOption: ShapeOption = {
  type: ShapeType.STAR,
  fill: [255, 191, 0, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Star',
  rotation: 0,
  position: [0, 0],
  polystar: { points: 5, outerRadius: 50, innerRadius: 30, type: 1 },
};

export const ShapeSidePanel: React.FC = () => {
  const outPoint = useCreatorStore((state) => state.toolkit.json?.timeline.properties.op as number);
  const currentFrame = useCreatorStore((state) => state.toolkit.currentFrame as number);
  const width = useCreatorStore((state) => (state.toolkit.json?.properties.sz as SizeJSON).w) as number;
  const height = useCreatorStore((state) => (state.toolkit.json?.properties.sz as SizeJSON).h) as number;
  const sceneIndex = useCreatorStore((state) => state.toolkit.sceneIndex);
  const scene = toolkit.scenes[sceneIndex];
  const { track } = useJitsu();

  const handleOnClick = useCallback(
    (type: ShapeTypes): void => {
      // Execute tracking event the minute we identify the type of file
      track(EventType.UploadedAsset, {
        client: GenericPayload,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.UploadedAsset,
        parameters: {
          source: AssetSource.BasicShapes,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          send_to_amplitude: true,
        },
      });

      if (!scene) return;

      const position: Scalar2D = [width / 2, height / 2];

      stateHistory.beginAction();
      // Calling toolkit
      if (type === ShapeTypes.Square) {
        createShape(scene, { ...rectangleOption, endFrame: outPoint, startFrame: currentFrame, position });
      } else if (type === ShapeTypes.Ellipse) {
        createShape(scene, { ...ellipseOption, endFrame: outPoint, startFrame: currentFrame, position });
      } else if (type === ShapeTypes.Polygon) {
        createShape(scene, { ...polygonOption, endFrame: outPoint, startFrame: currentFrame, position });
      } else if (type === ShapeTypes.Star) {
        createShape(scene, { ...starOption, endFrame: outPoint, startFrame: currentFrame, position });
      }

      emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
      stateHistory.endAction();
    },
    [track, scene, width, height, outPoint, currentFrame],
  );

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex border-b border-gray-900 px-3 text-white">
        <div className="py-2 text-xs font-semibold">Shapes</div>
      </div>
      <div className="flex w-full justify-between border-b border-gray-900 px-3 pb-2">
        {ShapeTopList.map((shape, shapeIndex) => (
          <div
            key={shapeIndex}
            onClick={(): void => handleOnClick(shape.type)}
            data-testid={`${shape.type.toLowerCase()}-create-button`}
            className="group   flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded hover:bg-gray-600"
          >
            {React.cloneElement(shape.icon, {
              className: `w-[16px] h-[16px] cursor-pointer fill-transparent rounded`,
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
