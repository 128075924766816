/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable no-empty */
/* eslint-disable id-length */
/* eslint-disable padding-line-between-statements */

import type {
  AnimatedPropertiesJSON,
  ColorJSON,
  GroupShapeJSON,
  LayerJSON,
  PercentageJSON,
  ScalarJSON,
  SceneJSON,
  ShapeJSON,
  ShapeLayerJSON,
  SizeJSON,
  TextLayerJSON,
  Vector,
  VectorJSON,
  AngleJSON,
  PropertiesJSON,
  PrecompositionLayerJSON,
  ImageLayer,
  GradientJSON,
  ShapeLayer,
  BezierView,
  GroupBezierView,
  DagNode,
  GroupShape,
} from '@lottiefiles/toolkit-js';
import { Size, LayerType, ShapeType } from '@lottiefiles/toolkit-js';
import { Color } from 'three';
import type { Line2 } from 'three/examples/jsm/lines/Line2';
import { Vector3, TextureLoader, Mesh, MeshBasicMaterial, BoxGeometry } from 'three/src/Three';

import { applyGradient } from '../3d/shapes/gradientFill';
import { getPathShape } from '../3d/shapes/path';
import { getRectangle } from '../3d/shapes/rectangle';
import { getStroke } from '../3d/shapes/stroke';
import { getTransform } from '../3d/shapes/transform';
import type { GradientFillProperty, RectangleProperty, TransformProperty, StrokeProperty } from '../3d/shapes/type';
import { GradientType } from '../3d/shapes/type';
import { RaycasterLayers } from '../constant';
import type { CMesh } from '../types/object';
import { CObject3D } from '../types/object';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { Box3 } from '~/lib/threejs/Box3';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const setCanvasMap = useCreatorStore.getState().ui.setCanvasMap;

const assertShapeLayerJSON = (json: LayerJSON): json is ShapeLayerJSON => 'shapes' in json;
const assertTextLayerJSON = (json: LayerJSON): json is TextLayerJSON => 'textAnimators' in json;
const assertGradientFillShapeJSON = (json: ShapeJSON): json is GroupShapeJSON => json.type === ShapeType.GRADIENT_FILL;
const assertPrecompositionLayerJSON = (json: LayerJSON): json is LayerJSON => json.type === 'PRECOMPOSITION';
const assertImageLayerJSON = (json: LayerJSON): json is LayerJSON => json.type === 'IMAGE';
const assertSolidLayerJSON = (json: LayerJSON): json is LayerJSON => json.type === 'SOLID';

const cachedAssets = new Map();

const parseFill = (mesh: CMesh, properties: AnimatedPropertiesJSON, accumulatedOpacity: number): void => {
  const { cl, o: opacity } = properties;
  // When keyframes are removed, 'value' shouldn't return undefined. Toolkit will resolve this on next update.
  // TODO: Revert to 'value' when toolkit updates. Temporary fix:
  const { b, g, r } = (cl.value ?? cl.staticValue) as ColorJSON;

  mesh.material.color = new Color(`rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)})`);
  mesh.material.opacity = (((opacity.value ?? opacity.staticValue) as PercentageJSON).pct * accumulatedOpacity) / 100;

  setCanvasMap(cl.id, mesh);
  setCanvasMap(opacity.id, mesh);
};

const parseGradientFill = (properties: AnimatedPropertiesJSON): GradientFillProperty => {
  const { g, ge, gs, ha, o } = properties;
  const settings: GradientFillProperty = {
    colors: (g.value as GradientJSON).colors as ColorJSON[],
    startX: { value: (gs.value as VectorJSON).x },
    startY: { value: (gs.value as VectorJSON).y },
    endX: { value: (ge.value as VectorJSON).x },
    endY: { value: (ge.value as VectorJSON).y },
    gradientType: { value: GradientType.Linear },
    highlight: { value: 0 },
    highlightAngle: { value: (ha.value as AngleJSON).deg },
    opacity: { value: (o.value as PercentageJSON).pct / 100 },
  };

  return settings;
};

const parseSolidLayer = (properties: PropertiesJSON, animatedProperties: AnimatedPropertiesJSON): CMesh => {
  const setting: RectangleProperty = {
    width: { value: (properties.sz as SizeJSON).w },
    height: { value: (properties.sz as SizeJSON).h },
    positionX: { value: (animatedProperties.p.value as VectorJSON).x },
    positionY: { value: (animatedProperties.p.value as VectorJSON).y },
    radius: { value: 0 },
    color: (properties.slc as unknown) as ColorJSON,
    drawOrder: properties.do as number,
  };
  const mesh = getRectangle(setting);

  return mesh;
};

export const parseStroke = (
  properties: AnimatedPropertiesJSON,
  mesh: CMesh,
  accumulatedOpacity: number = 1,
): CMesh | Line2 => {
  const { pct } = properties.o.value as PercentageJSON;
  const opacity = (accumulatedOpacity * pct) / 100;

  const settings: StrokeProperty = {
    color: (properties.sc.value ?? properties.sc.staticValue) as ColorJSON,
    opacity,
    // TODO: Revert to 'value' when toolkit updates. Temporary fix:
    width: (properties.sw.value ?? properties.sw.staticValue) as ScalarJSON,
  };
  const strokeMesh = getStroke(settings, mesh);
  strokeMesh.material.opacity *= accumulatedOpacity;

  return strokeMesh;
};

const parseGroupProperties = (group: CObject3D, groupProperties: AnimatedPropertiesJSON): void => {
  const { a, o, p, r, s, sa, sk } = groupProperties;

  // temp fix
  const posVal = p.value ? (p.value as VectorJSON) : { x: 250, y: 250 };
  const scaleVal = s.value ? (s.value as VectorJSON) : { x: 100, y: 100 };
  const rotVal = r.value ? (r.value as AngleJSON) : { deg: 0 };
  const opacityVal = o.value ? (o.value as PercentageJSON) : { pct: 100 };

  const settings: TransformProperty = {
    anchorX: { value: (a.value as VectorJSON).x },
    anchorY: { value: (a.value as VectorJSON).y },
    positionX: { value: posVal.x },
    positionY: { value: posVal.y },
    scaleX: { value: scaleVal.x / 100 },
    scaleY: { value: scaleVal.y / 100 },
    rotation: { value: rotVal.deg },
    opacity: { value: opacityVal.pct / 100 },
    skew: { value: (sa.value as AngleJSON).deg },
    skewAngle: { value: (sk.value as AngleJSON).deg },
  };

  getTransform(group, settings);

  setCanvasMap(p.id, group);
  setCanvasMap(r.id, group);
  setCanvasMap(s.id, group);
  setCanvasMap(o.id, group);
};

export const parseBezierShapes = (
  group: CObject3D,
  subBezierView: BezierView,
  accumulatedOpacity: number,
  dOrder?: number,
): void => {
  const drawOrder = subBezierView.shape.state.properties.do as number;

  for (const bezier of subBezierView.beziers) {
    let strokeLine: CMesh | Line2 | undefined;
    let gradientProperty;

    const bezierPoints = bezier.vertices.map((vertex: Vector, index: number) => ({
      in: bezier.inTangents[index] as Vector,
      out: bezier.outTangents[index] as Vector,
      vertex,
    }));

    const mesh = getPathShape(bezierPoints, bezier.isClosed, new Vector3(0, 0, 0));

    mesh.toolkitId = subBezierView.shape.state.id;
    mesh.drawOrder = dOrder ?? drawOrder;

    if (!mesh.drawOrder) {
      // if no draw order, inherit parent's draw order based on its index in grandparent
      if (!drawOrder && drawOrder !== 0) {
        const grandparent = (subBezierView.shape.parent as GroupShape).parent as ShapeLayer;
        // use grandparent's draw order as base, so it doesn't interfere with other grandparents
        let index = grandparent.drawOrder;

        for (const shape of grandparent.shapes) {
          if (shape.nodeId === group.toolkitId) mesh.drawOrder = index;

          index += 0.1;
        }
      }
    }

    mesh.position.z = mesh.drawOrder;
    mesh.material.opacity = 0;
    mesh.opacity = 1;
    setCanvasMap(subBezierView.shape.state.id, mesh);

    for (const style of subBezierView.styles) {
      if (style.state.type === ShapeType.STROKE) {
        strokeLine = parseStroke(style.state.animatedProperties, mesh, accumulatedOpacity * group.opacity);
        mesh.add(strokeLine);
      } else if (style.state.type === ShapeType.FILL) {
        parseFill(mesh, style.state.animatedProperties, accumulatedOpacity * group.opacity);
      } else if (style.state.type === ShapeType.GRADIENT_FILL) {
        gradientProperty = parseGradientFill(style.state.animatedProperties);
        applyGradient(mesh, gradientProperty);
      }
    }
    group.add(mesh);
  }
};

export const parseBeziers = (
  bezierView: BezierView,
  accumulatedOpacity: number,
  drawOrder?: number,
): CObject3D | undefined => {
  const group = new CObject3D();

  group.layers.enable(RaycasterLayers.CObject3D);
  group.toolkitId = bezierView.shape.state.id;
  group.drawOrder = drawOrder ?? ((bezierView.shape.state as GroupShapeJSON).properties.do as number);

  group.layerType = LayerType.GROUP;
  setCanvasMap(bezierView.shape.state.id, group);
  parseGroupProperties(group, (bezierView.shape.state as GroupShapeJSON).animatedProperties);

  for (const subBezierView of (bezierView as GroupBezierView).bezierViews.reverse()) {
    if (subBezierView.isGroup) {
      const subGroup = parseBeziers(
        subBezierView,
        accumulatedOpacity * group.opacity,
        drawOrder ?? ((bezierView.shape.state as GroupShapeJSON).properties.do as number),
      );

      if (subGroup) group.add(subGroup);
    } else {
      parseBezierShapes(
        group,
        subBezierView,
        accumulatedOpacity,
        drawOrder ?? ((bezierView.shape.state as GroupShapeJSON).properties.do as number),
      );
    }
  }

  return group;
};

const isWithinRenderFrame = (currentFrame: number, layer: LayerJSON): boolean => {
  const ip = layer.properties.ip as number;
  const op = layer.properties.op as number;

  return currentFrame >= ip && currentFrame <= op;
};

const parseLayers = (layers: LayerJSON[], scene: SceneJSON, opacity: number, drawOrder?: number): CObject3D[] => {
  const objects = [] as CObject3D[];
  const currentFrame = scene.timeline.properties.cf as number;

  for (const layer of layers.slice().reverse()) {
    const group = new CObject3D();

    /**
     * using drawOrderOffset
     * In main scene precomp layers has drawOrder
     * PLayer1 - DO 1
     *  Asset Layers: DO 1, DO2, DO3 ( 1 ... n)
     *
     * So for canvas render the drawOrder for composition layers in PLayer1 to be adjusted to 1.0 ... 1.n using drawOrderOffset so it will maintain the correct z-index
     *
     * In render:
     * PLayer1 - DO 1
     *  Asset Layers: DO 1.3, DO 1.6, DO 1.9
     */
    const drawOrderOffset = (layer.properties.do as number) / layers.length;
    setCanvasMap(layer.id, group);
    group.toolkitId = layer.id;
    group.layers.enable(RaycasterLayers.CLayer);
    group.drawOrder = drawOrder ?? (layer.properties.do as number);
    group.position.z = drawOrder ?? (layer.properties.do as number);

    group.layerType = layer.type;
    // TODO - Need to review this in more lotties
    group.visible = isWithinRenderFrame(currentFrame, layer);
    group.opacity *= opacity;
    parseGroupProperties(group, layer.animatedProperties);

    if (layer.layers.length > 0) {
      const obj = parseLayers(layer.layers, scene, assertPrecompositionLayerJSON(layer) ? group.opacity : opacity);

      group.add(...obj);
    }

    if (assertShapeLayerJSON(layer)) {
      const layerNode = getNodeByIdOnly(layer.id);

      if (layerNode) {
        const beziers = (layerNode as ShapeLayer).toBezier();

        for (const bezierView of beziers.reverse()) {
          if (bezierView.isGroup) {
            const parsed = parseBeziers(bezierView, group.opacity, (drawOrder as number) + drawOrderOffset);

            if (parsed) {
              parsed.position.setZ(drawOrder ?? (layer.properties.do as number));
              group.add(parsed);
              let gradientProperty;

              if (assertGradientFillShapeJSON((bezierView as BezierView).shape.state)) {
                gradientProperty = parseGradientFill((bezierView as BezierView).shape.state.animatedProperties);
              }
              if (gradientProperty) applyGradient(group, gradientProperty, layer.effects[0]);
            }
          } else {
            parseBezierShapes(group, bezierView, group.opacity, (drawOrder as number) + drawOrderOffset);
          }
        }
      }
    } else if (assertTextLayerJSON(layer)) {
    } else if (assertPrecompositionLayerJSON(layer)) {
      const precomLayers = (layer as PrecompositionLayerJSON).composition.layers;

      if (precomLayers.length > 0) {
        const obj = parseLayers(precomLayers, scene, group.opacity, layer.properties.do as number);

        group.add(...obj);
      }
    } else if (assertImageLayerJSON(layer)) {
      const imageAsset = scene.assets.find((asset) => asset.properties.ln === (layer as ImageLayer).referenceId);

      if (imageAsset) {
        if (cachedAssets.get((layer as ImageLayer).referenceId)?.sceneId === scene.id) {
          group.add(cachedAssets.get((layer as ImageLayer).referenceId).mesh);
        } else {
          cachedAssets.delete((layer as ImageLayer).referenceId);
          const texture = new TextureLoader().load(imageAsset.properties.ur as string);

          const { h, w } = imageAsset.properties.sz as SizeJSON;
          const geometry = new BoxGeometry(w, h, 0);
          const material = new MeshBasicMaterial({ map: texture, transparent: true });
          const mesh = new Mesh(geometry, material);

          mesh.position.setX(mesh.position.x + w / 2);
          mesh.position.setY(mesh.position.y + h / 2);

          mesh.scale.set(-1, -1, 1);
          mesh.updateMatrix();
          if (imageAsset.properties.do) {
            mesh.position.setZ(imageAsset.properties.do as number);
          }
          group.add(mesh);
          cachedAssets.set((layer as ImageLayer).referenceId, {
            referenceId: (layer as ImageLayer).referenceId,
            mesh,
            sceneId: scene.id,
          });
        }
      }
    } else if (assertSolidLayerJSON(layer)) {
      group.add(parseSolidLayer((layer as LayerJSON).properties, (layer as LayerJSON).animatedProperties));
    }
    objects.push(group);
  }

  return objects;
};

// parse toolkit state and return 3D object
export const parseToolKitState = (scene: SceneJSON): CObject3D[] => {
  const { layers } = scene;

  return parseLayers(layers, scene, 1);
};

// Resize canvas if precomp-size is different from canvasSize
const resizePrecompCanvas = (precompSize: Size, canvasSize: SizeJSON | null): void => {
  if (
    !canvasSize ||
    Math.floor(precompSize.width) !== Math.floor(canvasSize.w) ||
    Math.floor(precompSize.height) !== Math.floor(canvasSize.h)
  ) {
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.PRECOMP_SCENE_SIZE_INIT,
    });

    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.CANVAS_ADJUST_CAMERA,
      data: { size: precompSize.toJSON() },
    });
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.CANVAS_ADJUST,
      data: { size: precompSize.toJSON() },
    });
  }
};

// Recalculate precomp canvas size based on the layers/shapes in view
export const reCalculatePrecompCanvasSize = (
  precompAsset: DagNode,
  parsedObjects: CObject3D[],
  canvasSize: SizeJSON | null,
): void => {
  const hoverBox = new Box3();
  const maxSize = new Size(100, 100);
  const minSize = new Size(0, 0);

  if (typeof precompAsset.getData('canvasWidth') !== 'undefined') {
    maxSize.setWidth(Math.max(precompAsset.getData('canvasWidth') as number, 0));
    maxSize.setHeight(Math.max(precompAsset.getData('canvasHeight') as number, 0));

    minSize.setWidth(Math.max(precompAsset.getData('canvasMinWidth') as number, 0));
    minSize.setHeight(Math.max(precompAsset.getData('canvasMinHeight') as number, 0));
  }

  parsedObjects.forEach((eachObj) => {
    hoverBox.setFromObject(eachObj, true);

    maxSize.width = Number.isFinite(hoverBox.max.x) ? Math.max(maxSize.width, hoverBox.max.x) : maxSize.width;
    maxSize.height = Number.isFinite(hoverBox.max.y) ? Math.max(maxSize.height, hoverBox.max.y) : maxSize.height;

    minSize.width =
      Number.isFinite(hoverBox.min.x) && (hoverBox.min.x < minSize.width || minSize.width === 0)
        ? hoverBox.min.x
        : minSize.width;

    minSize.height =
      Number.isFinite(hoverBox.min.y) && (hoverBox.min.y < minSize.height || minSize.height === 0)
        ? hoverBox.min.y
        : minSize.height;
  });

  precompAsset.setData('canvasCustom', true);

  precompAsset.setData('canvasMinWidth', minSize.width);
  precompAsset.setData('canvasMinHeight', minSize.height);

  precompAsset.setData('canvasWidth', maxSize.width);
  precompAsset.setData('canvasHeight', maxSize.height);

  const precompSize = new Size(
    Math.max(maxSize.width + minSize.width, 0),
    Math.max(maxSize.height + minSize.height, 0),
  );

  resizePrecompCanvas(precompSize, canvasSize);
};

// Re-AdjustCanvas size if canvas width and height is available in toolkit asset data
export const reAdjustPrecompCanvas = (
  selectedPrecompositionId: string | null,
  parsedObjects: CObject3D[],
  canvasSize: SizeJSON | null,
): void => {
  if (!selectedPrecompositionId) return;
  const precompAsset = getNodeByIdOnly(selectedPrecompositionId);

  if (!precompAsset) return;

  if (precompAsset.getData('canvasWidth')) {
    const canvasWidth =
      (precompAsset.getData('canvasWidth') as number) + (precompAsset.getData('canvasMinWidth') as number);
    const canvasHeight =
      (precompAsset.getData('canvasHeight') as number) + (precompAsset.getData('canvasMinHeight') as number);
    const precompSize = new Size(Math.max(canvasWidth, 0), Math.max(canvasHeight, 0));

    resizePrecompCanvas(precompSize, canvasSize);
  } else {
    reCalculatePrecompCanvasSize(precompAsset, parsedObjects, canvasSize);
  }
};
