/**
 * Copyright 2023 Design Barn Inc.
 */

import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { Plugin } from '~/plugins/Plugin';

// TODO: fill in rest of interface after implementing accessors
export interface Property {
  nodeId: string;
  nodeType: string;
  type: string;
}

const methodNames = {
  clone: 'clone',
  copyValue: 'copyValue',
  defaultValue: 'defaultValue',
  isAnimated: 'isAnimated',
  value: 'value',
  reset: 'reset',
  setIsAnimated: 'setIsAnimated',
  setValue: 'setValue',
};

export function getPropertyMethods(plugin: Plugin, property: Property): ObjectMethods {
  return getObjectMethods(plugin, methodNames, property);
}

export function createPropertyObj(plugin: Plugin, property: Property): object {
  const propertyObj = {
    nodeId: property.nodeId,
    nodeType: property.nodeType,
    type: property.type,
  };

  registerObjectMethods(plugin, property, propertyObj, [getNodeMethods, getPropertyMethods]);

  return propertyObj;
}
