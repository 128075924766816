/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Method } from 'axios';
import axios from 'axios';

import { LOTTIE_UPLOAD } from '~/config';

const axiosConfig = {
  baseURL: LOTTIE_UPLOAD as string,
  timeout: 20000,
  method: 'POST' as Method,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// Create Axios
const service = axios.create(axiosConfig);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      return error.response;
    }

    return error;
  },
);

// Request
service.interceptors.request.use(
  (config) => {
    return config;
  },
  async (error) => {
    // Do something with request error
    // console.log(error);
    return Promise.reject(error);
  },
);

export default service;
