/**
 * Copyright 2021 Design Barn Inc.
 */

import type { ColorStopJSON } from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import GParser from 'gradient-parser';
import type { ColorStop } from 'gradient-parser';
import { get } from 'lodash-es';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ColorInput } from '../../../components/Elements/ColorInput/ColorInput';

import { Opacity } from '~/assets/icons';
import { NumberInput, defaultStyle } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

interface GradientFillProp {
  id: string;
}

export interface GradientData {
  length: number;
  rgba: number[];
}

// TODO: debounce
export const MultipleGradientFill: React.FC<GradientFillProp> = (shapeData: GradientFillProp) => {
  const id = shapeData.id;
  const [cssColor, opacity, type] = useCreatorStore(
    (state) => [
      get(state.toolkit.currentGFillShapes[id], 'cssColor') || '',
      get(state.toolkit.currentGFillShapes[id], 'opacity'),
      get(state.toolkit.currentGFillShapes[id], 'type'),
    ],
    shallow,
  );

  const handleOnChangeOpacity = useCallback((result: NumberResult) => {
    useCreatorStore.getState().toolkit.setGradientFillOpacity(result.value);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_OPACITY_UPDATED);
  }, []);

  const handleOnChangeColor = useCallback((color: string): void => {
    const gradientObj = GParser.parse(color);
    const updateParam: ColorStopJSON[] = [];

    if (gradientObj[0] && gradientObj[0].colorStops.length > 0) {
      gradientObj[0].colorStops.forEach((cStop: ColorStop) => {
        const [red, green, blue, alpha] = cStop.value;

        updateParam.push({
          r: Number(red),
          g: Number(green),
          b: Number(blue),
          a: Number(alpha),
          offset: Number(cStop.length?.value),
        });
      });
    }

    useCreatorStore.getState().toolkit.setGradientColor(updateParam);

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED);
  }, []);

  if (!id) return <></>;

  return (
    <div className="mt-2 flex w-44">
      <ColorInput
        type={type as string}
        shapeType={ShapeType.GRADIENT_FILL}
        styleClass="rounded-r-none w-[87.5px]"
        color={cssColor as string}
        opacity={opacity ?? 100}
        onChangeColor={handleOnChangeColor}
        onChangeOpacity={(result) => handleOnChangeOpacity({ name: 'opacity', value: result, trueValue: result })}
      />

      <div className="my-[-1px] border border-gray-800"></div>
      <div className="w-[87.5px]">
        <NumberInput
          styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
          name="opacity"
          value={opacity as number}
          label={<Opacity className="h-4 w-4" />}
          min={0}
          max={100}
          precision={0}
          onChange={handleOnChangeOpacity}
          append="%"
          showKeyframe
        />
      </div>
    </div>
  );
};

export const SingleGradientFill: React.FC = () => {
  const [cssColor, opacity, type] = useCreatorStore(
    (state) => [
      get(state.toolkit.currentGFillShape, 'cssColor') || '',
      get(state.toolkit.currentGFillShape, 'opacity'),
      get(state.toolkit.currentGFillShape, 'type'),
    ],
    shallow,
  );

  const handleOnChangeOpacity = useCallback((result: NumberResult) => {
    useCreatorStore.getState().toolkit.setGradientFillOpacity(result.value);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_OPACITY_UPDATED);
  }, []);

  const handleOnChangeColor = useCallback((color: string) => {
    const gradientObj = GParser.parse(color);
    const updateParam: ColorStopJSON[] = [];

    if (gradientObj[0] && gradientObj[0].colorStops.length > 0) {
      gradientObj[0].colorStops.forEach((cStop: ColorStop) => {
        const [red, green, blue, alpha] = cStop.value;

        updateParam.push({
          r: Number(red),
          g: Number(green),
          b: Number(blue),
          a: Number(alpha),
          offset: Number(cStop.length?.value),
        });
      });
    }

    useCreatorStore.getState().toolkit.setGradientColor(updateParam);

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED);
  }, []);

  return (
    <div className="mt-2 flex">
      <ColorInput
        type={type as string}
        shapeType={ShapeType.GRADIENT_FILL}
        styleClass="rounded-r-none !w-[87.5px]"
        color={cssColor as string}
        opacity={opacity}
        onChangeColor={handleOnChangeColor}
        onChangeOpacity={(result) => handleOnChangeOpacity({ name: 'opacity', value: result, trueValue: result })}
      />
      <div className="my-[-1px] border border-gray-800"></div>
      <div className="w-[87.5px]">
        <NumberInput
          styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
          name="opacity"
          value={opacity as number}
          label={<Opacity className="h-4 w-4" />}
          min={0}
          max={100}
          precision={0}
          onChange={handleOnChangeOpacity}
          append="%"
          showKeyframe
        />
      </div>
    </div>
  );
};

export const SingleGradientFillProperty: React.FC = () => {
  return <SingleGradientFill />;
};
