/**
 * Copyright 2023 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import type { ValueObject } from '../../property-values';

import { handleIncomingValueTypes } from './class';
import { handleIncomingInterpolators } from './interpolators';

import type { Plugin } from '~/plugins/Plugin';

export function unmarshal(plugin: Plugin, vmArgs: QuickJSHandle[]): unknown[] | null {
  let args;
  const updatedArgs: unknown[] = [];

  if (vmArgs[0]) {
    args = vmArgs.map(plugin.vm.dump);

    args.forEach((arg) => {
      if ((arg as ValueObject).valueType) {
        const updatedArg = handleIncomingValueTypes(arg);

        updatedArgs.push(updatedArg);
      } else if (arg.interpolatorType) {
        const updatedArg = handleIncomingInterpolators(arg);

        updatedArgs.push(updatedArg);
      } else {
        updatedArgs.push(arg);
      }
    });
  }

  return updatedArgs.length > 0 ? updatedArgs : null;
}
