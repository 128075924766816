/**
 * Copyright 2021 Design Barn Inc.
 */

import clsx from 'clsx';
import type { KeyboardEventHandler } from 'react';
import React, { useCallback, useRef, useMemo } from 'react';

import KeyframeButton from '../Button/KeyframeButton';

import { BindDragInputTo } from './BindDragInputTo';
import type { NumberResult } from './types';
import { useNumberInput } from './useNumberInput';
import { useTimeInput } from './useTimeInput';
import './input.css';

export const defaultStyle = {
  label:
    'p-1 h-[24px] w-full text-xs font-normal bg-gray-700 rounded border border-transparent focus-within:border-teal-500 number-input-label',
  input: 'text-xs pl-1 font-normal text-left bg-gray-700 focus:outline-none number-input',
  span: 'flex justify-center items-center mx-1 text-gray-500 select-none w-[16px]',
};

export interface NumberInputStyle {
  input?: string;
  keyframe?: string;
  label?: string;
  span?: string;
}

export interface TimerSetting {
  fps: number;
}

export interface NumberInputProps {
  append?: string;
  hasKeyframe?: boolean;
  label?: string | React.ReactNode | null;
  max?: number | null;
  message?: string | null | undefined;
  min?: number | null;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (result: NumberResult) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyframeClick?: () => void;
  precision?: number;
  showKeyframe?: boolean;
  step?: number;
  styleClass?: NumberInputStyle;
  testID?: string;
  timerSetting?: TimerSetting;
  value: number;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  append = '',
  hasKeyframe = false,
  label = null,
  max = null,
  message,
  min = null,
  name,
  onBlur,
  onChange = null,
  onKeyDown,
  onKeyframeClick,
  precision = 2,
  showKeyframe = false,
  step = 1,
  styleClass,
  testID = 'number-input',
  timerSetting = null,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const style = useMemo(() => ({ ...defaultStyle, ...styleClass }), [styleClass]);
  const [input, { handleInputChange, handleInputStop, handleOnBlur }] = (timerSetting ? useTimeInput : useNumberInput)({
    name,
    value,
    min,
    max,
    precision,
    inputRef,
    onChange,
    step,
    append,
    timerSetting,
  });

  const handleKeyframeClick = useCallback((): void => {
    if (onKeyframeClick) {
      onKeyframeClick();
    }
  }, [onKeyframeClick]);

  const handleDrag = useCallback(
    (obj: Record<string, Record<string, string>>): void => {
      const newEvent = new Event('ChangeEvent');

      handleInputChange(({ ...newEvent, ...obj, type: 'onDrag' } as unknown) as React.ChangeEvent<HTMLInputElement>);
      if (!timerSetting) {
        handleOnBlur();
      }
    },
    [handleInputChange, timerSetting, handleOnBlur],
  );

  const handleDragStop = useCallback(
    (obj: Record<string, Record<string, string>>): void => {
      const newEvent = new Event('DragStop');

      handleInputStop(({ ...newEvent, ...obj } as unknown) as React.ChangeEvent<HTMLInputElement>);
      handleOnBlur();
    },
    [handleOnBlur, handleInputStop],
  );

  const handleOnBlurInput = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(event);
    },
    [onBlur],
  );

  return (
    <>
      <div className="relative flex">
        <label
          className={clsx('group flex items-center', style.label)}
          onBlur={handleOnBlur}
          data-testid="number-input-container"
          onClick={() => {
            // When click number input select all the text
            inputRef.current?.select();
          }}
        >
          {label && (
            <BindDragInputTo inputRef={inputRef} value={input} setValue={handleDrag} onStop={handleDragStop}>
              <span className={style.span} data-testid="number-input-label">
                {label}
              </span>
            </BindDragInputTo>
          )}

          <input
            autoComplete="off"
            ref={inputRef}
            name={name}
            className={clsx('w-full', style.input)}
            value={message ?? input}
            min={min ?? 0}
            max={max ?? 100}
            onBlur={handleOnBlurInput}
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
            data-testid={testID}
          />
        </label>
        {showKeyframe && (
          <div className="absolute right-[-8px] top-[3px]">
            <KeyframeButton hasKeyframe={hasKeyframe} onClick={handleKeyframeClick} />
          </div>
        )}
      </div>
    </>
  );
};
