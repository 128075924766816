/**
 * Copyright 2021 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import { get } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { ColorInput } from '../../../components/Elements/ColorInput/ColorInput';

import { Opacity } from '~/assets/icons';
import { NumberInput, defaultStyle } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { TooltipWrapper } from '~/components/Elements/Tooltip/TooltipWrapper';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, removeKeyFrame } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface ShapeFillProps {
  id: string;
}

export const MultipleFill: React.FC<ShapeFillProps> = ({ id }) => {
  const [
    r,
    g,
    b,
    opacity,
    currentColorKeyframe,
    currentOpacityKeyframe,
    setAnimatedValue,
    addAnimatedValue,
  ] = useCreatorStore(
    (state) => [
      get(state.toolkit.currentFillShapes[id], 'r'),
      get(state.toolkit.currentFillShapes[id], 'g'),
      get(state.toolkit.currentFillShapes[id], 'b'),
      get(state.toolkit.currentFillShapes[id], 'opacity'),
      get(state.toolkit.currentFillShapes[id], 'colorCurrentKeyframe'),
      get(state.toolkit.currentFillShapes[id], 'opacityCurrentKeyframe'),
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
    ],
    shallow,
  );

  const hasColorKeyframe = Boolean(currentColorKeyframe);
  const hasOpacityKeyframe = Boolean(currentOpacityKeyframe);

  const colorString = useMemo(() => {
    // eslint-disable-next-line no-undefined
    if (r !== undefined && g !== undefined && b !== undefined && opacity !== undefined)
      return colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', '');
    else return 'ffffff';
  }, [r, g, b, opacity]);

  const handleKeyframeClick = useCallback(
    (type: number) => {
      if (type === AnimatedType.FILL_COLOR) {
        if (currentColorKeyframe) {
          removeKeyFrame(currentColorKeyframe);
        }

        if (!hasColorKeyframe) {
          addAnimatedValue(AnimatedType.FILL_COLOR, [r, g, b], id);
        }

        emitter.emit(EmitterEvent.SHAPE_FILL_COLOR_UPDATED);
      }

      if (type === AnimatedType.FILL_OPACITY) {
        if (currentOpacityKeyframe) {
          removeKeyFrame(currentOpacityKeyframe);
        }

        if (!hasOpacityKeyframe) {
          addAnimatedValue(AnimatedType.FILL_OPACITY, [opacity], id);
        }

        emitter.emit(EmitterEvent.SHAPE_FILL_OPACITY_UPDATED);
      }
    },
    [
      currentColorKeyframe,
      currentOpacityKeyframe,
      hasColorKeyframe,
      hasOpacityKeyframe,
      addAnimatedValue,
      r,
      g,
      b,
      id,
      opacity,
    ],
  );

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      const currentOpacity = opacity ?? 0;

      if (currentOpacity !== result.value) {
        setAnimatedValue(AnimatedType.FILL_OPACITY, [result.value], id);

        emitter.emit(EmitterEvent.SHAPE_FILL_OPACITY_UPDATED);
      }
    },
    [opacity, setAnimatedValue, id],
  );

  const handleOnChangeColor = useCallback(
    (color: string) => {
      const rgba = colord(color).toRgb();

      setAnimatedValue(AnimatedType.FILL_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a], id);
      // useCreatorStore.getState().toolkit.setFillColor([rgba.r, rgba.g, rgba.b, rgba.a]);
      emitter.emit(EmitterEvent.SHAPE_FILL_COLOR_UPDATED);
    },
    [setAnimatedValue, id],
  );

  if (!id) return <></>;

  return (
    <div className="mt-2 flex w-44">
      <div className="w-1/2">
        <TooltipWrapper label="Fill color">
          <ColorInput
            shapeType={ShapeType.FILL}
            styleClass="w-[84px]"
            color={colorString}
            opacity={opacity ?? 100}
            onChangeColor={handleOnChangeColor}
            showKeyframe
            hasKeyframe={hasColorKeyframe}
            onKeyframeClick={() => handleKeyframeClick(AnimatedType.FILL_COLOR)}
            onChangeOpacity={(value: number) =>
              handleOnChangeOpacity({
                name: 'opacity',
                trueValue: value,
                value,
              })
            }
          />
        </TooltipWrapper>
      </div>
      <div className="my-[-1px] border border-gray-800"></div>
      <div className="w-[84px]">
        <TooltipWrapper label="Opacity">
          <NumberInput
            styleClass={{ label: `${defaultStyle.label}` }}
            name="opacity"
            value={opacity as number}
            label={<Opacity className="h-4 w-4" />}
            min={0}
            max={100}
            precision={0}
            onChange={handleOnChangeOpacity}
            onKeyframeClick={() => handleKeyframeClick(AnimatedType.FILL_OPACITY)}
            hasKeyframe={hasOpacityKeyframe}
            append="%"
            showKeyframe
          />
        </TooltipWrapper>
      </div>
    </div>
  );
};

export const SingleFill: React.FC = () => {
  const [
    r,
    g,
    b,
    opacity,
    currentColorKeyframe,
    currentOpacityKeyframe,
    setAnimatedValue,
    addAnimatedValue,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentFillShape.r,
      state.toolkit.currentFillShape.g,
      state.toolkit.currentFillShape.b,
      state.toolkit.currentFillShape.opacity,
      state.toolkit.currentFillShape.colorCurrentKeyframe,
      state.toolkit.currentFillShape.opacityCurrentKeyframe,
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
    ],
    shallow,
  );

  const colorString = useMemo(
    () =>
      colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', ''),
    [r, g, b, opacity],
  );

  const hasColorKeyframe = Boolean(currentColorKeyframe);
  const hasOpacityKeyframe = Boolean(currentOpacityKeyframe);

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      setAnimatedValue(AnimatedType.FILL_OPACITY, [result.value]);
      emitter.emit(EmitterEvent.SHAPE_FILL_UPDATED);
    },
    [setAnimatedValue],
  );

  const handleOnChangeColor = useCallback(
    (color: string) => {
      const rgba = colord(color).toRgb();

      setAnimatedValue(AnimatedType.FILL_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a]);
      emitter.emit(EmitterEvent.SHAPE_FILL_UPDATED);
    },
    [setAnimatedValue],
  );

  const handleKeyframeClick = useCallback(
    (type: number) => {
      if (type === AnimatedType.FILL_COLOR) {
        if (currentColorKeyframe) {
          removeKeyFrame(currentColorKeyframe);
        }

        if (!hasColorKeyframe) {
          addAnimatedValue(AnimatedType.FILL_COLOR, [r, g, b]);
        }

        emitter.emit(EmitterEvent.SHAPE_FILL_COLOR_UPDATED);
      }

      if (type === AnimatedType.FILL_OPACITY) {
        if (currentOpacityKeyframe) {
          removeKeyFrame(currentOpacityKeyframe);
        }

        if (!hasOpacityKeyframe) {
          addAnimatedValue(AnimatedType.FILL_OPACITY, [opacity]);
        }

        emitter.emit(EmitterEvent.SHAPE_FILL_OPACITY_UPDATED);
      }
    },
    [
      currentColorKeyframe,
      hasColorKeyframe,
      addAnimatedValue,
      r,
      g,
      b,
      currentOpacityKeyframe,
      hasOpacityKeyframe,
      opacity,
    ],
  );

  return (
    <>
      <div className="mt-2 flex w-44">
        <TooltipWrapper label="Fill color">
          <ColorInput
            shapeType={ShapeType.FILL}
            styleClass="w-[84px]"
            color={colorString}
            opacity={opacity}
            showKeyframe
            hasKeyframe={hasColorKeyframe}
            onKeyframeClick={() => handleKeyframeClick(AnimatedType.FILL_COLOR)}
            onChangeColor={handleOnChangeColor}
            onChangeOpacity={(value: number) =>
              handleOnChangeOpacity({
                name: 'opacity',
                trueValue: value,
                value,
              })
            }
          />
        </TooltipWrapper>
      </div>
    </>
  );
};

export const SingleFillProperty: React.FC = () => {
  return <SingleFill />;
};
