/**
 * Copyright 2022 Design Barn Inc.
 */

export enum RaycasterLayers {
  Inactive = 1,
  CMesh = 2,
  CObject3D = 3,
  CLayer = 4,
  PreComposition = 5,
  Allpass = 6,
}

export const DOUBLE_CLICK_DELAY = 200;

export enum UserDataMap {
  MultiPivot = 'multiPivot',
  PivotCentered = 'pivotCentered',
  PivotVisible = 'pivotVisible',
  ScaleRatioLock = 'scaleRatioLock',
  SizeRatioLock = 'sizeRatioLock',
}
