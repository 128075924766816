/**
 * Copyright 2023 Design Barn Inc.
 */

import type {
  FillShape,
  GroupShape,
  GradientFillShape,
  EllipseShape,
  RectangleShape,
  StarShape,
  RoundedCornersShape,
} from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  setName: 'setName',
  name: 'name',
  classNames: 'classNames',
  addClassName: 'addClassName',
  removeClassName: 'removeClassName',
  type: 'type',
  moveBackward: 'moveBackward',
  moveForward: 'moveForward',
  moveToBack: 'moveToBack',
  moveToFront: 'moveToFront',
};

export function getShapeMethods(
  plugin: Plugin,
  groupShape:
    | GroupShape
    | FillShape
    | GradientFillShape
    | EllipseShape
    | RectangleShape
    | StarShape
    | RoundedCornersShape,
): ObjectMethods {
  return getObjectMethods(plugin, methodNames, groupShape);
}
