/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useContext, useState, useCallback, useRef } from 'react';

import { SaveContext } from './SaveContext';

import { WorkspaceProjectPencil } from '~/assets/icons';
import { useClickOutside } from '~/hooks/useClickOutside';

export const SaveEditName: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [edit, setEdit] = useState(false);
  const { fileName, setFileName } = useContext(SaveContext);

  const [inputName, setInputName] = useState(fileName);

  const handleTextChange = useCallback(() => {
    const element = inputRef.current as HTMLInputElement;
    const text = element.innerText || element.textContent;

    const inputText = text ? text.trim() : '';

    setInputName(inputText);
    setFileName(inputText);
  }, [setInputName, inputRef, setFileName]);

  const handleOnInputKeyPress = useCallback(
    (evt: { key: string }) => {
      if (evt.key === 'Enter') {
        handleTextChange();
        setEdit(false);
      }
    },
    [handleTextChange],
  );

  const selectSpan = useCallback(() => {
    const el = inputRef.current as HTMLInputElement;
    const range = document.createRange();

    range.selectNodeContents(el);
    const sel = window.getSelection();

    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }, []);

  const handleClickOutside = useCallback((): void => {
    handleTextChange();
    setEdit(false);
  }, [handleTextChange]);

  useClickOutside(inputRef, handleClickOutside);

  return (
    <div>
      <span
        data-testid="file-name-input"
        ref={inputRef}
        className="!inline-block h-full border-0  bg-gray-800 p-0 text-[10px] text-white outline-none selection:border-none selection:bg-teal-500 selection:outline-none"
        role="textbox"
        contentEditable={edit}
        onKeyPress={handleOnInputKeyPress}
        suppressContentEditableWarning={true}
      >
        {inputName}
      </span>

      <button
        data-testid="file-edit-name-button"
        className="align-middle"
        onClick={() => {
          setEdit(!edit);
          selectSpan();
        }}
      >
        <WorkspaceProjectPencil className="h-3 w-3 cursor-pointer select-none text-gray-500 hover:text-white" />
      </button>
    </div>
  );
};
