/**
 * Copyright 2021 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useCallback } from 'react';

import { TimelinePlayerChevronDown } from '~/assets/icons';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

interface ZoomOption {
  label: string;
  value: number;
}

const zoomOptions: ZoomOption[] = [
  {
    label: '200%',
    value: 200,
  },
  {
    label: '150%',
    value: 150,
  },
  {
    label: '125%',
    value: 125,
  },
  {
    label: '100%',
    value: 100,
  },
  {
    label: '75%',
    value: 75,
  },
  {
    label: '50%',
    value: 50,
  },
];

export const ZoomDropDown: React.FC = () => {
  const setZoomPercentage = useCreatorStore((state) => state.ui.setZoomPercentage);

  const handleZoomPercentage = useCallback(
    (percentage: number) => {
      setZoomPercentage(percentage);
    },
    [setZoomPercentage],
  );

  const handleZoomToFit = useCallback(() => {
    emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT, { skipUpdate: true });
  }, []);

  return (
    <Menu as="div" className="relative inline-block">
      {({ open }) => (
        <>
          <Menu.Button
            className={clsx('group ml-1 flex h-4 w-4 items-center justify-center rounded hover:bg-gray-700', {
              'bg-gray-700': open,
            })}
          >
            <TimelinePlayerChevronDown
              className={clsx('h-3 w-3 group-hover:stroke-white', {
                'stroke-white': open,
                'stroke-gray-400': !open,
              })}
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-[5px] w-[98px] rounded-lg bg-gray-800 py-2 drop-shadow-player">
              {zoomOptions.map((dropdown) => (
                <Menu.Item key={dropdown.label}>
                  <div
                    className="mx-2 mb-1 flex h-6 items-center px-2 text-[12px] leading-[18px] hover:bg-gray-700"
                    onClick={() => handleZoomPercentage(dropdown.value)}
                  >
                    <div className="cursor-default">{dropdown.label}</div>
                  </div>
                </Menu.Item>
              ))}
              <div className="my-1 w-full border-b border-gray-900"></div>
              <Menu.Item>
                <div
                  className="mx-2 flex h-6 items-center px-2 text-[12px] leading-[18px] hover:bg-gray-700"
                  onClick={handleZoomToFit}
                >
                  <div className="cursor-default">Zoom to fit</div>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export const ZoomControl: React.FC = () => {
  const zoomPercentage = useCreatorStore((state) => state.ui.zoomPercentage);

  return (
    <div className="flex items-center">
      <div className="w-[27px] cursor-default text-[12px] leading-[18px] text-gray-400">
        {zoomPercentage.toFixed(0)}%
      </div>
      <ZoomDropDown />
    </div>
  );
};
