/**
 * Copyright 2021 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useJitsu } from '@jitsu/react';
import React, { useEffect } from 'react';

import { Header } from './Header';
import { CreatorLock, AlertContainer, FullScreenLoading, GlobalModal } from './Overlay';
import { Property } from './Property';
import { Sidebar, SidebarPanel } from './Sidebar';

import { IconScout, IconScoutActive } from '~/assets/icons';
import { TestAnimationDialog, HiddenThumbnailPlayer } from '~/components/Elements/TestAnimationDialog';
import { GB_CONSTANT } from '~/config/growthbook';
import { ClickSource, EventType } from '~/data/eventStore';
import { Canvas } from '~/features/canvas';
import { PluginBrowserDialog } from '~/features/plugin-browser';
import { NewPluginModal } from '~/features/plugin-dev';
import { TimeLine, HiddenTimelineContainer } from '~/features/timeline';
import { getPluginManager, PluginUIType } from '~/plugins';
import { useCreatorStore } from '~/store';
import { GenericPayload } from '~/utils';
import './scrollbar.css';

// CSS rationale:
// Require to set position:relative on #main-content to bound the draggable element
// https://github.com/react-grid-layout/react-draggable/issues/213#issuecomment-480667868
// Tailwind layout experiment playground: https://play.tailwindcss.com/uV5XsPn3eb
const DefaultCreator: React.FC = () => {
  const { id, track } = useJitsu();
  const currentUser = useCreatorStore((state) => state.user.info);

  // Track and set id on first open, or when user changes
  React.useEffect(() => {
    if (currentUser.id && currentUser.email) {
      id({ id: currentUser.id, email: currentUser.email }, true);

      // Flatten userSegments to array of strings
      const segments = currentUser.userSegments?.map((segment) => segment.title);

      track(EventType.StartSession, {
        client: GenericPayload,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.StartSession,
        parameters: {
          email: currentUser.email,
          source: ClickSource.Creator,
          country: currentUser.country,
          userSegments: segments,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          send_to_amplitude: true,
        },
      });
    }
  }, [currentUser.id, currentUser.email, id, track, currentUser.country, currentUser.userSegments]);

  return (
    <div
      id="creator-root"
      className="flex h-full flex-col"
      onContextMenu={(event: MouseEvent) => {
        event.preventDefault();
      }}
    >
      <div className="box-border h-[40px] border-b border-gray-900 bg-gray-800" id="header">
        <Header />
      </div>
      <div className="relative flex flex-1 overflow-hidden">
        <Sidebar />
        <div id="main-content" className="relative flex flex-1 flex-col overflow-hidden">
          <div id="middle-content" className="flex flex-1 overflow-hidden">
            <AlertContainer />

            <SidebarPanel />
            <Canvas />
            <Property />
          </div>
          <HiddenTimelineContainer />
          <TestAnimationDialog />
          <PluginBrowserDialog />
          <NewPluginModal />
          <HiddenThumbnailPlayer />
        </div>
        <TimeLine />
      </div>
      <GlobalModal />
    </div>
  );
};

export const Creator: React.FC = () => {
  const forbiddenUser = useCreatorStore((state) => state.user.forbid);

  // Note: load plugin manager only when iconscout plugin is enabled
  const iconscoutPluginVisiblity = useFeatureIsOn(GB_CONSTANT.SHOW_ICONSCOUT_PLUGIN);

  useEffect(() => {
    if (iconscoutPluginVisiblity) {
      // Note: This is IS's plugin id that's hardcoded temporarily.
      // These values will be fetched from an external resource eventually, and moved outside of this file
      getPluginManager()
        .load('e54b2eef-df61-4758-9d81-c075abf07c59', {
          hasToolbarShortcut: true,
          uiType: PluginUIType.SidebarUI,
          toolbarIcon: <IconScout />,
          toolbarIconActive: <IconScoutActive />,
          eagerName: 'IconScout - Free design assets',
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [iconscoutPluginVisiblity]);

  return (
    <>
      <FullScreenLoading />
      {forbiddenUser && <CreatorLock />}
      {!forbiddenUser && <DefaultCreator />}
    </>
  );
};
