/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { AnimatedPropertyMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapperLayers } from './ContextMenuWrapperLayers';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  eventArg?: unknown;
  isOpen: boolean;
  onClose: () => void;
}

export const AnimatedPropertyMenu: React.FC<ShapeLayerMenuProps> = ({ coord, eventArg, isOpen, onClose }) => {
  return (
    <ContextMenuWrapperLayers
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      eventArg={eventArg}
      dropdownItems={AnimatedPropertyMenuList}
    ></ContextMenuWrapperLayers>
  );
};
