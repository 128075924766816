/**
 * Copyright 2022 Design Barn Inc.
 */

import { useFeature } from '@growthbook/growthbook-react';
import { ShapeType } from '@lottiefiles/toolkit-js';
import React, { useEffect, useState } from 'react';

import { AlignPropertyFullWidth } from '../components/AlignProperty';
import { AppearanceProperty, SingleAppearanceProperty } from '../components/AppearanceProperty';
import { LayerProperty } from '../components/LayerProperty';
import { ShapeProperty } from '../components/ShapeProperty';
import { TransformProperty } from '../components/TransformProperty';
import { Divider } from '../components/ui/Divider';

import { PropertyPanel } from './PropertyPanel';

import { GB_CONSTANT } from '~/config/growthbook';
import { AppearancePropertyPanel } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import { PropertyPanelType, ShapePathMap } from '~/store/constant';

export const ShapePanel: React.FC = () => {
  const property = useCreatorStore((state) => state.ui.currentPropertyPanel);

  const showAlignmentForMultiselect = useFeature(GB_CONSTANT.SHOW_ALIGNMENT_TOOLS_FOR_MULTISELECT).on;

  const displayShapePath =
    property === PropertyPanelType.RectanglePath ||
    property === PropertyPanelType.EllipsePath ||
    property === PropertyPanelType.PolygonPath ||
    property === PropertyPanelType.StarPath;

  const [panelType, setPanelType] = useState<string | null>(null);
  const selectedNodesInfo = useCreatorStore((state) => state.ui.selectedNodesInfo);
  const getLayerSimplifiedUI = useCreatorStore.getState().ui.getLayerSimplifiedUI;
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  useEffect(() => {
    if (selectedNodesInfo.length > 0) {
      const simplifiedUI = getLayerSimplifiedUI(selectedNodesInfo[0]?.nodeId);

      if (!simplifiedUI) return;

      const shapeNode = getNodeByIdOnly(simplifiedUI.belongToNodeId as string);

      if (shapeNode) {
        let _panelType = null;

        if (shapeNode.name.toLowerCase().includes('polygon')) _panelType = PropertyPanelType.PolygonPath;
        else if (shapeNode.name.toLowerCase().includes('star')) _panelType = PropertyPanelType.StarPath;
        else if (shapeNode.type === ShapeType.ELLIPSE) _panelType = PropertyPanelType.EllipsePath;
        else if (shapeNode.type === ShapeType.RECTANGLE) _panelType = PropertyPanelType.RectanglePath;

        if (_panelType) {
          setPanelType(_panelType);
        }
      }
    }
  }, [property, selectedNodesInfo, getLayerSimplifiedUI, getNodeByIdOnly]);

  return (
    <PropertyPanel>
      {(() => {
        if (property === PropertyPanelType.Precomposition) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.Precomposition} />
              <Divider />
            </>
          );
        } else if (property === PropertyPanelType.ShapeLayer) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <TransformProperty property={panelType} />
              <Divider />

              {panelType && (
                <>
                  <ShapeProperty property={panelType} type={panelType} />
                  <Divider />
                </>
              )}

              <LayerProperty type={PropertyPanelType.ShapeLayer} />
              <Divider />
              <AppearanceProperty />
            </>
          );
        } else if (property === PropertyPanelType.MultiObjects) {
          return (
            <>
              {showAlignmentForMultiselect && <AlignPropertyFullWidth enabled={true} />}
              <Divider />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.ShapeLayer} />
              <Divider />
              <AppearanceProperty />
            </>
          );
        } else if (property === PropertyPanelType.Group) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.Group} />
              <Divider />
              <AppearanceProperty />
            </>
          );
        } else if (AppearancePropertyPanel.includes(property)) {
          return <SingleAppearanceProperty />;
        } else if (displayShapePath) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <ShapeProperty property={ShapePathMap[property]} type={property} />
              <Divider />
              <AppearanceProperty />
            </>
          );
        } else {
          return (
            <>
              <AlignPropertyFullWidth enabled={false} />
              <TransformProperty property={property} />
              <Divider />
              <ShapeProperty property={property} type={property} />
              <Divider />
              <AppearanceProperty />
            </>
          );
        }
      })()}
    </PropertyPanel>
  );
};
