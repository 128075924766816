/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable id-length */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, MutableRefObject, MouseEvent, TouchEvent } from 'react';
import React, { useEffect, useRef } from 'react';

import type { TPropsCompAlpha, TCoords } from './types';

const rgbaColor = (r: number, g: number, b: number, a: number): string => {
  return `rgba(${[r, g, b, a / 100].join(',')})`;
};

const Alpha: FC<TPropsCompAlpha> = ({ alpha, color, onChange, rootPrefixCls }) => {
  const node = useRef() as MutableRefObject<HTMLDivElement>;

  const removeListeners = (): void => {
    window.removeEventListener('mousemove', onDrag);
    window.removeEventListener('mouseup', onDragEnd);
  };

  const removeTouchListeners = (): void => {
    window.removeEventListener('touchmove', onTouchMove);
    window.removeEventListener('touchend', onTouchEnd);
  };

  useEffect(() => {
    return () => {
      removeListeners();
      removeTouchListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseDown = (e: MouseEvent): void => {
    const x = e.clientX;
    const y = e.clientY;

    pointMoveTo({
      x,
      y,
    });

    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', onDragEnd);
  };

  const onDrag = (e: any): void => {
    const x = e.clientX;
    const y = e.clientY;

    pointMoveTo({
      x,
      y,
    });
  };

  const onDragEnd = (event: any): void => {
    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({
      x,
      y,
    });

    removeListeners();
  };

  const onTouchStart = (e: TouchEvent): void => {
    if (e.cancelable) {
      e.preventDefault();
    }

    if (e.touches.length !== 1) {
      return;
    }

    removeTouchListeners();

    const x = e.targetTouches[0]?.clientX;
    const y = e.targetTouches[0]?.clientY;

    if (x && y) pointMoveTo({ x, y });

    window.addEventListener('touchmove', onTouchMove, { passive: false });
    window.addEventListener('touchend', onTouchEnd, { passive: false });
  };

  const onTouchMove = (e: any): void => {
    if (e.cancelable) {
      e.preventDefault();
    }

    const x = e.targetTouches[0]?.clientX;
    const y = e.targetTouches[0]?.clientY;

    if (x && y)
      pointMoveTo({
        x,
        y,
      });
  };

  const onTouchEnd = (): void => {
    removeTouchListeners();
  };

  const getBackground = (): string => {
    const { blue, green, red } = color;
    const opacityGradient = `linear-gradient(to right, ${rgbaColor(red, green, blue, 0)} , ${rgbaColor(
      red,
      green,
      blue,
      100,
    )})`;

    return opacityGradient;
  };

  const getPrefixCls = (): string => `${rootPrefixCls}-alpha`;

  const pointMoveTo = (coords: TCoords): void => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!node.current) return;

    const rect = node.current.getBoundingClientRect();
    const width = rect.width;
    let left = coords.x - rect.left;

    left = Math.max(0, left);
    left = Math.min(left, width);

    const alphaValue = Math.round((left / width) * 100);

    onChange(alphaValue);
  };

  const getPointerBackground = (): string => {
    const { blue, green, red } = color;
    const alphaVal = (alpha || 1) / 100;

    return `rgba(${red}, ${green}, ${blue}, ${alphaVal})`;
  };

  const prefixCls = getPrefixCls();

  return (
    <div className={prefixCls} ref={node} onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
      <div className={`${prefixCls}-bg`} style={{ background: getBackground() }} />
      <span
        style={{
          left: `${alpha}%`,
          backgroundColor: getPointerBackground(),
        }}
      />
      <div className={`${prefixCls}-handler`} />
    </div>
  );
};

export default Alpha;
