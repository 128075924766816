/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect } from 'react';

export const ScriptContext = React.createContext(null);

interface Props {
  children?: React.ReactNode;
}

export const ScriptProvider: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    // Load Microsoft clarity on v1 creator only

    /* eslint-disable-next-line @typescript-eslint/prefer-includes */
    if (window.location.href.indexOf('v1-workshop.lottiefiles.dev') > -1) {
      const script = document.createElement('script');

      // https://clarity.microsoft.com/projects/view/d2c45hsqoy/gettingstarted/trackingCode
      script.textContent = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "d2c45hsqoy");`;
      script.setAttribute('type', 'text/javascript');

      document.head.appendChild(script);
    }
  }, []);

  return <ScriptContext.Provider value={null}>{children}</ScriptContext.Provider>;
};
