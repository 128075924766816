/**
 * Copyright 2023 Design Barn Inc.
 */

import { useJitsu } from '@jitsu/react';
import clsx from 'clsx';
import React, { useCallback } from 'react';

import { KeyframeIcon } from '~/assets/icons';
import { ClickSource, EventType } from '~/data/eventStore';
import { GenericPayload } from '~/utils';

interface Props {
  hasKeyframe?: boolean;
  isTransparent?: boolean;
  onClick?: () => void;
}

const KeyframeButton: React.FC<Props> = ({ hasKeyframe = false, isTransparent = false, onClick }) => {
  const { track } = useJitsu();

  const handleOnClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();

      track(EventType.AddedKeyframe, {
        client: GenericPayload,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.AddedKeyframe,
        parameters: {
          // Conveniently, the keyframe button is transparent from timeline but not on property panel.
          // Just reusing this bool for now, but should probably rename it later
          source: isTransparent ? ClickSource.Timeline : ClickSource.PropertyPanel,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          send_to_amplitude: true,
        },
      });

      if (onClick) {
        onClick();
      }
    },
    [isTransparent, onClick, track],
  );

  return (
    <button onClick={handleOnClick} data-testid="keyframe-btn">
      <KeyframeIcon
        className={clsx('h-4 w-4', {
          'fill-teal-200 stroke-teal-200 hover:fill-teal-400 hover:stroke-teal-400': hasKeyframe && isTransparent,
          'fill-teal-200 stroke-gray-800 hover:fill-teal-400': hasKeyframe && !isTransparent,
          'stroke-gray-500 hover:stroke-teal-400': !hasKeyframe && isTransparent,
          'stroke-gray-500 fill-gray-800 hover:stroke-teal-400': !hasKeyframe && !isTransparent,
        })}
      />
    </button>
  );
};

export default KeyframeButton;
