/**
 * Copyright 2022 Design Barn Inc.
 */

import { Resizable } from 're-resizable';
import React, { useCallback } from 'react';

import { LAYER_PANEL_DEFAULT_WIDTH, TIMELINE_BOTTOM_GUTTER } from '../constant';

import LayersPanel from './Layers';

import { useCreatorStore } from '~/store';

// Synchronize scrolling example https://codesandbox.io/s/8wvi7?file=/src/index.js
const LayerContent: React.FC = () => {
  const height = useCreatorStore((state) => state.timeline.height) - TIMELINE_BOTTOM_GUTTER;
  const setPreventScrollEvent = useCreatorStore.getState().timeline.setPreventScrollEvent;

  const handleOnLayerScroll = useCallback(
    (evt: React.UIEvent<HTMLDivElement>) => {
      // Manually query to prevent React rerender
      if (useCreatorStore.getState().timeline.preventScrollEvent) {
        setPreventScrollEvent(false);

        return;
      }

      const keyframePanel = document.getElementById('keyframe-panel');

      if (keyframePanel) {
        // Sync scroll layer panel scroll position with keyframe panel
        setPreventScrollEvent(true);
        keyframePanel.scrollTop = evt.currentTarget.scrollTop;
      }
    },
    [setPreventScrollEvent],
  );

  return (
    <div id="layer-panel" className="overflow-y-auto" style={{ height }} onScroll={handleOnLayerScroll}>
      <LayersPanel />
    </div>
  );
};

export const TimelineLayerPanel: React.FC = () => {
  return (
    <Resizable
      id="timeline-layer-panel"
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      maxWidth="90%"
      defaultSize={{ width: LAYER_PANEL_DEFAULT_WIDTH, height: '100%' }}
    >
      <div className="mt-[2px] h-[17px] items-center text-caption font-medium text-gray-300">Layers</div>
      <LayerContent />
    </Resizable>
  );
};
