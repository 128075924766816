/**
 * Copyright 2023 Design Barn Inc.
 */

import type {
  EllipseShape,
  PathShape,
  RectangleShape,
  RoundedCornersShape,
  StarShape,
  CubicBezierShape,
} from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  toPathShape: 'toPathShape',
  toBezier: 'toBezier',
};

export function getDrawableShapeMethods(
  plugin: Plugin,
  shape: CubicBezierShape | EllipseShape | PathShape | RectangleShape | RoundedCornersShape | StarShape,
): ObjectMethods {
  return getObjectMethods(plugin, methodNames, shape);
}
