/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';

export interface SupportFeatureContextType {
  error: null;
  isOpen: boolean;
  onClose: () => void;
  openAnywayCallback: () => void;
  openLabel: string;
  options?: Record<string, string | boolean>;
  revertCallback: () => void;
  revertLabel: string;
  setError: (error: null) => void;
}

const save: SupportFeatureContextType = {
  isOpen: false,
  error: null,
  onClose: () => {},
  setError: (_) => {},
  openLabel: 'Insert anyway',
  revertLabel: 'Insert another',
  openAnywayCallback: () => {},
  revertCallback: () => {},
};

export const SupportFeatureContext = React.createContext<SupportFeatureContextType>(save);
