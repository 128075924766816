/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, RectangleShape, Scene, SizeJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';

import { AnimatedSizeW, AnimatedSizeH } from '~/assets/icons';
import { TimelineAspectRatioToggle } from '~/components/Elements/Button/TimelineAspectRatioToggle';
import { TimelineDualNumberInput } from '~/components/Elements/Input/TimelineDualNumberInput';
import type { NumberInputRatioResult } from '~/components/Elements/Input/useDualNumberInputRatio';
import { useDualNumberInputRatio } from '~/components/Elements/Input/useDualNumberInputRatio';
import { MIN_SHAPE_WIDTH, MIN_SHAPE_HEIGHT, MAX_SHAPE_WIDTH, MAX_SHAPE_HEIGHT } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit, setShapeSize, getNodeById } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedSize: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const animatedValue = animatedProp.value as SizeJSON | null;
  const { h: height, w: width } = animatedValue ? animatedValue : { w: 250, h: 250 };
  // TODO: handle multi select
  const [sceneIndex, sizeRatioLock, selectedNodeId] = useCreatorStore(
    (state) => [state.toolkit.sceneIndex, state.ui.sizeRatioLocked, state.ui.selectedNodesInfo[0]?.nodeId as string],
    shallow,
  );

  const handleOnChange = useCallback(
    (result: NumberInputRatioResult) => {
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id) as AVLayer;

      if (node.parent) {
        if (result.type === 'both') {
          const value = (result.value as unknown) as [number, number];

          setShapeSize(node.parent as RectangleShape, value);
        } else if (result.type === 'width') {
          setShapeSize(node.parent as RectangleShape, [result.value as number, height]);
        } else if (result.type === 'height') {
          setShapeSize(node.parent as RectangleShape, [width, result.value as number]);
        }
        emitter.emit(EmitterEvent.SHAPE_SIZE_UPDATED);
      }
    },
    [width, height, sceneIndex, animatedProp],
  );

  const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id);
  const parentNode = node?.parent;
  const sizeRatioLocked = selectedNodeId === parentNode?.nodeId && sizeRatioLock;

  const { handleInputChange, handleOnClickAspectRatio, isLocked } = useDualNumberInputRatio({
    left: width,
    leftName: 'width',
    right: height,
    onChange: handleOnChange,
    ratioLocked: sizeRatioLocked,
  });

  const toggleAspectRatioLock = useCallback(() => {
    handleOnClickAspectRatio();
    if (node) {
      emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
        event: EmitterEvent.TOOLKIT_NODE_SIZE_UPDATED,
        data: {
          node: parentNode,
          sizeRatioLock: !isLocked,
        },
      });
    }
  }, [handleOnClickAspectRatio, isLocked, node, parentNode]);

  return (
    <div className="flex items-center">
      {parentNode && <TimelineAspectRatioToggle isLocked={isLocked} onClick={toggleAspectRatioLock} />}

      <TimelineDualNumberInput
        onChange={handleInputChange}
        left={width}
        right={height}
        leftOption={{
          name: 'width',
          label: <AnimatedSizeW className="h-3 w-3" />,
          precision: 0,
        }}
        rightOption={{
          name: 'height',
          label: <AnimatedSizeH className="h-3 w-3" />,
          precision: 0,
        }}
        leftMin={MIN_SHAPE_WIDTH}
        leftMax={MAX_SHAPE_WIDTH}
        rightMin={MIN_SHAPE_HEIGHT}
        rightMax={MAX_SHAPE_HEIGHT}
      />
    </div>
  );
};
