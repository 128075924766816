/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  DagNode,
  PercentageJSON,
  AnimatedPropertiesJSON,
  ColorStopJSON,
  GradientJSON,
  VectorJSON,
  AngleJSON,
  ValueJSON,
} from '@lottiefiles/toolkit-js';
import { ShapeType, GroupShape, GradientFillShape, GradientFillType } from '@lottiefiles/toolkit-js';
import { cloneDeep } from 'lodash-es';

import type { AppGFill } from '~/lib/toolkit';

export const GradientPoint = {
  StartX: 'StartX',
  StartY: 'StartY',
  EndX: 'EndX',
  EndY: 'EndY',
};

export const GradientFillMapping = {
  None: GradientFillType.NONE,
  Linear: GradientFillType.LINEAR,
  Radial: GradientFillType.RADIAL,
  Angular: GradientFillType.ANGULAR,
  Reflected: GradientFillType.REFLECTED,
  Diamond: GradientFillType.DIAMOND,
};

export const GradientFillTypes = Object.keys(GradientFillMapping);

export interface CurrentGFillShape {
  alpha: number;
  animatedProperties: AnimatedPropertiesJSON | null;
  bm: number;
  cssColor: string;
  fillRule: number;
  g: ColorStopJSON[];
  geX: number;
  geY: number;
  gsX: number;
  gsY: number;
  ha: number;
  hl?: ValueJSON;
  id: string | null;
  opacity: number;
  type: string;
}

export const getGradientFillShape = (node: DagNode | null): GradientFillShape | null => {
  if (node instanceof GroupShape) {
    const shape = node.shapes.find((sh) => sh.type === ShapeType.GRADIENT_FILL);

    if (shape instanceof GradientFillShape) {
      return shape;
    }
  } else if (node instanceof GradientFillShape) {
    return node;
  }

  return null;
};

export const getGradientFillShapes = (node: DagNode | null): GradientFillShape[] | null => {
  if (node instanceof GroupShape) {
    const shapes = node.shapes.filter((sh) => sh.type === ShapeType.GRADIENT_FILL) as GradientFillShape[];

    if (shapes.length > 0 && shapes[0] instanceof GradientFillShape) {
      return shapes;
    }
  } else if (node instanceof GradientFillShape) {
    return [node];
  }

  return null;
};

export const defaultCurrentGFillShape: CurrentGFillShape = {
  cssColor: 'linear-gradient(180deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)',
  alpha: 1,
  opacity: 100,
  fillRule: 1,
  id: null,
  bm: 0,
  animatedProperties: null,
  g: [],
  geX: 0,
  geY: 0,
  gsX: 0,
  gsY: 0,
  ha: 90,
  type: GradientFillTypes[1] as string,
};

export const convertToGradientCss = (colors: ColorStopJSON[]): string => {
  if (colors.length > 0) {
    let gradientStr = '';

    colors.forEach((color: ColorStopJSON, colorIndex: number) => {
      const isLast = colorIndex === colors.length - 1;
      const rgbaL = `rgba(${color.r},${color.g},${color.b},${color.a}) ${color.offset}%`;

      gradientStr = `${gradientStr}${rgbaL}`;
      if (!isLast) gradientStr = `${gradientStr},`;
    });

    return `linear-gradient(180deg, ${gradientStr})`;
  }

  return 'black';
};

export const getCurrentGFillShape = (node: GradientFillShape | null): CurrentGFillShape => {
  const currentGFillShape: CurrentGFillShape = { ...defaultCurrentGFillShape };

  if (node instanceof GradientFillShape) {
    const gFillState = node.state;

    // Temporarily set to Linear
    currentGFillShape.type = GradientFillTypes[1] as string;

    currentGFillShape.id = gFillState.id as string;

    currentGFillShape.bm = gFillState.properties.bm as number;
    currentGFillShape.opacity = (gFillState.animatedProperties.o.value as PercentageJSON).pct;
    currentGFillShape.fillRule = gFillState.properties.flr as number;
    currentGFillShape.animatedProperties = cloneDeep(gFillState.animatedProperties as AnimatedPropertiesJSON);

    // Color
    const colors = ((gFillState.animatedProperties as AnimatedPropertiesJSON).g.value as GradientJSON).colors;

    currentGFillShape.g = colors;

    // End point
    currentGFillShape.geX = ((gFillState.animatedProperties as AnimatedPropertiesJSON).ge.value as VectorJSON).x;
    currentGFillShape.geY = ((gFillState.animatedProperties as AnimatedPropertiesJSON).ge.value as VectorJSON).y;

    // Start point
    currentGFillShape.gsX = ((gFillState.animatedProperties as AnimatedPropertiesJSON).gs.value as VectorJSON).x;
    currentGFillShape.gsY = ((gFillState.animatedProperties as AnimatedPropertiesJSON).gs.value as VectorJSON).y;

    // Highlight Angle, relative to the direction from gs to ge
    const angle = ((gFillState.animatedProperties as AnimatedPropertiesJSON).ha.value as AngleJSON).deg;

    currentGFillShape.ha = angle;

    // Highlight Length, as a percentage between gs and ge
    currentGFillShape.hl = (gFillState.animatedProperties as AnimatedPropertiesJSON).hl.value as ValueJSON;

    // TESTING: Temporarily implementation
    currentGFillShape.cssColor = convertToGradientCss(colors);
  }

  return currentGFillShape;
};

export const getCurrentGFillShapes = (node: DagNode | null): AppGFill => {
  const fillShapes = getGradientFillShapes(node);

  let currentGradientFill = {};

  if (fillShapes && fillShapes.length > 0) {
    currentGradientFill = fillShapes
      .map((fs: GradientFillShape) => getCurrentGFillShape(fs))
      .reduce((fShapes: AppGFill, obj: CurrentGFillShape) => {
        if (obj.id) fShapes[obj.id] = { ...obj };

        return fShapes;
      }, {});
  }

  return currentGradientFill;
};
