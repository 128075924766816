/**
 * Copyright 2023 Design Barn Inc.
 */

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

import pkg from '~/../package.json';

type ReleaseStage = 'development' | 'staging' | 'production' | 'local';

let releaseStage: ReleaseStage = 'local';

const { hostname } = window.location;

if (hostname.endsWith('.com')) {
  releaseStage = 'production';
} else if (hostname.endsWith('.io')) {
  releaseStage = 'staging';
} else if (hostname.endsWith('.dev') && !hostname.split('.').includes('local')) {
  releaseStage = 'development';
}

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: pkg.version,
    releaseStage,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['staging', 'production'],
  });
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;

export { ErrorBoundary };
