/**
 * Copyright 2023 Design Barn Inc.
 */

import { useJitsu } from '@jitsu/react';
import React from 'react';

import CreatorFaqFramer from './CreatorFaqFramer';
import CreatorTutorialFramer from './CreatorTutorialFramer';
import WhatsNewFramer from './WhatsNewFramer';

import GetStartedChart from '~/assets/animations/get-started-chart.json';
import GetStartedSparkle from '~/assets/animations/get-started-sparkles.json';
import GetStartedToggle from '~/assets/animations/get-started-toggle.json';
import { LottieAnimationLightGray } from '~/assets/icons';
import { Bell, Chat, Cow } from '~/assets/illustrations/GettingStartedSVGs';
import { AssetSource, EventType } from '~/data/eventStore';
import { uploadJSON, uploadSVG, UploadComputerPanel } from '~/features/upload';
import { useCreatorStore } from '~/store';
import { GenericPayload } from '~/utils';

const LottieAnimationsList = [
  {
    name: 'Toggle',
    lottie: GetStartedToggle,
  },
  {
    name: 'Chart',
    lottie: GetStartedChart,
  },
  {
    name: 'Sparkle',
    lottie: GetStartedSparkle,
  },
];

const SvgList = [
  {
    name: 'Bell',
    svg: <Bell />,
    svgText: `<svg width="151" height="163" viewBox="0 0 151 163" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2911_379" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="2" width="146" height="158">
    <path d="M148.176 2.31641H3.69104V159.845H148.176V2.31641Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_2911_379)">
    <path d="M75.9938 159.826C87.0768 159.826 96.0614 150.842 96.0614 139.759C96.0614 128.676 87.0768 119.691 75.9938 119.691C64.9112 119.691 55.9266 128.676 55.9266 139.759C55.9266 150.842 64.9112 159.826 75.9938 159.826Z" fill="#B18500"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.672 71.7084V100.45C125.672 105.472 128.956 109.728 133.495 111.182H133.878C141.775 111.182 148.176 117.584 148.176 125.48C148.176 133.377 141.775 139.778 133.878 139.778H17.989C10.0925 139.778 3.69104 133.377 3.69104 125.48C3.69104 117.584 10.0925 111.182 17.989 111.182H18.5021C23.0421 109.728 26.329 105.472 26.329 100.45V71.7084C26.329 48.4645 42.2901 28.9569 63.8519 23.5367C62.579 21.3755 61.908 18.9125 61.9101 16.4043C61.9101 8.62508 68.2186 2.31641 75.9983 2.31641C83.7784 2.31641 90.0865 8.62508 90.0865 16.4043C90.0886 18.9125 89.418 21.3755 88.1446 23.5367C109.706 28.9569 125.672 48.4645 125.672 71.7084ZM70.3636 16.4047C70.3636 19.5156 72.888 22.0356 75.9987 22.0356C79.1098 22.0356 81.6338 19.5152 81.6338 16.4047C81.6338 13.2893 79.1098 10.7693 75.9987 10.7693C72.888 10.7693 70.3636 13.2893 70.3636 16.4047Z" fill="#FFBF00"/>
    <path d="M133.878 111.184H17.989C10.0925 111.184 3.69104 117.585 3.69104 125.482C3.69104 133.378 10.0925 139.78 17.989 139.78H133.878C141.775 139.78 148.176 133.378 148.176 125.482C148.176 117.585 141.775 111.184 133.878 111.184Z" fill="#FEDE7E"/>
    </g>
    </svg>
    `,
  },
  {
    name: 'Cow',
    svg: <Cow />,
    svgText: `<svg width="150" height="143" viewBox="0 0 150 143" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40051 20.2734H36.9874V43.9982H20.3668C13.799 43.9982 6.96305 38.9048 5.08655 32.6049L1.40051 20.2734Z" fill="#979797"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M146.158 20.2734H110.571V43.9982H127.191C133.759 43.9982 140.595 38.9048 142.471 32.6049L146.158 20.2734Z" fill="#979797"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.9783 7.27328C36.9783 3.98934 39.5919 1.30859 42.9431 1.30859C46.2271 1.30859 48.9077 3.98934 48.9077 7.27328V22.6876H36.9783V7.27328ZM98.7025 7.27328C98.7025 3.98934 101.316 1.30859 104.667 1.30859C107.951 1.30859 110.632 3.98934 110.632 7.27328V22.6876H98.7695V7.27328H98.7025Z" fill="#9C4D4D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6457 20.2734H110.578L117.682 81.9977H27.4747L34.6457 20.2734Z" fill="#D8D8D8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7141 91.5153C22.7141 82.3338 30.1532 74.8945 39.3348 74.8945H105.75C114.932 74.8945 122.371 82.3338 122.371 91.5153V110.549C122.371 127.572 108.565 141.377 91.4752 141.377H53.4758C36.4529 141.377 22.5801 127.572 22.5801 110.549V91.5153H22.7141Z" fill="#FF78C7"/>
    <path d="M93.9418 117.65C99.1976 117.65 103.458 113.39 103.458 108.134C103.458 102.878 99.1976 98.6172 93.9418 98.6172C88.6859 98.6172 84.4252 102.878 84.4252 108.134C84.4252 113.39 88.6859 117.65 93.9418 117.65Z" fill="#E43535"/>
    <path d="M51.1896 117.651C56.4458 117.651 60.7065 113.39 60.7065 108.134C60.7065 102.878 56.4458 98.6172 51.1896 98.6172C45.9337 98.6172 41.673 102.878 41.673 108.134C41.673 113.39 45.9337 117.651 51.1896 117.651Z" fill="#E43535"/>
    <path d="M58.3716 63.0322C60.9995 63.0322 63.1299 60.9018 63.1299 58.2739C63.1299 55.646 60.9995 53.5156 58.3716 53.5156C55.7436 53.5156 53.6133 55.646 53.6133 58.2739C53.6133 60.9018 55.7436 63.0322 58.3716 63.0322Z" fill="#595959"/>
    <path d="M86.8467 63.0322C89.4746 63.0322 91.605 60.9018 91.605 58.2739C91.605 55.646 89.4746 53.5156 86.8467 53.5156C84.2187 53.5156 82.0884 55.646 82.0884 58.2739C82.0884 60.9018 84.2187 63.0322 86.8467 63.0322Z" fill="#595959"/>
    </svg>
    `,
  },
  {
    name: 'Chat',
    svg: <Chat />,
    svgText: `<svg width="150" height="137" viewBox="0 0 150 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2911_369" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="1" width="147" height="134">
    <path d="M146.997 1.94922H0.492676V134.417H146.997V1.94922Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_2911_369)">
    <path d="M69.3525 22.5234C32.7705 22.5234 3.16272 47.0433 3.16272 77.2212C3.16272 92.003 10.2686 105.425 21.8047 115.295L8.99655 133.323C8.99655 133.323 25.0506 134.244 35.7971 124.374C45.6665 129.199 57.1149 131.963 69.3965 131.963C105.979 131.963 135.586 107.487 135.586 77.2648C135.586 47.0429 105.891 22.5234 69.3525 22.5234Z" fill="#2A94F4"/>
    <path d="M126.463 105.073L141.464 126.215C141.464 126.215 122.383 127.312 110.409 115.161C106.768 116.74 102.952 118.056 99.0481 118.977C93.7404 120.249 88.1696 120.951 82.3796 120.951C47.64 120.951 19.4358 96.1686 19.4358 65.5519C19.4358 59.1479 20.664 52.9632 22.9449 47.2168C31.5421 25.6361 54.8775 10.1523 82.336 10.1523C117.076 10.1523 145.28 34.9352 145.28 65.5519C145.324 81.0357 138.13 95.0279 126.463 105.073Z" fill="#CCE9FF"/>
    <path d="M60.3746 70.0699C65.3311 70.0699 69.3668 66.0346 69.3668 61.0781C69.3668 56.1216 65.3311 52.0859 60.3746 52.0859C55.4182 52.0859 51.3828 56.1216 51.3828 61.0781C51.3828 66.0346 55.3742 70.0699 60.3746 70.0699Z" fill="#2A94F4"/>
    <path d="M84.751 70.0699C89.7075 70.0699 93.7431 66.0346 93.7431 61.0781C93.7431 56.1216 89.7075 52.0859 84.751 52.0859C79.7945 52.0859 75.7592 56.1216 75.7592 61.0781C75.7592 66.0346 79.7505 70.0699 84.751 70.0699Z" fill="#2A94F4"/>
    <path d="M109.141 70.0699C114.097 70.0699 118.133 66.0346 118.133 61.0781C118.133 56.1216 114.097 52.0859 109.141 52.0859C104.184 52.0859 100.149 56.1216 100.149 61.0781C100.149 66.0346 104.14 70.0699 109.141 70.0699Z" fill="#2A94F4"/>
    </g>
    </svg>`,
  },
];

export const GettingStartedSidePanel: React.FC = () => {
  const timelineHeight = useCreatorStore((state) => state.timeline.height);
  const { track } = useJitsu();

  return (
    <div
      style={{
        height: `calc(100vh - ${timelineHeight}px)`,
        minWidth: '238px',
      }}
      className=" flex  flex-col overflow-y-auto border-l border-gray-900 dark:bg-gray-800"
    >
      <div className="getting-started-grid mb-16 w-[238px] overflow-x-hidden ">
        <div className="mb-2 flex border-b border-gray-900 px-3 text-white">
          <div className="py-2 text-xs font-semibold">Let&apos;s get started with Lottie Creator</div>
        </div>
        <div className="mb-2 flex items-center px-3 text-xs text-gray-300">Insert asset</div>
        <div className="flex justify-between px-3 pb-2">
          {SvgList.map((svg) => {
            return (
              <div
                key={svg.name}
                className="h-[66px] w-[66px] cursor-pointer rounded bg-[#1F2429] px-[14px]"
                onClick={async () => {
                  await uploadSVG(svg.svgText);
                  // Send analytics event
                  track(EventType.UploadedAsset, {
                    client: GenericPayload,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    event_type: EventType.UploadedAsset,
                    parameters: {
                      source: AssetSource.GetStartedStatic,
                      'file-type': 'svg',
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      send_to_amplitude: true,
                    },
                  });
                }}
              >
                {React.cloneElement(svg.svg)}
              </div>
            );
          })}
        </div>
        <div className="flex w-full justify-between border-b border-gray-900 px-3 pb-4">
          {LottieAnimationsList.map((animation) => {
            return (
              <div
                key={animation.name}
                className="relative h-[66px] w-[66px] cursor-pointer rounded  bg-[#1F2429] "
                onClick={async () => {
                  await uploadJSON(animation.lottie);
                  // Send analytics event
                  track(EventType.UploadedAsset, {
                    client: GenericPayload,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    event_type: EventType.UploadedAsset,
                    parameters: {
                      source: AssetSource.GetStartedJSON,
                      'file-type': 'json',
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      send_to_amplitude: true,
                    },
                  });
                }}
              >
                <dotlottie-player
                  renderer="svg"
                  autoplay
                  loop
                  className="h-20 w-20"
                  src={JSON.stringify(animation.lottie)}
                />
                <div className="absolute bottom-[-4px] left-[-3px] h-[14px] w-[14px] rounded-full bg-[#3D4852] p-[2px]">
                  <LottieAnimationLightGray />
                </div>
              </div>
            );
          })}
        </div>
        <div className=" border-b border-gray-900">
          <div className="mt-2 flex items-center px-3 text-xs text-gray-300">Upload asset</div>
          <UploadComputerPanel />
        </div>
        <div className=" border-b  border-gray-900 px-3 pt-3">
          <CreatorTutorialFramer />
        </div>
        <div className=" border-b  border-gray-900 px-3 pt-3">
          <WhatsNewFramer />
        </div>
        <div className=" px-3 pt-3">
          <CreatorFaqFramer />
        </div>
      </div>
    </div>
  );
};
