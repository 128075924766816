/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene, Toolkit } from '@lottiefiles/toolkit-js';
import { saveAs } from 'file-saver';

import { ExportType } from '~/data/constant';
import saveToWorkspaceService from '~/lib/saveToWorkspace';
import { useCreatorStore } from '~/store';

export const exportToolkitJson = async (
  toolkit: Toolkit,
  scene: Scene,
  pluginName: string = 'com.lottiefiles.lottie',
): Promise<unknown> => {
  const result = await toolkit.export(pluginName, {
    scene,
    filePath: 'LottieFiles.lottie',
  });

  return Promise.resolve(result);
};

export const exportToolkit = async (toolkit: Toolkit, scene: Scene, type = ExportType.JSON): Promise<unknown> => {
  const lottieJSON = await exportToolkitJson(toolkit, scene);

  const lottieName = lottieJSON.nm;
  const fileName = useCreatorStore.getState().project.info.name || 'Untitled file';

  if (type === ExportType.JSON && lottieJSON) {
    const filename = `${lottieName || fileName}.json`;
    const blob = new Blob([JSON.stringify(lottieJSON)], { type: 'text/plain;charset=utf-8' });

    saveAs(blob, filename);
  }

  if (type === ExportType.DotLottie) {
    const dotLottieJSON = await exportToolkitJson(toolkit, scene, 'com.lottiefiles.dotlottie');

    const filename = `${lottieName || fileName}.lottie`;
    const blob = new Blob([dotLottieJSON as BlobPart]);

    saveAs(blob, filename);
  }

  return Promise.resolve(lottieJSON);
};

export const exportToWorkspace = async (toolkit: Toolkit, scene: Scene): Promise<unknown> => {
  const lottieJSON = await exportToolkitJson(toolkit, scene);
  const blob = new Blob([JSON.stringify(lottieJSON)], { type: 'text/javascript;charset=utf-8' });

  let lottieSavedUrl = null;
  const form = new FormData();

  const { nm } = lottieJSON as Record<string, string>;

  const name = nm || 'new-lottie-';
  const filename = `${name}-${new Date()}.json`;

  form.append('file', blob, filename);

  try {
    const response = await saveToWorkspaceService({
      data: form,
    });

    if (response.data && response.data.payload) {
      lottieSavedUrl = response.data.payload.data_file;

      return Promise.resolve(lottieSavedUrl);
    }
  } catch (ex) {
    return Promise.resolve(null);
  }

  return Promise.resolve(lottieSavedUrl);
};
