/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Color } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from '.';

const methodNames = {
  complementaryColor: 'complementaryColor',
  hex3: 'hex3',
  hex4: 'hex4',
  hex6: 'hex6',
  hex8: 'hex8',
  hsl: 'hsl',
  luminance: 'luminance',
  name: 'name',
  rgb: 'rgb',
  rgba: 'rgba',
  brighten: 'brighten',
  clone: 'clone',
  contrast: 'contrast',
  darken: 'darken',
  desaturate: 'desaturate',
  distance: 'distancce',
  equals: 'equals',
  interpolate: 'interpolate',
  saturate: 'saturate',
  set: 'set',
  setAlpha: 'setAlpha',
  setBlue: 'setBlue',
  setGreen: 'setGreen',
  setRed: 'setRed',
  toArray: 'toArray',
};

function getColorMethods(plugin: Plugin, color: Color): ObjectMethods {
  return getObjectMethods(plugin, methodNames, color);
}

interface ColorObject extends ValueObject {
  alpha: number;
  blue: number;
  green: number;
  red: number;
}

export function createColorObject(plugin: Plugin, color: Color): ColorObject {
  const colorObject = {
    alpha: color.alpha,
    blue: color.blue,
    green: color.green,
    red: color.red,
    valueType: 'Color',
  };

  registerObjectMethods(plugin, color, colorObject, [getColorMethods]);

  return colorObject;
}
