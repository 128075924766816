/**
 * Copyright 2021 Design Barn Inc.
 */

export const USER_VIEWER_QUERY = `
    query {
        viewer {
            id
            email
            name
            avatarUrl
            country
            userSegments {
                title
            }
        }
    }
`;

export const USER_WHITELIST_QUERY = `
    query userHasCreatorAccess {
        userHasCreatorAccess
    }
`;
