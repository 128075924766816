/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ColorJSON, ScalarJSON } from '@lottiefiles/toolkit-js';
import type { IUniform } from 'three';

export interface GroupProperty {}

export interface RectangleProperty {
  color: ColorJSON;
  drawOrder: number;
  height: IUniform<number>;
  positionX: IUniform<number>;
  positionY: IUniform<number>;
  radius: IUniform<number>;
  width: IUniform<number>;
}

export interface EllipseProperty {
  height: IUniform<number>;
  positionX: IUniform<number>;
  positionY: IUniform<number>;
  width: IUniform<number>;
}

export interface PolystarProperty {
  innerRadius: IUniform<number>;
  outerRadius: IUniform<number>;
  points: IUniform<number>;
  positionX: IUniform<number>;
  positionY: IUniform<number>;
  type: IUniform<number>;
}

export interface StrokeProperty {
  color: ColorJSON;
  // dash: {
  //   gap: number;
  //   offset: number;
  //   scale: number;
  //   size: number;
  // };
  opacity: number;
  width: ScalarJSON;
}

export interface TransformProperty {
  anchorX: IUniform<number>;
  anchorY: IUniform<number>;
  opacity: IUniform<number>;
  positionX: IUniform<number>;
  positionY: IUniform<number>;
  rotation: IUniform<number>;
  scaleX: IUniform<number>;
  scaleY: IUniform<number>;
  skew: IUniform<number>;
  skewAngle: IUniform<number>;
}

export enum GradientType {
  Linear = 'Linear',
  Radical = 'Radical',
}

export interface GradientFillProperty {
  colors: Array<{
    a: number;
    b: number;
    g: number;
    r: number;
  }>;
  endX: IUniform<number>;
  endY: IUniform<number>;
  gradientType: { value: GradientType };
  highlight: IUniform<number>;
  highlightAngle: IUniform<number>;
  opacity: IUniform<number>;
  startX: IUniform<number>;
  startY: IUniform<number>;
}

export type ObjectProperty =
  | GroupProperty
  | RectangleProperty
  | EllipseProperty
  | PolystarProperty
  | StrokeProperty
  | TransformProperty;
