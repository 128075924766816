/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode, Scene, SceneJSON } from '@lottiefiles/toolkit-js';
import { debounce } from 'lodash-es';
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import type { DraggableEvent, DraggableData } from 'react-draggable';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { TIMELINE_BAR_BEGIN_OFFSET_PX, TIMELINE_SCRUBBER_DEBOUNCE_MS } from '../constant';

import { DragContext } from './Context/DragContext';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface Props {}

export const TimelineScrubber: React.FC<Props> = () => {
  const { playheadPos, setPlayheadNodeRef, setPlayheadPos } = useContext(DragContext);
  const scrubberRef = useRef(null);
  const scrubberNodeRef = useRef(null);

  const [
    fps,
    currentFrame,
    duration,
    selectedPrecompositionJson,
    precompFps,
    precompDuration,
    precompCurrentFrame,
    scrubberDrag,
    setScrubberDrag,
    selectedPrecompositionId,
    sceneIndex,
    setCurrentFrame,
    setSelectedPrecompositionJson,
  ] = useCreatorStore(
    (state) => [
      (state.toolkit.json?.timeline.properties.fr as number) || 30,
      (state.toolkit.json?.timeline.properties.cf as number) || 0,
      state.toolkit.json?.timeline.duration as number,
      state.toolkit.selectedPrecompositionJson,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.fr as number,
      state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.cf as number,
      state.timeline.scrubberDrag,
      state.timeline.setScrubberDrag,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.sceneIndex,
      state.toolkit.setCurrentFrame,
      state.toolkit.setSelectedPrecompositionJson,
    ],
    shallow,
  );

  const width = document.getElementById('keyframe-scrubber')?.clientWidth ?? 1;

  // Compute current position pixel based on current frame
  const [totalFrames, setTotalFrames] = useState(fps * duration);

  useEffect(() => {
    const totalFramesCalculated =
      (selectedPrecompositionJson ? precompFps : fps) * (selectedPrecompositionJson ? precompDuration : duration);

    const xPosCalculated =
      ((selectedPrecompositionJson ? precompCurrentFrame : currentFrame) / totalFramesCalculated) *
      (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX);

    setTotalFrames(totalFramesCalculated);
    setPlayheadPos(xPosCalculated);
  }, [
    setPlayheadPos,
    playheadPos,
    currentFrame,
    duration,
    fps,
    precompCurrentFrame,
    precompDuration,
    precompFps,
    selectedPrecompositionJson,
    width,
  ]);

  const getLayerUI = useCreatorStore((state) => state.ui.getLayerUI);

  // Debounce 10 ms
  const handleOnDrag = debounce((evt: DraggableEvent, data: DraggableData): void => {
    evt.preventDefault();
    const { x } = data;

    // TODO: Zoom
    const xStart = 0;
    const x1 = x + xStart;
    let widthRatio = Math.abs(x1 / (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX));

    // Compute Current Frame

    // Width ratio can't be exceed 1
    widthRatio = Math.min(1, widthRatio);

    let newFrame = Math.round(widthRatio * totalFrames);

    // Holding down the shift key while dragging the scrubber will make it snap
    // to **visible** keyframes only. Visible as in visible from the timeline.
    // Keyframes that belong to unexpanded layers will not be considered.
    if (evt.shiftKey) {
      let timeline;

      if (selectedPrecompositionId) {
        timeline = toolkit.scenes[sceneIndex]?.getNodeById(selectedPrecompositionId)?.timeline;
      } else {
        timeline = toolkit.scenes[sceneIndex]?.timeline;
      }

      if (timeline) {
        const visibleKeyFrameFrames = timeline.tracks
          // Only use tracks with keyframes
          .filter((track) => track.keyFrames.length > 0)
          // filter out tracks that belong to animated properties of unexpanded layers
          .filter((track) => {
            if (track.property.parent) {
              let layerUI = getLayerUI(track.property.parent.nodeId);

              if (!layerUI) {
                return false;
              }

              if (layerUI.appearanceType === 'PRECOMPOSITION') {
                return true;
              }
              // If the layerUI object for the node that the animated property
              // is attached to has no children, it means that node isn't
              // collapsible
              if (layerUI.children.length === 0) {
                do {
                  const parent = layerUI.parent.at(-1);

                  if (!parent) {
                    return false;
                  }

                  if (parent) {
                    layerUI = getLayerUI(parent);
                    if (!layerUI) {
                      return false;
                    }
                  }
                } while (layerUI.children.length === 0);
              }

              return layerUI.expanded;
            }

            return false;
          })
          // Unroll the keyframes
          .flatMap((track) => track.keyFrames)
          // We're only interested in the frame numbers
          .map((keyFrame) => keyFrame.frame)
          // sort them
          .sort((alpha, beta) => alpha - beta)
          // remove duplicates
          .filter((frame, index, list) => list.indexOf(frame) === index);

        let frameToSnapTo: number | undefined;

        // the snap range. If the newFrame is within this range of any existing
        // keyframes, we will snap This is very arbitrary. I went with 8% of the
        // totalFrames.
        const snapRange = 0.08 * totalFrames;

        // Figure out the direction that the user is currently dragging and find
        // an entry in the visibleKeyFrameFrames list that fits in the snapRange
        if (newFrame > currentFrame) {
          // Right
          frameToSnapTo = visibleKeyFrameFrames.find((frame) => frame > newFrame && frame < newFrame + snapRange);
        } else {
          // Left
          frameToSnapTo = visibleKeyFrameFrames.find((frame) => frame < newFrame && frame > newFrame - snapRange);
        }
        // If we do find one, replace the newFrame to the frame we're snapping to
        // Frame to snap to can be zero so . . . gotta disable this lint for this
        /* eslint-disable-next-line no-undefined */
        if (frameToSnapTo !== undefined) {
          newFrame = frameToSnapTo;
        }
      }
    }

    // Update store
    setCurrentFrame(newFrame);
    if (selectedPrecompositionId) {
      const scene = toolkit.scenes[sceneIndex] as Scene;
      const assetNode = getNodeById(scene, selectedPrecompositionId as string) as DagNode;

      assetNode.timeline.setCurrentFrame(newFrame);
      setSelectedPrecompositionJson(assetNode.state as SceneJSON);
    }

    // Send event to get latest toolkit state
    emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
  }, TIMELINE_SCRUBBER_DEBOUNCE_MS);

  const handleOnStart = useCallback((): void => {
    setScrubberDrag(false);
    // disable eslint to solve ambigious behaviour while draggign
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnStop = (): void => {
    // onDrag has a delay
    setTimeout(() => {
      setScrubberDrag(true);
      emitter.emit(EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED);
    }, TIMELINE_SCRUBBER_DEBOUNCE_MS * 2);
  };

  // Use a ref to keep track of whether the ref has been assigned to the DOM
  useEffect(() => {
    // You can perform any action here after the ref is assigned
    setPlayheadNodeRef(scrubberRef);
  }, [scrubberRef, scrubberNodeRef, setPlayheadNodeRef]);

  return (
    <Draggable
      // eslint-disable-next-line no-undefined
      position={scrubberDrag ? { x: Number(playheadPos || 0), y: 0 } : undefined}
      ref={scrubberRef}
      axis="x"
      grid={[(width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX) / totalFrames, 0]}
      handle="#scrubber-head"
      bounds="parent"
      onStart={handleOnStart}
      onDrag={handleOnDrag}
      onStop={handleOnStop}
    >
      <div ref={scrubberNodeRef} className="absolute top-[-8px] z-scrubber select-none">
        <div id="scrubber-head" className="relative z-scrubber mt-[24px] h-[17.36px] w-7 cursor-pointer rounded">
          <div className="absolute top-0 ml-[7.2px] rounded border-x-[9px] border-t-[8px] border-x-transparent border-t-orange-700 text-white"></div>
          <div className="absolute left-[8px] h-[1000px] w-4 bg-orange-700 opacity-0"></div>
          <div className="absolute left-[15px] h-[1000px]  w-0.5 bg-orange-700"></div>
        </div>
      </div>
    </Draggable>
  );
};
