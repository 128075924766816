/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

interface DividerProp {
  customClass?: string;
}
export const Divider: React.FC<DividerProp> = ({ customClass }: DividerProp) => {
  return <div className={`h-[1px] w-full bg-gray-900 ${customClass || ''}`}></div>;
};
