/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset, PrecompositionLayer, Scene } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { createPrecompAssetObj } from '../precomp/precomposition-asset';
import { createPrecompLayerObj } from '../precomp/precomposition-layer';
import { createNodeObject } from '../translate-node';
import { getCurrentScene } from '../utils';
import { newQuickjsObject } from '../vmInterface/marshal/object';
import { getTranslatedObject } from '../vmInterface/unmarshal/object';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getComposableMethods } from './composable';
import { getHasTimelineMethods } from './has-timeline';
import { getSizableMethods } from './sizable';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { resizePrecompLayer } from '~/lib/function/import';
import { renameNestedScenes, stateHistory } from '~/lib/toolkit';

export function getNodesByType(plugin: Plugin): void {
  const getNodesByTypeHandle = plugin.scope.manage(
    plugin.vm.newFunction(
      'getNodesByType',
      (vmString: QuickJSHandle): QuickJSHandle => {
        const string = plugin.vm.getString(vmString).toUpperCase();
        const scene = getCurrentScene();

        const nodes = scene.allChildren;
        const vmNodes = plugin.vm.newArray();
        const push = plugin.vm.getProp(vmNodes, 'push');

        for (const node of nodes) {
          if (node.nodeType.toUpperCase() === string) {
            const nodeObject = createNodeObject(plugin, node);
            const vmNodeObject = newQuickjsObject(plugin, nodeObject, true);

            plugin.vm.callFunction(push, vmNodes, vmNodeObject);
          }
        }

        return vmNodes;
      },
    ),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getNodesByType', {
    value: getNodesByTypeHandle,
    configurable: false,
  });
}

const methodNames = {
  createPrecompositionAsset: 'createPrecompositionAsset',
  crop: 'crop',
  bounds: 'bounds',
  resize: 'resize',
};

interface ImportOptions {
  animation: string;
  standardizeTimeline?: boolean;
}

async function importAsPrecompositionAsset(
  scene: Scene,
  vmOptions: QuickJSHandle,
  plugin: Plugin,
): Promise<QuickJSHandle> {
  const options = getTranslatedObject(plugin, vmOptions) as ImportOptions;
  const fileType = options.animation.split('.').pop();

  let precompAsset;

  stateHistory.beginAction();

  if (fileType === 'lottie') {
    precompAsset = await scene.importAsPrecompositionAsset('com.lottiefiles.dotlottie', {
      dotlottie: options.animation,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (fileType === 'json') {
    precompAsset = await scene.importAsPrecompositionAsset('com.lottiefiles.lottie', {
      animation: options.animation,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (fileType === 'svg') {
    const response = await fetch(options.animation);
    const svgString = await response.text();

    precompAsset = await scene.importAsPrecompositionAsset('com.lottiefiles.svg', {
      svgString,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

  stateHistory.endAction();

  const strippedPrecomp = createPrecompAssetObj(plugin, precompAsset as PrecompositionAsset);
  const vmStrippedPrecomp = newQuickjsObject(plugin, strippedPrecomp, true);

  return vmStrippedPrecomp;
}

async function importAsPrecompositionLayer(
  scene: Scene,
  vmOptions: QuickJSHandle,
  plugin: Plugin,
): Promise<QuickJSHandle> {
  const options = getTranslatedObject(plugin, vmOptions) as ImportOptions;
  const fileType = options.animation.split('.').pop();

  let precompLayer;

  stateHistory.beginAction();

  if (fileType === 'lottie') {
    precompLayer = await scene.importAsPrecompositionLayer('com.lottiefiles.dotlottie', {
      dotlottie: options.animation,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (fileType === 'json') {
    precompLayer = await scene.importAsPrecompositionLayer('com.lottiefiles.lottie', {
      animation: options.animation,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (fileType === 'svg') {
    const response = await fetch(options.animation);
    const svgString = await response.text();

    precompLayer = await scene.importAsPrecompositionLayer('com.lottiefiles.svg', {
      svgString,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  resizePrecompLayer(getCurrentScene(), precompLayer as PrecompositionLayer);
  renameNestedScenes();
  emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

  stateHistory.endAction();

  const strippedPrecomp = createPrecompLayerObj(plugin, precompLayer as PrecompositionLayer);
  const vmStrippedPrecomp = newQuickjsObject(plugin, strippedPrecomp, true);

  return vmStrippedPrecomp;
}

function getSceneMethods(plugin: Plugin, scene: Scene): ObjectMethods {
  return getObjectMethods(plugin, methodNames, scene);
}

export function createSceneObject(plugin: Plugin, scene: Scene): object {
  const sceneObject = {
    nodeId: scene.nodeId,
    nodeType: scene.nodeType,
    importAsPrecompositionLayer: async (args: QuickJSHandle) => {
      return importAsPrecompositionLayer(scene, args, plugin);
    },
    importAsPrecompositionAsset: async (args: QuickJSHandle) => {
      return importAsPrecompositionAsset(scene, args, plugin);
    },
  };

  registerObjectMethods(plugin, scene, sceneObject, [
    getNodeMethods,
    getComposableMethods,
    getHasTimelineMethods,
    getSizableMethods,
    getSceneMethods,
  ]);

  return sceneObject;
}
