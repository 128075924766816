/**
 * Copyright 2022 Design Barn Inc.
 */

import AuthInstance from './instance';

export const FetchUser = async (): Promise<unknown> => {
  const response = await AuthInstance.post('/deimos/v1/session');

  return Promise.resolve(response);
};

export const Logout = async (): Promise<unknown> => {
  const browserResponse = await AuthInstance.get('/auth/self-service/logout/browser');
  let response = null;

  if (browserResponse.data.logout_url) {
    response = await AuthInstance.get(browserResponse.data.logout_url);
  }

  return Promise.resolve(response);
};
