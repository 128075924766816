/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';

import { TemplateSizeField } from './field/TemplateSizeField';
import { TemplateSizeSelect } from './field/TemplateSizeSelect';
import { PropertyRow } from './ui/PropertyRow';

import { SceneIcon } from '~/assets/icons';

export const CompositionProperty: React.FC = () => {
  return (
    <PropertyRow title="Scene" icon={<SceneIcon className="mr-1 h-3 w-3"></SceneIcon>}>
      <TemplateSizeSelect />
      <TemplateSizeField />
    </PropertyRow>
  );
};
