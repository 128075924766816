/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

interface PlayButtonProps {
  isPlaying: boolean;
  togglePlayAnimation: () => void;
}

export const PlayButton: React.FC<PlayButtonProps> = ({ isPlaying, togglePlayAnimation }: PlayButtonProps) => {
  const Play: React.FC = () => {
    return (
      <svg
        style={{ pointerEvents: 'none' }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33313 3.46803C3.33313 2.52328 4.35923 1.93574 5.17402 2.41395L12.8959 6.94608C13.7006 7.41841 13.7006 8.58192 12.8959 9.05424L5.17401 13.5864C4.35923 14.0646 3.33313 13.477 3.33313 12.5323V3.46803Z"
          fill="white"
        />
      </svg>
    );
  };

  const Pause: React.FC = () => {
    return (
      <svg
        style={{ pointerEvents: 'none' }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.99972 2C3.26334 2 2.66638 2.59695 2.66638 3.33333V12.6667C2.66638 13.403 3.26334 14 3.99972 14H5.33305C6.06943 14 6.66638 13.403 6.66638 12.6667V3.33333C6.66638 2.59695 6.06943 2 5.33305 2H3.99972ZM10.6665 2C9.93017 2 9.33321 2.59695 9.33321 3.33333V12.6667C9.33321 13.403 9.93017 14 10.6665 14H11.9999C12.7363 14 13.3332 13.403 13.3332 12.6667V3.33333C13.3332 2.59695 12.7363 2 11.9999 2H10.6665Z"
          fill="#8795A1"
        />
      </svg>
    );
  };

  return (
    <div onClick={togglePlayAnimation} className="mr-[9.26px] rounded p-1 hover:cursor-pointer hover:bg-gray-700">
      {isPlaying ? <Pause /> : <Play />}
    </div>
  );
};
