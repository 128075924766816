/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable tailwindcss/no-custom-classname */

import type { AnimatedPropertyJSON, KeyFrameJSON, ValueJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import type { DraggableEvent, DraggableData } from 'react-draggable';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { useTimelineUtils } from '../hooks';

import { KeyframeIcon } from '~/assets/icons';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setKeyFrame, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface KeyFrameProps {
  animatedPropId: string;
  keyframe: KeyFrameJSON<ValueJSON>;
  layerId: string;
}

export const KeyFrame: React.FC<KeyFrameProps> = ({ animatedPropId, keyframe, layerId }: KeyFrameProps) => {
  const id = keyframe.frameId || `${keyframe.frame}`;

  const [
    selectedKeyframe,
    setCurrentFrame,
    setCurrentFrameId,
    setSelectedKeyframe,
    selectedPrecompositionId,
    sceneIndex,
    selectedNodesInfo,
    addToSelectedNodes,
    setTimelineContext,
  ] = useCreatorStore(
    (state) => [
      state.timeline.selectedKeyframes,
      state.toolkit.setCurrentFrame,
      state.toolkit.setCurrentFrameId,
      state.timeline.setSelectedKeyframe,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.sceneIndex,
      state.ui.selectedNodesInfo,
      state.ui.addToSelectedNodes,
      state.timeline.setTimelineContext,
    ],
    shallow,
  );

  const isSelected = selectedKeyframe.includes(id);
  const nodeRef = React.createRef<HTMLDivElement>();
  const isDraggingRef = React.useRef(false);
  const isADraggingEvent = React.useRef(false);

  const { finalWidth, getFrameFromPx, getFrameLeftPadding, totalFrames } = useTimelineUtils();
  const leftPositionPx = getFrameLeftPadding(keyframe.frame);

  const handleMouseUp = useCallback((): void => {
    if (!isADraggingEvent.current) {
      if (selectedPrecompositionId) {
        const assetNode = getNodeById(toolkit.scenes[sceneIndex] as Scene, selectedPrecompositionId);

        if (assetNode) {
          assetNode.timeline.setCurrentFrame(keyframe.frame);
        }
      }
      setCurrentFrame(keyframe.frame);
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
    }
    document.removeEventListener('mouseup', handleMouseUp);
  }, [keyframe.frame, setCurrentFrame, sceneIndex, selectedPrecompositionId]);

  const handleOnMouseDown = useCallback(
    (event): void => {
      if (event.type === 'mousedown' && event.button === 2 && event.target) {
        // right click
        if (selectedKeyframe[0] !== id) setSelectedKeyframe([id]);
        // TODO: handle multi select
        if ((selectedNodesInfo[0]?.nodeId as string) !== layerId) addToSelectedNodes([layerId], true);
        setCurrentFrameId(animatedPropId);

        isADraggingEvent.current = false;
        const { height, top, width, x } = event.target.getBoundingClientRect();

        const timelineContainer = document.getElementById('timeline-container');

        const timelineTop = timelineContainer?.getBoundingClientRect();

        if (timelineTop) {
          // Calculate the vertical position of the element relative to its parent
          const positionY = top - timelineTop.top;

          // Delay execute to next process tick using setTimeout, after mouse contextClosed called at KeyframeContent.
          setTimeout(() => {
            const marginLeft = 3;
            const marginTop = 20;

            setTimelineContext({
              selectedId: id,
              mousePos: {
                x: Number(x) + Number(width) + marginLeft,
                y: Number(positionY) + Number(height) + marginTop,
              },
            });
          }, 0);
        }
      } else {
        // Update store
        if (selectedKeyframe[0] !== id) setSelectedKeyframe([id]);

        isADraggingEvent.current = false;

        setCurrentFrameId(animatedPropId);

        // Send event to get latest toolkit state
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      }
    },

    [
      id,
      selectedKeyframe,
      setSelectedKeyframe,
      selectedNodesInfo,
      layerId,
      addToSelectedNodes,
      setCurrentFrameId,
      animatedPropId,
      setTimelineContext,
    ],
  );

  const handleOnDrag = useCallback((_: DraggableEvent, data: DraggableData): void => {
    const wasDragged = !(data.x === data.lastX && data.y === data.lastY);

    if (wasDragged && !isDraggingRef.current) {
      // Drag Start
    }
    if (wasDragged || isDraggingRef.current) {
      isADraggingEvent.current = true;
    }
    isDraggingRef.current = true;
  }, []);

  // Workaround to handle data.x and data.lastX is the same
  // https://github.com/react-grid-layout/react-draggable/pull/522#issuecomment-694921225
  const handleOnStop = useCallback(
    (_: DraggableEvent, data: DraggableData): void => {
      if (isDraggingRef.current) {
        // Drag End
        const frame = getFrameFromPx(data.x);

        // Set minimum keyframe to be set zero
        const finalFrame = Math.min(Math.max(Math.round(frame), 0), totalFrames);

        setKeyFrame(id, finalFrame);

        // Send event to get latest toolkit state
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      }
      isDraggingRef.current = false;
    },
    [getFrameFromPx, id, totalFrames],
  );

  const onClickKeyframe = useCallback(() => {
    // TODO: handle multiselect

    addToSelectedNodes([layerId], true);
  }, [addToSelectedNodes, layerId]);

  return (
    <>
      <Draggable
        axis="x"
        nodeRef={nodeRef}
        onMouseDown={handleOnMouseDown}
        onDrag={handleOnDrag}
        onStop={handleOnStop}
        position={{ x: leftPositionPx, y: 0 }}
        // The drag bounds is pointed to an element that's wider than the tracks
        // in the timeline. This is because the svg element is actually massive
        // and goes 2px over the bounds of the actual parent of this element.
        // Ultimately, that prevents the keyframe from being dragged to the last
        // element and this will have the drag some breathing room
        bounds={`#animated-${animatedPropId}`}
        grid={[finalWidth / totalFrames, 0]}
      >
        <div ref={nodeRef} className="absolute z-[99]" onClick={onClickKeyframe}>
          <div className="timeline-keyframe z-0">
            <KeyframeIcon
              data-keyframeid={id}
              data-animatedpropid={animatedPropId}
              className={clsx('timeline-keyframe h-4 w-4 cursor-pointer fill-current stroke-current', {
                'text-teal-500': isSelected,
                'text-white': !isSelected,
              })}
            />
          </div>
        </div>
      </Draggable>
    </>
  );
};

interface AnimationKeyFramesProps {
  animatedProp: AnimatedPropertyJSON;
  animatedPropId: string;
  layerId: string;
}

export const AnimationKeyFrames: React.FC<AnimationKeyFramesProps> = ({ animatedProp, animatedPropId, layerId }) => {
  return (
    <>
      {animatedProp.keyFrames.map((kf: KeyFrameJSON<ValueJSON>) => {
        return (
          <KeyFrame
            key={kf.frameId}
            keyframe={kf as KeyFrameJSON<ValueJSON>}
            layerId={layerId}
            animatedPropId={animatedPropId}
          />
        );
      })}
    </>
  );
};
