/**
 * Copyright 2023 Design Barn Inc.
 */

export const GB_CONSTANT = {
  SHOW_GET_STARTED: 'show_get_started',
  SHOW_PEN_TOOL: 'show_pen_tool',
  SHOW_TIMELINE_ZOOMBAR: 'show_timeline_zoombar',
  SHOW_PLUGINS: 'show_plugins',
  SHOW_ICONSCOUT_PLUGIN: 'show_iconscout_plugin',
  SHOW_MOTION_PRESETS_PLUGIN: 'show_motion_presets_plugin',
  SHOW_ALIGNMENT_TOOLS_FOR_MULTISELECT: 'show_alignment_tools_for_multiselect',
};
