/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import { IfFeatureEnabled, useFeature } from '@growthbook/growthbook-react';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PanelButton } from '../../Elements/Button/BarButton';

import { Shape, LottieAnimation, ShapeActive, LottieAnimationActive, Bolt, BoltActive, Plugin } from '~/assets/icons';
import { GB_CONSTANT } from '~/config/growthbook';
import { LibraryType } from '~/data/constant';
import { useCreatorStore } from '~/store';

const LibraryList = [
  {
    type: LibraryType.GettingStarted,
    activeIcon: <BoltActive />,
    inactiveIcon: <Bolt />,
    tooltipProps: {
      label: 'Get started',
      placement: 'right' as Placement,
    },
    testID: 'get-started-button',
  },
  {
    type: LibraryType.Shape,
    activeIcon: <ShapeActive />,
    inactiveIcon: <Shape />,
    tooltipProps: {
      label: 'Shapes',
      placement: 'right' as Placement,
    },
    testID: 'shapes-button',
  },
  {
    type: LibraryType.Lottie,
    activeIcon: <LottieAnimationActive />,
    inactiveIcon: <LottieAnimation />,
    tooltipProps: {
      label: 'Discover animations',
      placement: 'right' as Placement,
    },
    testID: 'discover-button',
  },
];

export const Library: React.FC = () => {
  const loadedPlugins = useCreatorStore((state) => state.plugins.loadedPlugins);

  const [currentLibrary, setCurrentLibrary] = useCreatorStore(
    (state) => [state.ui.currentLibrary, state.ui.setCurrentLibrary],
    shallow,
  );

  const showGetStarted = useFeature(GB_CONSTANT.SHOW_GET_STARTED).on;

  const pluginBrowserOpen = useCreatorStore((state) => state.ui.pluginBrowserOpen);
  const setPluginBrowserOpen = useCreatorStore.getState().ui.setPluginBrowserOpen;

  const handleOnClick: (type: LibraryType) => void = useCallback(
    (type) => {
      if (type === LibraryType.Plugin) {
        setPluginBrowserOpen(!pluginBrowserOpen);

        return;
      }

      if (currentLibrary === type) {
        // Set to null to collapse side panel
        setCurrentLibrary(null);
      } else {
        setCurrentLibrary(type);
      }
    },
    [currentLibrary, setCurrentLibrary, pluginBrowserOpen, setPluginBrowserOpen],
  );

  return (
    <div className="my-2 space-y-3">
      {LibraryList.map(
        (lib) =>
          ((lib.type === LibraryType.GettingStarted && showGetStarted) || lib.type !== LibraryType.GettingStarted) && (
            <PanelButton
              key={lib.type}
              activeIcon={lib.activeIcon}
              inactiveIcon={lib.inactiveIcon}
              tooltipProps={lib.tooltipProps}
              isSelected={currentLibrary === lib.type}
              onClick={() => handleOnClick(lib.type)}
              testID={lib.testID}
            />
          ),
      )}
      {/* Note: this will load ALL plugins, but plugin manager (in Creator.tsx) will only populate this based on growthbook */}
      {loadedPlugins
        .filter((plugin) => plugin.options.hasToolbarShortcut)
        .map((plugin) => {
          return (
            <PanelButton
              key={plugin.id}
              activeIcon={plugin.options.toolbarIconActive || <></>}
              inactiveIcon={plugin.options.toolbarIcon || <></>}
              tooltipProps={{ label: plugin.name, placement: 'right' }}
              isSelected={currentLibrary === `plugin-${plugin.id}`}
              onClick={() => {
                handleOnClick(`plugin-${plugin.id}` as LibraryType);
              }}
            />
          );
        })}

      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_PLUGINS}>
        <PanelButton
          activeIcon={<Plugin />}
          inactiveIcon={<Plugin />}
          tooltipProps={{
            label: 'Plugins',
            placement: 'right' as Placement,
          }}
          isSelected={currentLibrary === LibraryType.Plugin}
          onClick={() => handleOnClick(LibraryType.Plugin)}
        />
      </IfFeatureEnabled>
    </div>
  );
};
