/**
 * Copyright 2022 Design Barn Inc.
 */

import { useState, useCallback, useRef, useEffect } from 'react';

import type { NumberResult } from '~/components/Elements/Input';
import { computeAspectRatio } from '~/utils';

export interface NumberInputRatioResult {
  type: string;
  value: number[] | number;
}

interface UseDualNumberInputRatioProps {
  left: number;
  leftName: string;
  onChange: (result: NumberInputRatioResult) => void;
  ratioLocked?: boolean;
  right: number;
}

interface UseDualNumberInputRatioReturn {
  handleInputChange: (result: NumberResult) => void;
  handleOnClickAspectRatio: () => void;
  isLocked: boolean;
}

export const useDualNumberInputRatio = ({
  left,
  leftName,
  onChange,
  ratioLocked,
  right,
}: UseDualNumberInputRatioProps): UseDualNumberInputRatioReturn => {
  const onChangeHandler = useRef(onChange);
  const [ratio, setRatio] = useState<number>(left / right);
  const [isLocked, setIsLocked] = useState<boolean>(ratioLocked ?? true);

  // store handler
  useEffect(() => {
    onChangeHandler.current = onChange;
  }, [onChange]);

  useEffect(() => {
    setIsLocked(ratioLocked as boolean);
    if (ratioLocked) {
      setRatio(left / right);
    }
  }, [ratioLocked, left, right]);

  const handleInputChange = useCallback(
    (result: NumberResult) => {
      if (isLocked) {
        const isWidth = result.name === leftName;

        const newValue = computeAspectRatio(result.value, ratio, isWidth);

        const returnValue = isWidth ? [result.value, newValue] : [newValue, result.value];

        onChangeHandler.current({
          type: 'both',
          value: returnValue,
        });
      } else {
        onChangeHandler.current({
          type: result.name,
          value: result.value,
        });
      }
    },
    [isLocked, ratio, leftName, onChangeHandler],
  );

  const handleOnClickAspectRatio = useCallback(() => {
    const lock = !isLocked;

    if (lock) {
      setRatio(left / right);
    }
    setIsLocked(lock);
  }, [isLocked, left, right]);

  return { isLocked, handleInputChange, handleOnClickAspectRatio };
};
