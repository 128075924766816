/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ColorJSON, EffectJSON } from '@lottiefiles/toolkit-js';
import type { IUniform } from 'three';
import { Vector2, Vector3 } from 'three';

import type { CObject3D } from '../../types/object';
import { CMesh } from '../../types/object';

import type { GradientFillProperty } from './type';

export const applyGradient = (object: CObject3D, gradientProperty: GradientFillProperty, effect?: EffectJSON): void => {
  // TODO: if there are more than 3 colors, only the first and the last colors are used and the other colors are not used. what are they for?
  let color1 = gradientProperty.colors[0];
  let color2 = gradientProperty.colors[gradientProperty.colors.length - 1];

  // TODO: need to apply amount to tint
  // let amountToTint = 1;

  if (effect) {
    if (effect.properties.nm === 'Tint') {
      effect.effectValues.forEach((effectValue) => {
        if (effectValue.properties.nm === 'Map White To' && effectValue.animatedProperties.ec.value) {
          color1 = effectValue.animatedProperties.ec.value as ColorJSON;
        } else if (effectValue.properties.nm === 'Map Black To') {
          color2 = effectValue.animatedProperties.ec.value as ColorJSON;
        } else if (effectValue.properties.nm === 'Amount to Tint') {
          // amountToTint = (effectValue.animatedProperties.sl.value as ScalarJSON).value / 100;
        }
      });
    }
  }

  object.traverse((child) => {
    if (!(child instanceof CMesh) || !color1 || !color2) return;

    child.material.depthWrite = false;
    child.material.depthTest = false;

    const startColor = {
      value: new Vector3(color1.r / 255, color1.g / 255, color1.b / 255).multiplyScalar(color1.a),
    };

    const endColor = {
      value: new Vector3(color2.r / 255, color2.g / 255, color2.b / 255).multiplyScalar(color2.a),
    };

    const startPoint = { value: new Vector2(gradientProperty.startX.value, gradientProperty.startY.value) };
    const endPoint = { value: new Vector2(gradientProperty.endX.value, gradientProperty.endY.value) };
    const opacity = { value: gradientProperty.opacity.value * child.opacity };

    // the reason why we are using customUniforms and customChunks variables
    // is described in ~feature/canvas/types/object.ts file
    child.material.onBeforeCompile = (shader) => {
      if (child.customUniforms) {
        Object.entries(child.customUniforms).forEach(([key, value]) => {
          shader.uniforms[key] = value as IUniform;
        });
      }

      shader.uniforms['startColor'] = startColor;
      shader.uniforms['endColor'] = endColor;
      shader.uniforms['startPoint'] = startPoint;
      shader.uniforms['endPoint'] = endPoint;
      shader.uniforms['op'] = opacity;

      shader.vertexShader = `
      ${child.customChunks.vertexShader && child.customChunks.vertexShader[0] ? child.customChunks.vertexShader[0] : ''}
      uniform vec2 startPoint;
      uniform vec2 endPoint;
      varying float mixRate;

      float getDivisionRate(vec2 lineStart, vec2 lineEnd, vec2 point){
        vec2 line = lineEnd - lineStart;
        // Project c onto ab, computing the
        // parameterized position d(t) = a + t * (b - a)
        float t = dot(point - lineStart, line) / dot(line, line);

        // Clamp T to a 0-1 range. If t was < 0 or > 1
        // then the closest point was outside the line!
        t = clamp(t, 0.0, 1.0);

        return t;
      }
      ${shader.vertexShader}
      `;

      shader.vertexShader = shader.vertexShader.replace(
        `#include <uv_vertex>`,
        `#include <uv_vertex>
        float distanceToStart = length(position.xy - startPoint);
        float distanceToEnd = length(position.xy - endPoint);
        mixRate = getDivisionRate(startPoint, endPoint, position.xy);
        `,
      );

      shader.vertexShader = shader.vertexShader.replace(
        `#include <begin_vertex>`,
        `#include <begin_vertex>
        ${
          child.customChunks.vertexShader && child.customChunks.vertexShader[1]
            ? child.customChunks.vertexShader[1]
            : ''
        }
        `,
      );

      shader.fragmentShader = `
      uniform vec3 startColor;
      uniform vec3 endColor;
      uniform float op;

      varying float mixRate;
      ${shader.fragmentShader}`;
      shader.fragmentShader = shader.fragmentShader.replace(
        `vec4 diffuseColor = vec4( diffuse, opacity );`,
        `vec2 st = gl_FragCoord.xy;
          vec4 color = vec4(0.0, 0.0, 0.0, op);

          vec4 colorA = vec4(startColor, op);
          vec4 colorB = vec4(endColor, op);
          color = mix( colorA,
                 colorB,
                 mixRate );

          vec4 diffuseColor = vec4(color);`,
      );
    };
  });
};
