/**
 * Copyright 2023 Design Barn Inc.
 */

import type { FillShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getShapeMethods } from './shape';

import { emitter, EmitterEvent } from '~/lib/emitter';

const methodNames = {
  setColor: 'setColor',
  setOpacity: 'setOpacity',
  opacity: 'opacity',
  color: 'color',
  fillRule: 'fillRule',
};

const fillShapeModifiers = {
  fillRule: (result: unknown): string => {
    return result === 1 ? 'NONZERO' : 'EVEN_ODD';
  },
};

// manually creating setFillRule to take in string arguments
// instead of number arguments
function setFillRule(fill: FillShape, fillRule: string): FillShape {
  const uppercaseFillRule = fillRule.toUpperCase();

  if (!(uppercaseFillRule === 'NONZERO' || uppercaseFillRule === 'EVEN_ODD')) {
    throw new Error("Value must be 'NONZERO' or 'EVEN_ODD'.");
  }

  if (uppercaseFillRule === 'NONZERO') {
    fill.setFillRule(1);
  }

  if (uppercaseFillRule === 'EVEN_ODD') {
    fill.setFillRule(2);
  }

  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);

  return fill;
}

const additionalMethods = {
  setFillRule,
};

function getFillShapeMethods(plugin: Plugin, fillShape: FillShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, fillShape, fillShapeModifiers, additionalMethods);
}

export function createFillShapeObject(plugin: Plugin, fillShape: FillShape): object {
  const fillShapeObj = {
    nodeId: fillShape.nodeId,
    nodeType: fillShape.nodeType,
    type: fillShape.type,
  };

  registerObjectMethods(plugin, fillShape, fillShapeObj, [getNodeMethods, getShapeMethods, getFillShapeMethods]);

  return fillShapeObj;
}
