/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { GrowthBook, GrowthBookProvider as GBProvider } from '@growthbook/growthbook-react';
import React, { useEffect, useContext, useCallback } from 'react';

import { GROWTHBOOK_ENDPOINT } from '~/config';

export const UsersnapContext = React.createContext(null);

interface Props {
  children?: React.ReactNode;
}

// Create a GrowthBook instance
export const growthbook = new GrowthBook();

export const GrowthBookProvider: React.FC<Props> = ({ children }) => {
  const fetchGrowthbook = useCallback(async () => {
    const data = await fetch(GROWTHBOOK_ENDPOINT);
    const dataJSON = await data.json();

    growthbook.setFeatures(dataJSON.features);

    return data;
  }, []);

  useEffect(() => {
    // Load feature definitions from API
    fetchGrowthbook();
  }, [fetchGrowthbook]);

  return <GBProvider growthbook={growthbook}>{children}</GBProvider>;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
