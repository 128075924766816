/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SceneJSON } from '@lottiefiles/toolkit-js';
import DomToImage from 'dom-to-image';

import type { DotLottiePlayer } from '../../components/Elements/TestAnimationDialog/TestAnimationPlayer';
import { getLottieJSON } from '../function/menu';

import { getScaledSize } from '~/components/Elements/TestAnimationDialog/TestAnimationPlayer';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { getElementBySelectorAsync } from '~/utils';

const setOpenHiddenPlayer = useCreatorStore.getState().ui.setOpenHiddenPlayer;

export interface Metadata {
  thumbnail: string;
  title: string;
  version: string;
}

export interface ClientState {
  currentSelectedNode: string;
}

interface State {
  client: ClientState;
  toolkit: SceneJSON;
}

interface DotCreator {
  metadata: Metadata;
  state: State;
}

export const generateCreatorJSON = (
  toolkitJSON: SceneJSON,
  clientState: ClientState,
  metadata: Metadata,
): DotCreator => {
  const dotCreatorJSON: DotCreator = {
    metadata: {
      ...metadata,
    },
    state: {
      client: {
        ...clientState,
      },
      toolkit: toolkitJSON,
    },
  };

  return dotCreatorJSON;
};

export const getCanvasScreenshot = async (): Promise<string> => {
  setOpenHiddenPlayer(true);

  // Wait for #lottie dom to be shown
  const hiddenPlayerDOM = await getElementBySelectorAsync('#thumbnail-player');
  const shadowRoot = (hiddenPlayerDOM as Element).shadowRoot;
  const shadowContent = (shadowRoot as ShadowRoot).querySelector('#animation-container');
  const json = (await getLottieJSON()) as Record<string, never>;

  if (hiddenPlayerDOM) {
    (hiddenPlayerDOM as DotLottiePlayer).load(json);

    // eslint-disable-next-line id-length
    const { h, w } = json as Record<string, number>;

    const size = getScaledSize({ width: w as number, height: h as number });

    // convert dom to png
    const thumbnailImageDataURL = await DomToImage.toPng(shadowContent as HTMLElement, {
      quality: 1,
      height: size.h,
      width: size.w,
    });

    return Promise.resolve(thumbnailImageDataURL);
  } else {
    // Error image to browser screenshot
    const canvas = document.getElementById('artboard-canvas') as HTMLCanvasElement;

    return Promise.resolve(canvas.toDataURL());
  }
};

export const getDotCreator = async (): Promise<DotCreator | null> => {
  const json = toolkit.state;

  let dotCreator = null;

  const thumbnail = await getCanvasScreenshot();

  const nm = json.scenes[0]?.properties.nm || 'Untitled name';
  const metadata: Metadata = {
    title: nm as string,
    version: '1.0.0',
    thumbnail,
  };

  // TODO: handle multi select
  dotCreator = generateCreatorJSON(
    json as any,
    { currentSelectedNode: useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string },
    metadata,
  );

  // Setting this to false seems to intermittently generate blank thumbnails
  // setOpenHiddenPlayer(false);

  return Promise.resolve(dotCreator);
};
