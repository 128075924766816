/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene, SizeJSON, VectorJSON, ShapeLayer, GroupShape } from '@lottiefiles/toolkit-js';
import { Size, Vector, EllipseShape } from '@lottiefiles/toolkit-js';

import type { Scalar2D } from './types';

export interface EllipseOption {
  position?: Scalar2D;
  positionCurrentKeyFrame?: string;
  size?: Scalar2D;
  sizeCurrentKeyFrame?: string;
}

export const updateEllipse = (scene: Scene, id: string, option: EllipseOption): void => {
  const node = scene.getNodeById(id);

  if (node instanceof EllipseShape) {
    const { position, size } = option;
    const ellipse = node as EllipseShape;

    if (position) {
      ellipse.setPosition(new Vector(position[0], position[1]));
    }

    if (size) {
      ellipse.setSize(new Size(size[0], size[1]));
    }
  }
};

export const createEllipse = (scene: Scene, parent: ShapeLayer | GroupShape, ellipse?: EllipseOption): EllipseShape => {
  const shape = parent.createEllipseShape();

  if (ellipse) {
    updateEllipse(scene, shape.nodeId, ellipse);
  }

  return shape;
};

export type CurrentEllipse = Required<EllipseOption>;

export const getCurrentEllipse = (node: EllipseShape): CurrentEllipse => {
  const state = node.state.animatedProperties;

  const currentFrame = node.scene.timeline.currentFrame;

  // NOTE(miljau): the assumption here is that <KeyFrame>.frame and currentFrame will line up as integers
  return {
    position: [(state.p.value as VectorJSON).x, (state.p.value as VectorJSON).y],
    positionCurrentKeyFrame:
      node.position.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    size: [(state.sz.value as SizeJSON).w, (state.sz.value as SizeJSON).h],
    sizeCurrentKeyFrame: node.size.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
  };
};
