/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

// import { TimelineSidebarMarker, TimelineSidebarPlus, TimelineSidebarStar } from '~/assets/icons';

export const TimelineSidebar: React.FC = () => {
  return (
    <div id="timeline-sidebar" className="mt-[10px] flex w-10 flex-col items-center space-y-2">
      <div className="flex h-6 w-6 items-center justify-center">
        {/* <TimelineSidebarPlus className="h-4 w-4 cursor-not-allowed stroke-current text-gray-600" /> */}
      </div>
      <div className="flex h-6 w-6 items-center justify-center">
        {/* <TimelineSidebarMarker className="h-4 w-4 cursor-not-allowed fill-current stroke-current text-gray-600" /> */}
      </div>
      <div className="flex h-6 w-6 items-center justify-center">
        {/* <TimelineSidebarStar className="h-4 w-4 cursor-not-allowed stroke-current text-gray-600" /> */}
      </div>
    </div>
  );
};

export default TimelineSidebar;
