/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, DagNode, PathShape } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, toolkit, getNodeById } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PathEditProperty: React.FC = () => {
  const [selectedNodesInfo, sceneIndex, addToSelectedNodes, currentFrame] = useCreatorStore(
    (state) => [
      state.ui.selectedNodesInfo,
      state.toolkit.sceneIndex,
      state.ui.addToSelectedNodes,
      state.toolkit.currentFrame,
    ],
    shallow,
  );
  const [currentKeyFrame, setCurrentKeyFrame] = useState('');
  const [currentShapeNode, setCurrentShapeNode] = useState(null as null | DagNode);

  const updateCurrentKeyFrame = useCallback(() => {
    setCurrentKeyFrame('');
    if (currentShapeNode && currentShapeNode.shape) {
      (currentShapeNode as PathShape).shape.keyFrames.forEach((eachKeyFrame: Keyframe) => {
        if (eachKeyFrame.frame === currentFrame && !eachKeyFrame.isStatic) {
          setCurrentKeyFrame(eachKeyFrame.frameId);
        }
      });
    }
  }, [currentFrame, setCurrentKeyFrame, currentShapeNode]);

  useEffect(() => {
    updateCurrentKeyFrame();
  }, [updateCurrentKeyFrame]);

  useEffect(() => {
    // TODO: handle multi selection
    setCurrentShapeNode(getNodeById(toolkit.scenes[sceneIndex], selectedNodesInfo[0]?.nodeId as string));
  }, [selectedNodesInfo, sceneIndex]);

  const onEditClick = useCallback(() => {
    if (currentShapeNode) {
      addToSelectedNodes([(currentShapeNode.parent as AVLayer).nodeId as string], true);
    }
  }, [currentShapeNode, addToSelectedNodes]);

  const handleKeyframeClick = useCallback(() => {
    if (currentKeyFrame) {
      if ((currentShapeNode as PathShape).shape.keyFrames.length === 1) {
        (currentShapeNode as PathShape).shape.setIsAnimated(false);
      }
      removeKeyFrame(currentKeyFrame);
    } else {
      if (!(currentShapeNode as PathShape).shape.isAnimated) {
        (currentShapeNode as PathShape).shape.setIsAnimated(true);
      }

      (currentShapeNode as PathShape).shape.setValue((currentShapeNode as PathShape).shape.value);
    }
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
    updateCurrentKeyFrame();
  }, [currentShapeNode, updateCurrentKeyFrame, currentKeyFrame]);

  return (
    <>
      <div className="mb-2 w-[87px] rounded-r">
        <div className="relative flex items-center">
          <label
            className="group  flex h-[24px] w-full items-center rounded border border-transparent bg-gray-700 p-1 px-2 text-xs font-normal "
            data-testid="path-edit-container"
          >
            Edit Path
          </label>
          <div className="absolute right-[-8px] top-[3px]">
            <KeyframeButton hasKeyframe={Boolean(currentKeyFrame)} onClick={handleKeyframeClick} />
          </div>
        </div>
      </div>
      <div
        onClick={onEditClick}
        className="flex cursor-pointer justify-center rounded border border-gray-600 bg-white py-1 font-semibold text-gray-700 hover:border-gray-400 hover:bg-gray-100"
      >
        <div className="py-[2px] text-xs">Done Editing</div>
      </div>
    </>
  );
};
