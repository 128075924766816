/**
 * Copyright 2023 Design Barn Inc.
 */

import { useSortable } from '@dnd-kit/sortable';
import type { ShapeLayerJSON, ShapeJSON } from '@lottiefiles/toolkit-js';
import React from 'react';

import { SortableTreeItem } from './SortableTreeItem';

import type { Optional } from '~/types';

export type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

export interface SortableTreeItemLayerProps {
  TreeItemComponent: unknown;
  lastLayer?: unknown;
  layer: OptionalShapeLayer | ShapeJSON;
}

const animateLayoutChanges = ({ isDragging, isSorting }: { isDragging: boolean; isSorting: boolean }): boolean =>
  !(isSorting || isDragging);

export const SortableTreeItemLayer: React.FC<SortableTreeItemLayerProps> = ({
  TreeItemComponent,
  lastLayer,
  layer,
}: SortableTreeItemLayerProps) => {
  const layerId = layer.id;
  const sortableData = useSortable({
    id: layer.id,
    animateLayoutChanges,
    disabled: false,
  });

  const { attributes, isDragging, isSorting, listeners, setDraggableNodeRef, setDroppableNodeRef } = sortableData;

  return (
    <>
      <SortableTreeItem
        key={layerId}
        id={layerId as string}
        layer={layer}
        isDragging={isDragging}
        listeners={listeners}
        attributes={attributes}
        setDraggableNodeRef={setDraggableNodeRef}
        setDroppableNodeRef={setDroppableNodeRef}
        isSorting={isSorting}
        disableSorting={false}
        TreeItemComponent={TreeItemComponent}
        lastLayer={lastLayer}
      />
    </>
  );
};
