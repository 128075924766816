/**
 * Copyright 2021 Design Barn Inc.
 */

// Temporary disable any check until we implement graphQL typing generation
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useQuery } from 'urql';

import { SearchQuery, CuratedCollectionQuery } from '../api/search';

import { CursorType } from './SearchAnimation';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
// import NoSearchResultImage from '~/assets/illustrations/NoSearchResult';
import { SearchResultEmpty } from '~/assets/icons/placeholder';
import { Pagination } from '~/components/Elements/Pagination';
import { SEARCH_GRAPHQL_ENDPOINT, ASSET_ENDPOINT } from '~/config';

const getEndpoint = (endpoint: string): string => {
  if (endpoint && endpoint.toLowerCase().startsWith('http')) return endpoint;
  else return `${ASSET_ENDPOINT}${endpoint}`;
};

export interface DotLottiePlayerElement extends Element {
  load(record: Record<string, any>): void;
}

interface CaptionProps {
  animationUrl: string;
  avatarUrl: string;
  name: string;
  username: string;
}

const Caption: React.FC<CaptionProps> = ({ animationUrl, avatarUrl, name, username }) => {
  const preventPropagation = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, []);

  return (
    <div className="absolute bottom-0 hidden w-[100px] rounded-md from-black group-hover:block group-hover:bg-gradient-to-t">
      <div className="px-[6px] py-2">
        <div className="mb-1 h-[22px] w-[22px]">
          <a href={`https://lottiefiles.com${username}`} target="_blank" onClick={preventPropagation} rel="noreferrer">
            <img className="rounded-full shadow-sm" src={avatarUrl} height={22} width={22} />
          </a>
        </div>
        <div className="max-h-[80px] text-ellipsis whitespace-normal text-caption line-clamp-1">
          <a
            className="block truncate font-semibold text-white hover:underline"
            href={getEndpoint(animationUrl)}
            target="_blank"
            onClick={preventPropagation}
            rel="noreferrer"
          >
            {name}
          </a>
        </div>
      </div>
    </div>
  );
};

interface ItemProps {
  onClick: () => void;
  result: any;
}

const Item: React.FC<ItemProps> = ({ onClick, result }) => {
  const [onMouseOver, setMouseOver] = useState(false);

  return (
    result && (
      <div
        onMouseOver={() => {
          setMouseOver(true);
        }}
        onMouseOut={() => {
          setMouseOver(false);
        }}
        className="group relative my-[5px] h-[100px] w-[100px] cursor-pointer even:justify-self-end"
        onClick={onClick}
      >
        {onMouseOver && (
          <div className="h-[100px] w-[100px] rounded-md bg-white">
            <dotlottie-player
              renderer="svg"
              autoplay
              loop
              src={getEndpoint(result.lottieUrl)}
              style={{ height: '100px', width: '100px', borderRadius: '6px' }}
            ></dotlottie-player>
          </div>
        )}
        {!onMouseOver && (
          <div className="h-[100px] w-[100px] rounded-md bg-white">
            <img src={getEndpoint(result.imageUrl)} className="h-[100px] w-[100px] rounded-[6px]" />{' '}
          </div>
        )}
        {result.createdBy && (
          <Caption
            avatarUrl={getEndpoint(result.createdBy.avatarUrl)}
            animationUrl={getEndpoint(result.url)}
            name={result.name}
            username={result.createdBy.username}
          />
        )}
      </div>
    )
  );
};

const Loading: React.FC = () => {
  const playerRef = useRef(null);
  const player = playerRef.current as Element | null;

  if (player) {
    (player as DotLottiePlayerElement).load(LoadingAnimation as Record<string, any>);
  }

  return (
    <div className="py-2 text-center text-sm text-gray-50" data-testid="loading">
      <div className="absolute top-[calc(30%+80px)] flex w-full justify-center">
        <div className="w-[64px]">
          <dotlottie-player ref={playerRef} renderer="svg" autoplay loop></dotlottie-player>
        </div>
      </div>
    </div>
  );
};

const NoSearchResult: React.FC = () => {
  return (
    <div className="flex flex-col items-center text-gray-50" data-testid="no-search-result">
      <SearchResultEmpty className="mt-20 w-[190px] px-4" />
      <div className="mt-4 text-center text-[10px] font-bold text-gray-300">No results found</div>
      <p className="mt-4 w-[210px] whitespace-normal text-center text-[10px] text-gray-300">
        {`We couldn't find any animations based on your search`}
      </p>
    </div>
  );
};

interface ResultGridProps {
  data: any;
  fetching: boolean;
  onSelected: (result: any) => void;
}

const ResultGrid: React.FC<ResultGridProps> = ({ data, fetching, onSelected }) => {
  if (fetching) return <Loading />;
  if (!data) return null;
  if (data.edges.length === 0) return <NoSearchResult />;

  return (
    <div
      className="search-result-grid grid h-full auto-rows-min grid-cols-2 overflow-y-scroll px-3"
      data-testid="search-results"
    >
      {data.edges.map((edge: any, edgeIndex: number) => (
        <Item key={edgeIndex} result={edge.node} onClick={() => onSelected(edge.node)} />
      ))}
    </div>
  );
};

interface SearchResultProps {
  onError?: (msg: string) => void;
  onPageChanged: (cursor: string, cursorType: CursorType) => void;
  onSelected: (result: any) => void;
  query: {
    cursor: string;
    cursorType: CursorType;
    text: string;
  };
}

interface PageVariables {
  after: string | null;
  before: string | null;
  collectionId: number | null;
  first: number | null;
  last: number | null;
}

export const SearchResult: React.FC<SearchResultProps> = ({ onError, onPageChanged, onSelected, query }) => {
  const ANIMATION_LIMIT = 20;
  // NOTE: This is hardcoded to a curated collection. Dion is the owner of this collection
  const COLLECTION_ID = 1318984;

  const [variables, setVariables] = useState<PageVariables>({
    first: null,
    after: null,
    last: null,
    before: null,
    collectionId: COLLECTION_ID,
  });

  useEffect(() => {
    const updatedVariables = {
      first: query.cursorType === CursorType.NEXT_CURSOR ? ANIMATION_LIMIT : null,
      after: query.cursorType === CursorType.NEXT_CURSOR ? query.cursor : null,
      last: query.cursorType === CursorType.PREV_CURSOR ? ANIMATION_LIMIT : null,
      before: query.cursorType === CursorType.PREV_CURSOR ? query.cursor : null,
      collectionId: COLLECTION_ID,
    };

    setVariables(updatedVariables);
  }, [query]);

  const [result] = useQuery({
    query: query.text ? SearchQuery : CuratedCollectionQuery,
    variables: query.text ? { ...variables, query: query.text } : variables,
    context: useMemo(
      () => ({
        clientName: SEARCH_GRAPHQL_ENDPOINT,
      }),
      [],
    ),
  });

  const { data, error, fetching } = result;

  // const animationResult = data && (data?.searchPublicAnimations || data?.featuredPublicAnimations);
  const animationResult = data && (data?.searchPublicAnimations || data?.publicCollectionAnimations);

  const totalCount = animationResult ? animationResult.totalCount : 0;

  if (error) {
    if (onError) {
      onError(error.message);
    }
  }

  const timelineContainer = document.getElementsByClassName('drop-shadow-timeline');

  let timelineHeight = 30;

  if (timelineContainer[0]) {
    timelineHeight = timelineContainer.length > 0 ? timelineContainer[0].clientHeight : 30;
  }

  return (
    <>
      <div>
        <div
          style={{
            height: query.text ? `calc(100vh - ${timelineHeight + 180}px)` : `calc(100vh - ${timelineHeight + 210}px)`,
          }}
          className="overflow-auto"
          onContextMenu={(evt) => evt.preventDefault()}
        >
          <ResultGrid fetching={fetching} data={animationResult} onSelected={onSelected} />
        </div>
        {animationResult && animationResult.pageInfo && (
          <div
            className={`absolute w-full bg-gray-700 px-3 py-5`}
            style={{
              bottom: `0px`,
            }}
          >
            <Pagination
              onPageChanged={onPageChanged}
              hasPreviousPage={animationResult.pageInfo.hasPreviousPage}
              hasNextPage={animationResult.pageInfo.hasNextPage}
              prevCursor={animationResult.pageInfo.startCursor}
              nextCursor={animationResult.pageInfo.endCursor}
              // NOTE: Remove this once hasPreviousPage and hasNextPage is fixed
              totalCount={totalCount}
              limit={ANIMATION_LIMIT}
            />
          </div>
        )}
      </div>
    </>
  );
};
