/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React, { useCallback, Fragment, useState } from 'react';

import { UploadModal } from './UploadModal';

import { Plus as IconPlus, PlusActive, Upload, Link } from '~/assets/icons';
import { ImportType } from '~/data/constant';
import { useDisclosure } from '~/hooks';

const DropDownList = [
  {
    type: ImportType.Upload,
    activeIcon: <Upload />,
    inactiveIcon: <Upload />,
    label: 'Upload asset',
  },
  {
    type: ImportType.Url,
    activeIcon: <Link />,
    inactiveIcon: <Link />,
    label: 'Open animation from URL',
  },
];

interface InsertProps {
  defaultIsOpen?: boolean;
}

export const Insert: React.FC<InsertProps> = ({ defaultIsOpen = false }: InsertProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen });
  const [importType, setImportType] = useState<ImportType>(ImportType.Upload);

  const handleImport = useCallback(
    (type: ImportType) => {
      setImportType(type);
      onOpen();
    },
    [onOpen],
  );

  return (
    <>
      <Menu as="div" className="relative ml-2 inline-block">
        {({ open }) => (
          <>
            <Menu.Button
              className="group flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded stroke-gray-500 hover:bg-gray-700 hover:stroke-white "
              data-testid="insert-button"
            >
              {React.cloneElement(open ? <PlusActive /> : <IconPlus />, {
                className: 'h-[16px] w-[16px] cursor-pointer fill-current text-gray-500 group-hover:text-white',
              })}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-dropdown mt-1 w-[256px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 py-2 shadow-dropdown focus:outline-none">
                {DropDownList.map((dropdown, index) => (
                  <div key={dropdown.type}>
                    <Menu.Item>
                      <button
                        className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                          index === DropDownList.length - 1 && 'rounded-b'
                        } px-2 text-xs text-white `}
                        onClick={() => handleImport(dropdown.type)}
                      >
                        <div className="flex w-full justify-start rounded-lg p-2 align-baseline hover:bg-gray-600">
                          {React.cloneElement(dropdown.activeIcon, {
                            className: 'h-[16px] w-[16px]  text-gray-400 mr-2',
                          })}
                          {dropdown.label}
                        </div>
                      </button>
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <UploadModal isOpen={isOpen} onClose={onClose} importType={importType} />
    </>
  );
};
