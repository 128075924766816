/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable id-length */
/* eslint-disable grouped-accessor-pairs */
/* eslint-disable @typescript-eslint/member-ordering */
import type { Instance, ColorFormats } from 'tinycolor2';
import tinycolor from 'tinycolor2';

interface IInput {
  h: number;
  s: number;
  v: number;
}

export interface ITinyColor {
  HSB: number[];
  RGB: number[];
  alpha: number;
  alphaValue: number;
  blue: number;
  blueValue: number;
  brightness: number;
  brightnessValue: number;
  color: Instance;
  green: number;
  greenValue: number;
  hex: string;
  hue: number;
  hueValue: number;
  initHsb(): void;
  initRgb(): void;
  lightness: number;
  lightnessValue: number;
  red: number;
  redValue: number;
  saturation: number;
  saturationValue: number;
  toHexString(): string;
  toHsv(): ColorFormats.HSVA;
  toRgbString(): string;
}

let lastSaturationValue = 0;

export default class Color {
  public alphaValue: number;

  public blueValue: number;

  public brightnessValue: number;

  public color: Instance;

  public greenValue: number;

  public hueValue: number;

  public lightnessValue: number;

  public redValue: number;

  public saturationValue: number;

  public constructor(input: IInput | string, saturation?: number) {
    this.color = tinycolor(input);
    if (saturation) lastSaturationValue = saturation;

    this.initRgb();
    this.initHsb();

    const initAlpha = this.color.toRgb().a;

    this.alphaValue = Math.min(1, initAlpha) * 100;

    this.hueValue = this.color.toHsv().h;
    this.saturationValue = input === '000000' ? lastSaturationValue : this.color.toHsv().s;
    this.brightnessValue = this.color.toHsv().v;
    this.redValue = this.color.toRgb().r;
    this.greenValue = this.color.toRgb().g;
    this.blueValue = this.color.toRgb().b;
    this.lightnessValue = 0;
  }

  public static isValidHex(hex: string): boolean {
    return tinycolor(hex).isValid();
  }

  public initRgb(): void {
    const { b, g, r } = this.color.toRgb();

    this.redValue = r;
    this.greenValue = g;
    this.blueValue = b;
  }

  public initHsb(): void {
    const { h, s, v } = this.color.toHsv();

    this.hueValue = h;
    this.saturationValue = s;
    this.brightnessValue = v;
  }

  public toHexString(): string {
    return this.color.toHexString();
  }

  public toRgbString(): string {
    return this.color.toRgbString();
  }

  public toHsv(): ColorFormats.HSVA {
    return this.color.toHsv();
  }

  public get hex(): string {
    return this.color.toHex();
  }

  public set hue(value) {
    this.color = tinycolor({
      h: value,
      s: this.saturation,
      v: this.brightness,
    });

    this.initRgb();
    this.hueValue = value;
  }

  public get hue(): number {
    return this.hueValue;
  }

  public set saturation(value) {
    this.color = tinycolor({
      h: this.hue,
      s: value,
      v: this.brightness,
    });

    this.initRgb();
    this.saturationValue = value;
  }

  public get saturation(): number {
    return this.saturationValue;
  }

  public set lightness(value) {
    this.color = tinycolor({
      h: this.hue,
      s: this.saturation,
      l: value,
    });

    this.initRgb();
    this.lightnessValue = value;
  }

  public get lightness(): number {
    return this.lightnessValue;
  }

  public set brightness(value) {
    this.color = tinycolor({
      h: this.hue,
      s: this.saturation,
      v: value,
    });

    this.initRgb();
    this.brightnessValue = value;
  }

  public get brightness(): number {
    return this.brightnessValue;
  }

  // red
  public set red(value) {
    const rgb = this.color.toRgb();

    this.color = tinycolor({
      ...rgb,
      r: value,
    });

    this.initHsb();
    this.redValue = value;
  }

  public get red(): number {
    return this.redValue;
  }

  // green
  public set green(value) {
    const rgb = this.color.toRgb();

    this.color = tinycolor({
      ...rgb,
      g: value,
    });

    this.initHsb();
    this.greenValue = value;
  }

  public get green(): number {
    return this.greenValue;
  }

  // blue
  public set blue(value) {
    const rgb = this.color.toRgb();

    this.color = tinycolor({
      ...rgb,
      b: value,
    });

    this.initHsb();
    this.blueValue = value;
  }

  public get blue(): number {
    return this.blueValue;
  }

  // alpha
  public set alpha(value) {
    this.color.setAlpha(value / 100);
  }

  public get alpha(): number {
    return this.color.getAlpha() * 100;
  }

  public get RGB(): number[] {
    return [this.red, this.green, this.blue];
  }

  public get HSB(): number[] {
    return [this.hue, this.saturation, this.brightness];
  }
}
