/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StarShape } from '@lottiefiles/toolkit-js/dist';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PolygonRoundness } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MIN_POLYSTAR_ROUNDNESS, MAX_POLYSTAR_ROUNDNESS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PolystarOuterRoundnessField: React.FC = () => {
  const [
    currentShapeNode,
    outerRoundness,
    setOuterRoundness,
    setPolystarVariableIsAnimated,
    currentKeyFrame,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentSecondary?.currentShapeNode,
      state.toolkit.currentSecondary?.currentShape.polystar.outerRoundness,
      state.toolkit.setPolystarOuterRoundness,
      state.toolkit.setPolystarVariableIsAnimated,
      state.toolkit.currentSecondary?.currentShape.polystar.outerRoundnessCurrentKeyFrame,
    ],
    shallow,
  );

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setOuterRoundness(result.value);
      emitter.emit(EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED);
    },
    [setOuterRoundness],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyFrame === '') {
      setPolystarVariableIsAnimated('outerRoundness', true);
      setOuterRoundness(outerRoundness);
    } else if ((currentShapeNode as StarShape).numPoints.keyFrames.length === 1) {
      setPolystarVariableIsAnimated('outerRoundness', false);
      setOuterRoundness(outerRoundness);
    } else {
      removeKeyFrame(currentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED);
    stateHistory.endAction();
  }, [setOuterRoundness, setPolystarVariableIsAnimated, currentShapeNode, currentKeyFrame, outerRoundness]);

  return (
    <div className="w-full">
      <NumberInput
        name="outer-roundness"
        label={<PolygonRoundness className="h-4 w-4" />}
        value={(currentShapeNode as StarShape)?.outerRoundness?.value.value ?? outerRoundness}
        onChange={handleOnChange}
        showKeyframe
        min={MIN_POLYSTAR_ROUNDNESS}
        max={MAX_POLYSTAR_ROUNDNESS}
        append="%"
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={Boolean(currentKeyFrame)}
      />
    </div>
  );
};
