/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { RectangleRoundnessField } from './field/RectangleRoundnessField';
// import { ShapePositionField } from './field/ShapePositionField';
import { ShapeSizeField } from './field/ShapeSizeField';

export const RectangleProperty: React.FC = () => {
  return (
    <>
      {/* <ShapePositionField /> */}
      <ShapeSizeField />

      <div className="mr-32 mt-2 flex">
        <RectangleRoundnessField />
      </div>
    </>
  );
};
