/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Plugin } from '../Plugin';

export function consoleLog(plugin: Plugin): void {
  const logHandle = plugin.scope.manage(
    plugin.vm.newFunction('log', (...args) => {
      const nativeArgs = args.map(plugin.vm.dump);

      // eslint-disable-next-line no-console
      console.log('Printed from main.js:', ...nativeArgs);
      args.map((arg) => arg.dispose());
    }),
  );

  const consoleHandle = plugin.scope.manage(plugin.vm.newObject());

  plugin.vm.setProp(consoleHandle, 'log', logHandle);
  plugin.vm.setProp(plugin.vm.global, 'console', consoleHandle);
}
