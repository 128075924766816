/**
 * Copyright 2023 Design Barn Inc.
 */

import type { BaseAssetJSON } from '@lottiefiles/toolkit-js/dist';
import React, { useEffect, useState } from 'react';

import { SceneNameType } from '~/data/constant';
import { useCreatorStore } from '~/store';

export const SceneLabel: React.FC = () => {
  const selectedPrecompositionId = useCreatorStore((state) => state.toolkit.selectedPrecompositionId);
  const [sceneName, setSceneName] = useState('');

  useEffect(() => {
    if (selectedPrecompositionId) {
      const precompName = (useCreatorStore.getState().toolkit.selectedPrecompositionJson as BaseAssetJSON).assetId;

      setSceneName(precompName);
    } else {
      setSceneName(SceneNameType.MainScene);
    }
  }, [selectedPrecompositionId]);

  return (
    <div
      id="scene-label"
      className="absolute translate-y-[-18px] select-none whitespace-nowrap text-[12px] leading-none"
    >
      <span id="scene-size-label" className="overflow-hidden text-gray-400" />
      <span className="overflow-hidden font-bold text-white">{sceneName}</span>
    </div>
  );
};
