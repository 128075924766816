/**
 * Copyright 2022 Design Barn Inc.
 */

import { shallow } from 'zustand/shallow';

import { KEYFRAME_ICON_HALF_WIDTH, TIMELINE_BAR_BEGIN_OFFSET_PX } from '../../constant';

import { useCreatorStore } from '~/store';

interface TimelineUtilsProps {
  finalWidth: number;
  getFrameFromPx: (px: number) => number;
  getFrameFromTimeOffset: (timeOffset: number) => number;
  getFrameLeftPadding: (frame: number) => number;
  totalFrames: number;
}

export const useTimelineUtils = (): TimelineUtilsProps => {
  const [fps, duration, precomFps, precomDuration, selectedPrecompositionId] = useCreatorStore(
    (state) => [
      (state.toolkit.json?.timeline.properties.fr as number) || 20,
      (state.toolkit.json?.timeline.duration as number) || 0,
      (state.toolkit.selectedPrecompositionJson?.timeline.properties.fr as number) || 20,
      (state.toolkit.selectedPrecompositionJson?.timeline.duration as number) || 0,
      state.toolkit.selectedPrecompositionId,
    ],
    shallow,
  );

  const width = document.getElementById('keyframe-scrubber')?.clientWidth ?? 1;
  const totalFrames =
    (selectedPrecompositionId && precomFps ? precomFps : fps) *
    (selectedPrecompositionId && precomDuration ? precomDuration : duration);
  const finalWidth = width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX;

  const getFrameLeftPadding = (frame: number): number => {
    const xPos = (frame / totalFrames) * finalWidth + TIMELINE_BAR_BEGIN_OFFSET_PX - KEYFRAME_ICON_HALF_WIDTH;

    return xPos;
  };

  const getFrameFromPx = (left: number): number => {
    const frame =
      ((left - TIMELINE_BAR_BEGIN_OFFSET_PX + KEYFRAME_ICON_HALF_WIDTH) * totalFrames) /
      (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX);

    return frame;
  };

  const getFrameFromTimeOffset = (timeOffset: number): number => {
    const computed = (selectedPrecompositionId && precomFps ? precomFps : fps) * timeOffset;

    return parseFloat(computed.toFixed(2));
  };

  return {
    getFrameLeftPadding,
    getFrameFromPx,
    getFrameFromTimeOffset,
    totalFrames,
    finalWidth,
  };
};
