/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { NestedSceneLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapperLayers } from './ContextMenuWrapperLayers';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  eventArg?: unknown;
  isOpen: boolean;
  onClose: () => void;
}

export const NestedSceneLayerMenu: React.FC<ShapeLayerMenuProps> = ({ coord, eventArg, isOpen, onClose }) => {
  if (NestedSceneLayerMenuList[4] && window.editor) NestedSceneLayerMenuList[4].disabled = !window.editor.isPastable();

  if (NestedSceneLayerMenuList[2]) {
    // Temporarily disable precomp keyframe
    NestedSceneLayerMenuList[2].disabled = true;
  }

  return (
    <ContextMenuWrapperLayers
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      eventArg={eventArg}
      dropdownItems={NestedSceneLayerMenuList}
    ></ContextMenuWrapperLayers>
  );
};
