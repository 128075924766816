/**
 * Copyright 2023 Design Barn Inc.
 */

export const BASE_GRAPHQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_ENDPOINT;
export const BASE_GRAPHQL_WSS_ENDPOINT = import.meta.env.VITE_GRAPHQL_WSS_ENDPOINT;
export const SEARCH_GRAPHQL_ENDPOINT = import.meta.env.VITE_SEARCH_GRAPHQL_ENDPOINT;
export const AUTH_GRAPHQL_ENDPOINT = import.meta.env.VITE_AUTH_GRAPHQL_ENDPOINT;
export const AUTH_AJAX_ENDPOINT = import.meta.env.VITE_AUTH_AJAX_ENDPOINT;
export const LOTTIE_UPLOAD = import.meta.env.VITE_LOTTIE_UPLOAD;
export const WORKFLOW_PREVIEW = import.meta.env.VITE_WORKFLOW_PREVIEW;
export const WORKFLOW_DASHBOARD = import.meta.env.VITE_WORKFLOW_DASHBOARD;
export const INTERVAL_AUTO_SAVE = import.meta.env.VITE_INTERVAL_AUTO_SAVE;
export const OPEN_REPLAY_KEY = import.meta.env.VITE_OPEN_REPLAY_KEY;
export const OPEN_REPLAY_INGEST_POINT = import.meta.env.VITE_OPEN_REPLAY_INGEST_POINT;
export const GROWTHBOOK_ENDPOINT = import.meta.env.VITE_GROWTHBOOK_ENDPOINT;
export const ASSET_ENDPOINT = import.meta.env.VITE_ASSET_ENDPOINT;
export const OLVY_WORKSPACE = import.meta.env.VITE_OLVY_WORKSPACE_NAME;
export const OLVY_WHATSNEW_WIDGET = import.meta.env.VITE_OLVY_WHATSNEW_WIDGET;
export const OLVY_FEEDBACK_WIDGET = import.meta.env.VITE_OLVY_FEEDBACK_WIDGET;
export const IS_PROD = import.meta.env.PROD;
export const USERSNAP_PROJECT_API_KEY = import.meta.env.VITE_USERSNAP_PROJECT_API_KEY;
export const EVENTSTORE_API_KEY = import.meta.env.VITE_EVENTSTORE_API_KEY;
export const EVENTSTORE_HOST = import.meta.env.VITE_EVENTSTORE_HOST;
export const PLUGIN_ARTIFACTS_ENDPOINT = import.meta.env.VITE_PLUGINS_ARTIFACTS_ENDPOINT;
