/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { ShapeLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapperLayers } from './ContextMenuWrapperLayers';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  isOpen: boolean;
  onClose: () => void;
  pastable: boolean;
}

export const ShapeLayerMenuTimeline: React.FC<ShapeLayerMenuProps> = ({ coord, isOpen, onClose, pastable }) => {
  if (ShapeLayerMenuList[3]) ShapeLayerMenuList[3].disabled = !pastable;

  return (
    <ContextMenuWrapperLayers
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      dropdownItems={ShapeLayerMenuList}
    ></ContextMenuWrapperLayers>
  );
};
