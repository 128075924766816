/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import type { TreeLineState } from './layer-helper';

interface TreeLineProps {
  treeLines: TreeLineState[];
}

export const TreeLine: React.FC<TreeLineProps> = ({ treeLines }) => {
  return (
    <>
      {treeLines.map((obj, i) => (
        <React.Fragment key={i}>
          {obj.isLeaf ? (
            <>
              <div
                className="absolute border-l-[0.5px] border-gray-500"
                style={{ top: obj.top, left: obj.left, height: obj.height }}
              />
              <div className="absolute w-2 border-t-[0.5px] border-gray-500" style={{ left: obj.left }} />
            </>
          ) : (
            <>
              <div
                className="absolute border-l-[0.5px] border-gray-500"
                style={{ top: obj.top, left: obj.left, height: obj.height }}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export const AnimatedTreeLine: React.FC<TreeLineProps> = ({ treeLines }) => {
  return (
    <>
      {treeLines.map((obj, i) => (
        <React.Fragment key={i}>
          <div
            className="absolute border-l-[0.5px] border-gray-500"
            style={{ top: obj.top, left: obj.left, height: obj.height }}
          />
        </React.Fragment>
      ))}
    </>
  );
};
