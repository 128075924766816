/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable no-secrets/no-secrets */

import { ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import { get } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { ColorInput } from '../../../components/Elements/ColorInput/ColorInput';

import { StrokeWidth } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { TooltipWrapper } from '~/components/Elements/Tooltip/TooltipWrapper';
import { MAX_STROKE_WIDTH, MIN_STROKE_WIDTH } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, removeKeyFrame } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface StrokeProps {
  id: string;
}

enum MultiStrokeKeyFrames {
  COLOR = 'Color',
  WIDTH = 'Width',
}

export const MultipleStroke: React.FC<StrokeProps> = (shapeData: StrokeProps) => {
  const id = shapeData.id;

  const [
    strokeWidth,
    r,
    g,
    b,
    opacity,
    widthCurrentKeyframe,
    colorCurrentKeyframe,
    setAnimatedValue,
    addAnimatedValue,
  ] = useCreatorStore(
    (state) => [
      get(state.toolkit.currentStrokeShapes[id], 'width'),
      get(state.toolkit.currentStrokeShapes[id], 'r'),
      get(state.toolkit.currentStrokeShapes[id], 'g'),
      get(state.toolkit.currentStrokeShapes[id], 'b'),
      get(state.toolkit.currentStrokeShapes[id], 'o'),
      get(state.toolkit.currentStrokeShapes[id], 'widthCurrentKeyframe'),
      get(state.toolkit.currentStrokeShapes[id], 'colorCurrentKeyframe'),
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
    ],
    shallow,
  );

  const colorString = useMemo(() => {
    // eslint-disable-next-line no-undefined
    if (r !== undefined && g !== undefined && b !== undefined && opacity !== undefined)
      return colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', '');
    else return 'ffffff';
  }, [r, g, b, opacity]);

  const hasWidthKeyFrame = Boolean(widthCurrentKeyframe);
  const hasColorKeyFrame = Boolean(colorCurrentKeyframe);

  const handleKeyframeClick = useCallback(
    (type: string): void => {
      if (type === MultiStrokeKeyFrames.COLOR) {
        if (colorCurrentKeyframe) {
          removeKeyFrame(colorCurrentKeyframe);
        }
        if (!hasColorKeyFrame) {
          addAnimatedValue(AnimatedType.STROKE_COLOR, [r, g, b], id);
        }
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED);
      } else if (type === MultiStrokeKeyFrames.WIDTH) {
        if (widthCurrentKeyframe) {
          removeKeyFrame(widthCurrentKeyframe);
        }
        if (!hasWidthKeyFrame) {
          addAnimatedValue(AnimatedType.STROKE_WIDTH, [strokeWidth], id);
        }
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED);
      }
    },
    [
      id,
      r,
      g,
      b,
      widthCurrentKeyframe,
      hasColorKeyFrame,
      colorCurrentKeyframe,
      hasWidthKeyFrame,
      addAnimatedValue,
      strokeWidth,
    ],
  );

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      if (result.value || result.value === 0) {
        setAnimatedValue(AnimatedType.STROKE_WIDTH, [result.value], id);
      } else {
        setAnimatedValue(AnimatedType.STROKE_WIDTH, [result.value]);
      }
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED);
    },
    [setAnimatedValue, id],
  );

  const handleOnChangeColor = useCallback(
    (color: string) => {
      const rgba = colord(color).toRgb();

      if (color) {
        setAnimatedValue(AnimatedType.STROKE_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a], id);
      } else {
        setAnimatedValue(AnimatedType.STROKE_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a]);
      }
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED);
    },
    [setAnimatedValue, id],
  );

  const handleOnChangeOpacity = useCallback(
    (opac: number) => {
      if (opac) {
        setAnimatedValue(AnimatedType.STROKE_OPACITY, [opac], id);
      } else {
        setAnimatedValue(AnimatedType.STROKE_OPACITY, [opac]);
      }
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED);
    },
    [setAnimatedValue, id],
  );

  if (!id) return <></>;

  return (
    <div className="mt-2 flex w-44">
      <div className="w-1/2">
        <TooltipWrapper label="Stroke color">
          <ColorInput
            shapeType={ShapeType.STROKE}
            styleClass="w-[84px]"
            color={colorString}
            opacity={opacity || 0}
            onChangeColor={handleOnChangeColor}
            onChangeOpacity={handleOnChangeOpacity}
            showKeyframe
            hasKeyframe={hasColorKeyFrame}
            onKeyframeClick={() => handleKeyframeClick(MultiStrokeKeyFrames.COLOR)}
          />
        </TooltipWrapper>
      </div>
      <div className="my-[-8px] border border-gray-800"></div>
      <div className="w-[84px]">
        <TooltipWrapper label="Stroke width">
          <NumberInput
            name="stroke"
            label={<StrokeWidth className="h-1.5 w-2.5" />}
            value={strokeWidth as number}
            onChange={handleOnChange}
            min={MIN_STROKE_WIDTH}
            max={MAX_STROKE_WIDTH}
            showKeyframe
            hasKeyframe={hasWidthKeyFrame}
            onKeyframeClick={() => handleKeyframeClick(MultiStrokeKeyFrames.WIDTH)}
          />
        </TooltipWrapper>
      </div>
    </div>
  );
};

enum SingleStrokeKeyFrames {
  COLOR = 'Color',
  MITER = 'Miter',
  OPACITY = 'Opacity',
  WIDTH = 'Width',
}

export const SingleStroke: React.FC = () => {
  const [
    strokeWidth,
    r,
    g,
    b,
    miter,
    opacity,
    widthCurrentKeyframe,
    miterCurrentKeyframe,
    colorCurrentKeyframe,
    setAnimatedValue,
    addAnimatedValue,
  ] = useCreatorStore(
    (state) => [
      get(state.toolkit.currentStrokeShape, 'width'),
      get(state.toolkit.currentStrokeShape, 'r'),
      get(state.toolkit.currentStrokeShape, 'g'),
      get(state.toolkit.currentStrokeShape, 'b'),
      get(state.toolkit.currentStrokeShape, 'ml'),
      get(state.toolkit.currentStrokeShape, 'o'),
      get(state.toolkit.currentStrokeShape, 'widthCurrentKeyframe'),
      get(state.toolkit.currentStrokeShape, 'miterCurrentKeyframe'),
      get(state.toolkit.currentStrokeShape, 'colorCurrentKeyframe'),
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
    ],
    shallow,
  );

  const colorString = useMemo(() => colord({ r, g, b, alpha: opacity / 100 }).toHex(), [r, g, b, opacity]);
  const hasColorKeyFrame = Boolean(colorCurrentKeyframe);
  const hasWidthKeyFrame = Boolean(widthCurrentKeyframe);
  const hasMiterKeyFrame = Boolean(miterCurrentKeyframe);

  const handleKeyframeClick = useCallback(
    (type: string): void => {
      if (type === SingleStrokeKeyFrames.COLOR) {
        if (colorCurrentKeyframe) {
          removeKeyFrame(colorCurrentKeyframe);
        }
        if (!hasColorKeyFrame) {
          addAnimatedValue(AnimatedType.STROKE_COLOR, [r, g, b]);
        }
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED);
      } else if (type === SingleStrokeKeyFrames.WIDTH) {
        if (widthCurrentKeyframe) {
          removeKeyFrame(widthCurrentKeyframe);
        }
        if (!hasWidthKeyFrame) {
          addAnimatedValue(AnimatedType.STROKE_WIDTH, [strokeWidth]);
        }
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED);
      } else if (type === SingleStrokeKeyFrames.MITER) {
        if (miterCurrentKeyframe) {
          removeKeyFrame(miterCurrentKeyframe);
        }
        if (!hasMiterKeyFrame) {
          addAnimatedValue(AnimatedType.STROKE_MITER, [miter]);
        }
      }
    },
    [
      colorCurrentKeyframe,
      hasColorKeyFrame,
      addAnimatedValue,
      r,
      g,
      b,
      widthCurrentKeyframe,
      hasWidthKeyFrame,
      strokeWidth,
      miterCurrentKeyframe,
      hasMiterKeyFrame,
      miter,
    ],
  );

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setAnimatedValue(AnimatedType.STROKE_WIDTH, [result.value]);

      emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED);
    },
    [setAnimatedValue],
  );

  const handleOnChangeColor = useCallback(
    (color: string) => {
      const rgba = colord(color).toRgb();

      setAnimatedValue(AnimatedType.STROKE_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a]);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED);
    },
    [setAnimatedValue],
  );

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult | number) => {
      if (typeof result === 'number') setAnimatedValue(AnimatedType.STROKE_OPACITY, [result]);
      else setAnimatedValue(AnimatedType.STROKE_OPACITY, [result.value]);

      emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED);
    },
    [setAnimatedValue],
  );

  // const handleOnChangeMiter = useCallback(
  //   (result: NumberResult) => {
  //     setAnimatedValue(AnimatedType.STROKE_MITER, [result.value]);
  //     emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_MITER_UPDATED);
  //   },
  //   [setAnimatedValue],
  // );

  return (
    <div className="mt-2 flex w-44">
      <div className="w-1/2">
        <TooltipWrapper label="Stroke color">
          <ColorInput
            shapeType={ShapeType.STROKE}
            styleClass="w-[84px]"
            color={colorString}
            opacity={opacity || 0}
            onChangeColor={handleOnChangeColor}
            onChangeOpacity={handleOnChangeOpacity}
            showKeyframe
            hasKeyframe={hasColorKeyFrame}
            onKeyframeClick={() => handleKeyframeClick(MultiStrokeKeyFrames.COLOR)}
          />
        </TooltipWrapper>
      </div>
      <div className="my-[-8px] border border-gray-800"></div>
      <div className="w-[84px]">
        <TooltipWrapper label="Stroke width">
          <NumberInput
            name="stroke"
            label={<StrokeWidth className="h-1.5 w-2.5" />}
            value={strokeWidth as number}
            onChange={handleOnChange}
            min={MIN_STROKE_WIDTH}
            max={MAX_STROKE_WIDTH}
            showKeyframe
            hasKeyframe={hasWidthKeyFrame}
            onKeyframeClick={() => handleKeyframeClick(MultiStrokeKeyFrames.WIDTH)}
          />
        </TooltipWrapper>
      </div>
      {/* <div className="w-[87px] rounded-r">
          <NumberInput
            styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
            name="opacity"
            value={opacity}
            label={<Opacity className="h-4 w-4" />}
            min={0}
            max={100}
            precision={0}
            onChange={handleOnChangeOpacity}
            append="%"
            hasKeyframe={hasColorOpacityKeyFrame}
            onKeyframeClick={() => handleKeyframeClick(SingleStrokeKeyFrames.COLOR_OPACITY)}
            showKeyframe
          />
        </div> */}

      {/* <div className="mt-2 flex">
        <div className="w-[84px]">
          <LineJoinSelect />
        </div>
        <div className="ml-2 w-[84px]">
          <LineCapSelect />
        </div>
      </div>
      <div className="mt-2 flex w-[84px]" onClick={onTouchField}>
        <Tooltip bgColorClass="bg-[#000000]" offset={{ mainAxis: 7, crossAxis: -9 }} label="Miter" placement="bottom">
          <div>
            <NumberInput
              styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
              name="miter"
              value={miter}
              label={<LineJoinMiter className="h-4 w-4 stroke-gray-500" />}
              min={0}
              precision={0}
              onChange={handleOnChangeMiter}
              append=""
              hasKeyframe={hasMiterKeyFrame}
              onKeyframeClick={() => handleKeyframeClick(SingleStrokeKeyFrames.MITER)}
              showKeyframe={false}
            />
          </div>
        </Tooltip>
      </div> */}
    </div>
  );
};

export const SingleStrokeProperty: React.FC = () => {
  return <SingleStroke />;
};
