/**
 * Copyright 2022 Design Barn Inc.
 */

import type { QuickJSAsyncContext, QuickJSHandle, Scope } from 'quickjs-emscripten';
import type { createRoot } from 'react-dom/client';

import type { Manifest } from './PluginManager';
import { removeZustandListeners } from './shim/event-listeners/event-listener';
import { setupShim } from './shim/setup-shim';

export enum PluginUIType {
  FloatingUI = '#main-content',
  PresetsPanelUI = '#presets-panel',
  SidebarUI = '#sidebar-plugin-panel',
}

export interface PluginOptions {
  // The name to provide creator's ui components when the plugin is eager-loaded
  eagerName?: string;
  hasToolbarShortcut: boolean;
  toolbarIcon?: React.ReactElement;
  toolbarIconActive?: React.ReactElement;
  uiType: PluginUIType;
}

// Metadata is a json object containing data that will *eventually* be
// offloaded into a database when the plugin portal is developed.
// It is data that the developer will not have control over.
export interface Metadata {
  host: string;
}

export class Plugin {
  public _messageListener: ((event: MessageEvent) => void | undefined) | undefined;

  public _reactRoot: ReturnType<typeof createRoot> | null = null;

  public _uiIframe: HTMLIFrameElement | null = null;

  public _uiRootElement: HTMLElement | null = null;

  public classesHandle: QuickJSHandle | undefined;

  public creatorHandle: QuickJSHandle | undefined;

  public zustandListeners: Array<() => void> = [];

  public constructor(
    public readonly manifest: Manifest,
    public readonly runtimeSource: string,
    public readonly uiSource: string,
    public readonly scope: Scope,
    public readonly vm: QuickJSAsyncContext,
    public readonly devPlugin: boolean = false,
    public readonly pluginOptions: PluginOptions = { hasToolbarShortcut: false, uiType: PluginUIType.FloatingUI },
    public readonly metadata: Metadata,
  ) {}

  public async bootstrap(): Promise<void> {
    setupShim(this);
    this.scope.manage(this.vm.unwrapResult(this.vm.evalCode(this.runtimeSource)));
  }

  public cleanUp(): void {
    // remove event listener
    window.removeEventListener('message', this._messageListener as EventListenerOrEventListenerObject);
    removeZustandListeners(this.zustandListeners);

    // Dispose of handles
    this.creatorHandle?.dispose();
    this.classesHandle?.dispose();

    // Dispose of the Quickjs vm
    this.scope.dispose();

    // Destroy created react root
    this._reactRoot?.unmount();
    this._reactRoot = null;

    this._uiRootElement?.remove();
    this._uiRootElement = null;
  }

  // FIXME: this is temporary. Right now, the message gets serialized into a
  // string and then input into the quickjs's JSON.parse to quickly get a
  // quickjs handle for the message. In the future the object should be marshalled properly.

  // Dispatches a message into the quickjs environment.
  // It is assumed that message contains serializable data.

  public dispatchMessage(message: unknown): void {
    // marshalling the data into quickjs
    const messageString = JSON.stringify(message);
    const messageStringHandle = this.vm.newString(messageString);

    const vmJSON = this.vm.getProp(this.vm.global, 'JSON');
    const parse = this.vm.getProp(vmJSON, 'parse');

    const messageAsQuickJSObject = this.scope.manage(
      this.vm.unwrapResult(this.vm.callFunction(parse, vmJSON, messageStringHandle)),
    );

    parse.dispose();
    vmJSON.dispose();
    messageStringHandle.dispose();

    if (this.creatorHandle) {
      const onMessage = this.vm.getProp(this.creatorHandle, 'onMessage');

      this.vm.callFunction(onMessage, this.creatorHandle, messageAsQuickJSObject);
      onMessage.dispose();
      messageAsQuickJSObject.dispose();
    }
  }

  public id(): string {
    return this.manifest.id;
  }

  public name(): string {
    return this.manifest.name;
  }
}
