/**
 * Copyright 2022 Design Barn Inc.
 */

export const ViewportConfig = {
  CameraZPosition: -50,
  CameraZoom: 1,
  BackgroundWidth: 500,
  BackgroundHeight: 500,
  Margin: 50,
  MinZoom: 0.1,
  MaxZoom: 10,
  RedrawThrottleDelay: 0,
};
