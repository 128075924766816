/**
 * Copyright 2022 Design Barn Inc.
 */

import type React from 'react';
import { useEffect } from 'react';

import { emitter } from '~/lib/emitter';
import { eventHandler } from '~/lib/eventHandler/eventHandler';

export const EmitterListener: React.FC = () => {
  // Subscribe to emitter
  useEffect(() => {
    emitter.onAny(eventHandler);

    return () => {
      emitter.offAny(eventHandler);
    };
  }, []);

  return null;
};
