/**
 * Copyright 2022 Design Barn Inc.
 */

import type React from 'react';
import { useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { saveToCreator } from '~/lib/function/menu';
import { useCreatorStore } from '~/store';
import { NetworkStatus } from '~/store/projectSlice';

const useNetwork = (): [string | null, boolean] => {
  const [currentStatus, backOnline, setBackOnline, setNetwork] = useCreatorStore(
    (state) => [state.project.network, state.project.backOnline, state.project.setBackOnline, state.project.setNetwork],
    shallow,
  );
  const updateNetwork = useCallback(() => {
    const isNetworkOnline = window.navigator.onLine;

    const networkStatus = isNetworkOnline ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;

    if (currentStatus === NetworkStatus.OFFLINE && networkStatus === NetworkStatus.ONLINE) {
      setBackOnline(true);
    } else {
      setBackOnline(false);
    }

    if (currentStatus !== networkStatus) {
      setNetwork(networkStatus);
    }
  }, [currentStatus, setBackOnline, setNetwork]);

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return [currentStatus, backOnline];
};

export const NetworkListener: React.FC = () => {
  const [status, backOnline] = useNetwork();
  const saveProject = async (): Promise<void> => {
    const setAlert = useCreatorStore.getState().ui.setAlert;

    const doneSaved = await saveToCreator();

    if (doneSaved) {
      setAlert({
        text: 'Awesome, you’re back online! We’ve saved your work 🎉',
        alertColor: '#00951D',
        timeout: 5000,
        handleText: null,
      });
    }
  };

  useEffect(() => {
    if (backOnline) {
      // When users switch from offline to online
      saveProject();
    }
  }, [status, backOnline]);

  return null;
};
