/**
 * Copyright 2023 Design Barn Inc.
 */

import type { StarShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getDrawableShapeMethods } from './drawable-shape';
import { getShapeMethods } from './shape';
import { getSplitPositionMethods } from './split-position';

const methodNames = {
  absolutePosition: 'absolutePosition',
  direction: 'direction',
  numPoints: 'numPoints',
  outerRadius: 'outerRadius',
  outerRoundness: 'outerRoundness',
  rotation: 'rotation',
  setAbsolutePosition: 'setAbsolutePosition',
  setDirection: 'setDirection',
  setPoints: 'setPoints',
  setOuterRadius: 'setOuterRadius',
  setOuterRoundness: 'setOuterRoundness',
  setRotation: 'setRotation',
};

function getStarShapeMethods(plugin: Plugin, star: StarShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, star);
}

export function createStarShapeObject(plugin: Plugin, star: StarShape): object {
  const starShapeObj = {
    nodeId: star.nodeId,
    nodeType: star.nodeType,
    type: star.type,
  };

  registerObjectMethods(plugin, star, starShapeObj, [
    getNodeMethods,
    getDrawableShapeMethods,
    getSplitPositionMethods,
    getShapeMethods,
    getStarShapeMethods,
  ]);

  return starShapeObj;
}
