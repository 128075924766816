/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedColorProperty, Layer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  refreshChildrenOrder: 'refreshChildrenOrder',
  colors: 'colors',
  // TODO: implement when supported by Creator
  // setAutoOrient: 'setAutoOrient',
  // autoOrient: 'autoOrient',
};

// TODO: create shim interfaces instead of importing from toolkit
export interface ColorsInterface {
  fillProps: AnimatedColorProperty[];
  strokeProps: AnimatedColorProperty[];
}

export function getAvLayerMethods(plugin: Plugin, layer: Layer): ObjectMethods {
  return getObjectMethods(plugin, methodNames, layer);
}
