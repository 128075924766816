/**
 * Copyright 2023 Design Barn Inc.
 */

import type { HoldInterpolator } from '@lottiefiles/toolkit-js';

import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { Plugin } from '~/plugins/Plugin';

const methodNames = {
  interpolate: 'interpolate',
  toJSON: 'toJSON',
};

function getHoldInterpolatorMethods(plugin: Plugin, interpolator: HoldInterpolator): ObjectMethods {
  return getObjectMethods(plugin, methodNames, interpolator);
}

interface HoldInterpolatorJSON {
  interpolatorType: string;
}

export function createHoldInterpolatorObject(plugin: Plugin, interpolator: HoldInterpolator): HoldInterpolatorJSON {
  const interpolatorObject = {
    interpolatorType: 'Hold',
  };

  registerObjectMethods(plugin, interpolator, interpolatorObject, [getHoldInterpolatorMethods]);

  return interpolatorObject;
}
