/**
 * Copyright 2023 Design Barn Inc.
 */

import { BufferGeometry, Color, EdgesGeometry, Vector3 } from 'three';
import type { Shape } from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

import type { StrokeProperty } from './type';

import { CMesh } from '~/features/canvas';

export const getStroke = (settings: StrokeProperty, mesh: CMesh): CMesh | Line2 => {
  const { color, opacity, width: strokeWidth } = settings;
  // When keyframes are removed, 'value' shouldn't return undefined. Toolkit will resolve this on next update.
  // TODO: Revert to 'value' when toolkit updates. Temporary fix:
  const { b, g, r } = color;

  const parameterObj = (mesh.geometry as unknown) as {
    parameters:
      | {
          shapes: Shape | undefined;
        }
      | undefined;
  };

  let geometry: BufferGeometry;

  if (parameterObj.parameters?.shapes) {
    const extractedPoints = parameterObj.parameters.shapes.extractPoints(10).shape;
    const points = extractedPoints.map((element) => new Vector3(element.x, element.y, 0));

    geometry = new BufferGeometry().setFromPoints(points);
  } else if (mesh.geometry.type === 'BoxGeometry') {
    const edges = new EdgesGeometry(mesh.geometry);
    const lineGeometry = new LineGeometry().setPositions(edges.getAttribute('position').array as number[]);

    const lineMaterial = new LineMaterial({
      linewidth: strokeWidth.value / 1500,
      vertexColors: false,
      dashed: false,
      alphaToCoverage: false,
      wireframe: false,
      transparent: true,
      opacity,
      gapSize: 0,
      visible: true,
    });

    lineMaterial.color = new Color(`rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)})`);

    return new Line2(lineGeometry, lineMaterial);
  } else {
    geometry = mesh.geometry;
  }

  const line = new MeshLine();

  line.setGeometry(geometry);

  const material = new MeshLineMaterial({
    color: new Color(`rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)})`),
    lineWidth: strokeWidth.value / 2,
    transparent: true,
    opacity,
  });

  return new CMesh(line.geometry, material);
};
