/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useRef } from 'react';

import type { Option } from './Select';

import type { NumberResult } from '~/components/Elements/Input/';
import { useNumberInput } from '~/components/Elements/Input/useNumberInput';
import { countNumDigit } from '~/utils';

export interface InputSelectOption {
  max?: number;
  min?: number;
  name?: string;
  postFix?: string;
  precision?: number;
}

interface InputSelectProps {
  inputOption?: InputSelectOption;
  onInputChange: (result: NumberResult) => void;
  selected: Option;
}

export const SelectInput: React.FC<InputSelectProps> = ({ inputOption, onInputChange, selected }: InputSelectProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const inputValue = parseFloat(selected.btnLabel || selected.label);

  const name = inputOption?.name || 'inputSelect';
  const postFix = inputOption?.postFix || '';

  const [input, { handleInputChange, handleOnBlur }] = useNumberInput({
    name,
    value: inputValue,
    // TODO: check where does magic numbers are from and what are they for?
    min: inputOption?.min || 0,
    max: inputOption?.max || 10,
    precision: inputOption?.precision || 3,
    inputRef,
    onChange: onInputChange,
  });

  return (
    <div className="absolute left-[31px] top-0" onBlur={handleOnBlur}>
      <input
        autoComplete="off"
        ref={inputRef}
        name={name}
        value={input}
        style={{ width: `${countNumDigit(input)}ch` }}
        onChange={handleInputChange}
        onClick={() => inputRef.current?.select()}
        className="number-input relative inline border-none bg-transparent p-0 pt-[1px] text-xs outline-none"
      />

      {postFix && <span className="text-xs"> {postFix}</span>}
    </div>
  );
};
