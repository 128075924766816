/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PrecompositionAsset } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { getComposableMethods } from '../scene/composable';
import { getHasTimelineMethods } from '../scene/has-timeline';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  // fromJSON: 'fromJSON',
  frameRate: 'frameRate',
  setFrameRate: 'setFrameRate',
  // toJSON: 'toJSON',
};

function precompAssetMethods(plugin: Plugin, precompAsset: PrecompositionAsset): ObjectMethods {
  return getObjectMethods(plugin, methodNames, precompAsset);
}

export function createPrecompAssetObj(plugin: Plugin, precompAsset: PrecompositionAsset): object {
  const precompAssetObj = {
    nodeId: precompAsset.nodeId,
    nodeType: precompAsset.nodeType,
    type: precompAsset.type,
  };

  registerObjectMethods(plugin, precompAsset, precompAssetObj, [
    getNodeMethods,
    getComposableMethods,
    getHasTimelineMethods,
    precompAssetMethods,
  ]);

  return precompAssetObj;
}
