/**
 * Copyright 2022 Design Barn Inc.
 */

export const rgbaToHex = (params: Array<string | number>): string => {
  if (!Array.isArray(params)) return '';

  if (params.length < 3 || params.length > 4) return '';

  // eslint-disable-next-line id-length
  const parts = params.map((e: string | number) => {
    let r = Number(e).toString(16);

    if (r.length === 1) r = `0${r}`;

    return r;
  }, []);

  // eslint-disable-next-line no-negated-condition, no-implicit-coercion
  return !~parts.indexOf('NaN') ? `#${parts.join('')}` : '';
};

export default rgbaToHex;
