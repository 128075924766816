/**
 * Copyright 2021 Design Barn Inc.
 */

/* eslint-disable no-secrets/no-secrets */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import {
  Community,
  Discord,
  HelpCenter,
  Keyboard,
  LottieCompatibility,
  QuestionMark,
  QuestionMarkActive,
  ReportBug,
  RequestFeature,
  Slack,
  Twitter,
  VideoTutorials,
  WhatsNew,
} from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { OLVY_WORKSPACE, OLVY_WHATSNEW_WIDGET } from '~/config';
import { KeyboardShortcutsModal } from '~/features/keyboard-shortcuts';
import { useUsersnapApi } from '~/providers/UsersnapProvider';
import { useCreatorStore } from '~/store';
import { waitForElm } from '~/utils';

declare global {
  interface Window {
    OlvyUtils: {
      setUser: (
        widget: string,
        data: {
          designation?: string;
          email: string;
          identifier: string;
          meta?: Record<string, unknown>;
          name: string;
          tenant?: string;
        },
      ) => void;
      showWidget: (widget: string, widgetId: string) => void;
    };
  }
}

const Links = {
  whatsnew: 'whatsnew',
  videoTutorials: 'https://lottiefiles.com/tutorials/category/lottie-creator-basics',
  lottieCompatibility:
    'https://help.lottiefiles.com/hc/en-us/articles/15386214015769-What-features-of-Lottie-are-currently-supported-by-Lottie-Creator-',
  // changeLog: 'https://lottiefiles.notion.site/Lottie-Creator-Changelog-0aa18666001d4a26a97206c7955eb0ce',
  keyShortcut: 'https://lottiefiles.notion.site/Keyboard-shortcuts-e370a87aa1ce4c249701c9ff41f9ba28',
  helpCenter: 'https://lottiefiles.notion.site/Lottie-Creator-ca8f1d3fd8a54fdea379a2dd3a381d0b',
  reportBug: 'reportBug',
  requestFeature: 'https://feedback.lottiefiles.com/creator',
  contactUs: 'https://lottiefiles.zendesk.com/hc/en-us/requests/new',
  community: 'https://lottiefiles.com/community',
  twitter: 'https://twitter.com/lottiefiles',
  discord: 'https://discord.com/invite/6K3fm7yE9v',
  slack: 'https://join.slack.com/t/lottiecommunity/shared_invite/zt-20zby41n5-0P0QW3d0tQR_yGzngKvT_A',
};

const iconStyle = (active: boolean): string => `ml-1 mr-2.5 h-4 w-4 ${active ? 'text-white' : 'text-gray-500'}`;

export const Help: React.FC = () => {
  // Handle UserSnap
  const snapAPI = useUsersnapApi();

  const handleReportBug = useCallback(async () => {
    // Feedback behaviour: Prod should always use Olvy, non-prod should use Usersnap
    // Note: this has changed now, Prod and non-prod should both use Usersnap
    // Commenting this out for now in case in the future we decide to revert on this decision
    // if (IS_PROD) {
    // if (typeof window.OlvyUtils !== 'undefined' && window.OlvyUtils.showWidget) {
    // window.OlvyUtils.showWidget(OLVY_WORKSPACE, OLVY_FEEDBACK_WIDGET);
    // }
    // all other cases, use usersnap
    // } else
    if (snapAPI) {
      (snapAPI as unknown).open();
    }
  }, [snapAPI]);

  // Handle Olvy
  const handleWhatsNew = useCallback(async () => {
    if (typeof window.OlvyUtils !== 'undefined' && window.OlvyUtils.showWidget) {
      // Wait for this dom element to load
      await waitForElm(`olvy-modal-${OLVY_WHATSNEW_WIDGET}`);
      window.OlvyUtils.showWidget(OLVY_WORKSPACE, OLVY_WHATSNEW_WIDGET);
    }
  }, []);

  // Handle link clicked
  const handleLinkClicked = useCallback((link: string): void => {
    window.open(link);
  }, []);

  const [keyboardShortcutsModalOpen, setIsKeyboardShortcutsModalOpen] = useState(false);
  const showKeyboardShortcuts = useCallback(() => {
    setIsKeyboardShortcutsModalOpen(true);
  }, []);
  const onKeyboardShortcutModalClose = useCallback(() => {
    setIsKeyboardShortcutsModalOpen(false);
  }, []);

  const DropDownList1 = [
    {
      title: "What's new",
      icon: <WhatsNew />,
      link: Links.whatsnew,
      onClick: handleWhatsNew,
    },
    {
      title: 'Video tutorials',
      icon: <VideoTutorials />,
      link: Links.videoTutorials,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Help Center',
      icon: <HelpCenter />,
      link: Links.helpCenter,
      onClick: (link: string) => handleLinkClicked(link),
    },

    {
      title: 'Lottie compatibility',
      icon: <LottieCompatibility />,
      link: Links.lottieCompatibility,
      onClick: (link: string) => handleLinkClicked(link),
    },

    {
      title: 'Keyboard shortcuts',
      icon: <Keyboard />,
      link: Links.keyShortcut,
      onClick: (_link: string) => showKeyboardShortcuts(),
    },

    {
      title: 'Report a bug',
      icon: <ReportBug />,
      link: Links.reportBug,
      onClick: handleReportBug,
    },
    {
      title: 'Request feature',
      icon: <RequestFeature />,
      link: Links.requestFeature,
      onClick: (link: string) => handleLinkClicked(link),
    },
  ];

  const DropDownList2 = [
    {
      title: 'Community',
      icon: <Community />,
      link: Links.community,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Twitter',
      icon: <Twitter />,
      link: Links.twitter,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Discord',
      icon: <Discord />,
      link: Links.discord,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Slack',
      icon: <Slack />,
      link: Links.slack,
      onClick: (link: string) => handleLinkClicked(link),
    },
  ];

  // Commenting this out instead of removing, in case we u-turn on this decision
  // const openModalFirstTime = (): void => {
  //   // NOTE: On how Olvy works:
  //   // Olvy modal doesn’t show the first time if that value is not stored in local storage
  //   // Olvy will check localStorage key, to see if there's been a new update since
  //   // No extra handling required on our end
  //   const olvyLottiefilesLastOpened = localStorage.getItem(WHATSNEW_LAST_OPENED) || null;

  //   if (!olvyLottiefilesLastOpened) {
  //     handleWhatsNew();
  //   }
  // };

  const setOlvyUser = (): void => {
    // Get user info from state
    const currentUser = useCreatorStore.getState().user.info;

    if (typeof currentUser.id !== 'undefined' && typeof window.OlvyUtils !== 'undefined') {
      window.OlvyUtils.setUser(OLVY_WORKSPACE, {
        name: currentUser.name,
        email: currentUser.email,
        identifier: currentUser.id,
      });
    }
  };

  useEffect(() => {
    setOlvyUser();
    // Note: Deprecating Olvy eventually, for now we remove the auto-popup behaviour
    // openModalFirstTime();
  });

  return (
    <>
      <KeyboardShortcutsModal onClose={onKeyboardShortcutModalClose} isOpen={keyboardShortcutsModalOpen} />
      <Menu as="div" className="relative inline-block w-[24px]">
        {({ open }) => (
          <>
            <Tooltip offset={{ mainAxis: 7, crossAxis: 0 }} label="Help" placement="bottom">
              <Menu.Button className="group flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded hover:bg-gray-700">
                {React.cloneElement(open ? <QuestionMarkActive /> : <QuestionMark />, {
                  className:
                    'h-[16px] w-[16px] cursor-pointer fill-current text-gray-400 hover:bg-gray-700 group-hover:text-white',
                })}
              </Menu.Button>
            </Tooltip>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-dropdown mt-2 w-40 origin-top-right divide-y rounded-md bg-gray-700 p-2 shadow-dropdown ring-black focus:outline-none">
                {DropDownList1.map((dropdown) => {
                  return (
                    <Menu.Item key={dropdown.title}>
                      {({ active }) => (
                        <button
                          className={
                            'group mt-1 flex h-6 w-full items-center rounded-md border-gray-700 p-0.5 text-xs leading-normal text-white hover:border-gray-600 hover:bg-gray-600'
                          }
                          onClick={() => dropdown.onClick(dropdown.link)}
                        >
                          {React.cloneElement(dropdown.icon, {
                            className: iconStyle(active),
                          })}
                          {dropdown.title}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
                {<div className="-mx-2 my-1 border-r border-gray-500" />}
                {DropDownList2.map((dropdown) => {
                  return (
                    <Menu.Item key={dropdown.title}>
                      {({ active }) => (
                        <button
                          className={
                            'group mt-1 flex h-6 w-full items-center rounded-md border-gray-700 p-0.5 text-xs leading-normal text-white hover:border-gray-600 hover:bg-gray-600'
                          }
                          onClick={() => dropdown.onClick(dropdown.link)}
                        >
                          {React.cloneElement(dropdown.icon, {
                            className: iconStyle(active),
                          })}
                          {dropdown.title}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
