/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Keyframe, Scene } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../Plugin';

import { createSceneObject } from './scene';
import { createKeyframeObj } from './time/keyframe';
import { newQuickjsObject } from './vmInterface/marshal/object';

import { stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export function getCurrentScene(plugin: Plugin): void {
  const getCurrentSceneHandle = plugin.scope.manage(
    plugin.vm.newFunction(
      'getCurrentScene',
      (): QuickJSHandle => {
        const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
        const currentScene = toolkit.scenes[sceneIndex] as Scene;

        const strippedScene = createSceneObject(plugin, currentScene);
        const vmStrippedScene = newQuickjsObject(plugin, strippedScene, true);

        return vmStrippedScene;
      },
    ),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getCurrentScene', {
    value: getCurrentSceneHandle,
    configurable: false,
  });
}

export function getKeyFrameById(plugin: Plugin): void {
  const getKeyFrameByIdHandle = plugin.scope.manage(
    plugin.vm.newFunction(
      'getKeyFrameById',
      (vmFrameId: QuickJSHandle): QuickJSHandle => {
        const frameId = plugin.vm.getString(vmFrameId);
        const keyframe = toolkit.getKeyframeById(frameId) as Keyframe;
        const vmKeyframe = newQuickjsObject(plugin, createKeyframeObj(plugin, keyframe), true);

        return vmKeyframe;
      },
    ),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getKeyFrameById', {
    value: getKeyFrameByIdHandle,
    configurable: false,
  });
}

export function getSelectedKeyFrame(plugin: Plugin): void {
  const getSelectedKeyFrameHandle = plugin.scope.manage(
    plugin.vm.newFunction(
      'getSelectedKeyFrame',
      (): QuickJSHandle => {
        const sceneKeyframes = useCreatorStore.getState().timeline.selectedKeyframes;
        let vmKeyframe;

        if (sceneKeyframes[0]) {
          const frameId = useCreatorStore.getState().timeline.selectedKeyframes[0];
          const keyframe = toolkit.getKeyframeById(frameId as string) as Keyframe;

          vmKeyframe = newQuickjsObject(plugin, createKeyframeObj(plugin, keyframe), true);
        }

        return vmKeyframe as QuickJSHandle;
      },
    ),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getSelectedKeyFrame', {
    value: getSelectedKeyFrameHandle,
    configurable: false,
  });
}

export function beginAction(plugin: Plugin): void {
  const beginActionHandle = plugin.scope.manage(
    plugin.vm.newFunction('beginAction', (): void => {
      stateHistory.beginAction();
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'beginAction', {
    value: beginActionHandle,
    configurable: false,
  });
}

export function endAction(plugin: Plugin): void {
  const endActionHandle = plugin.scope.manage(
    plugin.vm.newFunction('endAction', (): void => {
      stateHistory.endAction();
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'endAction', {
    value: endActionHandle,
    configurable: false,
  });
}

// TODO: replace `beginAction` and `endAction` with `batchAction`
// TOFIX: doesn't work if 'creator.batchAction' is wrapped in `creator.onMessage = function (message: any) {...}`
// as 'message' won't be accessible
export function batchAction(plugin: Plugin): void {
  const batchActionHandle = plugin.scope.manage(
    plugin.vm.newFunction('batchAction', (vmCallback: QuickJSHandle): void => {
      const callback = plugin.vm.getString(vmCallback);

      stateHistory.beginAction();
      plugin.vm.evalCode(`(${callback}())()`);
      stateHistory.endAction();
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'batchAction', {
    value: batchActionHandle,
    configurable: false,
  });
}
