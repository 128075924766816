/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

/* Define icon since it is only used in this component */
export const AlignLeft: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.668 13.333V2.666" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.335 8.666H4.668v2.667h8.667V8.666ZM10.001 4.666H4.668v2h5.333v-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignCenter: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 13.333V2.666" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.665 8.666H3.332v2.667h9.333V8.666ZM10.665 4.666H5.332v2h5.333v-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignRight: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.332 13.333V2.666" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.665 8.666h8.667v2.667H2.665V8.666ZM5.999 4.666h5.333v2H5.999v-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignTop: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.165 2.666h10.667" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.832 13.333V4.666H9.165v8.667h2.667ZM7.168 10V4.665h-2v5.333h2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignMiddle: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.835 8H3.168" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.168 3.333v9.333h2.667V3.333H9.168ZM5.168 5.333v5.333h2V5.333h-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignBottom: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.835 13.334H3.168" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.168 2.667v8.667h2.667V2.667H9.168ZM5.168 6v5.334h2V6.001h-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
