/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import { useJitsu } from '@jitsu/react';
import React, { Fragment } from 'react';

import type { MenuDropdownProp } from './CreatorMenu';

import type { EventStorePayload } from '~/utils';

interface SubMenuProp {
  subItems: MenuDropdownProp[];
}

export const SubMenu: React.FC<SubMenuProp> = ({ subItems }: SubMenuProp) => {
  const { track } = useJitsu();

  const handleClick = (callback: () => void, eventParams?: EventStorePayload): void => {
    if (eventParams) {
      track(eventParams.event_type, {
        ...eventParams,
      });
    }
    callback();
  };

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu as="div" className="absolute right-[-103%] top-[-6px] inline-block w-full">
        {() => (
          <>
            <div className="absolute z-dropdown mt-1 w-[132px] cursor-pointer rounded-lg border border-gray-600 bg-gray-800 p-2 shadow-dropdown focus:outline-none">
              {subItems.map((child: MenuDropdownProp, index: number) => (
                <div key={child.type}>
                  <Menu.Item>
                    <button
                      className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                        index === subItems.length - 1 && 'rounded-b'
                      } rounded p-2 text-xs text-white hover:bg-gray-600`}
                      onClick={() => handleClick(child.callback, child.trackParams)}
                    >
                      {child.label}
                      <span className="absolute right-[16px] text-gray-500">{child.shortcut}</span>
                    </button>
                  </Menu.Item>
                </div>
              ))}
            </div>
          </>
        )}
      </Menu>
    </Transition>
  );
};
