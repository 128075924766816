/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

export class UploadError extends Error {
  public actionMessage: string;

  constructor(message: string) {
    super(message);
    this.name = 'Upload Error';
    this.actionMessage = '';
  }
}

export class InvalidFileFormatError extends UploadError {
  constructor(message: string) {
    super(message);
    this.name = 'Invalid file format';
    this.actionMessage = 'We only support Lottie JSON / dotLottie and SVG format';
  }
}

export class UnknownError extends UploadError {
  constructor(message: string) {
    super(message);
    this.name = 'Something went wrong';
    this.actionMessage = 'Please upload your animation again';
  }
}

export class InvalidURL extends UploadError {
  constructor(message: string) {
    super(message);
    this.name = 'Invalid URL';
    this.actionMessage = 'Please use a valid URL';
  }
}
