/**
 * Copyright 2022 Design Barn Inc.
 */

import { colord } from 'colord';
import { throttle } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { parseHex } from '../ColorPicker/utils/parseHex';

import { ColorPicker } from '~/components/Elements/ColorPicker';
import { isValidHex } from '~/components/Elements/ColorPicker/utils';
import { stateHistory } from '~/lib/toolkit';

interface ColorInputProps {
  color: string;
  colorPicker?: boolean;
  colorStyleClass?: string;
  disabled?: boolean;
  hasKeyframe?: boolean;
  inputStyleClass?: string;
  keyFrameStyleClass?: string;
  onChangeColor: (color: string) => void;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOpacity: (opacity: number) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyframeClick?: () => void;
  opacity: number;
  overrideStyle?: Record<string, string | number | boolean>;
  shapeType?: string;
  showKeyframe?: boolean;
  styleClass?: string;
  type?: string;
}

export const ColorInput: React.FC<ColorInputProps> = ({
  color,
  colorPicker = true,
  colorStyleClass = '',
  disabled,
  hasKeyframe = false,
  inputStyleClass = '',
  onChangeColor,
  onChangeOpacity,
  onKeyDown,
  onKeyframeClick,
  opacity,
  shapeType = '',
  overrideStyle = {},
  showKeyframe = false,
  styleClass = '',
  type,
}) => {
  const [colorString, setColorString] = useState(color);
  const [draggingEvent, setDraggingEvent] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const prevColorStringRef = useRef(colorString);

  const updateColor = useCallback(
    (colorValue: string): void => {
      onChangeColor(parseHex(colorValue));
      prevColorStringRef.current = colorValue.length < 6 ? colord(`#${colorValue}`).toHex() : colorValue;
    },
    [onChangeColor],
  );

  const updateColorString = useCallback(
    (newColor: string) => {
      if (colorString.replace('#', '').length === 6) setColorString(newColor.replace('#', ''));
      prevColorStringRef.current = newColor.replace('#', '');
    },
    [colorString],
  );

  const updateColorStringThrottled = useRef(throttle((newColor: string) => updateColorString(newColor), 75));

  useEffect(() => {
    // 1. updates colorString if color is set with Color Picker
    // and when the timeline is playing
    // 2. the throttling reduces rerenders when the timeline is playing
    // and the prop panel is open
    updateColorStringThrottled.current(color);
  }, [color]);

  const onChangeInput = useCallback(
    (evt: React.FormEvent<HTMLInputElement>): void => {
      const value = evt.currentTarget.value;

      setColorString(value);

      if (isValidHex(parseHex(value))) updateColor(parseHex(value));
    },
    [updateColor],
  );

  const handlePaste = useCallback(
    (evt: React.ClipboardEvent<HTMLInputElement>): void => {
      evt.preventDefault();

      const pasteValue = evt.clipboardData.getData('text').replace('#', '');

      setColorString(pasteValue);
      if (isValidHex(pasteValue)) updateColor(pasteValue);
    },
    [updateColor],
  );

  const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    inputRef.current?.select();
  }, []);

  const handleBlur = useCallback((): void => {
    if (isValidHex(parseHex(colorString)) && colorString.replace('#', '').length < 6) {
      const sixDigitHex = colord(parseHex(colorString)).toHex().replace('#', '');

      setColorString(sixDigitHex);
      updateColor(sixDigitHex);
    } else {
      setColorString(prevColorStringRef.current.replace('#', ''));
    }
  }, [colorString, updateColor]);

  const handleColorPickerChange = useCallback(
    (rgbValue: string): void => {
      const colorStringHex = colord(rgbValue).toHex().replace('#', '');

      if (!draggingEvent) {
        stateHistory.beginAction();
      }
      updateColor(colorStringHex);
      setColorString(colorStringHex);
      setDraggingEvent(true);
    },
    [updateColor, setDraggingEvent, draggingEvent],
  );

  const handleStopColorPickerChange = useCallback(
    (rgbValue: string): void => {
      const colorStringHex = colord(rgbValue).toHex();

      updateColor(colorStringHex);
      stateHistory.endAction();
      setDraggingEvent(false);
    },
    [updateColor, setDraggingEvent],
  );

  const colorInputStyle = `
    relative flex items-center rounded border border-transparent bg-gray-700 p-1 text-xs font-normal ${styleClass} ${
    shapeType === 'TestAnimationBackground' ? '' : 'number-input-label h-[24px] w-[84px] focus-within:border-teal-500'
  }
  `;

  return (
    <div className={colorInputStyle}>
      {colorPicker && (
        <ColorPicker
          styleClass={colorStyleClass}
          type={type as string}
          shapeType={shapeType as string}
          color={color}
          opacity={opacity}
          onChangeColor={handleColorPickerChange}
          onStopColor={handleStopColorPickerChange}
          onChangeOpacity={onChangeOpacity}
          showKeyframe={showKeyframe}
          hasKeyframe={hasKeyframe}
          onKeyframeClick={onKeyframeClick as () => void}
          overrideStyle={overrideStyle}
        />
      )}

      <input
        name="color"
        disabled={disabled}
        className={`number-input w-[55px] bg-gray-700 pl-1 text-left text-xs font-normal focus:outline-none ${inputStyleClass}`}
        value={type ?? colorString.toUpperCase().slice(0, 6)}
        onChange={(event) => onChangeInput(event)}
        onKeyDown={onKeyDown}
        onClick={handleOnClick}
        onPaste={handlePaste}
        spellCheck={false}
        onBlur={handleBlur}
      ></input>
    </div>
  );
};
