/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type { AnimatedPropertiesJSON, ColorJSON, ShapeJSON, GradientJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import { colord } from 'colord';
import React from 'react';

import {
  LayerThumbnailShape,
  LayerThumbnailGroup,
  LayerThumbnailPath,
  LayerThumbnailEllipse,
  LayerThumbnailNull,
  LayerThumbnailRectangle,
  LayerThumbnailText,
  LayerThumbnailFill,
  LayerThumbnailGradient,
  LayerThumbnailStroke,
  LayerThumbnailPolygon,
  LayerThumbnailStar,
  LayerThumbnailPrecomp,
  LayerThumbnailImage,
  LayerThumbnailSolid,
} from '~/assets/icons/timeline';
import { convertToGradientCss } from '~/lib/toolkit';

interface ThumbnailProps {
  animatedProperties?: AnimatedPropertiesJSON;
  className?: string;
  icon: React.ReactElement;
  type: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = ({ animatedProperties, className, icon, type }) => {
  let color = '';

  if (animatedProperties) {
    if (animatedProperties.cl) {
      // When keyframes are removed, 'value' shouldn't return undefined. Toolkit will resolve this on next update.
      // TODO: Revert to 'value' when toolkit updates. Temporary fix:
      // const { a: alpha, b, g, r } = animatedProperties.cl.value as ColorJSON;
      const { a: alpha, b, g, r } =
        (animatedProperties.cl.value as ColorJSON) ?? (animatedProperties.cl.staticValue as ColorJSON);

      color = colord({ r, g, b, a: alpha }).toRgbString();
    } else if (animatedProperties.sc) {
      // TODO: Revert to 'value' when toolkit updates. Temporary fix:
      // const { a: alpha, b, g, r } = animatedProperties.sc.value as ColorJSON;
      const { a: alpha, b, g, r } =
        (animatedProperties.sc.value as ColorJSON) ?? (animatedProperties.sc.staticValue as ColorJSON);

      color = colord({ r, g, b, a: alpha }).toRgbString();
    } else if (animatedProperties.g) {
      const colors = (animatedProperties.g.value as GradientJSON).colors;

      color = convertToGradientCss(colors);
    }
  }

  return (
    <div
      className={clsx(
        `flex h-4 w-4 items-center justify-center rounded-layer-thumbnail bg-gray-800 ${className || ''}`,
        {},
      )}
    >
      {React.cloneElement(icon, {
        className: clsx('h-4 w-4', {
          'stroke-current': type === 'st',
          'fill-current': type === 'fl' || type === 'SOLID',
          'text-gray-50': !animatedProperties,
        }),
        style: {
          color,
        },
      })}
    </div>
  );
};

const PreCompThumbnail: React.FC = () => {
  return (
    <div className={clsx('flex h-4 w-4 items-center justify-center rounded-layer-thumbnail bg-gray-800', {})}>
      <LayerThumbnailPrecomp />
    </div>
  );
};

interface LayerThumbnailProps {
  layer: ShapeJSON;
}

export const LayerThumbnail: React.FC<LayerThumbnailProps> = React.memo(({ layer }) => {
  const { animatedProperties, properties, type } = layer;
  let layerType = type as string;

  // preprocess type as sr contain polygon and star property
  if (type === 'sr') {
    if (properties.sy === 1) {
      layerType = 'star';
    } else if (properties.sy === 2) {
      layerType = 'polygon';
    }
  }

  // Build thumbnail map for conditional rendering
  const thumbnailMap: Record<string, React.ReactNode> = {
    SHAPE: <Thumbnail icon={<LayerThumbnailShape />} type={layerType} />,
    PRECOMPOSITION: <PreCompThumbnail />,
    IMAGE: <Thumbnail icon={<LayerThumbnailImage />} type={layerType} />,
    SOLID: <Thumbnail icon={<LayerThumbnailSolid />} type={layerType} />,
    gr: <Thumbnail icon={<LayerThumbnailGroup />} type={layerType} />,
    GROUP: <Thumbnail icon={<LayerThumbnailGroup />} type={layerType} />,
    sh: <Thumbnail icon={<LayerThumbnailPath />} type={layerType} />,
    rc: <Thumbnail icon={<LayerThumbnailRectangle />} type={layerType} />,
    el: <Thumbnail icon={<LayerThumbnailEllipse />} type={layerType} />,
    star: <Thumbnail icon={<LayerThumbnailStar />} type={layerType} />,
    polygon: <Thumbnail icon={<LayerThumbnailPolygon />} type={layerType} />,
    TEXT: <Thumbnail icon={<LayerThumbnailText />} type={layerType} />,
    NULL: <Thumbnail icon={<LayerThumbnailNull />} type={layerType} />,
    fl: <Thumbnail icon={<LayerThumbnailFill />} type={layerType} animatedProperties={animatedProperties} />,
    st: <Thumbnail icon={<LayerThumbnailStroke />} type={type} animatedProperties={animatedProperties} />,
    gf: <Thumbnail icon={<LayerThumbnailGradient />} type={layerType} animatedProperties={animatedProperties} />,
  };

  const ThumbnailView = thumbnailMap[layerType] ?? null;

  return <>{ThumbnailView}</>;
});
