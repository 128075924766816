/**
 * Copyright 2022 Design Barn Inc.
 */

import type { SizeJSON } from '@lottiefiles/toolkit-js';
import { ShapeType, Vector, Color, Percentage } from '@lottiefiles/toolkit-js';
import { Mesh, DoubleSide, MeshBasicMaterial, PlaneGeometry } from 'three';
import GifLoader from 'three-gif-loader';

// eslint-disable-next-line import/extensions
import GifLoading from '~/assets/animations/lottie-animation-loading.gif';
import { CObject3D } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { Scalar2D } from '~/lib/toolkit';
import { toolkit } from '~/lib/toolkit';
import type { RectangleOption } from '~/lib/toolkit/rectangle';
import { createRectangle } from '~/lib/toolkit/rectangle';
import { useCreatorStore } from '~/store';
import type { AnimationLoader } from '~/store/uiSlice';

// NOTE: Put loader and texture outside the function:
// This will keep loading UI visible when page loaded.
// So users with very slow internet speed can see the loading UI.
const loader = new GifLoader();
const texture = loader.load(
  GifLoading,

  // onLoad callback
  () => {
    // You probably don't need to set onLoad, as it is handled for you. However,
    // if you want to manipulate the reader, you can do so here:
  },

  // onProgress callback
  () => {
    // console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
  },

  // onError callback
  () => {
    // console.error('An error happened.');
  },
);

texture.repeat.set(2, 2);
texture.center.set(0.5, 0.5);

export const stopLoading = (id: string): void => {
  if (id) {
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event: EmitterEvent.CANVAS_OBJECT_REMOVED, data: { id } });
    emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_DETACHED);
  }

  // emit event so it will fallthrough to the default case to get the latest toolkit state
  emitter.emit(EmitterEvent.LOADED_ANIMATION);
};

export const startLoading = (): void => {
  const canvasWidth = (useCreatorStore.getState().toolkit.json?.properties.sz as SizeJSON).w as number;
  const canvasHeight = (useCreatorStore.getState().toolkit.json?.properties.sz as SizeJSON).h as number;

  const position: Scalar2D = [canvasWidth / 2, canvasHeight / 2];

  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const scene = toolkit.scenes[sceneIndex];

  if (!scene) return;

  const rectangleOptions = {
    type: ShapeType.RECTANGLE,
    fill: [255, 255, 255, 1],
    startFrame: 0,
    endFrame: 150,
    name: 'Rectangle',
    rotation: 0,
    position: [0, 0],
    rectangle: { size: [canvasWidth / 2, canvasHeight / 2], roundness: 0 },
  };

  const { fill } = rectangleOptions;
  const outPoint = useCreatorStore.getState().toolkit.json?.timeline.properties.op as number;
  const currentFrame = useCreatorStore.getState().toolkit.currentFrame as number;

  const shapeLayer = scene.createShapeLayer().setStartAndEndFrame(currentFrame, outPoint).setName('Loading...');

  shapeLayer.setOpacity(new Percentage(10));

  const groupShape = shapeLayer.createGroupShape().setPosition(new Vector(position[0], position[1])).setName('Group');

  const shape = createRectangle(scene, groupShape, rectangleOptions.rectangle as RectangleOption);

  shape.setName('Group');

  groupShape.createFillShape().setColor(new Color(fill[0], fill[1], fill[2], fill[3])).setName('Fill');

  groupShape.setOpacity(new Percentage(10));

  const nodeId = shapeLayer.nodeId ? shapeLayer.nodeId : null;

  const setAnimationLoader = useCreatorStore.getState().ui.setAnimationLoader;

  setAnimationLoader({ objectId: nodeId } as AnimationLoader);

  emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event: EmitterEvent.LOADING_ANIMATION, data: { id: nodeId } });
};

export const getGifTexture = (): CObject3D => {
  const plane = new Mesh(new PlaneGeometry(250, 250), new MeshBasicMaterial({ transparent: true }));

  plane.material.transparent = true;
  plane.material.side = DoubleSide;
  plane.material.map = texture;
  plane.position.set(250, 250, 0);
  plane.rotation.z = Number(-Math.PI);

  return (plane as unknown) as CObject3D;
};

export const getLoadingGif = (width: number, height: number): CObject3D => {
  const loadingGif = new CObject3D();

  loadingGif.displayOnly = true;

  loadingGif.visible = true;
  loadingGif.renderOrder = 1;

  loadingGif.position.z = 10000;
  loadingGif.position.x = width / 2;
  loadingGif.position.y = height / 2;

  const plane = getGifTexture();

  loadingGif.add(plane);

  return loadingGif;
};
