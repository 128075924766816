/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useState } from 'react';

import { useExportLottie } from './TestAnimationDialog';
import { TestAnimationPlayer } from './TestAnimationPlayer';

import { useCreatorStore } from '~/store';

export const HiddenThumbnailPlayer: React.FC = () => {
  const openHiddenPlayer = useCreatorStore((state) => state.ui.openHiddenPlayer);

  const [lottieJSON, setLottieJSON] = useState<unknown>('');

  const { exportJSON } = useExportLottie(setLottieJSON);

  useEffect(() => {
    exportJSON();
  }, [exportJSON]);

  return (
    <>
      {openHiddenPlayer && lottieJSON && (
        <div style={{ position: 'absolute', bottom: 0 }}>
          <TestAnimationPlayer forThumbnail={true} hidden={true} json={lottieJSON as Record<string, never>} />
        </div>
      )}
    </>
  );
};
