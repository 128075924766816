/**
 * Copyright 2023 Design Barn Inc.
 */

import { Vector3 } from 'three';

import { Box3 } from '../Box3';
import { Box3Helper } from '../Box3Helper';

import pivotCursorImg from './pivotCursor.svg';
import rotateImg from './rotate.svg';
import scaleImg from './scale.svg';
import translateImg from './translate.svg';

export interface Pointer {
  button?: number;
  x: number;
  y: number;
}

export enum TransformType {
  Pivot = 'Pivot',
  Rotation = 'Rotation',
  Scale = 'Scale',
  Translation = 'Translation',
}

export const cursorUrls: Record<TransformType, string> = {
  [TransformType.Translation]: translateImg,
  [TransformType.Scale]: scaleImg,
  [TransformType.Rotation]: rotateImg,
  [TransformType.Pivot]: pivotCursorImg,
};

export const cursorBaseAngles: Record<TransformType, { [tag: string]: number } | null> = {
  [TransformType.Translation]: {
    translateXY: 0,
  },
  [TransformType.Scale]: {
    ScaleX1: 90,
    ScaleX2: 90,
    ScaleY1: 0,
    ScaleY2: 0,
    ScaleTR: 45,
    ScaleBL: 45,
    ScaleTL: -45,
    ScaleBR: -45,
  },
  [TransformType.Rotation]: {
    pickerRotateTR: 270,
    pickerRotateTL: 180,
    pickerRotateBL: 90,
    pickerRotateBR: 0,
  },
  [TransformType.Pivot]: null,
};

export const rotationAxis = new Vector3(0, 0, -1);

export enum SpaceType {
  Local = 'local',
  World = 'world',
}

export const eye = new Vector3(0, 0, 1);

export const boundingBox = (): Box3Helper => {
  const box = new Box3();

  return new Box3Helper(box, 0x00b6fe);
};
