/**
 * Copyright 2021 Design Barn Inc.
 */

import type { Props } from '@floating-ui/react-dom-interactions';
import { flip, FloatingPortal, useFloating, offset } from '@floating-ui/react-dom-interactions';
import { ShapeType } from '@lottiefiles/toolkit-js';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import KeyframeButton from '../Button/KeyframeButton';

import { ColorMode, ColorModeAccordionControl } from './ColorModeAccordionControl';
import ColorPickerProperties from './ColorpickerProperties';
import { parseHex } from './utils/parseHex';

import { Close } from '~/assets/icons';
import { useClickOutside } from '~/hooks/useClickOutside';
import { GradientFillTypes, stateHistory } from '~/lib/toolkit';

interface ColorPickerPopoverProps {
  color: string;
  onChangeColor: (color: string) => void;
  onChangeOpacity: (opacity: number) => void;
  onClose?: () => void;
  onStopColor: (color: string) => void;
  opacity: number;
  shapeType?: string;
  type?: string;
}

function useDependentState<T>(dependency: unknown, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [, setValue] = useState<T>(initialValue);

  const value = useRef<T>(initialValue);

  useEffect(() => {
    setValue(initialValue);
    value.current = initialValue;
  }, [dependency, initialValue, value]);

  return [value.current, setValue];
}

const ColorPickerPopover = React.forwardRef<HTMLDivElement, ColorPickerPopoverProps>(
  ({ color, onChangeColor, onChangeOpacity, onClose, onStopColor, opacity }, ref) => {
    const [colorMode, setColorMode] = useState(ColorMode.Solid);
    const [dependentValue] = useDependentState(stateHistory.index, stateHistory.index);

    return (
      <div className="rounded-lg border-popover border-gray-600 bg-gray-300 shadow-sm dark:bg-gray-800" ref={ref}>
        <div className="flex select-none items-center justify-end border-b px-4 py-2 dark:border-gray-700">
          {/* <div className="text-xs font-bold text-white">{colorMode}</div> */}
          <ColorModeAccordionControl colorMode={colorMode} onSelectColorMode={setColorMode} />
          <div className="h-6 w-6 p-0.5">
            <Close className="h-5 w-5 cursor-pointer fill-current text-white hover:opacity-50" onClick={onClose} />
          </div>
        </div>
        <ColorPickerProperties
          key={dependentValue}
          onStopColor={onStopColor}
          onChangeColor={onChangeColor}
          onChangeOpacity={onChangeOpacity}
          color={color}
          opacity={opacity}
          colorMode={colorMode}
        />
      </div>
    );
  },
);

export type UseFloatingProps = Partial<Props>;
interface ColorPickerProps {
  color: string;
  hasKeyframe?: boolean;
  onChangeColor: (color: string) => void;
  onChangeOpacity: (opacity: number) => void;
  onKeyframeClick?: () => void;
  onStopColor: (color: string) => void;
  opacity: number;
  options?: UseFloatingProps;
  overrideStyle?: Record<string, string | number | boolean>;
  shapeType: string;
  showKeyframe?: boolean;
  styleClass?: string;
  type?: string;
}

const defaultOptions: UseFloatingProps = {
  placement: 'left-start',
  middleware: [flip(), offset(32)],
};

export const ColorPicker: React.FC<ColorPickerProps> = ({
  color,
  opacity,
  onChangeColor,
  onChangeOpacity,
  options = defaultOptions,
  showKeyframe = false,
  hasKeyframe = false,
  onStopColor,
  onKeyframeClick,
  styleClass = '',
  type,
  shapeType,
  overrideStyle,
}) => {
  const [open, setOpen] = React.useState(false);

  const { floating, reference, refs, strategy, x, y } = useFloating(options);

  const handleKeyframeClick = useCallback((): void => {
    if (onKeyframeClick) {
      onKeyframeClick();
    }
  }, [onKeyframeClick]);

  useClickOutside(refs.floating, () => setOpen(false));

  const topY = type ? 60 : y;
  const parsedHex = parseHex(color);

  const style = {
    ...(GradientFillTypes.includes(type as string) && { backgroundColor: parsedHex }),
    ...((shapeType === ShapeType.FILL || shapeType === ShapeType.STROKE) && { background: parsedHex }),
    ...(shapeType === 'TestAnimationBackground' && { backgroundColor: parsedHex }),
  };

  return (
    <>
      <div
        ref={reference}
        className={`h-4 w-4 cursor-pointer rounded-lg shadow-sm ${styleClass}`}
        style={style}
        onClick={() => setOpen(!open)}
      />
      <FloatingPortal>
        {open && (
          <div
            data-no-dnd="true"
            ref={floating}
            style={{
              position: strategy,
              top: topY ?? '',
              left: x ?? '',
              zIndex: 71,
              width: 250,
              borderRadius: 8,
              boxShadow:
                '0px 8px 48px #111417, 0px 2px 8px #121518, 0px 0px 1px rgba(255, 255, 255, 0.36), 0px 0px 1px rgba(255, 255, 255, 0.36)',
              ...overrideStyle,
            }}
          >
            <ColorPickerPopover
              type={type as string}
              color={color}
              opacity={opacity}
              onChangeColor={onChangeColor}
              onChangeOpacity={onChangeOpacity}
              onStopColor={onStopColor}
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
        )}
      </FloatingPortal>
      {showKeyframe && (
        <div className="absolute right-[-8px] top-[3px]">
          <KeyframeButton hasKeyframe={hasKeyframe} onClick={handleKeyframeClick} />
        </div>
      )}
    </>
  );
};
