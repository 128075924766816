/**
 * Copyright 2022 Design Barn Inc.
 */

import { MathUtils, Vector3 } from 'three';

import type { CMesh, CObject3D } from '../../types/object';

/* obj - your object (THREE.Object3D or derived)
 point - the point of rotation (THREE.Vector3)
 axis - the axis of rotation (normalized THREE.Vector3)
 theta - radian value of rotation
 pointIsWorld - boolean indicating the point is in world coordinates (default = true) */

export const rotateAboutPoint = (
  obj: CObject3D,
  point: Vector3,
  axis: Vector3,
  theta: number,
  pointIsWorld = true,
): void => {
  if (pointIsWorld) {
    // compensate for world coordinate
    obj.parent?.localToWorld(obj.position);
  }

  // remove the offset
  obj.position.sub(point);

  // rotate the POSITION
  obj.position.applyAxisAngle(axis, theta);

  // re-add the offset
  obj.position.add(point);

  if (pointIsWorld) {
    // undo world coordinates compensation
    obj.parent?.worldToLocal(obj.position);
  }

  // rotate the OBJECT
  obj.rotateOnAxis(axis, theta);
};

// get the absolute center position of a Mesh
export const getCenterPoint = (mesh: CMesh): Vector3 => {
  const middle = new Vector3();
  const geometry = mesh.geometry;

  geometry.computeBoundingBox();
  const boundingBox = geometry.boundingBox;

  if (!boundingBox) return new Vector3(0, 0, 0);
  middle.x = (boundingBox.max.x + boundingBox.min.x) / 2;
  middle.y = (boundingBox.max.y + boundingBox.min.y) / 2;
  middle.z = (boundingBox.max.z + boundingBox.min.z) / 2;

  mesh.localToWorld(middle);

  return middle;
};

export const cos = (rad: number): number => Math.cos(rad);
export const sin = (rad: number): number => Math.sin(rad);
export const tan = (rad: number): number => Math.tan(rad);
export const degToRad = (deg: number): number => MathUtils.degToRad(deg);
