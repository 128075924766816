/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ScalarJSON, Scene, StarShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedPoints as AnimatedPointsIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { MAX_POLYSTAR_POINTS, MIN_POLYSTAR_POINTS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setPolystarPoints, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedPoints: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const animatedValue = animatedProp.value as ScalarJSON | null;
  const points = animatedValue?.value ?? 0;

  const [sceneIndex] = useCreatorStore((state) => [state.toolkit.sceneIndex], shallow);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id);

      if (node?.parent) {
        setPolystarPoints(node.parent as StarShape, result.value);
        emitter.emit(EmitterEvent.POLYSTAR_POINTS_UPDATED);
      }
    },
    [sceneIndex, animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="points"
        onChange={handleOnChange}
        value={points}
        label={<AnimatedPointsIcon className="h-3 w-3" />}
        styleClass={styleClass}
        min={MIN_POLYSTAR_POINTS}
        max={MAX_POLYSTAR_POINTS}
      />
    </div>
  );
};
