/**
 * Copyright 2023 Design Barn Inc.
 */

import { Color, Scalar, Size, Angle, Vector, Percentage, Rect } from '@lottiefiles/toolkit-js';

import type { ValueObject } from '../../property-values';

export function handleIncomingValueTypes(
  value: Vector | Percentage | Angle | Size | Rect | Scalar | Color | ValueObject,
): object {
  // recreates a main thread equivalent value
  // so that toolkit object methods are accessible

  const { valueType } = value as ValueObject;
  let updatedValue = value;

  switch (valueType) {
    case 'Vector':
      updatedValue = new Vector((value as Vector).x, (value as Vector).y, (value as Vector).z);
      break;

    case 'Percentage':
      updatedValue = new Percentage((value as Percentage).value);
      break;

    case 'Angle':
      updatedValue = new Angle((value as Angle).value);
      break;

    case 'Size':
      updatedValue = new Size((value as Size).width, (value as Size).height, (value as Size).depth);
      break;

    case 'Rect':
      updatedValue = new Rect((value as Rect).offset, (value as Rect).size);
      break;

    case 'Scalar':
      updatedValue = new Scalar((value as Scalar).value);
      break;

    case 'Color':
      updatedValue = new Color(
        (value as Color).red,
        (value as Color).green,
        (value as Color).blue,
        (value as Color).alpha,
      );
      break;

    default:
      break;
  }

  return updatedValue as object;
}
