/**
 * Copyright 2021 Design Barn Inc.
 */

/**
 * Copyright 2022 Design Barn Inc.
 */

import { useMemo, useContext } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { USER_VIEWER_QUERY, USER_WHITELIST_QUERY } from './schema/auth';
import { wrapCallbacks } from './shared';

import { BASE_GRAPHQL_ENDPOINT } from '~/config';
import { clientAPIContext } from '~/lib/graphql';
import type { UserInfo } from '~/store/userSlice';

interface UserAPIProps {
  getUserInfo(): Promise<UserInfo>;
  isUserWhitelist(): Promise<boolean | null>;
}

export const useUserAPI = (token: string | null): UserAPIProps => {
  const apiClient = useContext(clientAPIContext);
  const handleError = useErrorHandler();

  const context = useMemo(
    () => ({
      clientName: BASE_GRAPHQL_ENDPOINT,
      token,
    }),
    [token],
  );

  const getUserInfo = async (): Promise<UserInfo | null> => {
    try {
      const res = await apiClient.query(USER_VIEWER_QUERY, {}, context).toPromise();

      // Sometimes this fails with a 200, but with an error. Manually handle errors until gql server returns correctly
      if (res.error) {
        handleError(res.error);
      } else {
        return Promise.resolve(res.data.viewer);
      }
    } catch (error) {
      handleError(error);
    }

    return Promise.resolve(null);
  };

  const isUserWhitelist = async (): Promise<boolean | null> => {
    try {
      const res = await apiClient.query(USER_WHITELIST_QUERY, {}, context).toPromise();

      return Promise.resolve(res.data.userHasCreatorAccess || false);
    } catch (error) {
      handleError(error);
    }

    return Promise.resolve(null);
  };

  const contextCallbackAPIs = wrapCallbacks(
    {
      getUserInfo,
      isUserWhitelist,
      handleError,
    },
    [apiClient, context],
  ) as UserAPIProps;

  return {
    ...contextCallbackAPIs,
  };
};
