/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';
import { truncateString } from '~/utils/utils';

export const SavingState = {
  UNSAVED: 'UNSAVED',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
};

export const NetworkStatus = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export interface IWorkspaceFolder {
  fileCount?: number;
  id: string;
  name: string;
  projectId: string;
  tempId?: string;
  thumbnails: string | null;
  type: string;
}

export interface IWorkspaceProject {
  folderCount: number;
  id: string;
  isPrivate?: boolean;
  stats?: { [key: string]: number };
  tempId?: string;
  title?: string;
  type: string;
  workspaceId: string;
}

export interface IWorkspace {
  icon: string;
  icon64?: string;
  id: string;
  name: string;
  projects: IWorkspaceProject[];
}

export interface ProjectInfo {
  draftProjectId?: string | null;
  fileId?: string | null;
  fileSubscribeKey?: string | null;
  fileVersionId?: string | null;
  id?: string;
  isDraft?: boolean;
  localChangeId?: string | null;
  name?: string;
  saveDraftCount?: number;
  saveFromPopup?: boolean;
  savingState?: string;
  subscriptionVersionId?: string | null;
}

export enum DirectoryType {
  Folder = 'folder',
  Project = 'project',
  Workspace = 'workspace',
}

export enum UrlType {
  Create = 'create',
  Existing = 'existing',
}

export interface SelectedDirectory {
  id: string;
  parentId?: string;
  type?: DirectoryType;
}

export interface FetchExistingFile {
  fileId: string | null;
  status: boolean;
}

export interface ProjectSlice {
  project: {
    autoSaveStart: boolean;
    backOnline: boolean;
    fetchExistingFile: FetchExistingFile;
    info: ProjectInfo;
    madeFirstChange: boolean;
    network: string | null;
    selectedDirectory: SelectedDirectory | null;
    selectedWorkspaceId: string | null;
    setAutoSaveStart(status: boolean): void;
    setBackOnline: (status: boolean) => void;
    setFetchExistingFile(data: FetchExistingFile): void;
    setInfo: (info: ProjectInfo) => void;
    setMadeFirstChange: (status: boolean) => void;
    setNetwork: (status: string) => void;
    setSelectedDirectory: (data: SelectedDirectory) => void;
    setSelectedWorkspaceId: (id: string) => void;
    setUrlType: (type: UrlType | null) => void;
    setWorkflowWorkspaces: (workspaces: IWorkspace[]) => void;
    urlType: UrlType | null;

    workflowWorkspaces: IWorkspace[];
  };
}

export const createProjectSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  ProjectSlice
> = (set) => ({
  project: {
    network: null,
    selectedDirectory: null,
    backOnline: false,
    madeFirstChange: false,
    autoSaveStart: false,
    workflowWorkspaces: [],
    selectedWorkspaceId: null,
    urlType: null,
    fetchExistingFile: { status: false, fileId: null },
    info: {
      name: 'Untitled file',
      isDraft: false,
      savingState: SavingState.UNSAVED,
      fileSubscribeKey: null,
      fileVersionId: null,
      subscriptionVersionId: null,
      fileId: null,
      draftProjectId: null,
      localChangeId: null,
      saveDraftCount: 0,
      saveFromPopup: false,
    },
    setInfo: (info: ProjectInfo) => {
      set((draft) => {
        const nameChanged = draft.project.info.name !== info.name;

        draft.project.info = {
          ...draft.project.info,
          ...info,
          ...(nameChanged && info.name
            ? {
                name: truncateString(info.name as string, 25),
              }
            : {}),
        };
      });
    },
    setSelectedDirectory: (data: SelectedDirectory) => {
      set((draft) => {
        draft.project.selectedDirectory = { ...data };
      });
    },
    setNetwork: (status: string) => {
      set((draft) => {
        draft.project.network = status;
      });
    },
    setBackOnline: (status: boolean) => {
      set((draft) => {
        draft.project.backOnline = status;
      });
    },
    setMadeFirstChange: (status: boolean) => {
      set((draft) => {
        draft.project.madeFirstChange = status;
      });
    },
    setWorkflowWorkspaces: (workspaces: IWorkspace[]) => {
      set((draft) => {
        draft.project.workflowWorkspaces = workspaces;
      });
    },
    setSelectedWorkspaceId: (workspaceId: string) => {
      set((draft) => {
        draft.project.selectedWorkspaceId = workspaceId;
      });
    },
    setAutoSaveStart: (status: boolean) => {
      set((draft) => {
        draft.project.autoSaveStart = status;
      });
    },
    setFetchExistingFile: (data: FetchExistingFile) => {
      set((draft) => {
        draft.project.fetchExistingFile = {
          ...draft.project.fetchExistingFile,
          ...data,
        };
      });
    },
    setUrlType: (urlType: UrlType | null) => {
      set((draft) => {
        draft.project.urlType = urlType;
      });
    },
  },
});
