/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  ShapeLayerJSON,
  ShapeJSON,
  AnimatedPropertyJSON,
  PropertyType,
  Scene,
  AVLayer,
  AnimatedProperty,
  KeyFrameJSON,
} from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { LayerUI } from '../../helper';
import type { TreeLineState } from '../layer-helper';
import { computeAnimatedPaddingLeft } from '../layer-helper';
// import { AnimatedTreeLine } from '../TreeLine';

import { AnimatedInput } from './AnimatedInput';
import { AnimatedType } from './animatedUtil';

import { LayerAnimated, LayerAnimatedShapeSpecific, LayerAnimatedStroke } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { AnimatedPropertyMenu } from '~/features/menu';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, toolkit, removeKeyFrame, mapAnimatedProperty } from '~/lib/toolkit';
import { CreatorAnimatedTypes } from '~/lib/toolkit/shape-helper';
import { useCreatorStore } from '~/store';
import type { Optional } from '~/types';

type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

interface LayerAnimatedThumbnailProps {
  id: string;
  layerType: string;
}

const LayerAnimatedThumbnail: React.FC<LayerAnimatedThumbnailProps> = ({
  id,
  layerType,
}: LayerAnimatedThumbnailProps) => {
  const layerMap = useCreatorStore.getState().ui.layerMap;
  // The last id in the parent array is the direct parent
  const parentLayerId = layerMap.get(id)?.parent.at(-1);
  const parentLayer = parentLayerId && layerMap.get(parentLayerId);
  const parentLayerAppearanceType = parentLayer && (parentLayer['appearanceType'] as ShapeType | undefined);
  const isShapeType =
    parentLayerAppearanceType &&
    [ShapeType.RECTANGLE, ShapeType.STAR, ShapeType.ELLIPSE].includes(parentLayerAppearanceType as ShapeType);

  if (isShapeType) {
    return <LayerAnimatedShapeSpecific className="h-4 w-4" />;
  }

  if (['fl'].includes(layerType)) return <LayerAnimatedStroke className="h-4 w-4" />;
  else return <LayerAnimated className="h-4 w-4 fill-current text-gray-500" />;
};

interface AnimatedRowProps {
  animatedProps: AnimatedPropertyJSON[];
  ids: string;
  layerType: string;
  parentId?: string;
  types: string;
}

const AnimatedRow: React.FC<AnimatedRowProps> = ({
  animatedProps,
  ids,
  layerType,
  parentId,
  types,
}: AnimatedRowProps) => {
  const timelineContext = useCreatorStore((state) => state.timeline.timelineContext);

  const hasMoreField = animatedProps.length > 1;
  const animatedProp = animatedProps[0];
  const animatedProp2 = hasMoreField ? animatedProps[1] : null;

  const id = ids.split('_')[0];
  const id2 = hasMoreField ? ids.split('_')[1] : null;

  const type = types[0];
  const type2 = hasMoreField ? types[1] : null;

  const [sceneIndex, currentFrame, addAnimatedValue, setStaticValue] = useCreatorStore(
    (state) => [
      state.toolkit.sceneIndex,
      state.toolkit.currentFrame,
      state.toolkit.addAnimatedValue,
      state.toolkit.setStaticValue,
    ],
    shallow,
  );

  const handleKeyFrameClick = useCallback(() => {
    const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id) as AnimatedProperty;

    const parentNode = node.parent as AVLayer;
    const mappedAnimatedProperty = mapAnimatedProperty[type];

    if (animatedProp.isAtKeyFrame) {
      const currentKeyFrame = animatedProp.keyFrames.find((keyFrame) => {
        return keyFrame.frame === currentFrame || keyFrame.frame === Math.round(currentFrame);
      }) as KeyFrameJSON | null;

      if (currentKeyFrame && mappedAnimatedProperty) {
        const currentValue = mappedAnimatedProperty.getAnimatedValue(parentNode);

        removeKeyFrame(currentKeyFrame.frameId as string);

        if (animatedProp.keyFrames.length === 1) {
          if (parentNode.type === 'GROUP' || parentNode.isAVLayer) {
            setStaticValue(mappedAnimatedProperty.animatedProperty, currentValue, parentNode.nodeId);
          } else {
            node.setIsAnimated(false);
            mappedAnimatedProperty.setShapeProperty(parentNode, currentValue);
          }
        }
      }
    } else if (mappedAnimatedProperty) {
      if (parentNode.type === 'GROUP' || parentNode.isAVLayer) {
        addAnimatedValue(
          mappedAnimatedProperty.animatedProperty,
          mappedAnimatedProperty.getAnimatedValue(parentNode),
          parentNode.nodeId,
        );
      } else {
        mappedAnimatedProperty.setShapeProperty(parentNode, mappedAnimatedProperty.getAnimatedValue(parentNode));
      }
    }
    emitter.emit(EmitterEvent.ANIMATED_KEYFRAME_UPDATED);
  }, [animatedProp, type, sceneIndex, currentFrame, addAnimatedValue, setStaticValue]);

  const onAnimatedClick = useCallback(
    (event) => {
      if (event.type === 'contextmenu' && event.button === 2 && parentId) {
        // TODO: handle multiselect

        const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

        addToSelectedNodes([parentId], true);
      }
    },
    [parentId],
  );

  const selectedNodeId = timelineContext.selectedId;

  const mousePos = timelineContext.mousePos;
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  // findNode;
  const selectedNode = selectedNodeId ? getNodeByIdOnly(selectedNodeId) : null;
  const selectedType = selectedNode?.type;

  //   console.log('Aniamted...');

  return (
    <>
      <div
        className={clsx('flex h-full items-center justify-between', {})}
        onContextMenu={(evt) => onAnimatedClick(evt)}
      >
        <div className="flex items-center">
          <LayerAnimatedThumbnail layerType={layerType} id={ids} />
          <span className="ml-[6.66px] text-gray-400">{AnimatedType[type]}</span>
          {/* invisible */}
          <span data-layerid={id} className="ml-1 hidden">
            {id}
          </span>
        </div>
        <div className="absolute right-0 mr-[2px] flex items-center">
          <AnimatedInput animatedProp={animatedProp} type={type} id={id} parentId={parentId} parentType={layerType} />

          {animatedProp2 && (
            <AnimatedInput
              animatedProp={animatedProp2}
              type={type2 as string}
              id={id2 as string}
              parentId={parentId}
              parentType={layerType}
            />
          )}

          <KeyframeButton hasKeyframe={animatedProp.isAtKeyFrame} onClick={handleKeyFrameClick} isTransparent />
        </div>
      </div>
      {CreatorAnimatedTypes.includes(selectedType) && (
        <div style={{ position: 'absolute' }}>
          <AnimatedPropertyMenu
            isOpen={selectedNodeId === id}
            onClose={() => null}
            coord={mousePos}
            eventArg={{
              id: selectedNodeId,
              type: selectedType,
            }}
          />
        </div>
      )}
    </>
  );
};

interface AnimatedProps {
  allParentExpanded: boolean;
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
  treeLines: TreeLineState[];
}

export const Animated: React.FC<AnimatedProps> = ({ layer, layerUI }) => {
  const { animated, highlight, level } = layerUI;

  // Combined ShapeLayers, Shape, Appearance
  // Conditional rendering
  if (animated.length === 0) {
    return null;
  }

  // Compute padding left to align layers according to their level
  // TODO: recompute level for multi level
  const paddingLeft = computeAnimatedPaddingLeft(level);

  const layerType = layer.type;

  return (
    <>
      <div data-layerid={layer.id}>
        {animated.map(({ id, type }, index) => {
          return (
            <div
              data-no-dnd="true"
              key={id}
              className={clsx('relative mt-[1px] h-[24px]', {
                'bg-[#272E34]': highlight,
                'rounded-b': highlight && index === animated.length - 1,
              })}
              style={{ paddingLeft }}
            >
              <AnimatedRow
                ids={id}
                animatedProps={[layer.animatedProperties[type as PropertyType]]}
                types={[type]}
                layerType={layerType}
                parentId={layer.id}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
