/**
 * Copyright 2023 Design Barn Inc.
 */

import type { EllipseShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getDrawableShapeMethods } from './drawable-shape';
import { getShapeMethods } from './shape';
import { getSplitPositionMethods } from './split-position';

const methodNames = {
  absolutePosition: 'absolutePosition',
  direction: 'direction',
  perimeter: 'perimeter',
  size: 'size',
  setAbsolutePosition: 'setAbsolutePosition',
  setDirection: 'setDirection',
  setSize: 'setSize',
  toBezier: 'toBezier',
  toPathShape: 'toPathShape',
};

function getEllipseShapeMethods(plugin: Plugin, ellipse: EllipseShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, ellipse);
}

export function createEllipseShapeObject(plugin: Plugin, ellipse: EllipseShape): object {
  const ellipseShapeObj = {
    nodeId: ellipse.nodeId,
    nodeType: ellipse.nodeType,
    type: ellipse.type,
  };

  registerObjectMethods(plugin, ellipse, ellipseShapeObj, [
    getNodeMethods,
    getDrawableShapeMethods,
    getSplitPositionMethods,
    getShapeMethods,
    getEllipseShapeMethods,
  ]);

  return ellipseShapeObj;
}
