/**
 * Copyright 2023 Design Barn Inc.
 */

import React, { forwardRef } from 'react';

import { LayerRow } from '../Layers';

import { FolderTreeItemWrapper } from './FolderTreeItemWrapper';
import type { TreeItemData } from './TreeItemData';
import type { TreeItemComponentProps } from './types';

import { useCreatorStore } from '~/store';

export const FolderTreeItem = forwardRef<HTMLDivElement, TreeItemComponentProps<TreeItemData>>((props, ref) => {
  const { id, layer } = props;

  const layerUI = useCreatorStore((state) => state.ui.layerMap.get(id));

  // Put the early return check here instead of <LayerRow/> to adhere rules of hooks order.
  if (!layerUI) {
    return null;
  }

  return (
    <FolderTreeItemWrapper {...props} ref={ref}>
      <LayerRow layerId={id} layer={layer} layerUI={layerUI} />
    </FolderTreeItemWrapper>
  );
});
