/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';

interface Props {
  children?: React.ReactNode;
}

export const PropertyPanel: React.FC<Props> = ({ children }) => {
  return <div className="w-full">{children}</div>;
};
