/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

const ContainerStyle = {
  width: '100%',
  height: '200px',
};

const IframeStyle = {
  width: '100%',
  height: '100%',
  border: 'none',
};

export const CreatorTutorialFramer = (): JSX.Element => {
  return (
    <div style={ContainerStyle}>
      <iframe title="WhatsNew" style={IframeStyle} src="https://releases.lottiefiles.com/creator-tutorial" />
    </div>
  );
};

export default CreatorTutorialFramer;
