/**
 * Copyright 2022 Design Barn Inc.
 */

import { useFeature, IfFeatureEnabled } from '@growthbook/growthbook-react';
import React, { useState } from 'react';

import type { DragContextType } from './Context/DragContext';
import { DragContext } from './Context/DragContext';
import { TimelineRangeSlider } from './TimelineRangeSlider';
import { TimelineScrubber } from './TimelineScrubber';
import { TimelineTimeScale } from './TimelineTimeScale';

import { GB_CONSTANT } from '~/config/growthbook';

export const TimelineControl: React.FC = () => {
  const showZoombar = useFeature(GB_CONSTANT.SHOW_TIMELINE_ZOOMBAR).on;
  const [playheadPos, setPlayheadPos] = useState(null);
  const [playheadNodeRef, setPlayheadNodeRef] = useState(null);

  const ctx = {
    playheadPos,
    setPlayheadPos,
    playheadNodeRef,
    setPlayheadNodeRef,
  };

  return (
    <div id="keyframe-scrubber" className={`${showZoombar === false ? 'mt-1' : ''}`}>
      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_TIMELINE_ZOOMBAR}>
        <TimelineRangeSlider />
      </IfFeatureEnabled>

      <DragContext.Provider value={ctx as DragContextType}>
        <TimelineScrubber />
        <TimelineTimeScale />
      </DragContext.Provider>
    </div>
  );
};
