/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Timeline, AnimatedProperty, Track } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { getNodeById } from '../utils';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { createTrackObject } from './track';

import { useCreatorStore } from '~/store';

const methodNames = {
  currentFrame: 'currentFrame',
  currentTime: 'currentTime',
  duration: 'duration',
  endFrame: 'endFrame',
  endTime: 'endTime',
  frameDuration: 'frameDuration',
  frameRate: 'frameRate',
  startFrame: 'startFrame',
  startTime: 'startTime',
  tracks: 'tracks',
  clear: 'clear',
  getTimeAt: 'getTimeAt',
  getTracksChangingFrom: 'getTracksChangingFrom',
  removeTracksAt: 'removeTracksAt',
  reorderTracksAt: 'reorderTracksAt',
  scale: 'scale',
  setCurrentFrame: 'setCurrentFrame',
  setCurrentTime: 'setCurrentTime',
  setDuration: 'setDuration',
  setEndFrame: 'setEndFrame',
  setEndTime: 'setEndTime',
  setFrameDuration: 'setFrameDuration',
  setFrameRate: 'setFrameRate',
  setStartAndEndFrame: 'setStartAndEndFrame',
  setStartAndEndTime: 'setStartAndEndTime',
  setStartFrame: 'setStartFrame',
  setStartTime: 'setStartTime',
  stretchToDuration: 'stretchToDuration',
};

const timelineModifiers = {
  setCurrentFrame: (_timeline: Timeline, frame: number[]) => {
    const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;

    setCurrentFrame(frame[0] as number);
  },
  setCurrentTime: (_timeline: Timeline, time: number[]) => {
    const timeInSeconds = time[0] as number;
    const isPrecomposition = Boolean(useCreatorStore.getState().toolkit.selectedPrecompositionJson);

    const frameRate = isPrecomposition
      ? useCreatorStore.getState().toolkit.selectedPrecompositionJson?.timeline.properties.fr
      : useCreatorStore.getState().toolkit.json?.timeline.properties.fr;

    const frameNumber = timeInSeconds * (frameRate as number);

    const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;

    setCurrentFrame(frameNumber);
  },
};

function getTrackByProperty(timeline: Timeline, vmProp: AnimatedProperty, plugin: Plugin): object {
  const property = getNodeById(vmProp.nodeId);
  const track = timeline.getTrackByProperty(property as AnimatedProperty) as Track;

  return createTrackObject(plugin, track);
}

function hasPropertyTrack(timeline: Timeline, vmProp: AnimatedProperty): boolean {
  const property = getNodeById(vmProp.nodeId);

  return timeline.hasPropertyTrack(property as AnimatedProperty);
}

const additionalMethods = {
  getTrackByProperty,
  hasPropertyTrack,
};

function getTimelineMethods(plugin: Plugin, timeline: Timeline): ObjectMethods {
  return getObjectMethods(plugin, methodNames, timeline, timelineModifiers, additionalMethods);
}

export function createTimelineObj(plugin: Plugin, timeline: Timeline): object {
  const timelineObj = {
    nodeId: timeline.nodeId,
    nodeType: timeline.nodeType,
  };

  registerObjectMethods(plugin, timeline, timelineObj, [getNodeMethods, getTimelineMethods]);

  return timelineObj;
}
