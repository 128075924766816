/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode, SceneJSON, Scene } from '@lottiefiles/toolkit-js';

import { getNodeById, toolkit } from '../toolkit';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

let previousTime: number;
let requestId: number;

const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;
const setPlaying = useCreatorStore.getState().timeline.setPlaying;

export const pause = (): void => {
  setPlaying(false);
  previousTime = performance.now();
  cancelAnimationFrame(requestId);
};

const animate = (t: number): void => {
  let fps = useCreatorStore.getState().toolkit.json?.timeline.properties.fr as number;
  let op = useCreatorStore.getState().toolkit.json?.timeline.properties.op as number;
  const looping = useCreatorStore.getState().timeline.looping;
  let frameElapsed = Math.ceil(((t - previousTime) * fps) / 1000) || 1;
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
  const setSelectedPrecompositionJson = useCreatorStore.getState().toolkit.setSelectedPrecompositionJson;

  let currentFrame = useCreatorStore.getState().toolkit.currentFrame;

  let assetNode = null as DagNode | null;

  if (selectedPrecompositionId) {
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

    assetNode = getNodeById(toolkit.scenes[sceneIndex] as Scene, selectedPrecompositionId);
    const json = assetNode?.state as SceneJSON;

    fps = (json.timeline.properties.fr as number) || 30;
    op = (json.timeline.properties.op as number) || op;

    currentFrame = (json.timeline.properties.cf as number) || 0;
    frameElapsed = Math.ceil(((t - previousTime) * fps) / 1000) || 1;
  }

  // Skip frame
  let finalFrame = currentFrame + frameElapsed;

  if (finalFrame > op) {
    if (looping) {
      finalFrame = 0;
    } else {
      pause();
      setCurrentFrame(0);
      emitter.emit(EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED);

      return;
    }
  }

  emitter.emit(EmitterEvent.CANVAS_DISABLE_PENCONTROL);
  setCurrentFrame(finalFrame);

  if (assetNode) {
    assetNode.timeline.setCurrentFrame(finalFrame);
    setSelectedPrecompositionJson(assetNode.state as SceneJSON);
  }
  emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
  previousTime = t;
  requestId = requestAnimationFrame(animate);
};

export const play = (): void => {
  setPlaying(true);
  previousTime = performance.now();
  requestId = requestAnimationFrame(animate);
};
