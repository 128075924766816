/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { TimelineKeyframePanel } from './TimelineKeyframePanel';
import { TimelineLayerPanel } from './TimelineLayerPanel';

export const TimelineMainContent: React.FC = () => {
  return (
    <div className="flex flex-1">
      <TimelineLayerPanel />
      <TimelineKeyframePanel />
    </div>
  );
};

export default TimelineMainContent;
