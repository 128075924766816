/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { WorkspaceLayers } from './layers';

import { useCreatorStore } from '~/store';

export const SavePanel: React.FC = () => {
  const workflowWorkspaces = useCreatorStore((state) => state.project.workflowWorkspaces);

  return (
    <div className="h-[295px] overflow-auto px-3 py-4">
      {workflowWorkspaces.length > 0 && (
        <div className="flex h-full w-full flex-col">
          <WorkspaceLayers workspaces={workflowWorkspaces} />
        </div>
      )}

      {workflowWorkspaces.length === 0 && (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <div className="text-caption text-gray-400">Loading Workspace...</div>
        </div>
      )}
    </div>
  );
};
