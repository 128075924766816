/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { ShapeLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

import { useCreatorStore } from '~/store';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  onClose: () => void;
  pastable: boolean;
}

export const ShapeLayerMenuCanvas: React.FC<ShapeLayerMenuProps> = ({ coord, onClose, pastable }) => {
  const [selectedNodeId, canvasMap] = useCreatorStore((state) => [
    state.ui.selectedNodesInfo[0]?.nodeId as string,
    state.ui.canvasMap,
  ]);
  const selectedNodeType = canvasMap.get(selectedNodeId)?.layerType;

  if (selectedNodeType !== 'SHAPE') return null;
  if (ShapeLayerMenuList[3]) ShapeLayerMenuList[3].disabled = !pastable;

  return <ContextMenuWrapper coord={coord} onClose={onClose} dropdownItems={ShapeLayerMenuList}></ContextMenuWrapper>;
};
