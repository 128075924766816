/**
 * Copyright 2022 Design Barn Inc.
 */

import { useJitsu } from '@jitsu/react';
import axios from 'axios';
import clsx from 'clsx';
import type { ChangeEvent } from 'react';
import React, { useCallback, useState, useContext } from 'react';

import UploadFile from '../assets/UploadFile';

import { uploadDotLottie, uploadJSON } from './helper';
import { UploadContext } from './UploadContext';
import { InvalidURL, UnknownError } from './UploadError';
import type { UploadError } from './UploadError';

import { Info } from '~/assets/icons';
import { FileType, AssetSource, EventType } from '~/data/eventStore';
import { verifySanitizeURL } from '~/utils';
import { GenericPayload } from '~/utils/eventStoreUtils';

export const UploadUrl: React.FC = () => {
  const [url, setUrl] = useState('');
  const { track } = useJitsu();

  // Setting local error because we don't want to show the error in the context panel
  const [error, setLocalError] = useState<UploadError | null>(null);

  const { onClose, setError, setLoading } = useContext(UploadContext);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  }, []);

  const handleOnClick = useCallback(async () => {
    const urlExtension = url.split('.').pop();

    // Reset panel error state
    setError(null);

    if (urlExtension === 'lottie') {
      try {
        track(EventType.UploadedAsset, {
          client: GenericPayload,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_type: EventType.UploadedAsset,
          parameters: {
            source: AssetSource.FileUrl,
            'file-type': FileType.DotLottie,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            send_to_amplitude: true,
          },
        });

        setLoading(true);
        await uploadDotLottie(url);
        setLoading(false);

        onClose();
      } catch (err) {
        if (err instanceof Error) {
          // eslint-disable-next-line no-console
          console.error(err);
          setLocalError(new UnknownError(err.message));
          setLoading(false);
        }
      }
    } else {
      let response;

      try {
        track(EventType.UploadedAsset, {
          client: GenericPayload,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_type: EventType.UploadedAsset,
          parameters: {
            source: AssetSource.FileUrl,
            'file-type': FileType.DotLottie,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            send_to_amplitude: true,
          },
        });

        response = await axios.get(verifySanitizeURL(url));
      } catch {
        setLocalError(new InvalidURL('Invalid URL'));
      }

      if (response && response.status === 200) {
        const lottieJSON = response.data;

        try {
          setLoading(true);
          await uploadJSON(lottieJSON);
          setLoading(false);

          onClose();
        } catch (err) {
          setLoading(false);

          if (err instanceof Error) {
            // eslint-disable-next-line no-console
            console.error(err);
            setLocalError(new UnknownError(err.message));
          }
        }
      }
    }
  }, [url, setError, track, setLoading, onClose]);

  return (
    <div className="p-3">
      <div className="flex h-full w-full flex-col items-center gap-3 p-12">
        <UploadFile className="h-16 w-20" />
        <span className="text-sm font-bold leading-[18px] text-white">Upload animation from URL</span>
        <div className="flex h-full w-full flex-col items-start">
          <div className="flex h-full w-full flex-row items-center justify-between gap-2">
            <input
              className={clsx('input', error ? 'input-error' : '')}
              onChange={handleInputChange}
              placeholder="Enter .json/.lottie URL"
              data-testid="url-input"
            />
            <button className="btn-primary disabled:btn-disabled" disabled={url === ''} onClick={handleOnClick}>
              Open
            </button>
          </div>
          <span className="text-error">{error?.actionMessage}</span>
        </div>
        <span className="mb-3 text-caption">
          Discover more free animations at <a href="lottiefiles.com">lottiefiles.com</a>
        </span>
      </div>
      <div className="banner-info">
        <Info className="h-8 w-8" />
        <p>Not all Lottie animation features are supported yet. Click ‘Learn more’ to find out which ones are.</p>
        <button
          className="banner-btn"
          onClick={() => window.open('https://help.lottiefiles.com/hc/en-us/articles/15386214015769.', '_blank')}
        >
          Learn more
        </button>
      </div>
    </div>
  );
};
