/**
 * Copyright 2021 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useCallback, useState, useEffect } from 'react';

import { CursorType } from '~/features/search-animation';

const RightChevronIcon: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12" fill="none" {...props}>
      <path d="M1.5 11L6.5 6L1.5 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

const LeftChevronIcon: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12" fill="none" {...props}>
      <path d="M6.5 1L1.5 6L6.5 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

export interface PaginationProps {
  // NOTE: Remove limit and totalcount once hasNext and hasPrevious is fixed
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  limit: number;
  nextCursor: string;
  onPageChanged: (cursor: string, cursorType: CursorType) => void;
  prevCursor: string;
  totalCount: number;
}

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { limit, nextCursor, onPageChanged, prevCursor, totalCount } = props;
  // NOTE: Add this back in when hasNextPage and hasPreviousPage is fixed
  // const { hasNextPage, hasPreviousPage, limit, nextCursor, onPageChanged, prevCursor, totalCount } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalCount]);

  const totalPages = Math.ceil(totalCount / limit);

  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  const previousPage = useCallback(() => {
    onPageChanged(prevCursor, CursorType.PREV_CURSOR);
    setCurrentPage(currentPage - 1);
  }, [currentPage, onPageChanged, prevCursor]);

  const nextPage = useCallback(() => {
    onPageChanged(nextCursor, CursorType.NEXT_CURSOR);
    setCurrentPage(currentPage + 1);
  }, [onPageChanged, nextCursor, currentPage]);

  return (
    <ul className="m-0 flex justify-between p-0">
      <button
        className={clsx(
          'mx-[2px] inline-flex h-5 w-5 cursor-pointer select-none items-center justify-center p-1 text-xs text-white',
          { 'cursor-default': !hasPreviousPage, 'cursor-pointer': hasPreviousPage },
        )}
        onClick={previousPage}
        onKeyPress={previousPage}
        data-testid="pagination-item-prev"
        disabled={!hasPreviousPage}
      >
        <LeftChevronIcon
          className={clsx('h-4 w-4 stroke-current', {
            'text-white': hasPreviousPage,
            'text-gray-600': !hasPreviousPage,
          })}
        />
      </button>

      <button
        className={clsx(
          'mx-[2px] inline-flex h-5 w-5 cursor-pointer select-none items-center justify-center p-1 text-xs text-white',
          { 'cursor-default': !hasNextPage, 'cursor-pointer': hasNextPage },
        )}
        onClick={nextPage}
        onKeyPress={nextPage}
        data-testid="pagination-item-next"
        disabled={!hasNextPage}
      >
        <RightChevronIcon
          className={clsx('h-4 w-4 stroke-current', {
            'text-white': hasNextPage,
            'text-gray-600': !hasNextPage,
          })}
        />
      </button>
    </ul>
  );
};
