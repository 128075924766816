/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/naming-convention */
import { version as clientVersion } from '../../package.json';

import type { EventAction, AssetSource, KeyframeSource, EventType, FileType } from '~/data/eventStore';

export interface ClientPayload {
  name: string;
  version: string;
}

export interface EventStorePayload {
  client: ClientPayload;
  event_type: EventType;
  parameters: EventStoreMenuSelect | EventStoreFileDownload | EventStoreAssetUpload | EventStoreKeyframeAdd;
}

export const GenericPayload: ClientPayload = {
  name: 'creator',
  version: clientVersion,
};

export interface EventStoreMenuSelect {
  action: EventAction;
  send_to_amplitude: boolean;
}

export interface EventStoreFileDownload {
  file?: {
    'animated-properties'?: string;
    'canvas-size'?: string;
    'frame-rate'?: string;
    'playback-duration'?: string;
  };
  send_to_amplitude: boolean;
  type: FileType;
}

export interface EventStoreAssetUpload {
  'file-url'?: string;
  'search-tag'?: string;
  send_to_amplitude: boolean;
  slug?: string;
  source: AssetSource;
  type: FileType;
}

export interface EventStoreKeyframeAdd {
  send_to_amplitude: boolean;
  source: KeyframeSource;
}
