/**
 * Copyright 2022 Design Barn Inc.
 */

import type { SceneJSON, Scene, Toolkit, PrecompositionAsset } from '@lottiefiles/toolkit-js';
import { Size } from '@lottiefiles/toolkit-js';

import { stateHistory } from './toolkit';
import type { Scalar2D } from './types';

import packageJson from '~/../package.json';
import { useCreatorStore } from '~/store';

export interface SceneOption {
  endFrame: number;
  frameRate: number;
  height: number;
  name?: string;
  startFrame: number;
  width: number;
}

export const createScene = (toolkit: Toolkit, option: SceneOption): Scene => {
  stateHistory.beginAction();
  const scene = toolkit.createScene({ name: option.name, enableNodeIds: true });

  scene.setWidth(option.width);
  scene.setHeight(option.height);
  scene.timeline.setStartAndEndFrame(option.startFrame, option.endFrame);
  scene.timeline.setFrameRate(option.frameRate);
  scene.meta.setGenerator(`@lottiefiles/creator ${packageJson.version}`);
  stateHistory.endAction();

  return scene;
};

export const getToolkitState = (toolkit: Toolkit, sceneIndex = 0): SceneJSON => toolkit.state.scenes[sceneIndex];

export const setSceneHeight = (scene: Scene, height: number): void => {
  scene.setHeight(height);
};

export const setPrecompHeight = (precompAsset: PrecompositionAsset, height: number): void => {
  precompAsset.setData('canvasHeight', (height - precompAsset.getData('canvasMinHeight')) as number);
};

export const setToolkitCurrentFrame = (toolkit: Toolkit, index: number): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  scene?.timeline.setCurrentFrame(index);
};

export const setSceneWidth = (scene: Scene, width: number): void => {
  scene.setWidth(width);
};

export const setPrecompWidth = (precompAsset: PrecompositionAsset, width: number): void => {
  precompAsset.setData('canvasWidth', (width - precompAsset.getData('canvasMinWidth')) as number);
};

export const setPrecompSize = (precompAsset: PrecompositionAsset, size: Scalar2D): void => {
  precompAsset.setData('canvasWidth', (size[0] - precompAsset.getData('canvasMinWidth')) as number);
  precompAsset.setData('canvasHeight', (size[1] - precompAsset.getData('canvasMinHeight')) as number);
};

export const setSceneSize = (scene: Scene, size: Scalar2D): void => {
  scene.setSize(new Size(size[0], size[1]));
};

export const removeAllScenes = (toolkit: Toolkit): void => {
  while (toolkit.scenes.length > 0 && toolkit.scenes[0]) {
    toolkit.removeScene(toolkit.scenes[0]);
  }
};
