/**
 * Copyright 2021 Design Barn Inc.
 */

import clsx from 'clsx';
import { throttle } from 'lodash-es';
import type { KeyboardEventHandler } from 'react';
import React, { useCallback, useRef, useMemo, useEffect } from 'react';

import KeyframeButton from '../Button/KeyframeButton';

import type { NumberResult } from './types';

import './input.css';
import { EmitterEvent } from '~/lib/emitter';
import { redoHistory, undoHistory } from '~/lib/history';
import { stateHistory } from '~/lib/toolkit';

export const defaultStyle = {
  label:
    'p-1 h-[24px] w-full text-xs font-normal bg-gray-700 rounded border border-transparent focus-within:border-teal-500 number-input-label',
  input: 'text-xs pl-1 font-normal text-left bg-gray-700 focus:outline-none number-input',
  span: 'flex justify-center items-center mx-1 text-gray-500 select-none w-[16px]',
};

export interface PeriodicInputStyle {
  input?: string;
  keyframe?: string;
  label?: string;
  span?: string;
}

export interface PeriodicInputProps {
  allowNegative: boolean;
  append?: string;
  hasKeyframe?: boolean;
  label?: string | React.ReactNode | null;
  max?: number | null;
  min?: number | null;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (result: NumberResult) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyframeClick?: () => void;
  period: number;
  precision?: number;
  showKeyframe?: boolean;
  step?: number;
  styleClass?: PeriodicInputStyle;
  value: number;
}

export const PeriodicInput: React.FC<PeriodicInputProps> = ({
  append = '',
  hasKeyframe = false,
  label = null,
  name,
  onChange,
  onKeyframeClick,
  period,
  precision = 2,
  showKeyframe = false,
  step = 1,
  styleClass,
  value,
}) => {
  const formatValue = useCallback(
    (val: number) => {
      let num = val;

      num %= period;
      const formatted = Number.isInteger(num) ? num : num.toFixed(precision);

      return `${formatted}${append}`;
    },
    [append, period, precision],
  );

  const [input, setInput] = React.useState<string>(formatValue(value));
  // NOTE(miljau): had to throttle it because the updates were too fast
  // updates will be throttled to 1 per 50ms
  const throttledOnChange = useRef(throttle(onChange, 50));

  useEffect(() => {
    setInput(formatValue(value));
  }, [value, setInput, formatValue]);

  const [focused, setFocused] = React.useState(false);
  const [pointerDown, setPointerDown] = React.useState(false);
  const [dragStartValue, setDragStartValue] = React.useState<null | number>(null);

  const inputRef = React.createRef<HTMLInputElement>();
  const style = useMemo(() => ({ ...defaultStyle, ...styleClass }), [styleClass]);

  const handleKeyframeClick = useCallback((): void => {
    if (onKeyframeClick) {
      onKeyframeClick();
    }
  }, [onKeyframeClick]);

  const propagateValue = useCallback(
    (newValue: number) => {
      if (newValue === value) {
        return;
      }
      setInput(formatValue(newValue));
      // Assumed that the newValue is a valid number
      throttledOnChange.current({ name, value: newValue, trueValue: 0 });
    },
    [setInput, formatValue, name, value],
  );

  const handleOnBlurInput = useCallback(
    (_event: React.FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(input);
      const isNewInputValid = !Number.isNaN(newValue) && Number.isFinite(newValue);

      if (!isNewInputValid) {
        // reset input to actual value
        setInput(formatValue(value));

        return;
      }
      if (focused) {
        propagateValue(Math.trunc(value / period) * period + newValue);
        setFocused(false);
      }
    },
    [input, focused, formatValue, value, propagateValue, period],
  );

  const handleOnChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInput = event.target.value;

      if (focused) {
        setInput(newInput);
      }
    },
    [focused, setInput],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { ctrlKey, key, metaKey, shiftKey } = event;

      if (key === 'ArrowUp') {
        event.preventDefault();
        propagateValue(value + step);
      } else if (key === 'ArrowDown') {
        event.preventDefault();
        propagateValue(value - step);
      } else if (key === 'Enter') {
        // Automatically trigger onBlur
        inputRef.current?.blur();
      } else if (key === 'z' && (metaKey || ctrlKey) && !shiftKey) {
        undoHistory(EmitterEvent.UI_UNDO);
      } else if (key === 'z' && (metaKey || ctrlKey) && shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      } else if (key === 'y' && ctrlKey && !shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      }
    },
    [inputRef, value, propagateValue, step],
  );

  const onPointerMove = useCallback(
    (event: React.PointerEvent<HTMLSpanElement>) => {
      if (dragStartValue !== null) {
        // truncating the offsetX because of the high precision
        const newValue = dragStartValue + Math.trunc(event.nativeEvent.offsetX);

        propagateValue(newValue);
        setInput(formatValue(newValue));
      }
    },
    [dragStartValue, propagateValue, setInput, formatValue],
  );

  const onPointerDown = useCallback(
    (event: React.PointerEvent<HTMLSpanElement>) => {
      (event.target as HTMLSpanElement).setPointerCapture(event.pointerId);
      setDragStartValue(value);
      setPointerDown(true);
      stateHistory.beginAction();
    },
    [setPointerDown, setDragStartValue, value],
  );

  const onPointerUp = useCallback(
    (event: React.PointerEvent<HTMLSpanElement>) => {
      (event.target as HTMLSpanElement).releasePointerCapture(event.pointerId);
      setDragStartValue(null);
      setPointerDown(false);
      stateHistory.endAction();
    },
    [setPointerDown],
  );

  const onLostPointerCapture = useCallback(
    (_event: React.PointerEvent<HTMLSpanElement>) => {
      // Will be called if the tab is switched while dragging
      setPointerDown(false);
    },
    [setPointerDown],
  );

  // Dealing updates from above
  // eg: prop panel
  useEffect(() => {
    if (focused || !inputRef.current) return;
    const newInput = formatValue(value);

    if (inputRef.current.value !== newInput) {
      inputRef.current.value = newInput;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, focused, formatValue]);

  const displayValue = useMemo(() => {
    if (focused) {
      return input;
    }

    return formatValue(value);
  }, [focused, input, formatValue, value]);

  return (
    <div className="relative flex ">
      <label
        className={clsx('group flex items-center', style.label)}
        data-testid="number-input-container"
        onClick={() => {
          inputRef.current?.select();
        }}
      >
        {label && (
          <span
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            // eslint-disable-next-line no-undefined
            onPointerMove={pointerDown ? onPointerMove : undefined}
            onLostPointerCapture={onLostPointerCapture}
            className="cursor-ew-resize"
          >
            <span className={style.span} data-testid="number-input-label">
              {label}
            </span>
          </span>
        )}

        <input
          autoComplete="off"
          ref={inputRef}
          name={name}
          className={clsx('w-full', style.input)}
          value={displayValue}
          onBlur={handleOnBlurInput}
          onChange={handleOnChangeInput}
          onFocus={() => setFocused(true)}
          data-testid="number-input"
          onKeyDown={onKeyDown}
        />
      </label>
      {showKeyframe && (
        <div className="absolute right-[-8px] top-[3px]">
          <KeyframeButton hasKeyframe={hasKeyframe} onClick={handleKeyframeClick} />
        </div>
      )}
    </div>
  );
};
