/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  DagNodeJSON,
  SceneJSON,
  RectangleShape,
  ShapeLayer,
  StarShape,
  FillShape,
  StrokeShape,
  StrokeDashType,
  BlendModeType,
  FillRuleType,
  Scene,
  ColorStopJSON,
  GradientFillShape,
  DagNode,
  Shape,
  PrecompositionLayer,
} from '@lottiefiles/toolkit-js';
import { ShapeType, GroupShape, ShapeLayer as ShapeL, AVLayer } from '@lottiefiles/toolkit-js';
import { last } from 'lodash-es';
import type { StateCreator } from 'zustand';

import { clearSelectionState, getAnimatedNode, getCurrentNode, getCurrentShapeNode } from './storeUtil';

import type { StoreSlice } from '.';
import { getLayerMap } from '~/features/timeline';
import type {
  ColorValue,
  CurrentFillShape,
  CurrentTransformProperty,
  CurrentShape,
  CurrentStrokeShape,
  CurrentGFillShape,
  AnimatedType,
} from '~/lib/toolkit';
import {
  getAppearanceListFromNodes,
  setAnimatedFillColor,
  setAnimatedFillOpacity,
  setStaticPivot,
  getAnimatedFunction,
  defaultCurrentGFillShape,
  defaultCurrentStrokeShape,
  defaultCurrentShape,
  defaultCurrentTransformProperty,
  defaultCurrentFillShape,
  toolkit,
  getCurrentShape,
  getCurrentTransform,
  getActiveTransform,
  getCurrentFillShape,
  getFillShape,
  getGradientFillShape,
  setShapePosition,
  setRectangleRoundeness,
  setPolystarRotation,
  setPolystarInnerRadius,
  setPolystarOuterRadius,
  setPolystarInnerRoundness,
  setPolystarOuterRoundness,
  setPolystarPoints,
  setStrokeColor,
  setShapeSize,
  setStrokeLineJoin,
  setStrokeLineCap,
  setStrokeMiter,
  setStrokeOpacity,
  addStrokeDash,
  setStrokeWidth,
  getStrokeShape,
  getCurrentStrokeShape,
  getCurrentGFillShape,
  getNodeById,
  getCurrentAppearance,
  removeStrokeDash,
  setStrokeDash,
  removeNode,
  setBlendMode,
  setFillRule,
  addAppearance,
  getAppearanceList,
  updateLayerHighlight,
  setGradient,
  setGradientOpacity,
  setGradientPoint,
} from '~/lib/toolkit';
import { PropertyPanelType } from '~/store/constant';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialSceneJSON: any = {
  assets: [],
  layers: [],
  markers: [],
  properties: {
    sz: {
      w: 512,
      h: 512,
    },
  },
  timeline: {
    duration: 5,
    properties: {
      fr: 30,
      ip: 0,
      op: 150,
    },
  },
};

export interface ToolkitSlice {
  toolkit: {
    addAnimatedValue(type: AnimatedType, value: unknown[], id?: string): void;
    addAppearance(value: string): void;
    addStrokeDash(type: StrokeDashType): void;
    currentFillShape: CurrentFillShape;
    currentFillShapes: Record<string, CurrentFillShape>;
    currentFrame: number;
    currentFrameId: string | null;
    currentGFillShape: CurrentGFillShape;
    currentGFillShapes: Record<string, CurrentGFillShape>;
    currentNode: DagNodeJSON | Record<string, never>;
    currentSecondary: DagNodeJSON | Record<string, never>;
    currentShape: CurrentShape;
    currentShapeNode: DagNode | AVLayer | Shape | null;
    currentStrokeShape: CurrentStrokeShape;
    currentStrokeShapes: Record<string, CurrentStrokeShape>;
    currentTransform: CurrentTransformProperty;
    currentTransformMultiselect: CurrentTransformProperty;
    getCurrentPropertyNode(): unknown | null;
    getCurrentTransformById(id: string): unknown;
    getNodeByIdOnly(id: string): DagNode | null;
    getSecondaryNodeId(): string | null;
    getShape(id: string, type: ShapeType): unknown | null;
    json?: SceneJSON;
    moveLayerDrawOrder(activeIds: string[], increase: boolean): void;
    removeAnimated(id: string, type: string): void;
    removeSelectedNode(id?: string): void;
    removeStrokeDash(id: string): void;
    sceneIndex: number;
    selectedPrecompositionId: string | null;
    selectedPrecompositionJson: SceneJSON | null;
    setAnimatedValue(type: AnimatedType, value: unknown[], id?: string): void;
    setBlendMode(value: BlendModeType): void;
    setCurrentFrame(index: number): void;
    setCurrentFrameId(id: string | null): void;
    setFillColor(color: ColorValue): void;
    setFillOpacity(opacity: number): void;
    setFillRule(value: FillRuleType): void;
    setGradientColor(data: ColorStopJSON[]): void;
    setGradientFillOpacity(opacity: number): void;
    setGradientPoint(name: string, opacity: number): void;
    setJSON(json: SceneJSON): void;
    setLayerDrawOrder(newIndex: number, activeId: string): void;
    setLayerShapeIndex(newIndex: number, activeId: string, parentId: string): void;
    setPolystarInnerRadius(radius: number): void;
    setPolystarInnerRoundness(r: number): void;
    setPolystarOuterRadius(radius: number): void;
    setPolystarOuterRoundness(r: number): void;
    setPolystarPoints(points: number): void;
    setPolystarRotation(deg: number): void;
    setPolystarVariableIsAnimated(variableType: string, isAnimated: boolean): void;
    setRectangleRoundness(r: number): void;
    setRectangleRoundnessIsAnimated(isAnimated: boolean): void;
    setSceneIndex(index: number): void;
    setSelectedPrecompositionId: (id: string | null) => void;
    setSelectedPrecompositionJson: (json: SceneJSON | null) => void;
    setShapePosition(x: number, y: number): void;
    setShapePositionIsAnimated(isAnimated: boolean): void;
    setShapeSize(w: number, y: number): void;
    setShapeSizeIsAnimated(isAnimated: boolean): void;
    setStaticPivot(x: number, y: number, id?: string): void;
    setStaticValue(type: AnimatedType, value: unknown[], id?: string): void;
    setStrokeColor(color: ColorValue): void;
    setStrokeDash(id: string, value: number): void;
    setStrokeLineCap(linCap: number): void;
    setStrokeLineJoin(lineJoin: number): void;
    setStrokeMiter(miter: number): void;
    setStrokeOpacity(opacity: number): void;
    setStrokeWidth(width: number): void;
  };
}

let defaultShapeJSON = {};

try {
  defaultShapeJSON = JSON.parse(JSON.stringify(defaultCurrentShape));
} catch (err) {
  defaultShapeJSON = {};
}

export const createToolkitSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  ToolkitSlice
> = (set, get) => ({
  toolkit: {
    sceneIndex: 0,
    json: initialSceneJSON,
    currentNode: {},
    currentSecondary: {},
    currentShape: JSON.parse(JSON.stringify(defaultShapeJSON)),
    currentTransform: { ...defaultCurrentTransformProperty },
    currentTransformMultiselect: { ...defaultCurrentTransformProperty },
    currentFillShape: { ...defaultCurrentFillShape },
    currentFillShapes: {},
    currentGFillShape: { ...defaultCurrentGFillShape },
    currentGFillShapes: {},
    currentStrokeShapes: {},
    currentStrokeShape: { ...defaultCurrentStrokeShape },
    currentFrame: 0,
    selectedPrecompositionId: null,
    selectedPrecompositionJson: null,
    currentShapeNode: null,
    currentFrameId: null,
    setCurrentFrameId: (id: string | null) => {
      return set((state) => {
        state.toolkit.currentFrameId = id;
      });
    },
    setSceneIndex: (index: number) => {
      return set((state) => {
        state.toolkit.sceneIndex = index;
      });
    },
    setCurrentFrame: (index: number) => {
      return set((state) => {
        state.toolkit.currentFrame = index;
      });
    },
    getShape: (id: string, type: ShapeType): unknown | null => {
      const sceneIndex = get().toolkit.sceneIndex;

      if (type === ShapeType.FILL) {
        return getCurrentFillShape(getNodeById(toolkit.scenes[sceneIndex] as Scene, id) as FillShape);
      } else if (type === ShapeType.STROKE) {
        return getCurrentStrokeShape(getNodeById(toolkit.scenes[sceneIndex] as Scene, id) as StrokeShape);
      } else if (type === ShapeType.GRADIENT_FILL) {
        return getCurrentGFillShape(getNodeById(toolkit.scenes[sceneIndex] as Scene, id) as GradientFillShape);
      }

      return null;
    },
    getCurrentPropertyNode: (): unknown | null => {
      const currentProperty = get().ui.currentPropertyPanel;

      let node = null;

      if (currentProperty === PropertyPanelType.Stroke) {
        node = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);
      } else if (currentProperty === PropertyPanelType.Fill) {
        node = getFillShape(getCurrentNode(get, toolkit) as ShapeLayer);
      } else if (currentProperty === PropertyPanelType.GradientFill) {
        node = getGradientFillShape(getCurrentNode(get, toolkit) as ShapeLayer);
      }

      return node;
    },
    setJSON: (json: SceneJSON) => {
      const selectedNodesInfo = get().ui.selectedNodesInfo;

      const sceneIndex = get().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) return;

      const oldLayerMap = get().ui.layerMap;
      let sceneJSON = json;

      if (get().toolkit.selectedPrecompositionId && get().toolkit.selectedPrecompositionJson) {
        sceneJSON = get().toolkit.selectedPrecompositionJson as SceneJSON;
      }

      const map = getLayerMap(sceneJSON as SceneJSON, oldLayerMap);

      const getCurrentNodeById = (id?: string): unknown => {
        const node = getCurrentNode(get, toolkit, id);
        const currentShape = getCurrentShape(node);
        const currentTransform = getCurrentTransform(node);
        const currentFillShape = getCurrentFillShape(getFillShape(node));
        const currentStrokeShape = getCurrentStrokeShape(getStrokeShape(node));
        const currentGFillShape = getCurrentGFillShape(getGradientFillShape(node));
        const currentShapeNode = getCurrentShapeNode(get, toolkit, id) as ShapeLayer;

        const [appearanceList, newAdded] = getAppearanceList(get, node, map);

        const { currentFillShapes, currentGFillShapes, currentStrokeShapes } = getCurrentAppearance(
          get,
          appearanceList,
        );

        return {
          node,
          currentShape,
          currentTransform,
          currentFillShape,
          currentStrokeShape,
          currentGFillShape,
          currentShapeNode,
          currentFillShapes,
          currentGFillShapes,
          currentStrokeShapes,
          newAdded,
          appearanceList,
        };
      };

      if (selectedNodesInfo.length > 1) {
        const nodes = selectedNodesInfo
          .map((nodeInfo) => getNodeById(scene, nodeInfo.nodeId))
          .filter(Boolean) as DagNode[];

        const [appearanceList, newAdded] = getAppearanceListFromNodes(get, nodes, map);

        const { currentFillShapes, currentGFillShapes, currentStrokeShapes } = getCurrentAppearance(
          get,
          appearanceList,
        );

        set((state) => {
          state.toolkit.json = json;
          state.toolkit.currentFillShapes = currentFillShapes;
          state.toolkit.currentStrokeShapes = currentStrokeShapes;
          state.toolkit.currentGFillShapes = currentGFillShapes;

          state.ui.layerMap = map;
          state.ui.appearanceList = appearanceList;

          newAdded.forEach((added) => {
            const id = added;

            updateLayerHighlight(state.ui.layerMap, id as string);
          });
        });
      } else {
        // const currentShape = getCurrentShape(node);
        // const currentTransform = getCurrentTransform(node);
        // const currentFillShape = getCurrentFillShape(getFillShape(node));
        // const currentStrokeShape = getCurrentStrokeShape(getStrokeShape(node));
        // const currentGFillShape = getCurrentGFillShape(getGradientFillShape(node));
        // const currentShapeNode = getCurrentShapeNode(get, toolkit) as ShapeLayer;

        // const [appearanceList, newAdded] = getAppearanceList(get, node, map);

        // const { currentFillShapes, currentGFillShapes, currentStrokeShapes } = getCurrentAppearance(
        //   get,
        //   appearanceList,
        // );

        const baseCurrentNode = getCurrentNodeById();

        if (baseCurrentNode) {
          const {
            appearanceList,
            currentFillShape,
            currentFillShapes,
            currentGFillShape,
            currentGFillShapes,
            currentShape,
            currentShapeNode,
            currentStrokeShape,
            currentStrokeShapes,
            currentTransform,
            newAdded,
            node,
          } = baseCurrentNode;

          set((state) => {
            state.toolkit.json = json;
            state.toolkit.currentShape = currentShape;
            state.toolkit.currentTransform = currentTransform;
            state.toolkit.currentFillShapes = currentFillShapes;
            state.toolkit.currentStrokeShapes = currentStrokeShapes;
            state.toolkit.currentStrokeShape = currentStrokeShape;
            state.toolkit.currentFillShape = currentFillShape;
            state.toolkit.currentGFillShapes = currentGFillShapes;
            state.toolkit.currentGFillShape = currentGFillShape;
            state.toolkit.currentShapeNode = currentShapeNode;

            state.ui.layerMap = map;
            state.ui.appearanceList = appearanceList;

            if (newAdded.length > 0) {
              const id = newAdded[0];

              updateLayerHighlight(state.ui.layerMap, id as string);
            }

            if (node?.type === 'SHAPE') {
              let secondaryNode: unknown | null = null;
              const getSimplifiedMap = get().ui.getLayerSimplifiedUI;
              const nodeSimplified = getSimplifiedMap(node.nodeId);

              if (nodeSimplified.descendant.length > 0) {
                // Handle one regular shape only
                nodeSimplified.descendant.forEach((descId: string): void => {
                  const descNode = getNodeById(scene, descId);

                  if (['el', 'rc', 'sr'].includes(descNode.type)) {
                    secondaryNode = descNode;
                  }
                });
              }

              if (secondaryNode) {
                const secondCurrentNode = getCurrentNodeById(secondaryNode.nodeId);

                if (secondCurrentNode) {
                  state.toolkit.currentSecondary.currentShape = secondCurrentNode.currentShape;
                  state.toolkit.currentSecondary.currentShapeNode = secondCurrentNode.currentShapeNode;
                }
              }
            }
          });
        }
      }
    },
    setShapePositionIsAnimated: (isAnimated: boolean) => {
      const node = getCurrentShapeNode(get, toolkit) as ShapeLayer;

      node.position.setIsAnimated(isAnimated);
    },
    setShapePosition: (x: number, y: number) => {
      const node = getCurrentShapeNode(get, toolkit) as ShapeLayer;

      setShapePosition(node, [x, y]);
    },
    setStaticPivot: (x: number, y: number, id?: string) => {
      const sceneIndex = get().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) return;
      const node = id ? getNodeById(scene, id) : getCurrentNode(get, toolkit);

      if (node instanceof GroupShape || node instanceof AVLayer) setStaticPivot(node, [x, y]);
    },
    setStaticValue: (type: AnimatedType, value: unknown[], id?: string) => {
      const animated = getAnimatedFunction[type];
      let node: FillShape | AVLayer | null = null;

      if (id) {
        // For select specific animation from multiple appearances using id
        const sceneIndex = get().toolkit.sceneIndex;

        node = getNodeById(toolkit.scenes[sceneIndex] as Scene, id) as FillShape | AVLayer | null;
      } else {
        // For select "current/individual" on single
        node = getAnimatedNode(animated.nodeType, get, toolkit);
      }

      if (!node) {
        return;
      }

      if (animated.hasKeyframes(node)) {
        return;
      } else {
        animated.enableAnimation(node, false);
      }

      animated.setStatic(node, value);
    },
    getSecondaryNodeId: (): string | null => {
      return get().ui.selectedNestedNodesInfo[0]?.nodeId || null;
    },
    setShapeSizeIsAnimated: (isAnimated: boolean) => {
      const nodeId = get().toolkit.getSecondaryNodeId();

      const node = getCurrentShapeNode(get, toolkit, nodeId) as RectangleShape;

      node.size.setIsAnimated(isAnimated);
    },
    setShapeSize: (width: number, height: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as RectangleShape;

      setShapeSize(node, [width, height]);
    },
    setRectangleRoundnessIsAnimated: (isAnimated: boolean) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as RectangleShape;

      node.roundness.setIsAnimated(isAnimated);
    },
    setRectangleRoundness: (r: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as RectangleShape;

      setRectangleRoundeness(node, r);
    },
    setPolystarRotation: (deg: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      setPolystarRotation(node, deg);
    },
    setPolystarInnerRadius: (radius: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      setPolystarInnerRadius(node, radius);
    },
    setPolystarOuterRadius: (radius: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      setPolystarOuterRadius(node, radius);
    },
    setPolystarInnerRoundness: (r: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      setPolystarInnerRoundness(node, r);
    },
    setPolystarOuterRoundness: (r: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      setPolystarOuterRoundness(node, r);
    },
    setPolystarPoints: (points: number) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      setPolystarPoints(node, points);
    },
    setPolystarVariableIsAnimated: (variableType: string, isAnimated: boolean) => {
      const nodeId = get().toolkit.getSecondaryNodeId();
      const node = getCurrentShapeNode(get, toolkit, nodeId) as StarShape;

      if (variableType === 'points') {
        node.numPoints.setIsAnimated(isAnimated);
      } else if (variableType === 'rotation') {
        node.rotation.setIsAnimated(isAnimated);
      } else if (variableType === 'outerRoundness') {
        node.outerRoundness.setIsAnimated(isAnimated);
      } else if (variableType === 'outerRadius') {
        node.outerRadius.setIsAnimated(isAnimated);
      } else if (variableType === 'innerRoundness') {
        node.innerRoundness.setIsAnimated(isAnimated);
      } else if (variableType === 'innerRadius') {
        node.innerRadius.setIsAnimated(isAnimated);
      }
    },
    removeAnimated: (id: string, type: string) => {
      const sceneIndex = get().toolkit.sceneIndex;
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, id);

      if (node && node.animatedProperties.length > 0) {
        const animated = node.animatedProperties.filter((anim) => anim.type === type);

        if (animated.length > 0) {
          animated[0].setIsAnimated(false);
        }
      }
      const setTimelineVisible = get().timeline.setVisible;

      setTimelineVisible(true);
    },
    addAnimatedValue: (type: AnimatedType, value: unknown[], id?: string) => {
      const animated = getAnimatedFunction[type];
      let node = null;

      if (id) {
        // For select specific animation from multiple appearances using id
        const sceneIndex = get().toolkit.sceneIndex;

        node = getNodeById(toolkit.scenes[sceneIndex] as Scene, id);
      } else {
        // For select "current/individual" on single
        node = getAnimatedNode(animated.nodeType, get, toolkit);
      }

      if (node) {
        animated.enableAnimation(node, true);
        animated.setAnimated(node, value);

        const setTimelineVisible = get().timeline.setVisible;

        setTimelineVisible(true);
      }
    },
    setAnimatedValue: (type: AnimatedType, value: unknown[], id?: string) => {
      const animated = getAnimatedFunction[type];
      let node: FillShape | AVLayer | null = null;

      if (id) {
        // For select specific animation from multiple appearances using id
        const sceneIndex = get().toolkit.sceneIndex;

        node = getNodeById(toolkit.scenes[sceneIndex], id) as FillShape | AVLayer | PrecompositionLayer | null;
      } else {
        // For select "current/individual" on single
        node = getAnimatedNode(animated.nodeType, get, toolkit);
      }

      if (!node) {
        return;
      }

      if (node.animatableProperties.length > 0) {
        // Compare the set value with the current value, return if it is same
        if (animated.isSame(node, value)) return;

        // Check for animated properties
        const property = node.animatedProperties.find((prop) => prop.type === animated.key);

        if (property && property.isAnimated) {
          const setTimelineVisible = get().timeline.setVisible;

          setTimelineVisible(true);
          animated.setAnimated(node, value);
        } else {
          animated.setStatic(node, value);
        }
      } else {
        animated.setStatic(node, value);
      }
    },
    setFillColor: (color: ColorValue) => {
      const fl = getFillShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setAnimatedFillColor(fl, color);
    },
    setFillOpacity: (opacity: number) => {
      const fl = getFillShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setAnimatedFillOpacity(fl, opacity);
    },
    setStrokeColor: (color: ColorValue) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeColor(st, color);
    },
    setStrokeWidth: (width: number) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeWidth(st, width);
    },
    setStrokeLineJoin: (lineJoin: number) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeLineJoin(st, lineJoin);
    },
    setStrokeLineCap: (lineCap: number) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeLineCap(st, lineCap);
    },
    setStrokeMiter: (miter: number) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeMiter(st, miter);
    },
    setStrokeOpacity: (opacity: number) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeOpacity(st, opacity);
    },
    addStrokeDash: (name: StrokeDashType) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      addStrokeDash(st, name);
    },
    removeStrokeDash: (id: string) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      removeStrokeDash(st, id);
    },
    setStrokeDash: (id: string, value: number) => {
      const st = getStrokeShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setStrokeDash(st, { id, value });
    },
    removeSelectedNode: (id?: string) => {
      const sceneIndex = get().toolkit.sceneIndex;

      const scene = toolkit.scenes[sceneIndex];

      if (scene) {
        // TODO: handle multi select
        const selectedId = id ? id : (get().ui.selectedNodesInfo[0]?.nodeId as string);

        removeNode(scene, selectedId);

        const parentId = last(get().ui.layerMap.get(selectedId)?.parent);

        set((state) => {
          clearSelectionState(state);

          // Select parent layer after deletion
          if (parentId) {
            state.ui.addToSelectedNodes([parentId], true);

            updateLayerHighlight(state.ui.layerMap, parentId);
          }
        });
      }
    },
    setBlendMode: (value: BlendModeType) => {
      const node = get().toolkit.getCurrentPropertyNode();

      if (node) {
        setBlendMode(node, value);
      }
    },
    setFillRule: (value: FillRuleType) => {
      const node = get().toolkit.getCurrentPropertyNode();

      if (node) {
        setFillRule(node, value);
      }
    },
    addAppearance: (value: string) => {
      const node = getCurrentNode(get, toolkit);

      if (node instanceof GroupShape || node instanceof ShapeL) addAppearance(node as GroupShape, value);
    },
    setGradientColor: (data: ColorStopJSON[]) => {
      const node = getGradientFillShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setGradient(node, data);
    },
    setGradientFillOpacity: (opacity: number) => {
      const gf = getGradientFillShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setGradientOpacity(gf, opacity);
    },
    setGradientPoint: (name: string, opacity: number) => {
      const gf = getGradientFillShape(getCurrentNode(get, toolkit) as ShapeLayer);

      setGradientPoint(gf, name, opacity);
    },
    setSelectedPrecompositionId: (id: string | null) => {
      set((state) => {
        state.toolkit.selectedPrecompositionId = id;
        if (id) {
          const sceneIndex = get().toolkit.sceneIndex;

          const assetNode = getNodeById(toolkit.scenes[sceneIndex] as Scene, id);

          state.toolkit.selectedPrecompositionJson = structuredClone(assetNode?.state) as SceneJSON;
        } else {
          state.toolkit.selectedPrecompositionJson = null;
        }
      });
    },
    setSelectedPrecompositionJson: (json: SceneJSON | null) => {
      set((state) => {
        state.toolkit.selectedPrecompositionJson = structuredClone(json) as SceneJSON;
      });
    },
    setLayerShapeIndex: (newIndex: number, activeId: string, parentId: string) => {
      const sceneIndex = get().toolkit.sceneIndex;
      const toolkitScene = toolkit.scenes[sceneIndex] as Scene | null;

      if (toolkitScene) {
        const updatedShape = getNodeById(toolkitScene, activeId);
        const updatedParentLayer = getNodeById(toolkitScene, parentId as string);

        if (updatedParentLayer) updatedParentLayer.setShapeIndex(updatedShape, newIndex);
      }
    },
    setLayerDrawOrder: (newIndex: number, activeId: string) => {
      const sceneIndex = get().toolkit.sceneIndex;
      const toolkitScene = toolkit.scenes[sceneIndex] as Scene | null;

      if (toolkitScene) {
        const updatedRootShape = getNodeById(toolkitScene, activeId);

        if (updatedRootShape) updatedRootShape.setDrawOrder(newIndex);
      }
    },
    moveLayerDrawOrder: (activeIds: string[], increase: boolean) => {
      const sceneIndex = get().toolkit.sceneIndex;
      const toolkitScene = toolkit.scenes[sceneIndex] as Scene | null;

      if (toolkitScene) {
        activeIds.forEach((activeId, index) => {
          const updatedRootShape = getNodeById(toolkitScene, activeId);

          if (updatedRootShape) {
            const drawOrder = updatedRootShape.state?.properties.do as number | undefined;

            // eslint-disable-next-line no-undefined
            if (drawOrder === undefined) return;
            const newDrawOrder = increase ? drawOrder + activeIds.length : drawOrder - 1;

            if (newDrawOrder < 0 + index) return;

            updatedRootShape.setDrawOrder(newDrawOrder);
          }
        });
      }
    },
    getCurrentTransformById: (id: string): unknown => {
      const sceneIndex = get().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) {
        return null;
      }
      const node = getNodeById(scene, id);
      const activeTransforms = getActiveTransform(node);

      return activeTransforms;
    },
    getNodeByIdOnly: (id: string): DagNode | null => {
      const sceneIndex = get().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) {
        return null;
      }
      const node = getNodeById(scene, id);

      return node;
    },
  },
});
