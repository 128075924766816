/**
 * Copyright 2021 Design Barn Inc.
 */

import DOMPurify from 'dompurify';
import urlRegex from 'url-regex';

export const verifySanitizeURL = (url: string): string => {
  if (typeof url === 'string' && urlRegex({ exact: true }).test(url)) {
    return DOMPurify.sanitize(url);
  }

  return url;
};

export const formatNumber = (num: number, decimal = 2): number => {
  const _dec = decimal.toString();

  return Number(Number(`${Math.round(Number(`${num}e+${_dec}`))}e-${_dec}`));
};

export const isNumber = (value: string | number): boolean => !Number.isNaN(Number(value));

export const isFirefox = (): boolean => navigator.userAgent.indexOf('Firefox') > 0;

export const isLottieURL = (text: string): boolean => {
  const regex = /https?:\/\/.*.lottiefiles.com\/.*.json/gu;

  return Boolean(text.match(regex));
};

// Reference: https://stackoverflow.com/a/49849482
export function isValidURL(text: string): boolean {
  const res = text.match(/(http(s)?:\/\/.)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/gu);

  return res !== null;
}

export const computeAspectRatio = (value: number, ratio: number, isWidth: boolean): number => {
  const newValue = isWidth ? value / ratio : value * ratio;

  return Math.round(newValue * 100) / 100;
};

export interface SizeType {
  height: number;
  width: number;
}

export const scaleToFit = (srcSize: SizeType, maxSize: SizeType): { height: number; width: number } => {
  const scale = Math.min(maxSize.width / srcSize.width, maxSize.height / srcSize.height);

  return { width: srcSize.width * scale, height: srcSize.height * scale };
};

export const isMacOS = navigator.userAgent.includes('Mac');

export const countNumDigit = (number: string | number): number => {
  return `${number}`.replace('.', '').length;
};

export const getBase64Image = async (url: string): Promise<string> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      const reader = new FileReader();

      reader.onloadend = async () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

export const slugify = (str: string): string => {
  return str ? str.toLowerCase().trim().replaceAll(' ', '-') : 'empty-slug';
};

export const getElementBySelectorAsync = async (selector: string): Promise<unknown> =>
  new Promise((resolve) => {
    const getElement = (): void => {
      const element = document.querySelector(selector);

      if (element) {
        resolve(element);
      } else {
        requestAnimationFrame(getElement);
      }
    };

    getElement();
  });

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.slice(0, maxLength - 3)}...`;
};

export async function waitForElm(selector: string): Promise<Element | null> {
  return new Promise((resolve) => {
    const element = document.getElementById(selector);

    if (element) {
      resolve(element);
    } else {
      const observer = new MutationObserver(() => {
        const selectedElement = document.getElementById(selector);

        // Resolve and disconnect observer, even if null, so promise doesnt wait forever
        // if (selectedElement) {
        resolve(selectedElement);
        observer.disconnect();
        // } else {
        // resolve(null);
        // observer.disconnect();
        // }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  });
}

// Use for check if response is JSON, calling from fetch func
export const checkIfRespJSON = async (response: unknown): Promise<boolean> => {
  if (!response) return false;

  const text = await response.text();

  try {
    JSON.parse(text);

    return true;
  } catch (err) {
    return false;
    // throw new Error(`Did not receive JSON, instead received: ${text}`);
  }
};

// Use for convert readableStream from fetch func, to Uint8Array type (dotLottie file format)
export const getUInt8ArrayFromReadableStream = async (response: unknown): Promise<Uint8Array | null> => {
  if (!response) return null;

  const reader = await response.getReader();
  const readerResult = await reader.read();

  return new Uint8Array(readerResult.value.buffer);
};
