/**
 * Copyright 2023 Design Barn Inc.
 */

import {
  Vector3,
  EdgesGeometry,
  BufferGeometry,
  Color,
  DoubleSide,
  ExtrudeGeometry,
  Line,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Path,
  PlaneGeometry,
  Shape,
  SphereGeometry,
  Object3D,
} from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';

import { CMesh } from '../../types';
import type { PathPoint } from '../shapes/path';
import { drawBezier } from '../shapes/path';

import { extrudeSettings } from '~/lib/threejs/PathControls';

export const createBezierPoint = (scale: number): Mesh => {
  const geometry = new PlaneGeometry(8, 8);
  const material = new MeshBasicMaterial({ color: 0xffffff, side: DoubleSide });

  const bezierPoint = new Mesh(geometry, material);

  const line = new MeshLine();

  line.setGeometry(
    new ExtrudeGeometry(new Shape().moveTo(0, 0).lineTo(0, 8).lineTo(8, 8).lineTo(8, 0).lineTo(0, 0), extrudeSettings),
  );

  const material2 = new MeshLineMaterial({
    color: new Color(0x0492c2),
    lineWidth: 1.5 * scale,
  });

  const threeMesh = new CMesh(line.geometry, material2);

  threeMesh.position.setX(-4);
  threeMesh.position.setY(-4);
  bezierPoint.add(threeMesh);

  return bezierPoint;
};

export const createControlPoint = (): Mesh => {
  const lineGeometry = new BufferGeometry().setFromPoints(
    new Path().absarc(0, 0, 5, 0, Math.PI * 2, true).getSpacedPoints(50),
  );
  const mesh = new LineBasicMaterial({ color: 0x0492c2 });
  const line = new Line(lineGeometry, mesh);

  const geometry = new SphereGeometry(4, 32, 32);
  const material = new MeshBasicMaterial({ color: 0xffffff });

  const controlPoint = new Mesh(geometry, material);

  controlPoint.add(line);

  return controlPoint;
};

export const createBezierLine = (start: Vector3, end: Vector3): LineSegments => {
  const shape = new Shape();

  shape.moveTo(start.x, start.y);
  shape.lineTo(end.x, end.y);

  const geometry = new ExtrudeGeometry(shape, extrudeSettings);

  return new LineSegments(new EdgesGeometry(geometry), new LineBasicMaterial({ color: 0x0492c2 }));
};

export const createPathBone = (pointA: PathPoint, pointB: PathPoint, scale: number): CMesh => {
  const line = new MeshLine();

  const shape = drawBezier([pointA, pointB], false);
  const extractedPoints = shape.extractPoints(10).shape;
  const points = extractedPoints.map((point) => new Vector3(point.x, point.y, 0));
  const geometry = new BufferGeometry().setFromPoints(points);

  line.setGeometry(geometry);

  const material2 = new MeshLineMaterial({
    color: new Color(0x0492c2),
    lineWidth: 1.5 * scale,
  });
  const boneMesh = new CMesh(line.geometry, material2);

  boneMesh.position.setZ(-45);

  return boneMesh;
};

export const createPathLine = (points: PathPoint[], scale: number): Object3D => {
  const pathLine = new Object3D();
  const shape = drawBezier(points, false);

  const geometry = new ExtrudeGeometry(shape, extrudeSettings);
  const meshPathLine = new MeshLine();

  meshPathLine.setGeometry(geometry);
  const meshPath = new Mesh(
    meshPathLine.geometry,
    new MeshLineMaterial({
      color: 0x000000,
      lineWidth: 5 * scale,
    }),
  );

  const boneMesh = new Mesh(
    meshPathLine.geometry,
    new MeshLineMaterial({
      color: 0x0492c2,
      lineWidth: 0.8 * scale,
    }),
  );

  pathLine.add(meshPath);
  pathLine.add(boneMesh);

  return pathLine;
};
