/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Scene, AnimatedPropertyJSON } from '@lottiefiles/toolkit-js';
import { flatten, uniq } from 'lodash-es';

import { getNodeById } from './shape';
import { toolkit } from './toolkit';

import { useCreatorStore } from '~/store';

export const getPathToRoot = (id: string, paths: string[]): null => {
  const node = toolkit.getNodeById(id);

  if (node) {
    paths.push(node.nodeId);

    if (node.parent) {
      return getPathToRoot(node.parent.nodeId, paths);
    }
  }

  return null;
};

export const createGetCurrentKeyframe = (cf: number): ((animatedProperty: AnimatedPropertyJSON) => string) => {
  const currentFrame = cf;

  return (animatedProperty: AnimatedPropertyJSON): string => {
    return animatedProperty.keyFrames.find((keyframe) => keyframe.frame === currentFrame)?.frameId || '';
  };
};

export const checkHasActiveKeyFrame = (): ((animatedProperty: AnimatedPropertyJSON) => string) => {
  return (animatedProperty: AnimatedPropertyJSON): string => {
    return animatedProperty.keyFrames.length > 0 ? animatedProperty.keyFrames[0]?.frameId || '' : '';
  };
};

export const getSelectedDrawOrder = (overrideId?: string): number | null => {
  const findParentLayer = (selectedNodeId: string): Record<string, unknown> | Array<Record<string, unknown>> => {
    const getMap = useCreatorStore.getState().ui.getLayerUI;

    const selectedMap = getMap(selectedNodeId);

    if (selectedMap && selectedMap.parent.length === 0) {
      return { id: selectedNodeId, layer: selectedMap };
    } else {
      return selectedMap?.parent.map((parentId: string) => findParentLayer(parentId));
    }
  };

  const getRootLayer = (id: string): Record<string, string | unknown> => {
    let rootLayers = findParentLayer(id);

    rootLayers = Array.isArray(rootLayers)
      ? (uniq(flatten(rootLayers)) as Array<Record<string, unknown>>)
      : (rootLayers as Record<string, unknown>);

    if (Array.isArray(rootLayers)) {
      return uniq(rootLayers)[0] as Record<string, unknown>;
    } else return rootLayers;
  };

  let newDrawOrder = null;

  // TODO: handle multi select
  const selectedNodeId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

  const selectedId = selectedNodeId || selectedPrecompositionId;

  if (selectedId) {
    let id = null;

    if (selectedPrecompositionId) {
      id = selectedNodeId || selectedPrecompositionId;
    } else if (selectedNodeId) {
      const rootLayer = getRootLayer(selectedNodeId);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (rootLayer) {
        id = rootLayer.id;
      }
    }

    if (overrideId) {
      id = overrideId;
    }

    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const toolkitScene = toolkit.scenes[sceneIndex] as Scene | null;

    if (toolkitScene) {
      const updatedRootShape = getNodeById(toolkitScene, id as string);

      if (updatedRootShape && updatedRootShape.drawOrder) newDrawOrder = updatedRootShape.drawOrder;
      else newDrawOrder = 0;
    }
  } else {
    newDrawOrder = 0;
  }

  return newDrawOrder;
};
