/**
 * Copyright 2023 Design Barn Inc.
 */

import type { RectangleShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { registerObjectMethods, getObjectMethods } from '../vmInterface/wrapper';

import { getDrawableShapeMethods } from './drawable-shape';
import { getShapeMethods } from './shape';
import { getSplitPositionMethods } from './split-position';

const methodNames = {
  absolutePosition: 'absolutePosition',
  perimeter: 'perimeter',
  roundness: 'roundness',
  size: 'size',
  setAbsolutePosition: 'setAbsolutePosition',
  setRoundness: 'setRoundness',
  setSize: 'setSize',
};

function getRectangleShapeMethods(plugin: Plugin, rect: RectangleShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, rect);
}

export function createRectangleShapeObject(plugin: Plugin, rect: RectangleShape): object {
  const rectangleShapeObj = {
    nodeId: rect.nodeId,
    nodeType: rect.nodeType,
    type: rect.type,
  };

  registerObjectMethods(plugin, rect, rectangleShapeObj, [
    getNodeMethods,
    getDrawableShapeMethods,
    getSplitPositionMethods,
    getShapeMethods,
    getRectangleShapeMethods,
  ]);

  return rectangleShapeObj;
}
