/**
 * Copyright 2021 Design Barn Inc.
 */

import type React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { startAutoSave } from './shared';

import { getDotCreator } from '~/lib/dotCreator';
import { useProjectAPI } from '~/providers/api/project';
import type { UploadFileRequestProps } from '~/providers/api/project';
import { useCreatorStore } from '~/store';
import { SavingState } from '~/store/projectSlice';

export const SaveFileUpload: React.FC = () => {
  const [subscribing, setSubscribing] = useState(false);
  const [token, selectedDirectoryId, newFileSubscribeKey, newFileVersionId, setInfo] = useCreatorStore(
    (state) => [
      state.user.token,
      state.project.selectedDirectory?.id,
      state.project.info.fileSubscribeKey,
      state.project.info.subscriptionVersionId,
      state.project.setInfo,
    ],
    shallow,
  );

  const {
    createFile,
    createNewVersion,
    createUploadRequest,
    getLatestVersionId,
    handleProjectSubscription,
  } = useProjectAPI(token);

  const handleFile = useCallback(async (): Promise<void> => {
    const sk = useCreatorStore.getState().project.info.fileSubscribeKey;
    const fvi = useCreatorStore.getState().project.info.fileVersionId;
    const fileId = useCreatorStore.getState().project.info.fileId;

    const isUpdate = Boolean(sk && fvi);

    try {
      if (isUpdate && fileId) {
        const res = await createNewVersion();

        if (res) {
          const latestVersionId = await getLatestVersionId({ id: fileId });

          if (latestVersionId) {
            setInfo({
              savingState: SavingState.SAVED,
              fileVersionId: latestVersionId as string,
            });
          }
        }
      } else if (selectedDirectoryId) {
        // SaveToWorkflow step 4 - call file create method
        const res = await createFile();

        if (res) {
          const { currentVersionId, id } = res;

          if (id && currentVersionId) {
            setInfo({
              fileVersionId: currentVersionId,
              fileId: id,
              savingState: SavingState.SAVED,
            });

            const { host, pathname, protocol } = window.location;

            const newUrl = `${protocol}//${host}${pathname}?fileId=${id}`;

            window.history.pushState({}, 'null', newUrl);
          }
        }
      }

      startAutoSave();
    } catch (err) {
      throw new Error(err as string);
    }
  }, [getLatestVersionId, selectedDirectoryId, createFile, createNewVersion, setInfo]);

  const runSubscribe = useCallback(async () => {
    if (newFileSubscribeKey && !subscribing) {
      setSubscribing(true);

      const done = await handleProjectSubscription({ key: newFileSubscribeKey });

      if (done) {
        handleFile();
      }
    }
  }, [handleProjectSubscription, newFileSubscribeKey, handleFile, subscribing]);

  useEffect(() => {
    if ((newFileSubscribeKey && newFileVersionId) || (newFileSubscribeKey && !newFileVersionId)) {
      runSubscribe();
    }
  }, [runSubscribe, newFileSubscribeKey, newFileVersionId]);

  const uploadFileToFMS = useCallback(
    async ({ fields, key, url }: UploadFileRequestProps) => {
      try {
        const subscribeKey = useCreatorStore.getState().project.info.fileSubscribeKey;

        if (!subscribeKey) {
          setInfo({
            fileSubscribeKey: key,
          });
        }

        const formData = new FormData();

        const amzKey = subscribeKey ? subscribeKey : key;

        formData.append('x-amz-meta-key', amzKey);
        Object.keys(fields).forEach((fieldKey: string) => {
          formData.append(fieldKey, fields[fieldKey] as string);
        });

        const creatorFile = await getDotCreator();

        const file = new File(
          [new Blob([JSON.stringify(creatorFile)], { type: 'application/json' })],
          `Animation-${Math.floor(Math.random() * 10000000 + 1)}.creator`,
        );

        formData.append('file', file);

        // SaveToWorkflow step 2 - upload .creator to FMS AWS S3
        await fetch(url, {
          method: 'POST',
          body: formData,
        });
      } catch (err) {
        throw new Error(err as string);
      }
    },
    [setInfo],
  );

  const startUpload = useCallback(async (): Promise<void> => {
    try {
      // SaveToWorkflow step 1 - create upload request params for FMS AWS S3 requirements
      const uploadFMSData = await createUploadRequest();

      if (uploadFMSData) {
        uploadFileToFMS(uploadFMSData);
      }
    } catch (err) {
      throw new Error(err as string);
    }
  }, [createUploadRequest, uploadFileToFMS]);

  useEffect(() => {
    startUpload();
  }, [startUpload]);

  return null;
};
