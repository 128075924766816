/**
 * Copyright 2023 Design Barn Inc.
 */

import type { EllipseShape, RectangleShape, StarShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  setPosition: 'setPosition',
  position: 'position',
};

export function getSplitPositionMethods(
  plugin: Plugin,
  shape: EllipseShape | RectangleShape | StarShape,
): ObjectMethods {
  return getObjectMethods(plugin, methodNames, shape);
}
