/**
 * Copyright 2021 Design Barn Inc.
 */

import { Dialog, Transition, Tab } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { UploadComputer } from './UploadComputer';
import { UploadContext } from './UploadContext';
import type { UploadError } from './UploadError';
import { UploadErrorPanel } from './UploadErrorPanel';
import { UploadUrl } from './UploadUrl';

import LoadingAnimation from '~/assets/animations/upload-loading.json';
import { Close } from '~/assets/icons';
import { Modal } from '~/components/Elements/Modal';
import { ImportType } from '~/data/constant';
import { Divider } from '~/features/property-panel';

interface Props {
  importType: ImportType;
  isOpen: boolean;
  onClose: () => void;
}

export const UploadModal: React.FC<Props> = ({ importType, isOpen, onClose }) => {
  const [error, setError] = useState<UploadError | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<number>(0);

  const Tabs = useMemo(
    () => [
      {
        label: 'From computer',
        value: ImportType.Upload,
      },
      { label: 'From URL', value: ImportType.Url },
    ],
    [],
  );

  // Clear error & loading state when modal is opened
  useEffect(() => {
    if (isOpen) {
      setError(null);
      setLoading(false);
      setActiveTab(Tabs.findIndex((tab) => tab.value === importType));
    }
  }, [isOpen, importType, setLoading, Tabs]);

  const ctx = useMemo(() => ({ isOpen, onClose, error, setError, setLoading, isLoading }), [
    isOpen,
    onClose,
    error,
    setError,
    setLoading,
    isLoading,
  ]);

  return (
    <UploadContext.Provider value={ctx}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 overflow-y-auto" id="upload-modal">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Dialog.Panel className=" max-w-[550px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
                <Dialog.Title
                  as="h3"
                  className=" relative h-10 px-4 py-3 text-left font-lf-regular font-bold text-white "
                >
                  Upload asset
                  <button className="absolute right-4">
                    <Close
                      className="h-4 w-4 cursor-pointer fill-current text-gray-500 hover:opacity-50"
                      onClick={onClose}
                    />
                  </button>
                </Dialog.Title>
                {error && <UploadErrorPanel />}
                {!isLoading && (
                  // Display active tabs after loaded
                  <>
                    <Tab.Group className={'flex border-b-2 border-gray-600'} defaultIndex={activeTab}>
                      <Tab.List className={'tab-list'}>
                        {Tabs.map((tab, index) => (
                          <Tab
                            key={tab.label}
                            className="tab-label"
                            onClick={() => {
                              setActiveTab(index);
                            }}
                          >
                            <span>{tab.label}</span>
                          </Tab>
                        ))}
                      </Tab.List>
                    </Tab.Group>
                    <Divider customClass={''} />
                    {Tabs[activeTab].value === ImportType.Url ? <UploadUrl /> : <UploadComputer />}
                  </>
                )}
                {isLoading && !error && (
                  // Display loading animation when uploading
                  <div className="flex h-full w-[550px] flex-col items-center justify-center p-12">
                    <div className="w-[200px]">
                      <dotlottie-player
                        renderer="svg"
                        autoplay
                        loop
                        src={JSON.stringify(LoadingAnimation)}
                      ></dotlottie-player>
                    </div>
                    <button
                      className="btn-secondary"
                      onClick={() => {
                        setLoading(false);
                        // TODO: actually cancel the process
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Modal>
    </UploadContext.Provider>
  );
};
