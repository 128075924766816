/**
 * Copyright 2022 Design Barn Inc.
 */

import { stateHistory, toolkit } from './toolkit';

import { useCreatorStore } from '~/store';

export const removeKeyFrame = (id: string, track: boolean = true): void => {
  const keyframe = toolkit.getKeyframeById(id);

  const parentTrack = keyframe?.parentTrack;

  // if the keyframe is at 0 and the track the property is not animated, we
  // refuse to remove the keyframe
  if (
    !keyframe ||
    !keyframe.parentTrack ||
    !parentTrack ||
    (keyframe.frame === 0 && !keyframe.parentTrack.isAnimated)
  ) {
    return;
  }
  const setTimelineVisible = useCreatorStore.getState().timeline.setVisible;

  setTimelineVisible(true);

  if (track) {
    stateHistory.beginAction();
  }

  keyframe.parentTrack.removeKeyFrame(keyframe.frame);
  // If it's the last keyframe in the track, set the property to be not
  // animated
  if (parentTrack.keyFrames.length === 0) {
    parentTrack.property.setIsAnimated(false);
  }
  if (track) stateHistory.endAction();
};

export const removeKeyFrames = (id: string): void => {
  const keyframe = toolkit.getKeyframeById(id);

  if (keyframe?.parentTrack?.isAnimated) {
    keyframe.parentTrack.clear();
  }
};

export const setKeyFrame = (id: string, frame: number): void => {
  const keyframe = toolkit.getKeyframeById(id);

  keyframe?.setFrameNo(frame);
};
