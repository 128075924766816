/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useState, useCallback } from 'react';

import { Select } from '~/components/Elements/Select';
import type { Option } from '~/components/Elements/Select';

const propertyCompositionOptions: Option[] = [
  {
    label: 'Below Previous in same...',
    value: 'below-previous-in-same-group',
  },
  {
    label: 'Above Previous in same...',
    value: 'above-previous-in-same-group',
  },
];

export const PropertyCompositeField: React.FC = () => {
  const [compositeField, setCompositeField] = useState<Option>(propertyCompositionOptions[0]);

  const handleOnChangeTemplate = useCallback((option: Option) => {
    setCompositeField(option);
    // TODO: Implement Composite Field
    // On-Hold: Waiting for toolkit introspection and updates
  }, []);

  return (
    <div className="inline-flex h-6 w-full">
      <div className="grow">
        <Select
          styleName="!fixed w-[176px]"
          options={propertyCompositionOptions}
          selected={compositeField}
          onChange={handleOnChangeTemplate}
        />
      </div>
    </div>
  );
};
