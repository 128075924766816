/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useEffect, useState, useContext } from 'react';

import { USERSNAP_PROJECT_API_KEY, OLVY_WORKSPACE } from '~/config';

export const UsersnapContext = React.createContext(null);

interface Props {
  children?: React.ReactNode;
  initParams?: any;
}

export const UsersnapProvider: React.FC<Props> = ({
  initParams = {
    button: { isHidden: true },
  },
  children,
}) => {
  const [usersnap, setUsersnap] = useState(null);

  useEffect(() => {
    let usersnapApi: any = null;

    // Need to use this version as global API key will render button from other project
    // https://help.usersnap.com/docs/usersnap-api-for-project-specific-widgets
    window.onUsersnapCXLoad = (api: any) => {
      api.init(initParams);

      usersnapApi = api;
      setUsersnap(api);
      api.hideButton();
      api.on('close', () => api.hideButton());
    };

    // NOTE: Prod and non-prod both will use Usersnap based on this issue https://linear.app/lottiefiles/issue/CRE-1367/swap-olvy-to-usersnap-in-prod
    // However, Olvy still needs to be init-ed for the whats-new widget to work

    // Olvy setup
    const olvyScript = document.createElement('script');

    olvyScript.defer = (1 as unknown) as boolean;
    olvyScript.src = `https://app.olvy.co/scriptV2.js`;

    const olvyConfig = document.createElement('script');

    olvyConfig.text = `var OlvyConfig = { workspaceAlias: '${OLVY_WORKSPACE}' };`;
    document.head.appendChild(olvyConfig);
    document.head.appendChild(olvyScript);

    // Usersnap setup
    const usersnapScript = document.createElement('script');

    usersnapScript.defer = (1 as unknown) as boolean;
    usersnapScript.src = `https://widget.usersnap.com/load/${USERSNAP_PROJECT_API_KEY}?onload=onUsersnapCXLoad`;
    document.head.appendChild(usersnapScript);

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }
      usersnapScript.remove();
      olvyScript.remove();
    };
    // Can't declare initParams as a dependency because it's an object and it will cause rerender on every change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UsersnapContext.Provider value={usersnap}>{children}</UsersnapContext.Provider>;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
