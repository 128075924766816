/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

export const Cow: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="41" height="39" viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.382812 5.54143H10.1099V12.0262H5.56694C3.77173 12.0262 1.90324 10.634 1.39033 8.91202L0.382812 5.54143Z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9498 5.54143H30.2227V12.0262H34.7656C36.5608 12.0262 38.4293 10.634 38.9422 8.91202L39.9498 5.54143Z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1074 1.98751C10.1074 1.0899 10.8218 0.357162 11.7378 0.357162C12.6354 0.357162 13.3681 1.0899 13.3681 1.98751V6.20075H10.1074V1.98751ZM26.9787 1.98751C26.9787 1.0899 27.6931 0.357162 28.6091 0.357162C29.5067 0.357162 30.2394 1.0899 30.2394 1.98751V6.20075H26.997V1.98751H26.9787Z"
        fill="#9C4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46984 5.54131H30.2246L32.1664 22.4126H7.50977L9.46984 5.54131Z"
        fill="#D8D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20851 25.0139C6.20851 22.5043 8.24186 20.4709 10.7515 20.4709H28.9051C31.4147 20.4709 33.4481 22.5043 33.4481 25.0139V30.2164C33.4481 34.8693 29.6744 38.6429 25.0032 38.6429H14.6167C9.96379 38.6429 6.17188 34.8693 6.17188 30.2164V25.0139H6.20851Z"
        fill="#FF78C7"
      />
      <path
        d="M25.6774 32.1579C27.114 32.1579 28.2786 30.9933 28.2786 29.5567C28.2786 28.1201 27.114 26.9555 25.6774 26.9555C24.2408 26.9555 23.0762 28.1201 23.0762 29.5567C23.0762 30.9933 24.2408 32.1579 25.6774 32.1579Z"
        fill="#E43535"
      />
      <path
        d="M13.9918 32.1579C15.4285 32.1579 16.5931 30.9933 16.5931 29.5566C16.5931 28.12 15.4285 26.9554 13.9918 26.9554C12.5552 26.9554 11.3906 28.12 11.3906 29.5566C11.3906 30.9933 12.5552 32.1579 13.9918 32.1579Z"
        fill="#E43535"
      />
      <path
        d="M15.9549 17.2285C16.6732 17.2285 17.2555 16.6462 17.2555 15.9279C17.2555 15.2096 16.6732 14.6273 15.9549 14.6273C15.2366 14.6273 14.6543 15.2096 14.6543 15.9279C14.6543 16.6462 15.2366 17.2285 15.9549 17.2285Z"
        fill="#595959"
      />
      <path
        d="M23.7381 17.2285C24.4564 17.2285 25.0387 16.6462 25.0387 15.9279C25.0387 15.2096 24.4564 14.6273 23.7381 14.6273C23.0198 14.6273 22.4375 15.2096 22.4375 15.9279C22.4375 16.6462 23.0198 17.2285 23.7381 17.2285Z"
        fill="#595959"
      />
    </svg>
  );
});

export const Bell: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_182_402330)">
        <path
          d="M18.6218 39.4239C21.3556 39.4239 23.5718 37.2077 23.5718 34.4739C23.5718 31.7401 21.3556 29.524 18.6218 29.524C15.8881 29.524 13.6719 31.7401 13.6719 34.4739C13.6719 37.2077 15.8881 39.4239 18.6218 39.4239Z"
          fill="#B18500"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8757 17.6881V24.7778C30.8757 26.0165 31.6857 27.0663 32.8053 27.4249H32.8999C34.8477 27.4249 36.4268 29.004 36.4268 30.9518C36.4268 32.8996 34.8477 34.4786 32.8999 34.4786H4.31395C2.36613 34.4786 0.787109 32.8996 0.787109 30.9518C0.787109 29.004 2.36613 27.4249 4.31395 27.4249H4.44051C5.56036 27.0663 6.37115 26.0165 6.37115 24.7778V17.6881C6.37115 11.9546 10.3082 7.14274 15.6268 5.80575C15.3128 5.27266 15.1473 4.66512 15.1478 4.04642C15.1478 2.12755 16.7039 0.571411 18.6229 0.571411C20.542 0.571411 22.098 2.12755 22.098 4.04642C22.0985 4.66512 21.9331 5.27266 21.619 5.80575C26.9375 7.14274 30.8757 11.9546 30.8757 17.6881ZM17.233 4.04653C17.233 4.81389 17.8557 5.43547 18.623 5.43547C19.3904 5.43547 20.013 4.81377 20.013 4.04653C20.013 3.27806 19.3904 2.65647 18.623 2.65647C17.8557 2.65647 17.233 3.27806 17.233 4.04653Z"
          fill="#FFBF00"
        />
        <path
          d="M32.8999 27.4249H4.31395C2.36613 27.4249 0.787109 29.004 0.787109 30.9518C0.787109 32.8996 2.36613 34.4786 4.31395 34.4786H32.8999C34.8478 34.4786 36.4268 32.8996 36.4268 30.9518C36.4268 29.004 34.8478 27.4249 32.8999 27.4249Z"
          fill="#FEDE7E"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_402330">
          <rect width="35.6397" height="38.8571" fill="white" transform="translate(0.787109 0.571411)" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const Chat: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_182_402321)">
        <path
          d="M20.3434 6.60582C9.61269 6.60582 0.927734 13.7983 0.927734 22.6505C0.927734 26.9865 3.01212 30.9237 6.39604 33.8187L2.63899 39.1069C2.63899 39.1069 7.34817 39.3771 10.5005 36.4821C13.3955 37.8974 16.7537 38.708 20.3563 38.708C31.0871 38.708 39.772 31.5284 39.772 22.6633C39.772 13.7982 31.0613 6.60582 20.3434 6.60582Z"
          fill="#2A94F4"
        />
        <path
          d="M37.0957 30.8208L41.4961 37.0225C41.4961 37.0225 35.8991 37.3442 32.3865 33.7801C31.3186 34.2433 30.1992 34.6293 29.0541 34.8995C27.4972 35.2726 25.8631 35.4785 24.1647 35.4785C13.9744 35.4785 5.70117 28.2089 5.70117 19.228C5.70117 17.3495 6.06144 15.5353 6.7305 13.8497C9.25235 7.51937 16.0974 2.97746 24.1519 2.97746C34.3422 2.97746 42.6155 10.2471 42.6155 19.228C42.6283 23.7699 40.5182 27.8743 37.0957 30.8208Z"
          fill="#CCE9FF"
        />
        <path
          d="M17.7099 20.5533C19.1638 20.5533 20.3476 19.3696 20.3476 17.9157C20.3476 16.4618 19.1638 15.278 17.7099 15.278C16.256 15.278 15.0723 16.4618 15.0723 17.9157C15.0723 19.3696 16.2431 20.5533 17.7099 20.5533Z"
          fill="#2A94F4"
        />
        <path
          d="M24.8603 20.5533C26.3142 20.5533 27.498 19.3696 27.498 17.9157C27.498 16.4618 26.3142 15.278 24.8603 15.278C23.4064 15.278 22.2227 16.4618 22.2227 17.9157C22.2227 19.3696 23.3935 20.5533 24.8603 20.5533Z"
          fill="#2A94F4"
        />
        <path
          d="M32.0146 20.5532C33.4685 20.5532 34.6523 19.3695 34.6523 17.9156C34.6523 16.4617 33.4685 15.2779 32.0146 15.2779C30.5607 15.2779 29.377 16.4617 29.377 17.9156C29.377 19.3695 30.5478 20.5532 32.0146 20.5532Z"
          fill="#2A94F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_402321">
          <rect width="42.9745" height="38.8571" fill="white" transform="translate(0.144531 0.571411)" />
        </clipPath>
      </defs>
    </svg>
  );
});
