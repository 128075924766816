/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import TimelineMainContent from './TimelineMainContent';
import TimelineSidebar from './TimelineSidebar';

export const TimelineBody: React.FC = () => {
  return (
    <div className="flex flex-1 bg-gray-700">
      <TimelineSidebar />
      <TimelineMainContent />
    </div>
  );
};

export default TimelineBody;
