/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/member-ordering */

import type { Scene, Object3D } from 'three';
import {
  PerspectiveCamera,
  Frustum,
  Vector3,
  Matrix4,
  Quaternion,
  OrthographicCamera,
  Line,
  Points,
  InstancedMesh,
} from 'three';

import { RaycasterLayers, CMesh } from '~/features/canvas';

/**
 * This is a class to check whether objects are in a selection area in 3D space
 */

const _frustum = new Frustum();
const _center = new Vector3();

const _tmpPoint = new Vector3();

const _vecNear = new Vector3();
const _vecTopLeft = new Vector3();
const _vecTopRight = new Vector3();
const _vecDownRight = new Vector3();
const _vecDownLeft = new Vector3();

const _vecFarTopLeft = new Vector3();
const _vecFarTopRight = new Vector3();
const _vecFarDownRight = new Vector3();
const _vecFarDownLeft = new Vector3();

const _vectemp1 = new Vector3();
const _vectemp2 = new Vector3();
const _vectemp3 = new Vector3();

const _matrix = new Matrix4();
const _quaternion = new Quaternion();
const _scale = new Vector3();

export default class SelectionBox {
  public camera: OrthographicCamera;

  public collection: CMesh[] = [];

  public deep: number = Number.MAX_VALUE;

  public endPoint: Vector3 = new Vector3();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public instances: any = {};

  public scene: Scene;

  public startPoint: Vector3 = new Vector3();

  public constructor(camera: OrthographicCamera, scene: Scene, deep = Number.MAX_VALUE) {
    this.camera = camera;
    this.scene = scene;
    this.deep = deep;
  }

  public select(startPoint?: Vector3, endPoint?: Vector3): CMesh[] {
    this.startPoint = startPoint || this.startPoint;
    this.endPoint = endPoint || this.endPoint;
    this.collection = [];

    this.updateFrustum(this.startPoint, this.endPoint);
    this.searchChildInFrustum(_frustum, this.scene);

    return this.collection;
  }

  public updateFrustum(startPoint?: Vector3, endPoint?: Vector3): void {
    const sp = startPoint || this.startPoint;
    const ep = endPoint || this.endPoint;

    // Avoid invalid frustum

    if (sp.x === ep.x) {
      ep.x += Number.EPSILON;
    }

    if (sp.y === ep.y) {
      ep.y += Number.EPSILON;
    }

    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();

    if (this.camera instanceof PerspectiveCamera) {
      _tmpPoint.copy(sp);
      _tmpPoint.x = Math.min(sp.x, ep.x);
      _tmpPoint.y = Math.max(sp.y, ep.y);
      ep.x = Math.max(sp.x, ep.x);
      ep.y = Math.min(sp.y, ep.y);

      _vecNear.setFromMatrixPosition(this.camera.matrixWorld);
      _vecTopLeft.copy(_tmpPoint);
      _vecTopRight.set(ep.x, _tmpPoint.y, 0);
      _vecDownRight.copy(ep);
      _vecDownLeft.set(_tmpPoint.x, ep.y, 0);

      _vecTopLeft.unproject(this.camera);
      _vecTopRight.unproject(this.camera);
      _vecDownRight.unproject(this.camera);
      _vecDownLeft.unproject(this.camera);

      _vectemp1.copy(_vecTopLeft).sub(_vecNear);
      _vectemp2.copy(_vecTopRight).sub(_vecNear);
      _vectemp3.copy(_vecDownRight).sub(_vecNear);
      _vectemp1.normalize();
      _vectemp2.normalize();
      _vectemp3.normalize();

      _vectemp1.multiplyScalar(this.deep);
      _vectemp2.multiplyScalar(this.deep);
      _vectemp3.multiplyScalar(this.deep);
      _vectemp1.add(_vecNear);
      _vectemp2.add(_vecNear);
      _vectemp3.add(_vecNear);

      const planes = _frustum.planes;

      planes[0]?.setFromCoplanarPoints(_vecNear, _vecTopLeft, _vecTopRight);
      planes[1]?.setFromCoplanarPoints(_vecNear, _vecTopRight, _vecDownRight);
      planes[2]?.setFromCoplanarPoints(_vecDownRight, _vecDownLeft, _vecNear);
      planes[3]?.setFromCoplanarPoints(_vecDownLeft, _vecTopLeft, _vecNear);
      planes[4]?.setFromCoplanarPoints(_vecTopRight, _vecDownRight, _vecDownLeft);
      planes[5]?.setFromCoplanarPoints(_vectemp3, _vectemp2, _vectemp1);
      planes[5]?.normal.multiplyScalar(-1);
    } else if (this.camera instanceof OrthographicCamera) {
      const left = Math.min(sp.x, ep.x);
      const top = Math.max(sp.y, ep.y);
      const right = Math.max(sp.x, ep.x);
      const down = Math.min(sp.y, ep.y);

      _vecTopLeft.set(left, top, -1);
      _vecTopRight.set(right, top, -1);
      _vecDownRight.set(right, down, -1);
      _vecDownLeft.set(left, down, -1);

      _vecFarTopLeft.set(left, top, 1);
      _vecFarTopRight.set(right, top, 1);
      _vecFarDownRight.set(right, down, 1);
      _vecFarDownLeft.set(left, down, 1);

      _vecTopLeft.unproject(this.camera);
      _vecTopRight.unproject(this.camera);
      _vecDownRight.unproject(this.camera);
      _vecDownLeft.unproject(this.camera);

      _vecFarTopLeft.unproject(this.camera);
      _vecFarTopRight.unproject(this.camera);
      _vecFarDownRight.unproject(this.camera);
      _vecFarDownLeft.unproject(this.camera);

      const planes = _frustum.planes;

      planes[0]?.setFromCoplanarPoints(_vecTopLeft, _vecFarTopLeft, _vecFarTopRight);
      planes[1]?.setFromCoplanarPoints(_vecTopRight, _vecFarTopRight, _vecFarDownRight);
      planes[2]?.setFromCoplanarPoints(_vecFarDownRight, _vecFarDownLeft, _vecDownLeft);
      planes[3]?.setFromCoplanarPoints(_vecFarDownLeft, _vecFarTopLeft, _vecTopLeft);
      planes[4]?.setFromCoplanarPoints(_vecTopRight, _vecDownRight, _vecDownLeft);
      planes[5]?.setFromCoplanarPoints(_vecFarDownRight, _vecFarTopRight, _vecFarTopLeft);
      planes[5]?.normal.multiplyScalar(-1);
    } else {
      // eslint-disable-next-line no-console
      console.error('THREE.SelectionBox: Unsupported camera type.');
    }
  }

  public searchChildInFrustum(frustum: Frustum, object: Object3D): void {
    if (object instanceof CMesh || object instanceof Line || object instanceof Points) {
      if (object.layers.isEnabled(RaycasterLayers.CMesh)) {
        if (object instanceof InstancedMesh) {
          this.instances[object.uuid] = [];

          for (let instanceId = 0; instanceId < object.count; instanceId += 1) {
            object.getMatrixAt(instanceId, _matrix);
            _matrix.decompose(_center, _quaternion, _scale);
            _center.applyMatrix4(object.matrixWorld);

            if (frustum.containsPoint(_center)) {
              this.instances[object.uuid].push(instanceId);
            }
          }
        } else {
          if (object.geometry.boundingSphere === null) object.geometry.computeBoundingSphere();

          _center.copy(object.geometry.boundingSphere.center);

          _center.applyMatrix4(object.matrixWorld);

          if (frustum.containsPoint(_center)) {
            this.collection.push(object as CMesh);
          }
        }
      }
    }

    if (object.children.length > 0) {
      object.children.forEach((child) => this.searchChildInFrustum(frustum, child));
    }
  }
}
