/**
 * Copyright 2022 Design Barn Inc.
 */

import { createClient, JitsuProvider } from '@jitsu/react';
import React from 'react';

import { EVENTSTORE_API_KEY, EVENTSTORE_HOST } from '~/config';

interface Props {
  children?: React.ReactNode;
  initParams?: unknown;
}

export const EventStoreProvider: React.FC<Props> = ({ children }) => {
  const jitsuClient = createClient({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tracking_host: EVENTSTORE_HOST,
    key: EVENTSTORE_API_KEY,
  });

  return <JitsuProvider client={jitsuClient}>{children}</JitsuProvider>;
};
