/**
 * Copyright 2023 Design Barn Inc.
 */

import type { TrimShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { registerObjectMethods, getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  trimEnd: 'trimEnd',
  trimMultipleShapes: 'trimMultipleShapes',
  trimOffset: 'trimOffset',
  trimStart: 'trimStart',
  setTrimEnd: 'setTrimEnd',
  setTrimMultipleShapes: 'setTrimMultipleShapes',
  setTrimOffset: 'setTrimOffset',
  setTrimStart: 'setTrimStart',
};

function getTrimShapeMethods(plugin: Plugin, trim: TrimShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, trim);
}

export function createTrimShapeObject(plugin: Plugin, trim: TrimShape): object {
  const trimShapeObj = {
    nodeId: trim.nodeId,
    nodeType: trim.nodeType,
    type: trim.type,
  };

  registerObjectMethods(plugin, trim, trimShapeObj, [getNodeMethods, getTrimShapeMethods]);

  return trimShapeObj;
}
