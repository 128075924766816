/**
 * Copyright 2023 Design Barn Inc.
 */

import { useCallback, useMemo, useState } from 'react';

interface State {
  open: boolean;
}

export interface OpenableController {
  close(): void;
  isOpen: boolean;
  open(): void;
  toggle(): void;
}

function useMoreButtonController(initialState: State): OpenableController {
  const [state, setState] = useState<State>(initialState);

  const isOpen = state.open;

  const open = useCallback(
    () =>
      setState({
        open: true,
      }),
    [],
  );

  const close = useCallback(
    () =>
      setState({
        open: false,
      }),
    [],
  );

  const toggle = useCallback(
    () =>
      setState((prevState) => ({
        open: !prevState.open,
      })),
    [],
  );

  const api = useMemo(
    () => ({
      open,
      close,
      toggle,
      isOpen,
    }),
    [open, close, toggle, isOpen],
  );

  return api;
}

export default function useOpenable(open = false): OpenableController {
  return useMoreButtonController({
    open,
  });
}
