/**
 * Copyright 2022 Design Barn Inc.
 */

// Temporary disable any check until we implement graphQL typing generation
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable promise/prefer-await-to-then */

import { useJitsu } from '@jitsu/react';
import axios from 'axios';
import React, { useState, useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { SearchBar } from './SearchBar';
import { SearchResult } from './SearchResult';

import { EventType, AssetSource, FileType } from '~/data/eventStore';
// eslint-disable-next-line no-restricted-imports
import { executeUpload, exportDotLottieToJSON } from '~/features/upload/components/middleware';
import { useDisclosure } from '~/hooks';
import { importToCanvas, removeAnimationLoader } from '~/lib/function/import';
import { getSelectedDrawOrder } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { AnimationLoaderStatus } from '~/store/uiSlice';
import { isLottieURL, isValidURL, checkIfRespJSON, getUInt8ArrayFromReadableStream, GenericPayload } from '~/utils';

export const enum CursorType {
  NEXT_CURSOR = 'NEXT_CURSOR',
  PREV_CURSOR = 'PREV_CURSOR',
}
const setAnimationLoader = useCreatorStore.getState().ui.setAnimationLoader;

export const SearchAnimation: React.FC = () => {
  const { track } = useJitsu();

  const handleError = useErrorHandler();

  const [hasError, setHasError] = useState(false);
  const [, setURL] = useState('');

  const [query, setQuery] = useState({ cursor: '', cursorType: CursorType.NEXT_CURSOR, text: '' });
  const [, setAnimationName] = useState('');
  const { onOpen } = useDisclosure();

  const handleOnSubmit = (text: string): void => {
    if (isValidURL(text)) {
      if (isLottieURL(text)) {
        // Check whether it can fetch Lottie JSON
        setAnimationName('');
        axios
          .get(text)
          .then((res) => {
            if (res.status === 200) {
              setURL(text);
              onOpen();
              setHasError(false);
            }
          })
          .catch(() => {
            setHasError(true);
          });
      } else {
        // Currently only support URL from Lottiefiles
        setHasError(true);
      }
    } else {
      // query GraphQL API endpoint
      setHasError(false);
      setQuery({ cursor: '', cursorType: CursorType.NEXT_CURSOR, text });
    }
  };

  const handlePageChange = useCallback((cursor: string, cursorType: CursorType) => {
    setQuery((oldQuery) => ({ ...oldQuery, cursor, cursorType }));
  }, []);

  const handleOnSelected = useCallback(
    async (result: any): Promise<void> => {
      try {
        const drawOrder = getSelectedDrawOrder() as number;
        const lottieUrl = result.lottieUrl;

        setAnimationLoader({
          name: result?.name || '',
          username: result?.createdBy?.username || '',
          avatarUrl: result?.createdBy?.avatarUrl || '',
          url: lottieUrl,
          status: AnimationLoaderStatus.Loading,
        });

        const creatorFileResp = await fetch(lottieUrl as string);

        // creatorFileResp must be clone, to prevent fileStream being locked from execute later
        const clonedResp = creatorFileResp.clone();
        const isJSON = await checkIfRespJSON(clonedResp);

        let creatorFileJSON = null;
        let dotLottieFile = null;

        // Execute tracking event the minute we identify the type of file
        track(EventType.UploadedAsset, {
          client: GenericPayload,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_type: EventType.UploadedAsset,
          parameters: {
            source: AssetSource.LottieLibrary,
            'file-type': isJSON ? FileType.JSON : FileType.DotLottie,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            send_to_amplitude: true,
          },
        });

        if (isJSON) {
          creatorFileJSON = await creatorFileResp.json();
        } else {
          dotLottieFile = await getUInt8ArrayFromReadableStream(creatorFileResp.body);

          if (dotLottieFile) creatorFileJSON = await exportDotLottieToJSON(dotLottieFile);
        }

        if (creatorFileJSON) {
          await executeUpload({
            openLabel: 'Insert anyway',
            revertLabel: 'Insert another',
            data: creatorFileJSON as unknown,
            execute: async () => importToCanvas(drawOrder),
            revert: () => {
              removeAnimationLoader(AnimationLoaderStatus.Reverted);
            },
          });
        }
      } catch (error) {
        handleError(error);
      }

      onOpen();
    },
    [onOpen, track, handleError],
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-2 flex border-b border-gray-900 px-3 text-white">
          <div className=" py-2 text-xs font-semibold">Discover free animations</div>
        </div>
        <SearchBar isError={hasError} onSubmit={handleOnSubmit} />
        <div className="mx-4 h-1 text-xs text-red-500">{hasError && 'Invalid URL'}</div>
      </div>
      {!query.text && <div className="mb-1 mt-2 px-3 text-xs font-semibold text-white">Featured animations</div>}
      <SearchResult query={query} onSelected={handleOnSelected} onPageChanged={handlePageChange} />
    </>
  );
};
