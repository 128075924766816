/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Toolkit, ProjectJSON } from '@lottiefiles/toolkit-js';

export type Unsubscribe = () => void;
export type Key = string | symbol;
export type Subscribe = (onStateChange: () => void) => Unsubscribe;

export type GetState = () => ProjectJSON;

export interface Store {
  getState: GetState;
  subscribe: Subscribe;
}

export function createToolkitEventStore(toolkit: Toolkit): Store {
  const listeners = new Set<() => void>();

  function notify(): void {
    listeners.forEach((listener) => {
      listener();
    });
  }

  toolkit.events.addGlobalListener(notify);

  function getState(): ProjectJSON {
    return toolkit.state;
  }

  const subscribe: Subscribe = (listener: () => void) => {
    listeners.add(listener);

    function unsubscribe(): void {
      listeners.delete(listener);
    }

    return unsubscribe;
  };

  return {
    subscribe,
    getState,
  };
}
