/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { StartSaveFileUpload } from '../../ProjectListener';

import { SaveToDraft } from '.';
import { useCreatorStore } from '~/store';
import { SavingState } from '~/store/projectSlice';

export const CreateFromWorkflow: React.FC = () => {
  const [savingState, madeFirstChange, selectedDirectoryId, saveFromPopup] = useCreatorStore(
    (state) => [
      state.project.info.savingState,
      state.project.madeFirstChange,
      state.project.selectedDirectory?.id,
      state.project.info.saveFromPopup,
    ],
    shallow,
  );

  const isSavingState = savingState === SavingState.SAVING;

  const popupSave = isSavingState && saveFromPopup;

  if (popupSave) return <StartSaveFileUpload />;
  else if (!selectedDirectoryId && madeFirstChange) return <SaveToDraft createNew={true} />;
  else if (madeFirstChange) return <StartSaveFileUpload />;

  return null;
};
