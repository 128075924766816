/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, Scene, VectorJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';

import { AnimatedScaleX, AnimatedScaleY } from '~/assets/icons';
import { TimelineAspectRatioToggle } from '~/components/Elements/Button/TimelineAspectRatioToggle';
import { TimelineDualNumberInput } from '~/components/Elements/Input/TimelineDualNumberInput';
import type { NumberInputRatioResult } from '~/components/Elements/Input/useDualNumberInputRatio';
import { useDualNumberInputRatio } from '~/components/Elements/Input/useDualNumberInputRatio';
import { MAX_TRANSFORM_SCALE, MIN_TRANSFORM_SCALE } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setAnimatedScale, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedScale: React.FC<AnimatedInputProp> = ({ animatedProp, parentId }) => {
  const [selectedNodeId, selectedNodeScale, scaleRatioLock] = useCreatorStore(
    (state) => [
      // TODO: handle multi select
      state.ui.selectedNodesInfo[0]?.nodeId as string,
      state.ui.selectedNodeTransform.scale,
      state.ui.scaleRatioLocked,
    ],
    shallow,
  );
  const sceneIndex = useCreatorStore((state) => state.toolkit.sceneIndex);

  // temp fix
  const animatedValue = animatedProp.value as VectorJSON | null;
  const { x, y } = animatedValue ? animatedValue : { x: 100, y: 100 };

  const { scaleX, scaleY } = useMemo(() => {
    if (selectedNodeId === parentId && selectedNodeScale) {
      return { scaleX: selectedNodeScale.x * 100, scaleY: selectedNodeScale.y * 100 };
    }

    return { scaleX: x, scaleY: y };
  }, [parentId, selectedNodeId, selectedNodeScale, x, y]);

  const handleOnChange = useCallback(
    (result: NumberInputRatioResult) => {
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id) as AVLayer;

      if (node.parent) {
        setAnimatedScale(node.parent as AVLayer, result.value as [number, number]);
        if (result.type === 'scaleX') {
          setAnimatedScale(node.parent as AVLayer, [result.value as number, y]);
        } else if (result.type === 'scaleY') {
          setAnimatedScale(node.parent as AVLayer, [x, result.value as number]);
        }
        emitter.emit(EmitterEvent.SHAPE_SIZE_UPDATED);
      }
    },
    [x, y, sceneIndex, animatedProp],
  );

  const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id) as AVLayer;
  const scaledNodeId = node.parent?.nodeId ?? '';
  const scaleRatioLocked = scaleRatioLock && selectedNodeId === scaledNodeId;

  const { handleInputChange, handleOnClickAspectRatio, isLocked } = useDualNumberInputRatio({
    left: x,
    leftName: 'scaleX',
    right: y,
    onChange: handleOnChange,
    ratioLocked: scaleRatioLocked,
  });

  const toggleScaleRatioLock = useCallback(() => {
    handleOnClickAspectRatio();
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.TOOLKIT_NODE_SCALE_UPDATED,
      data: {
        node: node.parent,
        scaleRatioLock: !isLocked,
      },
    });
  }, [handleOnClickAspectRatio, isLocked, node.parent]);

  return (
    <div className="flex items-center">
      <TimelineAspectRatioToggle isLocked={isLocked} onClick={toggleScaleRatioLock} />
      <TimelineDualNumberInput
        onChange={handleInputChange}
        left={scaleX}
        right={scaleY}
        leftOption={{
          name: 'scaleX',
          label: <AnimatedScaleX className="h-3 w-3" />,
        }}
        rightOption={{
          name: 'scaleY',
          label: <AnimatedScaleY className="h-3 w-3" />,
        }}
        leftMin={MIN_TRANSFORM_SCALE}
        leftMax={MAX_TRANSFORM_SCALE}
        rightMin={MIN_TRANSFORM_SCALE}
        rightMax={MAX_TRANSFORM_SCALE}
      />
    </div>
  );
};
