/**
 * Copyright 2023 Design Barn Inc.
 */

import { Toolkit } from '@lottiefiles/toolkit-js';
import type { Toolkit as ToolkitType, ProjectJSON } from '@lottiefiles/toolkit-js';
import { DotlottiePlugin } from '@lottiefiles/toolkit-plugin-dotlottie';
import { LottiePlugin } from '@lottiefiles/toolkit-plugin-lottie';
import { SvgPlugin } from '@lottiefiles/toolkit-plugin-svg';
import { cloneDeep } from 'lodash-es';

import { checkCompatibleFeatures } from '~/lib/features-checker';
import { importDotLottie } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant/modal';

// Function used to create temporary toolkit instance to import dotCreator/DotLottie and export as JSON
// Temporarily solution until dotlottie-js library able to resolve directly
const createTempToolkit = (): unknown => {
  const newToolkit = new Toolkit({ enableNodeIds: true, useFlatHierarchy: true });

  newToolkit.addPlugin(new LottiePlugin());

  const plugin = new DotlottiePlugin({
    importConfig: {
      enableNodeIds: true,
      standardizeTimeline: true,
    },
  });

  newToolkit.addPlugin(new SvgPlugin());

  newToolkit.addPlugin(plugin);

  newToolkit.initializeState();

  // make sure always fresh new
  return cloneDeep(newToolkit);
};

export const exportDotCreatorToJSON = async (json: unknown): Promise<unknown> => {
  const newToolkit = createTempToolkit() as ToolkitType;

  newToolkit.fromJSON(json as ProjectJSON);
  const scene = newToolkit.scenes[0];

  const result = await newToolkit.export('com.lottiefiles.lottie', {
    scene,
    filePath: 'LottieFiles.lottie',
  });

  return Promise.resolve(result);
};

export const exportDotLottieToJSON = async (data: unknown): Promise<unknown> => {
  const newToolkit = createTempToolkit() as ToolkitType;

  await importDotLottie(newToolkit, {
    dotlottie: data as string | ArrayBuffer,
    enableNodeIds: true,
    standardizeTimeline: true,
  });

  // await (scene as Scene).importAsPrecompositionLayer('com.lottiefiles.dotlottie', options);
  const scene = newToolkit.scenes[0];

  const result = await newToolkit.export('com.lottiefiles.lottie', {
    scene,
    filePath: 'LottieFiles.lottie',
  });

  return Promise.resolve(result);
};

interface UploadMiddlewareProp {
  data?: string;
  execute: () => Promise<void>;
  openLabel: string;
  revert: () => void;
  revertLabel: string;
  type: string;
}

export const executeUpload = async ({
  data,
  execute,
  openLabel = 'Insert anyway',
  revert,
  revertLabel = 'Insert another',
  type,
}: UploadMiddlewareProp): Promise<boolean> => {
  const checkFileType = async (): Promise<string[]> => {
    if (type === 'dotLottie') {
      const jsonData = (await exportDotLottieToJSON(data)) as unknown;

      return checkCompatibleFeatures(jsonData as unknown);
    } else return checkCompatibleFeatures(data as unknown);
  };

  const skipFeatureCheck = useCreatorStore.getState().ui.featureChecker.skip;

  const unsupportedFeatures = skipFeatureCheck ? [] : await checkFileType();

  if (unsupportedFeatures.length > 0) {
    const setFeatureChecker = useCreatorStore.getState().ui.setFeatureChecker;

    setFeatureChecker({ unsupportedFeatures });

    const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

    setTimeout(() => {
      setGlobalModal(GlobalModalConstant.SupportFeatures, {
        openLabel,
        revertLabel,
        openAnywayCallback: execute,
        revertCallback: revert,
      });
    }, 100);
  } else {
    const setFeatureChecker = useCreatorStore.getState().ui.setFeatureChecker;

    setFeatureChecker({ skip: false });
    execute();
  }

  return Promise.resolve(true);
};
