/**
 * Copyright 2022 Design Barn Inc.
 */

import type { SizeJSON, Scene, PrecompositionAsset } from '@lottiefiles/toolkit-js';
import React, { useState, useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { CompositionTemplate } from '~/assets/icons';
import { Select } from '~/components/Elements/Select';
import type { Option } from '~/components/Elements/Select';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setPrecompSize, setSceneSize, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const compositionOptions: Option[] = [
  {
    label: 'Custom size',
    value: 'custom',
  },
  {
    label: '500 x 500',
    value: '500x500',
  },
  {
    label: '1000 x 1000',
    value: '1000x1000',
  },
  {
    label: '1920 x 1080',
    value: '1920x1080',
  },
];

const parseSize = (size: string): [number, number] => {
  const [width, height] = size.split('x');

  return [parseInt(width, 10), parseInt(height, 10)];
};

export const TemplateSizeSelect: React.FC = () => {
  const sceneIndex = useCreatorStore((state) => state.toolkit.sceneIndex);
  const width = useCreatorStore((state) => (state.toolkit.json?.properties.sz as SizeJSON).w) as number;
  const height = useCreatorStore((state) => (state.toolkit.json?.properties.sz as SizeJSON).h) as number;
  const [currentPrecompJson, precompData] = useCreatorStore(
    (state) => [state.toolkit.selectedPrecompositionJson, state.toolkit.selectedPrecompositionJson?.data],
    shallow,
  );

  const [template, setTemplate] = useState<Option>(compositionOptions[1]);

  const handleOnChangeTemplate = useCallback(
    (option: Option) => {
      setTemplate(option);
      if (currentPrecompJson) {
        const precompAsset = getNodeById(toolkit.scenes[sceneIndex] as Scene, currentPrecompJson.id);

        precompAsset?.setData('canvasCustom', true);
        setPrecompSize(precompAsset as PrecompositionAsset, parseSize(option.value as string));
        emitter.emit(EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED);
      } else if (option.value !== 'custom') {
        setSceneSize(toolkit.scenes[sceneIndex] as Scene, parseSize(option.value as string));
        emitter.emit(EmitterEvent.SCENE_SIZE_UPDATED, { ...option });
        emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT);
      }
    },
    [currentPrecompJson, sceneIndex],
  );

  useEffect(() => {
    if (width && height) {
      const inTemplateOptionsIndex = compositionOptions.findIndex(
        (option) =>
          option.value ===
          `${
            currentPrecompJson && precompData
              ? (precompData['canvasWidth'] as number) + (precompData['canvasMinWidth'] as number)
              : width
          }x${
            currentPrecompJson && precompData
              ? (precompData['canvasHeight'] as number) + (precompData['canvasMinHeight'] as number)
              : height
          }`,
      );

      if (inTemplateOptionsIndex === -1) {
        setTemplate(compositionOptions[0]);
      } else {
        setTemplate(compositionOptions[inTemplateOptionsIndex]);
      }
    }
  }, [width, height, currentPrecompJson, precompData]);

  return (
    <div className="inline-flex h-6 w-full">
      <div className="grow">
        <Select options={compositionOptions.slice(1)} selected={template} onChange={handleOnChangeTemplate} />
      </div>
      <div className="ml-3 flex items-center justify-center px-1">
        <CompositionTemplate className="invisible h-4 w-4"></CompositionTemplate>
      </div>
    </div>
  );
};
