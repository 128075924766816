/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/ban-types */

import clsx from 'clsx';
import React, { forwardRef } from 'react';

import type { TreeItemComponentProps, TreeItemComponentType } from './types';

import './FolderTreeItemWrapper.css';

export const FolderTreeItemWrapper: TreeItemComponentType<{}, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TreeItemComponentProps>
>((props, ref) => {
  const {
    childCount,
    className,
    clone,
    collapsed,
    contentClassName,
    depth,
    disableCollapseOnItemClick,
    disableInteraction,
    disableSelection,
    disableSorting,
    ghost,
    handleProps,
    hideCollapseButton,
    indentationWidth,
    indicator,
    isLast,
    item,
    manualDrag,
    onClick,
    onDoubleClick,
    onRemove,
    parent,
    showDragHandle,
    style,
    wrapperRef,
    ...rest
  } = props;

  return (
    <li {...rest} className={clsx('dnd-sortable-tree_folder_wrapper', className)} ref={wrapperRef} style={style}>
      <div
        className={clsx('dnd-sortable-tree_folder_tree-item', contentClassName)}
        ref={ref}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        {...(manualDrag ?? handleProps)}
      >
        {props.children}
      </div>
    </li>
  );
});
