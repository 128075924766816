/**
 * Copyright 2021 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Logout, AvatarEmpty } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { AUTH_AJAX_ENDPOINT } from '~/config';
import { Logout as LogOut } from '~/lib/api/auth';
import { useCreatorStore } from '~/store';
import { verifySanitizeURL } from '~/utils';

interface CircularAvatarProps {
  avatarReady: boolean;
  avatarUrl: string | null;
}

const ProfileNotLoaded: React.FC<void> = () => {
  return (
    <>
      <div className="px-[30px] py-[20.5px]">
        <div className="text-[12px] text-gray-300">Log in to save your work</div>
        <button
          onClick={() => (window.location.href = `${AUTH_AJAX_ENDPOINT}/login?return_to=${window.location.href}`)}
          className="mt-[12px] h-[29px] w-[96px] cursor-pointer rounded-lg bg-teal-300 px-[30.5px] py-[5.5px] text-[12px] leading-[21px] text-white hover:bg-teal-400"
        >
          Log in
        </button>
      </div>
    </>
  );
};

const CircularAvatar: React.FC<CircularAvatarProps> = ({ avatarReady, avatarUrl }: CircularAvatarProps) => {
  return (
    <div className="relative">
      {(!avatarUrl || !avatarReady) && <AvatarEmpty className="h-4 w-4 rounded-full shadow-sm" />}
      {avatarUrl && avatarReady && (
        <img className="h-4 w-4 rounded-full shadow-sm" src={`${avatarUrl}`} alt="profile avatar" />
      )}
    </div>
  );
};

const DropDownList = [
  {
    title: 'Log Out',
    icon: <Logout />,
  },
];

const iconStyle = (active: boolean): string =>
  `ml-1 mr-1 h-4 w-4 inline ${active ? 'stroke-white' : 'stroke-gray-400'}`;

export const Profile: React.FC = () => {
  const [avatarReady, setAvatarReady] = useState(false);
  const [user, setUser] = useCreatorStore((state) => [state.user.info, state.user.setInfo], shallow);

  const avatarUrl = user.avatarUrl as string | null;

  useEffect(() => {
    const checkImage = async (): Promise<void> => {
      try {
        await axios.get(verifySanitizeURL(avatarUrl as string));
      } catch (ex) {
        setUser({ avatarUrl: null });
      }
      setAvatarReady(true);
    };

    if (avatarUrl) {
      checkImage();
    }
  }, [avatarUrl, setUser]);

  const handleDropdownClick = async (title: string): Promise<void> => {
    if (title === 'Log Out') {
      const response = await LogOut();

      if (response?.status === 204) {
        window.location.href = `${AUTH_AJAX_ENDPOINT}/login?return_to=${window.location.href}`;
      }
    }
  };

  return (
    <>
      <Menu as="div" className="relative inline-block">
        {() => (
          <>
            <Tooltip offset={{ mainAxis: 7, crossAxis: -9 }} label="Profile" placement="bottom">
              <Menu.Button className="group ml-1 mr-2 flex h-4 w-4 cursor-pointer items-center justify-center rounded hover:bg-gray-700">
                <CircularAvatar avatarUrl={avatarUrl} avatarReady={avatarReady} />
              </Menu.Button>
            </Tooltip>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-dropdown mt-2 w-[208px] origin-top-right scale-100 divide-y divide-black rounded-md bg-gray-700 opacity-100 shadow-dropdown ring-black focus:outline-none">
                <div className="text-center">{!avatarReady && ProfileNotLoaded()}</div>

                {avatarReady && (
                  <>
                    <Menu.Item key={'profile'}>
                      <div className="z-10 flex hover:cursor-pointer hover:rounded-t-md hover:border-gray-600 hover:bg-gray-600">
                        <div className="mt-2 flex w-full px-2 pt-[5px]">
                          <CircularAvatar avatarUrl={avatarUrl} avatarReady={avatarReady} />
                          <div className="relative bottom-2 ml-2 font-mono text-xl text-gray-300">
                            <p className="text-xs text-white">{user.name}</p>

                            <div className="flex space-x-4">
                              <p className="text-xs">{user.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Menu.Item>
                    <div className="p-2 pt-1">
                      {DropDownList.map((dropdown) => {
                        return (
                          <Menu.Item key={dropdown.title}>
                            {({ active }) => (
                              <button
                                className="mt-1 h-6 w-full items-center rounded-md border-gray-700 p-0.5 text-left text-xs leading-normal text-white hover:border-gray-600 hover:bg-gray-600"
                                onClick={async () => handleDropdownClick(dropdown.title)}
                              >
                                {React.cloneElement(dropdown.icon, {
                                  className: iconStyle(active),
                                })}
                                <span className="inline">{dropdown.title}</span>
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
