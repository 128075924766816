/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Percentage } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from '.';

const methodNames = {
  clone: 'clone',
  equals: 'equals',
  interpolate: 'interpolate',
  set: 'set',
  setValue: 'setValue',
  toArray: 'toArray',
  toDecimal: 'toDecimal',
  // toJSON: 'toJSON',
  toObject: 'toObject',
  toString: 'toString',
};

function getPercentageMethods(plugin: Plugin, percentage: Percentage): ObjectMethods {
  return getObjectMethods(plugin, methodNames, percentage);
}

interface PercentageObject extends ValueObject {
  value: number;
}

export function createPercentageObject(plugin: Plugin, percentage: Percentage): PercentageObject {
  const percentageObject = {
    value: percentage.value,
    valueType: 'Percentage',
  };

  registerObjectMethods(plugin, percentage, percentageObject, [getPercentageMethods]);

  return percentageObject;
}
