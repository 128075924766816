/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
// extend the threejs built-in types specifically for creator project

// eslint-disable-next-line max-classes-per-file
import { LayerType } from '@lottiefiles/toolkit-js';
import type { Material, MeshBasicMaterial, BufferGeometry } from 'three';
import { Vector3, Mesh, Object3D, Quaternion } from 'three';

import { ShapeTypes } from '~/store/constant';

// CObject3D = creator extended Object3D
export class CObject3D extends Object3D {
  public anchorPosition = new Vector3();

  // display object on canvas, without interact with toolkit and timelineLayers
  public displayOnly: boolean = false;

  public drawOrder = 0;

  // it true, the group rotation is same as this object's rotation
  public groupRotation = false;

  public initialPosition = new Vector3();

  public initialRotation = 0;

  public layerType: LayerType = LayerType.GROUP;

  public offset = new Vector3();
  
  // group opacity applies to all meshes inside the group
  public opacity = 1;

  public parentQuaternion = new Quaternion();

  public parentQuaternionInv = new Quaternion();

  public parentScale = new Vector3(1, 1, 1);

  public pointEnd = new Vector3();

  public pointStart = new Vector3();

  public startPosition = new Vector3();

  public startQuaternion = new Quaternion();

  public startScale = new Vector3(1, 1, 1);

  public toolkitAnchorPosition: Vector3 = new Vector3();

  public toolkitId: string = this.uuid;

  public worldPosition = new Vector3();

  public worldPositionStart = new Vector3();

  public worldQuaternion = new Quaternion();

  public worldQuaternionInv = new Quaternion();
}

export class CMesh<
TGeometry extends BufferGeometry = BufferGeometry,
TMaterial extends Material | Material[] = Material | Material[],
> extends Mesh {
  public anchorPosition = new Vector3();

  // onBeforeCompile method cannot be called from several places for one material.
  // Therefore, in case we need to change the vertex shader and fragment shader from several places,
  // we should store the shader content in these two variables(customChunks and customUniforms) and apply at the end of shape parsing
  public customChunks: any = {};

  public customUniforms: any = {};

  public drawOrder = 0;

  public groupRotation = false;

  public initialPosition = new Vector3();

  public initialRotation = 0;

  public layerType: LayerType = LayerType.SOLID;

  public override material: MeshBasicMaterial;

  public opacity = 1;

  public shapeType: ShapeTypes = ShapeTypes.None;

  public toolkitAnchorPosition: Vector3 = new Vector3();

  public toolkitId: string = this.uuid;

  public constructor(geometry?: TGeometry, material?: TMaterial) {
    super(geometry, material);
    this.material = material as MeshBasicMaterial;
  }
}
