/**
 * Copyright 2023 Design Barn Inc.
 */

import { gql } from 'urql';

export const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfoFields on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const PUBLIC_ANIMATION_FRAGMENT = gql`
  fragment PublicAnimationFields on PublicAnimation {
    id
    url
    name
    downloads
    lottieVersion
    sourceFileUrl
    lottieUrl
    imageUrl
    videoUrl
    createdAt
    updatedAt
    gifUrl
    sourceFileType
    lottieFileType
    createdBy {
      id
      firstName
      avatarUrl
      username
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;
