/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Toolkit } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useUpdate } from 'react-use';

import { createToolkitEventStore } from '~/lib/create-toolkit-event-store';
import { toolkit, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const ToolkitContext = React.createContext<Toolkit | null>(null);
const ToolkitStoreContext = React.createContext<unknown>(null);
const ToolkitHistoryContext = React.createContext<unknown>(null);

export const ToolkitHistoryProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return <ToolkitHistoryContext.Provider value={stateHistory}>{children}</ToolkitHistoryContext.Provider>;
};

export const ToolkitStoreProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [store] = React.useState(() => createToolkitEventStore(toolkit));

  return <ToolkitStoreContext.Provider value={store}>{children}</ToolkitStoreContext.Provider>;
};

const ToolkitListener = (): null => {
  const isAddingToolkitListener = useCreatorStore((state) => state.ui.isAddingToolkitListener);

  const rerender = useUpdate();

  React.useEffect(() => {
    toolkit.events.addGlobalListener(rerender);

    useCreatorStore.getState().ui.setIsAddingToolkitListener(false);

    return (): void => {
      toolkit.events.removeGlobalListener(rerender);
    };
  }, [rerender, isAddingToolkitListener]);

  return null;
};

export const ToolkitProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return (
    <>
      <ToolkitListener />
      <ToolkitContext.Provider value={toolkit}>
        <ToolkitStoreProvider>
          <ToolkitHistoryProvider>{children}</ToolkitHistoryProvider>
        </ToolkitStoreProvider>
      </ToolkitContext.Provider>
    </>
  );
};
