/**
 * Copyright 2022 Design Barn Inc.
 */

export const TIMELINE_BOTTOM_GUTTER = 70;
export const LAYER_PANEL_DEFAULT_WIDTH = 300;
export const TIMELINE_BAR_BEGIN_OFFSET_PX = 15;
export const KEYFRAME_ICON_HALF_WIDTH = 7;
export const TIMELINE_SCRUBBER_DEBOUNCE_MS = 10;
export const TIMELINE_KEYFRAME_DRAG_DEBOUNCE_MS = 20;
