/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Color, GradientColor, Scalar } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from '.';

const methodNames = {
  addColor: 'addColor',
  clone: 'clone',
  equals: 'equals',
  hasOpacity: 'hasOpacity',
  interpolate: 'interpolate',
  setColorStops: 'setColorStops',
  // toJSON: 'toJSON',
};

function getGradientColorMethods(plugin: Plugin, gradientColor: GradientColor): ObjectMethods {
  return getObjectMethods(plugin, methodNames, gradientColor);
}

export interface ColorStop {
  color: Color;
  stop: Scalar;
}

interface GradientColorObject extends ValueObject {
  colorStops: ColorStop[];
  colors: Color[];
  stops: Scalar[];
}

export function createGradientColorObject(plugin: Plugin, gradientColor: GradientColor): GradientColorObject {
  const gradientColorObject = {
    colorStops: gradientColor.colorStops,
    colors: gradientColor.colors,
    stops: gradientColor.stops,
    valueType: 'GradientColor',
  };

  registerObjectMethods(plugin, gradientColor, gradientColorObject, [getGradientColorMethods]);

  return gradientColorObject;
}
