/**
 * Copyright 2021 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { TimelinePlayerChevronDown } from '~/assets/icons';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

// TODO: temporary set hidden until we extract out time property
const TimeDropDown: React.FC = () => {
  return (
    <Menu as="div" className="relative inline-block">
      {({ open }) => (
        <>
          <Menu.Button
            className={clsx('group ml-1 hidden h-4 w-4 items-center justify-center rounded hover:bg-gray-700', {
              'bg-gray-700': open,
            })}
          >
            <TimelinePlayerChevronDown
              className={clsx('h-3 w-3 group-hover:stroke-white', {
                'stroke-white': open,
                'stroke-gray-400': !open,
              })}
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-[5px] w-[172px] rounded-lg bg-gray-800 py-2 drop-shadow-player">
              <div>content</div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const pad = (num: number, size: number): string => {
  return `000${num}`.slice(size * -1);
};

interface PlaybackTime {
  frames: string;
  seconds: string;
}

export const formatPlaybackTime = (input: string | number, fps: number): PlaybackTime => {
  const totalFrames = parseFloat(input as string) * fps;
  const minutes = Math.floor(parseFloat(input as string) / 60);
  const seconds = (Math.floor(parseFloat(input as string) % 60) + minutes * 60).toString();
  const remainingSeconds = totalFrames % (60 * fps);
  const frames = Math.round(remainingSeconds % fps).toString();

  return {
    frames,
    seconds,
  };
};

export const formatTime = (input: string | number, fps: number): string => {
  const secondsRegex = /^[+-]?\d*\.?\d+$/u;
  const timeRegex = /^(\d{1,3}):(\d{1,3}):(\d{1,3})$/u;

  let formattedTime;

  if (secondsRegex.test(input as string)) {
    // if format is in seconds return formatted time
    const totalFrames = parseFloat(input as string) * fps;
    const minutes = pad(Math.floor(parseFloat(input as string) / 60), 2);
    const seconds = pad(Math.floor(parseFloat(input as string) % 60), 2);
    const remainingSeconds = totalFrames % (60 * fps);
    const frames = pad(Math.round(remainingSeconds % fps), 2);

    formattedTime = `${minutes}:${seconds}:${frames}`;
  } else if (timeRegex.test(input as string)) {
    // if in M:S:F / MM:SS:FF / MMM:SSS:FFF format
    // check if it's valid
    const match = timeRegex.exec(input as string);
    const minutes = (match as RegExpExecArray)[1] as string;
    const seconds = (match as RegExpExecArray)[2] as string;
    const frames = (match as RegExpExecArray)[3] as string;

    const notValid = parseFloat(seconds) > 60 || parseFloat(frames) > fps;

    if (notValid) {
      const secondsFromFrames = parseFloat(frames) / fps;
      const totalSeconds = parseFloat(seconds) + parseFloat(minutes) * 60 + secondsFromFrames;

      formattedTime = formatTime(totalSeconds, fps);
    } else {
      formattedTime = input;
    }
  } else {
    formattedTime = input;
  }

  return formattedTime as string;
};

const Time: React.FC = () => {
  const [
    currentTime,
    duration,
    sceneIndex,
    currentFrameRate,
    currentPrecompJson,
    precompFrameRate,
    precompDuration,
    precompCurrentFrame,
  ] = useCreatorStore(
    (state) => [
      (state.toolkit.json?.timeline.properties.ct as number) || 0,
      (state.toolkit.json?.timeline.duration as number) || 0,
      state.toolkit.sceneIndex,
      state.toolkit.json?.timeline.properties.fr as number,

      state.toolkit.selectedPrecompositionJson,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
      state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      (state.toolkit.selectedPrecompositionJson?.timeline.properties.ct as number) || 0,
    ],
    shallow,
  );

  // 30 is the default frame rate
  const fps = toolkit.scenes[sceneIndex]?.timeline.frameRate ?? 30;

  const [frameRateDisplay, setFrameRateDisplay] = useState(currentPrecompJson ? precompFrameRate : fps);
  const [durationDisplay, setDurationDisplay] = useState(currentPrecompJson ? precompDuration : duration);

  // if not clamped, decimal points on currentTime adds on extra frames
  let clampedCurrentTime = currentTime > durationDisplay ? durationDisplay : currentTime;

  if (currentPrecompJson) {
    clampedCurrentTime = precompCurrentFrame > durationDisplay ? durationDisplay : precompCurrentFrame;
  }

  useEffect(() => {
    if (currentPrecompJson) {
      setFrameRateDisplay(precompFrameRate);

      setDurationDisplay(precompDuration);
    } else {
      setFrameRateDisplay(currentFrameRate);

      setDurationDisplay(duration);
    }
  }, [
    clampedCurrentTime,
    currentFrameRate,
    currentPrecompJson,
    currentTime,
    duration,
    precompCurrentFrame,
    precompDuration,
    precompFrameRate,
  ]);
  const playbackTime = formatPlaybackTime(clampedCurrentTime, frameRateDisplay as number);

  return (
    <div>
      <div className="flex text-xs leading-[18px]">
        <div className="flex w-[50px] text-white ">
          <div className="w-[20px] text-center">{playbackTime.seconds}s</div>
          <div className="ml-[4px] w-[20px]">{playbackTime.frames}f</div>
        </div>
      </div>
    </div>
  );
};

export const TimeControl: React.FC = () => {
  return (
    <div className="flex items-center">
      <Time />
      <TimeDropDown />
    </div>
  );
};
