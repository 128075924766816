/**
 * Copyright 2023 Design Barn Inc.
 */

import Tracker from '@openreplay/tracker';

import { OPEN_REPLAY_KEY, OPEN_REPLAY_INGEST_POINT } from '~/config';

export const tracker = new Tracker({
  projectKey: OPEN_REPLAY_KEY,
  ingestPoint: OPEN_REPLAY_INGEST_POINT,
});

export const runOpenReplay = async (): Promise<void> => {
  await tracker.start();
};
