/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Toolkit, Scene } from '@lottiefiles/toolkit-js';
import type { ImportOptions } from '@lottiefiles/toolkit-plugin-dotlottie';
import type { SvgImportOptions } from '@lottiefiles/toolkit-plugin-svg';

export const importLottie = async (toolkit: Toolkit, options: Record<string, unknown>): Promise<Scene> => {
  return toolkit.import('com.lottiefiles.lottie', options);
};

export const importDotLottie = async (toolkit: Toolkit, options: ImportOptions): Promise<Scene> => {
  return toolkit.import('com.lottiefiles.dotlottie', options);
};

export const importSVG = async (toolkit: Toolkit, options: SvgImportOptions): Promise<Scene> => {
  return toolkit.import('com.lottiefiles.svg', options);
};
