/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PrecompositionLayer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getAvLayerMethods } from '../layer/av-layer';
import { getLayerMethods } from '../layer/layer';
import { getNodeMethods } from '../node';
import { getHasTimelineMethods } from '../scene/has-timeline';
import { getSizableMethods } from '../scene/sizable';
import { getTransformableMethods } from '../shape/transformable';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  // fromJSON: 'fromJSON',
  getPrecompFrame: 'getPrecompFrame',
  precomposition: 'precomposition',
  precompositionScene: 'precompositionScene',
  timeMapping: 'timeMapping',
  loadPrecompScene: 'loadPrecompScene',
  reverseAnimation: 'reverseAnimation',
  setPrecompFrameToSceneFrame: 'setPrecompFrameToSceneFrame',
  setPrecomposition: 'setPrecomposition',
  setPrecompTimeToSceneTime: 'setPrecompTimeToSceneTime',
  setTimelineOffset: 'setTimelineOffset',
  setTimeStretch: 'setTimeStretch',
};

function getPrecompLayerMethods(plugin: Plugin, precompLayer: PrecompositionLayer): ObjectMethods {
  return getObjectMethods(plugin, methodNames, precompLayer);
}

export function createPrecompLayerObj(plugin: Plugin, precompLayer: PrecompositionLayer): object {
  const precompLayerObj = {
    nodeId: precompLayer.nodeId,
    nodeType: precompLayer.nodeType,
    type: precompLayer.type,
  };

  registerObjectMethods(plugin, precompLayer, precompLayerObj, [
    getNodeMethods,
    getSizableMethods,
    getLayerMethods,
    getHasTimelineMethods,
    getPrecompLayerMethods,
    getAvLayerMethods,
    getTransformableMethods,
  ]);

  return precompLayerObj;
}
