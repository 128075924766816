/**
 * Copyright 2023 Design Barn Inc.
 */

import { emitter, EmitterEvent } from './emitter';

import { toolkit, stateHistory, getToolkitState } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const addingToolkitListener = (
  callback: (selectedState: boolean, previousSelectedState: boolean) => void,
): (() => void) => {
  return useCreatorStore.subscribe((state) => state.ui.isAddingToolkitListener === false, callback, {
    fireImmediately: true,
  });
};

const waitForAddingToolkitListener = async (): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const unsub = addingToolkitListener((isAdded: boolean) => {
      if (isAdded) {
        unsub();
        resolve(true);
      }
    });
  });
};

export const undoHistory = async (event: string): Promise<null> => {
  const setJSON = useCreatorStore.getState().toolkit.setJSON;

  if (stateHistory.canUndo && stateHistory.index > 0) {
    // await for adding toolkitListener
    useCreatorStore.getState().ui.setIsAddingToolkitListener(true);
    await waitForAddingToolkitListener();

    // run toolkit undo
    const undoStateType = stateHistory.history[stateHistory.history.length - 1]?.type;

    if (undoStateType) {
      stateHistory.undo();

      // check latest json
      const json = structuredClone(getToolkitState(toolkit));

      setJSON(json);
    }

    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event });
  }

  return null;
};

export const redoHistory = async (event: string): Promise<null> => {
  if (stateHistory.canRedo) {
    // await for adding toolkitListener
    useCreatorStore.getState().ui.setIsAddingToolkitListener(true);
    await waitForAddingToolkitListener();

    stateHistory.redo();
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event });
  }
};
