/**
 * Copyright 2022 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/toolkit-js';
import type {
  SizeJSON,
  Scene as ToolkitScene,
  PrecompositionAssetJSON,
  PrecompositionLayerJSON,
  ShapeJSON,
  AVLayer,
  GroupShapeJSON,
} from '@lottiefiles/toolkit-js';
import CameraControls from 'camera-controls';
import type { Intersection } from 'three';
import {
  Scene,
  DoubleSide,
  MeshBasicMaterial,
  OrthographicCamera,
  PointLight,
  WebGLRenderer,
  AmbientLight,
  PlaneGeometry,
  RepeatWrapping,
  TextureLoader,
  Raycaster,
  Vector3,
} from 'three';
// eslint-disable-next-line import/no-namespace
import * as THREE from 'three';

import { getBackground } from '../3d/threeFactory/background';
import DragSelector from '../3d/utils/dragSelector';
import { getMouseCoord } from '../3d/utils/mouse';
import { unProject, clearThree, getParentLayer, getPointer } from '../3d/utils/three';
import { ViewportConfig } from '../config';
import { RaycasterLayers, DOUBLE_CLICK_DELAY, UserDataMap } from '../constant';
import { ToolkitListener } from '../toolkit/listener';
import { CMesh, CObject3D } from '../types/object';

import Editor from './editor';

import grid from '~/assets/images/grid.png';
import { ellipseOption, rectangleOption } from '~/components/Layout/Sidebar/SidebarPanel/ShapeSidePanel';
import { ToolType } from '~/data/constant';
// eslint-disable-next-line no-restricted-imports
import { getLoadingGif, startLoading, stopLoading } from '~/features/canvas/3d/threeFactory/loading';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { PathControls } from '~/lib/threejs/PathControls';
import { TransformControls } from '~/lib/threejs/TransformControls';
import type { Scalar2D, ShapeOption } from '~/lib/toolkit';
import { stateHistory, getAssetByReferenceId, createShape, getToolkitState, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { AnimationLoaderStatus } from '~/store/uiSlice';

CameraControls.install({ THREE });
const clock = new THREE.Clock();

const setZoomPercentage = useCreatorStore.getState().ui.setZoomPercentage;
const setTimelineVisible = useCreatorStore.getState().timeline.setVisible;
const setHasShownOnetimeTooltip = useCreatorStore.getState().ui.setHasShownOnetimeTooltip;
const setHovered = useCreatorStore.getState().ui.setCanvasHoveredNodeId;
const setPivotVisibility = useCreatorStore.getState().ui.setPivotVisibility;
const setScaleRatioLocked = useCreatorStore.getState().ui.setScaleRatioLocked;
const setSizeRatioLocked = useCreatorStore.getState().ui.setSizeRatioLocked;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

export default class Viewport {
  public camera: OrthographicCamera;

  public cameraControls: CameraControls | null = null;

  public canvasResizing = false;

  public canvasSize: SizeJSON | null = null;

  public container: HTMLCanvasElement;

  public dragSelector: DragSelector | null = null;

  public editor: Editor | null = null;

  public gridBackground: CMesh | null = null;

  public hoveredID: string | null = null;

  // any objects added to the scene from toolkit json will be included as a child of this object
  public objectContainer = new CObject3D();

  public pathControls: PathControls;

  public raycaster = new Raycaster();

  public renderer: WebGLRenderer | null = null;

  public scene = new Scene();

  public selectedIDs: string[] = [];

  public selectedToolbar: ToolType;

  public toolkitListener: ToolkitListener;

  public transformControls: TransformControls;

  public constructor(container: HTMLCanvasElement) {
    this.container = container;
    const width = container.clientWidth;
    const height = container.clientHeight;

    this.camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 0.1, 1000);
    this.camera.zoom = ViewportConfig.CameraZoom;
    // this will refine the camera up vector and its position
    // so that the coordinate origin can be placed at the top left of the canvas
    this.camera.up.set(0, -1, 0);
    this.camera.position.set(
      ViewportConfig.BackgroundWidth / 2,
      ViewportConfig.BackgroundHeight / 2,
      ViewportConfig.CameraZPosition,
    );
    this.camera.updateProjectionMatrix();

    this.scene.add(this.objectContainer);

    this.selectedToolbar = ToolType.Move;
    this.toolkitListener = new ToolkitListener(this);

    this._initRenderer();
    this._initCameraControls();
    this._initLight();
    this._initGridBackground();

    const json = getToolkitState(toolkit);
    const size = json.properties.sz as SizeJSON;

    this.adjustCanvasSize(size);

    this.transformControls = new TransformControls(this.camera, this.container, this.scene);
    this.scene.add(this.transformControls);
    if (this.renderer)
      this.dragSelector = new DragSelector(
        this.camera,
        this.scene,
        this.renderer,
        this.container,
        this.transformControls,
      );

    this.editor = new Editor(this.scene, this.objectContainer, this.transformControls);
    this.pathControls = new PathControls(container, this);

    this._addEventListeners();
    this._step();
  }

  // depending on the canvas size, we need to adjust the camera zoom value
  // so that the canvas always fit into the screen
  public adjustCamera(size: SizeJSON = { w: 500, h: 500 }): void {
    this.canvasSize = size;
    const canvas = document.getElementById('artboard-canvas');

    if (!canvas) {
      // eslint-disable-next-line no-console
      console.error('canvas does not exist');

      return;
    }
    this.camera.zoom = Math.min(
      (canvas.clientWidth - ViewportConfig.Margin) / size.w,
      (canvas.clientHeight - ViewportConfig.Margin) / size.h,
    );

    this.camera.updateProjectionMatrix();

    this.cameraControls?.setLookAt(
      size.w / 2,
      size.h / 2,
      ViewportConfig.CameraZPosition,
      size.w / 2,
      size.h / 2,
      0,
      true,
    );

    this.camera.updateProjectionMatrix();

    if (this.gridBackground) {
      this.gridBackground.scale.set(1 / this.camera.zoom, 1 / this.camera.zoom, 1);
    }
    const percentage = (100 * this.camera.zoom) / ViewportConfig.CameraZoom;

    setZoomPercentage(percentage);
  }

  // adjust canvas size depending on the size of the added object
  public async adjustCanvasSize(size: SizeJSON): Promise<void> {
    // using selectedPrecompositionId to check if precomp is selected before adjusting camera zoom
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    this.scene.children.filter((child) => child.name === 'background').forEach((background) => clearThree(background));

    this.canvasSize = size;
    const backgroundPlane = await getBackground(size.w, size.h);

    this.editor?.addObject(backgroundPlane, this.scene);

    this.cameraControls?.setLookAt(
      size.w / 2,
      size.h / 2,
      ViewportConfig.CameraZPosition,
      size.w / 2,
      size.h / 2,
      0,
      true,
    );
    this.camera.updateProjectionMatrix();

    const artboardHeight = document.getElementById('middle-content')?.clientHeight;

    if (typeof artboardHeight !== 'undefined' && artboardHeight > 80 && !selectedPrecompositionId) {
      this.camera.zoom = (artboardHeight - 80) / size.h;
    }
  }

  public clearScene(): void {
    this.transformControls.detach();
    while (this.objectContainer.children.length > 0) {
      const child = this.objectContainer.children[0];

      clearThree(child);
    }
  }

  public createBasicShapes(shapeType: ShapeType): void {
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const toolkitScene = toolkit.scenes[sceneIndex];
    const json = getToolkitState(toolkit);
    const outPoint = json.timeline.properties.op as number;
    const currentFrame = json.timeline.properties.cf as number;
    const width = (json.properties.sz as SizeJSON).w as number;
    const height = (json.properties.sz as SizeJSON).h as number;

    const position: Scalar2D = [width / 2, height / 2];

    let shapeOption: ShapeOption | undefined;

    if (shapeType === ShapeType.RECTANGLE) shapeOption = rectangleOption;
    else if (shapeType === ShapeType.ELLIPSE) shapeOption = ellipseOption;
    if (!shapeOption || !toolkitScene) return;
    stateHistory.beginAction();
    createShape(toolkitScene, {
      ...shapeOption,
      endFrame: outPoint,
      startFrame: currentFrame,
      position,
    });
    stateHistory.endAction();
    // stateHistory.removeTrackedEvent('addChild');

    emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
  }

  public resize(): void {
    const parent = this.container.parentNode;

    if (!parent) return;

    const width = (parent as Element).clientWidth;
    const height = (parent as Element).clientHeight;

    if (this.camera instanceof OrthographicCamera) {
      this.camera.left = width / -2;
      this.camera.right = width / 2;
      this.camera.top = height / 2;
      this.camera.bottom = height / -2;
    }

    this.camera.updateProjectionMatrix();

    if (this.renderer) {
      this.renderer.setSize(width, height);
      this.renderer.render(this.scene, this.camera);
    }
  }

  public select(selectIDs: string[]): void {
    // if (selectIDs.length === 1 && this.selectedID === selectIDs[0]) return;

    // TODO: handle multi select
    this.selectedIDs = selectIDs;

    // update the selected node id of the toolkit state when user select the object
    if (selectIDs.length) {
      addToSelectedNodes(selectIDs, true);
    }

    if (selectIDs.length === 0 && useCreatorStore.getState().ui.selectedNodesInfo.length !== 0) {
      useCreatorStore.getState().ui.removeSelectedNodes();
    }
  }

  public selectAll(): void {
    const precompId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const scene = precompId
      ? (toolkit.getNodeById(precompId) as ToolkitScene)
      : (toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene);
    const layerIDs = scene.layers.map((layer) => layer.nodeId) as string[];

    addToSelectedNodes(layerIDs, true);
  }

  public selectByID(ids: string[]): void {
    const canvasMap = useCreatorStore.getState().ui.canvasMap;

    const animationLoader = useCreatorStore.getState().ui.animationLoader.status;

    if (animationLoader === AnimationLoaderStatus.Loading && ids.length > 0) {
      this._updateTransformControlLoading(ids[0] as string);
    }

    const selectedObjects = ids.map((id) => canvasMap.get(id)).filter(Boolean);

    if (selectedObjects.length === 0) {
      this.transformControls.detach();
      this.transformControls.lastSelectedObjectIDs = [];
      if (!this.pathControls.hovering) this.pathControls.disablePenControls();

      return;
    }
    const multiSelect = selectedObjects.length > 1;

    if (multiSelect) {
      this.transformControls.attachMultiple(ids, false, true);
      this.selectedIDs = ids;
      setPivotVisibility(true);
    } else {
      ids.forEach((id) => {
        this.updatePathShape(id);
      });
      const selected = selectedObjects[0] as CObject3D | CMesh;

      const nonTransformable = selected instanceof CMesh;

      this.selectedIDs = nonTransformable ? [] : [selected.toolkitId];
      // Pass fromCanvas = false as indicator that this event is triggered from UI
      // this._objectSelected(selected, nonTransformable ? id : null);
      this.transformControls.detach();
      if (nonTransformable) {
        this.transformControls.showBoundingBox(selected, true);
        this.transformControls.attachNonTransformable(selected);

        const node = getNodeByIdOnly(selected.toolkitId);

        if (node) {
          setSizeRatioLocked(Boolean(node.data.get(UserDataMap.SizeRatioLock)));
        }

        return;
      }
      this.transformControls.attach(selected);
      const node = getNodeByIdOnly(selected.toolkitId);

      // center the anchor point on select.
      this.transformControls.onDragPivotPoint(true);

      if (node) {
        // pivot point is visible by default
        setPivotVisibility(Boolean(node.data.get(UserDataMap.PivotVisible) ?? true));
        setScaleRatioLocked(Boolean(node.data.get(UserDataMap.ScaleRatioLock)));
        this.pathControls.updatePathShapeControls(node);
      }

      // Scroll into layer row if it is selected not from UI/Layer, but from canvas
      if (selected.toolkitId) {
        // Set timeline to visible for scrollIntoView to work
        setTimelineVisible(true);

        // HACK: Timeout to allow the layers to rerender with parent expanded
        setTimeout(() => {
          document.getElementById(selected.toolkitId)?.scrollIntoView({ block: 'nearest' });
        }, 100);
      }
    }
  }

  public toggleSelection(): void {
    if (!this.hoveredID) {
      return;
    }

    const isSelected = useCreatorStore.getState().ui.selectedNodesInfo.find((node) => node.nodeId === this.hoveredID);

    // if the user has dragged a selection, do not unselect the layer
    if (isSelected && !this.transformControls.hasDragged) {
      useCreatorStore.getState().ui.removeSelectedNodes([this.hoveredID]);
    } else {
      addToSelectedNodes([this.hoveredID], false);
    }
  }

  public updateContainerCursor(cursor?: string, type?: string): void {
    if (this.selectedToolbar === ToolType.Hand) {
      this.container.style.cursor = cursor ?? 'grab';
    } else if (type && (this.selectedToolbar === ToolType.Pen || type === ToolType.Pen)) {
      if (cursor) {
        this.container.style.cursor = cursor;
      }
    } else {
      this.container.style.cursor = 'auto';
    }
  }

  public updatePathShape(id: string): void {
    const node = getNodeByIdOnly(id);

    if (!node) return;
    if ((node.state as GroupShapeJSON).type === 'sh') this.pathControls.updatePathShapeControls(node);
  }

  public zoomCamera(zoomIn: boolean): void {
    const zoomPercentage = (100 * this.camera.zoom) / ViewportConfig.CameraZoom;

    if (zoomIn) {
      if (zoomPercentage < ViewportConfig.MaxZoom * 100) setZoomPercentage(zoomPercentage + 10);
    } else if (zoomPercentage > ViewportConfig.MinZoom * 100) setZoomPercentage(zoomPercentage - 10);
  }

  private _addEventListeners(): void {
    let startTime = 0;

    this.container.addEventListener('pointerdown', (event: PointerEvent) => {
      // consider only left button click
      if (event.button !== 0 || this.selectedToolbar === ToolType.Hand || this.selectedToolbar === ToolType.Pen) return;
      if (event.timeStamp - startTime < DOUBLE_CLICK_DELAY) {
        this._doDoubleClickAction(event);

        return;
      }
      startTime = event.timeStamp;
      // ignore clicks while the transformControl is used
      if (this.transformControls.pivotHovered) return;
      if (
        this.transformControls.objects.length > 0 &&
        this.transformControls.axis &&
        this.transformControls.axis !== 'XY'
      )
        return;
      this._doClickAction(event);
    });

    this.container.addEventListener('pointermove', (event: MouseEvent) => this._doHoverAction(event));

    this.container.addEventListener('pointerup', () => {
      if (this.selectedToolbar === ToolType.Hand) this.updateContainerCursor('grab');

      if (this.transformControls.shiftDown) this.toggleSelection();

      this.transformControls.hasDragged = false;
    });

    this.container.addEventListener('pointerenter', () => {
      this.updateContainerCursor();
    });

    this.transformControls.addEventListener('dragging-changed', (evt: THREE.Event) => {
      // Only call toolkit when dragging end
      if (this.transformControls.objects.length === 0 || evt['value']) return;
      this.transformControls.onDrag();
    });

    document.body.addEventListener('keydown', (event: KeyboardEvent) => this._setZoom(event.ctrlKey || event.metaKey));
    document.body.addEventListener('keyup', () => this._setZoom(false));

    // Subscribe to Creator store
    useCreatorStore.subscribe(
      (state) => state.ui.currentTool,
      (currentTool) => this._setToolbar(currentTool),
    );
    useCreatorStore.subscribe(
      (state) => state.ui.zoomPercentage,
      (zoomPercentage) => {
        this.cameraControls?.zoomTo((ViewportConfig.CameraZoom * zoomPercentage) / 100);
        this._syncZoomChange();
      },
    );
    useCreatorStore.subscribe(
      (state) => state.ui.selectedNodesInfo,
      // TODO: handle multi select on canvas
      (selectedNodesInfo) => this.selectByID(selectedNodesInfo.map((node) => node.nodeId)),
    );
    useCreatorStore.subscribe(
      (state) => state.timeline.hoverId,
      (timelineHoverID) => this._doTimelineHoverAction(timelineHoverID),
    );
    useCreatorStore.subscribe(
      (state) => state.ui.pivotVisible,
      (visible) => {
        if (this.transformControls.objects.length > 0 && this.transformControls.pivotPoint) {
          const multiSelect = this.transformControls.objects.length > 1;

          if (multiSelect) {
            this.transformControls.pivotPoint.visible = visible;
          } else {
            const selectedObject = this.transformControls.objects[0] as CObject3D;
            const node = getNodeByIdOnly(selectedObject.toolkitId);

            if (node) {
              node.setData(UserDataMap.PivotVisible, visible);
            }
            this.transformControls.pivotPoint.visible = visible;
          }
        }
      },
    );
    useCreatorStore.subscribe(
      (state) => state.ui.animationLoader.status,
      (data) => {
        const objectId = useCreatorStore.getState().ui.animationLoader.objectId;

        if (data === AnimationLoaderStatus.Loading) {
          stateHistory.beginAction();
          startLoading();
        } else if (data === AnimationLoaderStatus.Loaded && objectId) {
          stopLoading(objectId);
        } else if (data === AnimationLoaderStatus.Reverted && objectId) {
          stopLoading(objectId);
          stateHistory.endAction();
        }
      },
    );

    useCreatorStore.subscribe(
      (state) => state.timeline.height,
      () => this._onResizeArtboard(),
    );

    useCreatorStore.subscribe(
      (state) => state.toolkit.json?.properties.sz as SizeJSON,
      async (size: SizeJSON, previousSize: SizeJSON) => {
        const selectedPrecompositionJson = useCreatorStore.getState().toolkit.selectedPrecompositionJson;

        if (!selectedPrecompositionJson && size.h !== previousSize.h && size.w !== previousSize.w) {
          this.adjustCanvasSize(size);
          this.adjustCamera(size);
        }
      },
    );

    useCreatorStore.subscribe(
      (state) => state.ui.canvasHoveredNodeId,
      (hoverId) => this._onHoverChange(hoverId),
    );

    this.toolkitListener.start();

    window.addEventListener('resize', () => {
      this._onResizeArtboard();
    });
  }

  private _doClickAction(event: MouseEvent): void {
    if (!this.hoveredID || this.pathControls.penEnabled) {
      this.select([]);
      this.transformControls.visible = false;
      this.transformControls.detach();
      if (!this.hoveredID) {
        this.dragSelector?.onPointerDown(event);
        if (!this.pathControls.hovering) this.pathControls.disablePenControls();
      }

      return;
    }

    const isSelected = useCreatorStore.getState().ui.selectedNodesInfo.find((node) => node.nodeId === this.hoveredID);

    if (isSelected && this.transformControls.objects.length > 0 && this.transformControls.axis) {
      this.transformControls.pointerHover(event as PointerEvent);
      this.transformControls.pointerDown(getPointer(event as PointerEvent, this.container));

      return;
    }

    if (!this.transformControls.shiftDown) {
      this.select([this.hoveredID]);
      const canvasMap = useCreatorStore.getState().ui.canvasMap;
      const object = canvasMap.get(this.hoveredID);

      if (!object) return;

      this.transformControls.objects = [object as CObject3D];
      this.transformControls.pointerHover(event as PointerEvent);
      this.transformControls.pointerDown(getPointer(event as PointerEvent, this.container));

      // show tooltip to notify users that double click will show shape's properties in the property panel
      // P.S. used local storage to show the tooltip only once in a lifetime
      if (!localStorage.getItem('shownTooltip')) {
        localStorage.setItem('shownTooltip', 'yes');
        setHasShownOnetimeTooltip();
      }
    }
  }

  // we select one level below child on a double click
  private _doDoubleClickAction(event: MouseEvent): void {
    let intersect = this._getIntersects(event, RaycasterLayers.CObject3D, this.objectContainer);

    if (!intersect || this.pathControls.penEnabled) {
      this.select([]);
      if (!intersect) this.dragSelector?.onPointerDown(event);

      return;
    }

    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const setSelectedPrecompositionId = useCreatorStore.getState().toolkit.setSelectedPrecompositionId;
    const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;
    const setTabState = useCreatorStore.getState().timeline.setTabState;

    const avLayer = getNodeByIdOnly(intersect.toolkitId) as AVLayer;

    const selectedPrecompositionAsset = selectedPrecompositionId
      ? (getNodeByIdOnly(selectedPrecompositionId) as AVLayer)
      : null;

    if (
      (avLayer.composition.state as PrecompositionAssetJSON).type === 'PRECOMPOSITION' &&
      (!selectedPrecompositionAsset ||
        selectedPrecompositionAsset.state.properties.ln !== avLayer.composition.state.properties.ln)
    ) {
      const precompositionAsset = getAssetByReferenceId(
        toolkit.scenes[sceneIndex] as ToolkitScene,
        avLayer.composition.state.properties.ln as string,
      );

      if (precompositionAsset) {
        setTabState(avLayer.composition.state.properties.ln as string, true);
        setSelectedPrecompositionId((precompositionAsset.state as PrecompositionLayerJSON).id);
        setCurrentFrame(0);
        useCreatorStore.getState().ui.resetLayerUI();
        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
        this.select([]);
      }
    } else {
      const layerMap = useCreatorStore.getState().ui.layerMap;

      // Get the layer that's currently intersecting
      const currentLayer = layerMap.get(intersect.toolkitId);

      if (!currentLayer) return;

      // Check for current selection
      // TODO
      const prevID = this.transformControls.lastSelectedObjectIDs[0];

      // if there is a previous node and they are exactly the same
      if (prevID && prevID === intersect.toolkitId) {
        // Get the first non-appearance layer in the children
        const nextLayerExist =
          currentLayer.children.map((child) => !layerMap.get(child)?.isAppearance).filter(Boolean).length > 0;

        if (nextLayerExist) intersect = intersect.children[0] as CObject3D;
      }

      setHovered(intersect.toolkitId);
      this.select([intersect.toolkitId]);
      localStorage.setItem('shownTooltip', 'yes');
    }
  }

  private _doHoverAction(event: MouseEvent): void {
    const intersect = this._getIntersects(event, RaycasterLayers.CLayer);

    if (!intersect) {
      this.transformControls.hideBoundingBox();
      setHovered('');

      return;
    }

    setHovered(intersect.toolkitId);
  }

  private _doTimelineHoverAction(id: string | null): void {
    const canvasMap = useCreatorStore.getState().ui.canvasMap;
    const hoveredObject = canvasMap.get(id ?? '');

    if (!id || !hoveredObject) {
      this.transformControls.hideBoundingBox();

      return;
    }

    this.transformControls.showBoundingBox(hoveredObject);
  }

  private _getIntersects(
    event: MouseEvent,
    raycasterlayer: RaycasterLayers,
    container = this.objectContainer,
  ): CObject3D | null {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    const mouse = getMouseCoord(event, this.container);

    // unproject mouse vector to 3D
    const vec3 = unProject(mouse, this.camera);

    vec3.z = -500;
    const direction = new Vector3(0, 0, 1);

    this.raycaster.set(vec3, direction);
    const intersects = this.raycaster.intersectObject(container, true);

    let nearestIntersect = intersects[0];

    if (!nearestIntersect) return null;

    const { intersect, isGroupShapeSelected } = this._isGroupShapeSelected(intersects);

    if (isGroupShapeSelected) nearestIntersect = intersect as Intersection;

    const layerObject = isGroupShapeSelected
      ? getParentLayer(nearestIntersect.object as CMesh, RaycasterLayers.CObject3D)
      : getParentLayer(nearestIntersect.object as CMesh, raycasterlayer);

    return (layerObject ?? null) as CObject3D | null;
  }

  private _initCameraControls(): void {
    this.cameraControls = new CameraControls(this.camera, this.container);
    this._setRotation(false);
    this._setZoom(false);
    this.cameraControls.mouseButtons.right = CameraControls.ACTION.NONE;
    this.cameraControls.mouseButtons.middle = CameraControls.ACTION.NONE;
    this.cameraControls.dollyToCursor = true;
    this.cameraControls.dollySpeed = 0.5;
    this.cameraControls.draggingSmoothTime = 0;
    this.cameraControls.smoothTime = 0;
    this.cameraControls.minZoom = ViewportConfig.MinZoom;
    this.cameraControls.maxZoom = ViewportConfig.MaxZoom;

    this.cameraControls.addEventListener('update', () => {
      setZoomPercentage((100 * this.camera.zoom) / ViewportConfig.CameraZoom);
      this._updateSceneLabel();
    });

    this.resize();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  }

  private async _initGridBackground(): Promise<void> {
    const textureLoader = new TextureLoader();
    const texture = await textureLoader.loadAsync(grid);

    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(100, 100);
    this.gridBackground = new CMesh(
      new PlaneGeometry(4000, 4000),
      new MeshBasicMaterial({ map: texture, transparent: true, side: DoubleSide }),
    );

    this.gridBackground.position.z = 100;
    this.scene.add(this.gridBackground);

    this.adjustCamera();
  }

  private _initLight(): void {
    const pLight1 = new PointLight(0xffffff, 0.1);
    const pLight2 = pLight1.clone();
    const pLight3 = pLight1.clone();
    const pLight4 = pLight1.clone();

    const { BackgroundHeight, BackgroundWidth } = ViewportConfig;

    pLight1.position.set(BackgroundWidth, 0, -10000);
    pLight2.position.set(BackgroundWidth, BackgroundHeight, -10000);
    pLight3.position.set(0, BackgroundHeight, -10000);
    pLight4.position.set(0, 0, -10000);
    this.scene.add(pLight1);
    this.scene.add(pLight2);
    this.scene.add(pLight3);
    this.scene.add(pLight4);
    this.scene.add(new AmbientLight(0xffffff, 0.6));
  }

  private _initRenderer(): void {
    // Enable preserveDrawingBuffer so that Usersnap can capture what drawn on canvas
    this.renderer = new WebGLRenderer({ antialias: true, canvas: this.container, preserveDrawingBuffer: true });
    this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    this.renderer.setClearColor(0xffffff);
    this.renderer.setPixelRatio(1.5);
    /**
     * Removed the tone mapping, output encoding for now due to color differenes to original lottie
     */
    // this.renderer.toneMapping = ACESFilmicToneMapping;
    // this.renderer.outputEncoding = sRGBEncoding;
  }

  private _isGroupShapeSelected(
    intersects: Intersection[],
  ): { intersect: Intersection | null; isGroupShapeSelected: boolean } {
    let isGroupShapeSelected = false;
    let intersect = null;

    for (const currentIntersect of intersects) {
      if (
        // TODO
        this.transformControls.lastSelectedObjectIDs[0] ===
        getParentLayer(currentIntersect.object as CMesh, RaycasterLayers.CObject3D)?.toolkitId
      ) {
        isGroupShapeSelected = true;
        intersect = currentIntersect as Intersection;
      }
    }

    return { isGroupShapeSelected, intersect };
  }

  private _onHoverChange(hoverId: string): void {
    this.hoveredID = hoverId;
    this.transformControls.onHoverChange(hoverId);
  }

  private _onResizeArtboard(): void {
    this.resize();
    this._updateSceneLabel();
  }

  private _setPanning(enable: boolean): void {
    if (!this.cameraControls || !this.dragSelector) return;
    if (this.selectedToolbar !== ToolType.Pen) {
      this.cameraControls.mouseButtons.left = enable ? CameraControls.ACTION.TRUCK : CameraControls.ACTION.NONE;
      this.cameraControls.mouseButtons.right = enable ? CameraControls.ACTION.TRUCK : CameraControls.ACTION.NONE;
    }

    this.dragSelector.disable = enable;
  }

  private _setRotation(enable: boolean): void {
    if (!this.cameraControls) return;
    this.cameraControls.mouseButtons.left = enable ? CameraControls.ACTION.ROTATE : CameraControls.ACTION.NONE;
  }

  private _setToolbar(type: ToolType): void {
    this.selectedToolbar = type;

    let timer: NodeJS.Timeout | undefined;

    this.canvasResizing = true;
    if (!timer)
      timer = setTimeout(() => {
        this.canvasResizing = false;
        clearTimeout(timer);
      }, 500);

    this.transformControls.enabled = !(type === ToolType.Hand || type === ToolType.Pen);
    if (type === ToolType.Hand) {
      this.updateContainerCursor('grab');
    } else this.updateContainerCursor('default');
    if (type === ToolType.Hand || type === ToolType.Pen) {
      this.transformControls.detach();
      useCreatorStore.getState().ui.removeSelectedNodes();
      this.selectedIDs = [];
      this._setPanning(true);
    } else {
      this._setPanning(false);
    }
    if (type === ToolType.Pen) {
      this.pathControls.disablePenControls();
      this.pathControls.enablePenControls();
    } else {
      this.pathControls.disablePenControls();
    }
  }

  private _setZoom(enable: boolean): void {
    if (!this.cameraControls) return;
    this.cameraControls.mouseButtons.wheel = enable ? CameraControls.ACTION.ZOOM : CameraControls.ACTION.NONE;
  }

  private _step(): void {
    requestAnimationFrame(() => this._step());
    if (!this.renderer || !this.cameraControls) return;
    const delta = clock.getDelta();

    this.cameraControls.update(delta);
    this.renderer.render(this.scene, this.camera);
    if (this.canvasResizing) this.resize();
  }

  // sync the anchor point as the object moves or the camera zoom changes
  private _syncZoomChange(): void {
    this.transformControls.pivotPoint?.scale.set(1 / this.camera.zoom, 1 / this.camera.zoom, 1);

    if (this.gridBackground) {
      this.gridBackground.scale.set(1 / this.camera.zoom, 1 / this.camera.zoom, 1);
      this.gridBackground.position.x = this.camera.position.x;
      this.gridBackground.position.y = this.camera.position.y;
    }
  }

  private _updateSceneLabel(): void {
    const sceneLabel = document.getElementById('scene-label');

    if (sceneLabel) {
      this.camera.updateMatrix();
      sceneLabel.style.top = `${this.camera.top - this.camera.position.y * this.camera.zoom}px`;
      sceneLabel.style.left = `${this.camera.right - this.camera.position.x * this.camera.zoom}px`;
      const sizeLabel = document.getElementById('scene-size-label');

      if (sizeLabel && this.canvasSize) {
        sizeLabel.innerText = `${Math.round(this.canvasSize.w)} x ${Math.round(this.canvasSize.h)} `;
      }
    }
  }

  private _updateTransformControlLoading(id: string): void {
    const animationLoader = useCreatorStore.getState().ui.animationLoader;

    if (animationLoader.objectId === id) {
      const node = toolkit.getNodeById(id);

      if (!node) return;

      const rectNodeIndex = node.state?.shapes[0].shapes.findIndex((nodeShape: ShapeJSON) => nodeShape.type === 'rc');

      if (rectNodeIndex === -1) return;
      const loadingBoundingBox = node.state?.shapes[0].shapes[rectNodeIndex].animatedProperties.sz.value;
      const [height, width] = [loadingBoundingBox.h, loadingBoundingBox.w];

      const material = getLoadingGif(width, height);

      this.transformControls.attach(material);
    }
  }
}
