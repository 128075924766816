/**
 * Copyright 2021 Design Barn Inc.
 */

import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import React, { useState } from 'react';

import { FixedTooltip } from '../Elements/Tooltip/FixedTooltip';

import { CompositionPanel, PresetPanel, ShapePanel } from '~/features/property-panel';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

const SettingTabs = [
  {
    label: 'Property',
  },
  { label: 'Presets' },
];

const PropertyView: React.FC = () => {
  const hasShownOnetimeTooltip = useCreatorStore.getState().ui.hasShownOnetimeTooltip;

  const [onetimeTooltipOpen, setOnetimeTooltipOpen] = useState(true);

  const property = useCreatorStore((state) => state.ui.currentPropertyPanel);

  return (
    <Tab.Group>
      <Tab.List className="select-none border-b border-gray-900 bg-gray-800 px-1">
        {SettingTabs.map((tab) => (
          <Tab key={tab.label} className="bg-gray-800 px-3 py-2 text-caption focus:outline-none">
            {({ selected }) => (
              <span
                className={clsx({
                  'font-bold text-white': selected,
                  'font-normal text-gray-400 hover:text-white': !selected,
                })}
              >
                {tab.label}
              </span>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="h-[calc(100%-51px)] select-none overflow-y-auto">
        <FixedTooltip
          offset={{ mainAxis: 15, crossAxis: 30 }}
          label="Double click an object to view its properties"
          subLabel="You can also select the object in the timeline."
          placement="left"
          open={hasShownOnetimeTooltip && onetimeTooltipOpen}
          setOpen={() => setOnetimeTooltipOpen(false)}
        >
          <div></div>
        </FixedTooltip>
        <Tab.Panel>
          <div>{property === PropertyPanelType.Composition ? <CompositionPanel /> : <ShapePanel />}</div>
        </Tab.Panel>
        <Tab.Panel className={'h-full'}>
          <PresetPanel />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export const Property: React.FC = () => {
  return (
    <div className="relative h-full bg-gray-800 text-gray-50">
      <div className="flex h-full w-[240px] flex-col">
        <PropertyView />
      </div>
    </div>
  );
};
