/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  DagNode,
  VectorJSON,
  AngleJSON,
  PercentageJSON,
  StarShape,
  EllipseShape,
  RectangleShape,
  PrecompositionLayer,
} from '@lottiefiles/toolkit-js';
import { AVLayer, GroupShape } from '@lottiefiles/toolkit-js';

import { createGetCurrentKeyframe, checkHasActiveKeyFrame } from './helper';
import type { Scalar2D } from './types';

export interface CurrentTransformProperty {
  anchorPoint: Scalar2D;
  anchorPointCurrentKeyframe: string;
  opacity: number;
  opacityCurrentKeyframe: string;
  position: Scalar2D;
  positionCurrentKeyframe: string;
  rotation: number;
  rotationCurrentKeyframe: string;
  scale: Scalar2D;
  scaleCurrentKeyframe: string;
  skew: number;
  skewAxis: number;
  skewAxisCurrentKeyframe: string;
  skewCurrentKeyframe: string;
}

export const defaultCurrentTransformProperty: CurrentTransformProperty = {
  anchorPoint: [0, 0],
  anchorPointCurrentKeyframe: '',
  position: [0, 0],
  positionCurrentKeyframe: '',
  scale: [1, 1],
  scaleCurrentKeyframe: '',
  rotation: 0,
  rotationCurrentKeyframe: '',
  skew: 0,
  skewCurrentKeyframe: '',
  skewAxis: 0,
  skewAxisCurrentKeyframe: '',
  opacity: 100,
  opacityCurrentKeyframe: '',
};

export const getCurrentTransformNode = (node: DagNode | null): DagNode | null => {
  if (node instanceof GroupShape || node instanceof AVLayer) return node;

  return node && node.parent ? getCurrentTransformNode(node.parent) : null;
};

export const getCurrentTransform = (
  node: DagNode | null,
  ignoreCurrentKeyFrame: boolean = false,
): CurrentTransformProperty => {
  const currentTransfom: CurrentTransformProperty = { ...defaultCurrentTransformProperty };

  const transformNode = getCurrentTransformNode(node);

  if (transformNode) {
    const transformState = (transformNode as PrecompositionLayer | AVLayer | RectangleShape | EllipseShape | StarShape)
      .state.animatedProperties;
    const currentFrame = transformNode.scene.timeline.currentFrame;

    const getKeyFrame = createGetCurrentKeyframe(currentFrame);

    const hasActiveKeyFrame = checkHasActiveKeyFrame();

    const posValue = transformState.p.value as VectorJSON | null;

    currentTransfom.position = posValue ? [posValue.x, posValue.y] : [250, 250];
    currentTransfom.positionCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.p)
      : getKeyFrame(transformState.p);

    // temp fix
    const scaleValue = transformState.s.value as VectorJSON | null;

    currentTransfom.scale = scaleValue ? [scaleValue.x, scaleValue.y] : [100, 100];

    currentTransfom.scaleCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.s)
      : getKeyFrame(transformState.s);

    // temp fix
    const rotValue = transformState.r.value as AngleJSON | null;

    currentTransfom.rotation = rotValue ? rotValue.deg : 0;
    currentTransfom.rotationCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.r)
      : getKeyFrame(transformState.r);

    currentTransfom.anchorPoint = [(transformState.a.value as VectorJSON).x, (transformState.a.value as VectorJSON).y];
    currentTransfom.anchorPointCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.a)
      : getKeyFrame(transformState.a);

    currentTransfom.skew = (transformState.sk.value as AngleJSON).deg;
    currentTransfom.skewCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.sk)
      : getKeyFrame(transformState.sk);

    currentTransfom.skewAxis = (transformState.sa.value as AngleJSON).deg;
    currentTransfom.skewAxisCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.sa)
      : getKeyFrame(transformState.sa);

    // temp fix
    const opacityVal = transformState.o.value as PercentageJSON | null;

    currentTransfom.opacity = opacityVal ? opacityVal.pct : 100;
    currentTransfom.opacityCurrentKeyframe = ignoreCurrentKeyFrame
      ? hasActiveKeyFrame(transformState.o)
      : getKeyFrame(transformState.o);
  }

  return currentTransfom;
};

export const getActiveTransform = (node: DagNode | null): CurrentTransformProperty => {
  return getCurrentTransform(node, true);
};
