/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Close, Reload } from '~/assets/icons';

export interface PluginUIHeaderProps {
  id: string;
  isDevPlugin: boolean;
  onClose: () => void;
  onReload?: () => void;
  title: string;
}

export const PluginUIHeader: React.FC<PluginUIHeaderProps> = (props) => {
  return (
    <div id="test-animation-header" className="box-border cursor-move select-none rounded-t-md">
      <div className="my-0.5 box-border flex items-center justify-between rounded-t-md border-gray-700 p-4">
        <span className="h-6 text-test-animation-header font-bold">{props.title}</span>
        <div className="flex flex-row space-x-3">
          {props.isDevPlugin && (
            <button className="">
              <Reload
                className="h-6 w-6 cursor-pointer stroke-current text-gray-500 hover:opacity-50"
                onClick={() => {
                  if (props.onReload) {
                    props.onReload();
                  }
                }}
              />
            </button>
          )}
          <button>
            <Close
              className="h-6 w-6 cursor-pointer fill-current text-gray-500 hover:opacity-50"
              onClick={() => {
                props.onClose();
              }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
