/**
 * Copyright 2022 Design Barn Inc.
 */

import { isNil, isEmpty, clamp } from 'lodash-es';
import { useCallback, useMemo, useEffect, useReducer, useRef, useState } from 'react';

import type { TimerSetting } from './NumberInput';
import type { NumberResult } from './types';

import { formatTime } from '~/features/timeline';
import { useEventListener } from '~/hooks';
import { EmitterEvent } from '~/lib/emitter';
import { redoHistory, undoHistory } from '~/lib/history';
import { stateHistory } from '~/lib/toolkit';
import { isNumber, formatNumber } from '~/utils';

const TIME_REGEX = /^(\d{1,3}):(\d{1,3}):(\d{1,3})$/u;
const SECONDS_REGEX = /^[+-]?\d*\.?\d+$/u;

interface TimeInputOption {
  max: number | null;
  min: number | null;
  precision: number;
  step: number;
  timerSetting: TimerSetting | null;
}

const timeFormatToSeconds = (input: string, fps: number): number => {
  const match = TIME_REGEX.exec(input);
  const minutes = (match as RegExpExecArray)[1] as string;
  const seconds = (match as RegExpExecArray)[2] as string;
  const frames = (match as RegExpExecArray)[3] as string;
  const secondsFromFrames = parseFloat(frames) / fps;

  const totalSeconds = parseFloat(seconds) + parseFloat(minutes) * 60 + secondsFromFrames;

  return totalSeconds;
};

// prepare the input value by removing spaces
const preProcessInput = (input: string): string => {
  const newInput = input.replace(/\s+/gu, '');

  return newInput;
};

const handleTimeFormat = (state: TimeInputState, newInput: string, fps: number): TimeInputState => {
  const newState: TimeInputState = { input: state.input, valid: { ...state.valid } };

  if (TIME_REGEX.test(newInput)) {
    // if in M:S:F / MM:SS:FF / MMM:SSS:FFF format
    const totalSeconds = timeFormatToSeconds(newInput, fps) as number;

    ((newState as unknown) as TimeInputState).valid.duration = totalSeconds;
    ((newState as unknown) as TimeInputState).input = formatTime(totalSeconds, fps);
  } else if (SECONDS_REGEX.test(newInput)) {
    // if in seconds format
    const totalSeconds = parseFloat(newInput);

    ((newState as unknown) as TimeInputState).valid.duration = totalSeconds;
    ((newState as unknown) as TimeInputState).input = formatTime(totalSeconds, fps);
  } else {
    // if not valid, restore the previous valid value
    ((newState as unknown) as TimeInputState).valid.duration = state.valid.duration;
  }

  return (newState as unknown) as TimeInputState;
};

// process the input value
const process = (state: TimeInputState, option: TimeInputOption, externalValue?: number): TimeInputState => {
  const { input, valid } = state;

  const { max, min, precision, timerSetting } = option;

  let newValue = externalValue ?? handleTimeFormat(state, input, (timerSetting as TimerSetting).fps).valid.duration;

  if (newValue) {
    // Handle minimum maximum
    if (!isNil(min) && !isNil(max)) {
      newValue = clamp(newValue, min, max);
    } else if (!isNil(min)) {
      newValue = Math.max(newValue, min);
    } else if (!isNil(max)) {
      newValue = Math.min(newValue, max);
    }
  }
  const newInput = isNil(newValue) ? preProcessInput(input) : preProcessInput(newValue.toString());

  const newState = handleTimeFormat(state, newInput, (timerSetting as TimerSetting).fps);

  // if is not a number or is empty, get the last valid value
  if (!isNumber(newState.valid.duration) || isEmpty(newState.input) || newState.valid.duration === 0) {
    newState.valid.duration = valid.duration;
  }

  // Round the result to the defined precision
  const roundedNumber = newState.valid.duration.toFixed(precision);

  newState.input = formatTime(roundedNumber, (timerSetting as TimerSetting).fps);
  if (newState.valid.fps === 0) newState.valid.fps = (timerSetting as TimerSetting).fps;

  return newState;
};

const handleFpsUpdate = (state: TimeInputState, option: TimeInputOption): TimeInputState => {
  const { fps } = option.timerSetting as TimerSetting;
  const newState = { input: state.input, valid: { ...state.valid } };

  if (fps && fps !== 0) {
    const currentTotalSeconds = timeFormatToSeconds(state.input, state.valid.fps);
    const currentTotalFrames = currentTotalSeconds * state.valid.fps;
    const newTotalSeconds = currentTotalFrames / fps;

    newState.input = formatTime(newTotalSeconds, fps);
    newState.valid.fps = parseFloat(fps.toFixed(option.precision));
  }

  return newState as TimeInputState;
};

const handleIncrementDecrement = (
  type: string,
  state: TimeInputState,
  fps: number,
  step: number,
  minSeconds: number,
): TimeInputState => {
  const inputSeconds = TIME_REGEX.test(state.input) ? timeFormatToSeconds(state.input, fps) : minSeconds;
  const inputFrames = (inputSeconds * fps) as number;

  const isIncrement = 1;
  const isDecrement = -1;

  const finalFrames = inputFrames + step * (type === 'increment' ? isIncrement : isDecrement);
  const finalSeconds = finalFrames / fps;

  const newState: TimeInputState = { ...state };

  newState.input = formatTime(finalSeconds, fps);

  return newState as TimeInputState;
};

interface TimeInputState {
  input: string;
  valid: {
    duration: number;
    fps: number;
  };
}

interface PayloadType {
  option: TimeInputOption;
  value: number;
}

type ActionType =
  | { payload: PayloadType; type: 'increment' }
  | { payload: PayloadType; type: 'decrement' }
  | { payload: PayloadType; type: 'confirm' }
  | { payload: PayloadType; type: 'fps_updated' }
  | { payload: string; type: 'input_changed' }
  | { payload: PayloadType; type: 'value_props_changed' };

const reducer = (state: TimeInputState, action: ActionType): TimeInputState => {
  switch (action.type) {
    case 'increment': {
      const { option } = action.payload;
      const newState = handleIncrementDecrement(
        action.type,
        state,
        option.timerSetting?.fps as number,
        option.step,
        option.min as number,
      );

      return newState;
    }

    case 'decrement': {
      const { option } = action.payload;
      const newState = handleIncrementDecrement(
        action.type,
        state,
        option.timerSetting?.fps as number,
        option.step,
        option.min as number,
      );

      return newState;
    }

    case 'confirm': {
      const { option } = action.payload;
      const newState = process(state, option);

      return newState;
    }

    case 'input_changed': {
      return { ...state, input: action.payload };
    }

    case 'value_props_changed': {
      const { option, value } = action.payload;

      const newState = process(state, option, value);

      return newState;
    }

    case 'fps_updated': {
      const { option } = action.payload;

      const newState = handleFpsUpdate(state, option);

      return newState;
    }

    default:
      throw new Error();
  }
};

interface UseTimeInputProps {
  inputRef: React.RefObject<HTMLInputElement>;
  max: number | null;
  min: number | null;
  name?: string;
  onChange?: ((result: NumberResult) => void) | null;
  precision: number;
  step?: number;
  timerSetting: TimerSetting | null;
  value: number;
}

type UseTimeInputReturn = [
  string,
  {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputStop: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleOnBlur: () => void;
  },
];

const initialState: TimeInputState = {
  input: '',
  valid: {
    duration: 0,
    fps: 0,
  },
};

const init = (obj: PayloadType): TimeInputState => {
  const newState = process(initialState, obj.option, obj.value);

  return newState;
};

export const useTimeInput = ({
  inputRef,
  max = null,
  min = null,
  name = '',
  onChange,
  precision,
  step = 1,
  timerSetting,
  value: valueProp,
}: UseTimeInputProps): UseTimeInputReturn => {
  // Create a ref that stores handler to prevent rerender
  const onChangeHandler = useRef(onChange);
  const valueRef = useRef(valueProp);
  const nameRef = useRef(name);
  const precisionRef = useRef(precision);

  const payload = useMemo(() => {
    return {
      value: valueProp,
      option: {
        min,
        max,
        precision,
        step,
        timerSetting,
      },
    };
  }, [valueProp, min, max, precision, step, timerSetting]);

  const [state, dispatch] = useReducer(reducer, initialState, (_: TimeInputState) => init(payload));
  const [draggingEvent, setDraggingEvent] = useState(false);

  // Update ref.current value if handler changes.
  useEffect(() => {
    onChangeHandler.current = onChange;
  }, [onChange]);

  // When the value prop changes, update the state
  useEffect(() => {
    valueRef.current = valueProp;
    dispatch({ type: 'value_props_changed', payload });
  }, [valueProp, payload]);

  // Store in nameRef to prevent rerender
  useEffect(() => {
    nameRef.current = name;
    precisionRef.current = precision;
  }, [name, precision]);

  // Trigger onChange callback when input value changes
  useEffect(() => {
    if (onChangeHandler.current && valueRef.current !== state.valid.duration) {
      if (!draggingEvent) {
        stateHistory.beginAction();
      }
      onChangeHandler.current({
        name: nameRef.current,
        value: formatNumber(state.valid.duration, precisionRef.current),
        trueValue: state.valid.duration,
      });

      setDraggingEvent(true);
    }
  }, [draggingEvent, state.valid.duration]);

  // Handle input change
  const handleInputStop = useCallback(() => {
    setDraggingEvent(false);
    stateHistory.endAction();
  }, [setDraggingEvent]);

  // Handle fps updates
  useEffect(() => {
    dispatch({ type: 'fps_updated', payload });
  }, [payload, timerSetting?.fps]);

  // Handle input change
  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (evt.type === 'onDrag') {
        // override the default drag behaviour so that we can increment and decrement the frame count
        if (Math.sign(parseInt(evt.target.value, 10)) === 1) dispatch({ type: 'increment', payload });
        if (Math.sign(parseInt(evt.target.value, 10)) === -1) dispatch({ type: 'decrement', payload });
      } else {
        dispatch({ type: 'input_changed', payload: evt.target.value });
      }
    },
    [payload],
  );

  // processInput function
  const handleOnBlur = useCallback((): void => {
    dispatch({ type: 'confirm', payload });
  }, [payload]);

  // handle keyboard interaction event
  const handleKeyDown = useCallback(
    (evt: KeyboardEvent): void => {
      const { ctrlKey, key, metaKey, shiftKey } = evt;

      if (key === 'ArrowUp') {
        evt.preventDefault();
        dispatch({ type: 'increment', payload });
        inputRef.current?.select();
      } else if (key === 'ArrowDown') {
        evt.preventDefault();
        dispatch({ type: 'decrement', payload });
        inputRef.current?.select();
      } else if (key === 'Enter') {
        // Automatically trigger onBlur
        inputRef.current?.blur();
      } else if (key === 'z' && (metaKey || ctrlKey) && !shiftKey) {
        undoHistory(EmitterEvent.UI_UNDO);
      } else if (key === 'z' && (metaKey || ctrlKey) && shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      } else if (key === 'y' && ctrlKey && !shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      }
    },
    [payload, inputRef],
  );

  // Subscribe to keydown events
  useEventListener('keydown', handleKeyDown, inputRef);

  return [state.input, { handleInputChange, handleOnBlur, handleInputStop }];
};
