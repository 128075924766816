/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ShapeLayerJSON, ShapeJSON, AnimatedPropertyJSON, PropertyType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React from 'react';

import type { LayerUI } from '../../helper';

import { AnimationKeyFrames } from './TimelineKeyFrame';

// import { SingleKeyFrameMenu } from '~/features/menu';
// import { CreatorAnimatedTypes } from '~/lib/toolkit/shape-helper';
// import { useCreatorStore } from '~/store';
import type { Optional } from '~/types';

type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

interface AnimatedRowProps {
  animatedProp: AnimatedPropertyJSON;
  layerId: string;
  type: string;
}

const KeyframeAnimatedRow: React.FC<AnimatedRowProps> = ({ animatedProp, layerId }) => {
  return (
    <div className={clsx('relative flex h-full w-full items-center', {})}>
      <AnimationKeyFrames animatedProp={animatedProp} layerId={layerId} animatedPropId={animatedProp.id} />
    </div>
  );
};

interface AnimatedProps {
  allParentExpanded: boolean;
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
}

export const KeyframeAnimated: React.FC<AnimatedProps> = ({ layer, layerUI }) => {
  const { animated } = layerUI;

  // Conditional rendering
  if (animated.length === 0) {
    return null;
  }

  return (
    <>
      {animated.map(({ id, type }) => (
        // This container is will be used to contain the drag
        <div key={id} id={`animated-${id}`}>
          <div className="mr-2 mt-[1px] h-6 bg-gray-800">
            <KeyframeAnimatedRow
              animatedProp={layer.animatedProperties[type as PropertyType]}
              type={type}
              layerId={layer.id}
            />
          </div>
        </div>
      ))}
    </>
  );
};
