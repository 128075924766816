/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene, ShapeLayer, GroupShape } from '@lottiefiles/toolkit-js';
import { CubicBezierShape, Vector, PathShape } from '@lottiefiles/toolkit-js';

import type { PathPoint } from '~/features/canvas';

export interface PathOption {
  isClosed?: boolean;
  pathPoints: PathPoint[];
}

export const updatePath = (scene: Scene, id: string, option: PathOption): void => {
  const node = scene.getNodeById(id);

  if (node instanceof PathShape) {
    const { pathPoints } = option;
    const path = node as PathShape;

    if (pathPoints.length > 0) {
      const vertexPoints = pathPoints.map((pathPoint) => {
        return new Vector(pathPoint.vertex.x, pathPoint.vertex.y);
      }) as Vector[];
      const inPoints = pathPoints.map((pathPoint) => {
        return new Vector(pathPoint.in.x, pathPoint.in.y);
      }) as Vector[];
      const outPoints = pathPoints.map((pathPoint) => {
        return new Vector(pathPoint.out.x, pathPoint.out.y);
      }) as Vector[];
      const cubicBezierPath = new CubicBezierShape()
        .setVertices(vertexPoints)
        .setInTangents(inPoints)
        .setOutTangents(outPoints);

      if (typeof option.isClosed !== 'undefined') {
        cubicBezierPath.setIsClosed(option.isClosed);
      }

      path.shape.setValue(cubicBezierPath);
    }
  }
};

export const createPath = (scene: Scene, parent: ShapeLayer | GroupShape, path?: PathOption): PathShape => {
  const shape = parent.createPathShape();

  if (path) {
    updatePath(scene, shape.nodeId, path);
  }

  return shape;
};
