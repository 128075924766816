/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import NotAllowedAnimation from '~/assets/animations/not-allowed.json';
import { LottieFilesFull } from '~/assets/icons';

export const CreatorLock: React.FC = () => {
  const goTo = (evt: React.MouseEvent<HTMLDivElement>, url: string): void => {
    evt.preventDefault();
    window.location.href = url;
  };

  return (
    <div className="h-screen bg-gray-800 font-sans text-gray-700">
      <div className="container flex h-screen w-full max-w-full">
        <LottieFilesFull className="absolute left-2.5 top-2.5 h-[24px]	w-[123px]" />
        <div className="flex h-screen w-full items-center justify-center">
          <div className="text-center">
            <dotlottie-player
              autoplay
              loop
              src={JSON.stringify(NotAllowedAnimation)}
              style={{ height: '106px' }}
            ></dotlottie-player>

            <h1 className="mb-4 text-center text-xl font-bold text-white">
              It looks like you're not allowed in yet 🔐
            </h1>
            <div className="mb-5 text-center text-sm font-light text-white">
              Hang tight, hopefully your turn is coming up soon
            </div>
            <div
              onClick={(evt) => goTo(evt, 'https://lottiefiles.com/')}
              className={`mx-auto my-0 flex h-[40px] w-[151px] cursor-pointer items-center justify-center rounded-xl bg-[#00C1A2] px-3 text-base font-bold leading-none text-white hover:bg-[#019D91] focus:outline-none`}
            >
              Go to homepage
            </div>
          </div>

          <div className="absolute bottom-6 flex flex-wrap gap-6">
            <div
              onClick={(evt) => goTo(evt, 'https://help.lottiefiles.com/hc/en-us')}
              className="cursor-pointer text-center text-xs font-bold text-white"
            >
              FAQ & Support
            </div>
            <div
              onClick={(evt) => goTo(evt, 'https://help.lottiefiles.com/hc/en-us/requests/new')}
              className="cursor-pointer text-center text-xs font-light text-white"
            >
              Contact Us
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
