/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';
import { nanoid } from 'nanoid';

import { WORKFLOW_PREVIEW, WORKFLOW_DASHBOARD } from '~/config';
import { ExportType } from '~/data/constant';
import { toolkit, exportToolkit, exportToWorkspace } from '~/lib/toolkit';
import { exportToolkitJson } from '~/lib/toolkit/export';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant/modal';
import { SavingState, NetworkStatus, UrlType } from '~/store/projectSlice';

const POPUP_ALERT_ID = nanoid();

interface SaveToCreatorProp {
  saveWithoutPopup?: boolean;
}

export const createNewFile = (): void => {
  window.open(window.location.origin, '_blank');
};

export const saveToWorkSpace = async (): Promise<void> => {
  const setLoader = useCreatorStore.getState().ui.setLoader;
  const setAlert = useCreatorStore.getState().ui.setAlert;
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  setLoader({
    isLoading: true,
    title: 'Launching your workspace...',
  });

  const savedUrl = await exportToWorkspace(toolkit, toolkit.scenes[sceneIndex] as Scene);

  setLoader({
    isLoading: false,
  });

  if (savedUrl) {
    window.open(`${WORKFLOW_PREVIEW}${savedUrl}`);
  } else {
    setAlert({
      text: 'Something went wrong while saving this animation.',
      alertColor: '#D92600',
      timeout: 7000,
      handle: async (): Promise<void> => {
        await saveToWorkSpace();
      },
      handleText: 'Try Again',
    });
  }
};

export const exportJSON = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  exportToolkit(toolkit, toolkit.scenes[sceneIndex] as Scene, ExportType.JSON);
};

// saveToCreator function calling from CMD+S or dropdown menu -> Save as...
export const saveToCreator = async (option?: SaveToCreatorProp): Promise<boolean> => {
  // Prevent from calling save multiple times when saving
  const savingState = useCreatorStore.getState().project.info.savingState;

  if (savingState === SavingState.SAVING) return Promise.resolve(false);

  const savingProject = (callback: (selectedState: boolean, previousSelectedState: boolean) => void): (() => void) => {
    return useCreatorStore.subscribe((state) => state.project.info.savingState === SavingState.SAVED, callback, {
      fireImmediately: true,
    });
  };

  const waitForProjectSaved = async (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const unsub = savingProject((isSaved: boolean) => {
        if (isSaved) {
          unsub();
          resolve(true);
        }
      });
    });
  };

  const handleSave = async (): Promise<boolean> => {
    const setProjectInfo = useCreatorStore.getState().project.setInfo;

    setProjectInfo({ savingState: SavingState.SAVING });

    const done = await waitForProjectSaved();

    return Promise.resolve(done);
  };

  const setAlert = useCreatorStore.getState().ui.setAlert;

  const networkStatus = useCreatorStore.getState().project.network;

  const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

  const handleURLSave = async (): Promise<boolean> => {
    const madeFirstChange = useCreatorStore.getState().project.madeFirstChange;

    if (madeFirstChange) {
      const urlType = useCreatorStore.getState().project.urlType;

      const saved = await handleSave();

      if (saved && urlType === UrlType.Existing) {
        // Popup once only
        setAlert({
          text: 'Changes in your file will be saved automatically.',
          alertColor: '#20272C',
          timeout: 7000,
          handleText: null,
          advanced: {
            alertId: POPUP_ALERT_ID,
            runOnceEveryPageRefresh: true,
          },
        });
      }

      return Promise.resolve(saved);
    } else {
      // didn't made first change

      setGlobalModal(GlobalModalConstant.ProjectSave, { createNew: true });
    }

    return Promise.resolve(false);
  };

  const handleDefaultSave = async (): Promise<boolean> => {
    // Handle default save will be "Save As" scenario
    // This means it will always prompt for a new location, so there's no need to check for draft or selectedDirectoryId
    // if (isDraft || !selectedDirectoryId) {
    setGlobalModal(GlobalModalConstant.ProjectSave, { createNew: true });
    // } else return Promise.resolve(handleSave());

    return Promise.resolve(false);
  };

  const urlType = useCreatorStore.getState().project.urlType;
  const containURL = [UrlType.Create, UrlType.Existing].includes(urlType as UrlType);

  if (networkStatus === NetworkStatus.OFFLINE) {
    setAlert({
      text: 'Looks like you’re offline. We couldn’t save your work.',
      alertColor: '#D92600',
      timeout: 5000,
      handleText: null,
    });
  } else if (option?.saveWithoutPopup) {
    // Autosave scenario
    return Promise.resolve(handleSave());
  } else if (containURL) {
    return handleURLSave();
  } else {
    // Save as
    return handleDefaultSave();
  }

  return Promise.resolve(false);
};

export const goToDashboard = (): void => {
  window.open(`${WORKFLOW_DASHBOARD}`, '_blank');
};

export const getLottieJSON = async (): Promise<unknown> => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const lottieJSON = await exportToolkitJson(toolkit, toolkit.scenes[sceneIndex] as Scene);

  return Promise.resolve(lottieJSON);
};

export const exportDotLottie = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  exportToolkit(toolkit, toolkit.scenes[sceneIndex] as Scene, ExportType.DotLottie);
};
