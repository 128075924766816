/**
 * Copyright 2022 Design Barn Inc.
 */

import { throttle } from 'lodash-es';
import { Vector3, Quaternion, MathUtils } from 'three';

import type { TransformInfo } from '../../../store/uiSlice';
import { rotateAboutPoint, scaleAboutPoint } from '../3d/shapes/transform';
import type { CObject3D } from '../types';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { rotationAxis, TransformType } from '~/lib/threejs/TransformControls';
import { AnimatedType, getCurrentTransform, toolkit, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;
const setStaticPivot = useCreatorStore.getState().toolkit.setStaticPivot;
const setSelectedNodeTransformation = useCreatorStore.getState().ui.setSelectedNodeTransformation;
const setSelectedGroupTransformation = useCreatorStore.getState().ui.setSelectedGroupTransformation;

export const getRealPosition = (object: CObject3D, position: Vector3 = object.position): Vector3 => {
  const quat = new Quaternion().setFromAxisAngle(rotationAxis, -object.rotation.z);
  const offset = new Vector3().copy(object.toolkitAnchorPosition);

  offset.multiply(object.scale).applyQuaternion(quat);

  return offset.add(position);
};

export const updateGroup = throttle((transform: TransformInfo): void => {
  if (transform.position) {
    setSelectedGroupTransformation({ position: transform.position });
  }
  // eslint-disable-next-line no-undefined
  if (transform.rotation !== undefined) {
    setSelectedGroupTransformation({ rotation: transform.rotation });
  }
  if (transform.scale) setSelectedGroupTransformation({ scale: transform.scale });
  if (transform.opacity) setSelectedGroupTransformation({ opacity: transform.opacity });
}, 100);

export const updateSelectedNode = throttle(
  (object: CObject3D, transformType: TransformType, isNegativeRotation?: boolean): void => {
    if (transformType === TransformType.Pivot || transformType === TransformType.Translation) {
      const realPos = getRealPosition(object);

      setSelectedNodeTransformation({ position: new Vector3(realPos.x, realPos.y, 0) });
    } else if (transformType === TransformType.Rotation) {
      let deg = MathUtils.radToDeg(object.rotation.z);

      deg = deg < 0 ? 180 + (180 - Math.abs(deg)) : deg;
      if (isNegativeRotation) {
        deg = -(360 - deg);
      }
      setSelectedNodeTransformation({ rotation: deg });
    } else {
      setSelectedNodeTransformation({ scale: new Vector3(object.scale.x, object.scale.y, 1) });
    }
  },
  100,
);

export const updateToolkit = (objects: CObject3D[], transformType: TransformType, pivot?: Vector3 | null): void => {
  // updateToolkit is called at the end of the canvas operation.
  // Having updateSelectedNode throttled means there is a possibility
  // that a throttled event gets fired after updateToolkit has been called.
  // this is to prevent such scenarios
  updateSelectedNode.cancel();

  stateHistory.beginAction();

  objects.forEach((object) => {
    object.updateMatrix();
    if (transformType === TransformType.Pivot || transformType === TransformType.Translation) {
      const realPos = getRealPosition(object);

      setAnimatedValue(AnimatedType.POSITION, [realPos.x, realPos.y], object.toolkitId);
    } else if (transformType === TransformType.Rotation) {
      const deg = MathUtils.radToDeg(object.rotation.z);
      // deg goes from 0 to 180.
      // numbers greater than 0 are represented with negative values.
      const adjustedDeg = deg < 0 ? 180 + (180 - Math.abs(deg)) : deg;
      // the normalized value will be from 0-360
      const toolkitNode = toolkit.getNodeById(object.toolkitId);
      const transform = getCurrentTransform(toolkitNode, false);
      const finalDeg =
        Math.trunc(transform.rotation / 360) * 360 + (transform.rotation < 0 ? -(360 - adjustedDeg) : adjustedDeg);

      setAnimatedValue(AnimatedType.ROTATION, [finalDeg], object.toolkitId);
    } else {
      setAnimatedValue(AnimatedType.SCALE, [object.scale.x * 100, object.scale.y * 100], object.toolkitId);
    }
    if (pivot) {
      setStaticPivot(pivot.x, pivot.y, object.toolkitId);
    }
  });
  emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });

  stateHistory.endAction();
  // update the pathshape bones once transformation is applied

  // setSelectedNodeTransformation({});
};

export const updatePivot = (object: CObject3D, pivot: Vector3): void => {
  setStaticPivot(pivot.x, pivot.y, object.toolkitId);

  emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
};

export const updateGroupFromUI = (transformType: TransformType, value: Vector3 | number): void => {
  const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;
  const canvasMap = useCreatorStore.getState().ui.canvasMap;
  const groupPivot = useCreatorStore.getState().ui.selectedGroupTransform.position;
  const objects: CObject3D[] = [];

  selectedNodesInfo.forEach((nodeInfo) => {
    const object = canvasMap.get(nodeInfo.nodeId) as CObject3D | null;

    if (!object) return;
    objects.push(object);
    if (transformType === TransformType.Translation && value instanceof Vector3) {
      object.position.add(value);
    } else if (transformType === TransformType.Scale && value instanceof Vector3) {
      const scaleValue = new Vector3().copy(object.scale).multiply(value);

      if (groupPivot) {
        const offset = new Vector3().copy(groupPivot).sub(object.toolkitAnchorPosition);

        scaleAboutPoint(object, groupPivot, scaleValue, true, offset);
      }
    } else if (transformType === TransformType.Rotation && typeof value === 'number') {
      if (groupPivot) {
        rotateAboutPoint(object, groupPivot, rotationAxis, -value, true);
      }
    }
  });
  updateToolkit(objects, transformType);
};
