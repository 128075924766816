/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useRef } from 'react';
import { MathUtils } from 'three';
import { shallow } from 'zustand/shallow';

import { Rotation as RotationIcon, RotationDegrees } from '~/assets/icons';
import { AnchorToggle } from '~/components/Elements/Button/AnchorToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { PeriodicInput } from '~/components/Elements/Input/PeriodicInput';
import { updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { TransformType } from '~/lib/threejs/TransformControls';
import { AnimatedType, removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface TransformRotationFieldProps {
  className?: string;
}

export const TransformRotationField: React.FC<TransformRotationFieldProps> = () => {
  const [
    rotation,
    currentKeyframe,
    setAnimatedValue,
    addAnimatedValue,
    setStaticValue,
    pivotVisible,
    setPivotVisibility,
    selectedNodesInfo,
    groupTransform,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentTransform.rotation,
      state.toolkit.currentTransform.rotationCurrentKeyframe,
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
      state.toolkit.setStaticValue,
      state.ui.pivotVisible,
      state.ui.setPivotVisibility,
      state.ui.selectedNodesInfo,
      state.ui.selectedGroupTransform,
    ],
    shallow,
  );

  const prevRotation = useRef<null | number>(null);
  const multiSelect = selectedNodesInfo.length > 1;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      if (result.name === 'rotation') {
        if (prevRotation.current === null) prevRotation.current = result.value;
        // eslint-disable-next-line no-undefined
        if (multiSelect && groupTransform.rotation !== undefined) {
          updateGroupFromUI(
            TransformType.Rotation,
            MathUtils.degToRad(
              Math.trunc(
                Math.trunc(MathUtils.radToDeg(groupTransform.rotation) / 360) * 360 +
                  result.value -
                  prevRotation.current,
              ),
            ),
          );
          prevRotation.current = Math.trunc(MathUtils.radToDeg(groupTransform.rotation) / 360) * 360 + result.value;
        } else setAnimatedValue(AnimatedType.ROTATION, [Math.trunc(rotation / 360) * 360 + result.value]);
      } else if (result.name === 'numberOfRotations') {
        setAnimatedValue(AnimatedType.ROTATION, [result.value * 360 + (rotation % 360)]);
      }

      emitter.emit(EmitterEvent.ANIMATED_ROTATION_UPDATED);
    },
    [multiSelect, groupTransform.rotation, setAnimatedValue, rotation],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyframe) {
      removeKeyFrame(currentKeyframe);
      setStaticValue(AnimatedType.ROTATION, [rotation]);
    } else {
      addAnimatedValue(AnimatedType.ROTATION, [rotation]);
    }
    stateHistory.endAction();
    emitter.emit(EmitterEvent.ANIMATED_ROTATION_UPDATED);
  }, [rotation, addAnimatedValue, currentKeyframe, setStaticValue]);

  const handleOnClickAnchor = useCallback(() => {
    setPivotVisibility(!pivotVisible);
  }, [pivotVisible, setPivotVisibility]);

  const rotationValue = multiSelect && groupTransform.rotation ? MathUtils.radToDeg(groupTransform.rotation) : rotation;

  prevRotation.current = rotationValue;

  return (
    <div className="mr-6 mt-2 flex">
      <div className="relative flex items-center">
        <div className="number-input-label flex w-44 rounded-md border border-transparent bg-gray-700 focus-within:border-teal-500">
          <NumberInput
            value={Math.trunc(rotation / 360)}
            name="numberOfRotations"
            label={<RotationIcon className="h-4 w-4" />}
            onChange={handleOnChange}
            styleClass={{
              label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
            }}
          />

          <div className="my-[-1px] border border-gray-800"></div>
          <PeriodicInput
            value={rotationValue}
            name="rotation"
            label={<RotationDegrees className="h-2 w-2" />}
            append="°"
            period={360}
            allowNegative
            onChange={handleOnChange}
            styleClass={{
              label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
            }}
          />
        </div>
        <div className="absolute right-[-8px] top-[3px]">
          <KeyframeButton onClick={handleKeyframeClick} hasKeyframe={currentKeyframe !== ''} />
        </div>
      </div>
      <Interactive>
        <AnchorToggle anchorVisible={pivotVisible} onClick={handleOnClickAnchor} />
      </Interactive>
    </div>
  );
};
