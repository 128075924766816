/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StarShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { Rotation, RotationDegrees } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { PeriodicInput } from '~/components/Elements/Input/PeriodicInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PolystarRotationField: React.FC = () => {
  const [currentShapeNode, rotation, setRotation, setPolystarVariableIsAnimated, currentKeyFrame] = useCreatorStore(
    (state) => [
      state.toolkit.currentSecondary?.currentShapeNode,
      state.toolkit.currentSecondary?.currentShape.polystar.rotation,
      state.toolkit.setPolystarRotation,
      state.toolkit.setPolystarVariableIsAnimated,
      state.toolkit.currentSecondary?.currentShape.polystar.rotationCurrentKeyFrame,
    ],
    shallow,
  );

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setRotation(result.value);

      if (result.name === 'rotation') {
        setRotation(Math.trunc(rotation / 360) * 360 + result.value);
      } else if (result.name === 'numberOfRotations') {
        setRotation(result.value * 360 + (rotation % 360));
      }
      emitter.emit(EmitterEvent.POLYSTAR_ROTATION_UPDATED);
    },
    [setRotation, rotation],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyFrame === '') {
      setPolystarVariableIsAnimated('rotation', true);
      setRotation(rotation);
    } else if ((currentShapeNode as StarShape).rotation.keyFrames.length === 1) {
      setPolystarVariableIsAnimated('rotation', false);
      setRotation(rotation);
    } else {
      removeKeyFrame(currentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_ROTATION_UPDATED);
    stateHistory.endAction();
  }, [setRotation, setPolystarVariableIsAnimated, currentShapeNode, currentKeyFrame, rotation]);

  return (
    <div className="mr-6 flex">
      <div className="relative flex items-center">
        <div className="number-input-label flex w-44 rounded-md border border-transparent bg-gray-700 focus-within:border-teal-500">
          <NumberInput
            value={Math.trunc(rotation / 360)}
            name="numberOfRotations"
            label={<Rotation className="h-4 w-4" />}
            onChange={handleOnChange}
            styleClass={{
              label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
            }}
          />

          <div className="my-[-1px] border border-gray-800"></div>
          <PeriodicInput
            value={rotation}
            name="rotation"
            label={<RotationDegrees className="h-2 w-2" />}
            append="°"
            period={360}
            allowNegative
            onChange={handleOnChange}
            styleClass={{
              label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
            }}
          />
        </div>
        <div className="absolute right-[-8px] top-[3px]">
          <KeyframeButton onClick={handleKeyframeClick} hasKeyframe={currentKeyFrame !== ''} />
        </div>
      </div>
    </div>
  );
};
