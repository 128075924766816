/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, MutableRefObject, MouseEvent, TouchEvent } from 'react';
import React, { useEffect, useRef } from 'react';

import { TinyColor } from '../../utils';

import type { TPropsComp, TCoords } from './types';

const Ribbon: FC<TPropsComp> = ({ color, onChange, onStop, rootPrefixCls }) => {
  const node = useRef() as MutableRefObject<HTMLDivElement>;

  const removeListeners = (): void => {
    window.removeEventListener('mousemove', onDrag);
    window.removeEventListener('mouseup', onDragEnd);
  };

  const removeTouchListeners = (): void => {
    window.removeEventListener('touchmove', onTouchMove);
    window.removeEventListener('touchend', onTouchEnd);
  };

  useEffect(() => {
    return () => {
      removeListeners();
      removeTouchListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseDown = (event: MouseEvent): void => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({
      x,
      y,
    });

    onChange(color);

    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', onDragEnd);
  };

  const onDrag = (event: any): void => {
    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({
      x,
      y,
    });
    onChange(color);
  };

  const onDragEnd = (event: any): void => {
    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({
      x,
      y,
    });

    onStop(color);

    removeListeners();
  };

  const onTouchStart = (event: TouchEvent): void => {
    if (event.cancelable) {
      event.preventDefault();
    }

    if (event.touches.length !== 1) {
      return;
    }

    removeTouchListeners();

    const x = event.targetTouches[0]?.clientX;
    const y = event.targetTouches[0]?.clientY;

    if (x && y) {
      pointMoveTo({ x, y });
      onChange(color);
    }

    window.addEventListener('touchmove', onTouchMove, { passive: false });
    window.addEventListener('touchend', onTouchEnd, { passive: false });
  };

  const onTouchMove = (event: any): void => {
    if (event.cancelable) {
      event.preventDefault();
    }

    const x = event.targetTouches[0].clientX;
    const y = event.targetTouches[0].clientY;

    pointMoveTo({
      x,
      y,
    });
    onChange(color);
  };

  const onTouchEnd = (): void => {
    onStop(color);
    removeTouchListeners();
  };

  const getPrefixCls = (): string => {
    return `${rootPrefixCls}-ribbon`;
  };

  const pointMoveTo = (coords: TCoords): void => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!node.current) return;
    const rect = node.current.getBoundingClientRect();
    const width = rect.width;
    let left = coords.x - rect.left;

    left = Math.max(0, left);
    left = Math.min(left, width);

    const huePercent = left / width;
    const hue = huePercent * 360;

    color.hue = hue;
    onChange(color);
  };

  const hueHsv = {
    h: color.hue,
    s: 1,
    v: 1,
  };

  const hueColor = new TinyColor(hueHsv).toHexString();

  const prefixCls = getPrefixCls();
  const hue = color.hue;
  const per = (hue / 360) * 100;

  return (
    <div className={prefixCls} ref={node} onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
      <span style={{ left: `${per}%`, backgroundColor: hueColor }} />
    </div>
  );
};

export default Ribbon;
