/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import RangeSlider from 'react-range-slider-input';
import { shallow } from 'zustand/shallow';

import { useCreatorStore } from '~/store';

import './timeline-range-slider.css';

const MIN = 0;
const MAX = 100;

export const TimelineRangeSlider: React.FC = () => {
  const [rangeStart, rangeEnd, setRange, setTimeScale] = useCreatorStore(
    (state) => [
      state.timeline.rangeStart,
      state.timeline.rangeEnd,
      state.timeline.setRange,
      state.timeline.setTimeScale,
    ],
    shallow,
  );

  const handleOnInput = useCallback(
    (input: [number, number]) => {
      setRange(input);

      // TODO: apply power scaling instead of linear scale?
      const zoomPercentage = ((100 - input[1] - input[0]) / (MAX - MIN)) * 100 + 100;

      setTimeScale(zoomPercentage);
    },
    [setRange, setTimeScale],
  );

  return (
    <RangeSlider
      onInput={handleOnInput}
      id="timeline-range-slider"
      value={[rangeStart, rangeEnd]}
      min={MIN}
      max={MAX}
    />
  );
};
