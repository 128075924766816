/**
 * Copyright 2023 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import { newQuickjsArray } from './array';
import { newQuickjsObject } from './object';

import type { Plugin } from '~/plugins/Plugin';

export function marshal(plugin: Plugin, args: object | number | string | boolean): QuickJSHandle {
  let vmArgs;

  if (Array.isArray(args)) {
    vmArgs = newQuickjsArray(plugin, args, true);
  } else {
    const type = typeof args as 'string' | 'number' | 'boolean' | 'object';

    switch (type) {
      case 'string':
        vmArgs = plugin.vm.newString(args as string);
        break;

      case 'number':
        vmArgs = plugin.vm.newNumber(args as number);
        break;

      case 'boolean':
        vmArgs = (args as boolean) === true ? plugin.vm.true : plugin.vm.false;
        break;

      case 'object':
        vmArgs = newQuickjsObject(plugin, args as object, true);
        break;

      default:
        break;
    }
  }

  return vmArgs as QuickJSHandle;
}
