/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { KeyboardEvent, ChangeEvent } from 'react';

interface IInput {
  alphaValue: number;
  hexValue: string;
  onChangeAlpha: (value: string) => void;
  onChangeHex: (value: string) => void;
  showAlpha?: boolean;
}

export const getAlphaValue = (value: string): string | number => {
  value.replace(/%/iu, '');
  if (value.startsWith('0') && value.length > 1) {
    return value.substr(1);
  } else if (Number(value) >= 100) {
    return 100;
    // eslint-disable-next-line no-restricted-globals
  } else if (!isNaN(Number(value))) {
    return value || 0;
  }

  return parseInt(value, 10);
};

export const onlyDigits = (string: string): string => {
  return string ? string.substr(0, 3).replace(/\D/gu, '') : '';
};

export const onlyLatins = (string: string): string => {
  // eslint-disable-next-line no-param-reassign
  if (string && string.startsWith('#')) string = string.substring(1);

  return string ? string.substr(0, 6).replace(/[^\d\sa-z-]/giu, '') : '';
};

export const handlePressEnter = (event: KeyboardEvent, fn: () => void): void => {
  if (event.key === 'Enter') {
    fn();
  }
};

export const inputsData = (props: IInput): any => {
  const inputHex = {
    wrapClass: 'input_rgba-hex',
    labelSymbol: true,
    idInput: `rgba-hex${Math.random() * 10000}`,
    valueInput: props.hexValue,
    labelText: 'Hex',
    labelArea: 'hex',
    labelClass: 'input_rgba-label',
    onChangeInput: (event: ChangeEvent<HTMLInputElement>) => props.onChangeHex(onlyLatins(event.target.value)),
    name: 'hex',
  };

  const inputAlpha = {
    wrapClass: 'input_rgba-alpha',
    labelSymbol: false,
    idInput: `rgba-alpha${Math.random() * 10000}`,
    valueInput: props.alphaValue,
    labelText: 'Alpha',
    labelArea: 'alpha',
    labelClass: 'input_rgba-label',
    onChangeInput: (event: ChangeEvent<HTMLInputElement>) => props.onChangeAlpha(onlyDigits(event.target.value)),
    name: 'alpha',
  };

  if (props.showAlpha === false) {
    return [inputHex];
  }

  return [inputHex, inputAlpha];
};
