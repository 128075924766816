/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Vector } from '@lottiefiles/toolkit-js';
import type { Vector3 } from 'three';
import { ExtrudeGeometry, Shape } from 'three';

import { RaycasterLayers } from '../../constant';
import { CMesh } from '../../types/object';
import { shapeMaterial } from '../threeFactory';

import { ShapeTypes } from '~/store/constant';

const extrudeSettings = {
  depth: 2,
  bevelEnabled: false,
  bevelSegments: 0,
  steps: 2,
  bevelSize: 1,
  bevelThickness: 1,
};

export interface PathPoint {
  in: Vector;
  out: Vector;
  vertex: Vector;
}

let pathMesh: CMesh | undefined;

export const drawBezier = (points: PathPoint[], closed: boolean): Shape => {
  const numberOfVertices = points.length;
  const tempShape = new Shape();

  if (points.length > 0 && points[0]) {
    tempShape.moveTo(points[0].vertex.x, points[0].vertex.y);
    let i = 1;

    while (i < numberOfVertices) {
      const currentPoint = points[i];
      const prevPoint = points[i - 1];

      if (!currentPoint || !prevPoint) continue;

      const prevPathV = prevPoint.vertex;
      const prevPathOut = prevPoint.out;

      const currentPathV = currentPoint.vertex;
      const currentPathIn = currentPoint.in;

      tempShape.bezierCurveTo(
        prevPathV.x + prevPathOut.x,
        prevPathV.y + prevPathOut.y,
        currentPathV.x + currentPathIn.x,
        currentPathV.y + currentPathIn.y,
        currentPathV.x,
        currentPathV.y,
      );
      i += 1;
    }

    const lastPoint = points[numberOfVertices - 1];

    if (closed && lastPoint) {
      tempShape.bezierCurveTo(
        lastPoint.vertex.x + lastPoint.out.x,
        lastPoint.vertex.y + lastPoint.out.y,
        points[0].vertex.x + points[0].in.x,
        points[0].vertex.y + points[0].in.y,
        points[0].vertex.x,
        points[0].vertex.y,
      );
    }
  }

  return tempShape;
};

export const getPathShape = (points: PathPoint[], closed: boolean, center: Vector3 | undefined): CMesh => {
  const pathShape = drawBezier(points, closed);
  const geometry = new ExtrudeGeometry(pathShape, extrudeSettings);

  pathMesh = new CMesh(geometry, shapeMaterial.clone());
  if (center) pathMesh.position.copy(center);
  pathMesh.shapeType = ShapeTypes.Path;
  pathMesh.layers.enable(RaycasterLayers.CMesh);

  return pathMesh;
};
