/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dialog } from '@headlessui/react';
import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import Draggable from 'react-draggable';

import { AnimatedProperties } from './animatedProperties';

import { Close } from '~/assets/icons';
import { Modal } from '~/components/Elements/Modal';
import { Divider } from '~/features/property-panel';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options?: any;
}

export const AnimationAdder: React.FC<Props> = ({ isOpen, onClose, options }) => {
  const { defaultPosition, layerId } = options;

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [containerHeight, setContainerHeight] = useState(-1);

  const [dragging, setDragging] = useState(false);

  const handleOnStartDrag = useCallback(() => {
    setDragging(true);
  }, []);

  const handleOnStopDrag = useCallback(() => {
    setDragging(false);
  }, []);

  useEffect(() => {
    const animationModal = document.getElementById('animationPropertyModal');

    setContainerHeight(animationModal?.clientHeight as number);
  }, []);

  const [yOffset, setYOffset] = useState<null | number>(null);

  useLayoutEffect(() => {
    if (modalRef.current && yOffset === null) {
      const modalHeight = modalRef.current.clientHeight + 10;
      const viewportHeight = window.innerHeight;

      if ((defaultPosition.y as number) + modalHeight > viewportHeight) {
        setYOffset(viewportHeight - modalHeight - (defaultPosition.y as number) - 10);
      }
    }
  }, [yOffset, defaultPosition.y]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} showBackdrop={false}>
        <>
          <Draggable
            defaultPosition={defaultPosition}
            handle=".draggable-handle"
            positionOffset={{ y: yOffset ?? 0, x: 0 }}
            onStart={handleOnStartDrag}
            onStop={handleOnStopDrag}
          >
            <div
              className={`${
                containerHeight === -1 ? 'invisible' : 'visible'
              } fixed inset-0 top-[-15px]  select-none overflow-y-auto`}
            >
              <div className=" relative min-h-full items-center justify-center p-6 text-center">
                <Dialog.Panel
                  ref={modalRef}
                  id="animationPropertyModal"
                  className="relative w-[248px] overflow-x-visible rounded-md bg-gray-800 align-middle shadow-sm transition-all"
                >
                  <Dialog.Title
                    as="h3"
                    className={`${
                      dragging ? 'cursor-grabbing' : 'cursor-grab'
                    } draggable-handle  relative h-full px-4 py-3 text-left text-xs font-bold text-white`}
                  >
                    <div>Animated Properties</div>
                  </Dialog.Title>

                  <Divider customClass="bg-gray-700" />

                  <AnimatedProperties layerId={layerId} />

                  <div className="absolute right-4 top-4">
                    <button>
                      <Close
                        className="h-4 w-4 cursor-pointer fill-current text-gray-400 hover:opacity-50"
                        onClick={onClose}
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Draggable>
        </>
      </Modal>
    </>
  );
};
