/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../Plugin';

import { getCurrentScene } from './utils';

import { emitter, EmitterEvent } from '~/lib/emitter';

export function remove(plugin: Plugin): void {
  const removeHandle = plugin.scope.manage(
    plugin.vm.newFunction('remove', (string: QuickJSHandle) => {
      const currentScene = getCurrentScene();
      const translatedString = plugin.vm.getString(string);
      const node = currentScene.getNodeById(translatedString) as DagNode;

      node.removeFromGraph();

      // TODO: create custom emitter event?
      emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'remove', { value: removeHandle, configurable: false });
}
