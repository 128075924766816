/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import tinyColor from 'tinycolor2';

import InputRgba from '../../InputRgba';
import { parseGradient, rgbaToArray, rgbaToHex } from '../../utils';
import ColorPanel from '../ColorPanel';
// import GradientPanel from '../GradientPanel';
import type { IPropsComp, TPropsChange, IActiveColor } from '../types';
// import DefaultColorsPanel from '../DefaultColorPanel';

const Gradient: React.FC<IPropsComp> = ({
  color: value = '#ffffff',
  // onChangeColor = () => ({}),
  colorBoardHeight = 120,
  // debounceMS = 300,
  // defaultColors,
  // format = 'rgb',
}) => {
  const initColor = useMemo(() => parseGradient(value), [value]);
  const { stops } = initColor;

  const lastStop = rgbaToArray(stops[stops.length - 1]![0]);
  const lastStopLoc = stops[stops.length - 1]![1];
  const activeStop = rgbaToHex([lastStop![0]!, lastStop![1]!, lastStop![2]!]);
  const activeIdx = stops[stops.length - 1]![2];

  const [activeColor, setActiveColor] = useState<IActiveColor>({
    hex: activeStop,
    alpha: Number(Math.round(lastStop![3]! * 100)),
    loc: lastStopLoc ?? '',
    index: activeIdx ?? '',
  });

  // const [color, setColor] = useState(initColor);
  // const debounceColor = useDebounce(color, debounceMS);

  // useEffect(() => {
  //   if (init === false) {
  // TODO(Guorong)
  // if (color.gradient === initColor.gradient) {
  //   return;
  // }
  // onChangeColor(color.gradient);
  // }
  // }, [color]);

  useEffect(() => {
    // setColor(initColor);

    const findActive = initColor.stops.find((stop: any[]) => stop[2] === activeColor.index);

    // Update active color
    if (findActive) {
      const tinycolor = tinyColor(String(findActive[0]));

      if (`#${tinycolor.toHex()}` !== activeColor.hex) {
        setActiveColor({
          ...activeColor,
          hex: `#${tinycolor.toHex()}`,
          alpha: tinycolor.getAlpha() * 100,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChangeActiveColor = useCallback(
    (activeValue: TPropsChange) => {
      setActiveColor({
        ...activeColor,
        hex: activeValue.hex,
        alpha: Number(Math.round(activeValue.alpha)),
      });

      // const { modifier, stops: colorStops, type } = color;
      // const rgba = tinyColor(activeValue.hex);

      // rgba.setAlpha(activeValue.alpha / 100);

      // const newStops = colorStops.map((item: (string | number)[]) => {
      //   if (item[1] === activeColor.loc) {
      //     return [rgba.toRgbString(), item[1], item[2]];
      //   }

      //   return item;
      // });

      // setColor({
      //   ...color,
      //   gradient: `${getGradient(type, newStops, modifier, format, true)}`,
      //   stops: newStops as (string | number)[][],
      // });
    },
    [activeColor],
  );

  const onSubmitChange = (rgba: string): void => {
    const rgbaArr = rgbaToArray(rgba);
    const hex = rgbaToHex([rgbaArr![0]!, rgbaArr![1]!, rgbaArr![2]!]);

    onChangeActiveColor({ hex, alpha: rgbaArr![3]! * 100 });
  };

  return (
    <div className="colorpicker">
      {/* <GradientPanel
        color={color}
        setColor={setColor}
        activeColor={activeColor}
        setActiveColor={setActiveColor}
        setInit={setInit}
        format={format}
      /> */}
      <ColorPanel
        hex={activeColor.hex}
        alpha={activeColor.alpha}
        onChange={onChangeActiveColor}
        colorBoardHeight={colorBoardHeight}
      />
      <InputRgba
        hex={activeColor.hex}
        alpha={activeColor.alpha}
        onChange={(rgbaValue) =>
          setActiveColor((prev) => ({
            ...prev,
            hex: rgbaValue.hex,
            alpha: rgbaValue.alpha,
          }))
        }
        onSubmitChange={onSubmitChange}
      />
      {/* <DefaultColorsPanel
        defaultColors={defaultColors ?? []}
        setColor={setColor}
        setActiveColor={setActiveColor}
        colorType="gradient"
      /> */}
    </div>
  );
};

export default Gradient;
