/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  AVLayer,
  FillShape,
  StrokeShape,
  RectangleShape,
  ShapeLayer,
  StarShape,
  PathShape as PathShapeType,
  CubicBezierShape,
  EllipseShape,
} from '@lottiefiles/toolkit-js';
import { PropertyType } from '@lottiefiles/toolkit-js';

import {
  setAnimatedFillColor,
  setAnimatedFillOpacity,
  setAnimatedOpacity,
  setAnimatedPosition,
  setAnimatedRotation,
  setAnimatedScale,
  setStaticFillColor,
  setStaticFillOpacity,
  setStaticOpacity,
  setStaticPosition,
  setStaticRotation,
  setStaticScale,
  setIsAnimatedPosition,
  setIsAnimatedScale,
  setIsAnimatedOpacity,
  setIsAnimatedRotation,
  setIsAnimatedFillColor,
  setIsAnimatedFillOpacity,
  setStaticStrokeWidth,
  setStaticStrokeMiter,
  setStaticStrokeOpacity,
  setAnimatedStrokeMiter,
  setAnimatedStrokeWidth,
  setAnimatedStrokeOpacity,
  setIsAnimatedStrokeOpacity,
  setIsAnimatedStrokeMiter,
  setIsAnimatedStrokeWidth,
  setStaticStrokeColor,
  setAnimatedStrokeColor,
  setIsAnimatedStrokeColor,
  setShapeSize,
  setIsAnimatedSize,
  setShapeOpacity,
  setShapeRotation,
  setShapePosition,
  setIsAnimatedPoints,
  setShapePoints,
  setIsAnimatedOuterRadius,
  setIsAnimatedOuterRoundness,
  setIsAnimatedInnerRadius,
  setIsAnimatedInnerRoundness,
  setShapeOuterRadius,
  setShapeOuterRoundness,
  setShapeInnerRadius,
  setShapeInnerRoundess,
  setAnimatedPath,
  setStaticPath,
  setIsAnimatedPath,
  setStaticRectangleRoundness,
  setAnimatedRectangleRoundness,
  setIsAnimatedRectangleRoundness,
} from './shape';
import type { Scalar2D } from './types';

export const CreatorAnimatedTypes = [
  PropertyType.POSITION,
  PropertyType.SIZE,
  PropertyType.SCALE,
  PropertyType.OPACITY,
  PropertyType.ROTATION,
  PropertyType.FILL_COLOR,
  PropertyType.STROKE_WIDTH,
  PropertyType.STROKE_COLOR,
  PropertyType.ROUNDNESS,
  PropertyType.NUMBER_OF_POINTS,
  PropertyType.INNER_RADIUS,
  PropertyType.OUTER_RADIUS,
  PropertyType.INNER_ROUNDNESS,
  PropertyType.OUTER_ROUNDNESS,
];

export enum AnimatedType {
  POSITION,
  SCALE,
  ROTATION,
  OPACITY,
  ANCHOR,
  FILL_COLOR,
  FILL_OPACITY,
  STROKE_WIDTH,
  STROKE_OPACITY,
  STROKE_MITER,
  STROKE_COLOR,
  SIZE,
  POINTS,
  OUTER_RADIUS,
  INNER_RADIUS,
  OUTER_ROUNDNESS,
  INNER_ROUNDNESS,
  PATH,
  ROUNDNESS,
}

export enum NodeType {
  TRANSFORM,
  FILL,
  STROKE,
  PATH,
}

type SetFunction = (node: unknown, value: unknown[]) => void;
type EnableFunction = (node: unknown, value: boolean) => void;

interface AnimatedInfo {
  enableAnimation: EnableFunction;
  hasKeyframes: (node: unknown) => boolean;
  isSame: (node: unknown, value: unknown[]) => boolean;
  key: string;
  nodeType: NodeType;
  setAnimated: SetFunction;
  setStatic: SetFunction;
}

export const getAnimatedFunction: Record<AnimatedType, AnimatedInfo> = {
  [AnimatedType.POSITION]: {
    key: 'p',
    nodeType: NodeType.TRANSFORM,
    setStatic: setStaticPosition,
    setAnimated: setAnimatedPosition,
    enableAnimation: setIsAnimatedPosition,
    isSame: (node: AVLayer | RectangleShape | StarShape | EllipseShape, value: Scalar2D) => {
      if (node.transform)
        return node.transform.position.value.x === value[0] && node.transform.position.value.y === value[1];

      return node.position.value.x === value[0] && node.position.value.y === value[1];
    },
    hasKeyframes: (node: AVLayer | RectangleShape | StarShape | EllipseShape) => {
      if (node.transform) return node.transform.position.keyFrames.length > 0;

      return node.position.keyFrames.length > 0;
    },
  },
  [AnimatedType.SCALE]: {
    key: 's',
    nodeType: NodeType.TRANSFORM,
    setStatic: setStaticScale,
    setAnimated: setAnimatedScale,
    enableAnimation: setIsAnimatedScale,
    isSame: (node: AVLayer, value: Scalar2D) => {
      return node.transform.scale.value.x === value[0] && node.transform.scale.value.y === value[1];
    },
    hasKeyframes: (node: AVLayer) => {
      return node.transform.scale.keyFrames.length > 0;
    },
  },
  [AnimatedType.ROTATION]: {
    key: 'r',
    nodeType: NodeType.TRANSFORM,
    setStatic: setStaticRotation,
    setAnimated: setAnimatedRotation,
    enableAnimation: setIsAnimatedRotation,
    isSame: (node: AVLayer | StarShape, value: [number]) => {
      return node.rotation.value.value === value[0];
    },
    hasKeyframes: (node: AVLayer | StarShape) => {
      return node.rotation.keyFrames.length > 0;
    },
  },
  [AnimatedType.OPACITY]: {
    key: 'o',
    nodeType: NodeType.TRANSFORM,
    setStatic: setStaticOpacity,
    setAnimated: setAnimatedOpacity,
    enableAnimation: setIsAnimatedOpacity,
    isSame: (node: AVLayer | FillShape | null, value: [number]) => {
      if (node?.transform) {
        return node.transform.opacity.value.value === value[0];
      } else if (node) {
        // For fill color opacity
        return node.opacity.value.value === value[0];
      } else return false;
    },
    hasKeyframes: (node: AVLayer | FillShape | null) => {
      if (node?.transform) {
        return node.transform.opacity.keyFrames.length > 0;
      } else if (node) {
        // For fill color opacity
        return node.opacity.keyFrames.length > 0;
      } else return false;
    },
  },
  [AnimatedType.FILL_COLOR]: {
    key: 'cl',
    nodeType: NodeType.FILL,
    setStatic: setStaticFillColor,
    setAnimated: setAnimatedFillColor,
    enableAnimation: setIsAnimatedFillColor,
    isSame: (node: FillShape, value: [number, number, number, number]) => {
      return (
        node.color.value.red === value[0] && node.color.value.green === value[1] && node.color.value.blue === value[2]
      );
    },
    hasKeyframes: (node: FillShape) => {
      return node.color.keyFrames.length > 0;
    },
  },
  [AnimatedType.FILL_OPACITY]: {
    key: 'o',
    nodeType: NodeType.FILL,
    setStatic: setStaticFillOpacity,
    setAnimated: setAnimatedFillOpacity,
    enableAnimation: setIsAnimatedFillOpacity,
    isSame: (node: FillShape, value: [number]) => {
      return node.opacity.value.value === value[0];
    },
    hasKeyframes: (node: FillShape) => {
      return node.opacity.keyFrames.length > 0;
    },
  },
  [AnimatedType.SIZE]: {
    key: 'sz',
    nodeType: NodeType.TRANSFORM,
    setStatic: setShapeSize,
    setAnimated: setShapeSize,
    enableAnimation: setIsAnimatedSize,
    isSame: (node: RectangleShape | EllipseShape, value: [number, number]) => {
      return node.size.value.width === value[0] && node.size.value.height === value[1];
    },
    hasKeyframes: (node: RectangleShape | EllipseShape) => {
      return node.size.keyFrames.length > 0;
    },
  },
  [AnimatedType.POINTS]: {
    key: 'nt',
    nodeType: NodeType.TRANSFORM,
    setStatic: setShapePoints,
    setAnimated: setShapePoints,
    enableAnimation: setIsAnimatedPoints,
    isSame: (node: StarShape, value: [number]) => {
      return node.numPoints.value.value === value[0];
    },
    hasKeyframes: (node: StarShape) => {
      return node.numPoints.keyFrames.length > 0;
    },
  },
  [AnimatedType.OUTER_RADIUS]: {
    key: 'or',
    nodeType: NodeType.TRANSFORM,
    setStatic: setShapeOuterRadius,
    setAnimated: setShapeOuterRadius,
    enableAnimation: setIsAnimatedOuterRadius,
    isSame: (node: StarShape, value: [number]) => {
      return node.outerRadius.value.value === value[0];
    },
    hasKeyframes: (node: StarShape) => {
      return node.outerRadius.keyFrames.length > 0;
    },
  },
  [AnimatedType.OUTER_ROUNDNESS]: {
    key: 'os',
    nodeType: NodeType.TRANSFORM,
    setStatic: setShapeOuterRoundness,
    setAnimated: setShapeOuterRoundness,
    enableAnimation: setIsAnimatedOuterRoundness,
    isSame: (node: StarShape, value: [number]) => {
      return node.outerRoundness.value.value === value[0];
    },
    hasKeyframes: (node: StarShape) => {
      return node.outerRoundness.keyFrames.length > 0;
    },
  },
  [AnimatedType.INNER_RADIUS]: {
    key: 'ir',
    nodeType: NodeType.TRANSFORM,
    setStatic: setShapeInnerRadius,
    setAnimated: setShapeInnerRadius,
    enableAnimation: setIsAnimatedInnerRadius,
    isSame: (node: StarShape, value: [number]) => {
      return node.innerRadius.value.value === value[0];
    },
    hasKeyframes: (node: StarShape) => {
      return node.innerRadius.keyFrames.length > 0;
    },
  },
  [AnimatedType.INNER_ROUNDNESS]: {
    key: 'is',
    nodeType: NodeType.TRANSFORM,
    setStatic: setShapeInnerRoundess,
    setAnimated: setShapeInnerRoundess,
    enableAnimation: setIsAnimatedInnerRoundness,
    isSame: (node: StarShape, value: [number]) => {
      return node.innerRoundness.value.value === value[0];
    },
    hasKeyframes: (node: StarShape) => {
      return node.innerRoundness.keyFrames.length > 0;
    },
  },
  [AnimatedType.STROKE_WIDTH]: {
    key: 'sw',
    nodeType: NodeType.STROKE,
    setStatic: setStaticStrokeWidth,
    setAnimated: setAnimatedStrokeWidth,
    enableAnimation: setIsAnimatedStrokeWidth,
    isSame: (node: StrokeShape, value: [number]) => {
      return node.strokeWidth.value.value === value[0];
    },
    hasKeyframes: (node: StrokeShape) => {
      return node.strokeWidth.keyFrames.length > 0;
    },
  },
  [AnimatedType.STROKE_OPACITY]: {
    key: 'o',
    nodeType: NodeType.STROKE,
    setStatic: setStaticStrokeOpacity,
    setAnimated: setAnimatedStrokeOpacity,
    enableAnimation: setIsAnimatedStrokeOpacity,
    isSame: (node: StrokeShape, value: [number]) => {
      return node.opacity.value.value === value[0];
    },
    hasKeyframes: (node: StrokeShape) => {
      return node.opacity.keyFrames.length > 0;
    },
  },
  [AnimatedType.STROKE_MITER]: {
    key: 'ml',
    nodeType: NodeType.STROKE,
    setStatic: setStaticStrokeMiter,
    setAnimated: setAnimatedStrokeMiter,
    enableAnimation: setIsAnimatedStrokeMiter,
    isSame: (node: StrokeShape, value: [number]) => {
      return node.miterLimit.value.value === value[0];
    },
    hasKeyframes: (node: StrokeShape) => {
      return node.miterLimit.keyFrames.length > 0;
    },
  },
  [AnimatedType.STROKE_COLOR]: {
    key: 'sc',
    nodeType: NodeType.STROKE,
    setStatic: setStaticStrokeColor,
    setAnimated: setAnimatedStrokeColor,
    enableAnimation: setIsAnimatedStrokeColor,
    isSame: (node: StrokeShape, value: [number]) => {
      return (
        node.color.value.red === value[0] && node.color.value.green === value[0] && node.color.value.blue === value[0]
      );
    },
    hasKeyframes: (node: StrokeShape) => {
      return node.color.keyFrames.length > 0;
    },
  },
  [AnimatedType.PATH]: {
    key: 'sh',
    nodeType: NodeType.PATH,
    setStatic: setStaticPath,
    setAnimated: setAnimatedPath,
    enableAnimation: setIsAnimatedPath,
    isSame: (node: PathShapeType, value: [CubicBezierShape]) => {
      return node.shape.value === value;
    },
    hasKeyframes: (node: PathShapeType) => {
      return node.shape.keyFrames.length > 0;
    },
  },
  [AnimatedType.ROUNDNESS]: {
    key: 'rd',
    nodeType: NodeType.TRANSFORM,
    setStatic: setStaticRectangleRoundness,
    setAnimated: setAnimatedRectangleRoundness,
    enableAnimation: setIsAnimatedRectangleRoundness,
    isSame(node: RectangleShape, value: [number]) {
      return node.roundness.value.value === value[0];
    },
    hasKeyframes: (node: RectangleShape) => {
      return node.roundness.keyFrames.length > 0;
    },
  },
} as Record<AnimatedType, AnimatedInfo>;

const mapSharedColorProperty = {
  getAnimatedValue: (parentNode: FillShape | StrokeShape) => {
    const { b, g, r } = parentNode.color.value.rgb;

    return [[r, g, b]];
  },
  setShapeProperty(node, value) {
    if (value) {
      setAnimatedFillColor(node as StrokeShape | FillShape, value[0]);
    }
  },
};

interface MappedAnimatedInfo {
  animatedProperty: AnimatedType;
  getAnimatedValue: (node: unknown) => unknown[];
  setShapeProperty: (node?: unknown, value?: unknown[]) => void;
}

/*
 * This dict is used to figure out how to animate a property that isn't static.
 */
export const mapAnimatedProperty: Record<string, MappedAnimatedInfo> = {
  p: {
    animatedProperty: AnimatedType.POSITION,
    getAnimatedValue: (parentNode: AVLayer) => {
      return [parentNode.position.value.x, parentNode.position.value.y];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapePosition(node as ShapeLayer, [value[0] as number, value[1] as number]);
      }
    },
  },
  s: {
    animatedProperty: AnimatedType.SCALE,
    getAnimatedValue: (parentNode: AVLayer) => {
      return [parentNode.scale.value.x, parentNode.scale.value.y];
    },
    setShapeProperty(node, value) {
      if (value) {
        setAnimatedScale(node as AVLayer, [value[0] as number, value[1] as number]);
      }
    },
  },
  r: {
    animatedProperty: AnimatedType.ROTATION,
    getAnimatedValue: (parentNode: AVLayer) => {
      return [parentNode.rotation.value.value];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeRotation(node as ShapeLayer, value[0] as number);
      }
    },
  },
  o: {
    animatedProperty: AnimatedType.OPACITY,
    getAnimatedValue: (parentNode: AVLayer) => {
      return [parentNode.opacity.value.value];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeOpacity(node as ShapeLayer, value[0] as number);
      }
    },
  },
  sz: {
    animatedProperty: AnimatedType.SIZE,
    getAnimatedValue: (parentNode: RectangleShape | EllipseShape) => {
      return [parentNode.size.value.width, parentNode.size.value.height];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeSize(node as RectangleShape | EllipseShape, [value[0] as number, value[1] as number]);
      }
    },
  },
  nt: {
    animatedProperty: AnimatedType.POINTS,
    getAnimatedValue: (parentNode: StarShape) => {
      return [Math.round(parentNode.numPoints.value.value)];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapePoints(node as StarShape, [value[0] as number]);
      }
    },
  },
  or: {
    animatedProperty: AnimatedType.OUTER_RADIUS,
    getAnimatedValue: (parentNode: StarShape) => {
      return [Math.round(parentNode.outerRadius.value.value)];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeOuterRadius(node as StarShape, [value[0] as number]);
      }
    },
  },
  os: {
    animatedProperty: AnimatedType.OUTER_ROUNDNESS,
    getAnimatedValue: (parentNode: StarShape) => {
      return [Math.round(parentNode.outerRoundness.value.value)];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeOuterRoundness(node as StarShape, [value[0] as number]);
      }
    },
  },
  ir: {
    animatedProperty: AnimatedType.INNER_RADIUS,
    getAnimatedValue: (parentNode: StarShape) => {
      return [Math.round(parentNode.innerRadius.value.value)];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeInnerRadius(node as StarShape, [value[0] as number]);
      }
    },
  },
  is: {
    animatedProperty: AnimatedType.INNER_ROUNDNESS,
    getAnimatedValue: (parentNode: StarShape) => {
      return [Math.round(parentNode.innerRoundness.value.value)];
    },
    setShapeProperty(node, value) {
      if (value) {
        setShapeInnerRoundess(node as StarShape, [value[0] as number]);
      }
    },
  },
  sw: {
    animatedProperty: AnimatedType.STROKE_WIDTH,
    getAnimatedValue: (parentNode: StrokeShape) => {
      return [Math.round(parentNode.strokeWidth.value.value)];
    },
    setShapeProperty(node, value) {
      if (value) {
        setAnimatedStrokeWidth(node as StrokeShape, [value[0] as number]);
      }
    },
  },
  sh: {
    animatedProperty: AnimatedType.PATH,
    getAnimatedValue: (parentNode: PathShapeType) => {
      return [parentNode.shape.value];
    },
    setShapeProperty(node, value) {
      if (value) {
        setAnimatedPath(node as PathShapeType, [value[0] as CubicBezierShape]);
      }
    },
  },
  cl: {
    animatedProperty: AnimatedType.FILL_COLOR,
    ...mapSharedColorProperty,
  },
  sc: {
    animatedProperty: AnimatedType.STROKE_COLOR,
    ...mapSharedColorProperty,
  },
  rd: {
    animatedProperty: AnimatedType.ROUNDNESS,
    getAnimatedValue: (parentNode: RectangleShape) => {
      return [parentNode.roundness.value.value];
    },
    setShapeProperty: (parentNode: RectangleShape, value: [number]) => {
      setAnimatedRectangleRoundness(parentNode, value);
    },
  },
} as Record<string, MappedAnimatedInfo>;
