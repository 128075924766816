/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Angle as ToolkitAngle } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from './index';

const methodNames = {
  radians: 'radians',
  clone: 'clone',
  equals: 'equals',
  interpolate: 'interpolate',
  set: 'set',
  setValue: 'setValue',
  toArray: 'toArray',
  // toJSON: 'toJSON',
  toObject: 'toObject',
  toString: 'toString',
  fromRadians: 'fromRadians',
};

function getAngleMethods(plugin: Plugin, angle: ToolkitAngle): ObjectMethods {
  return getObjectMethods(plugin, methodNames, angle);
}

interface AngleObject extends ValueObject {
  value: number;
}

export function createAngleObject(plugin: Plugin, angle: ToolkitAngle): AngleObject {
  const angleObject = {
    value: angle.value,
    valueType: 'Angle',
  };

  registerObjectMethods(plugin, angle, angleObject, [getAngleMethods]);

  return angleObject;
}
