/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import type { DagNode, Shape } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useEffect, useState, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedPanelCategory, AnimatedPanelProperty } from './helpers';
import {
  localUIUpdate,
  updateKeyframe,
  getFilteredAnimationCategories,
  getFilteredAnimationProperties,
} from './helpers';

import { AnimateAllButton } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { TooltipWrapper } from '~/components/Elements/Tooltip/TooltipWrapper';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { isMacOS } from '~/utils';

import './AnimationTimeline.css';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

interface AnimatedPropertyFromModalProp {
  animatedKey: number;
  animatedProperty: AnimatedPanelProperty;
  isLast: boolean;
  layerId: string;
  setAnimatedProps: React.Dispatch<React.SetStateAction<AnimatedPanelProperty[]>>;
}

const AnimatedPropertyFromModal: React.FC<AnimatedPropertyFromModalProp> = ({
  animatedKey,
  animatedProperty,
  isLast,
  layerId,
  setAnimatedProps,
}: AnimatedPropertyFromModalProp) => {
  const type = animatedProperty.type;

  const node = getNodeByIdOnly(layerId) as DagNode;
  const [currentTransform, setCurrentTransform] = useState<any>({ currentKeyframe: '' });

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    updateKeyframe(type, layerId);
    stateHistory.endAction();
    setCurrentTransform(localUIUpdate(node, type));
  }, [node, type, layerId]);

  // update UI on load
  useEffect(() => {
    setCurrentTransform(localUIUpdate(node, type));
  }, [node, type]);

  // update UI if 'animate all' has been triggered
  useEffect(() => {
    emitter.on(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES, () => {
      setCurrentTransform(localUIUpdate(node, type));
    });

    return () => {
      emitter.off(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES, () => {
        setCurrentTransform(localUIUpdate(node, type));
      });
    };
  }, [node, type]);

  // update the 'isAnimated' state, so that parent component can
  // set the 'allAnimated' button status based on children's isAnimated state
  useEffect(() => {
    setAnimatedProps((previousState) => {
      const newState = previousState.map((prop) => {
        if (type === prop.type) {
          return {
            ...prop,
            currentTransform,
            isAnimated: currentTransform.currentKeyframe !== '',
          };
        }

        return prop;
      });

      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransform]);

  const isFirst = Boolean(animatedKey === 0);

  return (
    <div
      className={clsx('mb-px flex cursor-pointer bg-gray-700 px-2 py-[3px]', {
        'rounded-t-lg': isFirst,
        'rounded-b-lg': isLast,
      })}
      onClick={handleKeyframeClick}
      key={animatedKey}
    >
      <KeyframeButton
        onClick={handleKeyframeClick}
        hasKeyframe={currentTransform.currentKeyframe !== ''}
        isTransparent
      />

      <span className="ml-1 align-super text-xs">{animatedProperty.label}</span>
    </div>
  );
};

interface AnimatedPropertiesProp {
  layerId: string;
}

export const getCombinedAnimatedProperties = (node: any, layerId: string): unknown => {
  const combinedNodes = [node];

  if (node.type === 'SHAPE') {
    const getSimplifiedMap = useCreatorStore.getState().ui.getLayerSimplifiedUI;
    const shapeAnimatedIds = getSimplifiedMap(layerId).shapesAppearancesIds;

    if (shapeAnimatedIds.length > 0) {
      shapeAnimatedIds.forEach((shapeAnimatedId: string) => {
        const shapeAnimatedNode = getNodeByIdOnly(shapeAnimatedId);

        combinedNodes.push(shapeAnimatedNode);
      });
    }
  }

  const combinedAnimatedMaps: any = {};
  const combinedAnimatedProps = combinedNodes.reduce((arr, animNode) => {
    const nodeAnimatedProp = getFilteredAnimationProperties(animNode);

    const newArr = nodeAnimatedProp.map((nAnimatedProp) => {
      nAnimatedProp.layerId = animNode.nodeId;

      return nAnimatedProp;
    });

    combinedAnimatedMaps[animNode.type === 'SHAPE' ? 'sh' : animNode.type] = newArr;

    return arr;
  }, []);

  return {
    combinedNodes,
    combinedAnimatedProps,
    combinedAnimatedMaps,
  };
};

export const AnimatedProperties: React.FC<AnimatedPropertiesProp> = ({ layerId }: AnimatedPropertiesProp) => {
  // const setAnimatedPropertyPopupUI = useCreatorStore.getState().ui.setAnimatedPropertyPopupUI;

  const [enableAll, setAnimatedPropertyPopupUI] = useCreatorStore(
    (state) => [state.ui.animatedPropertyPopupUI.enableAll || false, state.ui.setAnimatedPropertyPopupUI],
    shallow,
  );

  const [allAnimated, setAllAnimated] = useState(false);

  const node = getNodeByIdOnly(layerId as string) as Shape;

  const { combinedAnimatedMaps, combinedAnimatedProps, combinedNodes } = getCombinedAnimatedProperties(node, layerId);

  const [animatedProps, setAnimatedProps] = useState(() => combinedAnimatedProps);

  const animatedCategories =
    combinedNodes.length > 0 &&
    combinedNodes.reduce((arr: any, combinedNode: Shape) => {
      const newArr = getFilteredAnimationCategories(combinedNode);

      // eslint-disable-next-line no-param-reassign
      arr = arr.concat(newArr);

      return arr;
    }, []);

  useEffect(() => {
    if (animatedProps.length > 0) {
      setAllAnimated(animatedProps.every((prop) => prop.isAnimated));
    }
  }, [animatedProps]);

  useEffect(() => {
    let allTypes = [];

    if (animatedCategories?.length > 0) {
      allTypes = animatedCategories.map((item) => {
        return item.type;
      });
      if (enableAll) {
        setAnimatedPropertyPopupUI({
          types: allTypes,
        });
      }
    }
  }, [enableAll, animatedCategories, setAnimatedPropertyPopupUI]);

  return (
    <div className="overflow-auto px-4 py-3 text-white">
      {animatedCategories.length > 0 &&
        animatedCategories.map((animatedPropertyCategory: AnimatedPanelCategory, key: number) => {
          const _animatedProps = combinedAnimatedMaps[animatedPropertyCategory.type].filter((item) => {
            return animatedPropertyCategory.animationKeys?.includes(item.type);
          });

          return (
            <div key={key}>
              <div className={`flex justify-between ${key > 0 ? 'pt-2' : ''}`}>
                <div className="mb-1 overflow-visible text-xs text-gray-300">{animatedPropertyCategory.label}</div>
                <TooltipWrapper
                  label={allAnimated ? 'Clear all keyframes' : 'Animate all'}
                  shortcut={isMacOS ? 'cmd shift K' : 'ctrl shift K'}
                >
                  <AnimateAllButton
                    allPropsAnimated={allAnimated}
                    onClick={() => {
                      setAnimatedPropertyPopupUI({
                        type: animatedPropertyCategory.type,
                      });
                      emitter.emit(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES);
                    }}
                  />
                </TooltipWrapper>
              </div>
              <div className="rounded-lg">
                {_animatedProps.length > 0 &&
                  _animatedProps.map((animatedProperty: AnimatedPanelProperty, animKey: number) => {
                    const isLast = Boolean(animKey === _animatedProps.length - 1);

                    return (
                      <AnimatedPropertyFromModal
                        key={animKey}
                        layerId={animatedProperty.layerId}
                        animatedKey={animKey}
                        animatedProperty={animatedProperty}
                        isLast={isLast}
                        setAnimatedProps={setAnimatedProps}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};
