/**
 * Copyright 2021 Design Barn Inc.
 */

import { enableMapSet } from 'immer';
import React, { useCallback } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'urql';

import { AuthListener } from './AuthListener';
import { AutoSaveListener } from './AutoSaveListener';
import { EmitterListener } from './EmitterListener';
import { ErrorListener } from './ErrorListener';
import { EventStoreProvider } from './EventStoreProvider';
import { GrowthBookProvider } from './GrowthBookProvider';
import { HotkeyListener } from './HotkeyListener';
import { NetworkListener } from './NetworkListener';
import { ProjectListener } from './ProjectListener';
import { ScriptProvider } from './ScriptProvider';
import { ToolkitListener } from './ToolkitListener';
import { ToolkitProvider } from './ToolkitProvider';
import { UsersnapProvider } from './UsersnapProvider';

import ErrorAnimation from '~/assets/animations/something-went-wrong.json';
import LottieLogo from '~/assets/images/lottiefiles_logo_for_dark_bg.png';
import { WORKFLOW_DASHBOARD } from '~/config';
import { client } from '~/lib/graphql';
import { runOpenReplay } from '~/lib/tracker';

enableMapSet();

runOpenReplay();

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const handleReload = useCallback((evt: React.MouseEvent): void => {
    evt.preventDefault();
    window.location.reload();
  }, []);

  return (
    <div className="w-full bg-gray-800">
      <div className="flex h-[24px] w-full p-[10px]">
        <img src={LottieLogo} alt="Lottie logo" className="h-[20px]" />
      </div>
      <div className="flex h-[calc(100vh-24px)] w-full flex-col items-center justify-center px-4 text-white">
        <div className="-mt-10 w-[360px] text-center">
          <div className="flex justify-center">
            <dotlottie-player
              autoplay
              loop
              src={JSON.stringify(ErrorAnimation)}
              style={{ height: '140px', marginBottom: '20px' }}
            ></dotlottie-player>
          </div>
          <div className="mt-2 text-xl">Something went wrong somewhere 😔</div>
          <div className="mt-2 text-sm text-gray-100">
            You could refresh this page, or&nbsp;
            <a className="text-lf-teal" href="https://help.lottiefiles.com/hc/en-us/requests/new">
              contact our customer support team.
            </a>
            &nbsp; We&apos;re always eager to help!
          </div>
          {error.message && <div className="mt-2 w-[360px] text-xs text-gray-300">{error.message}</div>}
          <div className="mt-8">
            <a
              onClick={(evt) => handleReload(evt)}
              className="!h-10 cursor-pointer rounded-xl bg-[#00C1A2] px-4 py-2 text-base text-white"
            >
              Reload this page
            </a>
          </div>
          <div className="mt-4">
            <div className="invisible mt-4 h-3"></div>
            <a
              className="mt-4 !h-10 rounded-xl border-[1px] border-solid px-4 py-2 text-base text-white"
              href={`${WORKFLOW_DASHBOARD}`}
              target="_blank"
              rel="noreferrer"
            >
              Go to Dashboard
            </a>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 h-10 w-full">
          <div className="flex w-full justify-center text-sm">
            <div className="px-4">
              <a href="https://www.notion.so/lottiefiles/How-To-s-ca8f1d3fd8a54fdea379a2dd3a381d0b">FAQ & Support</a>
            </div>
            <div className="px-4">
              <a href="https://help.lottiefiles.com/hc/en-us/requests/new">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <GrowthBookProvider>
      <ScriptProvider>
        <EventStoreProvider>
          <UsersnapProvider>
            <ToolkitProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Provider value={client}>
                  <HotkeyListener />
                  <ToolkitListener />
                  <EmitterListener />
                  <AuthListener />
                  <ProjectListener />
                  <NetworkListener />
                  <AutoSaveListener />
                  <ErrorListener />

                  {children}
                </Provider>
              </ErrorBoundary>
            </ToolkitProvider>
          </UsersnapProvider>
        </EventStoreProvider>
      </ScriptProvider>
    </GrowthBookProvider>
  );
};
