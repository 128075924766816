/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import {
  FetchExistingFile,
  VersionChecking,
  FetchWorkflowWorkspaces,
  CreateFromWorkflow,
  ExistingFile,
  SaveToDraft,
  SaveFileUpload,
} from './components/save';

import { useCreatorStore } from '~/store';
import { SavingState, UrlType } from '~/store/projectSlice';

export const StartSaveFileUpload: React.FC = () => {
  return (
    <>
      <VersionChecking>
        <SaveFileUpload />
      </VersionChecking>
    </>
  );
};

export const URLProjectFile: React.FC = () => {
  const urlType = useCreatorStore((state) => state.project.urlType);

  if (urlType === UrlType.Existing) {
    return <ExistingFile />;
  } else if (urlType === UrlType.Create) {
    return <CreateFromWorkflow />;
  }

  return null;
};

export const NewProjectFile: React.FC = () => {
  const [savingState, selectedDirectoryId, madeFirstChange, token] = useCreatorStore(
    (state) => [
      state.project.info.savingState,
      state.project.selectedDirectory?.id,
      state.project.madeFirstChange,
      state.user.token,
    ],
    shallow,
  );

  const ableToSave = Boolean(selectedDirectoryId && token);

  if (savingState === SavingState.SAVING && ableToSave) {
    // Perform saving
    return (
      <>
        <StartSaveFileUpload />
      </>
    );
  } else if (madeFirstChange && token && !selectedDirectoryId) {
    // First time saving to draft
    return <SaveToDraft createNew={true} />;
  }

  return null;
};

export const InitProjectListener: React.FC = () => {
  const [fetchExisting, token, urlType] = useCreatorStore(
    (state) => [state.project.fetchExistingFile.status, state.user.token, state.project.urlType],
    shallow,
  );

  const containURL = [UrlType.Existing, UrlType.Create].includes(urlType as UrlType);

  if (fetchExisting && token) {
    // Run one time
    return <FetchExistingFile />;
  } else if (containURL && token) {
    // Deal with url (/create & query params)
    return <URLProjectFile />;
  } else if (token) {
    // Default empty url
    return <NewProjectFile />;
  }

  return null;
};

export const ProjectListener: React.FC = () => {
  const [workflowWorkspaces, token] = useCreatorStore(
    (state) => [state.project.workflowWorkspaces, state.user.token],
    shallow,
  );

  return (
    <>
      {token && workflowWorkspaces.length === 0 && <FetchWorkflowWorkspaces />}
      {token && workflowWorkspaces.length > 0 && <InitProjectListener />}
    </>
  );
};
