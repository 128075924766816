/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';

import { BlendingModeSelection } from './field/PropertyBlendingModeField';
import { PropertyCompositeField } from './field/PropertyCompositeField';
import { PropertyFillRule } from './field/PropertyFillRule';
import { PropertyRow } from './ui/PropertyRow';

export const SettingsProperty: React.FC = () => {
  return (
    <PropertyRow title={'Settings'}>
      <div className="flex w-[176px]">
        <BlendingModeSelection />
      </div>
      <div className="mt-2 flex w-[176px]">
        <PropertyCompositeField />
      </div>
      <div className="mt-2 flex w-[176px]">
        <PropertyFillRule />
      </div>
    </PropertyRow>
  );
};
