/**
 * Copyright 2023 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '~/plugins/Plugin';

// TODO: handle unmarshalling object without using JSON parse/stringify

export function getTranslatedObject(plugin: Plugin, string: QuickJSHandle): object | null {
  const vmJSON = plugin.vm.getProp(plugin.vm.global, 'JSON');
  const stringify = plugin.vm.getProp(vmJSON, 'stringify');
  const stringifyResult = plugin.vm.callFunction(stringify, vmJSON, string);

  if (stringifyResult.error) {
    stringifyResult.error.dispose();
    plugin.vm.throw(new Error('failed to stringify data'));

    return null;
  }

  const translatedString = plugin.vm.getString(stringifyResult.value);
  const parsedAsObject = JSON.parse(translatedString);

  stringify.dispose();
  vmJSON.dispose();
  string.dispose();
  stringifyResult.value.dispose();

  return parsedAsObject;
}
