/**
 * Copyright 2023 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React from 'react';

import type { MenuDropdownProp } from './CreatorMenu';
import { MenuInteraction } from './MenuInteraction';

import { useClickOutside } from '~/hooks';

export interface Coordinate {
  x: number;
  y: number;
}

export interface ContextMenuWrapperProps {
  coord: Coordinate;
  customClass?: string;
  dropdownItems: MenuDropdownProp[];
  eventArg?: unknown;
  onClose: () => void;
}

export const ContextMenuWrapper: React.FC<ContextMenuWrapperProps> = ({
  coord,
  customClass,
  dropdownItems,
  eventArg,
  onClose,
}) => {
  const menuRef = React.useRef(null);
  const handleClick = React.useCallback(
    (callbackFn: ((arg?: unknown) => void) | undefined): void => {
      if (callbackFn) callbackFn(eventArg);
      onClose();
    },
    [onClose, eventArg],
  );

  useClickOutside(menuRef, onClose, null);

  return (
    <>
      <Menu as="div">
        <Transition
          show={true}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items
            ref={menuRef}
            style={{ left: `${coord.x}px`, top: `${coord.y}px` }}
            className={`fixed z-dropdown mt-1 w-[240px] cursor-pointer rounded-lg border border-gray-600 bg-gray-800 p-2 focus:outline-none ${customClass}`}
          >
            {dropdownItems.map((dropdown, index) => {
              const { callback, disabled, isDivider, type } = dropdown;

              return (
                <div key={`${type}_${index}`}>
                  <div className={`relative rounded ${!disabled && 'hover:bg-gray-600'}`}>
                    <Menu.Item>
                      <MenuInteraction>
                        {() => (
                          <button
                            className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                              index === dropdownItems.length - 1 && 'rounded-b'
                            } p-2 text-xs ${disabled ? 'text-gray-500' : 'text-white'}`}
                            onClick={() => handleClick(callback)}
                            disabled={Boolean(disabled)}
                          >
                            {dropdown.label}
                            {!dropdown.children && (
                              <span className={`absolute right-[7px] ${disabled ? 'text-gray-500' : 'text-gray-300'}`}>
                                {dropdown.shortcut}
                              </span>
                            )}
                          </button>
                        )}
                      </MenuInteraction>
                    </Menu.Item>
                  </div>
                  {isDivider && index !== dropdownItems.length - 1 && (
                    <>
                      <div
                        className="my-2 h-[1px] w-full bg-gray-700"
                        style={{
                          width: '100%',
                        }}
                      ></div>
                    </>
                  )}
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
