/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import type { GroupShape, VectorJSON} from '@lottiefiles/toolkit-js';
import type { Intersection, Scene,
  OrthographicCamera} from 'three';
import {
  Raycaster,  Vector2,
  Quaternion,
  Vector3,
  MathUtils,
} from 'three';

import { updateGroup, updatePivot, updateSelectedNode, updateToolkit } from '../../../features/canvas/toolkit';
import { getCurrentTransform, stateHistory, toolkit } from '../../toolkit';
import { Box3 } from '../Box3';
import { Box3Helper } from '../Box3Helper';
import { DragControls } from '../DragControls';

import type { AlignDirection} from './AlignmentHelper';
import { AlignmentHelper } from './AlignmentHelper';
import type { Pointer} from './constant';
import { boundingBox, eye, rotationAxis, cursorBaseAngles, cursorUrls, SpaceType, TransformType } from './constant';
import { SnapHelper } from './SnapHelper';
import { TransformControlsGizmo } from './TransformControlGizmo';
import { TransformControlsPlane } from './TransformControlsPlane';

import { CMesh, CObject3D, isChild, getPivotPoint, rotateAboutPoint, scaleAboutPoint, intersectObjectsWithRay, intersectObjectWithRay, UserDataMap, getPointer } from '~/features/canvas';
import { getNodeById } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import type { TransformInfo } from '~/store/uiSlice';

const setStaticPivot = useCreatorStore.getState().toolkit.setStaticPivot;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const setSingleSelectModifier = useCreatorStore.getState().timeline.setSingleSelectionModifier;
const setMultiSelectModifier = useCreatorStore.getState().timeline.setMultiSelectionModifier;

const _raycaster = new Raycaster();

const _tempVector = new Vector3();
const _tempVector2 = new Vector3();
const selectionBox = boundingBox();

const hoverSelectionBox = boundingBox();

hoverSelectionBox.visible = false;

// This shows only bounding rectangle without control gizmos. needed for the nodes which don't have animated properties
const nonTransformBox = new Box3();
const nonTransformSelectionBox = new Box3Helper(nonTransformBox, 0x00b6fe);

nonTransformSelectionBox.visible = false;

export class TransformControls extends CObject3D {
  public _onPointerDown: (event: PointerEvent) => void;

  public _onPointerHover: (event: PointerEvent) => void;

  public _onPointerMove: (event: PointerEvent) => void;

  public _onPointerUp: (event: PointerEvent) => void;

  public _setScaleRatioLockedIcon: (isLocked: boolean) => void;

  public axis: string | null = null;

  public camera: OrthographicCamera;

  public dragging = false;

  public dragged = 0;

  public hasDragged = false

  public enabled = true;

  public groupTransform: TransformInfo = {};

  public objects: CObject3D[] = [];

  public mode: TransformType = TransformType.Translation

  public pivotPoint: CObject3D | null = null;

  public pivotDragging = false;

  public pivotHovered = false;

  public rotationAngle = 0;

  public size = 1;

  public space = SpaceType.Local;

  public tag = ''

  public nonTransformable: CObject3D | null = null;

  private readonly _cursorElement: HTMLElement;

  private readonly _cursorImageElement: HTMLImageElement;

  private readonly _domElement: HTMLElement = (document as unknown) as HTMLElement;

  private readonly _endNorm: Vector3 = new Vector3();

  private readonly _gizmo: TransformControlsGizmo;

  public lastSelectedObjectIDs: string[] = [];

  private readonly _plane: TransformControlsPlane;

  private readonly _startNorm: Vector3 = new Vector3();

  private  _isNegativeRotation = false;

  public scaleRatioLocked = false;

  public nonTransformNodeSelected = false;

  public shiftDown = false;

  public cmdDown = false;

  public alignmentHelper = new AlignmentHelper();

  public snapHelper = new SnapHelper();

  public constructor(_camera: OrthographicCamera, domElement: HTMLElement, scene: Scene) {
    super();

    this.visible = false;
    this._domElement = domElement;
    // disable touch scroll
    this._domElement.style.touchAction = 'none';

    this._gizmo = new TransformControlsGizmo();
    this.add(this._gizmo);

    this._plane = new TransformControlsPlane();
    this.add(this._plane);
    this.add(this.snapHelper.root);

    scene.add(selectionBox);
    scene.add(hoverSelectionBox);
    scene.add(nonTransformSelectionBox);

    this.initPivotPoint(_camera, domElement);

    // Defined getter, setter and store for a property
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const defineProperty = (propName: any, defaultValue: any, scope = this) => {
      let propValue = defaultValue;

      Object.defineProperty(scope, propName, {
        get: () => {
          return propValue ? propValue : defaultValue;
        },

        set: (value) => {
          if (propValue !== value) {
            propValue = value;

            this.dispatchEvent({ type: `${propName}-changed`, value });
            this.dispatchEvent({ type: 'change' });
          }
        },
      });
    };

    // Define properties with getters/setter
    // Setting the defined property will automatically trigger change event

    defineProperty('camera', _camera);
    defineProperty('object', null);
    defineProperty('enabled', true);
    defineProperty('axis', null);
    defineProperty('mode', 'translate');
    defineProperty('_translationSnap', null);
    defineProperty('_rotationSnap', null);
    defineProperty('_scaleSnap', null);
    defineProperty('space', 'world');
    defineProperty('size', 1);
    defineProperty('dragging', false);

    this.camera = _camera;

    this._onPointerDown = (event: PointerEvent) => {
      event.preventDefault();
      if (!this.enabled) return;

      this._domElement.addEventListener('pointermove', this._onPointerMove);

      this.pointerHover(event);
      if (this.objects.length > 0 && this.axis && this.axis !== 'XY')
        this.pointerDown(getPointer(event, this._domElement));
    };

    this._onPointerHover = (event: PointerEvent) => {
      if (!this.enabled) return;
      if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
        this.pointerHover(event);
      }
    };

    this._onPointerMove = (event: PointerEvent) => {
      if (!this.enabled) return;
      this.pointerMove(event);
    };

    this._onPointerUp = (event: PointerEvent) => {
      if (!this.enabled) return;

      this._domElement.releasePointerCapture(event.pointerId);

      this._domElement.removeEventListener('pointermove', this._onPointerMove);     

      // if the user has held SHIFT while scaling, returns the scaleRatioLock UI to the previous state
      this._setScaleRatioLockedIcon(this.scaleRatioLocked)
      
      this.pointerUp(getPointer(event, this._domElement));
      this.pointerHover(event);
    };

    this._setScaleRatioLockedIcon = (isLocked: boolean) => {
      const setScaleRatioLocked = useCreatorStore.getState().ui.setScaleRatioLocked

      setScaleRatioLocked(isLocked)
    }

    this._domElement.addEventListener('pointerdown', this._onPointerDown);
    this._domElement.addEventListener('pointermove', this._onPointerHover);
    this._domElement.addEventListener('pointerup', this._onPointerUp);
    this._domElement.addEventListener('pointerleave', (event) => this.updateCursor({x: event.clientX, y: event.clientY}, null));
    document.body.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.shiftKey) {
        this.shiftDown = true;
        this.snapHelper.setShiftDown(true);
        setMultiSelectModifier(true)
      }

      if (event.key === "Meta" || event.ctrlKey) {
        this.cmdDown = true
        setSingleSelectModifier(true)
      }
    })
    document.body.addEventListener('keyup', () => {
      this.shiftDown = false;
      this._setScaleRatioLockedIcon(this.scaleRatioLocked)
      this.snapHelper.setShiftDown(false);
      this.snapHelper.translationSnapEnd()
      setMultiSelectModifier(false)

      this.cmdDown = false;
      setSingleSelectModifier(false)
    })

    this._cursorElement = document.createElement('div');
    this._cursorElement.id = 'cursor';

    this._cursorImageElement = document.createElement('img');
    this._cursorElement.append(this._cursorImageElement);

    document.body.append(this._cursorElement);
  }

  public autoCenter(object: CObject3D): void {    
    if (!this.pivotPoint) return;

    this.pivotPoint.position.copy(new Box3().setFromObject(object, true).center);
    object.anchorPosition.copy(this.pivotPoint.position);
    updatePivot(object, this.pivotPoint.position);
  }

  public onDragPivotPoint(fromSignal?: boolean): void {
    // triggered when anchor point is moved
    if (this.objects.length === 0 || !this.pivotPoint) return;
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const scene = toolkit.scenes[sceneIndex];

    if (!scene) return;

    stateHistory.beginAction()

    this.objects.forEach(object => {
      if (!this.pivotPoint) return;

      const node = getNodeById(scene, object.toolkitId);
      const centered = node?.data.get(UserDataMap.PivotCentered) as boolean;

      if (centered){
        const pivot = (node as GroupShape).pivot;

        object.anchorPosition.x = pivot.x;
        object.anchorPosition.y = pivot.y;
      } else {
        this.pivotPoint.position.copy(new Box3().setFromObject(object, true).center);
        object.anchorPosition.copy(this.pivotPoint.position);
      }
  
      if (!fromSignal) {
        setStaticPivot(this.pivotPoint.position.x, this.pivotPoint.position.y, object.toolkitId);
      } 
    })

    stateHistory.endAction()

    
    this.updateGizmo();
    this.pivotDragging = false;
  }

  public async initPivotPoint(camera: OrthographicCamera, dom: HTMLElement): Promise<void> {
    this.pivotPoint = await getPivotPoint();
    this.add(this.pivotPoint);

    const dragControls = new DragControls([this.pivotPoint], camera as OrthographicCamera, dom);

    dragControls.transformGroup = true;

    const dragStartPosition = new Vector3();

    dragControls.addEventListener('dragstart', () => {
      if (!this.pivotPoint || !this.pivotPoint.visible){
        
        return;
      }
      dragStartPosition.copy(this.pivotPoint.position);
      this.pivotDragging = true;
    });

    dragControls.addEventListener('dragend', () => this.onDragPivotPoint());
  }

  public updateCursor(pointer: Pointer, intersect: CObject3D | null): void {
    if (!intersect || !this.enabled || !this.visible || this.objects.length === 0) {
      this._cursorElement.style.display = 'none';
      this._domElement.style.cursor = 'default';

      return;
    }
    const { tag } = intersect.userData;
    const mode = this.pivotDragging ? TransformType.Pivot : intersect.userData['mode'];

    this._cursorImageElement.src = this.pivotDragging ? cursorUrls.Pivot : cursorUrls[mode as TransformType];
    this._cursorElement.style.display = 'block';

    let angle = 0;
    const angleOnMode = cursorBaseAngles[mode as TransformType];

    if (angleOnMode){
      angle = Math.floor(MathUtils.radToDeg(intersect.rotation.z) + Number(angleOnMode[tag]));
    }

    const deltaX = mode === TransformType.Pivot ? 16 : 4; 
    const deltaY = mode === TransformType.Pivot ? 16 : 2;

    this._cursorElement.style.transform = `translate(${pointer.x + deltaX}px, ${pointer.y + deltaY}px) rotate(${
      mode === TransformType.Translation ? 0 : angle
    }deg)`;
    this._domElement.style.cursor = 'none';
  }

  public getGizmoIntersect(event: PointerEvent): false | Intersection | undefined {
    const pointer = getPointer(event, this._domElement);

    _raycaster.setFromCamera(pointer, this.camera);

    const objects = [this._gizmo._picker.translate, this._gizmo._picker.rotate, this._gizmo._picker.scale];

    if (this.pivotPoint?.visible) objects.push(this.pivotPoint);

    return intersectObjectsWithRay(
      objects,
      _raycaster,
      true,
    );
  }

  public onHoverChange(hoverId: string): void {
    if (this.objects.length > 1) return;
    const canvasMap = useCreatorStore.getState().ui.canvasMap;

    if (!hoverId) {
      this.hideBoundingBox();

      return;
    }
    const hoveredObject = canvasMap.get(hoverId);

    if (hoveredObject) {
      this.showBoundingBox(hoveredObject);
      this.updateGizmo([hoveredObject as CObject3D], true);
    } else {
      this.hideBoundingBox();
    }
  }

  public pointerHover(event: PointerEvent): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;
   
    const intersect = this.getGizmoIntersect(event);
    
    this.updateCursor({x: event.clientX, y: event.clientY}, this.dragging ? this.objects[0] as CObject3D : (intersect as Intersection | null)?.object as CObject3D | null);
    if (this.dragging) return;

    if (intersect) {
      this.axis = intersect.object.name;
      this.mode = intersect.object.userData['mode'];
      this.tag = intersect.object.userData['tag'];
    } else {
      this.axis = null;
    }

    this.pivotHovered = Boolean(intersect && isChild(this.pivotPoint, intersect.object as CObject3D));
  }

  public pointerDown(pointer: Pointer): void {
    if (this.objects.length === 0 || !this.axis) return;
    this.dragging = true;

    this.objects.forEach(obj => {
      obj.startPosition.copy(obj.position);
      obj.startQuaternion.copy(obj.quaternion);
      obj.startScale.copy(obj.scale);
    })
    const selectedObject = this.objects[0] as CObject3D;

    _raycaster.setFromCamera(pointer, this.camera);

    const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

    if (planeIntersect) {
      this.objects.forEach(obj => {
        obj.updateMatrixWorld();
        obj.parent?.updateMatrixWorld();
  
        obj.worldPositionStart.setFromMatrixPosition(obj.matrixWorld);
        obj.worldPositionStart.copy(obj.anchorPosition);
        obj.pointStart.copy(planeIntersect.point).sub(obj.worldPositionStart);
        obj.pointStart.z = obj.worldPosition.z;
        // TODO
        // this.snapHelper.translationSnapStart(obj.worldPosition);
      })

    }

    const toolkitNode = toolkit.getNodeById(selectedObject.toolkitId);
    const transform = getCurrentTransform(toolkitNode, false);

    this._isNegativeRotation = transform.rotation < 0;

    hoverSelectionBox.visible = false;
  }

  public pointerMove(event: PointerEvent): void {
    const pointer = getPointer(event, this._domElement);

    if (!this.axis || !this.dragging || pointer.button !== -1 || this.objects.length === 0 || !this.pivotPoint) return;
    const selectedObject = this.objects[0] as CObject3D;
    // keep the same position z value during the transformation
    const originalZ = selectedObject.position.z;

    selectedObject.userData['mode'] = this.mode;
    selectedObject.userData['tag'] = this.tag;

    _raycaster.setFromCamera(pointer, this.camera);

    const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

    if (!planeIntersect) return;
    this.objects.forEach(obj => {
      obj.pointEnd.copy(planeIntersect.point).sub(obj.worldPositionStart);
      obj.pointEnd.z = obj.worldPosition.z;
    })
    this.dragged += 1;

    if (this.mode === TransformType.Translation) {
      // Apply translate
      this.objects.forEach(obj => {
        if (!this.axis) return;
        obj.offset.copy(obj.pointEnd).sub(obj.pointStart);
        obj.offset.applyQuaternion(obj.worldQuaternionInv);

        if (!this.axis.includes('X')) obj.offset.x = 0;
        if (!this.axis.includes('Y')) obj.offset.y = 0;
        if (!this.axis.includes('Z')) obj.offset.z = 0;

        obj.offset
          .multiplyScalar(Math.sign(obj.scale.y))
          .applyQuaternion(obj.startQuaternion)
          .divide(obj.parentScale);
        
        if (this.shiftDown) {
          this.snapHelper.translationSnap(obj, obj.startPosition, obj.worldPositionStart, obj.offset, selectionBox.box.center);
        } else {
          obj.position.copy(obj.offset).add(obj.startPosition);
        }
      })
    } else if (this.mode === TransformType.Scale) {
      _tempVector.copy(selectedObject.pointStart);
      _tempVector2.copy(selectedObject.pointEnd);

      _tempVector.applyQuaternion(selectedObject.worldQuaternionInv);
      _tempVector2.applyQuaternion(selectedObject.worldQuaternionInv);

      _tempVector2.divide(_tempVector);

      if (this.axis.search('X') === -1) {
        _tempVector2.x = 1;
      }

      if (this.axis.search('Y') === -1) {
        _tempVector2.y = 1;
      }
      
      if (this.axis.search('Z') === -1) {
        _tempVector2.z = 1;
      }

      // Apply scale
      const scaleRatioLockedIcon = useCreatorStore.getState().ui.scaleRatioLocked

      if (this.scaleRatioLocked || this.shiftDown) {
        if (!scaleRatioLockedIcon) this._setScaleRatioLockedIcon(true)

        if (this.axis.includes('XY')) {
          const max = Math.max(_tempVector2.x, _tempVector2.y);
        
          _tempVector2.setX(max);
          _tempVector2.setY(max);
        } else if (this.axis === 'X') {
          _tempVector2.setY(_tempVector2.x);
        } else if (this.axis === 'Y') {
          _tempVector2.setX(_tempVector2.y);
        }
      }

      this.objects.forEach(obj => {
        const scaleValue = new Vector3().copy(obj.startScale).multiply(_tempVector2);

        if (this.pivotPoint) {
          const offset = new Vector3().copy(this.pivotPoint.position).sub(obj.toolkitAnchorPosition);

          scaleAboutPoint(obj, this.pivotPoint.position, scaleValue, true, offset);
        }
      })
    } else {
      selectedObject.offset.copy(selectedObject.pointEnd).sub(selectedObject.pointStart);

      this.rotationAngle = selectedObject.pointEnd.angleTo(selectedObject.pointStart);
      this._startNorm.copy(selectedObject.pointStart).normalize();
      this._endNorm.copy(selectedObject.pointEnd.normalize());

      this.rotationAngle *= this._endNorm.cross(this._startNorm).dot(eye) < 0 ? -1 : 1;
      this.objects.forEach(obj => {
        if (this.pivotPoint)
          rotateAboutPoint(obj, this.pivotPoint.position, rotationAxis, this.rotationAngle, true, obj.startPosition, obj.startQuaternion);
      })
    }

    this.objects.forEach(obj => {obj.position.z = originalZ});

    this.updateGizmo();

    if (this.mode === TransformType.Translation){
      this.objects.forEach(obj => {
        updateSelectedNode(obj, this.mode);
      });
      if (this.objects.length > 1 && this.groupTransform.position) {
        updateGroup({position: this.groupTransform.position});
      }
      this.updatePivotPosition();      
    }
    else if (this.mode === TransformType.Rotation) {
      this.objects.forEach(obj => {
        updateSelectedNode(obj, this.mode,  this._isNegativeRotation)
      })
      if (this.objects.length > 1) {
        updateGroup({rotation: this.groupTransform.rotation ?? 0})
      }
    }
    else if (this.mode === TransformType.Scale) {
      this.objects.forEach(obj => {
        updateSelectedNode(obj, TransformType.Scale);
      });
      if (this.objects.length > 1) {
        updateGroup({scale: new Vector3().copy(_tempVector2)})
      }
    }
  }

  // rotate the selected object by 90 degree to the right
  public rotateRight(): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;

    const multiSelect = this.objects.length > 1;

    if (multiSelect) {
      // TODO: multi-select
    } else {
      const selectedObject = this.objects[0] as CObject3D;

      rotateAboutPoint(selectedObject, this.pivotPoint.position, rotationAxis, -Math.PI / 2, true);
      updateToolkit([selectedObject], TransformType.Rotation);
      hoverSelectionBox.box.setFromObject(selectedObject, true);
      hoverSelectionBox.update();
    }

  }

  // rotate the selected object by 90 degree to the left
  public rotateLeft(): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;
    const multiSelect = this.objects.length > 1;

    if (multiSelect) {
      // TODO: multi-select
    } else {
      const selectedObject = this.objects[0] as CObject3D;

      rotateAboutPoint(selectedObject, this.pivotPoint.position, rotationAxis, Math.PI / 2, true);
      updateToolkit([selectedObject], TransformType.Rotation);
      hoverSelectionBox.box.setFromObject(selectedObject, true);
      hoverSelectionBox.update();
    }
  }

  public pointerUp(pointer: Pointer): void {
    if (pointer.button !== 0) return;
    this.dragging = false;
    this.dragged = 0;
    this.axis = null;
    this.updateGizmo();
    this.snapHelper.translationSnapEnd();
  }

  // executed after each dragging of transformcontrol gizmo
  public onDrag(): void {
    if (this.objects.length === 0 || this.dragged < 3) return;
    updateToolkit(this.objects, this.mode);
    this.hasDragged = true
  }

  public moveSelectedShape(offset: Vector3): void {
    if (this.objects.length === 0) return;
    this.objects.forEach(object => {
      object.position.add(offset);
      updateToolkit([object], TransformType.Translation)
      hoverSelectionBox.visible = false;
    })
  }

  public dispose(): void {
    this._domElement.removeEventListener('pointerdown', this._onPointerDown);
    this._domElement.removeEventListener('pointermove', this._onPointerHover);
    this._domElement.removeEventListener('pointermove', this._onPointerMove);
    this._domElement.removeEventListener('pointerup', this._onPointerUp);

    this.traverse((child) => {
      if (child instanceof CMesh) {
        child.geometry.dispose();
        child.material.dispose();
      }
    });
  }

  // Set current object
  public override attach(object: CObject3D | CMesh): this {
    // transform control is not available for mesh as of now.
    if (object instanceof CMesh) {
      this.showBoundingBox(object, true);
      this.lastSelectedObjectIDs = [object.toolkitId];

      return this;
    } 
    const isDisplay = object.displayOnly;

    if ((!object.parent || !this.pivotPoint)) return this;

    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    
    selectionBox.box.setFromObject(object, true);
    if (selectionBox.box.emptyObject){
      selectionBox.visible = false;
      hoverSelectionBox.visible = false;
      nonTransformSelectionBox.visible = false;

      return this;
    } 
    selectionBox.update();
    selectionBox.visible = true;

    this.objects = [object];
    this.alignmentHelper.object = object;

    this.lastSelectedObjectIDs = [object.toolkitId];

    const scene = toolkit.scenes[sceneIndex];

    if (!scene) return this;

    if (!isDisplay) {
      
      const node = getNodeById(scene, object.toolkitId);
      const pivot = (node as GroupShape).pivot;

      const centered = node?.data.get(UserDataMap.PivotCentered) as boolean;

      if (centered) {
        this.pivotPoint.position.x = pivot.x;
        this.pivotPoint.position.y = pivot.y;
        this.pivotPoint.position.z = -20;
        object.anchorPosition.copy(this.pivotPoint.position);
      } else {
        stateHistory.offTheRecord(() => node?.setData(UserDataMap.PivotCentered, true))
        this.autoCenter(object);
      }
      this.pivotPoint.visible = (node?.data.get(UserDataMap.PivotVisible) ?? true) as boolean;
      this.scaleRatioLocked = Boolean(node?.data.get(UserDataMap.ScaleRatioLock));
      
    }
    this.visible = true;

    object.initialPosition.copy(object.position);
    object.userData = {mode: TransformType.Translation};
    
    nonTransformSelectionBox.visible = false;
    this.nonTransformNodeSelected = false;
    this.updateGizmo();
    this.nonTransformable = null

    if(isDisplay) {
      this.enabled = false;
    }

    return this;
  }

  public attachMultiple(objectIDs: string[], fromSignal = false, newPivot = false): this {
    if (!this.pivotPoint) return this;
    const canvasMap = useCreatorStore.getState().ui.canvasMap;
    const pivotVisible = useCreatorStore.getState().ui.pivotVisible;

    this.visible = true;
    this.objects = objectIDs.map((toolkitID) => canvasMap.get(toolkitID)).filter(Boolean) as CObject3D[];
    this.updateGizmo();
    if (fromSignal) return this;

    const pivot = selectionBox.box.center;
    
    if (!newPivot && this.objects[0]) {
      const {x, y} = (getNodeByIdOnly(this.objects[0].toolkitId) as GroupShape).pivot;
      
      pivot.x = x;
      pivot.y = y;
    }
    this.pivotPoint.position.copy(pivot);
    this.pivotPoint.position.z = -30;
    this.pivotPoint.visible = pivotVisible;

    let maxOpacity = 0;
    let maxScaleX = 1;
    let maxScaleY = 1;
    let maxRotation = 0;

    stateHistory.offTheRecord(() => {
      this.objects.forEach(object => {
        const node = getNodeByIdOnly(object.toolkitId);
  
        if (!node) return;
        if (newPivot) {
          setStaticPivot(pivot.x, pivot.y, object.toolkitId);
        }
        const newAnchor = (node as GroupShape).state.animatedProperties.a.value as VectorJSON | null;
    
        if (newAnchor) {
          object.toolkitAnchorPosition.x = newAnchor.x;
          object.toolkitAnchorPosition.y = newAnchor.y;
        }
        object.initialPosition.copy(object.position);
        object.anchorPosition.copy(pivot);
        node.setData(UserDataMap.PivotCentered, true);
  
        maxOpacity = Math.max(maxOpacity, object.opacity);
  
        const scale = (node as GroupShape).state.animatedProperties.s.value as VectorJSON | null;
  
        if (scale){
  
          maxScaleX = Math.max(maxScaleX, scale.x);
          maxScaleY = Math.max(maxScaleY, scale.y);
        }
  
        maxRotation = Math.max(maxRotation, object.rotation.z);
        node.setData(UserDataMap.PivotCentered, true)
        
      })
    })
    this.lastSelectedObjectIDs = objectIDs;
    selectionBox.box.setFromObjects(this.objects, true, true);
    selectionBox.update();
    selectionBox.visible = true;
    this.updateGizmo();

    updateGroup({position: pivot, scale: new Vector3(maxScaleX / 100, maxScaleY / 100, 1), opacity: maxOpacity, pivot, rotation: selectionBox.box.zRotation})
    this.groupTransform.rotation = selectionBox.box.zRotation;
    this.groupTransform.position = new Vector3().copy(pivot);

    return this;
  }

  public updatePivotPosition(): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;
    const object = this.objects[0] as CObject3D;
    const parentScale = new Vector3();
    const parentQuaternion = new Quaternion();

    object.parent?.getWorldScale(parentScale);
    object.parent?.getWorldQuaternion(parentQuaternion)
    const offset = new Vector3().copy(object.position).sub(object.initialPosition).multiply(parentScale).applyQuaternion(parentQuaternion);
    
    this.pivotPoint.position.copy(object.anchorPosition).add(offset);
  }

  public showBoundingBox(object: CObject3D | CMesh, selectNonTransformable?: boolean): void {
    if (this.dragging) return;
    
    hoverSelectionBox.box.setFromObject(object, true);
    hoverSelectionBox.update();
    hoverSelectionBox.visible = true;
    
    if (selectNonTransformable) {
      nonTransformBox.setFromObject(object, true);
      nonTransformSelectionBox.update();
      nonTransformSelectionBox.visible = true;
      this.nonTransformNodeSelected = true;
    } 
  }
  

  public hideBoundingBox(): void {
    hoverSelectionBox.visible = false;
  }

  public attachNonTransformable(object: CObject3D): void {
    // keeps track of selected stroke, fill or path layers for alignment tools
    this.lastSelectedObjectIDs = [object.toolkitId];
    this.nonTransformable = object;
  }

  public align(direction: AlignDirection): void {
    const multiSelect = this.objects.length > 1;

    if (multiSelect) {
      // TODO: multi-select
    } else {
      // if a nontransformable layer is selected, try to attach its parent group layer to this.object
      if (!this.objects[0] && this.nonTransformable) {
        this.attach(this.nonTransformable)

        const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

        addToSelectedNodes([(this.objects[0] as unknown as CObject3D).toolkitId], true)
        
      }
      this.alignmentHelper.align(direction);
    }

  }

  public updateGizmo(objects = this.objects, translateOnly = false): void {
    const filteredObjects = objects.filter(Boolean);
    
    if (filteredObjects.length === 0) return;
    const multiSelect = filteredObjects.length > 1;

    if (this.objects.length) selectionBox.visible = true;
    selectionBox.box.setFromObjects(filteredObjects as CObject3D[], true);
    this.groupTransform.rotation = selectionBox.box.zRotation;
    if (this.dragging) selectionBox.update();
    if (this.pivotPoint) this.pivotPoint.position.z = -30;

    const rotatePicker = this._gizmo._picker.rotate;
    const scalePicker = this._gizmo._picker.scale;
    const scaleGizmo = this._gizmo._gizmo.scale;
    const translatePicker = this._gizmo._picker.translate;
    const bbx = selectionBox.box;

    translatePicker.children.forEach((tPicker: CMesh) => {
      tPicker.scale.set(bbx.max.distanceTo(bbx.minXmaxY), bbx.max.distanceTo(bbx.maxXminY), 1);
      tPicker.rotation.z = bbx.zRotation;
      tPicker.position.copy(bbx.max).add(bbx.min).divideScalar(2);
      tPicker.position.z = -15
    });

    if (translateOnly && !multiSelect) {
      /* 
      In normal situation, the translate picker is always selected first by the raycaster by being the closest to the camera.
      In the case that the translate picker moves alongside the mouse hovered shapes, the rotate and scale picker of the selected shape should be selected first.
      Lower z position means closer to the camera.
      */
      if (objects[0] !== this.objects[0]) {
        translatePicker.children[0].position.z = 0;
      }

      return;
    }

    rotatePicker.children.forEach((rPicker: CMesh) => {
      const {
        position,
        userData: { tag },
      } = rPicker;

      if (tag === 'pickerRotateTR') position.copy(bbx.maxXminY);
      else if (tag === 'pickerRotateTL') position.copy(bbx.min);
      else if (tag === 'pickerRotateBL') position.copy(bbx.minXmaxY);
      else if (tag === 'pickerRotateBR') position.copy(bbx.max);
      position.z = -5;
      rPicker.rotation.z = bbx.zRotation;
    });
    const scaleMeshes = [...scalePicker.children, ...scaleGizmo.children];

    scaleMeshes.forEach((sm: CMesh) => {
      const {
        position,
        scale,
        userData: { tag },
      } = sm;

      const roundPointDelta = new Vector2(8, 8);

      roundPointDelta.rotateAround(new Vector2(), bbx.zRotation);
      const scaleY = (bbx.max.distanceTo(bbx.maxXminY));
      const scaleX = bbx.min.distanceTo(bbx.maxXminY);
    
      if (tag === 'ScaleX1') {
        scale.setY(scaleY * 0.8);
        position.copy(bbx.max).add(bbx.maxXminY).divideScalar(2);
      } else if (tag === 'ScaleX2') {
        scale.setY(scaleY * 0.8);
        position.copy(bbx.min).add(bbx.minXmaxY).divideScalar(2);
      } else if (tag === 'ScaleY1') {
        scale.setX(scaleX * 0.8);
        position.copy(bbx.min).add(bbx.maxXminY).divideScalar(2);
      } else if (tag === 'ScaleY2') {
        scale.setX(scaleX * 0.8);
        position.copy(bbx.max).add(bbx.minXmaxY).divideScalar(2);
      } else if (tag === 'ScaleTR') position.copy(bbx.maxXminY);
      else if (tag === 'ScaleTL') position.copy(bbx.min);
      else if (tag === 'ScaleBL') position.copy(bbx.minXmaxY);
      else if (tag === 'ScaleBR') position.copy(bbx.max);
      
      sm.rotation.z = bbx.zRotation;
      position.z = scaleX < 30 || scaleY < 30 ? -10 : -20;
    });
  }

  // Detatch from object
  public detach(): this {
    if (this.objects.length > 1) {
      stateHistory.offTheRecord(() => {
        this.objects.forEach(obj => {
          const node = getNodeByIdOnly(obj.toolkitId);
    
          if (!node) return;
  
          node.setData(UserDataMap.PivotCentered, false);
        })
      })
      this.objects.forEach(obj => {
        const node = getNodeByIdOnly(obj.toolkitId);
  
        if (!node) return;

        stateHistory.offTheRecord(() => node.setData(UserDataMap.PivotCentered, false))
      })
    }
    this.objects = [];
    this.alignmentHelper.object = null;
    if(this.pivotPoint) this.pivotPoint.visible = false;

    this._cursorElement.style.display = 'none';
    this._domElement.style.cursor = 'default';
    selectionBox.visible = false;
    nonTransformSelectionBox.visible = false;
    this.nonTransformNodeSelected = false;
    this.visible = false;

    return this;
  }

  // reselect the last object selected before redrawing
  public reselectLastObject(timelineUpdated?: boolean): void {
    // when the timeline is updated, no need to show the previous selection
    if (timelineUpdated) {
      this.visible = false;

      return;
    } 
    const canvasMap = useCreatorStore.getState().ui.canvasMap;
    
    if (this.lastSelectedObjectIDs.length > 1) {
      this.attachMultiple(this.lastSelectedObjectIDs);
    
      return;
    };

    const lastSelected = canvasMap.get(this.lastSelectedObjectIDs[0] ?? '');

    if (lastSelected) this.attach(lastSelected);
  }

  public reset(): void {
    if (!this.enabled || this.objects.length === 0 || !this.dragging) return;
    this.objects.forEach(obj => {
      obj.position.copy(obj.startPosition);
      obj.quaternion.copy(obj.startQuaternion);
      obj.scale.copy(obj.startScale);

      obj.pointStart.copy(obj.pointEnd);
    })
  }

  public getRaycaster(): Raycaster {
    return _raycaster;
  }

  public getMode(): TransformType{
    return this.mode;
  }

  public setMode(mode: TransformType): void {
    this.mode = mode;
  }

  public setSize(size: number): void {
    this.size = size;
  }

  public setSpace(space: SpaceType): void {
    this.space = space;
  }

  public update(): void {
    // eslint-disable-next-line no-console
    console.warn(
      'THREE.TransformControls: update function has no more functionality and therefore has been deprecated.',
    );
  }

  // updateMatrixWorld  updates key transformation variables
  public override updateMatrixWorld(): void {
    if (this.dragging) {
      this.objects.forEach(obj => {
        obj.updateMatrixWorld();
        if (obj.parent === null) {
          // eslint-disable-next-line no-console
          console.error('TransformControls: The attached 3D object must be a part of the scene graph.');
        } else {
          obj.parentQuaternion.setFromRotationMatrix(obj.parent.matrixWorld);
          obj.parentScale.setFromMatrixScale(obj.parent.matrixWorld)
        }
  
        const worldScale = new Vector3();
  
        obj.matrixWorld.decompose(obj.worldPosition, obj.worldQuaternion, worldScale);
  
        obj.parentQuaternionInv.copy(obj.parentQuaternion).invert();
        obj.worldQuaternionInv.copy(obj.worldQuaternion).invert();
      })
    }
    this.camera.updateMatrixWorld();

    this._gizmo.update(this.camera as OrthographicCamera);
    super.updateMatrixWorld();
  }
}
