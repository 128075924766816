/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { useCreatorStore } from '~/store';

export const AlertContainer: React.FC = () => {
  const [alertOn, setAlertOn] = useState(false);

  const [alert, setAlert] = useCreatorStore((state) => [state.ui.alert, state.ui.setAlert], shallow);

  const text = alert.text;
  const handle = alert.handle || null;
  const handleText = alert.handleText || null;
  const alertColor = alert.alertColor || '#00DDB3';
  const timeout = alert.timeout || 5000;
  const advancedRunOnceEveryPageRefresh = alert.advanced?.runOnceEveryPageRefresh || false;
  const advancedAlertId = alert.advanced?.alertId || 'random';
  const alertPopupCount = useRef<Map<string, number>>(new Map());

  const closeAlert = useCallback((): void => {
    setAlertOn(false);
    setAlert({ text: null });
  }, [setAlert]);

  const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    evt.preventDefault();
    if (handle) {
      closeAlert();
      await handle();
    }
  };

  const openAlert = useCallback((): void => {
    setAlertOn(true);
    setTimeout(() => {
      closeAlert();
    }, timeout);
  }, [timeout, closeAlert]);

  useEffect(() => {
    if (advancedRunOnceEveryPageRefresh) {
      const currentCount = alertPopupCount.current.get(advancedAlertId) || 0;

      alertPopupCount.current.set(advancedAlertId, currentCount + 1);
    }

    if (text) {
      const newCount = alertPopupCount.current.get(advancedAlertId) || 0;

      const onlyFirstRun = advancedRunOnceEveryPageRefresh && newCount === 1;

      if (!advancedRunOnceEveryPageRefresh || onlyFirstRun) {
        openAlert();
      }
    }
  }, [text, openAlert, advancedRunOnceEveryPageRefresh, advancedAlertId]);

  return (
    <>
      {alertOn && (
        <div className="fixed flex w-full flex-col items-center justify-center p-3">
          <div
            className="relative mb-1 flex h-10 items-center rounded-md px-4 py-3 text-sm text-white transition-all duration-500"
            style={{
              backgroundColor: alertColor,
            }}
          >
            <p>{text}</p>
            {handleText && (
              <button className="ml-3 cursor-pointer font-bold" onClick={handleClick}>
                {handleText}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
