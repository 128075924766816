/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../Plugin';

import { getCurrentScene } from './utils';
import { newQuickjsObject } from './vmInterface/marshal/object';
import { getTranslatedObject } from './vmInterface/unmarshal/object';

// TODO: rewrite with introspection method
// TODO: api method below currently creates a node id registry
// on a scene and node level
// to check with toolkit team if can move this to toolkit

export function getData(plugin: Plugin, node: DagNode): QuickJSHandle {
  const data = node.getData(plugin.id());
  const nodeData = data as object;
  const vmData = newQuickjsObject(plugin, nodeData);

  return vmData;
}

export function setData(plugin: Plugin, node: DagNode, vmData: QuickJSHandle): void {
  const pluginId = plugin.id();
  const data = getTranslatedObject(plugin, vmData);
  const { nodeId } = node;

  // appends data to its nodeId key
  const previousData = node.getData(pluginId) as object;

  node.setData(pluginId, { ...previousData, ...data });

  // saves the nodeId to the scene
  const currentScene = getCurrentScene();
  const sceneData = currentScene.toolkit.getData(pluginId);
  const nodeIds = sceneData?.customNodeIds || [];

  if (!nodeIds.includes(nodeId)) {
    nodeIds.push(nodeId);
    currentScene.toolkit.setData(pluginId, { customNodeIds: nodeIds, ...sceneData });
  }
}

export function removeData(plugin: Plugin, node: DagNode): void {
  const pluginId = plugin.id();
  const { nodeId } = node;

  node.removeData(pluginId);

  // removes the nodeId from the scene
  const currentScene = getCurrentScene();
  const sceneData = currentScene.toolkit.getData(pluginId) as object;
  const customNodeIds = sceneData.customNodeIds;
  const filteredArray = customNodeIds.filter((id: string) => id !== nodeId);

  currentScene.toolkit.setData(pluginId, { [pluginId]: filteredArray });
}

export function customSceneData(plugin: Plugin): void {
  const getCustomSceneDataHandle = plugin.scope.manage(
    plugin.vm.newFunction(
      'getData',
      (): QuickJSHandle => {
        const currentScene = getCurrentScene();
        const sceneData = currentScene.toolkit.getData(plugin.id()) as object;
        const vmCustomSceneData = newQuickjsObject(plugin, sceneData);

        return vmCustomSceneData;
      },
    ),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getData', {
    value: getCustomSceneDataHandle,
    configurable: false,
  });

  const setCustomSceneDataHandle = plugin.scope.manage(
    plugin.vm.newFunction('setData', (vmObject: QuickJSHandle): void => {
      const object = getTranslatedObject(plugin, vmObject);
      const currentScene = getCurrentScene();
      const sceneData = currentScene.toolkit.getData(plugin.id()) as object;

      currentScene.toolkit.setData(plugin.id(), { ...sceneData, ...object });
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'setData', {
    value: setCustomSceneDataHandle,
    configurable: false,
  });

  const removeCustomSceneDataHandle = plugin.scope.manage(
    plugin.vm.newFunction('removeData', (): void => {
      const currentScene = getCurrentScene();
      const sceneData = currentScene.toolkit.getData(plugin.id());

      const nodeIds = sceneData?.customNodeIds;

      currentScene.toolkit.removeData(plugin.id());

      currentScene.toolkit.setData(plugin.id(), { customNodeIds: nodeIds });
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'removeData', {
    value: removeCustomSceneDataHandle,
    configurable: false,
  });
}
