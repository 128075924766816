/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';
import './customDragOverlay.css';

export const CustomDragOverlay: React.FC = () => {
  return (
    <div>
      <div className="horizontal-line"></div>
    </div>
  );
};
