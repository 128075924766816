/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { initViewport } from '../main';
import type { Viewport } from '../viewport';

import { SceneLabel } from './SceneLabel';

import { EmptyCanvasMenu, ShapeLayerMenuCanvas } from '~/features/menu';
import useOpenable from '~/hooks/useOpenable';
import { useCreatorStore } from '~/store';

export const Canvas: React.FC = () => {
  const [, setViewport] = useState<Viewport>();
  const mount = useRef<HTMLCanvasElement>(null);
  const [isPastable] = useCreatorStore((state) => [state.ui.isPastable], shallow);

  const { close: closeContextMenu, isOpen: isContextMenuOpen, open: openContextMenu } = useOpenable(false);
  const [coord, setCoord] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (mount.current) {
      const vp = initViewport(mount.current);

      setViewport(vp);
    }
  }, []);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      event.preventDefault();

      openContextMenu();
      setCoord({ x: event.nativeEvent.clientX, y: event.nativeEvent.clientY });
    },
    [openContextMenu],
  );

  return (
    <div id="artboard-container" className="relative flex flex-1 select-none overflow-hidden">
      <canvas ref={mount} id="artboard-canvas" onContextMenu={handleContextMenu} onPointerDown={closeContextMenu} />
      {isContextMenuOpen && <EmptyCanvasMenu onClose={closeContextMenu} coord={coord} pastable={isPastable} />}
      {isContextMenuOpen && <ShapeLayerMenuCanvas onClose={closeContextMenu} coord={coord} pastable={isPastable} />}
      {<SceneLabel />}
    </div>
  );
};
