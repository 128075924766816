/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { AnimatedAnchorPoint } from './AnimatedAnchorPoint';
import { AnimatedColor } from './AnimatedColor';
import { AnimatedInnerRadius } from './AnimatedInnerRadius';
import { AnimatedInnerRoundness } from './AnimatedInnerRoundness';
import { AnimatedOpacity } from './AnimatedOpacity';
import { AnimatedOuterRadius } from './AnimatedOuterRadius';
import { AnimatedOuterRoundness } from './AnimatedOuterRoundness';
import { AnimatedPoints } from './AnimatedPoints';
import { AnimatedPosition } from './AnimatedPosition';
import { AnimatedRotation } from './AnimatedRotation';
import { AnimatedRoundness } from './AnimatedRoundness';
import { AnimatedScale } from './AnimatedScale';
import { AnimatedSize } from './AnimatedSize';
import { AnimatedStrokeWidth } from './AnimatedStrokeWidth';
import type { AnimatedInputProp } from './animatedUtil';

export const AnimatedInput: React.FC<AnimatedInputProp> = ({ animatedProp, id, parentId, parentType, type }) => {
  const inputMap: Record<string, React.ReactNode> = {
    a: <AnimatedAnchorPoint animatedProp={animatedProp} id={id} type={type} />,
    s: <AnimatedScale animatedProp={animatedProp} id={id} type={type} parentId={parentId} />,
    p: <AnimatedPosition animatedProp={animatedProp} id={id} type={type} parentId={parentId} />,
    r: <AnimatedRotation animatedProp={animatedProp} id={id} type={type} parentId={parentId} parentType={parentType} />,
    o: <AnimatedOpacity animatedProp={animatedProp} id={id} type={type} />,
    rd: <AnimatedRoundness animatedProp={animatedProp} id={id} type={type} />,
    sw: <AnimatedStrokeWidth animatedProp={animatedProp} id={id} type={type} />,
    sc: <AnimatedColor animatedProp={animatedProp} id={id} type={type} />,
    cl: <AnimatedColor animatedProp={animatedProp} id={id} type={type} />,
    sz: <AnimatedSize animatedProp={animatedProp} id={id} type={type} />,
    nt: <AnimatedPoints animatedProp={animatedProp} id={id} type={type} />,
    ir: <AnimatedInnerRadius animatedProp={animatedProp} id={id} type={type} />,
    or: <AnimatedOuterRadius animatedProp={animatedProp} id={id} type={type} />,
    os: <AnimatedOuterRoundness animatedProp={animatedProp} id={id} type={type} />,
    is: <AnimatedInnerRoundness animatedProp={animatedProp} id={id} type={type} />,
  };

  const InputView = inputMap[type] ?? null;

  return <>{InputView}</>;
};
