/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Close } from '~/assets/icons';
import { useCreatorStore } from '~/store';

export const PluginBrowserHeader: React.FC = () => {
  const setPluginBrowserOpen = useCreatorStore((state) => state.ui.setPluginBrowserOpen);

  return (
    <div id="test-animation-header" className="box-border cursor-move select-none rounded-t-md">
      <div className="box-border flex items-center justify-between rounded-t-md border-gray-700 px-4 py-2">
        <span className="h-6 w-[200px] text-test-animation-header font-bold">Plugins</span>
        <button>
          <Close
            className="h-4 w-4 cursor-pointer fill-current text-gray-500 hover:opacity-50"
            onClick={() => setPluginBrowserOpen(false)}
          />
        </button>
      </div>
    </div>
  );
};
