/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useState, useEffect } from 'react';

interface BindDragInputToProps {
  children: React.ReactNode;
  inputRef: React.RefObject<HTMLInputElement>;
  onStop: (obj: Record<string, Record<string, string>>) => void;
  setValue: (obj: Record<string, Record<string, string>>) => void;
  value: number | string;
}

export const BindDragInputTo: React.FC<BindDragInputToProps> = ({
  children,
  inputRef,
  onStop,
  setValue,
  value,
}: BindDragInputToProps) => {
  // We are creating a snapshot of the values when the drag starts
  // because the [value] will itself change & we need the original
  // [value] to calculate during a drag.
  const [snapshot, setSnapshot] = useState(value);

  // This captures the starting position of the drag and is used to
  // calculate the diff in positions of the cursor.
  const [startVal, setStartVal] = useState(0);

  const [isDragging, setDragging] = useState(false);

  const [isMouseDown, setIsMouseDown] = useState(false);

  // Start the drag to change operation when the mouse button is down.
  const onStart = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      inputRef.current?.select();
      setStartVal(event.screenX);
      setSnapshot(value);
      setIsMouseDown(true);
      setDragging(true);
    },
    [value, inputRef],
  );

  // Only change the value if the drag was actually started.
  const onUpdate = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault();
      if (!isMouseDown || !isDragging) return;

      if (startVal) {
        const offset = event.screenX - startVal;

        setValue({ target: { value: (parseInt(snapshot as string, 10) + offset).toString() } });
      }
    },
    [isDragging, isMouseDown, setValue, snapshot, startVal],
  );

  // Stop the drag operation now.
  const onEnd = useCallback(
    (event: MouseEvent): void => {
      setStartVal(0);
      if (!isMouseDown || !isDragging) return;

      if (startVal) {
        const offset = event.screenX - startVal;

        onStop({ target: { value: (parseInt(snapshot as string, 10) + offset).toString() } });
      }
      setIsMouseDown(false);
      setDragging(false);
    },
    [isDragging, isMouseDown, onStop, snapshot, startVal],
  );

  // We use document events to update and end the drag operation
  // because the mouse may not be present over the label during
  // the operation..
  useEffect(() => {
    if (isMouseDown && isDragging) {
      document.addEventListener('mousemove', onUpdate);
      document.addEventListener('mouseup', onEnd);
    } else {
      document.removeEventListener('mousemove', onUpdate);
      document.removeEventListener('mouseup', onEnd);
    }

    return () => {
      document.removeEventListener('mousemove', onUpdate);
      document.removeEventListener('mouseup', onEnd);
    };
  }, [onUpdate, onEnd, isMouseDown, isDragging]);

  return (
    <span onMouseDown={onStart} className="cursor-ew-resize">
      {children}
    </span>
  );
};
