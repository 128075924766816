/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Project } from './project';

import { LottieDropdownArrow } from '~/assets/icons';
import { useWorkspaceAPI } from '~/providers/api/workspace';
import { useCreatorStore } from '~/store';
import type { IWorkspace, IWorkspaceProject } from '~/store/projectSlice';

export interface WorkspaceProp {
  index: number;
  workspace: IWorkspace;
}

export const Workspace: React.FC<WorkspaceProp> = ({ index, workspace }: WorkspaceProp) => {
  const [selectedWorkspaceId, setSelectedWorkspaceId, token] = useCreatorStore(
    (state) => [state.project.selectedWorkspaceId, state.project.setSelectedWorkspaceId, state.user.token],
    shallow,
  );

  const { fetchWorkflowProjects } = useWorkspaceAPI(token);
  const [projects, setProjects] = useState<IWorkspaceProject[]>([]);

  const isWorkspaceSelected = Boolean(selectedWorkspaceId === workspace.id);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const selectedWorkspaceProjects = (await fetchWorkflowProjects(
          selectedWorkspaceId as string,
        )) as IWorkspaceProject[];

        setProjects(selectedWorkspaceProjects);
      } catch (error) {
        // Handle any potential errors
        // console.error('Error fetching projects:', error);
      }
    };

    if (isWorkspaceSelected) {
      fetchData();
    }
  }, [selectedWorkspaceId, fetchWorkflowProjects, isWorkspaceSelected]);

  return (
    <>
      <button
        className={clsx(`flex w-full items-center rounded p-1 hover:bg-gray-700 ${index === 0 ? '' : 'mt-3'}`, {
          'bg-gray-700': isWorkspaceSelected,
        })}
        onClick={() => setSelectedWorkspaceId(workspace.id)}
      >
        <LottieDropdownArrow
          className={clsx(
            'mr-1 flex h-3 w-3 cursor-pointer items-center justify-center rounded-layer-thumbnail stroke-current text-gray-500 hover:text-white',
            {
              '-rotate-90': !isWorkspaceSelected,
            },
          )}
        />
        <img className="h-4 w-4 rounded-full shadow-sm" src={workspace.icon} alt="profile avatar" />

        <span className="ml-1 text-caption font-bold text-white">{workspace.name}</span>
      </button>
      {isWorkspaceSelected &&
        projects.length > 0 &&
        projects.map((project: IWorkspaceProject, projectIndex: number) => {
          return <Project key={projectIndex} index={projectIndex} project={project} />;
        })}
    </>
  );
};
