/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset, Scene, SizeJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { AspectRatioToggle } from '~/components/Elements/Button/AspectRatioToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberInputOption } from '~/components/Elements/Input';
import type { NumberInputRatioResult } from '~/components/Elements/Input/useDualNumberInputRatio';
import { useDualNumberInputRatio } from '~/components/Elements/Input/useDualNumberInputRatio';
import { MAX_WIDTH, MIN_WIDTH, MAX_HEIGHT, MIN_HEIGHT } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import {
  setSceneHeight,
  setSceneWidth,
  setSceneSize,
  toolkit,
  getNodeById,
  setPrecompSize,
  setPrecompWidth,
  setPrecompHeight,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const sizeOption: { h: NumberInputOption; w: NumberInputOption } = {
  w: {
    name: 'width',
    label: 'W',
    min: MIN_WIDTH,
    max: MAX_WIDTH,
    precision: 0,
  },
  h: {
    name: 'height',
    label: 'H',
    min: MIN_HEIGHT,
    max: MAX_HEIGHT,
    precision: 0,
  },
};

export const TemplateSizeField: React.FC = () => {
  const sceneIndex = useCreatorStore((state) => state.toolkit.sceneIndex);
  const scaleRatioLocked = useCreatorStore((state) => state.ui.scaleRatioLocked);
  const setScaleRatioLocked = useCreatorStore((state) => state.ui.setScaleRatioLocked);

  const width = useCreatorStore((state) => (state.toolkit.json?.properties.sz as SizeJSON).w) as number;
  const height = useCreatorStore((state) => (state.toolkit.json?.properties.sz as SizeJSON).h) as number;
  const [currentPrecompJson, precompData] = useCreatorStore(
    (state) => [state.toolkit.selectedPrecompositionJson, state.toolkit.selectedPrecompositionJson?.data],
    shallow,
  );

  const [precompWidthVal, setPrecompWidthVal] = useState(width);
  const [precompHeightVal, setPrecompHeightVal] = useState(height);

  useEffect(() => {
    if (currentPrecompJson && precompData) {
      setPrecompWidthVal((precompData['canvasWidth'] as number) + (precompData['canvasMinWidth'] as number));
      setPrecompHeightVal((precompData['canvasHeight'] as number) + (precompData['canvasMinHeight'] as number));
    }
  }, [currentPrecompJson, precompData]);

  const handleOnChangeResult = useCallback(
    (result: NumberInputRatioResult) => {
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) return;

      if (currentPrecompJson) {
        const precompAsset = getNodeById(toolkit.scenes[sceneIndex] as Scene, currentPrecompJson.id);

        precompAsset?.setData('canvasCustom', true);
        if (result.type === 'both') {
          const value = result.value as number[];

          if (value[0] && value[1]) setPrecompSize(precompAsset as PrecompositionAsset, [value[0], value[1]]);
        } else if (result.type === sizeOption.w.name) {
          setPrecompWidth(precompAsset as PrecompositionAsset, result.value as number);
        } else {
          setPrecompHeight(precompAsset as PrecompositionAsset, result.value as number);
        }
        emitter.emit(EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED);
      } else {
        if (result.type === 'both') {
          const value = result.value as number[];

          if (value[0] && value[1]) setSceneSize(scene, [value[0], value[1]]);
        } else if (result.type === sizeOption.w.name) {
          setSceneWidth(scene, result.value as number);
        } else {
          setSceneHeight(scene, result.value as number);
        }
        emitter.emit(EmitterEvent.SCENE_SIZE_UPDATED);
      }
    },
    [currentPrecompJson, sceneIndex],
  );

  const { handleInputChange, handleOnClickAspectRatio, isLocked } = useDualNumberInputRatio({
    left:
      currentPrecompJson && precompData
        ? (precompData['canvasWidth'] as number) - (precompData['canvasMinWidth'] as number)
        : width,
    leftName: sizeOption.w.name,
    right:
      currentPrecompJson && precompData
        ? (precompData['canvasHeight'] as number) - (precompData['canvasMinHeight'] as number)
        : height,
    onChange: handleOnChangeResult,
    ratioLocked: scaleRatioLocked,
  });

  const handleOnClickAspectRatioCurried = useCallback(() => {
    handleOnClickAspectRatio();
    setScaleRatioLocked(!scaleRatioLocked);
  }, [handleOnClickAspectRatio, scaleRatioLocked, setScaleRatioLocked]);

  // Valid size range is 1-4096
  // Left is scene width, right is scene height
  return (
    <div className="mt-2 flex w-full">
      <DualNumberInput
        onChange={handleInputChange}
        left={currentPrecompJson && precompData ? precompWidthVal : width}
        right={currentPrecompJson && precompData ? precompHeightVal : height}
        leftOption={sizeOption.w}
        rightOption={sizeOption.h}
        leftMax={sizeOption.w.max || MAX_WIDTH}
        leftMin={sizeOption.w.min || MIN_WIDTH}
        rightMax={sizeOption.h.max || MAX_HEIGHT}
        rightMin={sizeOption.h.min || MIN_HEIGHT}
        testID1="template-width"
        testID2="template-height"
      />
      <Interactive>
        <AspectRatioToggle isLocked={isLocked} onClick={handleOnClickAspectRatioCurried} />
      </Interactive>
    </div>
  );
};
