/**
 * Copyright 2022 Design Barn Inc.
 */

import {
  Angle,
  Vector,
  Size,
  Color,
  ShapeType,
  GroupShape,
  RectangleShape,
  EllipseShape,
  StarShape,
  AVLayer,
  PathShape,
  Scalar,
  Percentage,
  StrokeDashType,
  GradientColor,
  GradientFillType,
  PrecompositionLayer,
} from '@lottiefiles/toolkit-js';
import type {
  ShapeLayer,
  FillShape,
  Scene,
  DagNodeJSON,
  DagNode,
  Shape,
  StrokeShape as StrokeShapeType,
  PathShape as PathShapeType,
  CubicBezierShape,
  StrokeDash,
  BlendModeType,
  FillRuleType,
  GradientFillShape,
  ColorStopJSON,
  VectorJSON,
  Asset,
} from '@lottiefiles/toolkit-js';
import type { WritableDraft } from 'immer/dist/internal';

import type { EllipseOption } from './ellipse';
import { getCurrentEllipse, createEllipse } from './ellipse';
import { getSelectedDrawOrder } from './helper';
import type { PathOption } from './path';
import { createPath } from './path';
import { createPolystar, getCurrentPolystar } from './polystar';
import type { PolystarOption, CurrentPolystar } from './polystar';
import { createRectangle, getCurrentRectangle } from './rectangle';
import type { RectangleOption, CurrentRectangle } from './rectangle';
import { toolkit } from './toolkit';
import type { Scalar2D, ColorValue } from './types';

import type { LayerUI } from '~/features/timeline';
import { GradientPoint } from '~/lib/toolkit';
import { AppearanceTypes } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant/ui';

export interface ShapeOption {
  ellipse?: EllipseOption;
  endFrame: number;
  fill?: ColorValue;
  id?: string;
  name?: string;
  path?: PathOption;
  polystar?: PolystarOption;
  position: Scalar2D;
  rectangle?: RectangleOption;
  rotation: number;
  startFrame: number;
  stroke?: ColorValue;
  type: ShapeType;
}

let shapeLayerID = 1;

export const getNodeById = (scene: Scene, id: string): DagNode | null => scene.getNodeById(id);

export const getNodeState = (node: DagNode | null): DagNodeJSON | Record<string, never> =>
  node ? structuredClone(node.state) : {};

export const getAssetByReferenceId = (scene: Scene, referenceId: string): DagNode | null => {
  const foundAsset = scene.assets.find((asset: Asset) => {
    return asset.id === referenceId;
  });

  return foundAsset || null;
};

export const setShapePosition = (node: ShapeLayer | null, position: Scalar2D): void => {
  node?.setPosition(new Vector(position[0], position[1]));
};

export const setShapeRotation = (node: ShapeLayer | null, deg: number): void => {
  node?.setRotation(new Angle(deg));
};

export const setShapeOpacity = (node: ShapeLayer | null, pct: number): void => {
  node?.setOpacity(new Percentage(pct));
};

export const setShapeSize = (node: RectangleShape | EllipseShape | null, size: Scalar2D): void => {
  node?.setSize(new Size(size[0], size[1]));
};

export const setShapePoints = (node: StarShape | null, points: [number]): void => {
  node?.setPoints(new Scalar(points[0]));
};

export const setShapeOuterRadius = (node: StarShape | null, points: [number]): void => {
  node?.setOuterRadius(new Scalar(points[0]));
};

export const setShapeOuterRoundness = (node: StarShape | null, points: [number]): void => {
  node?.setOuterRoundness(new Scalar(points[0]));
};

export const setShapeInnerRadius = (node: StarShape | null, points: [number]): void => {
  node?.setInnerRadius(new Scalar(points[0]));
};

export const setShapeInnerRoundess = (node: StarShape | null, points: [number]): void => {
  node?.setInnerRoundness(new Scalar(points[0]));
};

export const setStaticPosition = (node: GroupShape | AVLayer | null, position: Scalar2D): void => {
  node?.position.setStaticValue(new Vector(position[0], position[1]));
};

export const setStaticScale = (node: GroupShape | AVLayer | null, position: Scalar2D): void => {
  node?.scale.setStaticValue(new Vector(position[0], position[1]));
};

export const setStaticRectangleRoundness = (node: RectangleShape | null, value: [number]): void => {
  node?.roundness.setStaticValue(new Scalar(value[0]));
};
export const setStaticRotation = (node: GroupShape | AVLayer | null, deg: [number]): void => {
  node?.rotation.setStaticValue(new Angle(deg[0]));
};

export const setStaticOpacity = (node: GroupShape | AVLayer | null, opacity: [number]): void => {
  node?.opacity.setStaticValue(new Percentage(opacity[0]));
};

export const setAnimatedPosition = (node: GroupShape | AVLayer | null, position: Scalar2D): void => {
  if (node?.transform) {
    node.transform.setPosition(new Vector(position[0], position[1]));
  } else {
    node?.setPosition(new Vector(position[0], position[1]));
  }
};

export const setAnimatedScale = (node: GroupShape | AVLayer | null, position: Scalar2D): void => {
  node?.transform.setScale(new Vector(position[0], position[1]));
};

export const setAnimatedOpacity = (node: GroupShape | AVLayer | FillShape | null, opacity: [number]): void => {
  if (node?.transform) {
    node.transform.setOpacity(new Percentage(opacity[0]));
  } else {
    // Fill Color Opacity
    node?.setOpacity(new Percentage(opacity[0]));
  }
};

export const setAnimatedRectangleRoundness = (node: RectangleShape | null, value: [number]): void => {
  node?.setRoundness(new Scalar(value[0]));
};
export const setAnimatedRotation = (node: GroupShape | AVLayer | StarShape | null, deg: [number]): void => {
  if (node?.rotation) {
    node.setRotation(new Angle(deg[0]));
  } else {
    node?.transform.setRotation(new Angle(deg[0]));
  }
};

export const setIsAnimatedPositionShape = (node: ShapeLayer | AVLayer | null, enable: boolean): void => {
  node?.position.setIsAnimated(enable);
};

export const setIsAnimatedPosition = (node: GroupShape | AVLayer | null, enable: boolean): void => {
  if (node?.transform) {
    node.transform.position.setIsAnimated(enable);
  } else {
    node?.position.setIsAnimated(enable);
  }
};

export const setIsAnimatedScale = (node: GroupShape | AVLayer | null, enable: boolean): void => {
  node?.transform.scale.setIsAnimated(enable);
};

export const setIsAnimatedOpacity = (node: GroupShape | AVLayer | null, enable: boolean): void => {
  if (node?.transform) {
    node.transform.opacity.setIsAnimated(enable);
  } else {
    // Fill Color Opacity
    node?.opacity.setIsAnimated(enable);
  }
};

export const setIsAnimatedRectangleRoundness = (node: RectangleShape | null, status: boolean): void => {
  node?.roundness.setIsAnimated(status);
};
export const setIsAnimatedRotation = (node: GroupShape | AVLayer | StarShape | null, enable: boolean): void => {
  if (node?.rotation) {
    node.rotation.setIsAnimated(enable);
  } else {
    node?.transform.rotation.setIsAnimated(enable);
  }
};

export const setIsAnimatedSize = (node: RectangleShape | null, enable: boolean): void => {
  node?.size.setIsAnimated(enable);
};

export const setIsAnimatedPoints = (node: StarShape | null, enable: boolean): void => {
  node?.numPoints.setIsAnimated(enable);
};

export const setIsAnimatedOuterRadius = (node: StarShape | null, enable: boolean): void => {
  node?.outerRadius.setIsAnimated(enable);
};

export const setIsAnimatedOuterRoundness = (node: StarShape | null, enable: boolean): void => {
  node?.outerRoundness.setIsAnimated(enable);
};

export const setIsAnimatedInnerRadius = (node: StarShape | null, enable: boolean): void => {
  node?.innerRadius.setIsAnimated(enable);
};

export const setIsAnimatedInnerRoundness = (node: StarShape | null, enable: boolean): void => {
  node?.innerRoundness.setIsAnimated(enable);
};

export const setIsAnimatedFillColor = (fl: FillShape | null, enable: boolean): void => {
  fl?.color.setIsAnimated(enable);
};

export const setIsAnimatedFillOpacity = (fl: FillShape | null, enable: boolean): void => {
  fl?.opacity.setIsAnimated(enable);
};

export const setIsAnimatedStrokeOpacity = (st: StrokeShapeType | null, enable: boolean): void => {
  st?.opacity.setIsAnimated(enable);
};

export const setIsAnimatedStrokeMiter = (st: StrokeShapeType | null, enable: boolean): void => {
  st?.miterLimit.setIsAnimated(enable);
};

export const setIsAnimatedStrokeWidth = (st: StrokeShapeType | null, enable: boolean): void => {
  st?.strokeWidth.setIsAnimated(enable);
};

export const setIsAnimatedStrokeColor = (st: StrokeShapeType | null, enable: boolean): void => {
  st?.color.setIsAnimated(enable);
};

export const setIsAnimatedPath = (ps: PathShapeType | null, enable: boolean): void => {
  ps?.shape.setIsAnimated(enable);
};

export const setStaticPivot = (node: GroupShape | AVLayer | null, pivot: Scalar2D): void => {
  const is3DNode = node?.transform.position.valueAtKeyFrame(0)?.is3D;
  const vec = is3DNode ? new Vector(pivot[0], pivot[1], 0) : new Vector(pivot[0], pivot[1]);

  node?.setPivot(vec);
};

export const setStaticFillColor = (fl: FillShape | null, color: ColorValue): void => {
  fl?.color.setStaticValue(new Color(color[0], color[1], color[2]));
};

export const setAnimatedFillColor = (node: StrokeShapeType | FillShape | null, color: ColorValue): void => {
  node?.setColor(new Color(color[0], color[1], color[2]));
};

export const setStaticFillOpacity = (fl: FillShape | null, opacity: [number]): void => {
  fl?.opacity.setStaticValue(new Percentage(opacity[0]));
};

export const setAnimatedFillOpacity = (fl: FillShape | null, opacity: [number]): void => {
  fl?.setOpacity(new Percentage(opacity[0]));
};

export const setStaticStrokeOpacity = (st: StrokeShapeType | null, opacity: [number]): void => {
  st?.opacity.setStaticValue(new Percentage(opacity[0]));
};

export const setStaticStrokeWidth = (st: StrokeShapeType | null, strokeWidth: [number]): void => {
  st?.strokeWidth.setStaticValue(new Scalar(strokeWidth[0]));
};

export const setStaticStrokeMiter = (st: StrokeShapeType | null, miterLimit: [number]): void => {
  st?.miterLimit.setStaticValue(new Scalar(miterLimit[0]));
};

export const setStaticStrokeColor = (st: StrokeShapeType | null, color: ColorValue): void => {
  st?.color.setStaticValue(new Color(color[0], color[1], color[2]));
};

export const setStaticPath = (ps: PathShapeType | null, value: [CubicBezierShape]): void => {
  ps?.shape.setStaticValue(value[0] as CubicBezierShape);
};

export const setAnimatedStrokeOpacity = (st: StrokeShapeType | null, opacity: [number]): void => {
  st?.setOpacity(new Percentage(opacity[0]));
};
export const setAnimatedStrokeWidth = (st: StrokeShapeType | null, strokeWidth: [number]): void => {
  st?.setStrokeWidth(new Scalar(strokeWidth[0]));
};
export const setAnimatedStrokeMiter = (st: StrokeShapeType | null, miterLimit: [number]): void => {
  st?.setMiterLimit(new Scalar(miterLimit[0]));
};

export const setAnimatedStrokeColor = (st: StrokeShapeType | null, color: ColorValue): void => {
  st?.setColor(new Color(color[0], color[1], color[2]));
};

export const setAnimatedPath = (ps: PathShapeType | null, value: [CubicBezierShape]): void => {
  ps?.shape.setValue(value[0]);
};

export const setStrokeWidth = (st: StrokeShapeType | null, width: number): void => {
  st?.setStrokeWidth(new Scalar(width));
};

export const setStrokeColor = (st: StrokeShapeType | null, color: ColorValue): void => {
  st?.setColor(new Color(color[0], color[1], color[2], color[3]));
};

export const setStrokeLineJoin = (st: StrokeShapeType | null, lineJoin: number): void => {
  st?.setLineJoinType(lineJoin);
};

export const setStrokeLineCap = (st: StrokeShapeType | null, lineCap: number): void => {
  st?.setLineCapType(lineCap);
};

export const setStrokeMiter = (st: StrokeShapeType | null, miter: number): void => {
  st?.setMiterLimit(new Scalar(miter));
};

export const setStrokeOpacity = (st: StrokeShapeType | null, opacity: number): void => {
  st?.setOpacity(new Percentage(opacity));
};

export const addStrokeDash = (st: StrokeShapeType | null, type: StrokeDashType): void => {
  if (st) {
    const newStroke = st.createStrokeDash();

    newStroke.setStrokeDashLength(new Scalar(5));

    if (type === StrokeDashType.Dash) newStroke.setName('Dash');
    else if (type === StrokeDashType.Offset) newStroke.setName('Offset');
    else newStroke.setName('Gap');

    newStroke.setStrokeDashType(type);
  }
};

export const removeStrokeDash = (st: StrokeShapeType | null, id: string): void => {
  if (st) {
    const strokeDash = st.getNodeById(id) as StrokeDash;

    st.removeChild(strokeDash);
  }
};

export const setStrokeDash = (st: StrokeShapeType | null, dashInput: Record<string, number | string>): void => {
  if (st) {
    const { id, value } = dashInput;
    const strokeDash = st.getNodeById(id as string) as StrokeDash;

    strokeDash.setStrokeDashLength(new Scalar(value as number));
  }
};

export const setBlendMode = (node: Shape | null, value: BlendModeType): void => {
  node?.setBlendMode(value);
};

export const setFillRule = (node: GradientFillShape | FillShape | null, value: FillRuleType): void => {
  node?.setFillRule(value);
};

export const setNodePosition = (node: AVLayer, position: Scalar2D): void => {
  if (node.nodeType === 'Shape') {
    setShapePosition(node as ShapeLayer, position);
  } else {
    setAnimatedPosition(node as AVLayer, position);
  }
};

export const addAppearance = (node: GroupShape, value: string): void => {
  let groupShape = null;

  if (node.type === 'SHAPE') {
    // In simplified layer's Single Layer, all appearances applied to shape's node, instead of ShapeLayer's node
    const getLayerSimplifiedUI = useCreatorStore.getState().ui.getLayerSimplifiedUI;
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

    const belongToGroupNodeId = getLayerSimplifiedUI(node.nodeId)?.belongToGroupNodeId;

    if (belongToGroupNodeId) {
      groupShape = getNodeByIdOnly(belongToGroupNodeId);
    }

    // TODO: Handle for Group
  } else {
    groupShape = node as GroupShape;
  }

  if (!groupShape) return;

  if (value === ShapeType.FILL) {
    groupShape.createFillShape().setColor(new Color(255, 255, 255, 1)).setName('Fill').setOpacity(new Percentage(100));
  } else if (value === ShapeType.STROKE) {
    groupShape.createStrokeShape().setColor(new Color(24, 30, 125, 1)).setStrokeWidth(new Scalar(10)).setName('Stroke');
  } else if (value === ShapeType.GRADIENT_FILL) {
    groupShape
      .createGradientFillShape()
      .setName('Gradient Fill')
      .setGradientType(GradientFillType.LINEAR)
      .setOpacity(new Percentage(100))
      .setStartPoint(new Vector(150, 0))
      .setEndPoint(new Vector(350, 300))
      .setGradient(
        new GradientColor().addColor(new Color(96, 93, 93, 1), 0).addColor(new Color(255, 255, 255, 1), 100),
      );
  }
};

export const setGradientOpacity = (gf: GradientFillShape | null, opacity: number): void => {
  gf?.setOpacity(new Percentage(opacity));
};

export const setGradientPoint = (gf: GradientFillShape | null, name: string, value: number): void => {
  if (!gf) return;
  if (name === GradientPoint.StartX) {
    const currentY = (gf.state.animatedProperties.gs.value as VectorJSON).y;

    gf.setStartPoint(new Vector(value, currentY));
  } else if (name === GradientPoint.StartY) {
    const currentX = (gf.state.animatedProperties.gs.value as VectorJSON).x;

    gf.setStartPoint(new Vector(currentX, value));
  } else if (name === GradientPoint.EndX) {
    const currentY = (gf.state.animatedProperties.ge.value as VectorJSON).y;

    gf.setEndPoint(new Vector(value, currentY));
  } else if (name === GradientPoint.EndY) {
    const currentX = (gf.state.animatedProperties.ge.value as VectorJSON).x;

    gf.setEndPoint(new Vector(currentX, value));
  }
};

export const setGradient = (node: GradientFillShape | null, data: ColorStopJSON[]): void => {
  // TODO: Integrate with Toolkit
  if (data.length > 0) {
    const newGradient = new GradientColor();

    data.forEach((cs: ColorStopJSON) => {
      // eslint-disable-next-line id-length
      const { a, b, g, offset, r } = cs;

      newGradient.addColor(new Color(r, g, b, a), offset as number);
    });

    node?.setGradient(newGradient);
  }
};

// createShape will create a Shape Layer, then a GroupShape Layer, then a Shape Layer
// ShapeLayer -> GroupShapeLayer -> ShapeLayer
export const createShape = (scene: Scene, option: ShapeOption): void => {
  const { endFrame, fill, id, name, position, startFrame, stroke, type } = option;
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
  const setSelectedPrecompositionId = useCreatorStore.getState().toolkit.setSelectedPrecompositionId;

  let precompNodeScene = null as Scene | null;

  if (selectedPrecompositionId) {
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const toolkitScene = toolkit.scenes[sceneIndex] as Scene | null;

    precompNodeScene = getNodeById(toolkitScene as Scene, selectedPrecompositionId as string) as Scene;
  }

  // Check if any shape is already selected.
  // If selected use selected index, and update drawOrder on newly created object.
  const newDrawOrder = getSelectedDrawOrder();

  const selectedScene = precompNodeScene ? precompNodeScene : scene;

  const shapeLayer = (selectedScene as Scene)
    .createShapeLayer()
    .setStartAndEndFrame(startFrame, endFrame)
    .setName(`Shape Layer ${shapeLayerID}`);

  if (newDrawOrder !== null) {
    shapeLayer.setDrawOrder(newDrawOrder);
  }

  const groupShape = shapeLayer.createGroupShape().setPosition(new Vector(position[0], position[1]));

  let shape;

  switch (type) {
    case ShapeType.RECTANGLE: {
      shape = createRectangle(scene, groupShape, option.rectangle);
      break;
    }

    case ShapeType.ELLIPSE: {
      shape = createEllipse(scene, groupShape, option.ellipse);
      break;
    }

    case ShapeType.STAR: {
      shape = createPolystar(scene, groupShape, option.polystar);
      break;
    }

    case ShapeType.POLYGON: {
      shape = createPolystar(scene, groupShape, option.polystar);
      shape.setName('Polygon');
      break;
    }

    case ShapeType.PATH: {
      shape = createPath(scene, groupShape, option.path);
      break;
    }

    default:
      break;
  }

  if (shape) {
    if (id) {
      shape.setId(id);
    }
    if (name) {
      // TODO: temporary set name to group shape
      groupShape.setName('Group');

      // groupShape.setName(`${name} ${shapeLayerID}`);
      (groupShape.parent as ShapeLayer).setStartTime(0);
      shape.setName(`${name} Path ${shapeLayerID}`);
    }
  }

  // Create Fill Shape
  if (fill) {
    groupShape.createFillShape().setColor(new Color(fill[0], fill[1], fill[2], fill[3])).setName('Fill');
  }

  if (stroke) {
    groupShape
      .createStrokeShape()
      .setStrokeWidth(new Scalar(10))
      .setColor(new Color(stroke[0], stroke[1], stroke[2], stroke[3]))
      .setName('Stroke');
  }

  shapeLayerID += 1;

  if (selectedPrecompositionId) {
    setSelectedPrecompositionId(selectedPrecompositionId);
  }

  const setSelectedIdAfterCreated = useCreatorStore.getState().ui.setSelectedIdAfterCreated;

  setSelectedIdAfterCreated(shapeLayer.nodeId);
};

export const isShapeType = (type: ShapeType): boolean =>
  type === ShapeType.RECTANGLE || type === ShapeType.ELLIPSE || type === ShapeType.STAR || type === ShapeType.PATH;

export const findShape = (shapes: Shape[]): Shape | undefined => shapes.find((sh) => isShapeType(sh.type));

export const getPropertyPanelType = (scene: Scene, id: string): PropertyPanelType => {
  let panelType: PropertyPanelType;

  panelType = PropertyPanelType.Composition;

  if (id) {
    const node = scene.getNodeById(id);

    if (node) {
      const nodeType = (node as Shape).type;

      if (node instanceof PrecompositionLayer) {
        panelType = PropertyPanelType.Precomposition;
      } else if (node instanceof AVLayer) {
        panelType = PropertyPanelType.ShapeLayer;
      } else if (node instanceof GroupShape) {
        panelType = PropertyPanelType.Group;
      } else if (node instanceof PathShape) {
        panelType = PropertyPanelType.EditPath;
      } else if (AppearanceTypes.includes(nodeType)) {
        if (nodeType === ShapeType.STROKE) {
          panelType = PropertyPanelType.Stroke;
        } else if (nodeType === ShapeType.FILL) {
          panelType = PropertyPanelType.Fill;
        } else if (nodeType === ShapeType.GRADIENT_FILL) {
          panelType = PropertyPanelType.GradientFill;
        }
      } else if (nodeType === ShapeType.RECTANGLE) panelType = PropertyPanelType.RectanglePath;
      else if (nodeType === ShapeType.ELLIPSE) panelType = PropertyPanelType.EllipsePath;
      else if (nodeType === ShapeType.STAR) {
        if (node.name.toLowerCase().includes('polygon')) panelType = PropertyPanelType.PolygonPath;
        else if (node.name.toLowerCase().includes('star')) panelType = PropertyPanelType.StarPath;
      }
    }
  }

  return panelType;
};

export interface CurrentShape {
  path?: PathOption;
  polystar: Omit<CurrentPolystar, 'position' | 'positionCurrentKeyFrame'>;
  position: Scalar2D;
  positionCurrentKeyFrame: string;
  rect: Omit<CurrentRectangle, 'position' | 'positionCurrentKeyFrame' | 'size' | 'sizeCurrentKeyFrame'>;
  size: Scalar2D;
  sizeCurrentKeyFrame: string;
}

export const defaultCurrentShape: CurrentShape = {
  position: [0, 0],
  positionCurrentKeyFrame: '',
  size: [0, 0],
  sizeCurrentKeyFrame: '',
  rect: { roundness: 0, roundnessCurrentKeyFrame: '' },
  polystar: {
    type: 1,
    innerRadius: 0,
    innerRadiusCurrentKeyFrame: '',
    outerRadius: 0,
    outerRadiusCurrentKeyFrame: '',
    innerRoundness: 0,
    innerRoundnessCurrentKeyFrame: '',
    outerRoundness: 0,
    outerRoundnessCurrentKeyFrame: '',
    rotation: 0,
    points: 0,
    pointsCurrentKeyFrame: '',
  },
};

export const getCurrentShape = (node: DagNode | null): CurrentShape => {
  const currentShape: CurrentShape = structuredClone(defaultCurrentShape);

  if (!node) return currentShape;
  let shapeNode: Shape | undefined;

  if (node instanceof GroupShape) {
    shapeNode = findShape(node.shapes);
  } else if (
    (node as Shape).type === ShapeType.RECTANGLE ||
    (node as Shape).type === ShapeType.ELLIPSE ||
    (node as Shape).type === ShapeType.STAR ||
    (node as Shape).type === ShapeType.POLYGON
  ) {
    shapeNode = node as Shape;
  }

  if (shapeNode) {
    if (shapeNode instanceof RectangleShape) {
      const {
        position,
        positionCurrentKeyFrame,
        roundness,
        roundnessCurrentKeyFrame,
        size,
        sizeCurrentKeyFrame,
      } = getCurrentRectangle(shapeNode);

      currentShape.position = position;
      currentShape.positionCurrentKeyFrame = positionCurrentKeyFrame;
      currentShape.size = size;
      currentShape.sizeCurrentKeyFrame = sizeCurrentKeyFrame;
      currentShape.rect.roundness = roundness;
      currentShape.rect.roundnessCurrentKeyFrame = roundnessCurrentKeyFrame;
    } else if (shapeNode instanceof EllipseShape) {
      const { position, positionCurrentKeyFrame, size, sizeCurrentKeyFrame } = getCurrentEllipse(shapeNode);

      currentShape.position = position;
      currentShape.positionCurrentKeyFrame = positionCurrentKeyFrame;
      currentShape.size = size;
      currentShape.sizeCurrentKeyFrame = sizeCurrentKeyFrame;
    } else if (shapeNode instanceof StarShape) {
      const { position, positionCurrentKeyFrame, ...rst } = getCurrentPolystar(shapeNode);

      currentShape.position = position;
      currentShape.positionCurrentKeyFrame = positionCurrentKeyFrame;
      currentShape.polystar = { ...rst };
    }
  }

  return currentShape;
};

export const removeNode = (scene: Scene, id: string): void => {
  if (id) {
    const node = scene.getNodeById(id);

    if (node instanceof AVLayer) {
      // TODO Need to check the use of exciseLayer
      node.exciseLayer();
    } else {
      node?.removeFromGraph();
    }
  }
};

export const updateLayerHighlight = (
  layerMap: Map<string, WritableDraft<WritableDraft<LayerUI>>>,
  id: string,
): void => {
  const newLayerUI = layerMap.get(id);

  if (newLayerUI) {
    newLayerUI.highlight = true;

    // Highlight all descendant child
    for (const descendantID of newLayerUI.descendant) {
      const descendantLayerUI = layerMap.get(descendantID);

      if (descendantLayerUI) {
        descendantLayerUI.highlight = true;
      }
    }

    // Expand all ancestor/parents for selected ID with parents
    for (const parentID of newLayerUI.parent) {
      const parentLayerUI = layerMap.get(parentID);

      if (parentLayerUI) {
        parentLayerUI.expanded = true;
      }
    }
  }
};

export const removeLayerHighlight = (
  layerMap: Map<string, WritableDraft<WritableDraft<LayerUI>>>,
  id: string,
): void => {
  const oldLayerUI = layerMap.get(id);

  if (oldLayerUI) {
    oldLayerUI.highlight = false;

    for (const descedantID of oldLayerUI.descendant) {
      const descendantLayerUI = layerMap.get(descedantID);

      if (descendantLayerUI) {
        descendantLayerUI.highlight = false;
      }
    }
  }
};
