/**
 * Copyright 2022 Design Barn Inc.
 */

import type { RectangleShape, ScalarJSON, Scene } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedRoundness as AnimatedRoundnessIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setAnimatedRectangleRoundness, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedRoundness: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const animatedValue = animatedProp.value as ScalarJSON | null;
  const roundness = animatedValue?.value ?? 0;

  const [sceneIndex] = useCreatorStore((state) => [state.toolkit.sceneIndex], shallow);

  const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id);
  const rect = node?.parent as RectangleShape;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setAnimatedRectangleRoundness(rect, [result.value]);
      emitter.emit(EmitterEvent.RECT_ROUNDNESS_UPDATED);
    },
    [rect],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="roundness"
        onChange={handleOnChange}
        value={roundness}
        label={<AnimatedRoundnessIcon className="h-3 w-3" />}
        styleClass={styleClass}
        min={0}
        max={Math.min(rect.size.value.width, rect.size.value.height) / 2}
      />
    </div>
  );
};
