/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useState, useCallback, useEffect } from 'react';
import Draggable from 'react-draggable';

import { TestAnimationHeader } from './TestAnimationHeader';
import type { DotLottiePlayer } from './TestAnimationPlayer';
import { TestAnimationPlayer } from './TestAnimationPlayer';
import { TestAnimationSubHeader } from './TestAnimationSubHeader';

import { Divider } from '~/features/property-panel';
import { exportToolkitJson, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const useExportLottie = (setLottieJSON: React.Dispatch<unknown>): { exportJSON: () => void } => {
  const sceneIndex = useCreatorStore((state) => state.toolkit.sceneIndex);
  const scene = toolkit.scenes[sceneIndex];

  const exportJSON = useCallback(async (): Promise<void> => {
    if (scene) {
      const lottie = await exportToolkitJson(toolkit, scene);

      setLottieJSON(lottie);
    }
  }, [scene, setLottieJSON]);

  return { exportJSON };
};

export const TestAnimationDialog: React.FC = () => {
  const nodeRef = React.useRef(null);
  const testAnimationOpen = useCreatorStore((state) => state.ui.testAnimationOpen);
  const [lottieJSON, setLottieJSON] = useState<unknown>('');
  const [playerVersion, setPlayerVersion] = useState<string>('');

  const { exportJSON } = useExportLottie(setLottieJSON);

  // Re-export JSON when reset is clicked
  const handleOnReset = useCallback(() => {
    exportJSON();
  }, [exportJSON]);

  useEffect(() => {
    if (testAnimationOpen) {
      exportJSON();
    }
  }, [testAnimationOpen, exportJSON]);

  const getPlayerVersion = useCallback((player: DotLottiePlayer) => {
    setPlayerVersion(player.getVersions().dotLottiePlayerVersion);
  }, []);

  return (
    <>
      {testAnimationOpen && (
        <Draggable nodeRef={nodeRef} bounds="parent" handle="#test-animation-header">
          <div
            ref={nodeRef}
            className="absolute right-[256px] top-[16px] z-test-animation max-h-[680px] w-[340px] rounded-md bg-gray-800 text-white"
          >
            <TestAnimationHeader />
            <Divider />
            <TestAnimationSubHeader playerVersion={playerVersion} onReset={handleOnReset} />
            <TestAnimationPlayer
              getPlayerVersion={getPlayerVersion}
              json={(lottieJSON as unknown) as Record<string, never>}
            />
          </div>
        </Draggable>
      )}
    </>
  );
};
