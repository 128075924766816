/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedIntegerProperty, StarShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PolystarPoints } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MAX_POLYSTAR_POINTS, MIN_POLYSTAR_POINTS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PolystarPointsField: React.FC = () => {
  const [currentShapeNode, points, setPoints, setPolystarVariableIsAnimated, currentKeyFrame] = useCreatorStore(
    (state) => [
      state.toolkit.currentSecondary?.currentShapeNode,
      state.toolkit.currentSecondary?.currentShape.polystar.points,
      state.toolkit.setPolystarPoints,
      state.toolkit.setPolystarVariableIsAnimated,
      state.toolkit.currentSecondary?.currentShape.polystar.pointsCurrentKeyFrame,
    ],
    shallow,
  );

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setPoints(Math.round(result.value));
      emitter.emit(EmitterEvent.POLYSTAR_POINTS_UPDATED);
    },
    [setPoints],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyFrame === '') {
      setPolystarVariableIsAnimated('points', true);
      setPoints(Math.round(points));
    } else if ((currentShapeNode as StarShape).numPoints.keyFrames.length === 1) {
      setPolystarVariableIsAnimated('points', false);
      setPoints(Math.round(points));
    } else {
      removeKeyFrame(currentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_POINTS_UPDATED);
    stateHistory.endAction();
  }, [setPoints, setPolystarVariableIsAnimated, currentShapeNode, currentKeyFrame, points]);

  return (
    <div className="w-full">
      <NumberInput
        name="points"
        label={<PolystarPoints className="h-4 w-4" />}
        value={
          ((currentShapeNode as StarShape | null)?.numPoints as AnimatedIntegerProperty | null)?.value.value ?? points
        }
        onChange={handleOnChange}
        showKeyframe
        min={MIN_POLYSTAR_POINTS}
        max={MAX_POLYSTAR_POINTS}
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={Boolean(currentKeyFrame)}
      />
    </div>
  );
};
