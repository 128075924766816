/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Mesh, OrthographicCamera, Scene, WebGLRenderer } from 'three';
import { Vector3, Group, MeshStandardMaterial } from 'three';
import { SelectionHelper } from 'three/examples/jsm/interactive/SelectionHelper';

import { getMouseCoord } from './mouse';
import { getParentLayer } from './three';

import { RaycasterLayers } from '~/features/canvas';
import type { CMesh } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import SelectionBox from '~/lib/threejs/SelectionBox';
import type { TransformControls } from '~/lib/threejs/TransformControls';

// eslint-disable-next-line func-names
SelectionHelper.prototype.onSelectOver = function () {
  if (this.element.parentElement) this.element.parentElement.removeChild(this.element);
};

export default class DragSelector {
  public allSelected: Mesh[] = [];

  public container: HTMLCanvasElement;

  public disable = false;

  public drag = 0;

  public readonly helper: SelectionHelper;

  public mousedown = false;

  public multiSelect = true;

  public scene: Scene;

  public selectedGroup: Group = new Group();

  public readonly selectionBox: SelectionBox;

  public transformControl: TransformControls;

  public constructor(
    camera: OrthographicCamera,
    scene: Scene,
    renderer: WebGLRenderer,
    container: HTMLCanvasElement,
    transformControl: TransformControls,
  ) {
    this.container = container;
    this.scene = scene;
    this.transformControl = transformControl;
    this.selectionBox = new SelectionBox(camera, scene);
    scene.add(this.selectedGroup);

    this.helper = new SelectionHelper(renderer, 'selectBox');
    this.setHelperVisibility(true);
    this.addEventListeners();
  }

  public addEventListeners(): void {
    const group = new Group();

    this.scene.add(group);

    this.container.addEventListener('pointermove', (event: MouseEvent) => {
      // only left mouse button click event should be fired
      if (event.buttons !== 1 || this.disable || !this.mousedown) {
        this.setHelperVisibility(false);

        return;
      }
      this.drag += 1;
      if (this.helper.isDown && !this.transformControl.visible) {
        const mouse = getMouseCoord(event, this.container);

        this.selectionBox.endPoint.set(mouse.x, mouse.y, 0.5);

        this.allSelected = this.selectionBox.select();
      }
    });
    this.container.addEventListener('pointerup', (event: MouseEvent) => {
      if (this.disable || this.drag < 5 || this.transformControl.visible) {
        this.mousedown = false;
        this.drag = 0;

        return;
      }
      this.mousedown = false;
      this.drag = 0;

      const mouse = getMouseCoord(event, this.container);

      this.selectionBox.endPoint.set(mouse.x, mouse.y, 0.5);

      this.allSelected = this.selectionBox.select();
      this.allSelected.sort((prev, curr) => {
        const prevPos = new Vector3();
        const currPos = new Vector3();

        prev.getWorldPosition(prevPos);
        curr.getWorldPosition(currPos);

        return prevPos.z - currPos.z;
      });
      if (this.multiSelect && this.allSelected.length > 1) {
        const layerObjectIds = [
          ...new Set(
            this.allSelected
              .map((selected) => getParentLayer(selected as CMesh, RaycasterLayers.CLayer)?.toolkitId)
              .filter(Boolean),
          ),
        ];

        if (layerObjectIds.length > 0) {
          emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
            event: EmitterEvent.CANVAS_SELECT_OBJECT_MULTIPLE,
            data: layerObjectIds,
          });
        }
      } else {
        const mesh = this.allSelected[0];

        if (mesh) {
          const layerObject = getParentLayer(mesh as CMesh, RaycasterLayers.CLayer);

          if (layerObject)
            emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
              event: EmitterEvent.CANVAS_SELECT_OBJECT,
              data: [layerObject.toolkitId],
            });
        }
      }
    });
  }

  public onPointerDown(event: MouseEvent): void {
    // only left mouse button click event should be fired
    if (event.buttons !== 1 || this.disable) {
      this.setHelperVisibility(false);

      return;
    }
    this.drag = 0;
    this.mousedown = true;
    if (this.transformControl.visible) {
      this.setHelperVisibility(false);

      return;
    }
    this.setHelperVisibility(true);
    const mouse = getMouseCoord(event, this.container);

    this.selectionBox.startPoint.set(mouse.x, mouse.y, 0.5);
    for (const item of this.selectionBox.collection) {
      if (item.material instanceof MeshStandardMaterial) item.material.emissive.set(0xaaaaaa);
    }
  }

  public setHelperVisibility(visible: boolean): void {
    this.helper.element.style.visibility = visible ? 'visible' : 'hidden';
  }
}
