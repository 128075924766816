/**
 * Copyright 2022 Design Barn Inc.
 */

import type { TreeLineState } from './layer-helper';
import { getAnimatedTreeLines, getLineLevelToDraw, getTreeLines } from './layer-helper';

import { useCreatorStore } from '~/store';

// useTreeLines hooks compute the treelines information to be render based on the current layer node information
// treeLines: treelines information for non-animated layer row
// animatedTreeLines: treelines information for animated properties row
export const useTreeLines = (
  parent: string[],
  hasChildren: boolean,
  isLastChild: boolean,
  level: number,
  hasAnimated: boolean,
): { animatedTreeLines: TreeLineState[]; treeLineLevel: number[]; treeLines: TreeLineState[] } => {
  const getMap = useCreatorStore((state) => state.ui.getLayerUI);

  // isParentLastList store the flag of whether the parent is the last child of the list from top to bottom
  // e.g. [great-grandParent, grandparent, parent] = [true, false, true]
  const isParentLastList = parent.map((node) => Boolean(getMap(node)?.last));

  // Get TreeLines to render
  const treeLineLevel = getLineLevelToDraw(isParentLastList);
  // Get the tree lines state array to render
  const treeLines = getTreeLines({ hasChildren, treeLineLevel, isLastChild, level, parent });
  let animatedTreeLines: TreeLineState[] = [];

  if (hasAnimated) {
    // Only process animated tree lines if there is animated property in current layer
    animatedTreeLines = getAnimatedTreeLines({ hasChildren, treeLineLevel, isLastChild, level, parent });
  }

  return { treeLines, treeLineLevel, animatedTreeLines };
};
