/**
 * Copyright 2023 Design Barn Inc.
 */

import { BufferAttribute, BufferGeometry, Line, LineBasicMaterial, Vector3 } from 'three';

import { CObject3D } from '~/features/canvas';

const pi = Math.PI;
const X_PLUS_AXIS_ = new Vector3(1, 0, 0);
const X_MINUS_AXIS = new Vector3(-1, 0, 0);
const NEAR_LIMIT = 20;

export class SnapHelper {
  public helperLine: Line;

  public root = new CObject3D();

  public shiftDown = false;

  public constructor() {
    this.helperLine = this.buildHelperObjects();
    this.helperLine.visible = false;
    this.helperLine.frustumCulled = false;
    this.helperLine.renderOrder = Infinity;
    this.root.add(this.helperLine);
  }

  public buildHelperObjects = (): Line => {
    const lineGeometry = new BufferGeometry();

    // attributes (3 vertices per point)
    const positions = new Float32Array(2 * 3);

    lineGeometry.setAttribute('position', new BufferAttribute(positions, 3));

    // material
    const lineMaterial = new LineBasicMaterial({
      color: 0xff0000,
    });

    return new Line(lineGeometry, lineMaterial);
  };

  public setShiftDown(shiftDown: boolean): void {
    this.shiftDown = shiftDown;
  }

  public translationSnap(
    object: CObject3D,
    start: Vector3,
    startWorld: Vector3,
    offset: Vector3,
    worldPosition: Vector3,
  ): void {
    const snappedOffset = new Vector3().copy(offset);
    const angleToHorizon = offset.x > 0 ? offset.angleTo(X_PLUS_AXIS_) : offset.angleTo(X_MINUS_AXIS);

    const isNear = offset.length() < NEAR_LIMIT;

    this.updateLine(0, startWorld);

    if (angleToHorizon < (isNear ? pi / 4 : pi / 6)) {
      snappedOffset.y = 0;
    } else if (angleToHorizon < pi / 4 && !isNear) {
      snappedOffset.y = Math.abs(snappedOffset.x) * Math.sign(snappedOffset.y);
    } else if (angleToHorizon < pi / 3 && !isNear) {
      snappedOffset.x = Math.abs(snappedOffset.y) * Math.sign(snappedOffset.x);
    } else {
      snappedOffset.x = 0;
    }
    object.position.copy(snappedOffset).add(start);

    this.updateLine(1, worldPosition);
    this.helperLine.visible = this.shiftDown;
  }

  public translationSnapEnd(): void {
    this.helperLine.visible = false;
  }

  public translationSnapStart(objectPosition: Vector3): void {
    if (!this.shiftDown) return;
    this.helperLine.visible = true;
    this.updateLine(0, objectPosition);
    this.updateLine(1, objectPosition);
  }

  public updateLine(index: number, position: Vector3): void {
    const positions = this.helperLine.geometry.getAttribute('position') as BufferAttribute;

    positions.setXYZ(index, position.x, position.y, position.z);
    positions.needsUpdate = true;
  }
}
