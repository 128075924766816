/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode, Shape } from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import { difference } from 'lodash-es';
import type { StoreApi } from 'zustand';

import type { CurrentFillShape, CurrentStrokeShape, CurrentGFillShape } from '.';
import type { LayerUIMap } from '~/features/timeline';
import type { StoreSlice } from '~/store';
import { PropertyPanelType } from '~/store/constant';

export type Appearance<T> = Record<string, T>;

export const AppearanceTypes = [ShapeType.FILL, ShapeType.STROKE, ShapeType.GRADIENT_FILL];
export const AppearancePropertyPanel = [
  PropertyPanelType.Fill,
  PropertyPanelType.Stroke,
  PropertyPanelType.GradientFill,
];
export const AppearanceTypeMapping: Record<string, string> = {
  [PropertyPanelType.Fill]: ShapeType.FILL,
  [PropertyPanelType.Stroke]: ShapeType.STROKE,
  [PropertyPanelType.GradientFill]: ShapeType.GRADIENT_FILL,
};

export const AppearanceOptions = [
  {
    label: 'Fill color',
    type: ShapeType.FILL,
  },
  {
    label: 'Stroke',
    type: ShapeType.STROKE,
  },
  // TODO(Guorong)
  // {
  //   label: 'Gradient Fill',
  //   type: ShapeType.GRADIENT_FILL,
  // },
];

export type AppStroke = Appearance<CurrentStrokeShape>;
export type AppFill = Appearance<CurrentFillShape>;
export type AppGFill = Appearance<CurrentGFillShape>;

export interface CurrentFillShapeProp {
  currentFillShapes: AppFill;
  currentGFillShapes: AppGFill;
  currentStrokeShapes: AppStroke;
}

export const defaultCurrentAppearance: CurrentFillShapeProp = {
  currentFillShapes: {},
  currentStrokeShapes: {},
  currentGFillShapes: {},
};

export const getCurrentAppearance = (
  get: StoreApi<StoreSlice>['getState'],
  appearanceList: Record<string, string>,
): CurrentFillShapeProp => {
  const currentFillShapes: AppFill = {};
  const currentStrokeShapes: AppStroke = {};
  const currentGFillShapes: AppGFill = {};

  if (Object.keys(appearanceList).length > 0) {
    const getShape = get().toolkit.getShape;

    for (const [id, type] of Object.entries(appearanceList)) {
      if (type === ShapeType.FILL) {
        const otherFill = getShape(id, type as ShapeType) as CurrentFillShape;

        currentFillShapes[id] = otherFill;
      } else if (type === ShapeType.STROKE) {
        const otherStroke = getShape(id, type as ShapeType) as CurrentStrokeShape;

        currentStrokeShapes[id] = otherStroke;
      } else if (type === ShapeType.GRADIENT_FILL) {
        const otherGFill = getShape(id, type as ShapeType) as CurrentGFillShape;

        currentGFillShapes[id] = otherGFill;
      }
    }

    return {
      currentFillShapes,
      currentStrokeShapes,
      currentGFillShapes,
    };
  }

  return defaultCurrentAppearance;
};

export const getAppearanceList = (
  get: StoreApi<StoreSlice>['getState'],
  node: DagNode | null,
  layerMap?: LayerUIMap,
): [Record<string, string>, string[]] => {
  const updateDescedantLayer = (
    descendants: string[],
    appearances: Record<string, string>,
    newLayerMap: LayerUIMap,
  ): void => {
    descendants.forEach((descendantId: string) => {
      const descedantLayer = newLayerMap.get(descendantId);

      if (descedantLayer && descedantLayer.isAppearance) {
        const { appearanceType } = descedantLayer;

        appearances[descendantId] = appearanceType as string;
      }
    });
  };
  const appearances: Record<string, string> = {};

  const newLayerMap = layerMap ? layerMap : get().ui.layerMap;

  let diff: string[] = [];

  let group = node;
  const type = (group as Shape | null)?.type;

  if (
    type === ShapeType.RECTANGLE ||
    type === ShapeType.ELLIPSE ||
    type === ShapeType.STAR ||
    type === ShapeType.POLYGON
  ) {
    group = node?.parent as DagNode;
  }
  if (group) {
    const nodeLayer = newLayerMap.get(group.nodeId);

    if (layerMap && nodeLayer) {
      // Get new added appearance ids
      const oldNodeLayer = get().ui.layerMap.get(group.nodeId);

      if (oldNodeLayer) {
        diff = difference(nodeLayer.descendant, oldNodeLayer.descendant);
      }
    }

    if (nodeLayer) {
      const { appearanceType } = nodeLayer;

      if (nodeLayer.isAppearance && AppearanceTypes.includes(appearanceType as ShapeType)) {
        appearances[group.nodeId] = appearanceType as string;
      } else if (nodeLayer.descendant.length > 0) {
        updateDescedantLayer(nodeLayer.descendant, appearances, newLayerMap);
      }
    }
  }

  return [{ ...appearances }, diff];
};

export const getAppearanceListFromNodes = (
  get: StoreApi<StoreSlice>['getState'],
  nodes: DagNode[],
  layerMap?: LayerUIMap,
): [Record<string, string>, string[]] => {
  let appearanceList = {};
  let newAdded: string[] = [];

  nodes.forEach((node) => {
    const [list, added] = getAppearanceList(get, node, layerMap);

    appearanceList = { ...appearanceList, ...list };
    newAdded = [...newAdded, ...added];
  });

  return [appearanceList, newAdded];
};
