/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { PolystarInnerRadiusField } from './field/PolystarInnerRadiusField';
import { PolystarInnerRoundnessField } from './field/PolystarInnerRoundnessField';
import { PolystarOuterRadiusField } from './field/PolystarOuterRadiusField';
import { PolystarOuterRoundnessField } from './field/PolystarOuterRoundnessField';
import { PolystarPointsField } from './field/PolystarPointsField';
import { PolystarRotationField } from './field/PolystarRotationField';
// import { ShapePositionField } from './field/ShapePositionField';

export const StarProperty: React.FC = () => {
  return (
    <div>
      {/* <ShapePositionField /> */}

      <div className="mr-9 mt-2 flex space-x-2">
        <PolystarRotationField />
      </div>

      <div className="mr-9 mt-2 flex space-x-2">
        <PolystarInnerRadiusField />
        <PolystarOuterRadiusField isStar />
      </div>

      <div className="mr-9 mt-2 flex space-x-2">
        <PolystarInnerRoundnessField />
        <PolystarOuterRoundnessField />
      </div>
      <div className="mr-32 mt-2 flex">
        <PolystarPointsField />
      </div>
    </div>
  );
};
