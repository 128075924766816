/**
 * Copyright 2022 Design Barn Inc.
 */

import './_colorpicker.css';
import { NAMED_COLORS } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import { ColorMode } from '../ColorModeAccordionControl';
import { PopupTabs, PopupTabsBody } from '../PopupTab';

import Gradient from './Gradient';
import Solid from './Solid';
import type { IPropsMain } from './types';

const DEFAULT_COLORS: string[] = [];

NAMED_COLORS.forEach((key) => {
  DEFAULT_COLORS.push(`#${key}`);
});

const DEFAULT_GRADIENT_COLORS = [
  'linear-gradient(0deg, rgb(255, 177, 153) 0%, rgb(255, 8, 68) 100%)',
  'linear-gradient(270deg, rgb(251, 171, 126) 8.00%, rgb(247, 206, 104) 92.00%)',
  'linear-gradient(315deg, rgb(150, 230, 161) 8.00%, rgb(212, 252, 121) 92.00%)',
  'linear-gradient(to left, rgb(249, 240, 71) 0%, rgb(15, 216, 80) 100%)',
  'linear-gradient(315deg, rgb(194, 233, 251) 8.00%, rgb(161, 196, 253) 92.00%)',
  'linear-gradient(0deg, rgb(0, 198, 251) 0%, rgb(0, 91, 234) 100%)',
  'linear-gradient(0deg, rgb(167, 166, 203) 0%, rgb(137, 137, 186) 51.00%, rgb(137, 137, 186) 100%)',
  'linear-gradient(0deg, rgb(80, 82, 133) 0%, rgb(88, 94, 146) 15.0%, rgb(101, 104, 159) 28.00%, rgb(116, 116, 176) 43.00%, rgb(126, 126, 187) 57.00%, rgb(131, 137, 199) 71.00%, rgb(151, 149, 212) 82.00%, rgb(162, 161, 220) 92.00%, rgb(181, 174, 228) 100%)',
  'linear-gradient(270deg, rgb(255, 126, 179) 0%, rgb(255, 117, 140) 100%)',
  'linear-gradient(90deg, rgb(120, 115, 245) 0%, rgb(236, 119, 171) 100%)',
  'linear-gradient(45deg, #2e266f 0.00%, #9664dd38 100.00%)',
  'radial-gradient(circle at center, yellow 0%, #009966 50%, purple 100%)',
];

const ColorPickerProperties: React.FC<IPropsMain> = ({
  color = '#ffffff',
  opacity,
  format = 'rgb',
  colorMode = ColorMode.Solid,
  debounceMS = 300,
  popupWidth = 248,
  colorBoardHeight = 120,
  defaultColors = DEFAULT_COLORS,
  defaultGradientColors = DEFAULT_GRADIENT_COLORS,
  onChangeColor = () => ({}),
  onStopColor,
  onChangeOpacity,
}) => {
  const onChangeSolid = useCallback(
    (solidValue: string): void => {
      onChangeColor(solidValue);
    },
    [onChangeColor],
  );

  const onChangeGradient = useCallback(
    (gradientValue: string): void => {
      onChangeColor(gradientValue);
    },
    [onChangeColor],
  );

  return (
    <>
      <PopupTabs popupWidth={popupWidth}>
        <PopupTabsBody>
          {colorMode === ColorMode.Solid ? (
            <Solid
              onChangeColor={onChangeSolid}
              onChangeOpacity={onChangeOpacity}
              color={color}
              opacity={opacity}
              format={format}
              defaultColors={defaultColors}
              debounceMS={debounceMS}
              colorBoardHeight={colorBoardHeight}
              onStopColor={onStopColor}
            />
          ) : (
            <Gradient
              onChangeColor={onChangeGradient}
              onChangeOpacity={onChangeOpacity}
              color={color}
              opacity={opacity}
              format={format}
              defaultColors={defaultGradientColors}
              debounceMS={debounceMS}
              colorBoardHeight={colorBoardHeight}
              onStopColor={onStopColor}
            />
          )}
        </PopupTabsBody>
      </PopupTabs>
    </>
  );
};

export default ColorPickerProperties;
