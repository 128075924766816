/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Size as ToolkitSize } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from '.';

const methodNames = {
  is2D: 'is2D',
  is3D: 'is3D',
  clone: 'clone',
  equals: 'equals',
  interpolate: 'interpolate',
  larger: 'larger',
  set: 'set',
  setDepth: 'setDepth',
  setHeight: 'setHeight',
  setWidth: 'setWidth',
  smaller: 'smaller',
  toArray: 'toArray',
  // toJSON: 'toJSON',
  toObject: 'toObject',
  toString: 'toString',
};

function getSizeMethods(plugin: Plugin, size: ToolkitSize): ObjectMethods {
  return getObjectMethods(plugin, methodNames, size);
}

interface SizeObject extends ValueObject {
  depth: number;
  height: number;
  width: number;
}

export function createSizeObject(plugin: Plugin, size: ToolkitSize): SizeObject {
  const sizeObject = {
    depth: size.depth,
    height: size.height,
    width: size.width,
    valueType: 'Size',
  };

  registerObjectMethods(plugin, size, sizeObject, [getSizeMethods]);

  return sizeObject;
}
