/**
 * Copyright 2023 Design Barn Inc.
 */

import type { RoundedCornersShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { registerObjectMethods, getObjectMethods } from '../vmInterface/wrapper';

import { getDrawableShapeMethods } from './drawable-shape';
import { getShapeMethods } from './shape';

const methodNames = {
  apply: 'apply',
  roundness: 'roundness',
  setRoundness: 'setRoundness',
};

function getRoundedShapeMethods(plugin: Plugin, shape: RoundedCornersShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, shape);
}

export function createRoundedCornersShapeObject(plugin: Plugin, shape: RoundedCornersShape): object {
  const roundedShapeObj = {
    nodeId: shape.nodeId,
    nodeType: shape.nodeType,
    type: shape.type,
  };

  registerObjectMethods(plugin, shape, roundedShapeObj, [
    getNodeMethods,
    getDrawableShapeMethods,
    getShapeMethods,
    getRoundedShapeMethods,
  ]);

  return roundedShapeObj;
}
