/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { NormalLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapperLayers } from './ContextMenuWrapperLayers';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  isOpen: boolean;
  onClose: () => void;
}

export const NormalLayerMenu: React.FC<ShapeLayerMenuProps> = ({ coord, isOpen, onClose }) => {
  if (NormalLayerMenuList[3] && window.editor) NormalLayerMenuList[3].disabled = !window.editor.isPastable();

  return (
    <ContextMenuWrapperLayers
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      dropdownItems={NormalLayerMenuList}
    ></ContextMenuWrapperLayers>
  );
};
