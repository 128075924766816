/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';

interface UseDisclosureProps {
  defaultIsOpen?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

interface UseDisclosureReturnType {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onToggle: () => void;
}

// Inspired by Chakra-ui's use-disclosure
// https://chakra-ui.com/docs/hooks/use-disclosure
export function useDisclosure(props: UseDisclosureProps = {}): UseDisclosureReturnType {
  const { onClose: onCloseProp, onOpen: onOpenProp } = props;

  const [isOpenState, setIsOpen] = React.useState(props.defaultIsOpen || false);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    onCloseProp?.();
  }, [onCloseProp]);

  const onOpen = React.useCallback(() => {
    setIsOpen(true);

    onOpenProp?.();
  }, [onOpenProp]);

  const onToggle = React.useCallback(() => {
    const action = isOpenState ? onClose : onOpen;

    action();
  }, [isOpenState, onOpen, onClose]);

  return {
    isOpen: Boolean(isOpenState),
    onOpen,
    onClose,
    onToggle,
  };
}
