/**
 * Copyright 2021 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { ComingSoon, PluginFailed } from '~/assets/icons';
import { GB_CONSTANT } from '~/config/growthbook';
import { getPluginManager, PluginUIType } from '~/plugins';
import { useCreatorStore } from '~/store';

export const MOTION_PRESETS_PLUGIN_ID = '68953250-9471-4b9d-896d-ff2ef56a6f40';

export const PresetPanel: React.FC = () => {
  const motionPresetsEnabled = useFeatureIsOn(GB_CONSTANT.SHOW_MOTION_PRESETS_PLUGIN);

  useEffect(() => {
    if (motionPresetsEnabled) {
      getPluginManager()
        .load(MOTION_PRESETS_PLUGIN_ID, {
          hasToolbarShortcut: false,
          uiType: PluginUIType.PresetsPanelUI,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [motionPresetsEnabled]);

  const loadedPlugins = useCreatorStore((state) => state.plugins.loadedPlugins);
  const currentPlugin = loadedPlugins.find((plugin) => plugin.id === MOTION_PRESETS_PLUGIN_ID);
  const isLoading = currentPlugin?.loading;
  const isFailed = currentPlugin?.failed;

  useEffect(() => {
    if (!isLoading) {
      const plugin = getPluginManager().getPlugin(MOTION_PRESETS_PLUGIN_ID);

      setTimeout(() => {
        plugin?.dispatchMessage({ action: 'showSidebar' });
      }, 0);
    }
  }, [isLoading]);

  return (
    <>
      {!motionPresetsEnabled && (
        <div className="mt-[100px] w-full p-5 text-center">
          <div className="mb-4 flex justify-center">
            <ComingSoon className="w-[124px]" />
          </div>
          <div className="text-[10px] font-bold text-gray-300">Coming soon: Presets</div>
          <div className="mt-1.5 px-1 text-center text-[10px] text-gray-300">
            Our animation presets will save you a whole lot of design time.
          </div>
        </div>
      )}
      {motionPresetsEnabled && (
        <div className="flex h-full w-full flex-col" id="presets-panel">
          {isLoading && !isFailed && (
            <div className="flex grow flex-col justify-center">
              <Player renderer="svg" autoplay loop className="h-20 w-20" src={LoadingAnimation} />
            </div>
          )}
          {isFailed && !isLoading && (
            <div className="flex grow flex-col justify-center">
              <div className="mx-auto w-[124px]">
                <PluginFailed className="w-[115px]" />
              </div>
              <div className="mx-auto w-[185px] ">
                <div className="mt-4 text-center text-[10px] font-bold text-gray-300">Couldn{"'"}t load presets</div>
                <div className="mt-2 text-center text-[10px] text-gray-300">
                  Please try again, or contact our{' '}
                  <a
                    target="_blank"
                    href="https://help.lottiefiles.com/hc/en-us/requests/new"
                    className="text-teal-500"
                    rel="noreferrer"
                  >
                    customer support team.
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
