/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import React, { useCallback, useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { TimelineTopbarChevronDown, CompTimelineTab, Close } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { SceneNameType } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

const TimelineTab: React.FC<{
  isActive?: boolean;
  name: string | JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onCloseClick?: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ isActive = false, name, onClick, onCloseClick }) => {
  const [hoverState, setHoverState] = useState(false);

  return (
    <div
      className={`relative flex h-[26px] items-center self-end rounded-t-lg ${
        isActive ? 'bg-gray-700' : 'cursor-pointer'
      } pl-4 pr-6`}
      onClick={onClick}
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
    >
      <div className="flex items-center ">
        <div className="flex">
          <CompTimelineTab className="mx-auto h-4 w-4 text-gray-400" />
          <span className="ml-1 text-caption font-normal">{name}</span>
        </div>
        {name !== 'current' && hoverState && (
          <div onClick={onCloseClick} className="z-10 ">
            {name !== SceneNameType.MainScene && (
              <Close className=" absolute right-[7px] top-[7px] z-10  h-3 w-3 cursor-pointer text-gray-400" />
            )}
          </div>
        )}
      </div>
      {isActive && (
        <div>
          <svg
            className="absolute -left-4 bottom-0 h-4 w-4"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 16H0C11.9 16 15.625 5.33333 16 0V16Z" fill="#2B343B" />
          </svg>
          <svg
            className="absolute -right-4 bottom-0 h-4 w-4"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 16V0C0 11.9 10.6667 15.625 16 16H0Z" fill="#2B343B" />
          </svg>
        </div>
      )}
    </div>
  );
};

const TimelineCollapseButton: React.FC = () => {
  const [tooltipProp, setTooltipProp] = useState({ label: '', placement: 'top' as Placement });
  const [visible, setVisible] = useCreatorStore(
    (state) => [state.timeline.visible, state.timeline.setVisible],
    shallow,
  );

  const handleOnToggle = useCallback((): void => {
    setVisible(!visible);
  }, [visible, setVisible]);

  useEffect(() => {
    if (visible) {
      setTooltipProp({
        label: 'Minimize',
        placement: 'bottom' as Placement,
      });
    } else {
      setTooltipProp({
        label: 'Expand',
        placement: 'top' as Placement,
      });
    }
  }, [visible]);

  return (
    <Tooltip bgColorClass="bg-[#000000]" offset={{ mainAxis: 7, crossAxis: 15 }} {...tooltipProp}>
      <button className="z-tooltip h-full" onClick={handleOnToggle}>
        <TimelineTopbarChevronDown
          className={clsx('mx-3 h-4 w-4 cursor-pointer', {
            'rotate-180': !visible,
          })}
        />
      </button>
    </Tooltip>
  );
};

const TimelineTopbar: React.FC = () => {
  const [
    json,
    selectedPrecompositionId,
    setSelectedPrecompositionId,
    removeSelectedNodes,
    setCurrentFrame,
    selectedPrecompositionJson,
    setTabState,
    hiddenTabs,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.json || null,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.setSelectedPrecompositionId,
      state.ui.removeSelectedNodes,
      state.toolkit.setCurrentFrame,
      state.toolkit.selectedPrecompositionJson,
      state.timeline.setTabState,
      state.timeline.hiddenTabs,
    ],
    shallow,
  );

  return (
    <div className="flex min-h-[28px] items-center">
      <TimelineCollapseButton />
      <TimelineTab
        name={SceneNameType.MainScene}
        isActive={selectedPrecompositionId === null}
        onClick={() => {
          setSelectedPrecompositionId(null);
          setCurrentFrame(0);
          removeSelectedNodes();

          emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
          useCreatorStore.getState().ui.resetLayerUI();
          emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
          emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
            event: EmitterEvent.CANVAS_ADJUST_CAMERA,
            data: { size: json?.properties.sz },
          });
          emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
            event: EmitterEvent.CANVAS_ADJUST,
            data: { size: json?.properties.sz },
          });
        }}
      />
      {json &&
        json.assets.map(
          (asset) =>
            asset.type === 'PRECOMPOSITION' &&
            !hiddenTabs.includes(asset.properties.ln as string) && (
              <TimelineTab
                key={asset.id}
                name={asset.properties.ln as string}
                isActive={
                  (selectedPrecompositionJson &&
                    selectedPrecompositionJson.properties.ln === asset.properties.ln) as boolean
                }
                onClick={() => {
                  if (asset.id !== selectedPrecompositionId) {
                    setSelectedPrecompositionId(asset.id);
                    setCurrentFrame(0);
                    removeSelectedNodes();

                    emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
                    useCreatorStore.getState().ui.resetLayerUI();
                    emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
                  }
                }}
                onCloseClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setTabState(asset.properties.ln as string, false);
                  if (selectedPrecompositionId === asset.id || selectedPrecompositionId === asset.properties.ln) {
                    setSelectedPrecompositionId(null);
                    setCurrentFrame(0);
                    removeSelectedNodes();

                    emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
                    useCreatorStore.getState().ui.resetLayerUI();
                    emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
                  }
                }}
              />
            ),
        )}
    </div>
  );
};

export default TimelineTopbar;
