/**
 * Copyright 2023 Design Barn Inc.
 */

// TODO: add TS types
export const BASE_EVENTS = [
  'addChild',
  'removeChild',
  'setPropertyValue',
  'updateProperty',
  'addKeyFrame',
  'removeKeyFrame',
  'updateKeyFrame',
  'render',
  'addScene',
  'removeScene',
  'updateDuration',
  'updateDrawOrder',
  'updateCollectionOrder',
  'batch',
  'updateAsset',
  'updateKeyFrameAttribute',
  'updateValue',
  'updateNodeId',
  'updateData',
];

// TODO: remove 'updateSelectedNode' when 'updateSelectedNodes' has been implemented
export const CREATOR_EVENTS = ['updateSelectedNode', 'updateSelectedNodes'];
