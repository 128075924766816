/**
 * Copyright 2021 Design Barn Inc.
 */

import { FloatingDelayGroup } from '@floating-ui/react-dom-interactions';
import React from 'react';

import { Library } from './Library';

const TopSidebar: React.FC = () => {
  return (
    <div>
      <div className="ml-[8px]">
        <Library />
      </div>
    </div>
  );
};

export const Sidebar: React.FC = () => {
  return (
    <div className="h-full dark:bg-gray-800">
      <div className="relative box-border flex h-full w-[40px] select-none flex-col">
        <FloatingDelayGroup delay={{ open: 2000, close: 200 }}>
          <TopSidebar />
        </FloatingDelayGroup>
      </div>
    </div>
  );
};
