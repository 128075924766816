/**
 * Copyright 2023 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../../Plugin';
import { getCurrentScene } from '../utils';

import { BASE_EVENTS, CREATOR_EVENTS } from './event-types';

import { useCreatorStore } from '~/store';

export function removeZustandListeners(zustandListeners: Array<() => void>): void {
  for (const listener of zustandListeners) {
    listener();
  }
}

function evaluateCode(plugin: Plugin, callback: string): void {
  plugin.vm.evalCode(`(${callback}())()`);
}

// TODO: properly handle toolkit events
function handleToolkitEvent(plugin: Plugin, event: string, callback: string): void {
  const currentScene = getCurrentScene();

  currentScene.addEventListener(event, () => evaluateCode(plugin, callback));
}

// TODO: handle other Creator UI events
function handleCreatorEvent(plugin: Plugin, event: string, callback: string): void {
  // TODO: remove after 'updateSelectedNodes' has been implemented
  if (event === 'updateSelectedNode') {
    const updateSelectedNode = useCreatorStore.subscribe(
      (state) => state.ui.selectedNodesInfo[0]?.nodeId as string,
      () => evaluateCode(plugin, callback),
    );

    plugin.zustandListeners.push(updateSelectedNode);
  }

  if (event === 'updateSelectedNodes') {
    const updateSelectedNodes = useCreatorStore.subscribe(
      (state) => state.ui.selectedNodesInfo,
      () => evaluateCode(plugin, callback),
    );

    plugin.zustandListeners.push(updateSelectedNodes);
  }
}

// TOFIX: doesn't work if is wrapped in `creator.onMessage = function (message: any) {...}`
// as 'message' won't be accessible
export function addEventListener(plugin: Plugin): void {
  const addEventListenerHandle = plugin.scope.manage(
    plugin.vm.newFunction('addEventListener', (vmEvent: QuickJSHandle, vmCallback: QuickJSHandle) => {
      const event = plugin.vm.getString(vmEvent);
      const callback = plugin.vm.getString(vmCallback);

      if (BASE_EVENTS.includes(event)) {
        handleToolkitEvent(plugin, event, callback);
      } else if (CREATOR_EVENTS.includes(event)) {
        handleCreatorEvent(plugin, event, callback);
      }
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'addEventListener', {
    value: addEventListenerHandle,
    configurable: false,
  });
}

// TODO:
// event listener that attaches to a node
// toolkit event listener to be removed on plugin exit
