/**
 * Copyright 2021 Design Barn Inc.
 */

import { FloatingDelayGroup } from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { Help } from './Help';
import { Notification } from './Notification';
import { Profile } from './Profile';
import { Toolbar } from './Toolbar';

import packageJson from '~/../package.json';
import { Preview, PreviewActive, ProjectStatusSaving, ProjectStatusUnsaved, ProjectStatusSaved } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { OLVY_WHATSNEW_WIDGET, OLVY_WORKSPACE } from '~/config';
import { CreatorMenu, PenMenu } from '~/features/menu';
import { Insert } from '~/features/upload';
import { saveToWorkSpace } from '~/lib/function/menu';
import { useUsersnapApi } from '~/providers/UsersnapProvider';
import { useCreatorStore } from '~/store';
import { SavingState } from '~/store/projectSlice';
import { waitForElm } from '~/utils';

const Report: React.FC = () => {
  const usersnapApi = useUsersnapApi();

  const handleClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).open();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).hideButton();
  }, [usersnapApi]);

  return (
    <button className="h-6 w-[86px] text-xs text-white" onClick={handleClick}>
      Report a bug
    </button>
  );
};

const ExportToWrokspace: React.FC = () => {
  return (
    <button className="h-6 w-[53px] rounded bg-[#00C1A2] text-xs text-white" onClick={saveToWorkSpace}>
      Export
    </button>
  );
};

const PreviewLottie: React.FC = () => {
  const [testAnimationOpen, setTestAnimationOpen] = useCreatorStore(
    (state) => [state.ui.testAnimationOpen, state.ui.setTestAnimationOpen],
    shallow,
  );

  const handleOnClickPreview = useCallback(() => {
    setTestAnimationOpen(!testAnimationOpen);
  }, [testAnimationOpen, setTestAnimationOpen]);

  return (
    <div className="flex h-8 flex-row items-center justify-center">
      <Tooltip offset={{ mainAxis: 7, crossAxis: 5 }} label="Preview in player" placement="bottom">
        <button
          className={clsx('group flex h-6 w-6 cursor-pointer items-center justify-center rounded', {
            'hover:bg-gray-700': !testAnimationOpen,
          })}
          onClick={handleOnClickPreview}
        >
          {React.cloneElement(testAnimationOpen ? <PreviewActive /> : <Preview />, {
            className: clsx('h-4 w-4 cursor-pointer text-gray-400 group-hover:text-white', {
              'text-teal-100': testAnimationOpen,
            }),
          })}
        </button>
      </Tooltip>
    </div>
  );
};

const AlphaBadge: React.FC = () => {
  // Handle Olvy
  const handleWhatsNew = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (typeof window.OlvyUtils !== 'undefined' && window.OlvyUtils.showWidget) {
      await waitForElm(`olvy-modal-${OLVY_WHATSNEW_WIDGET}`);
      window.OlvyUtils.showWidget(OLVY_WORKSPACE, OLVY_WHATSNEW_WIDGET);
    }
  }, []);

  return (
    <button
      onClick={handleWhatsNew}
      className="flex h-[19px] items-center rounded-[10px] bg-gray-700 px-3 text-[12px] text-white"
    >
      Beta {packageJson.version}
    </button>
  );
};

const RightMost: React.FC = () => {
  return (
    <div className="flex h-8 flex-row items-center justify-center">
      <Help />
      <Notification notifications={[]} />
      <Profile />
    </div>
  );
};

const HeaderLeft: React.FC = () => {
  return (
    <div className="mr-auto flex h-[24px] flex-1 items-center">
      <CreatorMenu />
      <div id="InsertContainer" className="mr-2 mt-1">
        <Insert />
      </div>
      <Toolbar />
      <div className="mr-2 mt-1">
        <PenMenu />
      </div>
    </div>
  );
};

const HeaderRight: React.FC = () => {
  return (
    <div className="ml-auto flex h-[24px] flex-1 items-center justify-end">
      <div className="flex items-center">
        <AlphaBadge />
        <div className="ml-2 h-8" />
        <Report />
        <div className="ml-2 h-8" />
        <ExportToWrokspace />
        <div className="ml-2 h-8" />
        <PreviewLottie />
        <div className="ml-2 h-8" />
        <RightMost />
      </div>
    </div>
  );
};

const HeaderCenter: React.FC = () => {
  const [savingState, projectName] = useCreatorStore(
    (state) => [state.project.info.savingState, state.project.info.name],
    shallow,
  );

  const iconStyle = 'h-4 w-4 fill-current text-gray-400';

  return (
    <div className="flex h-[24px] flex-1 items-center justify-center">
      {savingState === SavingState.UNSAVED && <ProjectStatusUnsaved className={iconStyle} />}
      {savingState === SavingState.SAVING && <ProjectStatusSaving className={iconStyle} />}
      {savingState === SavingState.SAVED && <ProjectStatusSaved className={`${iconStyle} text-teal-300`} />}
      <a data-testid="project-name" className="ml-1.5 text-xs text-gray-200">
        {projectName}
      </a>
    </div>
  );
};

export const Header: React.FC = () => {
  return (
    <header className="h-[40px]">
      <FloatingDelayGroup delay={{ open: 2000, close: 200 }}>
        <nav className="flex px-2 py-[8px]">
          <HeaderLeft />
          <HeaderCenter />
          <HeaderRight />
        </nav>
      </FloatingDelayGroup>
    </header>
  );
};
