/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ColorJSON } from '@lottiefiles/toolkit-js';
import { isNil } from 'lodash-es';
import { BoxGeometry, Color, Vector2 } from 'three';

import { CMesh } from '../../types/object';
import { shapeMaterial } from '../threeFactory';

import type { RectangleProperty } from './type';

import { RaycasterLayers } from '~/features/canvas';
import { ShapeTypes } from '~/store/constant';

export const RectangleSettings: RectangleProperty = {
  radius: { value: 2 },
  positionX: { value: 0 },
  positionY: { value: 0 },
  width: { value: 20 },
  height: { value: 20 },
  color: { r: 255, g: 255, b: 255 } as ColorJSON,
  drawOrder: 0,
};

export const getRectangle = (settings: RectangleProperty = RectangleSettings): CMesh => {
  const {
    color,
    drawOrder,
    height,
    positionX: { value: px },
    positionY: { value: py },
    radius,
    width,
  } = settings;
  const initialWidth = width.value;
  const initialHeight = height.value;

  const geometry = new BoxGeometry(width.value, height.value, 1, 20, 20, 1);
  const material = shapeMaterial.clone();

  const rectangle = new CMesh(geometry, material);

  rectangle.customUniforms.boxSize = {
    value: new Vector2(width.value, height.value).multiplyScalar(0.5),
  };
  rectangle.customUniforms.radius = radius;
  rectangle.customUniforms.width = width;
  rectangle.customUniforms.height = height;
  rectangle.customUniforms.initialWidth = { value: initialWidth };
  rectangle.customUniforms.initialHeight = { value: initialHeight };
  rectangle.customUniforms.width = width;

  rectangle.customChunks.vertexShader = [
    `uniform vec2 boxSize;
    uniform float radius;
    uniform float width;
    uniform float height;
    uniform float initialWidth;
    uniform float initialHeight;`,
    `float maxRadius = clamp(radius, 0.0, min(width / 2.0, height / 2.0));
    vec2 signs = sign(position.xy);
    
    vec2 subBox = boxSize - vec2(maxRadius);
    
    vec2 absPos = abs(transformed.xy); 
    // xy
    vec2 sub = absPos.xy - subBox.xy;
    if (absPos.x > subBox.x && absPos.y > subBox.y) {
      transformed.xy = normalize(sub) * maxRadius + subBox.xy;
      transformed.xy *= signs.xy;
    }
    
    // corner
    if (all(greaterThan(absPos, subBox))){
      vec2 sub2 = absPos - subBox;
      transformed.xy = (normalize(sub2) * maxRadius + subBox) * signs;
    }
    
    if(absPos.x >= subBox.x && absPos.y <= subBox.y) {
      transformed.x += signs.x * (width - initialWidth) / 2.0;
      transformed.y *= height / initialHeight;
    } else if(absPos.y >= subBox.y && absPos.x <= subBox.x) {
      transformed.y += signs.y * (height - initialHeight) / 2.0;
      transformed.x *= width / initialWidth;
    } else if(absPos.x > subBox.x && absPos.y > subBox.y){
      transformed.xy += signs.xy * vec2((width - initialWidth) / 2.0, (height - initialHeight) / 2.0);
    } else {
      transformed.xy += position.xy * vec2(width / initialWidth - 1.0, height / initialHeight - 1.0);
    }`,
  ];

  rectangle.position.set(px, py, 0);
  rectangle.shapeType = ShapeTypes.Square;

  rectangle.layers.enable(RaycasterLayers.CMesh);
  const { b, g, r } = color;

  rectangle.material.color = new Color(`rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)})`);
  if (!isNil(drawOrder)) {
    rectangle.drawOrder = drawOrder;
    rectangle.position.setZ(drawOrder);
  }

  return rectangle;
};
