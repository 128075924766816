/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PanelButton } from '../../Elements/Button/BarButton';

import { Hand, HandActive, Pointer, PointerActive } from '~/assets/icons';
import { ToolType } from '~/data/constant';
import { useCreatorStore } from '~/store';

const ToolbarList = [
  {
    type: ToolType.Move,
    activeIcon: <PointerActive />,
    inactiveIcon: <Pointer />,
    tooltipProps: {
      label: 'Move',
      placement: 'bottom' as Placement,
      shortcut: 'V',
    },
  },
  {
    type: ToolType.Hand,
    activeIcon: <HandActive />,
    inactiveIcon: <Hand />,
    tooltipProps: {
      label: 'Hand tool',
      placement: 'bottom' as Placement,
      shortcut: 'H',
    },
  },
];

export const Toolbar: React.FC = () => {
  const [currentTool, setCurrentTool] = useCreatorStore(
    (state) => [state.ui.currentTool, state.ui.setCurrentTool],
    shallow,
  );

  const handleOnClick: (type: ToolType) => void = useCallback(
    (type) => {
      setCurrentTool(type);
    },
    [setCurrentTool],
  );

  return (
    <div className="flex space-x-2">
      {ToolbarList.map((tool) => (
        <PanelButton
          key={tool.type}
          activeIcon={tool.activeIcon}
          inactiveIcon={tool.inactiveIcon}
          tooltipProps={tool.tooltipProps}
          isSelected={currentTool === tool.type}
          onClick={() => handleOnClick(tool.type)}
        />
      ))}
    </div>
  );
};
