/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import { colord } from 'colord';
import type { FC } from 'react';
import React, { useCallback, Fragment, useState, useMemo } from 'react';
import tinycolor from 'tinycolor2';

import { defaultStyle, NumberInput } from '../../Input';
import { checkFormat, hexToRgba, rgbaToHex } from '../utils';

import { getAlphaValue, onlyLatins, handlePressEnter } from './helpers';

import { CaretDown } from '~/assets/icons';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';

const colorOptions = ['HEX', 'RGB'];

const Divider: React.FC = () => <div className="h-[28px] w-[1px] bg-gray-600"></div>;

export interface IChange {
  alpha: number;
  hex: string;
}

interface TProps {
  alpha: number;
  format?: 'rgb' | 'hsl' | 'hex';
  hex: string;
  onChange: ({ alpha, hex }: IChange) => void;
  onSubmitChange?: (rgba: string) => void;
  showAlpha?: boolean;
}

const InputRgba: FC<TProps> = ({ alpha, format = 'rgb', hex, onChange, onSubmitChange, showAlpha = true }) => {
  const [colorMode, setColorMode] = useState<'HEX' | 'RGB'>('HEX');

  const onHandleSubmit = useCallback(
    (newColor?: string, newAlpha?: number): void => {
      const newHex = newColor ?? hex;
      const newOpacity = newAlpha ?? alpha;
      const rgba = tinycolor(newHex);

      rgba.setAlpha(Number(newOpacity) / 100);

      if (newOpacity !== alpha || newHex !== hex) {
        onChange({
          hex: newHex,
          alpha: Number(newOpacity),
        });
        if (onSubmitChange) {
          onSubmitChange(checkFormat(rgba.toRgbString(), format, showAlpha, newOpacity));
        }
      } else {
        onChange({
          hex,
          alpha,
        });
      }
    },
    [alpha, hex, format, onChange, onSubmitChange, showAlpha],
  );

  const onChangeAlpha = useCallback(
    (alphaValue: string | number): void => {
      const validAlpha = getAlphaValue(alphaValue.toString());

      onHandleSubmit(hex, Number(validAlpha));
    },
    [hex, onHandleSubmit],
  );

  const onChangeHex = useCallback(
    (hexValue: string): void => {
      onHandleSubmit(hexValue);
    },
    [onHandleSubmit],
  );

  const onChangeRGB = useCallback(
    (_channel: 'r' | 'g' | 'b', value: string): void => {
      const [r, g, b] = hexToRgba(hex);
      let newHex = '';

      if (_channel === 'r') {
        newHex = rgbaToHex([value, g ?? 0, b ?? 0]);
      } else if (_channel === 'g') {
        newHex = rgbaToHex([r ?? 0, value, b ?? 0]);
      } else {
        newHex = rgbaToHex([r ?? 0, g ?? 0, value]);
      }

      onHandleSubmit(newHex, alpha);
    },
    [alpha, hex, onHandleSubmit],
  );

  const colorString = useMemo(() => {
    const sixDigitHex = hex.length < 6 ? colord(hex).toHex().replace('#', '') : hex;

    return sixDigitHex.replace('#', '');
  }, [hex]);

  const [r, g, b] = useMemo(() => hexToRgba(hex), [hex]);

  return (
    <div className="mb-4 flex h-[16px]">
      <Menu as="div" className="relative inline-block">
        <Menu.Button className="group pointer-events-auto mr-2 flex h-[28px] w-14 cursor-pointer items-center justify-around rounded bg-gray-700 stroke-gray-500 text-xs text-white hover:stroke-white">
          <div className="ml-1">{colorMode}</div>
          <CaretDown className=" h-4 w-4 fill-current stroke-current dark:text-gray-600" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="pointer-events-auto absolute right-[-40px] z-dropdown mt-1 w-[98px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 shadow-dropdown focus:outline-none">
            {colorOptions.map((option, index) => {
              const isLast = index === colorOptions.length - 1;

              return (
                <div key={option} className={`px-2 ${index === 0 && 'pt-2'} pb-1`}>
                  <Menu.Item>
                    <button
                      className={`group flex h-6 w-full items-center rounded ${
                        isLast && 'mb-1'
                      } pl-2 text-xs text-white hover:bg-gray-600`}
                      onClick={() => setColorMode(option as 'RGB' | 'HEX')}
                    >
                      {option}
                    </button>
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
      {colorMode === 'HEX' ? (
        <>
          <ColorInput
            colorPicker={false}
            styleClass="rounded-r-none w-[102px] h-[28px] text-white hover:none"
            color={colorString}
            opacity={alpha}
            onChangeColor={(value) => onChangeHex(onlyLatins(value))}
            onChangeOpacity={onChangeAlpha}
            onChangeInput={(event) => onChangeHex(onlyLatins(event.target.value))}
            onKeyDown={(event) => handlePressEnter(event, onHandleSubmit)}
            disabled={false}
          />
          <Divider />
          <NumberInput
            styleClass={{
              label: `${defaultStyle.label} rounded-l-none text-white h-[28px] w-12`,
            }}
            name="opacity"
            value={alpha}
            min={0}
            max={100}
            precision={0}
            onChange={(result) => onChangeAlpha(result.value)}
            append="%"
          />
        </>
      ) : (
        <>
          <NumberInput
            styleClass={{
              label: `${defaultStyle.label} rounded-r-none text-white h-[28px] w-9`,
            }}
            name="r"
            value={r ?? 0}
            min={0}
            max={255}
            precision={0}
            onBlur={() => onHandleSubmit()}
            onChange={(result) => onChangeRGB('r', onlyLatins(result.value.toString()))}
          />
          <Divider />
          <NumberInput
            styleClass={{
              label: `${defaultStyle.label} rounded-none text-white h-[28px] w-9`,
            }}
            name="g"
            value={g ?? 0}
            min={0}
            max={255}
            precision={0}
            onBlur={() => onHandleSubmit()}
            onChange={(result) => onChangeRGB('g', onlyLatins(result.value.toString()))}
          />
          <Divider />
          <NumberInput
            styleClass={{
              label: `${defaultStyle.label} rounded-none text-white h-[28px] w-9`,
            }}
            name="b"
            value={b ?? 0}
            min={0}
            max={255}
            precision={0}
            onChange={(result) => onChangeRGB('b', onlyLatins(result.value.toString()))}
          />
          <Divider />
          <NumberInput
            styleClass={{
              label: `${defaultStyle.label} rounded-l-none text-white h-[28px] w-10`,
            }}
            name="b"
            value={alpha}
            min={0}
            max={100}
            precision={0}
            append="%"
            onChange={(result) => onChangeAlpha(result.value)}
          />
        </>
      )}
    </div>
  );
};

export default InputRgba;
