/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useCreatorStore } from '~/store';

interface ErrorTimerProp {
  fetching: boolean;
}

const ErrorTimer: React.FC<ErrorTimerProp> = React.memo(
  ({ fetching }: ErrorTimerProp) => {
    const [count, setCount] = useState(60);

    const handleError = useErrorHandler();

    useEffect(() => {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    useEffect(() => {
      if (count === 0 && fetching) {
        handleError({ message: 'Your action took too long. Please reload and try again' });
      }
    }, [count, handleError, fetching]);

    return null;
  },
  (prevProps, nextProps) => {
    // Only re-render if `fetching` has changed
    return prevProps.fetching === nextProps.fetching;
  },
);

export const ErrorListener: React.FC = () => {
  const fetching = useCreatorStore((state) => state.project.fetchExistingFile.status === true);

  if (fetching) return <ErrorTimer fetching={fetching} />;
  else return null;
};
