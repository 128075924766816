/**
 * Copyright 2023 Design Barn Inc.
 */

import { relottie } from '@lottiefiles/relottie';
import relottieExtractFeatures from '@lottiefiles/relottie-extract-features';
import type { CanILottie } from '@lottiefiles/relottie-extract-features';

export const checkCompatibleFeatures = (json?: unknown): string[] => {
  const featuresNotSupported: string[] = [];

  const canILottie = relottie().use(relottieExtractFeatures).processSync(JSON.stringify(json)).data[
    'extract-features'
  ] as CanILottie;

  const currentPlayer = 'lottie-creator';

  let currentVersion = '';

  if (canILottie.players[currentPlayer] && canILottie.players[currentPlayer].versions.length > 0) {
    const lastIndex = canILottie.players[currentPlayer].versions.length - 1;

    currentVersion = canILottie.players[currentPlayer].versions[lastIndex];
  }

  const featuresAvailable = Object.entries(canILottie.features);

  Object.entries(featuresAvailable).forEach(([, [, info]]) => {
    if (info.support[currentPlayer] && !info.support[currentPlayer][currentVersion]) {
      featuresNotSupported.push(info.title as string);
    }
  });

  return featuresNotSupported;
};
