/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Track } from '@lottiefiles/toolkit-js/';

import { createKeyframeObj } from '../../time/keyframe';
import { createTrackObject } from '../../time/track';
import { createNodeObject } from '../../translate-node';

import type { Plugin } from '~/plugins/Plugin';

export function stripReturnValues(
  plugin: Plugin,
  returnValues: object | number | string | boolean,
): object | number | string | boolean {
  let updatedReturnValues;

  // if array
  if (Array.isArray(returnValues)) {
    updatedReturnValues = [];

    (returnValues as object[]).forEach((arg) => {
      const strippedArg = stripReturnValues(plugin, arg);

      updatedReturnValues.push(strippedArg);
    });
    // if node
  } else if (typeof returnValues === 'object' && 'nodeType' in returnValues) {
    updatedReturnValues = createNodeObject(plugin, returnValues);
    // if keyframe
  } else if (typeof returnValues === 'object' && 'frame' in returnValues) {
    updatedReturnValues = createKeyframeObj(plugin, returnValues as Keyframe);
    // if track
  } else if (typeof returnValues === 'object' && 'property' in returnValues) {
    updatedReturnValues = createTrackObject(plugin, returnValues as Track);
  } else {
    updatedReturnValues = returnValues;
  }

  return updatedReturnValues;
}
