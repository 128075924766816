/**
 * Copyright 2023 Design Barn Inc.
 */

import { useClickOutside } from '~/hooks';

// To prevent useClickOutside's onExecute called on first comp initialize
export const ClickOutside: React.FC = (params) => {
  const { onExecute, onRef } = params;

  useClickOutside(onRef, onExecute, null);

  return null;
};
