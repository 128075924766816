/**
 * Copyright 2022 Design Barn Inc.
 */

// Cache the result for fast access
export const treeLinesCache = new Map();
export const animatedTreeLinesCache = new Map();
export const treeLineLevelCache = new Map();

export const clearLayerCache = (): void => {
  treeLinesCache.clear();
  animatedTreeLinesCache.clear();
  treeLineLevelCache.clear();
};

// getLineLevelToDraw take isParentLastList as input, and return array of which level of tree line to draw (draw above the current layer)
export const getLineLevelToDraw = (isParentLastList: boolean[]): number[] => {
  // [] -> []
  // [T] -> [1]
  // [T,T] -> [2]
  // [T,T,F] -> [2,3]
  // [T,T,T] -> [3]
  // [T,T,F,T] -> [2,4]

  // Example 1:
  // v - Layer 1       [] -> []              Layer 1 is root, so no lines to be drawn above
  //   |
  //   v - Layer 2     [T] -> [1]            Layer 2 has Layer 1 parent (last node), so draw tree line above at column 1
  //     |
  //     v - Layer 3   [T, T] -> [2]         Layer 3 has Layer 1 & 2 parent (both last nodes), so draw tree line above at column 2
  //     | |- Layer 4  [T, T, F] -> [2, 3]   Layer 4 has Layer 1, 2 (both last nodes) & 3 as parents (not last node), so draw tree line above at column 2 & 3
  //     | |- Layer 5  [T, T, F] -> [2, 3]   Layer 5 has Layer 1, 2 (both last nodes) & 3 as parents (not last node), so draw tree line above at column 2 & 3
  //     |-- Layer 6   [T, T] -> [2]         Layer 6 has Layer 1, 2 (both last nodes), so draw tree line above at column 2

  // Example 2:
  // v - Layer 1       [] -> []                    Explaination as above
  //   |
  //   v - Layer 2     [T] -> [1]
  //     |
  //     v - Layer 3   [T, T] -> [2]
  //     | | - Layer 4  [T, T, F] -> [2, 3]
  //     | | - Layer 5  [T, T, F] -> [2, 3]
  //     | | - Layer 6  [T, T, F] -> [2, 3]
  //     |   |- Layer 7  [T, T, F, T] -> [2, 4]    Layer 7 has Layer 1, 2, 6 (last nodes) & 3 (not last nodes), so draw tree line above at column 2 & 4
  //     |- Layer 8   [T, T] -> [2]
  //       |- Layer 9  [T, T, T] -> [3]            Layer 9 has Layer 1, 2, 6 parent (last nodes), so draw tree line above at column 3

  let count = 0;
  let startIndex = 0;
  const length = isParentLastList.length;
  const treeLineLevelToDraw = [];

  // compute key
  const key = isParentLastList.join(',');

  if (!treeLineLevelCache.has(key)) {
    // compute tree line level when it is not in cache
    for (let i = 0; i < length; i += 1) {
      if (isParentLastList[i]) {
        // Count the number of consecutive true
        count += 1;
      } else {
        treeLineLevelToDraw.push(count + startIndex);
        if (i === length - 1) {
          // Append to the list if the last value is false
          treeLineLevelToDraw.push(i + 1);
          break;
        } else {
          // Reset the count and store the start index
          count = 0;
          startIndex = i + 1;
        }
      }
      if (i === length - 1) {
        // If the list is all true, append to the list
        treeLineLevelToDraw.push(count + startIndex);
      }
    }
    // Store the result in cache to have fast access
    treeLineLevelCache.set(key, treeLineLevelToDraw);
  }

  return treeLineLevelCache.get(key);
};

export interface TreeLineState {
  height: number | string;
  isLeaf: boolean;
  left: number | string;
  top: number | string;
}

interface GetTreeLineProps {
  hasChildren: boolean;
  isLastChild: boolean;
  level: number;
  parent: string[];
  treeLineLevel: number[];
}

// getTreeLines return the tree lines information for render. It contains information such as top, left, height, isLeaf properties
export const getTreeLines = (props: GetTreeLineProps): TreeLineState[] => {
  const { hasChildren, isLastChild, level, treeLineLevel } = props;

  const top = -5;
  const left = 24;
  const leftPadding = 16;
  const leafHeight = 16;
  const straightLineHeight = 25;
  const aboveChevronHeight = 8;

  // stringify the object key
  // IMPROVEMENT: optimization improvement by manualing create object key string instead of calling JSON.stringify
  const objKey = JSON.stringify({ hasChildren, isLastChild, level, treeLineLevel });

  // Compute treeline calculation when it is not in cache
  if (!treeLinesCache.has(objKey)) {
    // Building array of tree line
    const treeLines: TreeLineState[] = [];

    // Only draw the tree lines when level > 0 (non-root level)
    treeLineLevel.forEach((colLevel) => {
      let object: TreeLineState = {
        top,
        left: left + leftPadding * (colLevel - 1),
        height: straightLineHeight,
        isLeaf: false,
      };

      if (hasChildren) {
        // Adjust height when there is chevron
        object.height = aboveChevronHeight;
      } else {
        object = { ...object, left: left + leftPadding * (colLevel - 1), height: leafHeight };
        if (colLevel === level) {
          // Leaf node
          object.isLeaf = true;
          object.height = isLastChild ? 17 : 32;
          object.top = isLastChild ? -6 : '';
        }
      }

      if (colLevel !== level) {
        object.height = straightLineHeight;
      }

      treeLines.push({ ...object });
    });

    treeLinesCache.set(objKey, treeLines);
  }

  return treeLinesCache.get(objKey);
};

// Similar as getTreeLines but for animated row
export const getAnimatedTreeLines = (props: GetTreeLineProps): TreeLineState[] => {
  const { hasChildren, isLastChild, level, parent, treeLineLevel } = props;

  const top = -5;
  // 0.5 difference from border?
  const left = 24.5;
  const leftPadding = 16;
  const straightLineHeight = 26;

  // stringify the object key
  const objKey = JSON.stringify({ hasChildren, isLastChild, level, treeLineLevel, parents: parent.length });

  if (!animatedTreeLinesCache.has(objKey)) {
    // Building array of tree line
    const treeLines: TreeLineState[] = [];

    const animatedTreeLine = [...treeLineLevel];

    if (hasChildren && parent.length === 0) {
      // Draw first level if the root layer has children
      animatedTreeLine.unshift(1);
    }

    if (!hasChildren && isLastChild) {
      // For last leaf animated property, remove the last line if it is the last child
      animatedTreeLine.pop();
    }

    // Compute treeline to draw property
    animatedTreeLine.forEach((colLevel) => {
      let object: TreeLineState = {
        top,
        left: left + leftPadding * colLevel,
        height: straightLineHeight,
        isLeaf: false,
      };

      if (!hasChildren || parent.length === 0) {
        object = { ...object, left: left + leftPadding * (colLevel - 1) };
      } else {
        object = { ...object };
        if (colLevel === level) {
          // Leaf node
          object.isLeaf = true;
          object.height = 32;
        }
      }

      if (colLevel !== level) {
        object.height = straightLineHeight;
      }

      treeLines.push({ ...object });
    });

    animatedTreeLinesCache.set(objKey, treeLines);
  }

  return animatedTreeLinesCache.get(objKey);
};

// Get the left-padding for layer (non-animated properties)
export const computePaddingLeft = (hasParent: boolean, hasChildren: boolean, level: number): number => {
  const padLeft = 16;

  if (hasParent) {
    if (hasChildren) {
      return level * padLeft;
    }

    return level * padLeft + padLeft;
  }

  return 0;
};

// Get the left-padding for animated properties layer
export const computeAnimatedPaddingLeft = (level: number): number => {
  const padLeft = 16;

  return (level + 1) * padLeft + padLeft;
};
