/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Popups {
  activeTab?: string | 'solid' | 'gradient';
  children?: any;
  onClick?: () => void;
  popupWidth?: number;
  tabName?: string;
}

export const PopupTabs: React.FC<Popups> = ({ activeTab = '', children, popupWidth }: Popups) => {
  const childrenContact = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      activeTab,
    });
  });

  return <div style={{ width: `${popupWidth}px` }}>{childrenContact}</div>;
};

export const PopupTabsBody: React.FC<Popups> = ({ activeTab = '', children }) => {
  const childrenContact = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      activeTab,
    });
  });

  return <div className="px-4 pb-5 pt-3">{childrenContact}</div>;
};
