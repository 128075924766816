/**
 * Copyright 2022 Design Barn Inc.
 */

import { Resizable } from 're-resizable';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { PlayerControl } from '../PlayerControl/PlayerControl';

import TimelineBody from './TimelineBody';
import TimelineTopbar from './TimelineTopbar';

import { useCreatorStore } from '~/store';

const MIN_HEIGHT_TIMELINE = 28;
const DEFAULT_HEIGHT_TIMELINE = 300;

// HiddenTimelineContainer purpose is to placed under the main timeline and
// and listen for the change in the height of the main timeline to adjust the height of the hidden timeline accordingly.
// Doing so will resize the top items accordingly (Canvas, PropertyPanel, Side Panel)
export const HiddenTimelineContainer: React.FC = () => {
  // Timeline height is the height of the main timeline minus the some height from timeline rounded topbar,
  // so that the background will not show white background
  const height = useCreatorStore((state) => state.timeline.height) - 16;

  return <div style={{ height }}></div>;
};

// Cast React.memo to avoid re-rendering the component when the height of the main timeline changes
const Content: React.FC = React.memo(() => {
  return (
    <>
      <TimelineTopbar />
      <TimelineBody />
    </>
  );
});

export const TimeLine: React.FC = () => {
  const [height, setHeight] = useState<number>(MIN_HEIGHT_TIMELINE);
  const timelineVisible = useCreatorStore((state) => state.timeline.visible);
  const setTimelineHeight = useCreatorStore((state) => state.timeline.setHeight);

  // Require to store in intermediate state to prevent bad setState warning
  // https://github.com/maslianok/react-resize-detector/issues/170#issuecomment-888073268
  const onResize = useCallback((_: number | undefined, containerHeight: number | undefined) => {
    if (containerHeight) {
      setHeight(containerHeight);
    }
  }, []);

  useLayoutEffect(() => {
    if (height) {
      setTimelineHeight(height);
    }
  }, [height, setTimelineHeight]);

  useLayoutEffect(() => {
    if (timelineVisible) {
      setHeight(DEFAULT_HEIGHT_TIMELINE);
    } else {
      setHeight(MIN_HEIGHT_TIMELINE);
    }
  }, [timelineVisible]);

  const { ref } = useResizeDetector({ onResize });

  return (
    <div
      id="TimelineContainer"
      className="absolute bottom-0 z-10 w-full rounded-t-2xl bg-gray-800 text-white drop-shadow-timeline"
      ref={ref}
    >
      <Resizable
        enable={{
          top: timelineVisible,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        size={{ height, width: '100%' }}
        // Set maxHeight half of window screen
        maxHeight={window.innerHeight * 0.5}
        minHeight={MIN_HEIGHT_TIMELINE}
      >
        <div className="relative flex h-full flex-col">
          <PlayerControl />
          <Content />
        </div>
      </Resizable>
    </div>
  );
};
