/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ProjectSaveModal, AnimationAdder, SupportFeatures } from '~/features/global-modal';
import { useDisclosure } from '~/hooks';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant/modal';

export const GlobalModal: React.FC = () => {
  const { onClose } = useDisclosure();

  const [globalModal, setGlobalModal] = useCreatorStore(
    (state) => [state.ui.globalModal, state.ui.setGlobalModal],
    shallow,
  );

  const onGlobalClose = useCallback(() => {
    setGlobalModal(null);
    onClose();
  }, [setGlobalModal, onClose]);

  const isOpen = true;
  const currentState = globalModal?.state;
  const modalOptions = globalModal?.options;

  return (
    <div>
      {currentState === GlobalModalConstant.ProjectSave && (
        <ProjectSaveModal options={modalOptions} isOpen={isOpen} onClose={onGlobalClose} />
      )}
      {currentState === GlobalModalConstant.AnimationAdder && (
        <AnimationAdder options={modalOptions} isOpen={isOpen} onClose={onGlobalClose} />
      )}
      {currentState === GlobalModalConstant.SupportFeatures && (
        <SupportFeatures options={modalOptions} isOpen={isOpen} onClose={onGlobalClose} />
      )}
    </div>
  );
};
