/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { TimeControl } from './TimeControl';
import { ZoomControl } from './ZoomControl';

import {
  TimelinePlay,
  TimelineSkipToStart,
  TimelineLoop,
  TimelineNoLoop,
  TimelinePause,
  TimelineMoveForward,
} from '~/assets/icons';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

interface ControlContainerProps {
  children: React.ReactNode;
  disabled?: boolean;
}

const ControlContainer: React.FC<ControlContainerProps> = ({ children, disabled }) => {
  return (
    <div
      className={clsx('group flex h-6 w-6 items-center justify-center rounded-[4px] ', {
        'hover:bg-gray-700': !disabled,
      })}
    >
      {children}
    </div>
  );
};

const Play: React.FC = () => {
  const [playing] = useCreatorStore((state) => [state.timeline.playing], shallow);

  const handlePlay = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_PLAY);
  }, []);

  const handlePause = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_PAUSE);
  }, []);

  useEffect(() => {
    const pauseIfHidden = (): void => {
      if (document.hidden) {
        handlePause();
      }
    };

    document.addEventListener('visibilitychange', pauseIfHidden);

    return () => {
      document.removeEventListener('visibilitychange', pauseIfHidden);
    };
  }, [handlePause]);

  return (
    <ControlContainer>
      {playing ? (
        <TimelinePause className="h-4 w-4" onClick={handlePause} />
      ) : (
        <TimelinePlay className="h-4 w-4" onClick={handlePlay} />
      )}
    </ControlContainer>
  );
};

const SkipToStart: React.FC = () => {
  const [currentFrame] = useCreatorStore((state) => [state.toolkit.currentFrame], shallow);

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_FIRST_FRAME);
  }, []);

  return (
    <ControlContainer disabled={currentFrame === 0}>
      <TimelineSkipToStart
        className={`h-4 w-4  ${
          currentFrame > 0
            ? 'fill-white stroke-white group-hover:fill-white group-hover:stroke-white'
            : 'fill-gray-400 stroke-gray-400 '
        }`}
        onClick={handleClick}
      />
    </ControlContainer>
  );
};

const MoveBackward: React.FC = () => {
  const [currentFrame] = useCreatorStore((state) => [state.toolkit.currentFrame], shallow);

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_FRAME);
  }, []);

  return (
    <ControlContainer disabled={currentFrame === 0}>
      <TimelineMoveForward
        className={`h-4 w-4 rotate-180  ${
          currentFrame > 0
            ? 'fill-white stroke-white group-hover:fill-white group-hover:stroke-white'
            : 'fill-gray-400 stroke-gray-400 '
        }`}
        onClick={handleClick}
      />
    </ControlContainer>
  );
};

const MoveForward: React.FC = () => {
  const [currentFrame, duration, currentFrameRate, currentPrecompJson, precompFrameRate, precompDuration] =
    useCreatorStore(
      (state) => [
        state.toolkit.currentFrame,
        (state.toolkit.json?.timeline.duration as number) || 0,
        state.toolkit.json?.timeline.properties.fr as number,

        state.toolkit.selectedPrecompositionJson,
        state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
        state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      ],
      shallow,
    );

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_NEXT_FRAME);
  }, []);

  return (
    <ControlContainer disabled={currentFrame === 0}>
      <TimelineMoveForward
        className={`h-4 w-4   ${
          currentFrame <
          (currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration)
            ? 'fill-white stroke-white group-hover:fill-white group-hover:stroke-white'
            : 'fill-gray-400 stroke-gray-400 '
        }`}
        onClick={handleClick}
      />
    </ControlContainer>
  );
};

const SkipToLast: React.FC = () => {
  const [currentFrame, duration, currentFrameRate, currentPrecompJson, precompFrameRate, precompDuration] =
    useCreatorStore(
      (state) => [
        state.toolkit.currentFrame,
        (state.toolkit.json?.timeline.duration as number) || 0,
        state.toolkit.json?.timeline.properties.fr as number,

        state.toolkit.selectedPrecompositionJson,
        state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
        state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      ],
      shallow,
    );

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_LAST_FRAME);
  }, []);

  return (
    <ControlContainer
      disabled={
        currentFrame ===
        (currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration)
      }
    >
      <TimelineSkipToStart
        className={`h-4 w-4 rotate-180  ${
          currentFrame <
          (currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration)
            ? 'fill-white stroke-white group-hover:fill-white group-hover:stroke-white'
            : 'fill-gray-400 stroke-gray-400 '
        }`}
        onClick={handleClick}
      />
    </ControlContainer>
  );
};

const Loop: React.FC = () => {
  const [looping, setLooping] = useCreatorStore(
    (state) => [state.timeline.looping, state.timeline.setLooping],
    shallow,
  );

  const handleToggle = useCallback(() => {
    setLooping(!looping);
  }, [looping, setLooping]);

  return (
    <ControlContainer>
      {looping ? (
        <TimelineLoop className=" h-4 w-4 fill-teal-200" onClick={handleToggle} />
      ) : (
        <TimelineNoLoop className=" h-4 w-4 fill-gray-400 group-hover:fill-white" onClick={handleToggle} />
      )}
    </ControlContainer>
  );
};

const Control: React.FC = () => {
  return (
    <div className="flex w-[120x] space-x-1">
      <SkipToStart />
      <MoveBackward />
      <Play />
      <MoveForward />
      <SkipToLast />
      <Loop />
    </div>
  );
};

const LeftContainer: React.FC = () => {
  return (
    <div className="w-[70px]">
      <TimeControl />
    </div>
  );
};

const RightContainer: React.FC = () => {
  return (
    <div className="ml-4 w-[45px]">
      <ZoomControl />
    </div>
  );
};

export const PlayerControl: React.FC = () => {
  return (
    <div className="absolute -top-9 left-1/2 z-player-control h-8 w-[303px] -translate-x-1/2 rounded-xl bg-gray-800/90 px-4 py-1">
      <div className="flex items-center justify-between">
        <LeftContainer />
        <Control />
        <RightContainer />
      </div>
    </div>
  );
};
