/**
 * Copyright 2022 Design Barn Inc.
 */

import { FillRuleType } from '@lottiefiles/toolkit-js';
import { get } from 'lodash-es';
import React, { useState, useCallback, useEffect } from 'react';

import { Select } from '~/components/Elements/Select';
import type { Option } from '~/components/Elements/Select';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

const propertyFillRuleOptions: Option[] = [
  {
    label: 'Non-Zero Winding',
    value: FillRuleType.NONZERO,
  },
  {
    label: 'Even-Odd',
    value: FillRuleType.EVEN_ODD,
  },
];

export const PropertyFillRule: React.FC = () => {
  const fRule = useCreatorStore((state) => {
    const currentProperty = state.ui.currentPropertyPanel;

    if (currentProperty === PropertyPanelType.Stroke) return get(state.toolkit.currentStrokeShape, 'fillRule');
    else if (currentProperty === PropertyPanelType.Fill) return get(state.toolkit.currentFillShape, 'fillRule');

    return FillRuleType.NONZERO;
  });

  const [fillRule, setFillRule] = useState<Option>(propertyFillRuleOptions[0]);

  const handleOnChangeTemplate = useCallback((option: Option) => {
    setFillRule(option);
    useCreatorStore.getState().toolkit.setFillRule(option.value as number);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_BLENDING_MODE_UPDATED);
  }, []);

  useEffect(() => {
    let frIndex = propertyFillRuleOptions.findIndex((bmo) => bmo.value === fRule);

    if (frIndex === -1) {
      frIndex = 0;
    }
    setFillRule(propertyFillRuleOptions[frIndex]);
  }, [fRule]);

  return (
    <div className="inline-flex h-6 w-full">
      <div className="grow">
        <Select
          styleName="!fixed w-[176px]"
          options={propertyFillRuleOptions}
          selected={fillRule}
          onChange={handleOnChangeTemplate}
        />
      </div>
    </div>
  );
};
