/**
 * Copyright 2022 Design Barn Inc.
 */

const WORKSPACE_FRAGMENT = `
  fragment WorkspaceFields on Workspace {
    id
    name
    icon
    features {
      name
      slug
      isEnabled
      max
    }
    hasOwnership
  }
`;

const PAGE_INFO_FRAGMENT = `
  fragment PageInfoFields on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const FETCH_WORKSPACE_ACCESS = `
query hasAccessToAWorkspace {
  hasAccessToAWorkspace
}`;

export const CREATE_INITIAL_WORKSPACE = `
  mutation setupInitialWorkspace {
    setupInitialWorkspace {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FRAGMENT}
`;

export const FETCH_CURRENT_WORKSPACE = `
 query getCurrentWorkspace{
   currentWorkspace {
     id
     name
   }
 }
 `;

export const FETCH_PROJECTS_BY_WORKSPACE_ID = `
 query getWorkspaceProjects($workspaceId: ID!) {
   workspaceProjects(workspaceId: $workspaceId, first: 10) {
     pageInfo {
       ...PageInfoFields
     }
     edges {
       cursor
       node {
         id
         title
         isPrivate
         workspaceId
         stats {
          folders
         }
       }
     }
     totalCount
   }
 }
 ${PAGE_INFO_FRAGMENT}
 `;

export const FETCH_DRAFT_PROJECT = `
 query workspaceDraftProject($workspaceId: ID!) {
  workspaceDraftProject(workspaceId: $workspaceId) {
     id
     isPrivate
     isSystem
     slug
     title
     workspaceId
   }
 }
 `;

export const FETCH_WORKFLOW_WORKSPACES = `
 query getWorkspaces{
  workspaces {
    ...WorkspaceFields
  }
}
${WORKSPACE_FRAGMENT}
 `;

export const FETCH_FOLDERS_BY_PROJECT_ID = `
 query foldersByProjectId($projectId: String!) {
  foldersByProjectId(projectId: $projectId) {
    id
    name
    filesCount
    projectId
    thumbnails {
      thumbnails {
        webp {
          medium {
            url
            contentType
          }
        }
      }
    }
  }
 }
 `;

export const CREATE_FOLDER = `
  mutation createFolder($input: CreateFolderInput!) {
    folderCreate(input: $input) {
      id
      name
      projectId
    }
  }
`;

export const CREATE_PROJECT = `
  mutation createProject($input: ProjectCreateInput!) {
    projectCreate(input: $input) {
      id
      title
      isPrivate
      workspaceId
      workspace {
        id
        icon
        name
      }
    }
  }
`;
