/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

interface LoopButtonProps {
  isLooping: boolean;
  toggleLooping: () => void;
}

export const LoopButton: React.FC<LoopButtonProps> = ({ isLooping, toggleLooping }: LoopButtonProps) => {
  const LoopOn: React.FC = () => {
    return (
      <svg
        style={{ pointerEvents: 'none' }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0583 2.40719C11.2535 2.21193 11.5701 2.21193 11.7654 2.40719L13.651 4.29281C13.8463 4.48807 13.8463 4.80465 13.651 4.99991L11.7654 6.88553C11.5701 7.08079 11.2535 7.08079 11.0583 6.88553C10.863 6.69027 10.863 6.37369 11.0583 6.17843L11.8088 5.42789H8.19268L7.42288 4.09456L12.0385 4.09456L11.0583 3.1143C10.863 2.91903 10.863 2.60245 11.0583 2.40719ZM3.5262 4.09428L3.54583 4.09456L4.34368 4.09456L5.11348 5.42789H4.19287L4.19287 6.76094C4.19287 7.12913 3.89439 7.42761 3.5262 7.42761C3.15801 7.42761 2.85953 7.12913 2.85953 6.76094L2.85953 4.76094C2.85953 4.39275 3.15801 4.09428 3.5262 4.09428ZM13.297 11.4215L13.2971 11.4277C13.2971 11.7959 12.9986 12.0944 12.6304 12.0944H12.0417L11.2719 10.7611H11.9637V9.42733C11.9637 9.05914 12.2622 8.76066 12.6304 8.76066C12.9986 8.76066 13.297 9.05914 13.297 9.42733V11.4215ZM5.0983 13.7811C4.90304 13.9763 4.58646 13.9763 4.3912 13.7811L2.50558 11.8955C2.31032 11.7002 2.31032 11.3836 2.50558 11.1884L4.3912 9.30274C4.58646 9.10748 4.90304 9.10748 5.0983 9.30274C5.29356 9.498 5.29356 9.81458 5.0983 10.0098L4.34708 10.7611L8.19268 10.7611L8.96248 12.0944H4.11873L5.0983 13.074C5.29356 13.2692 5.29356 13.5858 5.0983 13.7811ZM10.7595 13.5406C10.8976 13.7798 11.2034 13.8617 11.4425 13.7236C11.6817 13.5855 11.7636 13.2798 11.6256 13.0406L5.62556 2.64832C5.48749 2.40917 5.1817 2.32724 4.94255 2.46531C4.70341 2.60338 4.62147 2.90917 4.75954 3.14832L10.7595 13.5406Z"
          fill="white"
        />
      </svg>
    );
  };

  const LoopOff: React.FC = () => {
    return (
      <svg
        style={{ pointerEvents: 'none' }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8654 2.31291C11.0606 2.11765 11.3772 2.11765 11.5725 2.31291L13.4581 4.19853C13.6534 4.39379 13.6534 4.71037 13.4581 4.90564L11.5725 6.79125C11.3772 6.98652 11.0606 6.98652 10.8654 6.79125C10.6701 6.59599 10.6701 6.27941 10.8654 6.08415L11.6162 5.33333H3.99998V6.66667C3.99998 7.03486 3.7015 7.33333 3.33331 7.33333C2.96512 7.33333 2.66665 7.03486 2.66665 6.66667L2.66665 4.66667C2.66665 4.29848 2.96512 4 3.33331 4H11.8454L10.8654 3.02002C10.6701 2.82476 10.6701 2.50817 10.8654 2.31291ZM12.4375 11.9996C12.8057 11.9996 13.1041 11.7012 13.1041 11.333V9.33297C13.1041 8.96478 12.8057 8.6663 12.4375 8.6663C12.0693 8.6663 11.7708 8.96478 11.7708 9.33297V10.6663H4.1546L4.90542 9.91549C5.10068 9.72022 5.10068 9.40364 4.90542 9.20838C4.71015 9.01312 4.39357 9.01312 4.19831 9.20838L2.31269 11.094C2.11743 11.2893 2.11743 11.6058 2.31269 11.8011L4.19831 13.6867C4.39357 13.882 4.71015 13.882 4.90542 13.6867C5.10068 13.4915 5.10068 13.1749 4.90542 12.9796L3.92543 11.9996H12.4375Z"
          fill="#00DDB3"
        />
      </svg>
    );
  };

  return (
    <div className={'rounded p-1 hover:cursor-pointer hover:bg-gray-700'} onClick={toggleLooping}>
      {isLooping ? <LoopOff /> : <LoopOn />}
    </div>
  );
};
