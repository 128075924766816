/**
 * Copyright 2022 Design Barn Inc.
 */

import { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { useWorkspaceAPI } from '~/providers/api/workspace';
import { useCreatorStore } from '~/store';
import type { IWorkspace } from '~/store/projectSlice';

export const FetchWorkflowWorkspaces: React.FC = () => {
  const [setWorkflowWorkspaces, setSelectedWorkspaceId, token] = useCreatorStore(
    (state) => [state.project.setWorkflowWorkspaces, state.project.setSelectedWorkspaceId, state.user.token],
    shallow,
  );

  const { fetchWorkflowWorkspaces, getCurrentWorkspaceId, getWorkspaceAccess, setupInitialWorkspace } = useWorkspaceAPI(
    token,
  );

  const getWorkFlowWorkspaces = useCallback(async () => {
    // Check if user has access to any workspace
    // If none, setup workspace
    // If yes, continue

    try {
      const hasAccess = await getWorkspaceAccess();

      if (!hasAccess) {
        await setupInitialWorkspace();
      }
    } catch (error) {
      // NOTE: Trigger error listener to display "Something went wrong while setting up blablabla, redirect to workflow?"
    }

    const workflowWorkspaces = (await fetchWorkflowWorkspaces()) as IWorkspace[];

    if (workflowWorkspaces.length > 0) {
      const currentWorkspaceId = await getCurrentWorkspaceId();

      if (currentWorkspaceId) {
        // select first before render directories
        setSelectedWorkspaceId(currentWorkspaceId);
        setWorkflowWorkspaces(workflowWorkspaces);
      }
    } else {
      // NOTE: Trigger error listener to display action to user that something went wrong and they have no workflow folders?
      // Basically operating with no cloud functionality
    }
  }, [
    fetchWorkflowWorkspaces,
    getWorkspaceAccess,
    setupInitialWorkspace,
    getCurrentWorkspaceId,
    setSelectedWorkspaceId,
    setWorkflowWorkspaces,
  ]);

  useEffect(() => {
    if (token) {
      getWorkFlowWorkspaces();
    }
  }, [getWorkFlowWorkspaces, token]);

  return null;
};
