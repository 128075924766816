/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { registerObjectMethods } from '../vmInterface/wrapper';

import { getLayerMethods } from './layer';

export function createLayerObject(plugin: Plugin, layer: Layer): object {
  const layerObj = {
    nodeId: layer.nodeId,
    nodeType: layer.nodeType,
    type: layer.type,
  };

  registerObjectMethods(plugin, layer, layerObj, [getNodeMethods, getLayerMethods]);

  return layerObj;
}
