/**
 * Copyright 2022 Design Barn Inc.
 */

// import { nanoid } from 'nanoid';
import React, { useCallback, useContext } from 'react';
// import { shallow } from 'zustand/shallow';

import { SupportFeatureContext } from './SupportFeatureContext';

// import { Plus as IconPlus } from '~/assets/icons';
import { useCreatorStore } from '~/store';
// import { DirectoryType, SavingState } from '~/store/projectSlice';
const setFeatureChecker = useCreatorStore.getState().ui.setFeatureChecker;

export const OpenFooter: React.FC = () => {
  const { onClose, openAnywayCallback, openLabel, revertCallback, revertLabel } = useContext(SupportFeatureContext);

  const handleOpenAnyway = useCallback(async () => {
    setFeatureChecker({ skip: true });

    openAnywayCallback();
    onClose();
  }, [openAnywayCallback, onClose]);

  const handleRevertCallback = useCallback(async () => {
    revertCallback();
    onClose();
  }, [revertCallback, onClose]);

  return (
    <div className="float-right flex h-[58px] px-3 py-4">
      <div onClick={handleOpenAnyway}>
        <span className="mr-4 inline cursor-pointer text-caption text-gray-400 hover:text-white ">
          {openLabel || 'Open anyway'}
        </span>
      </div>
      <div className="flex">
        <button
          className="ml-1 w-full cursor-pointer rounded bg-teal-300 px-[8px] py-[3px] text-xs font-bold leading-[17px] text-white hover:bg-teal-400"
          onClick={handleRevertCallback}
        >
          {revertLabel || 'Upload another'}
        </button>
      </div>
    </div>
  );
};
