/**
 * Copyright 2023 Design Barn Inc.
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ColorInput } from '../ColorInput/ColorInput';

import { PlayerControls } from './PlayerControls/PlayerControls';
import { RenderVisibility } from './RenderVisibility';

import { Divider } from '~/features/property-panel';
import type { SizeType } from '~/utils';
import { scaleToFit } from '~/utils';

interface DotLottiePlayerElement {
  current: DotLottiePlayer;
}

export interface DotLottiePlayer {
  getLottie: () => AnimationItem;
  getVersions: () => { dotLottiePlayerVersion: string; lottieWebVersion: string };
  load: (src: Record<string, never>) => void;
  pause: () => void;
  play: () => void;
  seek: (value: number | string) => void;
  setLooping: (value: boolean) => void;
  stop: () => void;
}

export interface AnimationItem {
  currentFrame: number;
  goToAndStop: (frame: number, value: boolean) => void;
  isLoaded: boolean;
  isPaused: boolean;
  loop: boolean | number;
  name: string;
  onComplete: () => void;
  onEnterFrame: () => void;
  totalFrames: number;
}

interface Props {
  forThumbnail?: boolean;
  getPlayerVersion?: (player: DotLottiePlayer) => void;
  hidden?: boolean;
  json: Record<string, never>;
}

export const getScaledSize = (src: SizeType): { h: number; w: number } => {
  // player reference max height: 680 (popover max size) - 226
  const result = scaleToFit(src, { width: 340, height: 454 });

  return { h: result.height, w: result.width };
};

export const TestAnimationPlayer: React.FC<Props> = ({
  forThumbnail = false,
  getPlayerVersion,
  hidden = false,
  json,
}) => {
  // color picker state
  const [color, setColor] = useState('#ffffff');
  const [opacity, setOpacity] = useState(100);

  // player state
  const playerRef = useRef<DotLottiePlayerElement>(null);
  const player = playerRef.current as DotLottiePlayer | null;
  const [size, setSize] = useState({ h: 0, w: 0 });
  const [lottieInstance, setLottieInstance] = useState<AnimationItem | null>(null);
  const [isPlaying, setIsPlaying] = useState(forThumbnail ? false : !hidden);
  const [isLooping, setIsLooping] = useState(true);
  const [currentFrame, setCurrentFrame] = useState<number>(0);
  const [totalFrames, setTotalFrames] = useState<number>(1);

  const defaultSize = {
    h: 340,
    w: 454,
  };

  const updateColor = useCallback((colorValue: string): void => {
    setColor(colorValue);
  }, []);

  const handleOnChangeOpacity = useCallback((opac: number) => {
    setOpacity(opac);
  }, []);

  // eslint-disable-next-line id-length
  const { h, w } = json as Record<string, number | undefined>;

  useEffect(() => {
    setSize(getScaledSize({ width: (w as number) || defaultSize.w, height: (h as number) || defaultSize.h }));
  }, [defaultSize.h, defaultSize.w, h, w]);

  // Load the animation
  useEffect(() => {
    if (player) {
      if (!forThumbnail) player.load(json);
      if (getPlayerVersion) getPlayerVersion(player);
      setLottieInstance(player.getLottie());
    }
  }, [forThumbnail, getPlayerVersion, json, player, size.h, size.w]);

  // Update the current frame
  useEffect(() => {
    if (lottieInstance && isPlaying) {
      lottieInstance.onEnterFrame = () => {
        const frame = Math.floor(lottieInstance.currentFrame);

        setCurrentFrame(frame);
      };
    }
  }, [isPlaying, lottieInstance]);

  // Get the animation's total frames
  useEffect(() => {
    if (lottieInstance) setTotalFrames(lottieInstance.totalFrames);
  }, [lottieInstance, setTotalFrames]);

  // Reset player if animation reaches the end and loop is disabled
  useEffect(() => {
    if (lottieInstance && !isLooping) {
      lottieInstance.onComplete = () => {
        setIsPlaying(false);
        player?.stop();
      };
    }
  }, [currentFrame, isLooping, lottieInstance, player, totalFrames]);

  const togglePlayAnimation = useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false);
      (player as DotLottiePlayer).pause();
    } else {
      setIsPlaying(true);
      (player as DotLottiePlayer).play();
    }
  }, [isPlaying, player]);

  const toggleLooping = useCallback(() => {
    if (isLooping) {
      setIsLooping(false);
      (player as DotLottiePlayer).setLooping(false);
    } else {
      setIsLooping(true);
      (player as DotLottiePlayer).setLooping(true);
    }
  }, [isLooping, player]);

  return (
    <div>
      <RenderVisibility hidden={hidden}>
        <dotlottie-player
          ref={playerRef}
          background={color}
          renderer="svg"
          autoplay
          loop
          style={{
            height: size.h,
            width: size.w,
            maxWidth: forThumbnail ? 'none' : 340,
          }}
          {...(hidden && forThumbnail
            ? {
                id: 'thumbnail-player',
                src: JSON.stringify(json),
              }
            : {
                id: 'test-animation-player',
              })}
        />
        {playerRef.current && lottieInstance && (
          <>
            <PlayerControls
              player={player as DotLottiePlayer}
              lottieInstance={lottieInstance}
              isPlaying={isPlaying}
              isLooping={isLooping}
              togglePlayAnimation={togglePlayAnimation}
              currentFrame={currentFrame}
              setCurrentFrame={setCurrentFrame}
              totalFrames={totalFrames}
              toggleLooping={toggleLooping}
            />
          </>
        )}
        <Divider />
        <div className="rounded-b-md border-x-popover border-b-popover border-gray-600">
          <div className="flex items-center justify-center rounded-b-md border-gray-700 p-3">
            <span className="text-caption font-bold text-gray-50">Background color</span>
            <div className="ml-2 flex h-6 w-[88px] items-center justify-start rounded bg-gray-700 pl-2">
              <ColorInput
                shapeType={'TestAnimationBackground'}
                styleClass=""
                color={color}
                onChangeColor={updateColor}
                onChangeOpacity={handleOnChangeOpacity}
                opacity={opacity}
                colorStyleClass={color}
                inputStyleClass={'ml-1 w-14 bg-transparent text-caption font-normal uppercase'}
              />
            </div>
          </div>
        </div>
      </RenderVisibility>
    </div>
  );
};
