/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, DagNode, Toolkit } from '@lottiefiles/toolkit-js';
import { StrokeShape, GroupShape, FillShape } from '@lottiefiles/toolkit-js';
import type { WritableDraft } from 'immer/dist/internal';
import type { StoreApi } from 'zustand';

import { PropertyPanelType } from './constant';

import type { StoreSlice } from '.';
import {
  getNodeById,
  findShape,
  defaultCurrentShape,
  defaultCurrentTransformProperty,
  NodeType,
  getCurrentTransformNode,
  getFillShape,
  getStrokeShape,
  defaultCurrentFillShape,
} from '~/lib/toolkit';

export const getCurrentNode = (
  get: StoreApi<StoreSlice>['getState'],
  toolkit: Toolkit,
  nodeId?: string | null,
): DagNode | null => {
  const sceneIndex = get().toolkit.sceneIndex;
  const selectedNodeId = nodeId || (get().ui.selectedNodesInfo[0]?.nodeId as string);
  const scene = toolkit.scenes[sceneIndex];

  if (scene) {
    return getNodeById(scene, selectedNodeId);
  }

  return null;
};

export const getCurrentShapeNode = (
  get: StoreApi<StoreSlice>['getState'],
  toolkit: Toolkit,
  nodeId?: string | null,
): DagNode | null => {
  const sceneIndex = get().toolkit.sceneIndex;
  const selectedNodeId = nodeId || (get().ui.selectedNodesInfo[0]?.nodeId as string);

  const scene = toolkit.scenes[sceneIndex];

  let node;

  if (scene) {
    node = getNodeById(scene, selectedNodeId);
  } else {
    return null;
  }

  if (node instanceof GroupShape) {
    return findShape(node.shapes) || null;
  } else if (node instanceof FillShape || node instanceof StrokeShape) {
    return findShape((node.parent as GroupShape).shapes) || null;
  } else return node;
};

export const clearSelectionState = (draft: WritableDraft<StoreSlice>): void => {
  draft.toolkit.currentNode = {};
  draft.toolkit.currentShape = structuredClone(defaultCurrentShape);
  draft.toolkit.currentSecondary.currentShape = structuredClone(defaultCurrentShape);
  draft.toolkit.currentTransform = { ...defaultCurrentTransformProperty };
  draft.toolkit.currentFillShape = { ...defaultCurrentFillShape };
  draft.ui.currentPropertyPanel = PropertyPanelType.Composition;
  draft.ui.selectedNodesInfo = [];
};

export const getAnimatedNode = (
  nodeType: NodeType,
  get: StoreApi<StoreSlice>['getState'],
  toolkit: Toolkit,
): AVLayer | FillShape | null => {
  let node = null;

  switch (nodeType) {
    case NodeType.TRANSFORM:
      node = getCurrentTransformNode(getCurrentNode(get, toolkit)) as AVLayer;
      break;

    case NodeType.FILL:
      node = (getFillShape(getCurrentNode(get, toolkit)) as unknown) as FillShape;
      break;

    case NodeType.STROKE:
      node = (getStrokeShape(getCurrentNode(get, toolkit)) as unknown) as FillShape;
      break;

    default:
  }

  return node;
};
