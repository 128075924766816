/**
 * Copyright 2022 Design Barn Inc.
 */

export enum EventType {
  AddedKeyframe = 'added-keyframe',
  DownloadedFile = 'downloaded-file',
  SelectedFileMenu = 'selected-file-menu',
  StartSession = 'start-session',
  UploadedAsset = 'uploaded-asset',
}

export enum EventAction {
  CreateNew = 'create new file',
  DownloadFile = 'download file',
  GoToDashboard = 'go to dashboard',
  SaveDotCreator = 'save dotcreator',
  SaveWorkspace = 'export to workspace',
}

export enum AssetSource {
  BasicShapes = 'basic-shapes',
  FileUrl = 'file-url',
  GetStartedJSON = 'get-started-JSON',
  GetStartedStatic = 'get-started-static',
  GetStartedUpload = 'get-started-upload',
  IconScout = 'iconscout',
  Local = 'local',
  LottieLibrary = 'lottie-library',
}

export enum KeyframeSource {
  PropertyPanel = 'property-panel',
  Timeline = 'timeline',
}

export enum FileType {
  DotLottie = 'dotlottie',
  JSON = 'json',
  SVG = 'svg',
}

export enum ClickSource {
  Creator = 'creator',
  PropertyPanel = 'property-panel',
  Timeline = 'timeline',
}
