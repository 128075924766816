/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React, { useCallback, Fragment } from 'react';

import { Minus, Plus } from '~/assets/icons';
import { Accordion } from '~/components/Elements/Accordion';
import { stateHistory } from '~/lib/toolkit';

interface ExtraPropertyAccordionProps {
  showBottomBorder?: boolean;
  showBottomMargin?: boolean;
}

type PropertyAccordionProps = React.ComponentProps<typeof Accordion> & ExtraPropertyAccordionProps;

export const PropertyAccordion: React.FC<PropertyAccordionProps> = ({
  children,
  showBottomBorder = false,
  showBottomMargin = false,
  ...rst
}) => {
  return (
    <div className="flex pb-3 pl-4 pr-3 pt-4">
      <Accordion {...rst}>
        <div className="mt-2 flex w-full flex-col">{children}</div>
      </Accordion>
      {showBottomBorder && !showBottomMargin && <div className="mt-3 border-b border-gray-900"></div>}
      {showBottomMargin && <div className="mt-3"></div>}
    </div>
  );
};

interface PropertyAccordionControlAdderProps {
  onClickAdd(value: string): void;
  options: Array<Record<string, string>>;
}

const PropertyAccordionControlAdder: React.FC<PropertyAccordionControlAdderProps> = ({
  onClickAdd,
  options,
}: PropertyAccordionControlAdderProps) => {
  return (
    <>
      {options.length > 0 && (
        <>
          <Menu as="div" className="relative ml-2 inline-block">
            {() => (
              <>
                <Menu.Button className="group pointer-events-auto flex h-full w-full cursor-pointer items-center justify-center rounded stroke-gray-500 hover:bg-gray-700 hover:stroke-white">
                  <Plus className=" h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="pointer-events-auto absolute right-0 z-dropdown mt-1 w-[98px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 shadow-dropdown focus:outline-none">
                    {options.map((dropdown, index) => {
                      const isLast = index === options.length - 1;

                      const { label, type } = dropdown;

                      return (
                        <div key={type} className={`px-2 ${index === 0 && 'pt-2'} pb-1`}>
                          <Menu.Item>
                            <button
                              className={`group flex h-6 w-full items-center rounded ${
                                isLast && 'mb-1'
                              } pl-2 text-xs text-white hover:bg-gray-600`}
                              onClick={() => onClickAdd(type as string)}
                            >
                              {label}
                            </button>
                          </Menu.Item>
                        </div>
                      );
                    })}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </>
      )}
    </>
  );
};

interface PropertyAccordionControlProps {
  currentCount?: number;
  onChangeAdd?: (count: number | string) => void;
  onChangeRemove?: (count: number) => void;
  options?: Array<Record<string, string>>;
  showAddOnly?: boolean;
  showAddRemove?: boolean;
}

export const PropertyAccordionControl: React.FC<PropertyAccordionControlProps> = ({
  currentCount = 0,
  onChangeAdd,
  onChangeRemove,
  showAddRemove = false,
  showAddOnly = false,
  options = [],
}) => {
  const onClickAdd = useCallback(
    (evt: React.MouseEvent<HTMLDivElement> | string) => {
      stateHistory.beginAction();
      if (options.length > 0) {
        const selection = evt as string;

        if (onChangeAdd) onChangeAdd(selection);
      } else {
        (evt as React.MouseEvent<HTMLDivElement>).preventDefault();

        const newCount = currentCount + 1;

        if (onChangeAdd) onChangeAdd(newCount);
      }
      stateHistory.endAction();
    },
    [onChangeAdd, currentCount, options],
  );

  const onClickRemove = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault();
      const newCount = currentCount - 1;

      if (onChangeRemove) onChangeRemove(newCount);
    },
    [onChangeRemove, currentCount],
  );

  return (
    <div className={`absolute right-[-5px] flex flex-row`}>
      {showAddRemove && (
        <>
          {currentCount > 0 && !showAddOnly && (
            <div className="pointer-events-auto ml-2" onClick={onClickRemove}>
              <Minus className=" h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
            </div>
          )}

          {options.length === 0 && (
            <div className="pointer-events-auto ml-2" onClick={onClickAdd}>
              <Plus className=" h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
            </div>
          )}

          {options.length > 0 && (
            <PropertyAccordionControlAdder options={options} onClickAdd={onClickAdd}></PropertyAccordionControlAdder>
          )}
        </>
      )}
    </div>
  );
};
