/**
 * Copyright 2021 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { AlignPropertyFullWidth } from './AlignProperty';
import { MultipleGradientFill, SingleGradientFillProperty } from './GradientFillProperty';
import { GradientPointsProperty } from './GradientPointsProperty';
import { LayerProperty } from './LayerProperty';
import { SettingsProperty } from './SettingsProperty';
import { MultipleFill, SingleFillProperty } from './ShapeFillProperty';
import { MultipleStroke, SingleStrokeProperty } from './StrokeProperty';
import { TransformProperty } from './TransformProperty';
import { Divider } from './ui/Divider';
import { PropertyAccordionControl, PropertyAccordion } from './ui/PropertyAccordion';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { AppearanceOptions } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

const AppearanceList: React.FC = () => {
  const appearances = useCreatorStore((state) => state.ui.appearanceList);

  return (
    <>
      {Object.entries(appearances).map(([id, type]) => {
        return (
          <span key={id}>
            {type === ShapeType.FILL && <MultipleFill id={id} />}
            {type === ShapeType.STROKE && <MultipleStroke id={id} />}
            {type === ShapeType.GRADIENT_FILL && <MultipleGradientFill id={id} />}
          </span>
        );
      })}
    </>
  );
};

export const AppearanceProperty: React.FC = () => {
  const [appearanceList, addAppearance] = useCreatorStore(
    (state) => [state.ui.appearanceList, state.toolkit.addAppearance],
    shallow,
  );

  const onChangeAdd = useCallback(
    (value: number | string): void => {
      addAppearance(value as string);
      emitter.emit(EmitterEvent.APPEARANCE_CREATED);
    },
    [addAppearance],
  );

  const appearanceLists = Object.entries(appearanceList as Record<string, string>);

  return (
    <PropertyAccordion
      title="Appearance"
      withDisclosure={false}
      disableAccordionButton={true}
      header={
        <PropertyAccordionControl
          currentCount={appearanceLists.length}
          onChangeAdd={onChangeAdd}
          showAddRemove
          showAddOnly={true}
          options={AppearanceOptions}
        />
      }
    >
      <AppearanceList />
    </PropertyAccordion>
  );
};

export const SingleAppearanceProperty: React.FC = () => {
  const property = useCreatorStore((state) => state.ui.currentPropertyPanel);

  if (property === PropertyPanelType.Stroke) {
    return (
      <>
        {/* <Divider /> */}
        <AlignPropertyFullWidth enabled={true} />
        <TransformProperty property={property}>
          <SingleStrokeProperty />
        </TransformProperty>
        <Divider />
        <LayerProperty type={PropertyPanelType.Stroke} />
        {/* <Divider />
        <DashProperty />
        <Divider />
        <SettingsProperty /> */}
      </>
    );
  } else if (property === PropertyPanelType.Fill) {
    return (
      <>
        <AlignPropertyFullWidth enabled={true} />
        <TransformProperty property={property}>
          <SingleFillProperty />
        </TransformProperty>
        <Divider />
        <LayerProperty type={PropertyPanelType.Fill} />
        {/* <Divider />
        <SettingsProperty /> */}
      </>
    );
  } else if (property === PropertyPanelType.GradientFill) {
    return (
      <>
        <AlignPropertyFullWidth enabled={true} />
        <TransformProperty property={'Fill' as PropertyPanelType}>
          <SingleGradientFillProperty />
        </TransformProperty>
        <Divider />
        <GradientPointsProperty />
        <Divider />
        <SettingsProperty />
      </>
    );
  }

  return <></>;
};
