/**
 * Copyright 2022 Design Barn Inc.
 */

import axios from 'axios';

import { AUTH_AJAX_ENDPOINT } from '~/config';

const axiosConfig = {
  baseURL: AUTH_AJAX_ENDPOINT as string,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
  // Enable cookies on header request
  withCredentials: true,
  xsrfCookieName: '',
};

// Create Axios
const service = axios.create(axiosConfig);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      return error.response;
    }

    return error;
  },
);

// Request
service.interceptors.request.use(
  (config) => {
    return config;
  },
  async (error) => {
    // Do something with request error
    // console.log(error);
    return Promise.reject(error);
  },
);

export default service;
