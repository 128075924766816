/**
 * Copyright 2023 Design Barn Inc.
 */

import type { GroupShape, Layer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  setRotation: 'setRotation',
  setScale: 'setScale',
  setSkew: 'setSkew',
  setSkewAxis: 'setSkewAxis',
  setPosition: 'setPosition',
  setPivot: 'setPivot',
  setOpacity: 'setOpacity',
  setAnchor: 'setAnchor',
  absolutePosition: 'absolutePosition',
  pivot: 'pivot',
  pivotAtKeyFrame: 'pivotAtKeyFrame',
  getPositionRelativeTo: 'getPositionRelativeTo',
  rotation: 'rotation',
  scale: 'scale',
  skew: 'skew',
  skewAxis: 'skewAxis',
  position: 'position',
  opacity: 'opacity',
  anchorPoint: 'anchorPoint',
  setAbsolutePosition: 'setAbsolutePosition',
};

export function getTransformableMethods(plugin: Plugin, layer: GroupShape | Layer): ObjectMethods {
  return getObjectMethods(plugin, methodNames, layer);
}
