/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StarShape } from '@lottiefiles/toolkit-js/dist';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PolystarInnerRadius } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MIN_POLYSTAR_RADIUS, MAX_POLYSTAR_RADIUS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PolystarInnerRadiusField: React.FC = () => {
  const [
    currentShapeNode,
    innerRadius,
    setInnerRadius,
    setPolystarVariableIsAnimated,
    currentKeyFrame,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentSecondary?.currentShapeNode,
      state.toolkit.currentSecondary?.currentShape.polystar.innerRadius,
      state.toolkit.setPolystarInnerRadius,
      state.toolkit.setPolystarVariableIsAnimated,
      state.toolkit.currentSecondary?.currentShape.polystar.innerRadiusCurrentKeyFrame,
    ],
    shallow,
  );
  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setInnerRadius(result.value);
      emitter.emit(EmitterEvent.POLYSTAR_INNER_RADIUS_UPDATED);
    },
    [setInnerRadius],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyFrame === '') {
      setPolystarVariableIsAnimated('innerRadius', true);
      setInnerRadius(innerRadius);
    } else if ((currentShapeNode as StarShape).innerRadius.keyFrames.length === 1) {
      setPolystarVariableIsAnimated('innerRadius', false);
      setInnerRadius(innerRadius);
    } else {
      removeKeyFrame(currentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_INNER_RADIUS_UPDATED);
    stateHistory.endAction();
  }, [setInnerRadius, setPolystarVariableIsAnimated, currentShapeNode, currentKeyFrame, innerRadius]);

  return (
    <div className="w-full">
      <NumberInput
        name="inner-radius"
        label={<PolystarInnerRadius className="h-4 w-4" />}
        value={(currentShapeNode as StarShape)?.innerRadius?.value.value ?? innerRadius}
        onChange={handleOnChange}
        min={MIN_POLYSTAR_RADIUS}
        max={MAX_POLYSTAR_RADIUS}
        showKeyframe
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={Boolean(currentKeyFrame)}
      />
    </div>
  );
};
