/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import type { UniqueIdentifier } from '@dnd-kit/core';
import type {
  AnimatedPropertiesJSON,
  AnimatedPropertyJSON,
  LayerJSON,
  SceneJSON,
  ShapeJSON,
  ShapeLayerJSON,
  ShapeType,
} from '@lottiefiles/toolkit-js';
import { PropertyType } from '@lottiefiles/toolkit-js';
import { cloneDeep } from 'lodash-es';

import { flattenTree } from './TimelineLayerPanel/DraggableWrapper/utilities';

import { toolkit } from '~/lib/toolkit';
import { AppearanceTypes } from '~/lib/toolkit/appearance';
import { LAYER_TYPES } from '~/lib/toolkit/constant';
import { useCreatorStore } from '~/store';
import type { Optional } from '~/types';

// Decides the order of animated properties displayed within the timeline.
const ANIMATED_SORT_ORDER = [
  PropertyType.POSITION,
  PropertyType.SCALE,
  PropertyType.SIZE,
  PropertyType.FILL_COLOR,
  PropertyType.OPACITY,
  PropertyType.ROTATION,
  PropertyType.STROKE_COLOR,
  PropertyType.STROKE_WIDTH,
  PropertyType.ROUNDNESS,
  PropertyType.NUMBER_OF_POINTS,
  PropertyType.INNER_RADIUS,
  PropertyType.OUTER_RADIUS,
  PropertyType.INNER_ROUNDNESS,
  PropertyType.OUTER_ROUNDNESS,
];

export const getAnimatedProperties = (
  animatedProperties: AnimatedPropertiesJSON,
): [Record<string, AnimatedPropertyJSON>, boolean] => {
  let hasAnimated = false;
  const animated = Object.keys(animatedProperties)
    .filter((key) => animatedProperties[key as PropertyType].isAnimated)
    .reduce((obj, key) => {
      hasAnimated = true;
      obj[key] = animatedProperties[key as PropertyType];

      return obj;
    }, {} as Record<string, AnimatedPropertyJSON>);

  return [animated, hasAnimated];
};

const getKeyFrameIds = (animatedProperties: AnimatedPropertiesJSON): string[] => {
  let kfIds: string[] = [];

  kfIds = Object.keys(animatedProperties)
    .filter((key) => animatedProperties[key as PropertyType].isAnimated)
    .reduce((arr: string[], key) => {
      const animatedPropertiesKey = animatedProperties[key as PropertyType];

      if (animatedPropertiesKey.keyFrames.length > 0) {
        const frameIds = animatedPropertiesKey.keyFrames.map((apk) => apk.frameId);

        if (frameIds.length > 0) {
          arr.push(...(frameIds as string[]));
        }
      }

      return arr;
    }, []);

  return kfIds;
};

export const getNestedAnimatedKeyFrames = (
  keyframesIds: string[],
  layer: ShapeLayerJSON | ShapeJSON | LayerJSON,
): void => {
  const keyframes = getKeyFrameIds(layer.animatedProperties);

  if (keyframes.length > 0) {
    keyframes.map((kf) => keyframesIds.push(kf));
  }
  if (layer.shapes && layer.shapes.length > 0) {
    layer.shapes.map((layerShape: ShapeLayerJSON | ShapeJSON | LayerJSON) => {
      return getNestedAnimatedKeyFrames(keyframesIds, layerShape);
    });
  }
};

export const hasAnimation = (layer: LayerJSON): boolean => {
  return (
    Object.keys(layer.animatedProperties).filter((key) => layer.animatedProperties[key as PropertyType].isAnimated)
      .length > 0
  );
};

const updateLayerMap = (
  layer: ShapeLayerJSON | ShapeJSON | LayerJSON,
  layerMap: LayerUIMap,
  oldLayerMap: LayerUIMap,
  level: number,
  descendant: string[],
  children: string[],
  parent: string[],
  last: boolean,
): void => {
  const { animatedProperties } = layer;

  let expanded = false;
  let highlight = false;
  let timelineTrackStart = 0;
  let timelineTrackEnd = 100;
  let timelineTrackDuration = (useCreatorStore.getState().toolkit.json?.timeline.duration as number) || 5;

  // get oldLayerUI if exists
  const oldLayerUI = oldLayerMap.get(layer.id);

  // checking duplicated layer has existing value
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  let possibleClonedLayer = null;

  if (scene) {
    possibleClonedLayer = toolkit.getNodeById(layer.id);
  }

  if (oldLayerUI) {
    // Compare and maintain previous state
    expanded = oldLayerUI.expanded || expanded;
    highlight = oldLayerUI.highlight || highlight;

    timelineTrackStart = oldLayerUI.timelineTrackStart || timelineTrackStart;
    timelineTrackEnd = oldLayerUI.timelineTrackEnd || timelineTrackEnd;
    timelineTrackDuration = oldLayerUI.timelineTrackDuration || timelineTrackDuration;
  }

  const [animated, hasAnimated] = getAnimatedProperties(animatedProperties);

  if (layer.type === 'PRECOMPOSITION' && !oldLayerUI) {
    // run once when precomp newly imported
    const fps = useCreatorStore.getState().toolkit.json?.timeline.properties.fr as number;

    const inPoint = layer.properties.ip as number;
    const outPoint = layer.properties.op as number;

    timelineTrackStart = (inPoint / fps / timelineTrackDuration) * 100;
    timelineTrackEnd = (outPoint / fps / timelineTrackDuration) * 100;
  } else if (
    level === 0 &&
    possibleClonedLayer &&
    possibleClonedLayer.type === 'SHAPE' &&
    possibleClonedLayer.state &&
    possibleClonedLayer.state.properties
  ) {
    const fps = useCreatorStore.getState().toolkit.json?.timeline.properties.fr as number;

    const inPoint = possibleClonedLayer.state.properties.ip as number;
    const outPoint = possibleClonedLayer.state.properties.op as number;

    timelineTrackStart = (inPoint / fps / timelineTrackDuration) * 100;
    timelineTrackEnd = (outPoint / fps / timelineTrackDuration) * 100;
  }

  // eslint-disable-next-line prefer-const
  let frameIds: string[] = [];

  const isNullLayer = layer.type === 'GROUP' && 'effects' in layer;

  if ((level === 0 && ['SHAPE', 'PRECOMPOSITION'].includes(layer.type)) || isNullLayer) {
    getNestedAnimatedKeyFrames(frameIds, layer);
  }

  const layerUI = {
    type: 'layer',
    expanded,
    animated: Object.keys(animated)
      .map((key) => ({
        type: key,
        id: animated[key]?.id || '',
      }))
      .sort((alpha, beta) => {
        let aOrd = ANIMATED_SORT_ORDER.indexOf(alpha.type as PropertyType);
        let bOrd = ANIMATED_SORT_ORDER.indexOf(beta.type as PropertyType);

        // If a or b wasn't found in the ANIMATED_SORT_ORDER list, push them to the bottom
        if (aOrd === -1) {
          aOrd = ANIMATED_SORT_ORDER.length;
        }

        if (bOrd === -1) {
          bOrd = ANIMATED_SORT_ORDER.length;
        }

        return aOrd - bOrd;
      }),
    isAppearance: AppearanceTypes.includes(layer.type as ShapeType),
    appearanceType: layer.type,
    highlight,
    level,
    descendant,
    frameIds,
    children,
    rerender: false,
    parent,
    last,
    ...(LAYER_TYPES.includes(layer.type)
      ? {
          timelineTrackStart,
          timelineTrackEnd,
          timelineTrackDuration,
        }
      : {}),
  };

  layerMap.set(layer.id, layerUI);

  // Animated Properties
  if (hasAnimated) {
    const length = Object.keys(animated).length;

    Object.keys(animated).forEach((key, i) => {
      const animatedProp = animated[key];

      if (animatedProp && animatedProp.id) {
        const animId = animatedProp.id;

        const animatedUI: LayerUI = {
          type: 'animated',
          parent: [...parent, layer.id],
          expanded: false,
          animated: [],
          level,
          highlight: false,
          children: [],
          descendant: [],
          rerender: false,
          last: length === i + 1,
        };

        layerMap.set(animId, animatedUI);
      }
    });
  }
};

type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

const traverseShape = (
  layer: OptionalShapeLayer | ShapeJSON | LayerJSON | null,
  layerMap: LayerUIMap,
  oldLayerMap: LayerUIMap,
  parent: string[],
  level: number,
  isLastChild: boolean,
): string[] => {
  if (!layer) return [];

  const shapeLayer = layer as OptionalShapeLayer;

  // Save currentParent to be store in Layer Map
  const currentParent = [...parent];

  parent.push(layer.id);

  // Get children
  const descendant: string[] = [];
  const children: string[] = [];

  // Store animated property as children as well ??
  const [animated, hasAnimated] = getAnimatedProperties(layer.animatedProperties);

  // Store animated value
  if (hasAnimated) {
    Object.keys(animated).forEach((key) => {
      const animatedProp = animated[key];

      if (animatedProp && animatedProp.id) {
        descendant.push(animatedProp.id);
      }
    });
  }

  // Traverse shape and layers
  if (shapeLayer.shapes) {
    const length = shapeLayer.shapes.length - 1;

    shapeLayer.shapes.forEach((_layer, i) => {
      // Check whether it is the last node
      const last = i === length;
      const newParent = [...parent];
      const descendantPath = traverseShape(_layer, layerMap, oldLayerMap, newParent, level + 1, last);

      children.push(_layer.id);
      descendant.push(...descendantPath);
    });
  }

  updateLayerMap(layer, layerMap, oldLayerMap, level, [...descendant], [...children], [...currentParent], isLastChild);
  // Insert into children list
  descendant.unshift(layer.id);

  return descendant;
};

export interface AnimatedUI {
  id: string;
  type: string;
}

export interface SimplifiedLayer {
  isGroup: boolean;
  layers: any;
}

export interface LayerUI {
  [key: string]: unknown;

  // animated properties info
  animated: AnimatedUI[];

  belongToGroupNodeId?: string;
  // for simplifiedLayer children/shape ids
  belongToNodeId?: string;

  // children of the node
  children: string[];

  cloneByLayerId?: string;

  // all the descendants from the node
  descendant: string[];

  // whether the layer is expanded
  expanded: boolean;

  frameIds: string[];

  // whether the layer is highlighted
  highlight: boolean;

  // whether the layer is appearance
  isAppearance?: boolean;

  // whether the layer is the last node of the level
  last: boolean;

  // layer level or depth
  level: number;

  // parent list of the layer
  parent: string[];
  // rerender
  rerender: boolean;

  timelineTrackDuration?: number;

  timelineTrackEnd?: number;

  timelineTrackStart?: number;

  // the layer type
  type: string;
}

export type LayerUIMap = Map<string, LayerUI>;

export const getLayerMap = (json: SceneJSON, oldLayerMap: LayerUIMap): LayerUIMap => {
  const layerMap = new Map<string, LayerUI>();

  json.allLayers.forEach((layer) => {
    if (
      layer.type === 'SHAPE' ||
      layer.type === 'PRECOMPOSITION' ||
      layer.type === 'SOLID' ||
      layer.type === 'IMAGE' ||
      layer.type === 'GROUP'
    ) {
      traverseShape(layer, layerMap, oldLayerMap, [], 0, true);
    }
  });

  return layerMap;
};

export type LayerSimplifiedUIMap = Map<string, any>;

export const simplifiedLayersFromTree = (flattenedTree: any): void => {
  const SidePanelShapeTypes = ['el', 'sr', 'rc'];
  const shapeTrees = cloneDeep(
    flattenedTree.filter((fTree: any) => {
      return fTree.type === 'SHAPE';
    }),
  );

  const _layerMap = useCreatorStore.getState().ui.layerMap;

  const setLayerSimplifiedUI = useCreatorStore.getState().ui.setLayerSimplifiedUI;

  const simplifiedTree = shapeTrees.map((shapeTree) => {
    const descNode = _layerMap.get(shapeTree.id);
    const shapes = [];

    const shapeLayerAnimated = descNode.animated;

    if (descNode.descendant.length > 0) {
      descNode.descendant.forEach((descendantId: string) => {
        const descDescNode = _layerMap.get(descendantId);

        if (descDescNode.appearanceType === 'gr') {
          const shapeNode = {
            groupShapeId: descendantId,
            shapes: [],
            appearances: [],
            animatedIds: [...shapeLayerAnimated],
            shapesAppearances: [],
          };

          const shapesAppearancesIds = [];

          if (descDescNode.descendant.length > 0) {
            descDescNode.descendant.forEach((descDescendantId: string) => {
              const groupDescNode = _layerMap.get(descDescendantId);
              const json = { ...flattenedTree.find((ftree) => ftree.id === descDescendantId) };

              const level = shapeTree.depth;

              // roundness, points, outer radius, outer roundness, inner radius, inner roundness
              const animatedKeys = [...AppearanceTypes, 'r', 'sz', 'rd', 'nt', 'or', 'os', 'ir', 'is'];

              if (groupDescNode.animated.length > 0) {
                groupDescNode.animated.forEach((descNodeAnimated) => {
                  if (animatedKeys.includes(descNodeAnimated.type)) {
                    shapeNode.animatedIds.push({
                      type: descNodeAnimated.type,
                      id: descNodeAnimated.id,
                    });
                  }
                });
              }
              if (animatedKeys.includes(groupDescNode.appearanceType)) {
                shapeNode.animatedIds.push({
                  type: groupDescNode.appearanceType,
                  id: descDescendantId,
                });
              }

              // basic shapeNode (Circle, )
              if (SidePanelShapeTypes.includes(groupDescNode.appearanceType)) {
                shapeNode.shapes.push(json);
                shapesAppearancesIds.push(descDescendantId);
                setLayerSimplifiedUI(descDescendantId, 'level', level);
                // shapeTree.depth
              }

              // appearanceNode
              if (AppearanceTypes.includes(groupDescNode.appearanceType)) {
                shapeNode.appearances.push(json);
                shapesAppearancesIds.push(descDescendantId);
                setLayerSimplifiedUI(descDescendantId, 'level', level);
              }
            });
            shapeNode.shapesAppearances = [...shapeNode.shapes, ...shapeNode.appearances];
            shapes.push(shapeNode);
          }

          if (shapesAppearancesIds.length > 0) {
            setLayerSimplifiedUI(shapeTree.id, 'shapesAppearancesIds', shapesAppearancesIds);
          }
        }
      });
    }

    let hasGroup = false;

    if (shapes.length > 1) {
      hasGroup = true;
    }

    const layerSimplified = {
      isGroup: hasGroup,
      layers: shapes,
    };

    if (hasGroup === false) {
      // Single Layer
      const singleLayer = layerSimplified.layers[0]?.shapes[0];

      if (singleLayer) {
        setLayerSimplifiedUI(shapeTree.id, 'belongToNodeId', singleLayer.id);
        setLayerSimplifiedUI(shapeTree.id, 'belongToGroupNodeId', layerSimplified.layers[0].groupShapeId);
        setLayerSimplifiedUI(shapeTree.id, 'parent', []);
      }
    }

    shapeTree.simplified = layerSimplified;

    return shapeTree;
  });

  return simplifiedTree;
};

export const getFlattenLayers = (layers: LayerJSON[], activeId: string): any => {
  const removeLayersChildren = (items: any, ids: string[]): LayerJSON[] => {
    const excludeParentIds = [...ids];

    return items.filter((item: any) => {
      if (item.parentId && excludeParentIds.includes(item.parentId)) {
        if (item.shapes?.length) {
          excludeParentIds.push(item.id);
        }
      }

      return true;
    });
  };

  const flattenedTree = flattenTree(layers as any);

  const simplifiedTree = simplifiedLayersFromTree(flattenedTree as any);

  const collapsedItems = simplifiedTree.reduce<UniqueIdentifier[]>(
    (acc, { collapsed, id, shapes }) => (collapsed && shapes?.length ? [...acc, id] : acc),
    [],
  );

  const activeChildren = activeId ? [activeId, ...collapsedItems] : collapsedItems;
  const result = removeLayersChildren(simplifiedTree, activeChildren as string[]);

  return result;
};

export const resetTimelineLayerPopup = (): void => {
  const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;

  setTimelineContext({
    selectedId: null,
    mousePos: { x: 0, y: 0 },
  });
};
