/**
 * Copyright 2023 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import { copyIntoVmObject } from './object';

import type { Plugin } from '~/plugins/Plugin';

// create an equivalent array for QuickJS
export function newQuickjsArray(plugin: Plugin, array: object, includeFunctions?: boolean): QuickJSHandle {
  const vmArray = plugin.vm.newArray();

  copyIntoVmObject(plugin, array, vmArray, includeFunctions);

  return vmArray;
}
