/**
 * Copyright 2022 Design Barn Inc.
 */

import { Dialog } from '@headlessui/react';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import React from 'react';

import { TextInput } from './TextInput';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { HyperLink } from '~/assets/icons';
import { Modal } from '~/components/Elements/Modal';
import { getPluginManager } from '~/plugins/PluginManager';
import { useCreatorStore } from '~/store';

export const NewPluginModal: React.FC = () => {
  const setPluginBrowserOpen = useCreatorStore.getState().ui.setPluginBrowserOpen;
  const newPluginModalOpen = useCreatorStore((state) => state.ui.newPluginModalOpen);
  const setNewPluginModalOpen = useCreatorStore.getState().ui.setNewPluginModalOpen;

  const [url, setUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const pluginManager = getPluginManager();

  const loadingAnimation = (
    <div className="flex h-[267px] w-full items-center justify-center">
      <div className="mb-[16px] h-20 w-20">
        <LottiePlayer renderer="svg" autoplay loop className="h-20 w-20" src={LoadingAnimation} />
      </div>
    </div>
  );

  const form = (
    <div className="h-[267px] w-full p-[20px]">
      <div className="mb-[5px]">
        <div className="mb-[10px] text-xs text-gray-300">Development URL</div>
        <TextInput
          placeholder="Enter development localhost link"
          icon={
            <HyperLink className="absolute top-[1px] mx-2 my-1 inline h-[24px] w-[24px] fill-gray-500 dark:text-white" />
          }
          onChange={(value) => setUrl(value)}
          value={url}
        />
      </div>
      <div className="pb-[20px]">
        <span className="text-xs text-gray-400">
          Development link must be a localhost link starting with <code>http://localhost/</code>
          <br />
          <br />
          All other details (e.g., your plugin&apos;s name will be fetched from the Manifest file.
        </span>
      </div>

      <div className="flex justify-end">
        <button
          className="rounded bg-teal-400 p-[5px] text-[12px] text-white"
          onClick={() => {
            let baseUrl;

            try {
              baseUrl = new URL(url);
            } catch (err) {
              // Invalid url
              // TODO: handle error
              return;
            }

            setIsLoading(true);

            pluginManager
              .loadDevPlugin(baseUrl)
              // eslint-disable-next-line promise/prefer-await-to-then
              .then(() => {
                setIsLoading(false);
                setNewPluginModalOpen(false);
                setPluginBrowserOpen(true);
              })
              // eslint-disable-next-line promise/prefer-await-to-then, node/handle-callback-err
              .catch((_err) => {
                setIsLoading(false);
                // TODO: error handling
              });
          }}
        >
          Create Plugin
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={newPluginModalOpen}
      onClose={() => {
        if (!isLoading) {
          setNewPluginModalOpen(false);
          setPluginBrowserOpen(true);
        }
      }}
    >
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-[360px] rounded-md bg-gray-700 p-0 align-middle shadow-xl">
          <Dialog.Title className="flex border-b border-gray-900 p-6 py-4 text-left text-lg font-medium leading-6 text-gray-100">
            Create a New Plugin
          </Dialog.Title>
          {isLoading ? loadingAnimation : form}
        </Dialog.Panel>
      </div>
    </Modal>
  );
};
