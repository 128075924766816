/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

export interface DragContextType {
  playheadNodeRef: any;
  playheadPos: number | null;
  setPlayheadNodeRef: (playNodeRef: any) => void;
  setPlayheadPos: (pos: number) => void;
}

const drag: DragContextType = {
  playheadPos: null,
  setPlayheadPos: () => {},
  playheadNodeRef: null,
  setPlayheadNodeRef: () => {},
};

export const DragContext = React.createContext<DragContextType>(drag);
