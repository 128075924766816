/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Close } from '~/assets/icons';
import { useCreatorStore } from '~/store';

export const TestAnimationHeader: React.FC = () => {
  const setTestAnimationOpen = useCreatorStore((state) => state.ui.setTestAnimationOpen);

  return (
    <div
      id="test-animation-header"
      className="box-border cursor-move select-none rounded-t-md border-x-popover border-t-popover border-gray-600"
    >
      <div className="box-border flex items-center justify-between rounded-t-md border-gray-700 px-4 py-2">
        <span className="h-6 w-[200px] text-test-animation-header font-bold">Test animation for web player</span>
        <button>
          <Close
            className="h-4 w-4 cursor-pointer fill-current text-white hover:opacity-50"
            onClick={() => setTestAnimationOpen(false)}
          />
        </button>
      </div>
    </div>
  );
};
