/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useContext } from 'react';

import { UploadContext } from './UploadContext';

import { Error, Close } from '~/assets/icons';

interface Props {}

export const UploadErrorPanel: React.FC<Props> = () => {
  const { error, setError } = useContext(UploadContext);

  return (
    <div className={clsx(error ? 'banner-error' : '', 'm-3')}>
      <Error className="h-8 w-8" />
      <div className="w-full">
        <h1 className="font-lf-semi-bold">{error?.name}</h1>
        <p className="">{error?.actionMessage}</p>
      </div>
      <button className="h-4 w-4">
        <Close className="h-4 w-4 cursor-pointer  hover:opacity-50" onClick={() => setError(null)} />
      </button>
    </div>
  );
};
