/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

export const CompTimelineTab: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 12H4V14" stroke="#5359FD" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 2V4H2" stroke="#5359FD" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 4H12V2" stroke="#5359FD" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 14V12H14" stroke="#5359FD" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="4" y="4" width="8" height="8" stroke="#5359FD" strokeLinejoin="round" />
    </svg>
  );
});

export const TimelineSidebarPlus: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.667 8H3.333M8 12.666V3.333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

export const TimelineTopbarChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="#22292F" d="M0 0h16v16H0z" />
      <path
        d="M11.333 7.333 8 10.666 4.667 7.333"
        stroke="#606F7B"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const TimelineSidebarMarker: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          d="M5.266 1.936h5.495a1.26 1.26 0 0 1 1.26 1.26v7.49l-4.007 4.008-4.008-4.007v-7.49a1.26 1.26 0 0 1 1.26-1.26Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const TimelineSidebarStar: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 2.333V8m0 5.666V8m5.333-3L8 8m0 0-5.333 3M8 8 2.667 5M8 8l5.333 3" strokeLinecap="round" />
    </svg>
  );
});

export const LayerChevronCollapse: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m7.5 6 2 2-2 2" />
      <path d="m7.5 6 2 2-2 2V6Z" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

export const LayerAnimated: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.2 9.7c.6-1.5-.1-3.2-1.6-3.8-1.5-.6-3.2.1-3.8 1.6-.6 1.5.1 3.2 1.6 3.8 1.5.6 3.2-.1 3.8-1.6z" />
      <path d="M8.1 5.7c-.3 0-.6.1-.9.2.7.6 1.2 1.6 1.2 2.7s-.5 2.1-1.2 2.7c.3.1.6.2.9.2 1.5 0 2.8-1.3 2.8-2.9S9.6 5.7 8.1 5.7z" />
      <path d="M11.6 5.7c-.3 0-.6.1-.8.2.7.6 1.1 1.6 1.1 2.8s-.4 2.1-1.1 2.8c.3.1.6.2.8.2 1.5 0 2.8-1.3 2.8-2.9s-1.2-3.1-2.8-3.1z" />
    </svg>
  );
});

export const LayerAnimatedShapeSpecific: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.351 9.025a.53.53 0 000-.435L6.963 3.12a.431.431 0 00-.373-.218c-.155 0-.28.094-.373.218L2.83 8.59a.373.373 0 000 .435c.093.124.217.218.373.218h6.775c.156 0 .311-.094.373-.218z"
        fill="#606F7B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.848 8.621c.25.435-.062 1.026-.559 1.026h-3.45a.41.41 0 00-.404.466 3.39 3.39 0 00.622 1.772c.373.528.87.932 1.46 1.18.59.25 1.213.311 1.865.218a3.35 3.35 0 001.679-.777 3.5 3.5 0 001.025-1.554 3.45 3.45 0 00.062-1.865 3.522 3.522 0 00-.932-1.616 3.396 3.396 0 00-1.647-.87c-.249-.062-.467.093-.529.342-.03.093 0 .186.031.28"
        fill="#606F7B"
      />
      <path
        d="M10.351 9.025a.53.53 0 000-.435L6.963 3.12a.431.431 0 00-.373-.218c-.155 0-.28.094-.373.218L2.83 8.59a.373.373 0 000 .435c.093.124.217.218.373.218h6.775c.156 0 .311-.094.373-.218zm.497-.404c.25.435-.062 1.026-.559 1.026h-3.45a.41.41 0 00-.404.466 3.39 3.39 0 00.622 1.772c.373.528.87.932 1.46 1.18.59.25 1.213.311 1.865.218a3.35 3.35 0 001.679-.777 3.5 3.5 0 001.025-1.554 3.45 3.45 0 00.062-1.865 3.522 3.522 0 00-.932-1.616 3.396 3.396 0 00-1.647-.87c-.249-.062-.467.093-.529.342-.03.093 0 .186.031.28l.777 1.398z"
        stroke="#2B343B"
        strokeWidth={0.6}
      />
    </svg>
  );
});

export const LayerAddAnimated: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)" stroke="#272E34" strokeWidth={0.686} strokeLinecap="round" strokeLinejoin="round">
        <path d="M3.861 2.64H1.14M2.5 4V1.279" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.167 .306)" d="M0 0h4.667v4.667H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const LayerThumbnailShape: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.13 9.59a2.264 2.264 0 1 0 2.734-2.356"
        stroke="#F5F8FA"
        strokeWidth={0.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.938 4.443 4.347 8.618h5.172L6.938 4.443Z"
        stroke="#F5F8FA"
        strokeWidth={0.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const LayerThumbnailGroup: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.996 4.332a.333.333 0 0 0-.334-.333H7.33a.333.333 0 0 0 0 .666h1.332c.184 0 .334-.149.334-.333Zm.999 0c0 .184.15.333.333.333a1 1 0 0 1 1 1 .333.333 0 0 0 .666 0c0-.92-.746-1.666-1.666-1.666a.333.333 0 0 0-.333.333Zm1.999 2.998a.333.333 0 0 0-.667 0v1.333a.333.333 0 0 0 .667 0V7.33Zm0 2.998a.333.333 0 0 0-.667 0 1 1 0 0 1-.999 1 .333.333 0 1 0 0 .666c.92 0 1.666-.746 1.666-1.666Zm-4.997 1.333c0-.184.149-.333.333-.333h1.332a.333.333 0 1 1 0 .666H7.33a.333.333 0 0 1-.333-.333Zm-1-7.329a.333.333 0 0 0-.333-.333C4.744 3.999 4 4.745 4 5.665a.333.333 0 1 0 .666 0 1 1 0 0 1 1-1c.183 0 .332-.149.332-.333Zm-.333 6.996a.333.333 0 1 1 0 .666c-.92 0-1.665-.746-1.665-1.666a.333.333 0 1 1 .666 0 1 1 0 0 0 1 1Zm-1-2.665a.333.333 0 0 1-.665 0V7.33a.333.333 0 1 1 .666 0v1.333Z"
        fill="#F5F8FA"
      />
    </svg>
  );
});

export const LayerThumbnailPath: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="12" height="12" rx="2.5" fill="#22292F" />
      <g clipPath="url(#clip0_1053_483468)">
        <rect
          width="1.5"
          height="1.5"
          transform="matrix(-1 0 0 1 9.16699 7.00098)"
          stroke="#F5F8FA"
          strokeWidth="0.416566"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 7.00081C8.44045 5.9536 7.7626 5.07563 6.83333 4.74121"
          stroke="#F5F8FA"
          strokeWidth="0.416566"
          strokeLinecap="round"
        />
        <rect
          x="2.8335"
          y="7.00098"
          width="1.5"
          height="1.5"
          stroke="#F5F8FA"
          strokeWidth="0.416566"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 7.00081C3.55955 5.9536 4.2374 5.07563 5.16667 4.74121"
          stroke="#F5F8FA"
          strokeWidth="0.416566"
          strokeLinecap="round"
        />
        <rect
          x="5.25342"
          y="3.66699"
          width="1.5"
          height="1.5"
          stroke="#F5F8FA"
          strokeWidth="0.416566"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path d="M8.58626 4.50098H6.75293" stroke="#F5F8FA" strokeWidth="0.416566" />
        <ellipse cx="8.6696" cy="4.48405" rx="0.416667" ry="0.416667" fill="#F5F8FA" />
        <path d="M3.41667 4.50098H5.25" stroke="#F5F8FA" strokeWidth="0.416566" />
        <ellipse
          cx="0.416667"
          cy="0.416667"
          rx="0.416667"
          ry="0.416667"
          transform="matrix(-1 0 0 1 3.75 4.06738)"
          fill="#F5F8FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_483468">
          <rect width="8" height="8" fill="white" transform="translate(2.00049 2)" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const LayerThumbnailRectangle: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="12" height="12" rx="1.875" fill="#2B343B" />
      <path
        d="M8.1119 3.33398H3.88941C3.58266 3.33398 3.33398 3.58266 3.33398 3.88941V8.1119C3.33398 8.41865 3.58266 8.66732 3.88941 8.66732H8.1119C8.41865 8.66732 8.66732 8.41865 8.66732 8.1119V3.88941C8.66732 3.58266 8.41865 3.33398 8.1119 3.33398Z"
        stroke="white"
        strokeWidth="0.416566"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const LayerThumbnailEllipse: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="12" height="12" rx="1.88" fill="#22292F" />
      <path
        d="M3.25146 5.91848C3.25146 7.3917 4.44575 8.58598 5.91897 8.58598C7.39219 8.58598 8.58647 7.3917 8.58647 5.91848C8.58647 4.44526 7.39219 3.25098 5.91897 3.25098C4.44575 3.25098 3.25146 4.44526 3.25146 5.91848Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const LayerThumbnailPolygon: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="12" height="12" rx="1.875" fill="#2B343B" />
      <g clipPath="url(#clip0_1046_193241)">
        <path
          d="M6.00029 3.33391L3.00028 8.16724L8.98799 8.16724L6.00029 3.33391Z"
          stroke="white"
          strokeWidth="0.420407"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1046_193241">
          <rect width="8" height="8" fill="white" transform="translate(2.00049 2)" />
        </clipPath>
      </defs>
    </svg>

    // <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    //   <g clipPath="url(#a)">
    //     <path
    //       d="M7.998 4.443 4 10.886h7.982L7.998 4.443Z"
    //       stroke="#fff"
    //       strokeWidth={0.673}
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="a">
    //       <path fill="#fff" transform="translate(2.666 2.666)" d="M0 0h10.664v10.664H0z" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
});

export const LayerThumbnailStar: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="12" height="12" rx="2.5" fill="#22292F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00066 3.08398C6.09763 3.08398 6.18585 3.14006 6.22702 3.22786L6.99402 4.86393L8.70493 5.12421C8.79757 5.13831 8.87456 5.20303 8.90435 5.29188C8.93415 5.38073 8.91175 5.47879 8.84633 5.54589L7.60101 6.82322L7.89481 8.62713C7.9102 8.72159 7.87031 8.81655 7.79207 8.87167C7.71384 8.9268 7.611 8.93243 7.52722 8.88617L6.00066 8.04324L4.4741 8.88617C4.39031 8.93243 4.28747 8.9268 4.20924 8.87167C4.13101 8.81655 4.09112 8.72159 4.1065 8.62713L4.40031 6.82322L3.15499 5.54589C3.08957 5.47879 3.06717 5.38073 3.09696 5.29188C3.12676 5.20303 3.20374 5.13831 3.29639 5.12421L5.0073 4.86393L5.7743 3.22786C5.81546 3.14006 5.90369 3.08398 6.00066 3.08398ZM6.00066 3.92294L5.40331 5.19711C5.36788 5.27269 5.29707 5.3256 5.21455 5.33815L3.85274 5.54533L4.84633 6.56445C4.90168 6.62123 4.92682 6.7009 4.91407 6.77916L4.68268 8.19983L5.87981 7.53881C5.95502 7.49728 6.04629 7.49728 6.1215 7.53881L7.31863 8.19983L7.08724 6.77916C7.0745 6.7009 7.09963 6.62123 7.15499 6.56445L8.14857 5.54533L6.78676 5.33815C6.70424 5.3256 6.63343 5.27269 6.598 5.19711L6.00066 3.92294Z"
        fill="white"
      />
    </svg>

    // <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    //   <path
    //     fillRule="evenodd"
    //     cl11c.13 0 .247.075.302.192l1.023 2.181 2.28.347a.333.333 0 0 1 .189.562l-1.66 1.703.391 2.404a.333.333 0 0 1-.49.346L7.998 10.72l-2.034 1.124a.333.333 0 0 1-.49-.346l.391-2.404-1.66-1.703a.333.333 0 0 1 .189-.562l2.28-.347 1.023-2.18a.333.333 0 0 1 .301-.193Zm0 1.119-.796 1.698a.333.333 0 0 1-.251.188l-1.816.276L6.46 8.75a.333.333 0 0 1 .09.286l-.308 1.894 1.595-.881a.333.333 0 0 1 .323 0l1.595.88-.308-1.893a.333.333 0 0 1 .09-.286l1.325-1.359-1.816-.276a.333.333 0 0 1-.251-.188L7.999 5.23Z"
    //     fill="#fff"
    //   />
    // </svg>
  );
});

export const LayerThumbnailText: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.777 4.444h6.443c.184 0 .333.15.333.333v1.306a.333.333 0 1 1-.667 0V5.11H8.442v5.777h1a.333.333 0 0 1 0 .666H6.554a.333.333 0 1 1 0-.666h1.222V5.11H5.11v.973a.333.333 0 1 1-.666 0V4.777a.333.333 0 0 1 .333-.333Z"
        fill="#F5F8FA"
      />
    </svg>
  );
});

export const LayerThumbnailNull: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.11 4.332c0-.184.149-.333.333-.333h1.11a.333.333 0 0 1 0 .667h-1.11a.333.333 0 0 1-.334-.334Zm3.443.334a.333.333 0 1 1 0-.667c.798 0 1.444.647 1.444 1.444a.333.333 0 0 1-.666 0 .778.778 0 0 0-.778-.777Zm-5.11-.667a.333.333 0 0 1 0 .667.778.778 0 0 0-.777.777.333.333 0 1 1-.667 0C4 4.646 4.646 4 5.443 4Zm-1.11 6.22a.333.333 0 0 0-.334.334c0 .798.647 1.444 1.444 1.444a.333.333 0 0 0 0-.666.778.778 0 0 1-.777-.778.333.333 0 0 0-.334-.333Zm3.11 1.112a.333.333 0 1 0 0 .666h1.11a.333.333 0 0 0 0-.666h-1.11Zm3.11 0a.333.333 0 1 0 0 .666c.798 0 1.444-.646 1.444-1.444a.333.333 0 1 0-.666 0c0 .43-.348.778-.778.778Zm1.11-4.222c.185 0 .334.15.334.334v1.11a.333.333 0 0 1-.666 0v-1.11c0-.184.149-.334.333-.334ZM4 8.553a.333.333 0 0 0 .667 0v-1.11a.333.333 0 1 0-.667 0v1.11Z"
        fill="#F5F8FA"
      />
      <path stroke="#F5F8FA" strokeWidth={0.667} strokeLinecap="round" d="m9.988 6.035-3.972 3.972" />
    </svg>
  );
});

export const LayerThumbnailFill: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={10} height={10} rx={5} transform="matrix(-1 0 0 1 12.996 3.004)" />
    </svg>
  );
});

export const LayerThumbnailSolid: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={10} height={10} rx={1} transform="matrix(-1 0 0 1 12.996 3.004)" />
    </svg>
  );
});

export const LayerThumbnailStroke: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={10} height={10} rx={5} transform="matrix(-1 0 0 1 12.996 3.008)" />
    </svg>
  );
});

export const LayerThumbnailImage: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.664 4.444H5.332a.889.889 0 0 0-.888.889v5.332c0 .49.397.888.888.888h5.332c.491 0 .889-.398.889-.888V5.333a.889.889 0 0 0-.889-.89Z"
        stroke="#F5F8FA"
        strokeWidth={0.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.856 7.745a.889.889 0 1 0 0-1.778.889.889 0 0 0 0 1.778ZM9.522 7.49l-4.825 4.063h6.856V9.707L9.522 7.49Z"
        fill="#F5F8FA"
      />
    </svg>
  );
});

export const LayerThumbnailPrecomp: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.332 2.001V10M5.998 3.334v5.332M9.997 3.334H2M8.664 6H3.332M8.664 2.001V10M9.997 8.666H2"
        stroke="#5359FD"
        strokeWidth={0.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const AnimatedScaleX: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m8.5 4.638 2 2-2 2M3.5 8.638l-2-2 2-2M10.5 6.638h-9"
        stroke="#606F7B"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const AnimatedScaleY: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m8.5 9.138-2 2-2-2M4.5 4.138l2-2 2 2M6.5 11.138v-9"
        stroke="#606F7B"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const AnimatedPositionX: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m3.554 9 1.989-2.808-2.007-2.844h.846L6.01 5.652l1.494-2.304h.855L6.398 6.183 8.432 9h-.864L5.93 6.741 4.4 9h-.846Z"
        fill="#606F7B"
      />
    </svg>
  );
});

export const AnimatedPositionY: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.861 3.348h.846l1.62 2.745h-.18l1.647-2.745h.837l-2.043 3.33V9h-.71V6.678L3.86 3.348Z"
        fill="#606F7B"
      />
    </svg>
  );
});

export const AnimatedRotation: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.065 1.5v2.25h2.25" stroke="#606F7B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.105 3.75A4.5 4.5 0 1 1 1.5 6"
        stroke="#606F7B"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={6} cy={6} r={1} stroke="#606F7B" strokeWidth={0.75} />
    </svg>
  );
});

export const AnimatedOpacity: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={4.676} cy={4.676} r={3.176} stroke="#606F7B" strokeWidth={0.75} />
      <path
        d="M4.118 7.323a3.176 3.176 0 0 1 4.235-2.995"
        stroke="#606F7B"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.118 7.323a3.177 3.177 0 1 0 4.235-2.995"
        stroke="#606F7B"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.67 1.67"
      />
    </svg>
  );
});

export const TimelineBarEdge: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="2" height="12" viewBox="0 0 2 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="2" height="12" rx="1" fill="#DAE1E7" />
    </svg>
  );
});

export const TimelinePlay: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.333 3.468a1.222 1.222 0 0 1 1.841-1.055l7.722 4.533a1.222 1.222 0 0 1 0 2.108l-7.722 4.532a1.222 1.222 0 0 1-1.84-1.054V3.468Z"
        fill="#fff"
      />
    </svg>
  );
});

export const TimelinePause: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2c-.736 0-1.333.597-1.333 1.333v9.334C2.667 13.403 3.264 14 4 14h1.333c.737 0 1.334-.597 1.334-1.333V3.333C6.667 2.597 6.07 2 5.333 2H4Zm6.667 0c-.736 0-1.333.597-1.333 1.333v9.334c0 .736.597 1.333 1.333 1.333H12c.737 0 1.334-.597 1.334-1.333V3.333C13.334 2.597 12.737 2 12 2h-1.333Z"
        fill="#fff"
      />
    </svg>
  );
});

export const TimelineSkipToStart: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.333 4.159c0-.95-1.036-1.537-1.85-1.048L5.08 6.952a1.222 1.222 0 0 0 0 2.096l6.402 3.841a1.222 1.222 0 0 0 1.851-1.048V4.16Z" />
      <path strokeLinecap="round" d="M2.833 12.833V3.166" />
    </svg>
  );
});

export const TimelineMoveForward: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.6875 11.7667C4.6875 12.7325 5.75504 13.3168 6.56857 12.7961L12.454 9.02944C13.2052 8.54869 13.2052 7.45131 12.454 6.97056L6.56857 3.20388C5.75505 2.68323 4.6875 3.26746 4.6875 4.23333L4.6875 11.7667Z" />
      <line x1="2.375" y1="3.1665" x2="2.375" y2="12.8332" strokeLinecap="round" />
    </svg>
  );
});

export const TimelineLoop: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.865 2.313a.5.5 0 0 1 .707 0L13.458 4.2a.5.5 0 0 1 0 .707l-1.886 1.886a.5.5 0 0 1-.707-.707l.751-.751H4v1.333a.667.667 0 0 1-1.333 0v-2c0-.368.298-.666.666-.666h8.512l-.98-.98a.5.5 0 0 1 0-.708ZM12.437 12a.667.667 0 0 0 .667-.666v-2a.667.667 0 0 0-1.333 0v1.333H4.155l.75-.751a.5.5 0 1 0-.707-.707l-1.885 1.886a.5.5 0 0 0 0 .707l1.885 1.885a.5.5 0 1 0 .707-.707l-.98-.98h8.513Z"
      />
    </svg>
  );
});

export const TimelineNoLoop: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.058 2.407a.5.5 0 0 1 .707 0l1.886 1.886a.5.5 0 0 1 0 .707l-1.886 1.886a.5.5 0 0 1-.707-.708l.75-.75H8.194l-.77-1.333h4.616l-.98-.98a.5.5 0 0 1 0-.708ZM3.526 4.094H4.344l.77 1.334h-.921V6.76a.667.667 0 0 1-1.333 0v-2c0-.368.298-.667.666-.667Zm9.771 7.327v.007a.667.667 0 0 1-.667.666h-.588l-.77-1.333h.692V9.427a.667.667 0 0 1 1.333 0v1.995Zm-8.199 2.36a.5.5 0 0 1-.707 0l-1.885-1.886a.5.5 0 0 1 0-.707L4.39 9.303a.5.5 0 0 1 .707.707l-.75.751h3.845l.77 1.333H4.118l.98.98a.5.5 0 0 1 0 .707Zm5.661-.24a.5.5 0 1 0 .867-.5l-6-10.393a.5.5 0 1 0-.866.5l6 10.393Z"
      />
    </svg>
  );
});

export const TimelinePlayerChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.5 5.5 6 8 3.5 5.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

export const LayerThumbnailGradient: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <foreignObject x="3" y="3" width="16" height="16">
        <div
          className="h-2.5 w-2.5 cursor-pointer rounded-lg shadow-sm"
          style={{ background: props.style?.color || 'white' }}
        ></div>
      </foreignObject>
    </svg>
  );
});

interface AnimateAllButtonProps {
  allPropsAnimated: boolean;
  onClick: () => void;
}

export const AnimateAllButton: React.FC<AnimateAllButtonProps> = ({
  allPropsAnimated,
  onClick,
}: AnimateAllButtonProps) => {
  const IsAnimatedButton: React.FC = () => {
    return (
      <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="6.14456"
          height="6.14456"
          rx="1.33333"
          transform="matrix(0.708978 0.705231 -0.708978 0.705231 9.30957 0)"
          stroke="#00C1A2"
          strokeLinecap="round"
        />
        <rect
          width="6.14456"
          height="6.14456"
          rx="1.33333"
          transform="matrix(0.708978 0.705231 -0.708978 0.705231 4.68945 0)"
          stroke="#00C1A2"
        />
      </svg>
    );
  };

  const IsNotAnimatedButton: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        stroke="#606F7B"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          width="6.14456"
          height="6.14456"
          rx="1.33333"
          transform="matrix(0.708978 0.705231 -0.708978 0.705231 9.30957 0)"
          strokeLinecap="round"
        />
        <rect
          width="6.14456"
          height="6.14456"
          rx="1.33333"
          transform="matrix(0.708978 0.705231 -0.708978 0.705231 4.68945 0)"
        />
      </svg>
    );
  };

  return (
    <div className={'group rounded p-1 hover:cursor-pointer hover:bg-gray-700'} onClick={onClick}>
      {allPropsAnimated ? <IsAnimatedButton /> : <IsNotAnimatedButton className="group-hover:stroke-white" />}
    </div>
  );
};

export const AnimatedSizeW: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.874 3.005h.84l1.555 5.332h-.22l1.593-4.712h.506l1.574 4.674h-.21l1.784-5.294h.83l-2.118 5.99h-.782L5.843 4.932h.143L4.555 8.995h-.782z"
        fill="#606f7b"
        strokeWidth={0.953899}
      />
    </svg>
  );
});

export const AnimatedSizeH: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.695 3.141h.756v2.577h3.014V3.141h.746v5.843h-.746V6.342H4.451v2.642h-.756z"
        fill="#606f7b"
        strokeWidth={0.949582}
      />
    </svg>
  );
});

export const AnimatedRoundness: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.667 2.333H9.333a7 7 0 00-7 7v2.333" stroke="#606F7B" strokeLinecap="round" />
    </svg>
  );
});

export const AnimatedPoints: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.486 3.475L7 7l-3.486 3.526M10.467 10.545L7 7 3.533 3.455M11.958 7H2.042M7 2.042v9.917"
        stroke="#606F7B"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const AnimatedInnerRadius: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 10.033l-3.243 1.704.618-3.611L1.75 5.565l3.628-.525L7 1.75l1.622 3.29 3.628.525-2.625 2.56.618 3.612L7 10.033z"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx={7.00002}
        cy={7.26211}
        rx={2.1}
        ry={2.1}
        fill="#3D4852"
        stroke="#606F7B"
        strokeWidth={0.6}
        strokeMiterlimit={3.99933}
        strokeLinecap="round"
        strokeDasharray="0.6 1.2"
      />
    </svg>
  );
});

export const AnimatedOuterRadius: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4181_66282)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 12.834A5.833 5.833 0 107 1.167a5.833 5.833 0 000 11.667zm-3.243-1.097L7 10.033l3.243 1.704-.618-3.611 2.625-2.56-3.628-.526L7 1.75 5.378 5.04l-3.628.525 2.625 2.561-.618 3.61z"
          fill="#3D4852"
        />
        <ellipse
          cx={6.99996}
          cy={7.00033}
          rx={5.83333}
          ry={5.83333}
          stroke="#606F7B"
          strokeWidth={0.6}
          strokeLinecap="round"
          strokeDasharray="0.6 1.2"
        />
        <path
          d="M7 10.033l-3.243 1.704.618-3.611L1.75 5.565l3.628-.525L7 1.75l1.622 3.29 3.628.525-2.625 2.56.618 3.612L7 10.033z"
          stroke="#606F7B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4181_66282">
          <path fill="#fff" d="M0 0H14V14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const AnimatedOuterRoundness: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4181_78901)">
        <path
          d="M9.459 13.125c2.025 0 3.666-1.663 3.666-3.714a3.72 3.72 0 00-1.955-3.286c.162 2.416-.621 3.47-.959 3.808a3.122 3.122 0 01-.299.257c-.412.32-1.675 1.104-3.787.768a3.665 3.665 0 003.334 2.167z"
          fill="#3D4852"
        />
        <path
          d="M11.17 6.125a3.72 3.72 0 011.955 3.286c0 2.051-1.641 3.714-3.666 3.714a3.665 3.665 0 01-3.334-2.167"
          stroke="#606F7B"
          strokeWidth={0.6}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.6 1.2"
        />
        <path d="M.875 10.208h5.333a4 4 0 004-4V.875" stroke="#606F7B" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_4181_78901">
          <path fill="#fff" d="M0 0H14V14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const AnimatedInnerRoundness: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.625 3.5A6.125 6.125 0 013.5 9.625V6.5a3 3 0 013-3h3.125z" fill="#3D4852" />
      <path
        d="M9.35 2.188A5.687 5.687 0 012.188 9.35"
        stroke="#606F7B"
        strokeWidth={0.6}
        strokeLinecap="round"
        strokeDasharray="0.6 1.2"
      />
      <path d="M11.667 2.333H6.333a4 4 0 00-4 4v5.333" stroke="#606F7B" strokeLinecap="round" />
    </svg>
  );
});
