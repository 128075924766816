/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Scene as ToolkitScene } from '@lottiefiles/toolkit-js/dist';
import { Vector3 } from 'three';

import { getRealPosition } from '../../../features/canvas/toolkit';
import { Box3 } from '../Box3';

import type { CObject3D } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export enum AlignDirection {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top',
}

export class AlignmentHelper {
  public object: CObject3D | null = null;

  public align(direction: AlignDirection): void {
    if (this.object === null) return;

    // calculate the parent's boundaries
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const scene = toolkit.scenes[sceneIndex];

    const parent = this.object.parent as CObject3D;
    const parentBoundingBox = new Box3().setFromObject(parent, true);

    let isNestedObject = false;

    let parentLeftEdge;
    let parentRightEdge;
    let parentTopEdge;
    let parentBottomEdge;

    if ((parent.layerType === 'GROUP' && parent.parent && parent.parent.type === 'Scene') || parent.type === 'Scene') {
      parentLeftEdge = 0;
      parentRightEdge = (scene as ToolkitScene).size.width;
      parentTopEdge = 0;
      parentBottomEdge = (scene as ToolkitScene).size.height;
    } else {
      isNestedObject = true;

      parentLeftEdge = Math.min(parentBoundingBox.min.x, parentBoundingBox.minXmaxY.x);
      parentRightEdge = Math.max(parentBoundingBox.max.x, parentBoundingBox.maxXminY.x);
      parentTopEdge = Math.min(parentBoundingBox.min.y, parentBoundingBox.maxXminY.y);
      parentBottomEdge = Math.max(parentBoundingBox.max.y, parentBoundingBox.minXmaxY.y);
    }

    // get new position and set it
    const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;
    const alignedPosition = this.getAlignedPosition(
      isNestedObject,
      parentLeftEdge,
      parentRightEdge,
      parentTopEdge,
      parentBottomEdge,
      direction,
    );

    setAnimatedValue(AnimatedType.POSITION, [alignedPosition.x, alignedPosition.y], this.object.toolkitId);
    emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
  }

  public getAlignedPosition(
    isNestedObject: boolean,
    leftEdge: number,
    rightEdge: number,
    topEdge: number,
    bottomEdge: number,
    direction: AlignDirection,
  ): Vector3 {
    const currentLocalPosition = getRealPosition(this.object as CObject3D);
    const currentWorldPosition = isNestedObject
      ? ((this.object as CObject3D).parent as CObject3D).localToWorld(currentLocalPosition)
      : currentLocalPosition;

    const objectBoundingBox = new Box3().setFromObject(this.object as CObject3D, true);
    const objectCenter = objectBoundingBox.center;

    const objectLeftEdge = Math.min(
      objectBoundingBox.min.x,
      objectBoundingBox.minXmaxY.x,
      objectBoundingBox.max.x,
      objectBoundingBox.maxXminY.x,
    );
    const objectRightEdge = Math.max(
      objectBoundingBox.min.x,
      objectBoundingBox.minXmaxY.x,
      objectBoundingBox.max.x,
      objectBoundingBox.maxXminY.x,
    );
    const objectTopEdge = Math.min(
      objectBoundingBox.min.y,
      objectBoundingBox.maxXminY.y,
      objectBoundingBox.max.y,
      objectBoundingBox.minXmaxY.y,
    );
    const objectBottomEdge = Math.max(
      objectBoundingBox.min.y,
      objectBoundingBox.maxXminY.y,
      objectBoundingBox.max.y,
      objectBoundingBox.minXmaxY.y,
    );

    const positions = {
      [AlignDirection.Left]: new Vector3(
        currentWorldPosition.x - objectLeftEdge + leftEdge,
        currentWorldPosition.y,
        currentWorldPosition.z,
      ),
      [AlignDirection.Right]: new Vector3(
        currentWorldPosition.x + (rightEdge - objectRightEdge),
        currentWorldPosition.y,
        currentWorldPosition.z,
      ),
      [AlignDirection.Center]: new Vector3(
        currentWorldPosition.x + (rightEdge / 2 - objectCenter.x) + leftEdge / 2,
        currentWorldPosition.y,
        currentWorldPosition.z,
      ),
      [AlignDirection.Top]: new Vector3(
        currentWorldPosition.x,
        currentWorldPosition.y - objectTopEdge + topEdge,
        currentWorldPosition.z,
      ),
      [AlignDirection.Middle]: new Vector3(
        currentWorldPosition.x,
        currentWorldPosition.y + (bottomEdge / 2 - objectCenter.y) + topEdge / 2,
        currentWorldPosition.z,
      ),
      [AlignDirection.Bottom]: new Vector3(
        currentWorldPosition.x,
        currentWorldPosition.y + (bottomEdge - objectBottomEdge),
        currentWorldPosition.z,
      ),
    };

    const alignedWorldPosition = positions[direction];
    const alignedLocalPosition = isNestedObject
      ? ((this.object as CObject3D).parent as CObject3D).worldToLocal(alignedWorldPosition)
      : alignedWorldPosition;

    return alignedLocalPosition;
  }
}
