/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ScalarJSON, Scene, StarShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedInnerRoundness as AnimatedInnerRoundnessIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { MAX_POLYSTAR_ROUNDNESS, MIN_POLYSTAR_ROUNDNESS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setPolystarInnerRoundness, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedInnerRoundness: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const animatedValue = animatedProp.value as ScalarJSON | null;
  const innerRoundness = animatedValue?.value ?? 0;

  const [sceneIndex] = useCreatorStore((state) => [state.toolkit.sceneIndex], shallow);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id);

      if (node?.parent) {
        setPolystarInnerRoundness(node.parent as StarShape, result.value);
        emitter.emit(EmitterEvent.POLYSTAR_INNER_ROUNDNESS_UPDATED);
      }
    },
    [sceneIndex, animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="innerRoundness"
        onChange={handleOnChange}
        value={innerRoundness}
        label={<AnimatedInnerRoundnessIcon className="h-3 w-3" />}
        styleClass={styleClass}
        min={MIN_POLYSTAR_ROUNDNESS}
        max={MAX_POLYSTAR_ROUNDNESS}
        append="%"
      />
    </div>
  );
};
