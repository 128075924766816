/**
 * Copyright 2023 Design Barn Inc.
 */

import type { GradientFillShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getShapeMethods } from './shape';

import { emitter, EmitterEvent } from '~/lib/emitter';

const methodNames = {
  endPoint: 'endPoint',
  fillRule: 'fillRule',
  gradient: 'gradient',
  gradientType: 'gradientType',
  highlightAngle: 'highlightAngle',
  highlightLength: 'highlightLength',
  name: 'name',
  numColors: 'numColors',
  opacity: 'opacity',
  startPoint: 'startPoint',
  setEndPoint: 'setEndPoint',
  setFillRule: 'setFillRule',
  setGradient: 'setGradient',
  setGradientType: 'setGradientType',
  setHighlightAngle: 'setHighlightAngle',
  setHighlightLength: 'setHighlightLength',
  setName: 'setName',
  setOpacity: 'setOpacity',
  setStartPoint: 'setStartPoint',
};

const gradientFillShapeModifiers = {
  fillRule: (result: unknown): string => {
    return result === 1 ? 'NONZERO' : 'EVEN_ODD';
  },
};

// manually creating setFillRule to take in string arguments
// instead of number arguments
function setFillRule(fill: GradientFillShape, fillRule: string): GradientFillShape {
  const uppercaseFillRule = fillRule.toUpperCase();

  if (!(uppercaseFillRule === 'NONZERO' || uppercaseFillRule === 'EVEN_ODD')) {
    throw new Error("Value must be 'NONZERO' or 'EVEN_ODD'.");
  }

  if (uppercaseFillRule === 'NONZERO') {
    fill.setFillRule(1);
  }

  if (uppercaseFillRule === 'EVEN_ODD') {
    fill.setFillRule(2);
  }

  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);

  return fill;
}

const additionalMethods = {
  setFillRule,
};

function getGradientFillShapeMethods(plugin: Plugin, gradientFs: GradientFillShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, gradientFs, gradientFillShapeModifiers, additionalMethods);
}

export function createGradientFillShapeObject(plugin: Plugin, gradientFillShape: GradientFillShape): object {
  const gradientFillShapeObj = {
    nodeId: gradientFillShape.nodeId,
    nodeType: gradientFillShape.nodeType,
    type: gradientFillShape.type,
  };

  registerObjectMethods(plugin, gradientFillShape, gradientFillShapeObj, [
    getNodeMethods,
    getShapeMethods,
    getGradientFillShapeMethods,
  ]);

  return gradientFillShapeObj;
}
