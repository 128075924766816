/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';
import React, { useCallback, useRef } from 'react';
import { Vector3 } from 'three';
import { shallow } from 'zustand/shallow';

import { ScaleX, ScaleY } from '~/assets/icons';
import { AspectRatioToggle } from '~/components/Elements/Button/AspectRatioToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberInputOption } from '~/components/Elements/Input';
import type { NumberInputRatioResult } from '~/components/Elements/Input/useDualNumberInputRatio';
import { useDualNumberInputRatio } from '~/components/Elements/Input/useDualNumberInputRatio';
import { MAX_TRANSFORM_SCALE, MIN_TRANSFORM_SCALE } from '~/data/range';
import { updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { TransformType } from '~/lib/threejs/TransformControls';
import { AnimatedType, getNodeById, removeKeyFrame, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const scaleOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: 'scaleX',
    label: <ScaleX className="h-4 w-4" />,
    append: '%',
  },
  y: {
    name: 'scaleY',
    label: <ScaleY className="h-4 w-4" />,
    append: '%',
  },
};

export const TransformScaleField: React.FC = () => {
  const [
    scaleX,
    scaleY,
    currentKeyframe,
    setAnimatedValue,
    addAnimatedValue,
    setStaticValue,
    scaleRatioLocked,
    { scale },
    selectedNodesInfo,
    sceneIndex,
    groupTransform,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentTransform.scale[0],
      state.toolkit.currentTransform.scale[1],
      state.toolkit.currentTransform.scaleCurrentKeyframe,
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
      state.toolkit.setStaticValue,
      state.ui.scaleRatioLocked,
      state.ui.selectedNodeTransform,
      state.ui.selectedNodesInfo,
      state.toolkit.sceneIndex,
      state.ui.selectedGroupTransform,
    ],
    shallow,
  );

  const prevX = useRef<null | number>(null);
  const prevY = useRef<null | number>(null);

  const multiSelect = selectedNodesInfo.length > 1;
  const handleOnChangeResult = useCallback(
    (result: NumberInputRatioResult) => {
      if (result.type === 'both') {
        const value = result.value as [number, number];

        if (prevX.current === null) prevX.current = value[0];
        if (prevY.current === null) prevY.current = value[1];
        if (multiSelect) {
          updateGroupFromUI(TransformType.Scale, new Vector3(value[0] / prevX.current, value[1] / prevY.current, 1));
          prevX.current = value[0];
          prevY.current = value[1];
        } else setAnimatedValue(AnimatedType.SCALE, [value[0], value[1]]);
      } else if (result.type === scaleOption.x.name) {
        if (prevX.current === null) prevX.current = result.value as number;

        if (multiSelect) {
          updateGroupFromUI(TransformType.Scale, new Vector3((result.value as number) / prevX.current, 1, 1));
          prevX.current = result.value as number;
        } else {
          setAnimatedValue(AnimatedType.SCALE, [result.value as number, scaleY]);
        }
      } else {
        if (prevY.current === null) prevY.current = result.value as number;

        if (multiSelect) {
          updateGroupFromUI(TransformType.Scale, new Vector3(1, (result.value as number) / prevY.current, 1));
          prevY.current = result.value as number;
        } else {
          setAnimatedValue(AnimatedType.SCALE, [scaleX, result.value as number]);
        }
      }

      emitter.emit(EmitterEvent.ANIMATED_SCALE_UPDATED);
    },
    [multiSelect, scaleX, scaleY, setAnimatedValue],
  );

  // NOTE(miljau):
  // The assumption being made here is that scale applies only to
  // GroupShapes and that the TransformScaleField won't apply to
  // any other nodes. In such a case the nodeId being used here
  // should line up with the toolkitId used by the canvas code
  const { handleInputChange, handleOnClickAspectRatio, isLocked } = useDualNumberInputRatio({
    left: scaleX,
    leftName: scaleOption.x.name,
    right: scaleY,
    onChange: handleOnChangeResult,
    ratioLocked: scaleRatioLocked,
  });

  const handleOnClickAspectRatioCurried = useCallback(() => {
    handleOnClickAspectRatio();
    const scene = toolkit.scenes[sceneIndex] as Scene;
    // TODO: handle multi selection
    const node = getNodeById(scene, selectedNodesInfo[0]?.nodeId as string);

    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.TOOLKIT_NODE_SCALE_UPDATED,
      data: {
        node,
        scaleRatioLock: !scaleRatioLocked,
      },
    });
  }, [handleOnClickAspectRatio, scaleRatioLocked, sceneIndex, selectedNodesInfo]);

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyframe) {
      removeKeyFrame(currentKeyframe);
      setStaticValue(AnimatedType.SCALE, [scaleX, scaleY]);
    } else {
      addAnimatedValue(AnimatedType.SCALE, [scaleX, scaleY]);
    }
    stateHistory.endAction();

    emitter.emit(EmitterEvent.ANIMATED_SCALE_UPDATED);
  }, [scaleX, scaleY, addAnimatedValue, currentKeyframe, setStaticValue]);

  let leftValue = scale ? scale.x * 100 : scaleX;
  let rightValue = scale ? scale.y * 100 : scaleY;

  if (multiSelect && groupTransform.scale) {
    leftValue = groupTransform.scale.x * 100;
    rightValue = groupTransform.scale.y * 100;
  }

  prevX.current = leftValue;
  prevY.current = rightValue;

  return (
    <div className="mt-2 flex w-full">
      <DualNumberInput
        onChange={handleInputChange}
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={currentKeyframe !== ''}
        left={leftValue}
        right={rightValue}
        leftOption={scaleOption.x}
        rightOption={scaleOption.y}
        showKeyframe
        leftMin={MIN_TRANSFORM_SCALE}
        leftMax={MAX_TRANSFORM_SCALE}
        rightMin={MIN_TRANSFORM_SCALE}
        rightMax={MAX_TRANSFORM_SCALE}
        tooltip={{ left: 'Scale (X)', right: 'Scale (Y)' }}
      />
      <Interactive>
        <AspectRatioToggle isLocked={isLocked} onClick={handleOnClickAspectRatioCurried} />
      </Interactive>
    </div>
  );
};
