/**
 * Copyright 2022 Design Barn Inc.
 */

import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader } from 'three';

import pivot from '~/assets/images/pivot.png';
import { CObject3D } from '~/features/canvas';
import { TransformType } from '~/lib/threejs/TransformControls';

export const getPivotPoint = async (): Promise<CObject3D> => {
  const pivotPoint = new CObject3D();
  const texture = await new TextureLoader().loadAsync(pivot);

  texture.needsUpdate = true;

  const geometry = new PlaneGeometry(16, 16);
  const material = new MeshBasicMaterial({
    map: texture,
    side: DoubleSide,
    depthTest: false,
    transparent: true,
    opacity: 1,
  });

  const mesh = new Mesh(geometry, material);

  mesh.userData['mode'] = TransformType.Pivot;

  pivotPoint.visible = false;
  pivotPoint.renderOrder = 1;
  pivotPoint.add(mesh);

  return pivotPoint;
};
