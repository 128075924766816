/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useState } from 'react';

interface MenuInteractionProps {
  children: ({ hover }: { hover?: boolean }) => React.ReactNode;
}

export const MenuInteraction: React.FC<MenuInteractionProps> = React.forwardRef(({ children }, _) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div className="w-[103%]" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {children({ hover })}
    </div>
  );
});
