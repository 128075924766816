/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Asset } from '@lottiefiles/toolkit-js';

import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const renameNestedScenes = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  let precompCount = 0;

  toolkit.scenes[sceneIndex].assets.forEach((eachAsset: Asset) => {
    if (eachAsset.type === 'PRECOMPOSITION') {
      precompCount += 1;
      if (!eachAsset.id.includes('Scene')) {
        eachAsset.setId(`Scene ${precompCount}`);
      }
    }
  });
};
