/**
 * Copyright 2023 Design Barn Inc.
 */

import type { MenuDropdownProp } from './components/CreatorMenu';

import { MenuType } from '~/data/constant';
import { EventAction, EventType, FileType } from '~/data/eventStore';
import { emitter, EmitterEvent } from '~/lib/emitter';
import {
  createNewFile,
  exportDotLottie,
  exportJSON,
  goToDashboard,
  saveToCreator,
  saveToWorkSpace,
} from '~/lib/function/menu';
import { GenericPayload } from '~/utils';

// These are precalculated heights for the context menu. Update them if any
// items are added/removed from the context menus.
export const MENU_HEIGHT = {
  ShapeLayerMenuTimeline: 427,
  NormalLayerMenu: 286,
  NestedSceneLayerMenu: 475,
  SingleKeyFrameMenu: 137,
};

const CopyItem: MenuDropdownProp = {
  type: 'Copy',
  label: 'Copy',
  shortcut: '⌘C',
  callback: () => emitter.emit(EmitterEvent.CANVAS_COPY),
};

const PasteItem: MenuDropdownProp = {
  type: 'Paste',
  label: 'Paste',
  shortcut: '⌘V',
  callback: () => emitter.emit(EmitterEvent.CANVAS_PASTE),
};

const DuplicateItem: MenuDropdownProp = {
  type: 'Duplicate',
  label: 'Duplicate',
  shortcut: '⌘D',
  callback: () => emitter.emit(EmitterEvent.CANVAS_DUPLICATE),
};

const ZoomInItem: MenuDropdownProp = {
  type: 'ZoomIn',
  label: 'Zoom in',
  shortcut: '⌘+',
  callback: () => emitter.emit(EmitterEvent.CANVAS_ZOOM_IN),
};

const ZoomOutItem: MenuDropdownProp = {
  type: 'ZoomOut',
  label: 'Zoom out',
  shortcut: '⌘-',
  callback: () => emitter.emit(EmitterEvent.CANVAS_ZOOM_OUT),
};

const ZoomToFitItem: MenuDropdownProp = {
  type: 'ZoomToFit',
  label: 'Zoom to fit',
  shortcut: '⌘0',
  callback: () => emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT),
};

const AnimatedPropertiesItem: MenuDropdownProp = {
  type: 'AnimatedProperties',
  label: 'Animated properties...',
  shortcut: '⇧K',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_SHOW_ANIMATED_PROPERTIES),
};

const ShowAllKeyframesItem: MenuDropdownProp = {
  type: 'ShowAllKeyframes',
  label: 'Show all keyframes',
  isDivider: true,
  shortcut: 'U',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES),
};

const BringToFrontItem: MenuDropdownProp = {
  type: 'BringToFront',
  label: 'Bring to front',
  shortcut: ']',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_UP),
};

const SendToBackItem: MenuDropdownProp = {
  type: 'SendToBack',
  label: 'Send to back',
  shortcut: '[',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_DOWN),
};

const RotateRightItem: MenuDropdownProp = {
  type: 'RotateRight',
  label: 'Rotate 90° right',
  callback: () => emitter.emit(EmitterEvent.CANVAS_OBJECT_ROTATE_RIGHT),
};

const RotateLeftItem: MenuDropdownProp = {
  type: 'RotateLeft',
  label: 'Rotate 90° left',
  callback: () => emitter.emit(EmitterEvent.CANVAS_OBJECT_ROTATE_LEFT),
};

const DeleteItem: MenuDropdownProp = {
  type: 'Delete',
  label: 'Delete',
  shortcut: '⌫',
  callback: (arg) => emitter.emit(EmitterEvent.UI_DELETE, arg),
};

const DeleteAnimatedItem: MenuDropdownProp = {
  type: 'Delete',
  label: 'Delete',
  shortcut: '⌫',
  callback: (arg) => emitter.emit(EmitterEvent.UI_ANIMATED_DELETE, arg),
};

const EditScene: MenuDropdownProp = {
  type: 'EditScene',
  label: 'Edit Scene',
  shortcut: '',
  callback: (arg) => emitter.emit(EmitterEvent.TIMELINE_PRECOMP_EDIT_SCENE, arg),
};

const RenameItem: MenuDropdownProp = {
  type: 'RenameItem',
  label: 'Rename',
  shortcut: '',
  callback: (arg) => emitter.emit(EmitterEvent.TIMELINE_RENAME_LAYER_START, arg),
};
const isDevelopment = import.meta.env.DEV;

const DownloadMenu = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
  type: '',
  label: 'Download as',
  children: [
    {
      type: MenuType.JSON,
      callback: exportJSON,
      label: 'Lottie JSON',
      shortcut: '⌘E',
      trackParams: {
        client: GenericPayload,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        parameters: { type: FileType.JSON, send_to_amplitude: true },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.DownloadedFile,
      },
    },
    {
      type: MenuType.DotLottie,
      callback: exportDotLottie,
      label: 'dotLottie',
      trackParams: {
        client: GenericPayload,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        parameters: { type: FileType.DotLottie, send_to_amplitude: true },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.DownloadedFile,
      },
    },
  ],
};

export const CreatorMenuList: MenuDropdownProp[] = [
  {
    type: MenuType.New,
    callback: createNewFile,
    label: 'Create new file',
    trackParams: {
      client: GenericPayload,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      parameters: { action: EventAction.CreateNew, send_to_amplitude: true },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
  {
    type: '',
    label: '',
    isDivider: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  {
    type: MenuType.SaveToCreator,
    callback: saveToCreator,
    label: 'Save as working file (.creator)',
    // shortcut: '⌘S',
    trackParams: {
      client: GenericPayload,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      parameters: { action: EventAction.SaveDotCreator, send_to_amplitude: true },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
  {
    type: MenuType.SaveToWorkspace,
    callback: saveToWorkSpace,
    label: 'Export to My Workspace (.lottie)',
    shortcut: '⇧⌘S',
    trackParams: {
      client: GenericPayload,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      parameters: { action: EventAction.SaveWorkspace, send_to_amplitude: true },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
  ...(isDevelopment ? [DownloadMenu] : []),
  {
    type: '',
    label: '',
    isDivider: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  {
    type: MenuType.GoToDashboard,
    callback: goToDashboard,
    label: 'Go to Dashboard',
    trackParams: {
      client: GenericPayload,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      parameters: { action: EventAction.GoToDashboard, send_to_amplitude: true },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
];

export const EmptyCanvasMenuList: MenuDropdownProp[] = [
  {
    ...PasteItem,
    isDivider: true,
    disabled: true,
  },
  {
    type: 'ToggleTimeline',
    label: 'Show/Hide timeline',
    isDivider: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  ZoomInItem,
  ZoomOutItem,
  ZoomToFitItem,
];

export const ShapeLayerMenuList: MenuDropdownProp[] = [
  AnimatedPropertiesItem,
  {
    ...ShowAllKeyframesItem,
    isDivider: true,
  },
  CopyItem,
  PasteItem,
  {
    ...DuplicateItem,
    isDivider: true,
  },
  BringToFrontItem,
  SendToBackItem,
  RotateRightItem,
  {
    ...RotateLeftItem,
    isDivider: true,
  },
  { ...RenameItem, isDivider: true },
  DeleteItem,
];

export const NormalLayerMenuList: MenuDropdownProp[] = [
  AnimatedPropertiesItem,
  {
    ...ShowAllKeyframesItem,
    isDivider: true,
  },
  CopyItem,
  PasteItem,

  {
    ...DuplicateItem,
    isDivider: true,
  },
  { ...RenameItem, isDivider: true },
  DeleteItem,
];

export const SingleKeyFrameMenuList: MenuDropdownProp[] = [
  CopyItem,
  {
    ...PasteItem,
    isDivider: true,
  },
  DeleteItem,
];

export const AnimatedPropertyMenuList: MenuDropdownProp[] = [
  {
    ...DeleteAnimatedItem,
    isDivider: true,
  },
];

export const NestedSceneLayerMenuList: MenuDropdownProp[] = [
  {
    ...EditScene,
    isDivider: true,
  },
  AnimatedPropertiesItem,
  {
    ...ShowAllKeyframesItem,
    isDivider: true,
  },
  CopyItem,
  PasteItem,
  {
    ...DuplicateItem,
    isDivider: true,
  },
  BringToFrontItem,
  SendToBackItem,
  RotateRightItem,
  {
    ...RotateLeftItem,
    isDivider: true,
  },
  { ...RenameItem, isDivider: true },
  DeleteItem,
];
