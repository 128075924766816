/**
 * Copyright 2022 Design Barn Inc.
 */

import { throttle } from 'lodash-es';
import type { FC } from 'react';
import React, { useRef, useCallback } from 'react';
import tinycolor from 'tinycolor2';

import type { IChange } from '../../InputRgba';
import InputRgba from '../../InputRgba';
import { checkFormat } from '../../utils';
import ColorPickerPanel from '../ColorPanel';
import type { IPropsComp } from '../types';
// import DefaultColorsPanel from '../DefaultColorPanel';

const ColorPickerSolid: FC<IPropsComp> = ({
  color: value = '#ffffff',
  opacity,
  onChangeColor = () => ({}),
  onStopColor = () => ({}),
  onChangeOpacity,
  format = 'rgb',
  colorBoardHeight = 120,
  // defaultColors,
}) => {
  const node = useRef<HTMLDivElement | null>(null);

  const handleChangeColor = throttle(({ alpha, hex }: IChange, isDragging = true): void => {
    let newOpacity = alpha;

    if (hex === 'transparent' && alpha === 0) {
      newOpacity = 100;
    }

    const rgba = tinycolor(hex);

    rgba.setAlpha(newOpacity / 100);
    const res = checkFormat(rgba.toRgbString(), format, false, newOpacity);

    if (res !== '') {
      if (isDragging) {
        onChangeColor(res);
      } else {
        onStopColor(res);
      }
    }
    onChangeOpacity(newOpacity);
  }, 40);

  const handleStopColor = useCallback(
    (change: IChange) => {
      handleChangeColor(change, false);
    },
    [handleChangeColor],
  );

  return (
    <div ref={node} className="colorpicker">
      <ColorPickerPanel
        hex={value}
        alpha={opacity}
        colorBoardHeight={colorBoardHeight}
        onChange={handleChangeColor}
        onStop={handleStopColor}
      />
      <InputRgba hex={value} alpha={opacity} format={format} onChange={handleChangeColor} />
      {/* <div className="h-[1px] w-full bg-gray-700" /> */}
      {/* <DefaultColorsPanel defaultColors={defaultColors ?? []} setColor={setColor} colorType="solid" /> */}
    </div>
  );
};

export default ColorPickerSolid;
