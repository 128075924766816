/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React from 'react';

import { LottieFeatureCheckerError } from '~/assets/icons';
import { Divider } from '~/features/property-panel';
import { useCreatorStore } from '~/store';

export const ErrorDisplay: React.FC = () => {
  const unsupportedFeatures = useCreatorStore.getState().ui.featureChecker.unsupportedFeatures;

  return (
    <>
      <div className="max-h-[300px] min-h-[100px] overflow-y-auto">
        {unsupportedFeatures.length > 0 && (
          <>
            {unsupportedFeatures.map((feature, featureIndex) => {
              return (
                <div key={featureIndex} className="px-4 pt-2">
                  <div className={clsx(`my-1.5 flex justify-between rounded`)}>
                    <span className="text-caption font-bold text-white">{feature}</span>

                    <div className="mt-[2px]">
                      <LottieFeatureCheckerError className={'h-3 w-3'} />
                    </div>
                  </div>

                  {featureIndex !== unsupportedFeatures.length - 1 && (
                    <>
                      <Divider customClass="bg-[#4C5863]" />
                    </>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
