/**
 * Copyright 2023 Design Barn Inc.
 */

import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import type { Transform } from '@dnd-kit/utilities';
import { CSS } from '@dnd-kit/utilities';
import type { Scene, ShapeJSON } from '@lottiefiles/toolkit-js';
import type { CSSProperties } from 'react';
import React, { useCallback } from 'react';

import { resetTimelineLayerPopup } from '../../helper';

import type { OptionalShapeLayer } from './SortableTreeItemLayer';
import { getNumbersInRange } from './utilities';

import { stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface SortableTreeItemProps {
  TreeItemComponent: unknown;
  attributes: DraggableAttributes;
  disableSorting: boolean;
  id: string;
  isDragging: boolean;
  isSorting: boolean;
  lastLayer?: unknown;
  layer: OptionalShapeLayer | ShapeJSON;
  listeners: SyntheticListenerMap | undefined;
  setDraggableNodeRef: (element: HTMLElement | null) => void;
  setDroppableNodeRef: (element: HTMLElement | null) => void;
  transform: Transform | null;
  transition?: string | null;
}

const SortableTreeItemNotMemoized = ({
  TreeItemComponent,
  attributes,
  disableSorting,
  id,
  isDragging,
  isSorting,
  lastLayer,
  listeners,
  setDraggableNodeRef,
  setDroppableNodeRef,
  transform,
  transition,
  ...props
}: SortableTreeItemProps): unknown => {
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? 0,
  };

  const onClick = useCallback(() => {
    stateHistory.offTheRecord(() => {
      const precompId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

      const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;
      const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
      const removeSelectedNodes = useCreatorStore.getState().ui.removeSelectedNodes;
      const alreadySelected = useCreatorStore.getState().ui.selectedNodesInfo.find((node) => node.nodeId === id);

      const singleSelectModifier = useCreatorStore.getState().timeline.singleSelectionModifier;
      const multiSelectModifier = useCreatorStore.getState().timeline.multiSelectionModifier;

      // TODO: move this logic to the parent?
      if (multiSelectModifier) {
        if (selectedNodes.length) {
          // get the starting and ending draw orders
          const prevSelectionDrawOrders = selectedNodes.map(
            (node) => useCreatorStore.getState().ui.canvasMap.get(node.nodeId)?.drawOrder,
          );

          const firstDrawOrder = useCreatorStore.getState().ui.canvasMap.get(id)?.drawOrder as number;
          const secondDrawOrder =
            (prevSelectionDrawOrders.sort()[0] as number) > firstDrawOrder
              ? (prevSelectionDrawOrders.sort()[prevSelectionDrawOrders.length - 1] as number)
              : (prevSelectionDrawOrders.sort()[0] as number);

          // get the ids of the layers between these two draw orders
          const selectedDrawOrders = getNumbersInRange(
            Math.min(firstDrawOrder, secondDrawOrder),
            Math.max(firstDrawOrder, secondDrawOrder),
          );

          const layerIDs: string[] = [];
          const allLayers = precompId
            ? (toolkit.getNodeById(precompId) as Scene).layers
            : (toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as Scene).layers;

          allLayers.forEach((layer) => {
            if (selectedDrawOrders.includes(layer.drawOrder)) {
              layerIDs.push(layer.nodeId);
            }
          });

          addToSelectedNodes(layerIDs, true);
        } else {
          addToSelectedNodes([id], true);
        }

        return;
      }

      if (singleSelectModifier && alreadySelected) {
        removeSelectedNodes([id]);
      } else if (singleSelectModifier) {
        addToSelectedNodes([id], false);
      } else {
        addToSelectedNodes([id], true);
      }

      resetTimelineLayerPopup();
    });
  }, [id]);

  return (
    <>
      <TreeItemComponent
        {...props}
        id={id}
        ref={setDraggableNodeRef}
        wrapperRef={setDroppableNodeRef}
        style={style}
        ghost={isDragging}
        disableInteraction={isSorting}
        handleProps={{
          ...attributes,
          ...listeners,
        }}
        onClick={onClick}
        disableSorting={disableSorting}
      />
    </>
  );
};

export const SortableTreeItem = React.memo(SortableTreeItemNotMemoized) as typeof SortableTreeItemNotMemoized;
