/**
 * Copyright 2022 Design Barn Inc.
 */

import type { LayerJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { MENU_HEIGHT } from '../../../../menu/constant';
import { TIMELINE_BOTTOM_GUTTER } from '../constant';
import { simplifiedLayersFromTree } from '../helper';
import { flattenTree } from '../TimelineLayerPanel/DraggableWrapper/utilities';

import { TimelineContent } from './TimelineContent';
import { TimelineControl } from './TimelineControl';

import { SingleKeyFrameMenu } from '~/features/menu';
import { useCreatorStore } from '~/store';

const simplifiedTimelineKeyFrame = (layerJSON: LayerJSON[] | undefined): unknown => {
  if (!layerJSON) return [];
  const flattenedTree = flattenTree(layerJSON);

  const simplifiedTree = simplifiedLayersFromTree(flattenedTree as unknown);

  return (
    simplifiedTree.length > 0 &&
    simplifiedTree.map((json) => {
      return json;
    })
  );
};

const KeyframeRows: React.FC = () => {
  const [selectedPrecompositionId, selectedPrecompositionJson, layerJSON] = useCreatorStore(
    (state) => [state.toolkit.selectedPrecompositionId, state.toolkit.selectedPrecompositionJson, state.toolkit.json],
    shallow,
  );

  return (
    <>
      <TimelineContent
        layers={
          selectedPrecompositionId
            ? selectedPrecompositionJson?.allLayers || []
            : simplifiedTimelineKeyFrame(layerJSON?.allLayers) || []
        }
      />
    </>
  );
};

// Synchronize scrolling example https://codesandbox.io/s/8wvi7?file=/src/index.js
const KeyframeContent: React.FC = React.memo(() => {
  const height = useCreatorStore((state) => state.timeline.height) - TIMELINE_BOTTOM_GUTTER;
  const setPreventScrollEvent = useCreatorStore.getState().timeline.setPreventScrollEvent;

  const handleOnLayerScroll = useCallback(
    (evt: React.UIEvent<HTMLDivElement>) => {
      // Manually query to prevent React rerender
      if (useCreatorStore.getState().timeline.preventScrollEvent) {
        setPreventScrollEvent(false);

        return;
      }

      const layerPanel = document.getElementById('layer-panel');

      if (layerPanel) {
        // Sync scroll layer panel scroll position with keyframe panel
        setPreventScrollEvent(true);
        layerPanel.scrollTop = evt.currentTarget.scrollTop;
      }
    },
    [setPreventScrollEvent],
  );

  return (
    <div id="keyframe-panel" className="overflow-y-auto" style={{ height }} onScroll={handleOnLayerScroll}>
      <KeyframeRows />
    </div>
  );
});

export const TimelineKeyframePanel: React.FC = () => {
  const [timelineContext, selectedKeyframes, setSelectedKeyframes] = useCreatorStore((state) => [
    state.timeline.timelineContext,
    state.timeline.selectedKeyframes,
    state.timeline.setSelectedKeyframe,
  ]);
  const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;
  const [menuOpened, setMenuOpened] = useState(false);

  const [outsideMousePos, setOutsideMousePos] = useState({ x: 0, y: 0 });
  const mousePos = timelineContext.selectedId ? timelineContext.mousePos : outsideMousePos;

  const rightMenuClosed = useCallback(() => {
    if (menuOpened) {
      setMenuOpened(false);
      setTimelineContext({
        selectedId: null,
        mousePos: { x: 0, y: 0 },
      });
    }
  }, [setMenuOpened, menuOpened, setTimelineContext]);

  const onTimelineClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.type === 'contextmenu' && event.button === 2) {
        // right click
        setMenuOpened(true);

        if (timelineContext.selectedId === null) {
          // mouse right click outside of keyframe, within timeline container
          const timelineContainer = document.getElementById('timeline-container');
          const containerRect = timelineContainer?.getBoundingClientRect();
          const timelineSideBar = document.getElementById('timeline-sidebar')?.getBoundingClientRect();
          const timelineLayerPanel = document.getElementById('timeline-layer-panel')?.getBoundingClientRect();

          if (!timelineSideBar || !timelineLayerPanel || !containerRect) return;
          // Calculate the click coordinates relative to the container
          const xOffset = Number(timelineSideBar.width) + Number(timelineLayerPanel.width);
          const clickX = xOffset + event.clientX - containerRect.left;
          const clickY = event.clientY - containerRect.top;
          const marginTop = 20;
          const marginLeft = 10;

          setOutsideMousePos({
            x: (Number(clickX) || 0) + marginLeft,
            y: (Number(clickY) || 0) + marginTop,
          });
        }
      }
    },
    [setMenuOpened, timelineContext],
  );

  // If a keyframe is selected and a user clicks away, deselect all the selected keyframes
  useEffect(() => {
    const listener = (ev: MouseEvent): void => {
      if (selectedKeyframes.length === 0) {
        return;
      }

      if ((ev.target as HTMLElement).classList.contains('timeline-keyframe')) {
        return;
      }
      setSelectedKeyframes([]);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [selectedKeyframes, setSelectedKeyframes]);

  const timelineHeight = useCreatorStore.getState().timeline.height;

  let yOffset = mousePos.y;

  if ((mousePos.y as number) + MENU_HEIGHT.SingleKeyFrameMenu > timelineHeight) {
    yOffset = timelineHeight - MENU_HEIGHT.SingleKeyFrameMenu;
  }

  return (
    <div id="timeline-container" className="relative mr-2 flex flex-1 flex-col" onContextMenu={onTimelineClick}>
      {menuOpened && (
        <div className="z-dropdown">
          <SingleKeyFrameMenu isOpen={menuOpened} onClose={rightMenuClosed} coord={{ x: mousePos.x, y: yOffset }} />
        </div>
      )}
      <div id="timelinecontrol-container" className="relative h-[20px] w-full bg-gray-700">
        <TimelineControl />
      </div>
      <KeyframeContent />
    </div>
  );
};
