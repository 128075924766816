/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Rect, Size, Vector } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { createSizeObject } from './size';
import { createVectorObject } from './vector';

import type { ValueObject } from '.';

const methodNames = {
  bottom: 'bottom',
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
  height: 'height',
  left: 'left',
  right: 'right',
  top: 'top',
  topLeft: 'topLeft',
  topRight: 'topRight',
  getWidth: 'width',
  x: 'x',
  y: 'y',
  clone: 'clone',
  equals: 'equals',
  interpolate: 'interpolate',
  // toJSON: 'toJSON',
};

function getRectMethods(plugin: Plugin, rect: Rect): ObjectMethods {
  return getObjectMethods(plugin, methodNames, rect);
}

interface RectObject extends ValueObject {
  offset: Vector;
  size: Size;
}

export function createRectObject(plugin: Plugin, rect: Rect): RectObject {
  const rectObject = {
    size: createSizeObject(plugin, rect.size),
    offset: createVectorObject(plugin, rect.offset),
    valueType: 'Rect',
    ...getRectMethods(plugin, rect),
  };

  registerObjectMethods(plugin, rect, rectObject, [getRectMethods]);

  return rectObject;
}
