/**
 * Copyright 2023 Design Barn Inc.
 */

import { HoldInterpolator, LinearInterpolator, Vector, BezierInterpolator } from '@lottiefiles/toolkit-js';

export function handleIncomingInterpolators(
  interpolator: LinearInterpolator | HoldInterpolator | BezierInterpolator,
): object {
  // recreates a main thread equivalent value
  // so that toolkit object methods are accessible

  const { interpolatorType } = interpolator;
  let updatedInterpolator = interpolator;

  switch (interpolatorType) {
    case 'Linear':
      updatedInterpolator = new LinearInterpolator();
      break;

    case 'Hold':
      updatedInterpolator = new HoldInterpolator();
      break;

    case 'Bezier':
      // eslint-disable-next-line no-case-declarations
      const newControlPoints = [];

      for (const controlPoint of (interpolator as BezierInterpolator).controlPoints) {
        const newControlPoint = new Vector(controlPoint.x, controlPoint.y);

        newControlPoints.push(newControlPoint);
      }

      updatedInterpolator = new BezierInterpolator(...newControlPoints);
      break;

    default:
      break;
  }

  return updatedInterpolator as object;
}
