/**
 * Copyright 2022 Design Barn Inc.
 */

import { Disclosure, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

import { ChevronRight } from '~/assets/icons';

interface AccordionProps {
  children?: React.ReactNode;
  disableAccordionButton?: boolean;
  header?: React.ReactNode;
  title: string;
  withDisclosure?: boolean;
}

interface AccordionButtonProps {
  children: React.ReactNode;
  className: string;
  disableAccordionButton?: boolean;
}

const AccordionButton: React.FC<AccordionButtonProps> = ({
  children,
  className,
  disableAccordionButton,
}: AccordionButtonProps) => {
  return (
    <>
      {disableAccordionButton && <div className={className}>{children}</div>}
      {!disableAccordionButton && <Disclosure.Button className={className}>{children}</Disclosure.Button>}
    </>
  );
};

export const Accordion: React.FC<AccordionProps> = ({
  children,
  disableAccordionButton = false,
  header = null,
  title,
  withDisclosure = true,
}) => {
  return (
    <Disclosure as="div" className="z-disclosure" defaultOpen>
      {({ open }) => (
        <>
          <AccordionButton
            data-testid="accordion-button"
            disableAccordionButton={disableAccordionButton}
            className={clsx('relative flex w-[199px] items-center text-xs font-semibold text-gray-100', {
              'pointer-events-none': !withDisclosure,
            })}
          >
            {withDisclosure && <ChevronRight className={`mr-1 h-4 w-4 stroke-current ${open ? 'rotate-90' : ''}`} />}
            {title}
            <span className=" absolute flex h-full w-full flex-row">{header}</span>
          </AccordionButton>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static={!withDisclosure}>{children}</Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
