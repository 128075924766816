/**
 * Copyright 2022 Design Barn Inc.
 */

export enum ShapeTypes {
  Ellipse = 'Ellipse',
  Fill = 'Fill',
  Line = 'Line',
  None = 'None',
  Path = 'Path',
  Polygon = 'Polygon',
  Square = 'Square',
  Star = 'Star',
  Stroke = 'Stroke',
  Transform = 'Transform',
  TrimPath = 'TrimPath',
}

export enum PropertyPanelType {
  Composition = 'Composition',
  EditPath = 'Edit Path',
  Ellipse = 'Ellipse',
  EllipsePath = 'Ellipse Path',
  Fill = 'Fill',
  GradientFill = 'GradientFill',
  Group = 'Group',
  Line = 'Line',
  MultiObjects = 'Multiple Objects',
  Path = 'Path',
  Polygon = 'Polygon',
  PolygonPath = 'Polygon Path',
  Precomposition = 'Precomposition',
  Rectangle = 'Rectangle',
  RectanglePath = 'Rectangle Path',
  ShapeLayer = 'ShapeLayer',
  Star = 'Star',
  StarPath = 'Star Path',
  Stroke = 'Stroke',
}

export const ShapePathMap = {
  [PropertyPanelType.RectanglePath]: PropertyPanelType.Rectangle,
  [PropertyPanelType.EllipsePath]: PropertyPanelType.Ellipse,
  [PropertyPanelType.PolygonPath]: PropertyPanelType.Polygon,
  [PropertyPanelType.StarPath]: PropertyPanelType.Star,
};

// export const ShapeTypeMap = {
//   [ShapeTypeTK.RECTANGLE]: PropertyPanelType.Rectangle,
//   [ShapeTypeTK.ELLIPSE]: PropertyPanelType.Ellipse,
//   // [PropertyPanelType.PolygonPath]: PropertyPanelType.Polygon,
//   [ShapeTypeTK.STAR]: PropertyPanelType.Star,
// };

// LayerType and ShapeType arent available for export when using
// --isolatedModules soo this had to be untyped
export const RENAMABLE_LAYER_TYPES: string[] = [
  // precomps
  'PRECOMPOSITION',
  // shape layer
  'SHAPE',
  // ellipse
  'el',
  // group
  'gr',
  // path
  'sh',
  // rectangle
  'rc',
  // star
  'sr',
];
