/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { NumberInput } from './NumberInput';
import type { NumberInputProps } from './NumberInput';
import type { NumberResult } from './types';

export type NumberInputOption = Omit<NumberInputProps, 'showKeyframe' | 'onKeyframeClick' | 'onChange' | 'value'>;

interface TimelineDualNumberInputProps {
  left: number;
  leftMax?: number;
  leftMin?: number;
  leftOption: NumberInputOption;
  onChange: (result: NumberResult) => void;
  right: number;
  rightMax?: number;
  rightMin?: number;
  rightOption: NumberInputOption;
}

export const TimelineDualNumberInput: React.FC<TimelineDualNumberInputProps> = ({
  left,
  leftMax = null,
  leftMin = null,
  leftOption,
  onChange,
  right,
  rightMax = null,
  rightMin = null,
  rightOption,
}) => {
  return (
    <div className="number-input-timeline flex rounded border-[0.5px] border-transparent focus-within:border-gray-600">
      <NumberInput
        styleClass={{
          label: 'pl-1 pr-1 h-4 w-14 border-[0.5px]  border-transparent bg-gray-700 rounded-l',
          input: 'number-input bg-gray-700 pl-1 text-left text-[10px] font-normal leading-[10px] focus:outline-none',
          span: 'flex select-none items-center justify-center text-gray-500',
        }}
        {...leftOption}
        value={left}
        min={leftMin}
        max={leftMax}
        onChange={onChange}
      />
      <div className="h-4 w-[1px] bg-[#272E34]"></div>
      <NumberInput
        styleClass={{
          label: 'pl-1 pr-1 h-4 w-14 border-[0.5px] border-transparent bg-gray-700 rounded-r',
          input: 'number-input bg-gray-700 pl-1 text-left text-[10px] font-normal leading-[10px] focus:outline-none',
          span: 'flex select-none items-center justify-center text-gray-500',
        }}
        {...rightOption}
        value={right}
        min={rightMin}
        max={rightMax}
        onChange={onChange}
      />
    </div>
  );
};
