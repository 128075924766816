/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useRef, useEffect, useCallback } from 'react';

import { AspectRatioLock, AspectRatioUnlock } from '~/assets/icons';

interface TimelineAspectRatioToggleProps {
  isLocked?: boolean;
  onClick?: () => void;
}

export const TimelineAspectRatioToggle: React.FC<TimelineAspectRatioToggleProps> = ({ isLocked = false, onClick }) => {
  const onClickHandler = useRef(onClick);

  // store onclick handler
  useEffect(() => {
    onClickHandler.current = onClick;
  }, [onClick]);

  const handleOnClick = useCallback(() => {
    if (onClickHandler.current) {
      onClickHandler.current();
    }
  }, []);

  const icon = isLocked ? (
    <>
      <AspectRatioLock className="h-3 w-3" />
    </>
  ) : (
    <AspectRatioUnlock className="h-3 w-3 stroke-[#606F7B]" />
  );

  return (
    <div className="mr-[5px]" onClick={handleOnClick}>
      {icon}
    </div>
  );
};
