/**
 * Copyright 2021 Design Barn Inc.
 */

import { gql } from 'urql';

import { PAGE_INFO_FRAGMENT, PUBLIC_ANIMATION_FRAGMENT } from './fragments';

export const SearchQuery = gql`
  query searchPublicAnimations(
    $query: String!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: QuerySortOptions
  ) {
    searchPublicAnimations(
      query: $query
      first: $first
      after: $after
      last: $last
      before: $before
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...PublicAnimationFields
        }
      }
      pageInfo {
        ...PageInfoFields
      }
      totalCount
    }
  }
  ${PUBLIC_ANIMATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const FeaturedQuery = gql`
  query featuredPublicAnimations($first: Int, $after: String, $last: Int, $before: String, $orderBy: QuerySortOptions) {
    featuredPublicAnimations(first: $first, after: $after, last: $last, before: $before, orderBy: $orderBy) {
      edges {
        node {
          ...PublicAnimationFields
        }
      }
      pageInfo {
        ...PageInfoFields
      }
      totalCount
    }
  }
  ${PUBLIC_ANIMATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const CuratedCollectionQuery = gql`
  query publicCollectionAnimations(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: QuerySortOptions
    $collectionId: Float!
  ) {
    publicCollectionAnimations(
      first: $first
      after: $after
      last: $last
      before: $before
      orderBy: $orderBy
      collectionId: $collectionId
    ) {
      edges {
        node {
          ...PublicAnimationFields
        }
      }
      pageInfo {
        ...PageInfoFields
      }
      totalCount
    }
  }
  ${PUBLIC_ANIMATION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
