/**
 * Copyright 2021 Design Barn Inc.
 */

import React, { useCallback } from 'react';

import { AlignBottom, AlignCenter, AlignLeft, AlignMiddle, AlignRight, AlignTop } from '../icons/AlignIcons';

import { PropertyAccordion } from './ui/PropertyAccordion';

import { emitter, EmitterEvent } from '~/lib/emitter';

const Align: React.FC = () => {
  return (
    <div className="flex items-center">
      <div className="flex space-x-2 p-2">
        <AlignLeft className="h-4 w-4" />
        <AlignCenter className="h-4 w-4" />
        <AlignRight className="h-4 w-4" />
        <div className="border-l border-gray-900"></div>
        <AlignTop className="h-4 w-4" />
        <AlignMiddle className="h-4 w-4" />
        <AlignBottom className="h-4 w-4" />
      </div>
    </div>
  );
};

export const AlignProperty: React.FC = () => {
  return (
    <PropertyAccordion title="Align">
      <Align />
    </PropertyAccordion>
  );
};

interface AlignFullWidthProps {
  enabled: boolean;
}

const AlignFullWidth: React.FC<AlignFullWidthProps> = ({ enabled }) => {
  const handleOnClick = useCallback((direction: string) => {
    const alignment = `ALIGNMENT_${direction.toUpperCase()}`;

    emitter.emit(EmitterEvent[alignment as keyof typeof EmitterEvent]);
  }, []);

  const buttonStyle = `flex h-6 w-6 rounded ${
    enabled ? 'fill-gray-400 stroke-gray-400' : 'fill-gray-600 stroke-gray-600'
  }  p-1 hover:cursor-pointer hover:bg-gray-700 hover:fill-white hover:stroke-white`;

  return (
    <div>
      <div className="flex w-[240px] justify-evenly py-2">
        <div onClick={() => handleOnClick('left')}>
          <AlignLeft className={`${buttonStyle}`} style={{ flex: `1 1 16.666%` }} />
        </div>
        <div onClick={() => handleOnClick('right')}>
          <AlignRight className={`${buttonStyle}`} style={{ flex: `1 1 16.666%` }} />
        </div>
        <div onClick={() => handleOnClick('center')}>
          <AlignCenter className={`${buttonStyle}`} style={{ flex: `1 1 16.666%` }} />
        </div>
        <div onClick={() => handleOnClick('top')}>
          <AlignTop className={`${buttonStyle}`} style={{ flex: `1 1 16.666%` }} />
        </div>
        <div onClick={() => handleOnClick('middle')}>
          <AlignMiddle className={`${buttonStyle}`} style={{ flex: `1 1 16.666%` }} />
        </div>
        <div onClick={() => handleOnClick('bottom')}>
          <AlignBottom className={`${buttonStyle}`} style={{ flex: `1 1 16.666%` }} />
        </div>
      </div>
    </div>
  );
};

export const AlignPropertyFullWidth: React.FC<AlignFullWidthProps> = ({ enabled }) => {
  return (
    <>
      <AlignFullWidth enabled={enabled} />
    </>
  );
};
