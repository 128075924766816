/**
 * Copyright 2022 Design Barn Inc.
 */

import { PropertyType, ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import React, { useCallback, useMemo } from 'react';

import { ColorInput } from '../../../../../../components/Elements/ColorInput/ColorInput';

import type { AnimatedInputProp } from './animatedUtil';

import type { NumberResult } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedColor: React.FC<AnimatedInputProp> = ({ animatedProp, type }) => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  // When keyframes are removed, 'value' shouldn't return undefined. Toolkit will resolve this on next update.
  // TODO: Revert to 'value' when toolkit updates. Temporary fix:
  // const { b, g, r } = animatedProp.value;

  const { b, g, r } = animatedProp.value ?? animatedProp.staticValue;

  // Note: color's alpha is NOT SUPPORTED on Lottie Player. Use fill opacity instead.
  const node = getNodeByIdOnly(animatedProp.id);

  // TODO: Revert to 'value' when toolkit updates. Temporary fix:
  // const opacity = node.parent.state.animatedProperties.o.value.pct || 100;
  const opacity =
    (node.parent.state.animatedProperties.o.value ?? node.parent.state.animatedProperties.o.staticValue).pct || 100;

  const colorString = useMemo(
    () =>
      colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', ''),
    [r, g, b, opacity],
  );

  const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;

  const handleOnChangeColor = useCallback(
    (color: string) => {
      const rgba = colord(color).toRgb();

      if (type === PropertyType.FILL_COLOR) {
        setAnimatedValue(AnimatedType.FILL_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a]);
      } else if (type === PropertyType.STROKE_COLOR) {
        setAnimatedValue(AnimatedType.STROKE_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a]);
      }
      emitter.emit(EmitterEvent.SHAPE_FILL_UPDATED);
    },
    [setAnimatedValue, type],
  );
  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      const currentOpacity = opacity ? opacity : 0;

      if (currentOpacity !== result.value) {
        if (type === PropertyType.FILL_COLOR) {
          setAnimatedValue(AnimatedType.FILL_OPACITY, [result.value]);
        } else if (type === PropertyType.STROKE_COLOR) {
          setAnimatedValue(AnimatedType.STROKE_OPACITY, [result.value]);
        }

        emitter.emit(EmitterEvent.SHAPE_FILL_OPACITY_UPDATED);
      }
    },
    [setAnimatedValue, opacity, type],
  );

  return (
    <div className="flex items-center">
      <ColorInput
        overrideStyle={{ left: '350px' }}
        colorStyleClass={'!w-3 !h-3'}
        styleClass="rounded-r-none w-[70px] !h-4 justify-end"
        inputStyleClass="text-[10px] w-[45px] !h-4 bg-transparent"
        color={colorString}
        opacity={opacity ?? 100}
        onChangeColor={handleOnChangeColor}
        shapeType={type === PropertyType.FILL_COLOR ? ShapeType.FILL : ShapeType.STROKE}
        onChangeOpacity={(value: number) =>
          handleOnChangeOpacity({
            name: 'opacity',
            trueValue: value,
            value,
          })
        }
      />
    </div>
  );
};
