/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable no-case-declarations */
/* eslint-disable padding-line-between-statements */
import { ShapeType } from '@lottiefiles/lottie-js';
import type { SceneJSON, SizeJSON } from '@lottiefiles/toolkit-js';
import { DagNode } from '@lottiefiles/toolkit-js';
import { throttle } from 'lodash-es';
import { Vector3 } from 'three';

import { ViewportConfig } from '../config';
import { UserDataMap } from '../constant';
import type Viewport from '../viewport/viewport';

import { parseToolKitState, reAdjustPrecompCanvas } from './parse';

// eslint-disable-next-line no-restricted-imports
import { getGifTexture } from '~/features/canvas/3d/threeFactory/loading';
// eslint-disable-next-line no-restricted-imports
import { toggleAnimateAll } from '~/features/global-modal/animation-adder/helpers';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AlignDirection } from '~/lib/threejs/TransformControls/AlignmentHelper';
import { getToolkitState, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';
import { AnimationLoaderStatus } from '~/store/uiSlice';
import { isNumber } from '~/utils';

const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
const setScaleRatioLocked = useCreatorStore.getState().ui.setScaleRatioLocked;
const setSizeRatioLocked = useCreatorStore.getState().ui.setSizeRatioLocked;
const removeSelectedNode = useCreatorStore.getState().toolkit.removeSelectedNode;
const removeKeyframes = useCreatorStore.getState().timeline.removeKeyframes;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

export class ToolkitListener {
  public enable = true;

  public viewport: Viewport;

  public constructor(viewport: Viewport) {
    this.viewport = viewport;
  }

  public adjustCanvasSize(): void {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    if (!selectedPrecompositionId) {
      const json = getToolkitState(toolkit);
      const size = json.properties.sz as SizeJSON;

      this.viewport.adjustCanvasSize(size);
    }
  }

  public categorizeEvent(
    event: EmitterEvent,
    data: Record<string, string | string[] | number | boolean | DagNode | SizeJSON> | null,
  ): void {
    const json = getToolkitState(toolkit);
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    switch (event) {
      case EmitterEvent.LOADING_ANIMATION:
      case EmitterEvent.LOADED_ANIMATION:
        this.redraw(true);

        if (data?.['id']) {
          this.viewport.selectByID([data['id'] as string]);
        }

        break;
      case EmitterEvent.RESET:
        this.viewport.clearScene();
        break;

      // We need to destroy the all scene objects and parse the whole toolkit json
      case EmitterEvent.TOOLKIT_JSON_IMPORTED:
      case EmitterEvent.SHAPE_CREATED:
        this.redraw(event === EmitterEvent.SHAPE_CREATED);
        break;
      case EmitterEvent.CANVAS_ZOOM_IN:
        this.viewport.zoomCamera(true);
        break;
      case EmitterEvent.CANVAS_ZOOM_OUT:
        this.viewport.zoomCamera(false);
        break;

      // need to adjust the camera only. No need to update anything else
      case EmitterEvent.CANVAS_ZOOM_TO_FIT:
        this.viewport.adjustCamera(json.properties.sz as SizeJSON);
        break;
      case EmitterEvent.RECTANGLE_CREATED:
        this.viewport.createBasicShapes(ShapeType.RECTANGLE);
        break;
      case EmitterEvent.ELLIPSE_CREATED:
        this.viewport.createBasicShapes(ShapeType.ELLIPSE);
        break;
      // need to update the background size only.
      case EmitterEvent.SCENE_SIZE_UPDATED:
        this.adjustCanvasSize();
        break;

      case EmitterEvent.PRECOMP_SCENE_SIZE_INIT:
        this.updatePrecompJSON();
        break;
      case EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED:
        this.updatePrecompJSON();
        this.redraw(false, event);
        break;
      case EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED:
        const setSelectedPrecompositionJson = useCreatorStore.getState().toolkit.setSelectedPrecompositionJson;

        if (selectedPrecompositionId) {
          const assetNode = getNodeByIdOnly(selectedPrecompositionId);

          const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

          if (isNumber(currentFrame) && assetNode) assetNode.timeline.setCurrentFrame(currentFrame);

          setSelectedPrecompositionJson((assetNode as DagNode).state as SceneJSON);
        }
        this.redraw(false, event);
        break;

      // TIMELINE EVENTS: need to compare the previous toolkit state with the current one and update the only changed parts
      case EmitterEvent.TIMELINE_BAR_DRAGGING_UPDATED:
      case EmitterEvent.TIMELINE_BAR_RESIZE_LEFT_UPDATED:
      case EmitterEvent.TIMELINE_BAR_RESIZE_RIGHT_UPDATED:
      case EmitterEvent.TIMELINE_DURATION_UPDATED:
      case EmitterEvent.TIMELINE_FPS_UPDATED:
      case EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL:
        if (selectedPrecompositionId) {
          this.updatePrecompJSON();
        }
        this.redraw(false);
        break;
      case EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED:
        // When frame update is ended, continue to show the previous selection
        if (!this.viewport.pathControls.penEnabled) this.viewport.transformControls.reselectLastObject();

        break;
      case EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES:
        toggleAnimateAll({ type: data?.type });
        this.redraw(false);
        break;

      case EmitterEvent.CANVAS_COPY:
        this.viewport.editor?.copyObjects();
        break;
      case EmitterEvent.CANVAS_CUT:
        this.viewport.editor?.cutObjects();
        break;
      case EmitterEvent.CANVAS_DUPLICATE:
        this.viewport.editor?.duplicateObjects();
        break;
      case EmitterEvent.CANVAS_PASTE:
        this.viewport.editor?.pasteObjects();
        break;
      case EmitterEvent.UI_UNDO:
      case EmitterEvent.UI_REDO:
        if (useCreatorStore.getState().toolkit.selectedPrecompositionId) this.updatePrecompJSON();
        this.redraw(false);
        this.viewport.transformControls.updateGizmo();
        break;

      // We need to destroy and recreate only selected object for these events
      case EmitterEvent.APPEARANCE_CREATED:
      case EmitterEvent.ANIMATED_ANCHOR_UPDATED:
      case EmitterEvent.ANIMATED_OPACITY_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_FILL_COLOR_ALPHA_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_FILL_COLOR_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_ALPHA_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_TRIM_END_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_TRIM_OFFSET_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_TRIM_START_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED:
      case EmitterEvent.POLYSTAR_INNER_RADIUS_UPDATED:
      case EmitterEvent.POLYSTAR_INNER_ROUNDNESS_UPDATED:
      case EmitterEvent.POLYSTAR_OUTER_RADIUS_UPDATED:
      case EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED:
      case EmitterEvent.POLYSTAR_POINTS_UPDATED:
      case EmitterEvent.RECT_ROUNDNESS_UPDATED:
      case EmitterEvent.SHAPE_SIZE_UPDATED:
      case EmitterEvent.SHAPE_FILL_UPDATED:
      case EmitterEvent.SHAPE_FILL_COLOR_UPDATED:
      case EmitterEvent.SHAPE_FILL_OPACITY_UPDATED:
        this.redraw(false);
        break;

      // We need to change only transformation of the selected object for these events
      case EmitterEvent.ANIMATED_POSITION_UPDATED:
      case EmitterEvent.ANIMATED_ROTATION_UPDATED:
      case EmitterEvent.ANIMATED_SCALE_UPDATED:
      case EmitterEvent.POLYSTAR_ROTATION_UPDATED:
      case EmitterEvent.SHAPE_POSITION_UPDATED:
      case EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED:
        this.redraw(false);
        break;
      case EmitterEvent.SHAPE_MOVE_DOWN:
        this.moveSelectedShape('down');
        break;
      case EmitterEvent.SHAPE_MOVE_LEFT:
        this.moveSelectedShape('left');
        break;
      case EmitterEvent.SHAPE_MOVE_RIGHT:
        this.moveSelectedShape('right');
        break;
      case EmitterEvent.SHAPE_MOVE_UP:
        this.moveSelectedShape('up');
        break;

      // Handle alignment functions
      case EmitterEvent.ALIGNMENT_LEFT:
        this.viewport.transformControls.align(AlignDirection.Left);
        break;
      case EmitterEvent.ALIGNMENT_RIGHT:
        this.viewport.transformControls.align(AlignDirection.Right);
        break;
      case EmitterEvent.ALIGNMENT_CENTER:
        this.viewport.transformControls.align(AlignDirection.Center);
        break;
      case EmitterEvent.ALIGNMENT_TOP:
        this.viewport.transformControls.align(AlignDirection.Top);
        break;
      case EmitterEvent.ALIGNMENT_MIDDLE:
        this.viewport.transformControls.align(AlignDirection.Middle);
        break;
      case EmitterEvent.ALIGNMENT_BOTTOM:
        this.viewport.transformControls.align(AlignDirection.Bottom);
        break;

      case EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED:
        break;
      case EmitterEvent.TOOLKIT_NODE_SCALE_UPDATED:
        const scaleNode = data?.['node'];
        if (scaleNode instanceof DagNode) {
          const scaleRatioLocked = Boolean(data?.[UserDataMap.ScaleRatioLock]);
          scaleNode.setData(UserDataMap.ScaleRatioLock, scaleRatioLocked);
          this.viewport.transformControls.scaleRatioLocked = scaleRatioLocked;
          setScaleRatioLocked(scaleRatioLocked);
        }
        break;
      case EmitterEvent.TOOLKIT_NODE_SIZE_UPDATED:
        const sizeNode = data?.['node'];
        if (sizeNode instanceof DagNode) {
          const sizeRatioLocked = Boolean(data?.[UserDataMap.SizeRatioLock]);
          sizeNode.setData(UserDataMap.SizeRatioLock, sizeRatioLocked);
          setSizeRatioLocked(sizeRatioLocked);
        }
        break;
      case EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT:
        this.viewport.transformControls.lastSelectedObjectIDs = [];
        break;
      case EmitterEvent.CANVAS_OBJECT_ROTATE_LEFT:
        this.viewport.transformControls.rotateLeft();
        break;
      case EmitterEvent.CANVAS_OBJECT_ROTATE_RIGHT:
        this.viewport.transformControls.rotateRight();
        break;
      case EmitterEvent.CANVAS_ADJUST:
        this.viewport.adjustCanvasSize(data?.['size'] as SizeJSON);
        break;
      case EmitterEvent.CANVAS_RESIZE:
        this.viewport.resize();
        break;
      case EmitterEvent.CANVAS_ADJUST_CAMERA:
        this.viewport.adjustCamera(data?.['size'] as SizeJSON);
        break;
      case EmitterEvent.UI_DELETE:
        // Can be with currentFrame or without currentFrame
        const keyframes = useCreatorStore.getState().timeline.selectedKeyframes;
        const currentFrameId = useCreatorStore.getState().toolkit.currentFrameId || '';

        const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;
        stateHistory.beginAction();

        if (!currentFrameId) {
          this.viewport.transformControls.detach();
          if (this.viewport.selectedIDs.length) {
            this.viewport.selectedIDs.forEach((id) => {
              this.viewport.editor?.removeObject(id);
              removeSelectedNode(id);
            });
          }
        }

        if (keyframes.length > 0) {
          removeKeyframes();
        } else {
          this.updatePrecompJSON();
        }

        stateHistory.endAction();

        // TODO: handle multi select
        // Redraw if the deleted node is of type Stroke and Fill to update the canvas
        if (
          selectedNodes[0]?.propertyPanel === PropertyPanelType.Stroke ||
          selectedNodes[0]?.propertyPanel === PropertyPanelType.Fill ||
          selectedNodes[0]?.propertyPanel.toLocaleLowerCase().includes('path')
        ) {
          this.redraw(false);
        }

        // emit event so it will fallthrough to the default case to get the latest toolkit state
        emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
        break;
      case EmitterEvent.CANVAS_DESELECT_ALL:
        this.viewport.select([]);
        break;
      case EmitterEvent.CANVAS_SELECT_ALL_LAYERS:
        this.viewport.selectAll();
        break;
      case EmitterEvent.CANVAS_SELECT_OBJECT:
      case EmitterEvent.CANVAS_SELECT_OBJECT_MULTIPLE:
        this.viewport.select((data as unknown) as string[]);
        break;
      case EmitterEvent.CANVAS_OBJECT_REMOVED:
        const id = data?.['id'] as string;
        if (id) this.viewport.editor?.removeObject(id);
        else if (this.viewport.selectedIDs.length) {
          this.viewport.selectedIDs.forEach((selectedID) => this.viewport.editor?.removeObject(selectedID));
        }
        break;
      case EmitterEvent.CANVAS_DISABLE_PENCONTROL:
        this.viewport.pathControls.disablePenControls();
        break;

      case EmitterEvent.MAIN_SCENE_SELECTED:
        emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
        useCreatorStore.getState().ui.resetLayerUI();
        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
          event: EmitterEvent.CANVAS_ADJUST_CAMERA,
          data: { size: json.properties.sz },
        });
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
          event: EmitterEvent.CANVAS_ADJUST,
          data: { size: json.properties.sz },
        });
        break;

      case EmitterEvent.PLUGIN_CANVAS_UPDATE:
        this.redraw(false);
        break;

      default:
        break;
    }
  }

  public getSize(json: SceneJSON): SizeJSON {
    return json.properties.sz as SizeJSON;
  }

  public moveSelectedShape(direction: string): void {
    const offset = new Vector3();
    switch (true) {
      case direction === 'up':
        offset.set(0, -1, 0);
        break;
      case direction === 'down':
        offset.set(0, 1, 0);
        break;
      case direction === 'left':
        offset.set(-1, 0, 0);
        break;
      case direction === 'right':
        offset.set(1, 0, 0);
        break;
      default:
        break;
    }
    this.viewport.transformControls.moveSelectedShape(offset);
  }

  public redraw(shapeCreated: boolean, event?: EmitterEvent): void {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const animationLoadingStatus = useCreatorStore.getState().ui.animationLoader.status;

    this.viewport.clearScene();

    // get toolkit state json
    let json = getToolkitState(toolkit);

    // parse toolkit state json to get the three.js representation
    if (selectedPrecompositionId) {
      const selectedPrecompositionJson = useCreatorStore.getState().toolkit.selectedPrecompositionJson;

      json = selectedPrecompositionJson as SceneJSON;
    }
    const parsedObjects = parseToolKitState(json);

    if (animationLoadingStatus === AnimationLoaderStatus.Loading) {
      const videoMesh = getGifTexture();
      parsedObjects.push(videoMesh);
    }

    reAdjustPrecompCanvas(selectedPrecompositionId as string | null, parsedObjects, this.viewport.canvasSize);

    if (parsedObjects.length > 0) {
      parsedObjects.forEach((object) => {
        this.viewport.editor?.addObject(object);
      });

      const selectedIdAfterCreated = useCreatorStore.getState().ui.selectedIdAfterCreated;

      if (shapeCreated && selectedIdAfterCreated) {
        // TODO: handle multiselect
        addToSelectedNodes([selectedIdAfterCreated], true);
      } else if (!this.viewport.pathControls.penEnabled) {
        this.viewport.transformControls.reselectLastObject(
          event === EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED || event === EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED,
        );
      }
      this.viewport.pathControls.redrawPathControls();
    }
  }

  public start(): void {
    this.startListener();
  }

  public startListener(): void {
    // We need to listen to TOOLKIT_STATE_UPDATED only so that the render is in sync with currentFrame in timeline
    emitter.on(
      EmitterEvent.TOOLKIT_STATE_UPDATED,
      throttle((param) => {
        this.categorizeEvent(param.event, param.data);
      }, ViewportConfig.RedrawThrottleDelay),
    );
  }

  public stop(): void {
    this.enable = false;
  }

  public updatePrecompJSON(): void {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const setSelectedPrecompositionJson = useCreatorStore.getState().toolkit.setSelectedPrecompositionJson;
    const setSelectedPrecompositionId = useCreatorStore.getState().toolkit.setSelectedPrecompositionId;

    if (selectedPrecompositionId) {
      const assetNode = getNodeByIdOnly(selectedPrecompositionId);

      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

      if (isNumber(currentFrame) && assetNode) assetNode.timeline.setCurrentFrame(currentFrame);

      if (assetNode && (assetNode as DagNode).state) {
        setSelectedPrecompositionJson((assetNode as DagNode).state as SceneJSON);
        emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
      } else {
        setSelectedPrecompositionId(null);
        emitter.emit(EmitterEvent.MAIN_SCENE_SELECTED);
      }
    }
  }
}
