/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

export const Upload: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.10425 5.52863L7.93268 2.7002L10.7611 5.52863"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.93277 2.70035V9.30001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.63293 9.29987L2.63293 11.9665C2.63293 12.3202 2.7578 12.6593 2.98007 12.9093C3.20233 13.1594 3.50379 13.2999 3.81812 13.2999L12.1144 13.2999C12.4287 13.2999 12.7302 13.1594 12.9525 12.9093C13.1747 12.6593 13.2996 12.3202 13.2996 11.9665L13.2996 9.29987"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const Link: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9387 8.44615C13.5187 9.04673 13.8397 9.85112 13.8325 10.6861C13.8252 11.521 13.4903 12.3197 12.8999 12.9101C12.3095 13.5005 11.5108 13.8354 10.6758 13.8427C9.84091 13.8499 9.03652 13.529 8.43594 12.9489L8.42969 12.9429L6.81917 11.3322C6.49625 11.0094 6.24647 10.6209 6.08689 10.1931C5.9273 9.76524 5.86158 9.30809 5.8942 8.85263C5.92682 8.39717 6.05701 7.95405 6.27594 7.55333C6.49488 7.15261 6.79743 6.80366 7.16308 6.53015C7.38421 6.36475 7.69755 6.40992 7.86295 6.63104C8.02836 6.85217 7.98318 7.16551 7.76206 7.33091C7.51123 7.51853 7.30369 7.7579 7.15351 8.03278C7.00333 8.30767 6.91402 8.61163 6.89165 8.92406C6.86927 9.23649 6.91435 9.55008 7.02383 9.84356C7.1333 10.137 7.30462 10.4035 7.52614 10.625L9.13363 12.2325C9.54529 12.6286 10.0958 12.8477 10.6672 12.8427C11.2399 12.8377 11.7878 12.608 12.1928 12.203C12.5978 11.798 12.8275 11.2501 12.8325 10.6774C12.8375 10.1058 12.6182 9.55505 12.2218 9.14334L11.3064 8.22259C11.1117 8.02676 11.1126 7.71018 11.3085 7.51549C11.5043 7.3208 11.8209 7.32172 12.0156 7.51755L12.9387 8.44615Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9117 5.81504C10.0713 6.24287 10.137 6.70002 10.1044 7.15548C10.0718 7.61094 9.94158 8.05405 9.72265 8.45478C9.50372 8.8555 9.20117 9.20445 8.83552 9.47796C8.61439 9.64336 8.30105 9.59819 8.13564 9.37706C7.97024 9.15594 8.01541 8.84259 8.23654 8.67719C8.48736 8.48957 8.6949 8.2502 8.84508 7.97532C8.99526 7.70044 9.08457 7.39647 9.10695 7.08404C9.12932 6.77161 9.08424 6.45802 8.97477 6.16454C8.86529 5.87106 8.69398 5.60456 8.47245 5.38311L6.86498 3.77564C6.45332 3.37954 5.9028 3.16043 5.33143 3.1654C4.75869 3.17037 4.21082 3.4001 3.80581 3.80511C3.40081 4.21012 3.17108 4.75799 3.1661 5.33073C3.16113 5.9021 3.38024 6.45261 3.77634 6.86427L4.69118 7.77911C4.88644 7.97437 4.88644 8.29095 4.69118 8.48622C4.49592 8.68148 4.17933 8.68148 3.98407 8.48622L3.05986 7.56201C2.4798 6.96142 2.15888 6.15698 2.16614 5.32204C2.17339 4.4871 2.50829 3.68842 3.0987 3.098C3.68912 2.50759 4.48781 2.17269 5.32275 2.16543C6.15768 2.15818 6.96207 2.47915 7.56266 3.05921L7.56891 3.06525L9.17943 4.67588C9.50234 4.99869 9.75212 5.38724 9.9117 5.81504Z"
        fill="currentColor"
      />
    </svg>
  );
});
