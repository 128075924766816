/**
 * Copyright 2023 Design Barn Inc.
 */

import type { DagNode, PathShape, Shape } from '@lottiefiles/toolkit-js';
import { StarType, PropertyType, ShapeType } from '@lottiefiles/toolkit-js';
import { intersection } from 'lodash-es';

// eslint-disable-next-line no-restricted-imports
import { getCombinedAnimatedProperties } from '~/features/global-modal/animation-adder/animatedProperties';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, checkHasActiveKeyFrame, removeKeyFrames, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const addAnimatedValue = useCreatorStore.getState().toolkit.addAnimatedValue;
const getCurrentTransform = useCreatorStore.getState().toolkit.getCurrentTransformById;
const setStaticValue = useCreatorStore.getState().toolkit.setStaticValue;
const removeAnimated = useCreatorStore.getState().toolkit.removeAnimated;

export interface AnimatedPanelCategory {
  animationKeys?: string[];
  label?: string;
  name?: string;
  type?: string | null;
}

export interface AnimatedPanelProperty {
  category: AnimatedPanelCategory[];
  isAnimated: boolean;
  label: string;
  selected: boolean;
  type: string;
  updateKeyframe?: () => void;
}

export const animatedNameLabels = {
  [PropertyType.SHAPE]: { name: 'shape', label: 'Shape', type: PropertyType.SHAPE },
  [ShapeType.FILL]: { name: 'fill', label: 'Fill', type: ShapeType.FILL },
  [ShapeType.STROKE]: { name: 'stroke', label: 'Stroke', type: ShapeType.STROKE },
  [PropertyType.PATH]: { name: 'path', label: 'Path', type: ShapeType.PATH },

  [ShapeType.RECTANGLE]: { name: 'rectangle', label: 'Rectangle', type: ShapeType.RECTANGLE },
  [ShapeType.ELLIPSE]: { name: 'ellipse', label: 'Ellipse', type: ShapeType.ELLIPSE },
  [`${ShapeType.STAR}_star`]: { name: 'star', label: 'Star', type: ShapeType.STAR },
  [`${ShapeType.STAR}_polygon`]: { name: 'polygon', label: 'Polygon', type: ShapeType.STAR },
};

export const animatedCategoryUIMapping = {
  [ShapeType.STROKE]: [PropertyType.STROKE_COLOR, PropertyType.STROKE_WIDTH],
  [ShapeType.FILL]: [PropertyType.FILL_COLOR, PropertyType.OPACITY],
  [PropertyType.PATH]: [],
};

export const hasMapping = (node: DagNode): boolean => {
  return Object.entries(animatedCategoryUIMapping).some(([categoryMappingType]) => node.type === categoryMappingType);
};

export const isTransform = (node: DagNode): boolean => {
  return node.type === 'SHAPE';
};

export const isShape = (node: DagNode): boolean => {
  return ['el', 'sr', 'rc'].includes(node.type);
};

export const animatedTransformProperties = [
  {
    type: PropertyType.POSITION,
    label: 'Position',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.SCALE,
    label: 'Scale',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.OPACITY,
    label: 'Opacity',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.ROTATION,
    label: 'Rotation',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
];

export const animatedPanelProperties: AnimatedPanelProperty[] = [
  ...animatedTransformProperties,
  {
    type: PropertyType.SIZE,
    label: 'Size',
    selected: false,
    isAnimated: false,
    category: [
      animatedNameLabels[PropertyType.SHAPE],
      animatedNameLabels[ShapeType.RECTANGLE],
      animatedNameLabels[ShapeType.ELLIPSE],
    ],
  },
  {
    type: PropertyType.FILL_COLOR,
    label: 'Fill Color',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.FILL]],
  },
  {
    type: PropertyType.OPACITY,
    label: 'Fill Opacity',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.FILL]],
  },
  {
    type: ShapeType.PATH,
    label: 'Edit Path',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.PATH]],
  },
  {
    type: PropertyType.STROKE_WIDTH,
    label: 'Stroke Width',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.STROKE]],
  },
  {
    type: PropertyType.STROKE_COLOR,
    label: 'Stroke Color',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.STROKE]],
  },
  {
    type: PropertyType.ROUNDNESS,
    label: 'Roundness',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE], animatedNameLabels[ShapeType.RECTANGLE]],
  },
  {
    type: PropertyType.NUMBER_OF_POINTS,
    label: 'Points',
    selected: false,
    isAnimated: false,
    category: [
      animatedNameLabels[PropertyType.SHAPE],
      animatedNameLabels[`${ShapeType.STAR}_polygon`],
      animatedNameLabels[`${ShapeType.STAR}_star`],
    ],
  },
  {
    type: PropertyType.INNER_RADIUS,
    label: 'Inner radius',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE], animatedNameLabels[`${ShapeType.STAR}_star`]],
  },
  {
    type: PropertyType.OUTER_RADIUS,
    label: 'Outer radius',
    selected: false,
    isAnimated: false,
    category: [
      animatedNameLabels[PropertyType.SHAPE],
      animatedNameLabels[`${ShapeType.STAR}_polygon`],
      animatedNameLabels[`${ShapeType.STAR}_star`],
    ],
  },
  {
    type: PropertyType.INNER_ROUNDNESS,
    label: 'Inner roundness',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE], animatedNameLabels[`${ShapeType.STAR}_star`]],
  },
  {
    type: PropertyType.OUTER_ROUNDNESS,
    label: 'Outer roundness',
    selected: false,
    isAnimated: false,
    category: [
      animatedNameLabels[PropertyType.SHAPE],
      animatedNameLabels[`${ShapeType.STAR}_polygon`],
      animatedNameLabels[`${ShapeType.STAR}_star`],
    ],
  },
];

export const animatedPanelCategories: AnimatedPanelCategory[] = [
  {
    type: PropertyType.SHAPE,
    name: 'shape',
    label: 'Transform',
  },
  {
    type: ShapeType.FILL,
    name: 'fill',
    label: 'Fill',
  },
  {
    type: PropertyType.PATH,
    name: 'path',
    label: 'Path',
  },
  {
    type: ShapeType.STROKE,
    name: 'stroke',
    label: 'Stroke',
  },
];

export const animatedPanelShapes = [
  {
    type: ShapeType.RECTANGLE,
    name: 'rectangle',
    label: 'Rectangle Path',
  },
  {
    type: ShapeType.ELLIPSE,
    name: 'ellipse',
    label: 'Ellipse Path',
  },
  {
    type: ShapeType.STAR,
    name: 'star',
    label: 'Star Path',
  },
  {
    type: ShapeType.STAR,
    name: 'polygon',
    label: 'Polygon Path',
  },
];

interface CurrentTransform {
  [key: string]: unknown;
  currentKeyframe: string;
}

export const localUIUpdate = (node: DagNode, type: string): CurrentTransform => {
  const currentShape = useCreatorStore.getState().toolkit.currentSecondary?.currentShape;

  const isShapeNodeSelected = [ShapeType.RECTANGLE, ShapeType.STAR, ShapeType.ELLIPSE].includes(node.type);
  let data = {};
  let currentKeyframe = '';

  if (isShapeNodeSelected) {
    if (type === PropertyType.ROUNDNESS) {
      data = {
        roundness: currentShape.rect.roundness,
      };
      currentKeyframe = currentShape.rect.roundnessCurrentKeyFrame;
    } else if (type === PropertyType.OUTER_RADIUS) {
      data = {
        outerRadius: currentShape.polystar.outerRadius,
      };
      currentKeyframe = currentShape.polystar.outerRadiusCurrentKeyFrame;
    } else if (type === PropertyType.NUMBER_OF_POINTS) {
      data = {
        numPoints: currentShape.polystar.points,
      };
      currentKeyframe = currentShape.polystar.pointsCurrentKeyFrame;
    } else if (type === PropertyType.INNER_RADIUS) {
      data = {
        innerRadius: currentShape.polystar.innerRadius,
      };
      currentKeyframe = currentShape.polystar.innerRadiusCurrentKeyFrame;
    } else if (type === PropertyType.INNER_ROUNDNESS) {
      data = {
        innerRoundness: currentShape.polystar.innerRoundness,
      };
      currentKeyframe = currentShape.polystar.innerRoundnessCurrentKeyFrame;
    } else if (type === PropertyType.OUTER_ROUNDNESS) {
      data = {
        outerRoundness: currentShape.polystar.outerRoundness,
      };
      currentKeyframe = currentShape.polystar.outerRoundnessCurrentKeyFrame;
    } else if (type === PropertyType.SIZE) {
      const size = currentShape.size;

      data = {
        size,
      };
      currentKeyframe = currentShape.sizeCurrentKeyFrame;
    }
  }

  let currentTransform = getCurrentTransform(node.nodeId);

  if (currentTransform) {
    if ([ShapeType.FILL, ShapeType.STROKE, ShapeType.PATH].includes(node.type)) {
      if (type === PropertyType.OPACITY && node.type === ShapeType.FILL) {
        const hasActiveKeyFrame = checkHasActiveKeyFrame();
        const opacityAnimatedProperties = node.state.animatedProperties[type];

        data = {
          opacity: opacityAnimatedProperties.value.pct || 100,
        };
        currentKeyframe = hasActiveKeyFrame(opacityAnimatedProperties);
      } else if ([PropertyType.STROKE_COLOR, PropertyType.FILL_COLOR].includes(type as string)) {
        const hasActiveKeyFrame = checkHasActiveKeyFrame();
        const colorAnimatedProperties = node.state.animatedProperties[type];

        data = {
          color: colorAnimatedProperties.value,
        };
        currentKeyframe = hasActiveKeyFrame(colorAnimatedProperties);
      } else if (type === PropertyType.STROKE_WIDTH) {
        const hasActiveKeyFrame = checkHasActiveKeyFrame();

        const strokeWidthAnimatedProperties = node.state.animatedProperties.sw;

        data = {
          strokeWidth: strokeWidthAnimatedProperties.value.value,
        };
        currentKeyframe = hasActiveKeyFrame(strokeWidthAnimatedProperties);
      } else if (type === ShapeType.PATH) {
        const hasActiveKeyFrame = checkHasActiveKeyFrame();

        const pathAnimatedProperties = node.state.animatedProperties.sh;

        data = {
          path: ((node as unknown) as PathShape).shape.value,
        };
        currentKeyframe = hasActiveKeyFrame(pathAnimatedProperties);
      }
    } else if (type === PropertyType.POSITION) {
      // Shapes and other nodes have to be handled separately
      if (isShapeNodeSelected) {
        data = {
          x: currentShape.position[0],
          y: currentShape.position[1],
        };
        currentKeyframe = currentShape.positionCurrentKeyFrame;
      } else {
        data = {
          x: currentTransform.position[0],
          y: currentTransform.position[1],
        };
        currentKeyframe = currentTransform.positionCurrentKeyframe;
      }
    } else if (type === PropertyType.OPACITY) {
      data = {
        opacity: currentTransform.opacity || 100,
      };
      currentKeyframe = currentTransform.opacityCurrentKeyframe;
    } else if (type === PropertyType.SCALE) {
      data = {
        scaleX: currentTransform.scale[0] || 1,
        scaleY: currentTransform.scale[1] || 1,
      };
      currentKeyframe = currentTransform.scaleCurrentKeyframe;
    } else if (type === PropertyType.ROTATION) {
      // Stars can rotate too
      if (isShapeNodeSelected) {
        data = {
          rotation: currentShape.polystar.rotation,
        };
        currentKeyframe = currentShape.polystar.rotationCurrentKeyFrame;
      } else {
        data = {
          rotation: currentTransform.rotation || 0,
        };
        currentKeyframe = currentTransform.rotationCurrentKeyframe;
      }
    }
  }

  currentTransform = {
    currentKeyframe,
    [type]: data,
  };

  return currentTransform as CurrentTransform;
};

export const getFilteredAnimationCategories = (node: Shape): AnimatedPanelCategory[] => {
  const layer = node.toJSON();

  let filteredAnimatedPanelCategories: AnimatedPanelCategory[] = [];
  const animationKeys = Object.keys(layer.animatedProperties) as string[];

  if (hasMapping(node)) {
    const mapIndex = animatedPanelCategories.findIndex((panelCat) => panelCat.type === node.type);

    if (mapIndex > -1) {
      filteredAnimatedPanelCategories = [
        {
          ...animatedPanelCategories[mapIndex],
          animationKeys: animatedCategoryUIMapping[node.type],
        },
      ];
    }
  } else if (isTransform(node)) {
    filteredAnimatedPanelCategories = animatedPanelCategories
      .map((categoryProperty) => {
        const catAnimationKeys = animatedTransformProperties.filter((element) => {
          const categoriesNames = element.category.map((elemCat) => elemCat.name);

          return categoriesNames.includes(categoryProperty.name);
        });

        if (catAnimationKeys.length > 0) {
          const catAnimationTypes = catAnimationKeys.map((animKey) => animKey.type);

          return {
            ...categoryProperty,
            animationKeys: catAnimationTypes as string[],
          };
        }

        return {
          ...categoryProperty,
        };
      })
      .filter((animatedCategoryProperty) => {
        return intersection(animatedCategoryProperty.animationKeys, animationKeys).length > 0;
      });
  } else if (isShape(node)) {
    filteredAnimatedPanelCategories = animatedPanelShapes
      .filter((animatedShape) =>
        node.type === ShapeType.STAR
          ? node.name.toLowerCase().includes(animatedShape.name)
          : animatedShape.type === node.type,
      )
      .map((categoryProperty) => {
        const catAnimationKeys = animatedPanelProperties.filter((element) => {
          const categoriesNames = element.category.map((elemCat) => elemCat.name);

          return categoriesNames.includes(categoryProperty.name);
        });

        if (catAnimationKeys.length > 0) {
          const catAnimationTypes = catAnimationKeys.map((animKey) => animKey.type);

          return {
            ...categoryProperty,
            animationKeys: catAnimationTypes as string[],
          };
        }

        return {
          ...categoryProperty,
        };
      })
      .filter((animatedCategoryProperty) => {
        return intersection(animatedCategoryProperty.animationKeys, animationKeys).length > 0;
      });
  } else {
    filteredAnimatedPanelCategories = animatedPanelCategories
      .map((categoryProperty) => {
        const catAnimationKeys = animatedPanelProperties.filter((element) => {
          const categoriesNames = element.category.map((elemCat) => elemCat.name);

          return categoriesNames.includes(categoryProperty.name);
        });

        if (catAnimationKeys.length > 0) {
          const catAnimationTypes = catAnimationKeys.map((animKey) => animKey.type);

          return {
            ...categoryProperty,
            animationKeys: catAnimationTypes as string[],
          };
        }

        return {
          ...categoryProperty,
        };
      })
      .filter((animatedCategoryProperty) => {
        return intersection(animatedCategoryProperty.animationKeys, animationKeys).length > 0;
      });
  }

  return filteredAnimatedPanelCategories;
};

export const updateKeyframe = (type: string, nodeId: string): void => {
  // TODO: handle multiselection for animate all button
  const layerId = nodeId;
  // useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;
  const node = getNodeByIdOnly(layerId) as DagNode;

  const currentTransform = localUIUpdate(node, type) as CurrentTransform;
  const { currentKeyframe } = currentTransform;

  removeKeyFrames(currentKeyframe);
  removeAnimated(layerId, type);

  if (currentKeyframe) {
    if (type === PropertyType.POSITION) {
      const { x, y } = currentTransform[type];

      setStaticValue(AnimatedType.POSITION, [x, y], layerId);
    } else if (type === PropertyType.SCALE) {
      const { scaleX, scaleY } = currentTransform[type];

      setStaticValue(AnimatedType.SCALE, [scaleX, scaleY], layerId);
    } else if (type === PropertyType.OPACITY) {
      const { opacity } = currentTransform[type];

      setStaticValue(AnimatedType.OPACITY, [opacity], layerId);
    } else if (type === PropertyType.ROTATION) {
      const { rotation } = currentTransform[type];

      setStaticValue(AnimatedType.ROTATION, [rotation], layerId);
    } else if (type === PropertyType.FILL_COLOR) {
      const { b, g, r } = currentTransform[type].color;

      setStaticValue(AnimatedType.FILL_COLOR, [r, g, b], layerId);
    } else if (type === ShapeType.PATH) {
      // PATH
      const { path } = currentTransform[type];

      setStaticValue(AnimatedType.PATH, [path], layerId);
    } else if (type === PropertyType.STROKE_COLOR) {
      const { b, g, r } = currentTransform[type].color;

      setStaticValue(AnimatedType.STROKE_COLOR, [r, g, b], layerId);
    } else if (type === PropertyType.STROKE_WIDTH) {
      const { strokeWidth } = currentTransform[type];

      setStaticValue(AnimatedType.STROKE_WIDTH, [strokeWidth], layerId);
    } else if (type === PropertyType.ROUNDNESS) {
      const { roundness } = currentTransform[type];

      setStaticValue(AnimatedType.ROUNDNESS, [roundness], layerId);
    } else if (type === PropertyType.OUTER_RADIUS) {
      const { outerRadius } = currentTransform[type];

      setStaticValue(AnimatedType.OUTER_RADIUS, [outerRadius], layerId);
    } else if (type === PropertyType.NUMBER_OF_POINTS) {
      const { numPoints } = currentTransform[type];

      setStaticValue(AnimatedType.POINTS, [numPoints], layerId);
    } else if (type === PropertyType.INNER_RADIUS) {
      const { innerRadius } = currentTransform[type];

      setStaticValue(AnimatedType.INNER_RADIUS, [innerRadius], layerId);
    } else if (type === PropertyType.INNER_ROUNDNESS) {
      const { innerRoundness } = currentTransform[type];

      setStaticValue(AnimatedType.INNER_ROUNDNESS, [innerRoundness], layerId);
    } else if (type === PropertyType.OUTER_ROUNDNESS) {
      const { outerRoundness } = currentTransform[type];

      setStaticValue(AnimatedType.OUTER_ROUNDNESS, [outerRoundness], layerId);
    } else if (type === PropertyType.SIZE) {
      const { size } = currentTransform[type];

      setStaticValue(AnimatedType.SIZE, size, layerId);
    }
  } else if (type === PropertyType.POSITION) {
    const { x, y } = currentTransform[type];

    addAnimatedValue(AnimatedType.POSITION, [x, y], layerId);
  } else if (type === PropertyType.SCALE) {
    const { scaleX, scaleY } = currentTransform[type];

    addAnimatedValue(AnimatedType.SCALE, [scaleX, scaleY], layerId);
  } else if (type === PropertyType.OPACITY) {
    const { opacity } = currentTransform[type];

    addAnimatedValue(AnimatedType.OPACITY, [opacity], layerId);
  } else if (type === PropertyType.ROTATION) {
    const { rotation } = currentTransform[type];

    addAnimatedValue(AnimatedType.ROTATION, [rotation], layerId);
  } else if (type === PropertyType.FILL_COLOR) {
    const { b, g, r } = currentTransform[type].color;

    addAnimatedValue(AnimatedType.FILL_COLOR, [r, g, b], layerId);
  } else if (type === ShapeType.PATH) {
    const { path } = currentTransform[type];

    addAnimatedValue(AnimatedType.PATH, [path], layerId);
  } else if (type === PropertyType.STROKE_COLOR) {
    const { b, g, r } = currentTransform[type].color;

    addAnimatedValue(AnimatedType.STROKE_COLOR, [r, g, b], layerId);
  } else if (type === PropertyType.STROKE_WIDTH) {
    const { strokeWidth } = currentTransform[type];

    addAnimatedValue(AnimatedType.STROKE_WIDTH, [strokeWidth], layerId);
  } else if (type === PropertyType.ROUNDNESS) {
    const { roundness } = currentTransform[type];

    addAnimatedValue(AnimatedType.ROUNDNESS, [roundness], layerId);
  } else if (type === PropertyType.OUTER_RADIUS) {
    const { outerRadius } = currentTransform[type];

    addAnimatedValue(AnimatedType.OUTER_RADIUS, [outerRadius], layerId);
  } else if (type === PropertyType.NUMBER_OF_POINTS) {
    const { numPoints } = currentTransform[type];

    addAnimatedValue(AnimatedType.POINTS, [numPoints], layerId);
  } else if (type === PropertyType.INNER_RADIUS) {
    const { innerRadius } = currentTransform[type];

    addAnimatedValue(AnimatedType.INNER_RADIUS, [innerRadius], layerId);
  } else if (type === PropertyType.INNER_ROUNDNESS) {
    const { innerRoundness } = currentTransform[type];

    addAnimatedValue(AnimatedType.INNER_ROUNDNESS, [innerRoundness], layerId);
  } else if (type === PropertyType.OUTER_ROUNDNESS) {
    const { outerRoundness } = currentTransform[type];

    addAnimatedValue(AnimatedType.OUTER_ROUNDNESS, [outerRoundness], layerId);
  } else if (type === PropertyType.SIZE) {
    const { size } = currentTransform[type];

    addAnimatedValue(AnimatedType.SIZE, size, layerId);
  }

  emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
};

export const getFilteredAnimationProperties = (node: Shape): AnimatedPanelProperty[] => {
  let filteredAnimationProperties: AnimatedPanelProperty[] = [];

  const filteredAnimationCategories = getFilteredAnimationCategories(node) as AnimatedPanelCategory[];

  const layer = node.toJSON();

  let animationKeys = Object.keys(layer.animatedProperties) as string[];

  if (layer.type === ShapeType.STAR && (layer.properties.sy as StarType) === StarType.Polygon) {
    animationKeys = animationKeys.filter(
      (key) => ![PropertyType.INNER_RADIUS, PropertyType.INNER_ROUNDNESS].includes(key as PropertyType),
    );
  }

  filteredAnimationCategories.forEach((animatedPropertyCategory: AnimatedPanelCategory) => {
    if (hasMapping(node)) {
      const animatedKeys = animatedPropertyCategory.animationKeys as string[];

      filteredAnimationProperties = animatedKeys.reduce(
        (animatedProperties: AnimatedPanelProperty[], animatedKey: string): AnimatedPanelProperty[] => {
          const animatedIndex = animatedPanelProperties.findIndex((animatedProperty) => {
            if (animatedProperty.category.length > 0) {
              const animPropertyCategoryIndex = animatedProperty.category.findIndex(
                (animCategory) => animCategory.type === node.type,
              );

              return animatedProperty.type === animatedKey && animPropertyCategoryIndex > -1;
            }

            return animatedProperty.type === animatedKey;
          }) as number;

          if (animatedIndex > -1) {
            animatedProperties.push(animatedPanelProperties[animatedIndex] as AnimatedPanelProperty);
          }

          return animatedProperties;
        },
        [],
      );
    } else if (isShape(node)) {
      const animatedKeys = animatedPropertyCategory.animationKeys as string[];

      filteredAnimationProperties = animatedKeys.reduce(
        (animatedProperties: AnimatedPanelProperty[], animatedKey: string): AnimatedPanelProperty[] => {
          const animatedIndex = animatedPanelProperties.findIndex(
            (animatedProperty) => animatedProperty.type === animatedKey,
          ) as number;

          let withinCategory = false;

          if (animatedPanelProperties[animatedIndex].category.length > 0) {
            const categoryIndex = animatedPanelProperties[animatedIndex].category.findIndex(
              (animatedCat) => animatedCat.type === layer.type,
            );

            if (categoryIndex > -1) withinCategory = true;
          }

          if (animatedIndex > -1 && withinCategory) {
            animatedProperties.push(animatedPanelProperties[animatedIndex] as AnimatedPanelProperty);
          }

          return animatedProperties;
        },
        [],
      );
    } else {
      filteredAnimationProperties = animatedPanelProperties.filter((element) => {
        const categoriesNames = element.category.map((elemCat) => elemCat.name);

        return categoriesNames.includes(animatedPropertyCategory.name) && animationKeys.includes(element.type);
      });
    }
  });

  if (filteredAnimationProperties.length > 0) {
    for (const prop of filteredAnimationProperties) {
      const currentTransform = localUIUpdate(node, prop.type);

      prop.updateKeyframe = () => {
        updateKeyframe(prop.type, node.nodeId);
        localUIUpdate(node, prop.type);
      };
      prop.isAnimated = currentTransform.currentKeyframe !== '';
    }
  }

  return filteredAnimationProperties as AnimatedPanelProperty[];
};

export const toggleAnimateAll = (): void => {
  // TODO: handle multiselection for animate all button
  const nodeId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;

  if (!nodeId) return;

  const node = getNodeByIdOnly(nodeId) as Shape;

  const { combinedAnimatedMaps } = getCombinedAnimatedProperties(node, nodeId);

  const type = useCreatorStore.getState().ui.animatedPropertyPopupUI.type;
  const types = useCreatorStore.getState().ui.animatedPropertyPopupUI.types;
  const enableAll = useCreatorStore.getState().ui.animatedPropertyPopupUI.enableAll;

  let animatedProps = [];

  // eslint-disable-next-line no-undefined
  if (enableAll === undefined && type) {
    animatedProps = [combinedAnimatedMaps[type as string]];
  } else if (enableAll) {
    animatedProps = types.map((item) => combinedAnimatedMaps[item as string]);
  }
  //   else if (enableAll === false) {
  //   }

  if (animatedProps.length > 0) {
    stateHistory.beginAction();
    animatedProps.forEach((animatedProp: unknown) => {
      const allAnimated = animatedProp.every((prop) => prop.isAnimated);
      const allNotAnimated = animatedProp.every((prop) => !prop.isAnimated);

      if (allAnimated || allNotAnimated) {
        for (const prop of animatedProp) {
          (prop.updateKeyframe as () => void)();
        }
      } else {
        // if only some props are animated, animate the other props
        for (const prop of animatedProp) {
          if (!prop.isAnimated) (prop.updateKeyframe as () => void)();
        }
      }
    });

    stateHistory.endAction();
  }
};
