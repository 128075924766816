/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { CompositionProperty } from '../components/CompositionProperty';
import { TimeProperty } from '../components/TimeProperty';
import { Divider } from '../components/ui/Divider';

import { PropertyPanel } from './PropertyPanel';

import { useCreatorStore } from '~/store';

export const CompositionPanel: React.FC = () => {
  const [currentPrecompJson] = useCreatorStore(
    (state) => [state.toolkit.selectedPrecompositionJson, state.toolkit.selectedPrecompositionJson?.data],
    shallow,
  );

  return (
    <PropertyPanel key={currentPrecompJson ? currentPrecompJson.id : 'main'}>
      <CompositionProperty />
      <Divider />
      <TimeProperty />
      <Divider />
      {/* <BackgroundProperty /> */}
    </PropertyPanel>
  );
};
