/**
 * Copyright 2023 Design Barn Inc.
 */

import { graphql } from 'msw';

export const handlers = [
  // Handles a "Login" mutation
  graphql.query('userHasCreatorAccess', (_, res, ctx) => {
    return res(
      ctx.data({
        userHasCreatorAccess: true,
      }),
    );
  }),

  graphql.query('hasAccessToAWorkspace', (_, res, ctx) => {
    return res(
      ctx.data({
        hasAccessToAWorkspace: true,
      }),
    );
  }),

  graphql.query('getWorkspaces', (_, res, ctx) => {
    return res(
      ctx.data({
        workspaces: [],
      }),
    );
  }),
];
