/**
 * Copyright 2023 Design Barn Inc.
 */

import type { BezierInterpolator, Interpolator } from '@lottiefiles/toolkit-js';
import { Vector } from '@lottiefiles/toolkit-js';

import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { Plugin } from '~/plugins/Plugin';

interface BezierInterpolatorJSON {
  controlPoints: Vector[];
  interpolatorType: string;
}

const methodNames = {
  interpolate: 'interpolate',
  getYValueFromT: 'getYValueFromT',
  getTValueFromX: 'getTValueFromX',
  controlPoints: 'controlPoints',
};

function getVmControlPoints(controlPoints: Vector[]): Vector[] {
  const vmControlPoints = [];

  for (const controlPoint of controlPoints) {
    // TODO: use createVectorObject instead
    const vmControlPoint = {
      x: controlPoint.x,
      y: controlPoint.y,
      valueType: 'Vector',
    };

    vmControlPoints.push(vmControlPoint);
  }

  return (vmControlPoints as unknown) as Vector[];
}

const bezierModifiers = {
  controlPoints: (controlPoints: Vector[]): Vector[] => {
    return getVmControlPoints(controlPoints);
  },
};

function split(interpolator: BezierInterpolator, x: number): BezierInterpolator[] {
  const returnedInterpolators = interpolator.split(x);

  const vmInterpolators = [];

  for (const returnedInterpolator of returnedInterpolators) {
    const vmControlPoints = getVmControlPoints((returnedInterpolator.controlPoints as unknown) as Vector[]);

    vmInterpolators.push(vmControlPoints);
  }

  return (vmInterpolators as unknown) as BezierInterpolator[];
}

function splitT(interpolator: BezierInterpolator, t: number): BezierInterpolator[] {
  const returnedInterpolators = interpolator.split(t);

  const vmInterpolators = [];

  for (const returnedInterpolator of returnedInterpolators) {
    const vmControlPoints = getVmControlPoints((returnedInterpolator.controlPoints as unknown) as Vector[]);

    vmInterpolators.push(vmControlPoints);
  }

  return (vmInterpolators as unknown) as BezierInterpolator[];
}

function setControlPoints(interpolator: BezierInterpolator, vmControlPoints: Vector[]): void {
  const newControlPoints = [];

  for (const vmControlPoint of vmControlPoints) {
    const controlPoint = new Vector(vmControlPoint.x, vmControlPoint.y);

    newControlPoints.push(controlPoint);
  }

  interpolator.setControlPoints((newControlPoints as unknown) as Vector[]);
}

function toJSON(json: BezierInterpolatorJSON): BezierInterpolatorJSON {
  return {
    interpolatorType: 'Bezier',
    controlPoints: (json.controlPoints.map((cp: Vector) => cp.toJSON()) as unknown) as Vector[],
  };
}

const additionalMethods = {
  setControlPoints,
  split,
  splitT,
  toJSON,
};

function getBezierInterpolatorMethods(plugin: Plugin, interpolator: Interpolator): ObjectMethods {
  return getObjectMethods(plugin, methodNames, interpolator, bezierModifiers, additionalMethods);
}

export function createBezierInterpolatorObject(
  plugin: Plugin,
  interpolator: BezierInterpolator,
): BezierInterpolatorJSON {
  const interpolatorObject = {
    interpolatorType: 'Bezier',
  };

  registerObjectMethods(plugin, interpolator, interpolatorObject, [getBezierInterpolatorMethods]);

  return interpolatorObject as BezierInterpolatorJSON;
}
