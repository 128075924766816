/**
 * Copyright 2023 Design Barn Inc.
 */

import type {
  AnimatedPositionProperty,
  AnimatedAngleProperty,
  AnimatedScaleProperty,
  AnimatedPercentageProperty,
  AnimatedColorProperty,
  AnimatedVectorProperty,
  Track,
  Value,
  AnimatedProperty,
} from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { getPropertyMethods } from '../properties';
import { createKeyframeObj } from '../time/keyframe';
import { createTrackObject } from '../time/track';
import { handleOutgoingValueTypes } from '../vmInterface/marshal/class';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  copyValue: 'copyValue',
  animatedKeyFrames: 'animatedKeyFrames',
  keyFrames: 'keyFrames',
  track: 'track',
  valueAtKeyFrame: 'valueAtKeyFrame',
  isActive: 'isActive',
  setIsAnimated: 'setIsAnimated',
  setValueAtKeyFrame: 'setValueAtKeyFrame',
  splitKeyFrameAt: 'splitKeyFrameAt',
  values: 'values',
};

const animatedPropertyModifiers = {
  keyframes: (plugin: Plugin, keyframeArray: Keyframe[]) => {
    const newArray = [];

    for (const keyframe of keyframeArray as object[]) {
      newArray.push(createKeyframeObj(plugin, keyframe as Keyframe));
    }

    return newArray;
  },
  track: (plugin: Plugin, track: Track) => {
    const trackObject = createTrackObject(plugin, track);

    return trackObject;
  },
  values: (values: Value[], plugin: Plugin) => {
    const returnValues = values.map((value) => {
      return handleOutgoingValueTypes(plugin, value);
    });

    return (returnValues as unknown) as AnimatedProperty[];
  },
};

function getAnimatedPropertyMethods(
  plugin: Plugin,
  prop:
    | AnimatedPositionProperty
    | AnimatedPercentageProperty
    | AnimatedAngleProperty
    | AnimatedScaleProperty
    | AnimatedColorProperty
    | AnimatedVectorProperty,
): ObjectMethods {
  return getObjectMethods(plugin, methodNames, prop, animatedPropertyModifiers);
}

export function createdAnimatedPropertyObj(
  plugin: Plugin,
  prop:
    | AnimatedPositionProperty
    | AnimatedPercentageProperty
    | AnimatedAngleProperty
    | AnimatedScaleProperty
    | AnimatedColorProperty
    | AnimatedVectorProperty,
): object {
  const animatedPropObj = {
    nodeId: prop.nodeId,
    nodeType: prop.nodeType,
    type: prop.type,
  };

  registerObjectMethods(plugin, prop, animatedPropObj, [
    getNodeMethods,
    getPropertyMethods,
    getAnimatedPropertyMethods,
  ]);

  return animatedPropObj;
}
