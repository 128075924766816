/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  AVLayer,
  CubicBezierJSON,
  DrawableBezierView,
  GroupShape,
  GroupShapeJSON,
  Scene,
  ShapeJSON,
} from '@lottiefiles/toolkit-js';
import { CubicBezierShape, PathShape, ShapeType, Vector } from '@lottiefiles/toolkit-js';
import type { DagNode } from '@lottiefiles/toolkit-js/dist';
import type { Intersection, Object3D, Mesh } from 'three';
import { BufferGeometry, Line, ExtrudeGeometry, LineBasicMaterial, Plane, Raycaster, Vector3 } from 'three';

import penPointerVertexImg from './pentool-icons/pen-adjust-vertex.svg';
import penPointerCloseImg from './pentool-icons/pen-pointer-close.svg';
import penPointerImg from './pentool-icons/pen-pointer.svg';

import { ToolType } from '~/data/constant';
import type { PathPoint, Viewport, CMesh } from '~/features/canvas';
import {
  findMeshByID,
  createPathLine,
  createPathBone,
  createBezierLine,
  CObject3D,
  drawBezier,
  getMouseCoord,
  parseStroke,
  createBezierPoint,
  createControlPoint,
} from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { ShapeOption } from '~/lib/toolkit';
import { stateHistory, toolkit, createShape } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const { setCurrentTool, zoomPercentage } = useCreatorStore.getState().ui;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

export const extrudeSettings = { depth: 1, bevelEnabled: false };
const raycaster = new Raycaster();

interface NextPoint {
  bones: Mesh | Line | null;
  vertex: Mesh | null;
}

class PathControls {
  public _closedPath = false;

  public _viewport: Viewport;

  public absoluteInOutPoints: PathPoint[] = [];

  public bezierControlPointsGroup = new CObject3D();

  public bezierLinesGroup = new CObject3D();

  public boneGroup = new CObject3D();

  public controlPointsGroup = new CObject3D();

  public draggingElement: Mesh | null | Object3D = null;

  public draggingNextPoint: NextPoint | null = null;

  public group = new CObject3D();

  public hovering = false;

  public isDragging = false;

  public pathOffset: Vector | null = null;

  public pathPoints: PathPoint[] = [];

  public penEnabled = false;

  public pointerDown = false;

  public scale = 100 / zoomPercentage;

  public selectedControlPoint: Mesh | null = null;

  public selectedMesh: CMesh | null = null;

  public selectedNode: DagNode | null = null;

  private readonly _domElement: HTMLCanvasElement = (document as unknown) as HTMLCanvasElement;

  public constructor(domElement: HTMLCanvasElement, viewport: Viewport) {
    this._domElement = domElement;
    this._viewport = viewport;

    this._domElement.addEventListener('pointerdown', this.pointerDownListener.bind(this), false);
    this._domElement.addEventListener('pointermove', this.pointerMoveListener.bind(this), false);
    this._domElement.addEventListener('pointerup', this.pointerUpListener.bind(this), false);
    useCreatorStore.subscribe(
      (state) => state.ui.zoomPercentage,
      (percentage) => this.onCameraZoomChange(percentage),
    );
    this._viewport.scene.add(this.group);
    this.group.add(this.boneGroup);
    this.group.add(this.controlPointsGroup);
    this.group.add(this.bezierControlPointsGroup);
    this.group.add(this.bezierLinesGroup);
  }

  public createNewPathShape(): void {
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const scene = toolkit.scenes[sceneIndex];

    if (!scene) return;
    const pathOption: ShapeOption = {
      type: ShapeType.PATH,
      stroke: [0, 0, 0, 1],
      startFrame: 0,
      endFrame: 120,
      name: 'PATH Shape',
      rotation: 0,
      position: [0, 0],
      path: { pathPoints: this.pathPoints, isClosed: this._closedPath },
    };

    stateHistory.beginAction();
    createShape(scene as Scene, pathOption);

    this.penEnabled = false;

    emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
    stateHistory.endAction();
  }

  public disablePenControls(): void {
    if (this.penEnabled) {
      if (this.selectedMesh === null) {
        this.createNewPathShape();
        setCurrentTool(ToolType.Move);
      } else {
        this.updateToolkitPath();
      }
    }
    this._viewport.updateContainerCursor(`auto`, ToolType.Pen);

    this.penEnabled = false;
    this.pathPoints = [];
    this.absoluteInOutPoints = [];
    this.selectedControlPoint = null;
    this.selectedNode = null;
    this.selectedMesh = null;
    this.removePenMeshes();
    this.drawBezierLines();
  }

  public drawBezierLines(): void {
    this.bezierLinesGroup.children = this.bezierControlPointsGroup.children.map((point) =>
      createBezierLine(this.selectedControlPoint?.position ?? new Vector3(), point.position),
    );
  }

  public drawBezierPoint(type: string, point: Vector3): void {
    if (!this.selectedControlPoint) return;
    const selectedControlPoint = this.pathPoints[this.selectedControlPoint.userData['nodePoint']] as PathPoint;

    if (selectedControlPoint.vertex.x === point.x && selectedControlPoint.vertex.y === point.y) return;
    const bezierPoint = createBezierPoint(this.scale);

    bezierPoint.scale.set(this.scale, this.scale, 1);
    bezierPoint.position.copy(point);
    bezierPoint.userData['nodePoint'] = this.bezierControlPointsGroup.children.length;
    bezierPoint.userData['type'] = type;

    this.bezierControlPointsGroup.add(bezierPoint);
  }

  public drawBezierPoints(): void {
    if (!this.selectedControlPoint || !this.selectedNode) return;
    const inOutPoint = this.absoluteInOutPoints[this.selectedControlPoint.userData['nodePoint']] as PathPoint;

    if (typeof inOutPoint === 'undefined') return;
    this.bezierControlPointsGroup.children = [];

    const InPoint = new Vector3(inOutPoint.in.x, inOutPoint.in.y, -20);

    this.drawBezierPoint('in', InPoint);

    const OutPoint = new Vector3(inOutPoint.out.x, inOutPoint.out.y, -35);

    this.drawBezierPoint('out', OutPoint);

    this.drawBezierLines();
  }

  public drawControlPoint(vertex: Vector): void {
    const controlPoint = createControlPoint();

    const point = new Vector3(vertex.x, vertex.y, -45);

    controlPoint.scale.set(this.scale, this.scale, 1);
    controlPoint.position.copy(point);
    controlPoint.userData['nodePoint'] = this.controlPointsGroup.children.length;
    this.controlPointsGroup.add(controlPoint);
  }

  public drawPathBones(): void {
    this.boneGroup.children = [];
    this.pathPoints.forEach((eachPoint, index) => {
      if (index === 0) return;
      const boneMesh = createPathBone(this.pathPoints[index - 1] as PathPoint, eachPoint, this.scale);

      this.boneGroup.add(boneMesh);

      if (index === this.pathPoints.length - 1 && this.selectedNode) {
        const { closed } = (this.selectedNode.state as ShapeJSON).animatedProperties.sh.value as CubicBezierJSON;

        if (closed) {
          const lastBone = createPathBone(eachPoint, this.pathPoints[0] as PathPoint, this.scale);

          this.boneGroup.add(lastBone);
        }
      }
    });
  }

  public drawPathPoint(event: MouseEvent): void {
    const mouseCords = getMouseCoord(event, this._domElement);
    const planeNormal = new Vector3();
    const plane = new Plane();
    const point = new Vector3();

    planeNormal.copy(this._viewport.camera.position).normalize();
    plane.setFromNormalAndCoplanarPoint(planeNormal, this._viewport.scene.position);
    raycaster.setFromCamera(mouseCords, this._viewport.camera);
    raycaster.ray.intersectPlane(plane, point);

    const controlPoint = createControlPoint();

    controlPoint.scale.set(this.scale, this.scale, 1);
    point.setZ(-45);
    controlPoint.position.copy(point);
    controlPoint.userData['nodePoint'] = this.controlPointsGroup.children.length;

    this._viewport.camera.updateProjectionMatrix();

    const intersects = raycaster.intersectObjects(this.controlPointsGroup.children, true);

    if (intersects.length > 0) {
      if (intersects[0]?.object.userData['nodePoint'] === 0 && this.selectedNode === null) {
        this._closedPath = true;

        this.disablePenControls();
      } else {
        (this.selectedControlPoint as CMesh | null)?.material.color.setHex(0xffffff);
        if ((intersects[0] as Intersection).object.type === 'Line') {
          this.draggingElement = (intersects[0] as Intersection).object.parent;
        } else {
          this.draggingElement = (intersects[0] as Intersection).object;
        }
        (this.draggingElement as CMesh).material.color.setHex(0x0492c2);
        this.selectedControlPoint = this.draggingElement as CMesh;
        this.updateAbsolutePoints();
        this.drawBezierPoints();
      }

      return;
    }

    const bezierIntesects = raycaster.intersectObjects(this.bezierControlPointsGroup.children, true);

    if (bezierIntesects.length > 0) {
      this.draggingElement = (bezierIntesects[0] as Intersection).object;
      (this.draggingElement as CMesh).material.color.setHex(0x0492c2);
    }

    if (this.selectedMesh === null) {
      this.pathPoints.push({
        vertex: new Vector(point.x, point.y),
        in: new Vector(0, 0),
        out: new Vector(0, 0),
      });
      this.drawPathShape();
      this.controlPointsGroup.add(controlPoint);
    }
  }

  public drawPathShape(): void {
    if (this.selectedMesh && this.pathOffset) {
      const nodeState = this.selectedNode.state;
      const { closed } = nodeState.animatedProperties.sh.value as CubicBezierJSON;

      const shapeOriginal = drawBezier(nodeState.animatedProperties.sh.value.points, closed);

      this.selectedMesh.geometry = new ExtrudeGeometry(shapeOriginal, extrudeSettings);

      if (this.selectedMesh.children.length > 0) {
        this.selectedMesh.remove(this.selectedMesh.children[0] as CMesh);
        const bezierGroup = this.selectedNode.parent.toBezier() as DrawableBezierView[];

        for (const style of (bezierGroup[0] as DrawableBezierView).styles) {
          if (style.state.type === ShapeType.STROKE) {
            const strokeLine = parseStroke(style.state.animatedProperties, this.selectedMesh);

            this.selectedMesh.add(strokeLine);
          }
        }
      }

      this.drawPathBones();
    } else {
      this.boneGroup.children = [];
      this.pathPoints.forEach((eachPoint, index) => {
        if (index === 0) return;
        const bone = createPathLine([this.pathPoints[index - 1] as PathPoint, eachPoint], this.scale);

        this.boneGroup.add(bone);
      });
    }
  }

  public enablePenControls(): void {
    emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_DETACHED);
    this.penEnabled = true;
  }

  public onCameraZoomChange(zoom: number): void {
    // keep the control point size always the same on the screen
    this.scale = 100 / zoom;

    this.controlPointsGroup.children.forEach((point) => point.scale.set(this.scale, this.scale, 1));
    this.bezierControlPointsGroup.children.forEach((point) => point.scale.set(this.scale, this.scale, 1));
  }

  public pointerDownListener(event: MouseEvent): void {
    this.pointerDown = true;
    if (!this.penEnabled || event.button === 2) {
      return;
    }
    stateHistory.beginAction();

    this.drawPathPoint(event);
    this.isDragging = true;
    this.drawBezierLines();
  }

  public pointerMoveListener(event: MouseEvent): void {
    if (this.pointerDown) {
      this.boneGroup.visible = false;
    }
    this.hovering = false;
    if (!this.penEnabled) {
      return;
    }
    if (!this.selectedNode) {
      this._viewport.updateContainerCursor(`url(${penPointerImg}) 1 1, pointer`, ToolType.Pen);
    }

    const mouseCords = getMouseCoord(event, this._domElement);
    const planeNormal = new Vector3();
    const plane = new Plane();
    const point = new Vector3();

    planeNormal.copy(this._viewport.camera.position).normalize();
    plane.setFromNormalAndCoplanarPoint(planeNormal, this._viewport.scene.position);
    raycaster.setFromCamera(mouseCords, this._viewport.camera);
    raycaster.ray.intersectPlane(plane, point);
    point.setZ(-45);

    if (this.isDragging) {
      this._viewport.updateContainerCursor(`url(${penPointerVertexImg}) -10 -10, pointer`, ToolType.Pen);

      if (this.draggingElement !== null) {
        this.draggingElement.position.copy(point);
        if (this.draggingElement.userData['type']) {
          if (this.selectedControlPoint) {
            const parentNode = this.selectedNode.parent as AVLayer | GroupShape;
            const relateiveVertex = parentNode.getPositionRelativeTo(
              (this.pathPoints[this.selectedControlPoint.userData['nodePoint']] as PathPoint).vertex,
            );
            const relativeTangents = parentNode
              .getPositionRelativeTo(new Vector(point.x, point.y))
              .subtractToClone(relateiveVertex);

            if (this.draggingElement.userData['type'] === 'in') {
              (this.pathPoints[this.selectedControlPoint.userData['nodePoint']] as PathPoint).in = relativeTangents;
            } else if (this.draggingElement.userData['type'] === 'out') {
              (this.pathPoints[this.selectedControlPoint.userData['nodePoint']] as PathPoint).out = relativeTangents;
            }
          }
        } else {
          this.controlPointsGroup.children[this.draggingElement.userData['nodePoint']] = this.draggingElement as Mesh;
          if (this.pathPoints[this.draggingElement.userData['nodePoint']]) {
            (this.pathPoints[this.draggingElement.userData['nodePoint']] as PathPoint).vertex = new Vector(
              point.x,
              point.y,
            );
          }
        }
        this.updateAbsolutePoints();
        this.drawBezierPoints();
        this.drawBezierLines();
        this.updateToolkitPath();
        this.drawPathShape();
      }
    } else if (!this.selectedNode) {
      const intersects = raycaster.intersectObjects(this.controlPointsGroup.children, true);

      if (intersects.length > 0) {
        if (intersects[0]?.object.userData['nodePoint'] === 0 && this.selectedNode === null) {
          this._viewport.updateContainerCursor(`url(${penPointerCloseImg}) 1 1, pointer`, ToolType.Pen);
        }
      }

      if (this.draggingNextPoint) {
        this.draggingNextPoint.vertex?.position.copy(point);
        if (this.controlPointsGroup.children.length > 0) {
          if (this.draggingNextPoint.bones) {
            this.group.remove(this.draggingNextPoint.bones);
          }
          const lastPoint = this.controlPointsGroup.children[this.controlPointsGroup.children.length - 1];

          const material = new LineBasicMaterial({ color: 0x00b6fe });

          const geometry = new BufferGeometry().setFromPoints([
            new Vector3(lastPoint?.position.x, lastPoint?.position.y),
            point,
          ]);
          const line = new Line(geometry, material);

          this.draggingNextPoint.bones = line;
          this.group.add(this.draggingNextPoint.bones);
        }
      } else {
        const controlPoint = createControlPoint();

        controlPoint.scale.set(this.scale, this.scale, 1);
        point.setZ(-45);
        controlPoint.position.copy(point);
        controlPoint.userData['nodePoint'] = this.controlPointsGroup.children.length;

        this.draggingNextPoint = {
          vertex: controlPoint,
          bones: null,
        };
        this.group.add(controlPoint);
      }
    } else if (this.selectedNode) {
      const intersects = raycaster.intersectObjects(
        [...this.controlPointsGroup.children, ...this.bezierControlPointsGroup.children],
        true,
      );

      if (intersects.length > 0) {
        this._viewport.updateContainerCursor(`url(${penPointerVertexImg}) 5 5, auto`, ToolType.Pen);
      } else {
        this._viewport.updateContainerCursor();
      }
      this.hovering = intersects.length > 0;
    }
  }

  public pointerUpListener(): void {
    this.draggingElement = null;
    this.isDragging = false;
    this.pointerDown = false;
    this.boneGroup.visible = true;
    if (!this.penEnabled) return;
    this.drawBezierLines();
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED, { commit: true });
    stateHistory.endAction();
  }

  public redrawPathControls(): void {
    if (this.penEnabled || this.boneGroup.children.length > 0) {
      // TODO: handle multi select
      const selectedNodeId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;
      const node = getNodeByIdOnly(selectedNodeId);

      if (node) this.updatePathShapeControls(node);

      this.removePenMeshes();
      this.setPathShape(this.selectedNode);
    }
  }

  public removePenMeshes(): void {
    this.pathPoints = [];

    this.controlPointsGroup.children = [];
    this.bezierControlPointsGroup.children = [];
    this.boneGroup.children = [];
    if (this.draggingNextPoint?.bones && this.draggingNextPoint.vertex) {
      this.group.remove(this.draggingNextPoint.vertex);
      this.group.remove(this.draggingNextPoint.bones);
    }
    this.bezierLinesGroup.children = [];
  }

  public setPathBones(node: DagNode): void {
    this.setPathPoints(node);
    this.drawPathBones();
  }

  public setPathPoints(node: DagNode, drawControlPoint = false): void {
    if (!this.penEnabled) return;
    // const canvasMap = useCreatorStore.getState().ui.canvasMap;
    const pathPoints = node.state.animatedProperties.sh.value.points as PathPoint[];

    // this.selectedMesh = canvasMap.get(node.state.id) as CMesh | null;
    this.selectedMesh = findMeshByID(this._viewport.objectContainer, node.state.id);
    this.selectedNode = node;

    this.pathPoints = [];
    this.absoluteInOutPoints = [];
    node.absoluteCoordinates.vertices.forEach((vertex: Vector, index: number) => {
      if (drawControlPoint) {
        this.drawControlPoint(vertex);
      }
      this.pathPoints.push({
        vertex: new Vector(vertex.x, vertex.y),
        in: (pathPoints[index] as PathPoint).in,
        out: (pathPoints[index] as PathPoint).out,
      });

      this.absoluteInOutPoints.push({
        vertex: new Vector(vertex.x, vertex.y),
        in: new Vector(node.absoluteCoordinates.inTangents[index].x, node.absoluteCoordinates.inTangents[index].y),
        out: new Vector(node.absoluteCoordinates.outTangents[index].x, node.absoluteCoordinates.outTangents[index].y),
      });
    });
  }

  public setPathShape(node: DagNode, offset?: Vector): void {
    if (offset) this.pathOffset = offset;

    this.setPathPoints(node, true);
    this.drawPathShape();
    this.group.visible = true;
  }

  public updateAbsolutePoints(): void {
    this.absoluteInOutPoints = [];
    if (this.selectedNode) {
      this.selectedNode.absoluteCoordinates.vertices.forEach((vertex: Vector, index: number) => {
        this.absoluteInOutPoints.push({
          vertex: new Vector(vertex.x, vertex.y),
          in: new Vector(
            this.selectedNode.absoluteCoordinates.inTangents[index].x,
            this.selectedNode.absoluteCoordinates.inTangents[index].y,
          ),
          out: new Vector(
            this.selectedNode.absoluteCoordinates.outTangents[index].x,
            this.selectedNode.absoluteCoordinates.outTangents[index].y,
          ),
        });
      });
    }
  }

  /**
   *  Draw the path lines or control points if a path shape or parent group shape is selected
   */
  public updatePathShapeControls(node: DagNode): void {
    if ((node.state as GroupShapeJSON).type === 'sh') {
      const offset = {
        x: (node as DagNode).parent?.absolutePosition.x ?? 0,
        y: (node as DagNode).parent?.absolutePosition.y ?? 0,
      } as Vector;

      this.disablePenControls();
      this.enablePenControls();
      this.setPathShape(node, offset);
    } else if ((node.state as GroupShapeJSON).type === 'gr') {
      this.disablePenControls();
      (node.state as GroupShapeJSON).shapes.forEach((shapeObj: ShapeJSON) => {
        if (shapeObj.type === 'sh') {
          this.setPathBones(getNodeByIdOnly(shapeObj.id) as DagNode);
        }
      });
    } else {
      this.removePenMeshes();
    }
  }

  public updateToolkitPath(): void {
    if (!this.selectedNode || !(this.selectedNode instanceof PathShape) || this.pathPoints.length === 0) return;
    const parentNode = this.selectedNode.parent as AVLayer | GroupShape;

    const vertexPoints = this.pathPoints.map((pathPoint) => parentNode.getPositionRelativeTo(pathPoint.vertex));
    const inPoints = this.pathPoints.map((pathPoint) => new Vector(pathPoint.in.x, pathPoint.in.y));
    const outPoints = this.pathPoints.map((pathPoint) => new Vector(pathPoint.out.x, pathPoint.out.y));
    const { closed } = (this.selectedNode.state as ShapeJSON).animatedProperties.sh.value as CubicBezierJSON;

    const cubicBezierPath = new CubicBezierShape()
      .setVertices(vertexPoints)
      .setInTangents(inPoints)
      .setOutTangents(outPoints)
      .setIsClosed(closed);

    (this.selectedNode as PathShape).shape.setValue(cubicBezierPath);
  }
}

export { PathControls };
