/**
 * Copyright 2022 Design Barn Inc.
 */

import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { Menu, Transition } from '@headlessui/react';
import React, { useCallback, Fragment } from 'react';
import { shallow } from 'zustand/shallow';

import { ChevronDown, ConvertVertex, Pen, PenActive } from '~/assets/icons';
import { GB_CONSTANT } from '~/config/growthbook';
import { ToolType } from '~/data/constant';
import { useCreatorStore } from '~/store';

const DropDownList = [
  {
    type: ToolType.Pen,
    activeIcon: <PenActive />,
    inactiveIcon: <Pen />,
    label: 'Pen Tool',
    shortcut: 'P',
  },
  {
    type: ToolType.ConvertVertex,
    activeIcon: <ConvertVertex />,
    inactiveIcon: <ConvertVertex />,
    label: 'Convert Vertex',
  },
];

export const PenMenu: React.FC = () => {
  const [currentTool, setCurrentTool] = useCreatorStore(
    (state) => [state.ui.currentTool, state.ui.setCurrentTool],
    shallow,
  );
  const handleOnClick: (type: ToolType) => void = useCallback(
    (type) => {
      setCurrentTool(type);
    },
    [setCurrentTool],
  );

  return (
    <>
      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_PEN_TOOL}>
        <Menu as="div" className="relative ml-2 inline-block">
          {({ open }) => (
            <>
              <Menu.Button className="group flex h-[24px] w-[48px] cursor-pointer items-center justify-center rounded  hover:bg-gray-700 ">
                {React.cloneElement(currentTool === ToolType.Pen ? <PenActive /> : <Pen />, {
                  className:
                    'h-[16px] w-[16px] fill-current text-gray-400 group-hover:text-white group-hover:bg-gray-700',
                })}

                {React.cloneElement(open ? <ChevronDown /> : <ChevronDown />, {
                  className: 'h-[16px] w-[16px] cursor-pointer fill-current text-gray-500 group-hover:text-white',
                })}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-dropdown mt-1 w-[192px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 py-2 shadow-dropdown focus:outline-none">
                  {DropDownList.map((dropdown, index) => (
                    <div key={dropdown.type}>
                      <Menu.Item>
                        <button
                          className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                            index === DropDownList.length - 1 && 'rounded-b'
                          } px-2 text-xs text-white `}
                          onClick={() => handleOnClick(dropdown.type)}
                        >
                          <div className="flex w-full justify-between rounded-lg p-2 hover:bg-gray-600">
                            <div className="flex">
                              {React.cloneElement(
                                currentTool === dropdown.type ? dropdown.activeIcon : dropdown.inactiveIcon,
                                {
                                  className: 'h-[16px] w-[16px] fill-current text-gray-400 mr-2',
                                },
                              )}
                              {dropdown.label}
                            </div>
                            {dropdown.shortcut && <div className="px-2">{dropdown.shortcut}</div>}
                          </div>
                        </button>
                      </Menu.Item>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </IfFeatureEnabled>
    </>
  );
};
