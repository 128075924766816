/**
 * Copyright 2022 Design Barn Inc.
 */

import { useJitsu } from '@jitsu/react';
import React, { useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';

import UploadFile from '../assets/UploadFile';

import { uploadDotLottie, uploadJSON, uploadSVG } from './helper';
import { Insert } from './Insert';
import { UploadContext } from './UploadContext';
import { InvalidFileFormatError, UnknownError } from './UploadError';

import { Info } from '~/assets/icons';
import { AssetSource, EventType, FileType } from '~/data/eventStore';
// eslint-disable-next-line no-restricted-imports
import { executeUpload } from '~/features/upload/components/middleware';
import { GenericPayload } from '~/utils/eventStoreUtils';

interface Props {}

export const UploadComputer: React.FC<Props> = () => {
  const { track } = useJitsu();

  const { onClose, setError, setLoading } = useContext(UploadContext);

  const handleOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      setError(null);
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const fileType = file.name.split('.').pop();
        const reader = new FileReader();
        let assetType = FileType.JSON;

        setLoading(true);
        reader.onload = async (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            let lottieRecord = null as null | Uint8Array | string;

            try {
              // Get Lottie record for dotlottie, svg, json

              let json = null;

              const fileName = file?.name.split('.')[0];

              if (fileType === 'lottie') {
                lottieRecord = new Uint8Array(event.target.result as ArrayBufferLike);
                assetType = FileType.DotLottie;
              } else if (fileType === 'svg') {
                lottieRecord = event.target.result as string;
                assetType = FileType.SVG;
              } else {
                json = JSON.parse(event.target.result as string);
              }

              if (fileType === 'lottie') {
                await executeUpload({
                  revertLabel: 'Upload another',
                  openLabel: 'Open anyway',
                  type: 'dotLottie',
                  data: lottieRecord,
                  execute: async () => uploadDotLottie(lottieRecord, fileName),
                  revert: () => {
                    const container = document.getElementById('InsertContainer');
                    const root = ReactDOM.createRoot(container);

                    root.render(<Insert defaultIsOpen={true} />);
                  },
                });
              } else if (fileType === 'svg') {
                await uploadSVG(lottieRecord as string, fileName);
              } else {
                await executeUpload({
                  revertLabel: 'Upload another',
                  openLabel: 'Open anyway',
                  data: json as unknown,
                  execute: async () => uploadJSON(json as unknown, fileName),
                  revert: () => {
                    const container = document.getElementById('InsertContainer');
                    const root = ReactDOM.createRoot(container);

                    root.render(<Insert defaultIsOpen={true} />);
                  },
                });
              }

              // Send analytics event
              track(EventType.UploadedAsset, {
                client: GenericPayload,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_type: EventType.UploadedAsset,
                parameters: {
                  source: AssetSource.Local,
                  'file-type': assetType,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  send_to_amplitude: true,
                },
              });

              onClose();
            } catch (err) {
              if (err instanceof Error) {
                // TODO: set better, more specific error messages from upload error result
                // eslint-disable-next-line no-console
                console.error(err);
                setLoading(false);
                setError(new InvalidFileFormatError(err.message));
              }
            }
          }
        };

        reader.onabort = (err) => {
          if (err instanceof Error) {
            setLoading(false);
            setError(new UnknownError(err.message));
          }
        };

        reader.onerror = (err) => {
          if (err instanceof Error) {
            setLoading(false);
            setError(new UnknownError(err.message));
          }
        };

        if (file.type === 'application/json') {
          reader.readAsText(file);
        } else if (fileType === 'svg') {
          reader.readAsText(file);
        } else if (fileType === 'lottie') {
          reader.readAsArrayBuffer(file);
        } else {
          setLoading(false);
          setError(new InvalidFileFormatError(file.type));
        }
      }
    },
    [onClose, setError, setLoading, track],
  );

  const { getInputProps, getRootProps } = useDropzone({ onDrop: handleOnDrop });

  return (
    <div className="p-3">
      <div
        {...getRootProps({
          className:
            'dropzone w-full h-full flex flex-col  items-center border-dashed border-2 border-gray-600 cursor-pointer mb-4 ',
        })}
        data-testid="upload-panel"
      >
        <input data-testid="upload-file-modal" {...getInputProps()} />
        <div className="flex h-full w-full flex-col items-center gap-1 p-12">
          <UploadFile className="h-16 w-20" />
          <span className="text-sm font-bold leading-[18px] text-white">{'Drag & drop asset to upload'}</span>
          <span className="text-caption">or</span>
          <button className="rounded-lg bg-teal-300 px-3 py-2 text-sm font-bold leading-[21px] text-white hover:bg-teal-400">
            Browse
          </button>
        </div>
        <span className="mb-3 text-caption">You can upload JSON, dotlottie or SVG asset (up to 20MB)</span>
      </div>
      <div className="banner-info">
        <Info className="h-8 w-8" />
        <p>Not all Lottie animation features are supported yet. Click ‘Learn more’ to find out which ones are.</p>
        <button
          className="banner-btn"
          onClick={() => window.open('https://help.lottiefiles.com/hc/en-us/articles/15386214015769.', '_blank')}
        >
          Learn more
        </button>
      </div>
    </div>
  );
};
