/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import Draggable from 'react-draggable';

import { PluginUIHeader } from './PluginUIHeader';

import type { PluginUIType } from '~/plugins';

export interface PluginUIProps {
  host: string;
  id: string;
  isDevPlugin: boolean;
  onClose: () => void;
  onReload?: () => void;
  options: {
    height: number;
    width: number;
  };
  src: string;
  title: string;
  uiType: PluginUIType;
  withIframe: (iframe: HTMLIFrameElement | null) => void;
}

export const PluginUI: React.FC<PluginUIProps> = (props) => {
  const nodeRef = React.useRef(null);

  const maxWidth = props.options.width === 0 ? 340 : props.options.width;
  const maxHeight = props.options.height === 0 ? 680 : props.options.height;

  return (
    <Draggable nodeRef={nodeRef} bounds="#main-content">
      <div
        ref={nodeRef}
        className={`absolute right-[256px] top-[16px] z-test-animation  rounded-md border border-gray-600 bg-gray-800 text-white`}
      >
        <PluginUIHeader {...props} />
        <div className="h-[1px] w-full bg-gray-700"></div>
        <div>
          <iframe
            src={props.host}
            sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
            ref={(iframe) => props.withIframe(iframe)}
            id={`plugin-${props.id}`}
            style={{
              minWidth: `${maxWidth}px`,
              minHeight: `${maxHeight}px`,
              maxWidth: `${maxWidth}px`,
              maxHeight: `${maxHeight}px`,
            }}
          ></iframe>
        </div>
      </div>
    </Draggable>
  );
};
