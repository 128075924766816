/**
 * Copyright 2021 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { Bell, BellActive } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';

const emptyNotification = (): JSX.Element => (
  <div className="p-4 text-[10px] text-gray-300">You don&apos;t have any notifications.</div>
);

interface Props {
  notifications: string[];
}

export const Notification: React.FC<Props> = ({ notifications }) => {
  return (
    <Menu as="div" className="relative inline-block">
      {({ open }) => (
        <>
          <Tooltip offset={{ mainAxis: 7, crossAxis: -9 }} label="Notifications" placement="bottom">
            <Menu.Button className="group mx-2 flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded hover:bg-gray-700">
              {React.cloneElement(open ? <BellActive /> : <Bell />, {
                className:
                  'h-[16px] w-[16px] cursor-pointer fill-current text-gray-400 hover:bg-gray-700 group-hover:text-white',
              })}
            </Menu.Button>
          </Tooltip>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-dropdown mt-1 flex h-[80px] w-[200px] origin-top-right items-center justify-center divide-y rounded-lg border border-gray-600 bg-gray-700 shadow-dropdown ring-black focus:outline-none">
              {notifications.length === 0
                ? emptyNotification()
                : notifications.map((notification) => {
                    return (
                      <Menu.Item key={notification}>
                        <button
                          className={
                            'group flex w-full items-center rounded-md border-gray-700 p-2.5 text-sm text-white hover:border-gray-600 hover:bg-gray-600'
                          }
                        >
                          {notification}
                        </button>
                      </Menu.Item>
                    );
                  })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
