/**
 * Copyright 2023 Design Barn Inc.
 */

import type { ShapeJSON, Scene } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import RangeSlider from 'react-range-slider-input';
import { shallow } from 'zustand/shallow';

import type { OptionalShapeLayer } from '../../TimelineLayerPanel/Layers';
import { useTimelineUtils } from '../hooks';

import type { LayerUI } from '~/features/timeline';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, toolkit, setKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

import './timeline-track.css';

interface Props {
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
  selected: boolean;
}

export const TimelineTrack: React.FC<Props> = ({ layer, layerUI, selected }) => {
  // Temporary useState
  const [duration, setLayerUI] = useCreatorStore(
    (state) => [state.toolkit.json?.timeline.duration as number, state.ui.setLayerUI],
    shallow,
  );

  const { timelineTrackDuration, timelineTrackEnd, timelineTrackStart } = layerUI;
  const { getFrameFromTimeOffset } = useTimelineUtils();

  const start = timelineTrackStart ?? 0;
  const end = timelineTrackEnd ?? 0;

  const handleOnInput = useCallback(
    (input: [number, number]) => {
      // TODO: handle multiselect

      const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

      addToSelectedNodes([layer.id], true);

      const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, layer.id);

      const [inputStart, inputEnd] = input;
      const inputStartTime = (inputStart / 100) * duration;
      const inputEndTime = (inputEnd / 100) * duration;

      const newDiff = {
        start: 0,
        end: 0,
      };

      if (node.startTime !== inputStartTime) {
        newDiff.start = Math.abs(node.startTime - inputStartTime);

        setLayerUI(layer.id, 'timelineTrackStart', inputStart);

        node.setStartTime(inputStartTime);
        emitter.emit(EmitterEvent.TIMELINE_BAR_START_TIME_UPDATED);
      }

      if (node.endTime !== inputEndTime) {
        newDiff.end = Math.abs(node.endTime - inputEndTime);

        setLayerUI(layer.id, 'timelineTrackEnd', inputEnd);

        node.setEndTime(inputEndTime);
        emitter.emit(EmitterEvent.TIMELINE_BAR_END_TIME_UPDATED);
      }

      const isDragging = inputStart !== timelineTrackStart && inputEnd !== timelineTrackEnd;

      if (isDragging) {
        const isDraggingLeft = Boolean(inputStart < Number(timelineTrackStart));
        const isDraggingRight = !isDraggingLeft;

        const offsetFrame = getFrameFromTimeOffset(newDiff.start);

        if (layerUI.frameIds.length > 0) {
          layerUI.frameIds.forEach((layerFrameId) => {
            const frameObj = toolkit.getKeyframeById(layerFrameId);

            if (frameObj) {
              const currentFrame = frameObj.frame;

              let newFrame = null;

              if (isDraggingLeft) {
                // minus
                // convert time to px
                newFrame = currentFrame - offsetFrame;
              } else if (isDraggingRight) {
                // plus
                newFrame = currentFrame + offsetFrame;
              }

              setKeyFrame(layerFrameId, newFrame as number);

              // dragged end
              emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
            }
          });
        }
      }
    },
    [setLayerUI, layer.id, duration, timelineTrackStart, timelineTrackEnd, layerUI, getFrameFromTimeOffset],
  );

  useEffect(() => {
    // update end time
    const layerId = layer.id;

    if (duration !== (timelineTrackDuration || 0)) {
      // duration changed.
      setLayerUI(layerId, 'timelineTrackDuration', duration);

      const element = document.getElementsByClassName(layerId);

      if (element.length > 0) {
        const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

        const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, layer.id);
        const curStart = ((node.startTime / duration) * 100) as number;

        const curEnd = ((node.endTime / duration) * 100) as number;

        setLayerUI(layerId, 'timelineTrackEnd', curEnd);
        setLayerUI(layerId, 'timelineTrackStart', curStart);
      }
    }
  }, [timelineTrackDuration, setLayerUI, duration, layerUI, layer.id, timelineTrackStart, timelineTrackEnd]);

  const handleOnClick = useCallback(() => {
    // TODO: handle multiselect

    const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

    addToSelectedNodes([layer.id], true);
  }, [layer.id]);

  const handleStateHistoryStart = useCallback(() => {
    stateHistory.beginAction();
  }, []);

  const handleStateHistoryEnd = useCallback(() => {
    stateHistory.endAction();
  }, []);

  return (
    <div onClick={handleOnClick}>
      <RangeSlider
        id="timeline-track"
        className={clsx(layer.id, { selected })}
        onInput={handleOnInput}
        value={[start, end]}
        onThumbDragStart={handleStateHistoryStart}
        onRangeDragStart={handleStateHistoryStart}
        onThumbDragEnd={handleStateHistoryEnd}
        onRangeDragEnd={handleStateHistoryEnd}
      />
    </div>
  );
};

export const TimelineEmptyTrack: React.FC = () => {
  return <div className="h-6 bg-gray-800"></div>;
};
