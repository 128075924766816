/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant/modal';
import { SavingState } from '~/store/projectSlice';

interface VersionCheckingProps {
  children: React.ReactNode;
}

export const VersionChecking: React.FC<VersionCheckingProps> = ({ children }: VersionCheckingProps) => {
  const [pass, setPass] = useState(false);
  const [currentVersionId, fileId, token] = useCreatorStore(
    (state) => [state.project.info.fileVersionId, state.project.info.fileId, state.user.token],
    shallow,
  );

  const { getLatestVersionId } = useProjectAPI(token || null);

  const checkVersion = useCallback(async () => {
    const latestVersionId = await getLatestVersionId({ id: fileId as string });

    if (latestVersionId && latestVersionId !== currentVersionId) {
      const setInfo = useCreatorStore.getState().project.setInfo;
      const setAlert = useCreatorStore.getState().ui.setAlert;
      const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

      setInfo({
        savingState: SavingState.SAVED,
      });
      setAlert({
        text: 'Another user has made changes to this file since it was last auto-saved',
        alertColor: '#20272C',
        timeout: 600000,
        handle: async (): Promise<void> => {
          setGlobalModal(GlobalModalConstant.ProjectSave, { createNew: true });
        },
        handleText: 'Save a copy',
      });
      setPass(false);
    } else if (fileId && !latestVersionId) {
      setPass(false);
    } else {
      setPass(true);
    }
  }, [currentVersionId, fileId, getLatestVersionId]);

  useEffect(() => {
    if (fileId) {
      checkVersion();
    } else {
      setPass(true);
    }
  }, [checkVersion, fileId]);

  // prevent rerender after saved
  const savingState = useCreatorStore.getState().project.info.savingState;

  if (savingState === SavingState.SAVING) {
    return pass ? <>{children}</> : null;
  } else {
    return null;
  }
};
