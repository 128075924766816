/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useRef, useEffect, useCallback } from 'react';

import { TooltipWrapper } from '../Tooltip/TooltipWrapper';

import { AspectRatioLock, AspectRatioUnlock } from '~/assets/icons';

interface AspectRatioToggleProps {
  isLocked?: boolean;
  onClick?: () => void;
}

export const AspectRatioToggle: React.FC<AspectRatioToggleProps> = ({ isLocked = false, onClick }) => {
  const onClickHandler = useRef(onClick);

  // store onclick handler
  useEffect(() => {
    onClickHandler.current = onClick;
  }, [onClick]);

  const handleOnClick = useCallback(() => {
    if (onClickHandler.current) {
      onClickHandler.current();
    }
  }, []);

  const icon = isLocked ? (
    <AspectRatioLock className="h-4 w-4" />
  ) : (
    <AspectRatioUnlock className="h-4 w-4 stroke-white" />
  );

  return (
    <button onClick={handleOnClick} className="flex h-6 w-6 items-center justify-center">
      <TooltipWrapper offset={{ mainAxis: 7, crossAxis: -55 }} label={'Constraint proportions'}>
        {icon}
      </TooltipWrapper>
    </button>
  );
};
