/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import React from 'react';

import { Tooltip } from './Tooltip';

export interface TooltipWrapperProps {
  children: React.ReactElement;
  label: string | undefined;
  offset?: { crossAxis: number; mainAxis: number };
  placement?: Placement;
  shortcut?: string;
}

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  label,
  offset = { mainAxis: 7, crossAxis: 0 },
  placement = 'bottom',
  shortcut,
}) => {
  return (
    <>
      {label ? (
        <Tooltip
          bgColorClass="bg-gray-900"
          offset={offset}
          label={label}
          placement={placement}
          shortcut={shortcut ?? ''}
        >
          <div className="flex items-center">{children}</div>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
