/**
 * Copyright 2022 Design Barn Inc.
 */

import { BlendModeType } from '@lottiefiles/toolkit-js';
import { get } from 'lodash-es';
import React, { useState, useCallback, useEffect } from 'react';

import { Select } from '~/components/Elements/Select';
import type { Option } from '~/components/Elements/Select';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

const bmFirstSegment = [
  {
    label: 'Normal',
    value: BlendModeType.NORMAL,
  },
  {
    label: 'Dissolve',
    value: BlendModeType.DISSOLVE,
  },
];

const bmSecondSegment = [
  {
    label: 'Darken',
    value: BlendModeType.DARKEN,
  },
  {
    label: 'Multiply',
    value: BlendModeType.MULTIPLY,
  },
  {
    label: 'Linear Burn',
    value: BlendModeType.LINEAR_BURN,
  },
  {
    label: 'Color Burn',
    value: BlendModeType.COLOR_BURN,
  },
];

const bmThirdSegment = [
  {
    label: 'Add',
    value: BlendModeType.ADD,
  },
  {
    label: 'Lighten',
    value: BlendModeType.LIGHTEN,
  },
  {
    label: 'Screen',
    value: BlendModeType.SCREEN,
  },
  {
    label: 'Linear Dodge',
    value: BlendModeType.LINEAR_DODGE,
  },
  {
    label: 'ColorDodge',
    value: BlendModeType.COLOR_DODGE,
  },
];

const bmForthSegment = [
  {
    label: 'Overlay',
    value: BlendModeType.OVERLAY,
  },
  {
    label: 'Soft Light',
    value: BlendModeType.SOFT_LIGHT,
  },
  {
    label: 'Hard Light',
    value: BlendModeType.HARD_LIGHT,
  },
  {
    label: 'Linear Light',
    value: BlendModeType.LINEAR_LIGHT,
  },
  {
    label: 'Vivid Light',
    value: BlendModeType.VIVID_LIGHT,
  },
  {
    label: 'Pin Light',
    value: BlendModeType.PIN_LIGHT,
  },
  {
    label: 'Hard Mix',
    value: BlendModeType.HARD_MIX,
  },
];

const bmFifthSegment = [
  {
    label: 'Difference',
    value: BlendModeType.DIFFERENCE,
  },
  {
    label: 'Exclusion',
    value: BlendModeType.EXCLUSION,
  },
];

const bmLastSegment = [
  {
    label: 'Hue',
    value: BlendModeType.HUE,
  },
  {
    label: 'Saturation',
    value: BlendModeType.SATURATION,
  },
  {
    label: 'Color',
    value: BlendModeType.COLOR,
  },
  {
    label: 'Luminosity',
    value: BlendModeType.LUMINOSITY,
  },
];

const dividerSegment = [
  {
    label: '',
    value: '',
    isDivider: true,
  },
];

const blendingModeOptions: Option[] = [
  {
    label: 'Blending Mode',
    value: 'blendingMode',
  },
  ...bmFirstSegment,
  ...dividerSegment,
  ...bmSecondSegment,
  ...dividerSegment,
  ...bmThirdSegment,
  ...dividerSegment,
  ...bmForthSegment,
  ...dividerSegment,
  ...bmFifthSegment,
  ...dividerSegment,
  ...bmLastSegment,
];

export const BlendingModeSelection: React.FC = () => {
  const bMode = useCreatorStore((state) => {
    const currentProperty = state.ui.currentPropertyPanel;

    if (currentProperty === PropertyPanelType.Stroke) return get(state.toolkit.currentStrokeShape, 'bm');
    else if (currentProperty === PropertyPanelType.Fill) return get(state.toolkit.currentFillShape, 'bm');

    return 0;
  });

  const [bmOption, setBMOption] = useState<Option>(blendingModeOptions[0]);

  const handleOnChangeTemplate = useCallback((option: Option) => {
    setBMOption(option);

    useCreatorStore.getState().toolkit.setBlendMode(option.value as number);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_BLENDING_MODE_UPDATED);
  }, []);

  useEffect(() => {
    let bModeIndex = blendingModeOptions.findIndex((bmo) => bmo.value === bMode);

    if (bModeIndex === -1) {
      bModeIndex = 0;
    }
    setBMOption(blendingModeOptions[bModeIndex]);
  }, [bMode]);

  return (
    <div className="inline-flex h-6 w-full">
      <div className="grow">
        <Select
          styleName="!fixed w-[176px]"
          options={blendingModeOptions.slice(1)}
          selected={bmOption}
          onChange={handleOnChangeTemplate}
        />
      </div>
    </div>
  );
};
