/**
 * Copyright 2023 Design Barn Inc.
 */

import React, { useCallback } from 'react';

interface SeekerProps {
  max: number;
  onChange: (e: unknown) => void;
  value: number;
}

export const PlayerSeeker: React.FC<SeekerProps> = ({ max, onChange, value }: SeekerProps) => {
  const progress = (value / max) * 100;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      const frame = Math.trunc((newValue / 100) * max);

      onChange(frame);
    },
    [max, onChange],
  );

  const handleSeekerDrag = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      if (event.buttons === 1) {
        const mouseOffset = event.nativeEvent.offsetX;
        const seekerLength = event.currentTarget.getBoundingClientRect().width;
        const newValue = (mouseOffset / seekerLength) * max;
        const clampedValue = Math.min(max, Math.max(0, newValue));
        const frame = Math.trunc(clampedValue);

        onChange(frame);
      }
    },
    [max, onChange],
  );

  const seekerStyle = {
    backgroundImage: `-webkit-gradient(linear, left top, right top, color-stop(${progress}%, #00DDB3), color-stop(${progress}%, #D9E0E6))`,
    width: '100%',
  };

  return (
    <div className="flex w-full items-center ">
      <input
        type="range"
        className="rounded-[5px] hover:cursor-pointer"
        onChange={handleChange}
        onInput={handleChange}
        onMouseMove={handleSeekerDrag}
        value={progress}
        style={seekerStyle}
      />
    </div>
  );
};
