/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import type { PluginUIProps } from './PluginUI';

import { PluginUIType } from '~/plugins';

export const SidebarPluginUI: React.FC<PluginUIProps> = (props) => {
  return (
    <div className={props.uiType === PluginUIType.SidebarUI ? 'h-[calc(100%-40px)]' : 'h-full'}>
      <iframe
        sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
        src={props.host}
        ref={(iframe) => props.withIframe(iframe)}
        id={`plugin-${props.id}`}
        className="h-full w-full"
      ></iframe>
    </div>
  );
};
