/**
 * Copyright 2022 Design Barn Inc.layers.tsx
 */

import { ShapeType, LayerType } from '@lottiefiles/toolkit-js';
import type {
  ShapeLayerJSON,
  LayerJSON,
  ShapeJSON,
  Scene,
  PrecompositionLayerJSON,
  AVLayer,
} from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import { flatten, uniqBy } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import type { SimplifiedLayer } from '..';
import { MENU_HEIGHT } from '../../../../menu/constant';
import type { LayerUI } from '../helper';

import { Animated, AnimatedButton } from './Animated';
import { DraggableWrapper } from './DraggableWrapper';
import { CustomDragOverlay } from './DraggableWrapper/customDragOverlay';
import { useTreeLines } from './hooks';
import { computePaddingLeft } from './layer-helper';
import { LayerCollapseButton } from './LayerCollapseButton';
import { LayerThumbnail } from './LayerThumbnail';
import { TreeLine } from './TreeLine';

import { ShapeLayerMenuTimeline, NormalLayerMenu, NestedSceneLayerMenu } from '~/features/menu';
import { useClickOutside } from '~/hooks/useClickOutside';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import type { Optional } from '~/types';

export type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

interface LayerRowContainerProp {
  allParentExpanded: boolean;
  children?: React.ReactNode;
  expanded: boolean;
  hasAnimated: boolean;
  hasChildren: boolean;
  hasParent: boolean;
  highlight: boolean;
  id: string;
  isSelected: boolean;
  level: number;
  onDoubleClick: () => void;
  refId: string;
}

export const LayerRowContainer: React.FC<LayerRowContainerProp> = ({
  children,
  id,
  onDoubleClick,
  refId,
  ...props
}) => {
  const [setHoverID, canvasHoveredID, { hoverIsTop, hoverOverId }] = useCreatorStore(
    (state) => [state.timeline.setHoverID, state.ui.canvasHoveredNodeId, state.timeline.layerConfiguration],
    shallow,
  );

  const { allParentExpanded, expanded, hasAnimated, hasChildren, hasParent, highlight, isSelected, level } = props;

  // Compute padding left to align layers according to their level
  const paddingLeft = useMemo(() => `${computePaddingLeft(hasParent, hasChildren, level)}px`, [
    hasParent,
    hasChildren,
    level,
  ]);

  return (
    <>
      {hoverIsTop && hoverOverId === id && <CustomDragOverlay />}
      <div
        id={id}
        data-layerid={id}
        data-refid={refId}
        className={clsx(
          'relative mt-[1px] box-border flex h-6 w-full flex-row items-center justify-between text-ellipsis whitespace-nowrap border-[0.5px] border-transparent',
          {
            // Only expanded when all the parent layers are expanded
            hidden: hasParent && !allParentExpanded,
            'bg-gray-900': highlight && isSelected,
            'bg-[#1F2429]': highlight && !isSelected,
            rounded: (!expanded && hasChildren) || !hasAnimated,
            'rounded-t': (hasAnimated && !hasChildren) || (isSelected && expanded),
            'border-white': id === canvasHoveredID,
            'hover:border-white': !(hoverOverId === id),
          },
        )}
        style={{ paddingLeft }}
        onMouseOver={() => setHoverID(id)}
        onMouseOut={() => setHoverID(null)}
        onDoubleClick={onDoubleClick}
        data-testid="layer-row-container"
      >
        {children}
      </div>
      {!hoverIsTop && hoverOverId === id && <CustomDragOverlay />}
    </>
  );
};

interface LayerRowProps {
  layer: OptionalShapeLayer | ShapeJSON | SimplifiedLayer;
  layerId?: string;
  layerUI: LayerUI;
  onCollapse?: unknown;
}

export const LayerRow: React.FC<LayerRowProps> = ({ layer, layerUI }) => {
  let simplifiedLayer = null;
  const getSimplifiedMap = useCreatorStore.getState().ui.getLayerSimplifiedUI;

  if (layer.simplified?.isGroup) {
    // TODO: Multiple shapes in one group
  } else {
    // Single layer
    const singleLayer = layer.simplified.layers[0]?.shapes[0];

    if (singleLayer) {
      simplifiedLayer = {
        id: singleLayer.id,
        name: singleLayer.properties?.nm,
        animatedProperties: singleLayer.animatedProperties,
        properties: singleLayer.properties,
        type: singleLayer.type,
        layer: layer.simplified.layers[0],
      };
    }
  }

  const name = simplifiedLayer?.name || (layer.properties.nm as string);

  const { animated, children, expanded, highlight, last, level, parent } = getSimplifiedMap(layer.id);

  const [
    showLayerID,
    isSelected,
    getMap,
    setLayerUI,
    sceneIndex,
    isRenamingCurrentLayer,
    setRenamingLayer,
  ] = useCreatorStore(
    (state) => [
      state.timeline.showLayerID,
      state.ui.selectedNodesInfo.some((node) => node.nodeId === layer.id),
      state.ui.getLayerUI,
      state.ui.setLayerUI,
      state.toolkit.sceneIndex,
      state.timeline.renamingLayer === layer.id,
      state.timeline.setRenamingLayer,
    ],
    shallow,
  );

  const hasChildren = Boolean(children.length);
  const hasParent = Boolean(parent.length);

  const allParentExpanded = hasParent && parent.every((id: string) => getMap(id)?.expanded);
  const hasAnimated = Boolean(animated.length > 0);

  const { animatedTreeLines, treeLines } = useTreeLines(parent, hasChildren, last, level, hasAnimated);

  const getAllChildrenIds = useCallback(
    (id: string): string[] => {
      let results: string[] = [];
      const childLayer = getMap(id);

      const childrenIds = [...(childLayer?.children ?? [])];

      if (childrenIds.length > 0) {
        results = [...childrenIds, ...childrenIds.map((childId: string) => getAllChildrenIds(childId))] as string[];
      } else {
        results = [];
      }

      return flatten(results);
    },
    [getMap],
  );

  const handleOnClickLayerCollapse = useCallback(
    (expand: boolean) => {
      setLayerUI(layer.id, 'expanded', expand);
      // If expand false, get all childs below
      if (hasChildren) {
        const allChildIds = getAllChildrenIds(layer.id);

        if (allChildIds.length > 0) {
          allChildIds.forEach((childId: string) => {
            const { rerender } = useCreatorStore.getState().ui.layerMap.get(childId as string) as LayerUI;

            setLayerUI(childId, 'rerender', !rerender);
            if (expand === false) setLayerUI(childId, 'expanded', expand);
          });
        }
      }
    },
    [layer.id, setLayerUI, getAllChildrenIds, hasChildren],
  );

  const handleRowDoubleClick = useCallback(() => {
    const avLayer = getNodeById(toolkit.scenes[sceneIndex] as Scene, layer.id) as AVLayer;

    if (avLayer.state.type === 'PRECOMPOSITION') {
      emitter.emit(EmitterEvent.TIMELINE_PRECOMP_EDIT_SCENE, {
        id: (layer as PrecompositionLayerJSON).id,
      });
    }
  }, [layer, sceneIndex]);

  const handleStopRename = useCallback(
    (newName: string) => {
      // Don't allow layers to be renamed to have empty names
      if (newName.trim().length > 0) {
        const avLayer = getNodeById(toolkit.scenes[sceneIndex] as Scene, simplifiedLayer?.id) as AVLayer;

        stateHistory.beginAction();
        avLayer.setName(newName);
        emitter.emit(EmitterEvent.TIMELINE_RENAME_LAYER_END, { commit: true });
        stateHistory.endAction();
      }
      setRenamingLayer(null);
    },
    [setRenamingLayer, sceneIndex, simplifiedLayer],
  );

  useEffect(() => {
    const expandAll = (): void => {
      setLayerUI(layer.id, 'expanded', true);
      if (hasChildren) {
        const allChildIds = getAllChildrenIds(layer.id);

        if (allChildIds.length > 0) {
          allChildIds.forEach((childId: string) => {
            setLayerUI(childId, 'expanded', true);
          });
        }
      }
    };

    if (isSelected) {
      emitter.on(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES, expandAll);
    }

    return () => {
      emitter.off(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES, expandAll);
    };
  }, [getAllChildrenIds, hasChildren, isSelected, layer.id, setLayerUI]);

  const props = {
    hasParent,
    allParentExpanded,
    hasAnimated,
    highlight,
    isSelected,
    expanded,
    hasChildren,
    level,
  };

  const renameRef = React.useRef<HTMLInputElement | null>() as React.MutableRefObject<HTMLInputElement | null>;

  // NOTE: data-layerid is used by the context menu handlers so be sure to add
  // them to elements that should show context menus
  const titleElement = isRenamingCurrentLayer ? (
    <input
      className="w-full border-none bg-transparent selection:bg-teal-800 selection:text-white focus-visible:outline-none"
      defaultValue={name}
      ref={(ref) => {
        renameRef.current = ref;
        ref?.focus();
        ref?.select();
      }}
      onBlur={(ev) => handleStopRename(ev.currentTarget.value)}
      onKeyDown={(ev) => {
        if (ev.key === 'Enter') {
          ev.stopPropagation();
          handleStopRename(ev.currentTarget.value);
        }
      }}
    />
  ) : (
    <span data-layerid={layer.id} className="select-none">
      {name}
      <span data-layerid={layer.id} className={`absolute ml-2 opacity-30 ${showLayerID ? 'visible' : 'invisible'}`}>
        {layer.id}
      </span>
    </span>
  );

  const simplifiedAnimated = [...(simplifiedLayer?.layer?.animatedIds || [])];

  // layer is the head
  // getting the fill color layer

  let combinedAnimated = [];
  let isLayerAnimated: boolean | unknown = false;

  //   useEffect(() => {
  if (simplifiedAnimated.length > 0 && simplifiedLayer) {
    // default Shape animated
    // let allAnimated = [];

    combinedAnimated.push({
      layer,
      layerUI,
    });

    simplifiedAnimated.forEach((sa) => {
      const simplifiedAnimatedLayer = simplifiedLayer?.layer.shapesAppearances.find((item) => {
        return (
          item.type === sa.type || (sa.type in item.animatedProperties && item.animatedProperties[sa.type].isAnimated)
        );
      });

      if (simplifiedAnimatedLayer) {
        const simplifiedLayerUI = getSimplifiedMap(simplifiedAnimatedLayer.id);

        combinedAnimated.push({
          layer: simplifiedAnimatedLayer,
          layerUI: simplifiedLayerUI,
        });
      }
    });

    combinedAnimated = uniqBy(combinedAnimated, 'layer.id');

    combinedAnimated.forEach((item) => {
      const nestedItem = Object.values(item.layer.animatedProperties);

      if (nestedItem.length > 0) {
        nestedItem.forEach((nItem) => {
          if (nItem.isAnimated) {
            isLayerAnimated = true;
          }
        });
      }
    });
  }

  return (
    <>
      <div data-layerid={layer.id}></div>
      <LayerRowContainer
        id={layer.id}
        refId={layer.referenceId as string}
        onDoubleClick={handleRowDoubleClick}
        {...props}
      >
        <div data-layerid={layer.id} className="flex grow items-center">
          {isLayerAnimated && <LayerCollapseButton expanded={expanded} onClick={handleOnClickLayerCollapse} />}
          {!isLayerAnimated && <div className="w-4" />}
          <TreeLine treeLines={treeLines} />
          <LayerThumbnail layer={simplifiedLayer || (layer as ShapeJSON)} />
          <div data-layerid={layer.id} className="ml-1 h-[15px] grow">
            {titleElement}
          </div>
        </div>
        <div className="mr-[2px] flex w-4 items-center">
          <AnimatedButton animated={hasAnimated} layer={layer} />
        </div>
      </LayerRowContainer>

      {expanded &&
        combinedAnimated.length > 0 &&
        combinedAnimated.map((combineAnim: unknown, index) => {
          return (
            <Animated
              key={index}
              layer={combineAnim.layer}
              layerUI={combineAnim.layerUI}
              allParentExpanded={allParentExpanded}
              treeLines={animatedTreeLines}
            />
          );
        })}
    </>
  );
};

interface ShapeLayerProp {
  layer: OptionalShapeLayer | ShapeJSON;
}

export const ShapeLayer: React.FC<ShapeLayerProp> = ({ layer }) => {
  // This is required to force rerender the child layer when parent is expanded
  const layerUI = useCreatorStore((state) => state.ui.layerMap.get(layer.id));

  // Put the early return check here instead of <LayerRow/> to adhere rules of hooks order.
  if (!layerUI) {
    return null;
  }

  const layerShapes = (layer as OptionalShapeLayer).shapes;
  const hasLayerShapes = layerShapes && layerShapes.length > 0;

  return (
    <>
      <LayerRow key={layer.id} layer={layer} layerUI={layerUI} />

      {hasLayerShapes &&
        (layer as ShapeLayerJSON).shapes.map((shape) => {
          return <ShapeLayer key={shape.id} layer={shape} />;
        })}
    </>
  );
};

const LayerMenuContainer: React.FC = () => {
  const [timelineContext, menuOpened] = useCreatorStore(
    (state) => [state.timeline.timelineContext, state.timeline.timelineContext.menuOpened || false],
    shallow,
  );
  const mousePos = timelineContext.mousePos;
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const selectedNodeId = timelineContext.selectedId;
  const selectedRefId = timelineContext.referenceId;
  const selectedNode = selectedNodeId ? getNodeByIdOnly(selectedNodeId) : null;
  const selectedType = selectedNode?.type;

  const isNormalLayer = [ShapeType.GROUP, ShapeType.RECTANGLE, ShapeType.ELLIPSE, ShapeType.STAR, ShapeType.PATH];

  const onCloseMenu = useCallback(() => {
    if (menuOpened) {
      const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;

      setTimelineContext({
        selectedId: null,
        menuOpened: false,
        mousePos: { x: 0, y: 0 },
      });
    }
  }, [menuOpened]);

  const timelineHeight = useCreatorStore.getState().timeline.height;

  let yOffset = null;

  if (selectedType === LayerType.SHAPE) {
    if ((mousePos.y as number) + MENU_HEIGHT.ShapeLayerMenuTimeline > timelineHeight) {
      yOffset = timelineHeight - MENU_HEIGHT.ShapeLayerMenuTimeline;
    }
  } else if (isNormalLayer.includes(selectedType)) {
    if ((mousePos.y as number) + MENU_HEIGHT.NormalLayerMenu > timelineHeight) {
      yOffset = timelineHeight - MENU_HEIGHT.NormalLayerMenu;
    }
  } else if (selectedType === 'PRECOMPOSITION') {
    if ((mousePos.y as number) + MENU_HEIGHT.NestedSceneLayerMenu > timelineHeight) {
      yOffset = timelineHeight - MENU_HEIGHT.NestedSceneLayerMenu;
    }
  }

  if (!yOffset) yOffset = mousePos.y;

  return (
    <div className="absolute">
      {selectedType === LayerType.SHAPE && (
        <ShapeLayerMenuTimeline isOpen={menuOpened} onClose={onCloseMenu} coord={{ x: mousePos.x, y: yOffset }} />
      )}
      {isNormalLayer.includes(selectedType) && (
        <NormalLayerMenu isOpen={menuOpened} onClose={onCloseMenu} coord={{ x: mousePos.x, y: yOffset }} />
      )}
      {selectedType === 'PRECOMPOSITION' && (
        <NestedSceneLayerMenu
          isOpen={menuOpened}
          onClose={onCloseMenu}
          coord={{ x: mousePos.x, y: yOffset }}
          eventArg={{ id: selectedNodeId, layerRefId: selectedRefId as string }}
        />
      )}
    </div>
  );
};

interface LayersProp {
  layers: LayerJSON[];
}

const Layers: React.FC<LayersProp> = ({ layers }) => {
  const layerContainerRef = useRef(null);
  const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;
  const menuOpened = useCreatorStore((state) => state.timeline.timelineContext.menuOpened || false);

  const handleClickOutside = useCallback(() => {
    if (menuOpened) {
      setTimelineContext({
        selectedId: null,
        mousePos: { x: 0, y: 0 },
      });
    }
  }, [menuOpened, setTimelineContext]);

  useClickOutside(layerContainerRef, handleClickOutside, null);

  return (
    <>
      <div
        ref={layerContainerRef}
        id="LayerContainer"
        className={clsx('text-[12px] font-normal leading-[15px] before:select-none', {
          'flex h-full items-center justify-center': layers.length === 0,
        })}
      >
        <LayerMenuContainer />
        {layers.length > 0 ? (
          <>
            <DraggableWrapper layers={layers} />
          </>
        ) : (
          <span className="px-[30px] text-center text-gray-300">
            A new layer will appear here for every object you insert and asset you upload.
          </span>
        )}
      </div>
    </>
  );
};

const LayersPanel: React.FC = () => {
  // root layers
  let layers = useCreatorStore((state) => state.toolkit.json?.allLayers || []);
  const [selectedPrecompositionId, sceneIndex] = useCreatorStore(
    (state) => [state.toolkit.selectedPrecompositionId, state.toolkit.sceneIndex],
    shallow,
  );

  if (selectedPrecompositionId !== null) {
    const precomNode = getNodeById(toolkit.scenes[sceneIndex] as Scene, selectedPrecompositionId);

    layers = (precomNode as Scene).state.allLayers;
  }

  //   console.log('layers: ', layers);

  return <Layers layers={layers} />;
};

export default LayersPanel;
