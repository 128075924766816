/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';

import type { UploadError } from './UploadError';

export interface UploadContextType {
  error: UploadError | null;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  setError: (error: UploadError | null) => void;
  setLoading: (loading: boolean) => void;
}

const upload: UploadContextType = {
  isOpen: false,
  isLoading: false,
  error: null,
  onClose: () => {},
  setError: (_) => {},
  setLoading: (_) => {},
};

export const UploadContext = React.createContext<UploadContextType>(upload);
