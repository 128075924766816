/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useEffect, useRef } from 'react';

import KeyframeButton from '../Button/KeyframeButton';
import { TooltipWrapper } from '../Tooltip/TooltipWrapper';

import { NumberInput } from './NumberInput';
import type { NumberInputProps } from './NumberInput';
import type { NumberResult } from './types';

const leftStyle = {
  label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
};

const rightStyle = {
  label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
};

export type NumberInputOption = Omit<NumberInputProps, 'showKeyframe' | 'onKeyframeClick' | 'onChange' | 'value'>;

interface DualNumberInputProps {
  hasKeyframe?: boolean;
  left: number;
  leftMax?: number;
  leftMin?: number;
  leftOption: NumberInputOption;
  message?: string | null;
  onChange: (result: NumberResult) => void;
  onKeyframeClick?: () => void;
  right: number;
  rightMax?: number;
  rightMin?: number;
  rightOption: NumberInputOption;
  showKeyframe?: boolean;
  testID1?: string;
  testID2?: string;
  tooltip?: {
    left: string;
    right: string;
  };
}

export const DualNumberInput: React.FC<DualNumberInputProps> = ({
  hasKeyframe = false,
  left,
  leftMax = null,
  leftMin = null,
  leftOption,
  message,
  onChange,
  onKeyframeClick,
  right,
  rightMax = null,
  rightMin = null,
  rightOption,
  showKeyframe = false,
  testID1,
  testID2,
  tooltip,
}) => {
  const onKeyframeClickHandler = useRef(onKeyframeClick);

  // store handler
  useEffect(() => {
    onKeyframeClickHandler.current = onKeyframeClick;
  }, [onKeyframeClick]);

  const handleOnKeyframeClick = useCallback(() => {
    if (onKeyframeClickHandler.current) {
      onKeyframeClickHandler.current();
    }
  }, []);

  return (
    <div className="relative flex items-center">
      <div className="number-input-label flex w-44 rounded-md border border-transparent bg-gray-700 focus-within:border-teal-500">
        <TooltipWrapper label={tooltip?.left}>
          <NumberInput
            styleClass={leftStyle}
            {...leftOption}
            value={left}
            min={leftMin}
            max={leftMax}
            onChange={onChange}
            testID={testID1 ?? 'number-input1'}
            message={message}
          />
        </TooltipWrapper>
        <div className="my-[-1px] border border-gray-800"></div>
        <TooltipWrapper label={tooltip?.right}>
          <NumberInput
            styleClass={rightStyle}
            {...rightOption}
            value={right}
            min={rightMin}
            max={rightMax}
            onChange={onChange}
            testID={testID2 ?? 'number-input2'}
            message={message}
          />
        </TooltipWrapper>
      </div>
      {showKeyframe && (
        <div className="absolute right-[-8px] top-[3px]">
          <KeyframeButton onClick={handleOnKeyframeClick} hasKeyframe={hasKeyframe} />
        </div>
      )}
    </div>
  );
};
