/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { compact } from 'lodash-es';
import React, { useEffect, useContext } from 'react';

import { SaveContext } from '../SaveContext';

import { Workspace } from './workspace';

import { useCreatorStore } from '~/store';
import type { IWorkspace } from '~/store/projectSlice';
import { DirectoryType } from '~/store/projectSlice';

interface WorkspaceLayersProp {
  workspaces: IWorkspace[];
}

export const WorkspaceLayers: React.FC<WorkspaceLayersProp> = ({ workspaces }: WorkspaceLayersProp) => {
  const { setSelected, setUIMap } = useContext(SaveContext);

  useEffect(() => {
    const selectedWorkspace = {
      id: useCreatorStore.getState().project.selectedWorkspaceId,
      type: DirectoryType.Workspace,
      parentId: null,
    };

    const selectedDirectory = useCreatorStore.getState().project.selectedDirectory;

    setUIMap((draft: any) => {
      const toBeSelectedIds = compact([selectedWorkspace.id, selectedDirectory?.parentId, selectedDirectory?.id]);
      const toBeSelected = compact([selectedWorkspace, selectedDirectory]);

      toBeSelectedIds.map((selectedId, index: number) => {
        draft[selectedId] = {
          expanded: true,
        };

        if (index === toBeSelectedIds.length - 1) {
          // select last one
          const lastSelectedId = toBeSelectedIds[index];

          const selectedTypeIndex = toBeSelected.findIndex(
            (selected) => lastSelectedId === selected.id || lastSelectedId === selected.parentId,
          );

          const selectedType = toBeSelected[selectedTypeIndex]?.type;

          setSelected({ id: lastSelectedId as string, type: selectedType as DirectoryType });
        }

        return null;
      });
    });
  }, [setSelected, setUIMap]);

  return (
    <div className="">
      {workspaces.length > 0 &&
        workspaces.map((workspace, workspaceIndex: number) => {
          return <Workspace key={workspaceIndex} index={workspaceIndex} workspace={workspace} />;
        })}
    </div>
  );
};
