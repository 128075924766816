/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC, MutableRefObject } from 'react';
import React, { useState, useRef } from 'react';

import { TinyColor } from '../../utils';
import type { ITinyColor } from '../../utils/color';

import Alpha from './Alpha';
import Board from './Board';
import Ribbon from './Ribbon';
import type { TPropsMain } from './types';

import { EyeDropper as EyeDropperIcon } from '~/assets/icons';

let eyeDropper: unknown = null;

if (window.EyeDropper) {
  // @ts-ignore
  eyeDropper = new EyeDropper();
}
const abortController = new AbortController();

const Panel: FC<TPropsMain> = ({ alpha, className, colorBoardHeight, hex, onChange, onStop }) => {
  const node = useRef() as MutableRefObject<HTMLDivElement>;

  const [saturation, setSaturation] = React.useState(0);
  const colorConvert = new TinyColor(hex, saturation) as ITinyColor;

  colorConvert.alpha = alpha;
  const [eyeDropperSelected, setEyeDropperSelected] = useState(false);

  const handleAlphaChange = (alphaValue: number): void => {
    onChange({
      hex: colorConvert.toHexString(),
      alpha: alphaValue,
    });
  };

  const handleChange = (color: ITinyColor): void => {
    onChange({
      hex: color.toHexString(),
      alpha,
    });
  };

  const handleEyeDropperClick = async (): Promise<void> => {
    if (!window.EyeDropper || !eyeDropper) {
      // eslint-disable-next-line no-console
      console.warn('Your browser does not support the EyeDropper API');

      return;
    }
    setEyeDropperSelected(true);

    // @ts-ignore
    const result = await eyeDropper.open({ signal: abortController.signal });

    setEyeDropperSelected(false);
    handleChange(new TinyColor(result.sRGBHex));
  };

  const handleStop = (color: ITinyColor): void => {
    onStop({
      hex: color.toHexString(),
      alpha,
    });
  };

  return (
    <div ref={node} className={`color-picker-panel ${className}`} tabIndex={0}>
      <Board
        rootPrefixCls="color-picker-panel"
        color={colorConvert}
        colorBoardHeight={colorBoardHeight ?? 0}
        onChange={handleChange}
        setSaturation={setSaturation}
        onStop={handleStop}
      />
      <div className="flex">
        <EyeDropperIcon
          className={`${
            eyeDropperSelected ? 'border-2 border-[#00DDB3]' : ''
          } mr-1 h-8 w-8 rounded-lg hover:bg-gray-600`}
          onClick={handleEyeDropperClick}
        />
        <div className="color-picker-panel-wrap">
          <div className="color-picker-panel-wrap-ribbon">
            <Ribbon
              rootPrefixCls="color-picker-panel"
              color={colorConvert}
              onChange={handleChange}
              onStop={handleStop}
            />
          </div>
          <div className="color-picker-panel-wrap-alpha">
            <Alpha rootPrefixCls="color-picker-panel" alpha={alpha} color={colorConvert} onChange={handleAlphaChange} />
          </div>
        </div>
        <div className="my-0.5 ml-[10px] h-6 w-6 rounded" style={{ backgroundColor: colorConvert.toHexString() }}></div>
      </div>
    </div>
  );
};

export default Panel;
