/**
 * Copyright 2022 Design Barn Inc.
 */

import { useMemo, useContext } from 'react';

import {
  FETCH_CURRENT_WORKSPACE,
  FETCH_PROJECTS_BY_WORKSPACE_ID,
  FETCH_WORKFLOW_WORKSPACES,
  FETCH_FOLDERS_BY_PROJECT_ID,
  CREATE_FOLDER,
  FETCH_DRAFT_PROJECT,
  CREATE_PROJECT,
  FETCH_WORKSPACE_ACCESS,
  CREATE_INITIAL_WORKSPACE,
} from './schema';
import { wrapCallbacks } from './shared';

import { BASE_GRAPHQL_ENDPOINT } from '~/config';
import { clientAPIContext } from '~/lib/graphql';
import { useCreatorStore } from '~/store';
import type { IWorkspace, IWorkspaceProject, IWorkspaceFolder } from '~/store/projectSlice';
import { DirectoryType } from '~/store/projectSlice';
import { slugify } from '~/utils';

export interface UploadFileRequestProps {
  fields: Record<string, string>;
  key: string;
  url: string;
}

interface WorkspaceAPIProps {
  createFolderDirectory({ name, projectId }: { name: string; projectId: string }): Promise<{ id: string }>;
  createProjectDirectory({ name, workspaceId }: { name: string; workspaceId: string }): Promise<{ id: string }>;
  fetchWorkflowFolders(id: string): Promise<IWorkspaceFolder[] | []>;
  fetchWorkflowProjects(id: string): Promise<IWorkspaceProject[] | []>;
  fetchWorkflowWorkspaces(): Promise<IWorkspace[] | []>;
  getCurrentWorkspaceId(): Promise<string | null>;
  getDraftProjectId(): Promise<string | null>;
  getWorkspaceAccess(): Promise<boolean | Error>;
  setupInitialWorkspace(): Promise<IWorkspace | Error>;
}

// NOTE: Why is this hardcoded?
const DEFAULT_WORKSPACE_ICON =
  'https://workflow-bucket-dev-caee261.s3.us-east-1.amazonaws.com/lottie-logo-192_(1).ico-aa8ecjlakh';

export const useWorkspaceAPI = (token: string | null): WorkspaceAPIProps => {
  const apiClient = useContext(clientAPIContext);

  const context = useMemo(
    () => ({
      clientName: BASE_GRAPHQL_ENDPOINT,
      token,
    }),
    [token],
  );

  const getWorkspaceAccess = async (): Promise<boolean | Error> => {
    const wsResponse = await apiClient.query(FETCH_WORKSPACE_ACCESS, {}, context).toPromise();

    if (wsResponse.error) {
      return Promise.resolve(Error('Could not fetch workspace access'));
    }

    const hasAccess = wsResponse.data?.hasAccessToAWorkspace;

    return Promise.resolve(hasAccess);
  };

  const getCurrentWorkspaceId = async (): Promise<string | null | Error> => {
    const wsResponse = await apiClient.query(FETCH_CURRENT_WORKSPACE, {}, context).toPromise();

    if (wsResponse.error) {
      return Promise.resolve(Error('Could not fetch workspace'));
    }

    const workspaceId = wsResponse.data?.currentWorkspace?.id;

    return Promise.resolve(workspaceId);
  };

  const getDraftProjectId = async (): Promise<string | null | Error> => {
    const currentWorkspaceId = useCreatorStore.getState().project.selectedWorkspaceId;

    let workspaceId = null;

    if (currentWorkspaceId) {
      workspaceId = currentWorkspaceId;
    } else {
      const wsResponse = await apiClient.query(FETCH_CURRENT_WORKSPACE, {}, context).toPromise();

      if (wsResponse.error) {
        return Promise.resolve(Error('Could not fetch workspace'));
      }

      workspaceId = wsResponse.data?.currentWorkspace?.id;
    }

    if (workspaceId) {
      const pResponse = await apiClient.query(FETCH_DRAFT_PROJECT, { workspaceId }, context).toPromise();

      if (pResponse.error) {
        return Promise.resolve(Error('Could not fetch workspace'));
      }
      const draftFolderId = pResponse.data?.workspaceDraftProject?.id;

      if (draftFolderId) {
        return Promise.resolve(draftFolderId);
      }
    }

    return Promise.resolve(null);
  };

  const fetchWorkflowWorkspaces = async (): Promise<IWorkspace[] | [] | Error> => {
    // Get all workspaces
    const wsResponse = await apiClient.query(FETCH_WORKFLOW_WORKSPACES, {}, context).toPromise();

    if (wsResponse.error) {
      return Promise.resolve(Error('Could not fetch workspace'));
    }

    const { workspaces } = wsResponse.data;

    const results = workspaces.map((node: IWorkspace) => {
      return {
        type: DirectoryType.Workspace,
        id: node.id,
        name: node.name as string,
        icon: node.icon || DEFAULT_WORKSPACE_ICON,
      };
    });

    return Promise.resolve(results);
  };

  const fetchWorkflowProjects = async (id: string): Promise<IWorkspaceProject[] | [] | Error> => {
    const pResponse = await apiClient.query(FETCH_PROJECTS_BY_WORKSPACE_ID, { workspaceId: id }, context).toPromise();

    if (pResponse.error) {
      return Promise.resolve(Error('Could not fetch projects'));
    }

    const { workspaceProjects } = pResponse.data;

    const results = workspaceProjects.edges.map(({ node }: { node: IWorkspaceProject }) => {
      return {
        ...node,
        type: DirectoryType.Project,
        folderCount: node.stats?.['folders'] || 0,
        folders: [],
      };
    });

    return Promise.resolve(results);
  };

  const fetchWorkflowFolders = async (id: string): Promise<IWorkspaceFolder[] | [] | Error> => {
    const fResponse = await apiClient.query(FETCH_FOLDERS_BY_PROJECT_ID, { projectId: id }, context).toPromise();

    if (fResponse.error) {
      return Promise.resolve(Error('Could not fetch folders'));
    }

    const folders = fResponse.data.foldersByProjectId;

    const results = folders.map((folder: IWorkspaceFolder) => ({ ...folder, type: DirectoryType.Folder }));

    return Promise.resolve(results);
  };

  const createProjectDirectory = async ({
    name,
    workspaceId,
  }: {
    name: string;
    workspaceId: string;
  }): Promise<{ id: string } | Error> => {
    const requestParam = {
      input: {
        title: name,
        workspaceId,
        isPrivate: false,
        slug: slugify(name),
      },
    };
    const createProjectResp = await apiClient.mutation(CREATE_PROJECT, requestParam, context).toPromise();

    if (createProjectResp.error) {
      return Promise.resolve(Error('Could not create project'));
    }

    const newProjectData = createProjectResp.data?.projectCreate;

    return Promise.resolve(newProjectData);
  };

  const createFolderDirectory = async ({
    name,
    projectId,
  }: {
    name: string;
    projectId: string;
  }): Promise<{ id: string } | Error> => {
    const requestParam = {
      input: {
        name,
        projectId,
        slug: slugify(name),
      },
    };
    const createFolderResponse = await apiClient.mutation(CREATE_FOLDER, requestParam, context).toPromise();

    if (createFolderResponse.error) {
      return Promise.resolve(Error('Could not create project'));
    }

    const newFolderData = createFolderResponse.data?.folderCreate;

    return Promise.resolve(newFolderData);
  };

  const setupInitialWorkspace = async (): Promise<{ workspace: IWorkspace } | Error> => {
    const createFolderResponse = await apiClient.mutation(CREATE_INITIAL_WORKSPACE, {}, context).toPromise();

    if (createFolderResponse.error) {
      return Promise.resolve(Error('Could not setup initial workspace'));
    }

    const workspaceData = createFolderResponse.data?.setupInitialWorkspace;

    return Promise.resolve(workspaceData);
  };

  const contextCallbackAPIs = wrapCallbacks(
    {
      getCurrentWorkspaceId,
      getDraftProjectId,
      fetchWorkflowFolders,
      fetchWorkflowProjects,
      fetchWorkflowWorkspaces,
      createProjectDirectory,
      createFolderDirectory,
      getWorkspaceAccess,
      setupInitialWorkspace,
    },
    [apiClient, context],
  ) as WorkspaceAPIProps;

  return {
    ...contextCallbackAPIs,
  };
};
