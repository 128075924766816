/**
 * Copyright 2021 Design Barn Inc.
 */

import { authExchange } from '@urql/exchange-auth';
import { createClient as createWSClient } from 'graphql-ws';
import { createContext } from 'react';
import { createClient, makeOperation, dedupExchange, cacheExchange, fetchExchange, subscriptionExchange } from 'urql';

import packageJson from '~/../package.json';
import {
  SEARCH_GRAPHQL_ENDPOINT,
  BASE_GRAPHQL_ENDPOINT,
  AUTH_GRAPHQL_ENDPOINT,
  BASE_GRAPHQL_WSS_ENDPOINT,
} from '~/config';

const wsClient = createWSClient({
  url: BASE_GRAPHQL_WSS_ENDPOINT,
});

const fetchWithTimeout = async (url: RequestInfo | URL, opts: RequestInit): Promise<Response> => {
  const controller = new AbortController();

  const id = setTimeout(() => controller.abort(), 10000);

  try {
    return fetch(url, {
      ...opts,
      signal: controller.signal,
    });
  } finally {
    clearTimeout(id);
  }
};

export const client = createClient({
  // The default client url
  url: BASE_GRAPHQL_ENDPOINT as string,
  fetch: fetchWithTimeout,
  exchanges: [
    dedupExchange,
    cacheExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
    authExchange({
      addAuthToOperation({ operation }) {
        // if clientName does not exist, we return operation without modifications
        if (!operation.context.clientName) {
          return operation;
        }

        const { clientName, fetchOptions, token, useToken = true } = operation.context;
        const options = typeof fetchOptions === 'function' ? fetchOptions() : fetchOptions ?? {};

        const tokenConfig =
          token && useToken
            ? {
                headers: {
                  Authorization: `Bearer ${token || ''}`,
                  'Content-Type': 'application/json',
                  'client-name': 'creator',
                  'client-version': packageJson.version,
                },
              }
            : {};

        // Client name here has a different meaning than the one in the headers
        // This clientName is responsible for switching endpoints (Why again?)
        switch (clientName) {
          case BASE_GRAPHQL_ENDPOINT: {
            const context = {
              ...operation.context,
              url: BASE_GRAPHQL_ENDPOINT,
              fetchOptions: {
                ...tokenConfig,
                ...options,
              },
            };

            return makeOperation(operation.kind, operation, context);
          }

          case SEARCH_GRAPHQL_ENDPOINT: {
            const context = {
              ...operation.context,
              url: SEARCH_GRAPHQL_ENDPOINT,
              fetchOptions: {
                ...tokenConfig,
                ...options,
              },
            };

            return makeOperation(operation.kind, operation, context);
          }

          case AUTH_GRAPHQL_ENDPOINT: {
            const context = {
              ...operation.context,
              url: AUTH_GRAPHQL_ENDPOINT,
              fetchOptions: {
                ...tokenConfig,
                ...options,
              },
            };

            return makeOperation(operation.kind, operation, context);
          }

          default: {
            throw new Error(`Unexpected object: ${clientName}`);
          }
        }
      },
      getAuth: async (): void => null,
    }),
    fetchExchange,
  ],
});

export const clientAPIContext = createContext(client);
