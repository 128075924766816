/**
 * Copyright 2021 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */

import type { AnimatedPropertiesJSON } from '@lottiefiles/toolkit-js';
import { get } from 'lodash-es';
import React from 'react';

import { Thumbnail } from '../../timeline/components/Timeline/TimelineLayerPanel/LayerThumbnail';

import { TransformPositionField } from './field/TransformPositionField';
import { TransformRotationField } from './field/TransformRotationField';
import { TransformScaleField } from './field/TransformScaleField';
import { getShapeIcon } from './ShapeProperty';
import { PropertyRow } from './ui/PropertyRow';

import { SceneIcon, Shape, ThumbnailGroup, ThumbnailPath } from '~/assets/icons';
import { LayerThumbnailFill, LayerThumbnailGradient, LayerThumbnailStroke } from '~/assets/icons/timeline';
import { AppearancePropertyPanel, AppearanceTypeMapping } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

interface TransformPropertyProps {
  children?: React.ReactNode;
  property?: PropertyPanelType;
}

const TransformThumbnail: React.FC = () => {
  const property = useCreatorStore((state) => state.ui.currentPropertyPanel);

  const animatedProperties = useCreatorStore((state): unknown => {
    const currentProperty = state.ui.currentPropertyPanel;

    if (currentProperty === PropertyPanelType.Stroke)
      return get(state.toolkit.currentStrokeShape, 'animatedProperties');
    else if (currentProperty === PropertyPanelType.Fill)
      return get(state.toolkit.currentFillShape, 'animatedProperties');
    else if (currentProperty === PropertyPanelType.GradientFill)
      return get(state.toolkit.currentGFillShape, 'animatedProperties');

    return null;
  });

  if (!animatedProperties || Object.keys(animatedProperties).length === 0) {
    return <Shape className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" />;
  }

  // TODO: Support multiple layerType
  const layerType = AppearanceTypeMapping[property] as string;
  const thumbnailMap: Record<string, React.ReactNode> = {
    fl: (
      <Thumbnail
        icon={<LayerThumbnailFill />}
        type={layerType}
        animatedProperties={animatedProperties as AnimatedPropertiesJSON}
      />
    ),
    st: (
      <Thumbnail
        icon={<LayerThumbnailStroke />}
        type={layerType}
        animatedProperties={animatedProperties as AnimatedPropertiesJSON}
      />
    ),
    gf: (
      <Thumbnail
        icon={<LayerThumbnailGradient />}
        type={layerType}
        animatedProperties={animatedProperties as AnimatedPropertiesJSON}
      />
    ),
  };
  const ThumbnailView = thumbnailMap[layerType] ?? null;

  return <>{ThumbnailView}</>;
};

export const TransformProperty: React.FC<TransformPropertyProps> = ({ children, property }: TransformPropertyProps) => {
  let Title: React.FC;

  switch (property) {
    case PropertyPanelType.Precomposition:
      Title = () => {
        return (
          <>
            Transform:
            <SceneIcon className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" />
            Nested Scene
          </>
        );
      };
      break;

    case PropertyPanelType.ShapeLayer:
      Title = () => {
        return (
          <>
            Transform:
            <Shape className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" />
            Shape Layer
          </>
        );
      };
      break;

    case PropertyPanelType.MultiObjects:
      Title = () => {
        return (
          <>
            Transform:
            <Shape className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" />
            Multiple Objects
          </>
        );
      };
      break;

    case PropertyPanelType.Group:
      Title = () => {
        return (
          <>
            Transform:
            <ThumbnailGroup className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" />
            Group
          </>
        );
      };
      break;

    case PropertyPanelType.Path:
    case PropertyPanelType.EditPath:
      Title = () => {
        return (
          <>
            <ThumbnailPath className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" /> {property}
          </>
        );
      };
      break;

    case PropertyPanelType.EllipsePath:
    case PropertyPanelType.RectanglePath:
    case PropertyPanelType.PolygonPath:
    case PropertyPanelType.StarPath:
      Title = () => {
        const shapeIcon = getShapeIcon(property);

        return (
          <>
            <span className="mr-1">Transform:</span>
            {shapeIcon}
            {/* <Shape className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" /> */}
            {property}
          </>
        );
      };
      break;

    default:
      Title = () => {
        return AppearancePropertyPanel.includes(property as PropertyPanelType) ? (
          <>
            <TransformThumbnail />
            {property}
          </>
        ) : (
          <>
            <Shape className="mx-1 my-auto h-3 w-3 rounded bg-gray-700 p-0.5" />
            {property}
          </>
        );
      };
      break;
  }

  return (
    <PropertyRow title={<Title />}>
      {children ? (
        <>{children}</>
      ) : (
        <>
          <TransformPositionField />
          <TransformScaleField />
          <TransformRotationField />
        </>
      )}
    </PropertyRow>
  );
};
