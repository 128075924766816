/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { SingleKeyFrameMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

import { useCreatorStore } from '~/store';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  isOpen: boolean;
  onClose: () => void;
}

export const SingleKeyFrameMenu: React.FC<ShapeLayerMenuProps> = ({ coord, isOpen, onClose }) => {
  const isPastable = useCreatorStore((state) => state.ui.isPastable);

  if (SingleKeyFrameMenuList[1]) SingleKeyFrameMenuList[1].disabled = !isPastable;

  return (
    <ContextMenuWrapper
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      dropdownItems={SingleKeyFrameMenuList}
    ></ContextMenuWrapper>
  );
};