/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { ChevronDown } from '~/assets/icons';

export enum ColorMode {
  Linear = 'Linear',
  Solid = 'Solid',
}

export const ColorOptions = [
  {
    label: 'Solid',
    type: ColorMode.Solid,
  },
  {
    label: 'Linear',
    type: ColorMode.Linear,
  },
];

interface ColorModeAccordionControlProps {
  colorMode: ColorMode;
  onSelectColorMode: React.Dispatch<React.SetStateAction<ColorMode>>;
}

export const ColorModeAccordionControl: React.FC<ColorModeAccordionControlProps> = ({
  colorMode,
  onSelectColorMode,
}) => {
  return (
    <div className={`absolute left-4 flex flex-row`}>
      <Menu as="div" className="relative inline-block">
        {() => (
          <>
            <Menu.Button className="group pointer-events-auto flex h-full w-full cursor-pointer items-center justify-center rounded stroke-gray-500 hover:stroke-white">
              <div className="text-xs font-bold text-white">{colorMode}</div>
              <ChevronDown className=" ml-2 h-4 w-4 stroke-current dark:text-gray-400" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="pointer-events-auto absolute right-[-40px] z-dropdown mt-1 w-[98px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 shadow-dropdown focus:outline-none">
                {ColorOptions.map((dropdown, index) => {
                  const isLast = index === ColorOptions.length - 1;

                  const { label, type } = dropdown;

                  return (
                    <div key={type} className={`px-2 ${index === 0 && 'pt-2'} pb-1`}>
                      <Menu.Item>
                        <button
                          className={`group flex h-6 w-full items-center rounded ${
                            isLast && 'mb-1'
                          } pl-2 text-xs text-white hover:bg-gray-600`}
                          onClick={() => onSelectColorMode(type)}
                        >
                          {label}
                        </button>
                      </Menu.Item>
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
