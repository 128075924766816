/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React from 'react';

import { LayerChevronCollapse } from '~/assets/icons';

interface Props {
  expanded?: boolean;
  handleProps?: unknown;
  onClick: (expanded: boolean) => void;
}

export const LayerCollapseButton: React.FC<Props> = ({ expanded = false, onClick }) => {
  return (
    <button
      data-testid="layer-collapse-btn"
      className="flex items-center justify-center rounded-layer-thumbnail"
      onClick={(evt) => {
        evt.stopPropagation();
        onClick(!expanded);
      }}
    >
      <LayerChevronCollapse
        className={clsx('h-4 w-4 fill-current stroke-current text-gray-400 hover:text-white', {
          'rotate-90': expanded,
        })}
      />
    </button>
  );
};
