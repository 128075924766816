/**
 * Copyright 2023 Design Barn Inc.
 */

import { DoubleSide, MeshBasicMaterial, PlaneGeometry } from "three";

import { CMesh } from "~/features/canvas";

export class TransformControlsPlane extends CMesh {
  public override type = 'TransformControlsPlane';

  public constructor() {
    super(
      new PlaneGeometry(100000, 100000, 2, 2),
      new MeshBasicMaterial({
        visible: false,
        wireframe: true,
        side: DoubleSide,
        transparent: true,
        opacity: 0.1,
        toneMapped: false,
      }),
    );
  }
}