/**
 * Copyright 2023 Design Barn Inc.
 */

import {
  HoldInterpolator,
  LinearInterpolator,
  Keyframe,
  GradientColor,
  Color,
  Scalar,
  Size,
  Angle,
  Vector,
  Percentage,
  Rect,
  BezierInterpolator,
} from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import { createBezierInterpolatorObject } from '../../interpolators/bezier-interpolator';
import { createHoldInterpolatorObject } from '../../interpolators/hold-interpolator';
import { createLinearInterpolatorObject } from '../../interpolators/linear-interpolator';
import { createAngleObject } from '../../property-values/angle';
import { createColorObject } from '../../property-values/color';
import { createGradientColorObject } from '../../property-values/gradient-color';
import { createPercentageObject } from '../../property-values/percentage';
import { createRectObject } from '../../property-values/rect';
import { createScalarObject } from '../../property-values/scalar';
import { createSizeObject } from '../../property-values/size';
import { createVectorObject } from '../../property-values/vector';
import { createKeyframeObj } from '../../time/keyframe';
import { unmarshal } from '../unmarshal';

import { marshal } from '.';
import type { Plugin } from '~/plugins/Plugin';

export function handleOutgoingValueTypes(plugin: Plugin, args: object | number | string | boolean): unknown {
  let newArgs = args;

  if (typeof args == 'object') {
    if (args instanceof Vector) {
      newArgs = createVectorObject(plugin, args);
    } else if (args instanceof Percentage) {
      newArgs = createPercentageObject(plugin, args);
    } else if (args instanceof Angle) {
      newArgs = createAngleObject(plugin, args);
    } else if (args instanceof Size) {
      newArgs = createSizeObject(plugin, args);
    } else if (args instanceof Rect) {
      newArgs = createRectObject(plugin, args);
    } else if (args instanceof Scalar) {
      newArgs = createScalarObject(plugin, args);
    } else if (args instanceof Color) {
      newArgs = createColorObject(plugin, args);
    } else if (args instanceof GradientColor) {
      newArgs = createGradientColorObject(plugin, args);
    } else if (args instanceof Keyframe) {
      newArgs = createKeyframeObj(plugin, args as Keyframe);
    } else if (args instanceof HoldInterpolator || args.type === 'Hold') {
      newArgs = createHoldInterpolatorObject(plugin, args as HoldInterpolator);
    } else if (args instanceof LinearInterpolator || args.type === 'Linear') {
      newArgs = createLinearInterpolatorObject(plugin, args as LinearInterpolator);
    } else if (args instanceof BezierInterpolator || args.type === 'Bezier') {
      newArgs = createBezierInterpolatorObject(plugin, args as BezierInterpolator);
    }
  }

  return newArgs;
}

export function registerClasses(plugin: Plugin): void {
  // list of classes to register
  // manually providing class names as Class.name returns minified name
  const classes = [
    { name: 'HoldInterpolator', Class: HoldInterpolator },
    { name: 'LinearInterpolator', Class: LinearInterpolator },
    { name: 'Keyframe', Class: Keyframe },
    { name: 'GradientColor', Class: GradientColor },
    { name: 'Color', Class: Color },
    { name: 'Scalar', Class: Scalar },
    { name: 'Size', Class: Size },
    { name: 'Angle', Class: Angle },
    { name: 'Vector', Class: Vector },
    { name: 'Percentage', Class: Percentage },
    { name: 'Rect', Class: Rect },
    { name: 'BezierInterpolator', Class: BezierInterpolator },
  ];

  // for each class in classes array, create a function
  // that lets users create a value in QuickJS
  for (const currentClass of classes) {
    const innerFunctionName = `create${currentClass.name}`;

    const handle = plugin.scope.manage(
      plugin.vm.newFunction(
        innerFunctionName,
        (...vmArgs: QuickJSHandle[]): QuickJSHandle => {
          const args = unmarshal(plugin, vmArgs);
          const value = args ? new currentClass.Class(...args) : new currentClass.Class();
          const valueWithMethods = handleOutgoingValueTypes(plugin, value) as object;

          return marshal(plugin, valueWithMethods);
        },
      ),
    );

    plugin.vm.defineProp(plugin.classesHandle as QuickJSHandle, innerFunctionName, {
      value: handle,
      configurable: false,
    });

    // creates a wrapper function that users can call as a class constructor
    plugin.vm.evalCode(`
      function ${currentClass.name}(...args) {
        return classes.${innerFunctionName}(...args)
      }
    `);
  }
}
