/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

// import { ShapePositionField } from './field/ShapePositionField';
import { ShapeSizeField } from './field/ShapeSizeField';

export const EllipseProperty: React.FC = () => {
  return (
    <>
      {/* <ShapePositionField /> */}
      <ShapeSizeField />
    </>
  );
};
