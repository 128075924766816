/**
 * Copyright 2023 Design Barn Inc.
 */

import type { GroupShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getShapeMethods } from './shape';
import { getTransformableMethods } from './transformable';

const methodNames = {
  createTrimShape: 'createTrimShape',
  createGradientFillShape: 'createGradientFillShape',
  createGradientStrokeShape: 'createGradientStrokeShape',
  createPathShape: 'createPathShape',
  createStrokeShape: 'createStrokeShape',
  clearShapes: 'clearShapes',
  createFillShape: 'createFillShape',
  toBezier: 'toBezier',
  createGroupShape: 'createGroupShape',
  createEllipseShape: 'createEllipseShape',
  createRectangleShape: 'createRectangleShape',
  createStarShape: 'createStarShape',
  createRoundedCornersShape: 'createRoundedCornersShape',
  colors: 'colors',
  numProperties: 'numProperties',
  shapeRole: 'shapeRole',
  shapes: 'shapes',
};

function getGroupShapeMethods(plugin: Plugin, groupShape: GroupShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, groupShape);
}

export function createGroupShapeObj(plugin: Plugin, groupShape: GroupShape): object {
  const groupShapeObj = {
    nodeId: groupShape.nodeId,
    nodeType: groupShape.nodeType,
    type: groupShape.type,
  };

  registerObjectMethods(plugin, groupShape, groupShapeObj, [
    getNodeMethods,
    getShapeMethods,
    getTransformableMethods,
    getGroupShapeMethods,
  ]);

  return groupShapeObj;
}
