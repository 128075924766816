/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ShapeLayerJSON, ShapeJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import { uniqBy } from 'lodash-es';
import React from 'react';

import type { OptionalShapeLayer } from '../../TimelineLayerPanel/Layers';

import { KeyframeAnimated } from './KeyframeAnimated';
import { TimelineTrack } from './TimelineTrack';

import type { LayerUI } from '~/features/timeline';
import { useCreatorStore } from '~/store';

interface TimelineRowProps {
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
}

const isTrack = (layer: OptionalShapeLayer | ShapeJSON): boolean => {
  // Track contain ip and op properties
  return 'ip' in layer.properties && 'op' in layer.properties;
};

export const TimelineRow: React.FC<TimelineRowProps> = ({ layer, layerUI }) => {
  // const { animated, children, expanded, highlight, last, level, parent } = layerUI;
  //   const { highlight, parent } = layerUI;
  const getSimplifiedMap = useCreatorStore.getState().ui.getLayerSimplifiedUI;

  const combinedLayerUI = getSimplifiedMap(layer.id);

  const highlight = combinedLayerUI.hightlight;
  const parent = combinedLayerUI.parent;
  const expanded = combinedLayerUI.expanded;

  const getMap = useCreatorStore((state) => state.ui.getLayerUI);

  const hasParent = Boolean(parent.length);
  const allParentExpanded = hasParent && parent.every((id: string) => getMap(id)?.expanded);

  const isShapeType = 'simplified' in layer;

  let simplifiedLayer = null;
  let combinedAnimated = [];

  if (isShapeType) {
    if (layer.simplified?.isGroup) {
      // TODO: Multiple shapes in one group
    } else {
      // Single layer
      const singleLayer = layer.simplified.layers[0]?.shapes[0];

      if (singleLayer) {
        simplifiedLayer = {
          name: singleLayer.properties?.nm,
          animatedProperties: singleLayer.animatedProperties,
          properties: singleLayer.properties,
          type: singleLayer.type,
          layer: layer.simplified.layers[0],
        };
      }
    }

    const simplifiedAnimated = [...(simplifiedLayer?.layer?.animatedIds || [])];

    if (simplifiedAnimated.length > 0 && simplifiedLayer) {
      // default Shape animated
      combinedAnimated.push({
        layer,
        layerUI,
      });

      simplifiedAnimated.forEach((sa) => {
        const simplifiedAnimatedLayer = simplifiedLayer?.layer.shapesAppearances.find((item) => {
          return (
            item.type === sa.type || (sa.type in item.animatedProperties && item.animatedProperties[sa.type].isAnimated)
          );
        });

        if (simplifiedAnimatedLayer) {
          const simplifiedLayerUI = getSimplifiedMap(simplifiedAnimatedLayer.id);

          combinedAnimated.push({
            layer: simplifiedAnimatedLayer,
            layerUI: simplifiedLayerUI,
          });
        }
      });

      combinedAnimated = uniqBy(combinedAnimated, 'layer.id');
    }
  }

  return (
    <>
      <div
        className={clsx('mr-2 mt-[1px] bg-[#22292f] pl-[11px]', {
          hidden: hasParent && !allParentExpanded,
        })}
      >
        {isTrack(layer) ? (
          <TimelineTrack layer={layer} layerUI={layerUI} selected={highlight} />
        ) : (
          <></>
          //   <TimelineEmptyTrack />
        )}
      </div>
      {isShapeType &&
        expanded &&
        combinedAnimated.length > 0 &&
        combinedAnimated.map((combineAnim: unknown, index) => {
          return (
            <KeyframeAnimated
              key={index}
              layer={combineAnim.layer}
              layerUI={combineAnim.layerUI}
              allParentExpanded={allParentExpanded}
            />
          );
        })}
    </>
  );
};

interface TimelineItemProps {
  layer: OptionalShapeLayer | ShapeJSON;
}

export const TimelineItem: React.FC<TimelineItemProps> = ({ layer }: TimelineItemProps) => {
  // This is required to force rerender the child layer when parent is expanded
  const layerUI = useCreatorStore((state) => state.ui.layerMap.get(layer.id));

  // Put the early return check here instead of <LayerRow/> to adhere rules of hooks order.
  if (!layerUI) {
    return null;
  }

  return (
    <div className="">
      <TimelineRow layer={layer} layerUI={layerUI} />
      {(layer as OptionalShapeLayer).shapes &&
        (layer as ShapeLayerJSON).shapes.map((shape) => <TimelineItem key={shape.id} layer={shape} />)}
    </div>
  );
};
