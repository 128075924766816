/**
 * Copyright 2022 Design Barn Inc.
 */

/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';
import { useLocation } from 'react-router-dom';

export const useURLQuery = (): unknown => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
