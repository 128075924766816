/**
 * Copyright 2022 Design Barn Inc.
 */

export const FILE_UPLOAD_CREATE_REQUEST_MUTATION = `
 mutation FileUploadCreateRequest($input: CreateUploadRequestInput) {
   fileUploadRequestCreate(input: $input) {
     fields
     key
     url
   }
 }
`;

export const FILE_UPDATE_SUBSCRIPTION = `
 subscription FileUpdate($key: String!) {
   fileUpdate(key: $key) {
     versionId
   }
 }
`;

export const FILE_CREATE_MUTATION = `
   mutation FileCreate($input: FileCreateInput!) {
       fileCreate(input: $input) {
           id
           currentVersionId
       }
   }
`;

export const FILE_VERSION_CREATE_MUTATION = `
  mutation FileVersionCreate($input: FileVersionCreateInput!) {
    fileVersionCreate(input: $input) {
      id
      fileVersionId
    }
  }
`;

export const FILE_VERSION_QUERY = `
  query file($id: ID!) {
    file(id: $id) {
      currentVersionId
    }
  }
`;

export const FILE_BY_ID_QUERY = `
  query file($id: ID!) {
    file(id: $id) {
      folderId
      id
      name
      projectId
      project {
        workspaceId
      }
      type
      fileObject {
        key
        url
        versionId
        attributes {
            contentType
        }
      }
    }
  }
`;
