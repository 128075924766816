/**
 * Copyright 2022 Design Barn Inc.
 */

import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

// See: https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

// Reference: https://usehooks-ts.com/react-hook/use-event-listener
export function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
): void;
export function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: RefObject<T>,
): void;

export function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void
>(
  eventName: KW | KH,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: RefObject<T>,
): void {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!(targetElement && (targetElement as HTMLElement).addEventListener)) {
      // eslint-disable-next-line no-undefined
      return undefined;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener: typeof handler = (event) => savedHandler.current(event);

    (targetElement as HTMLElement).addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      (targetElement as HTMLElement).removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
