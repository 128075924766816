/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';

interface LayerConfiguration {
  disableSorting?: boolean;
  hideCollapseButton?: boolean;
  hoverIsTop?: boolean;
  hoverOverId?: string | null;
  indentationWidth?: number;
  indicator?: boolean;
}

interface TimelineContext {
  menuOpened?: boolean;
  mousePos: { x: number; y: number };
  referenceId?: string;
  selectedId: string | null;
}

const DEFAULT_LAYER_CONFIGURATION = {
  disableSorting: false,
  hideCollapseButton: false,
  indentationWidth: 10,
  indicator: true,
};

export interface TimelineSlice {
  timeline: {
    clearTabState(): void;
    height: number;
    hiddenTabs: string[];
    hoverId: string | null;
    layerConfiguration: LayerConfiguration;
    looping: boolean;
    multiSelectionModifier: boolean;
    playing: boolean;
    preventScrollEvent: boolean;
    rangeEnd: number;
    rangeStart: number;
    removeKeyframes: () => void;
    renamingLayer: string | null;
    scale: number;
    scrubberDrag: unknown | null;
    selectedKeyframes: string[];
    setHeight: (height: number) => void;
    setHoverID: (id: string | null) => void;
    setLayerConfiguration(data: LayerConfiguration): void;
    setLooping(loop: boolean): void;
    setMultiSelectionModifier(keydown: boolean): void;
    setPlaying(playing: boolean): void;
    setPreventScrollEvent(preventScrollEvent: boolean): void;
    setRange(range: number[]): void;
    setRangeEnd(end: number): void;
    setRangeStart(start: number): void;
    setRenamingLayer(layerId: string | null): void;
    setScrubberDrag(status: unknown | null): void;
    setSelectedKeyframe(id: string[]): void;
    setSingleSelectionModifier(keydown: boolean): void;
    setTabState(asset: string, visible: boolean): void;
    setTimeScale(scale: number): void;
    setTimelineContext(newContext: TimelineContext): void;
    setVisible(visible: boolean): void;
    showLayerID: boolean;
    singleSelectionModifier: boolean;
    timelineContext: TimelineContext;
    visible: boolean;
  };
}

export const createTimelineSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  TimelineSlice
> = (set, get) => ({
  timeline: {
    height: 0,
    hoverId: null,
    hiddenTabs: [],
    setTabState: (tabName: string, visible: boolean) => {
      const isInHiddenTabs = get().timeline.hiddenTabs.includes(tabName);

      set((state) => {
        if (isInHiddenTabs && visible) {
          state.timeline.hiddenTabs.splice(state.timeline.hiddenTabs.indexOf(tabName), 1);
        } else if (!isInHiddenTabs && !visible) {
          state.timeline.hiddenTabs.push(tabName);
        }
      });
    },
    // Prevent non-zero division
    rangeStart: 0,
    rangeEnd: 100,
    renamingLayer: null,
    preventScrollEvent: false,
    playing: false,
    looping: true,
    scale: 100,
    visible: true,
    showLayerID: false,
    singleSelectionModifier: false,
    multiSelectionModifier: false,
    selectedKeyframes: [],
    clearTabState: () => {
      set((state) => {
        state.timeline.hiddenTabs = [];
      });
    },
    layerConfiguration: DEFAULT_LAYER_CONFIGURATION,
    scrubberDrag: true,
    setScrubberDrag: (status: unknown | null) => {
      set((state) => {
        state.timeline.scrubberDrag = status;
      });
    },
    timelineContext: { selectedId: null, mousePos: { x: 0, y: 0 } },
    setTimelineContext: (newContext: TimelineContext) => {
      set((state) => {
        state.timeline.timelineContext = {
          ...state.timeline.timelineContext,
          ...newContext,
        };
      });
    },
    removeKeyframes: () => {
      const selectedKeyframes = get().timeline.selectedKeyframes;

      stateHistory.beginAction();
      selectedKeyframes.forEach((id) => removeKeyFrame(id, false));
      stateHistory.endAction();

      // After toolkit keyframes removed, remove the timeline UI selected keyframes too
      set((state) => {
        state.timeline.selectedKeyframes = [];
        state.toolkit.currentFrameId = '';
      });
    },
    setMultiSelectionModifier: (keydown: boolean) => {
      set((state) => {
        state.timeline.multiSelectionModifier = keydown;
      });
    },
    setPreventScrollEvent: (preventScrollEvent: boolean) => {
      set((state) => {
        state.timeline.preventScrollEvent = preventScrollEvent;
      });
    },
    setPlaying: (playing: boolean) => {
      set((state) => {
        state.timeline.playing = playing;
      });
    },
    setLooping: (looping: boolean) => {
      set((state) => {
        state.timeline.looping = looping;
      });
    },
    setVisible: (visible: boolean) => {
      set((state) => {
        state.timeline.visible = visible;
      });
    },
    setHeight: (height: number) => {
      set((state) => {
        state.timeline.height = height;
      });
    },
    setHoverID: (id: string | null) => {
      set((state) => {
        state.timeline.hoverId = id;
      });
    },
    setSingleSelectionModifier: (keydown: boolean) => {
      set((state) => {
        state.timeline.singleSelectionModifier = keydown;
      });
    },
    setTimeScale: (scale: number) => {
      set((state) => {
        state.timeline.scale = scale;
      });
    },
    setRange: (range: [number, number]) => {
      set((state) => {
        state.timeline.rangeStart = range[0];
        state.timeline.rangeEnd = range[1];
      });
    },
    setRangeStart: (start: number) => {
      set((state) => {
        state.timeline.rangeStart = start;
      });
    },
    setRangeEnd: (end: number) => {
      set((state) => {
        state.timeline.rangeEnd = end;
      });
    },
    setRenamingLayer: (layerId: string | null) => {
      set((state) => {
        state.timeline.renamingLayer = layerId;
      });
    },
    setSelectedKeyframe: (id: string[]) => {
      set((state) => {
        state.timeline.selectedKeyframes = id;
      });
    },
    setLayerConfiguration: (data: LayerConfiguration) => {
      set((state) => {
        state.timeline.layerConfiguration = {
          ...state.timeline.layerConfiguration,
          ...data,
        };
      });
    },
  },
});
