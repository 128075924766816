/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Vector } from '@lottiefiles/toolkit-js';
import { Vector2 } from 'three';

// Get offset of an recursively nested child element
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOffset = (el: any): { left: number; top: number } => {
  let x = 0;
  let y = 0;
  let element = el;

  while (element && !Number.isNaN(element.offsetLeft) && !Number.isNaN(element.offsetTop)) {
    x += element.offsetLeft - element.scrollLeft;
    y += element.offsetTop - element.scrollTop;
    element = element.offsetParent;
  }

  return { top: y, left: x };
};

export const getMouseCoord = (mouseInfo: MouseEvent | Vector, canvas: HTMLCanvasElement): Vector2 => {
  const mouse = new Vector2();
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const offsetTop = getOffset(canvas).top;
  const offsetLeft = getOffset(canvas).left;

  // Compute extra offset created by centering canvas inside a relative div
  // const transformOffsetLeft = canvas.clientWidth / 2
  // const transformOffsetTop = canvas.clientHeight / 2
  if (mouseInfo instanceof MouseEvent) {
    mouse.x = ((mouseInfo.clientX - offsetLeft + window.scrollX) / width) * 2 - 1;
    mouse.y = -((mouseInfo.clientY - offsetTop + window.scrollY) / height) * 2 + 1;
  } else {
    mouse.x = ((mouseInfo.x + window.scrollX) / width) * 2 - 1;
    mouse.y = -((mouseInfo.y + window.scrollY) / height) * 2 + 1;
  }

  return mouse;
};
