/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useRef, useState, useContext, useEffect } from 'react';

import { SaveContext } from '../SaveContext';

import { useClickOutside } from '~/hooks/useClickOutside';
import { useWorkspaceAPI } from '~/providers/api/workspace';
import { useCreatorStore } from '~/store';
import { DirectoryType } from '~/store/projectSlice';

interface CreateDirectoryProps {
  index: number;
  parentId: string;
  tempId: string;
  type: string;
}

export const CreateDirectory: React.FC<CreateDirectoryProps> = ({ index, parentId, tempId, type }) => {
  const token = useCreatorStore.getState().user.token;

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputName, setInputName] = useState('');
  const [creating, setCreating] = useState(false);
  const { setRowMap, setSelected, setUIMap, uiMap } = useContext(SaveContext);

  const { createFolderDirectory, createProjectDirectory } = useWorkspaceAPI(token);

  const handleTextChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setInputName(evt.target.value);
    },
    [setInputName],
  );

  const createDirectory = useCallback(async () => {
    let directoryResult = null;

    const name = inputName;

    if (type === DirectoryType.Project) {
      directoryResult = await createProjectDirectory({
        name,
        workspaceId: parentId,
      });
    } else if (type === DirectoryType.Folder) {
      directoryResult = await createFolderDirectory({
        name,
        projectId: parentId,
      });
    }

    if (directoryResult) {
      const newDirectoryId = directoryResult.id;

      if (newDirectoryId) {
        let input = {};

        if (type === DirectoryType.Project) {
          input = { title: name, workspaceId: parentId, folders: [] };
        } else if (type === DirectoryType.Folder) {
          input = { name, projectId: parentId };
        }

        const newCreatedChild = {
          ...input,
          id: newDirectoryId,
          isPrivate: false,
          type,
        };

        const key = type === DirectoryType.Project ? 'projects' : 'folders';

        setRowMap((draft: any) => {
          const childWithoutTemp = draft[parentId][key].filter((row: { id: string }) => row.id !== tempId);

          // delete the old temp row
          draft[parentId][key] = [...childWithoutTemp];
          delete draft[tempId];

          // create new one
          draft[parentId][key] = [...childWithoutTemp, newCreatedChild];
          draft[newDirectoryId] = newCreatedChild;
        });

        setUIMap((draft: any) => {
          // delete the old temp UI
          delete draft[tempId];

          // Create new one
          draft[newDirectoryId] = {
            expanded: false,
            name,
            type,
          };
        });

        // selected newly created directory
        setSelected(newCreatedChild);
      }
    }
    setCreating(false);
  }, [
    type,
    setSelected,
    createFolderDirectory,
    createProjectDirectory,
    inputName,
    setRowMap,
    setUIMap,
    parentId,
    tempId,
    setCreating,
  ]);

  const handleOnInputKeyPress = useCallback(
    (evt: { key: string }) => {
      if (evt.key === 'Enter' && inputName !== '' && token && !creating) {
        setCreating(true);
        createDirectory();
      }
    },
    [token, createDirectory, setCreating, creating, inputName],
  );

  const handleClickOutside = useCallback((): void => {
    const key = type === DirectoryType.Project ? 'projects' : 'folders';

    setRowMap((draft: any) => {
      const childWithoutTemp = draft[parentId][key].filter((row: { id: string }) => row.id !== tempId);

      // delete the old temp row
      draft[parentId][key] = [...childWithoutTemp];
      delete draft[tempId];
    });

    setUIMap((draft: any) => {
      // delete the old temp UI
      delete draft[tempId];
    });

    setSelected({});
  }, [setRowMap, setUIMap, parentId, tempId, setSelected, type]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, [inputRef, uiMap, setUIMap, parentId]);

  useClickOutside(inputRef, handleClickOutside);

  return (
    <>
      <div
        className={`ml-[37px] flex h-[28px] w-[280] cursor-pointer items-center rounded border-2 border-solid border-teal-500 bg-gray-700 px-1 py-[3px] ${
          index === 0 ? 'mt-3' : 'mt-2'
        }`}
      >
        <input
          ref={inputRef}
          autoComplete="off"
          type="text"
          value={inputName}
          className="h-full  w-full border-0 bg-transparent text-sm text-white outline-none"
          onChange={handleTextChange}
          onKeyDown={handleOnInputKeyPress}
          placeholder="New folder"
          style={{ boxShadow: 'none' }}
        />
      </div>
    </>
  );
};
