/**
 * Copyright 2022 Design Barn Inc.
 */

import { isNil, clamp, isEmpty } from 'lodash-es';
import { useCallback, useMemo, useEffect, useReducer, useRef, useState } from 'react';

import type { NumberResult } from './types';

import { useEventListener } from '~/hooks';
import { EmitterEvent } from '~/lib/emitter';
import { undoHistory, redoHistory } from '~/lib/history';
import { stateHistory } from '~/lib/toolkit';
import { isNumber, formatNumber } from '~/utils';

interface NumberInputOption {
  append: string;
  max: number | null;
  min: number | null;
  precision: number;
  step: number;
}

// process the value to make sure it is within the defined range
const processValue = (val: number, option: NumberInputOption): number => {
  const { max, min } = option;

  let newValue = val;

  // Handle minimum maximum
  if (!isNil(min) && !isNil(max)) {
    newValue = clamp(newValue, min, max);
  } else if (!isNil(min)) {
    newValue = newValue < min ? min : newValue;
  } else if (!isNil(max)) {
    newValue = newValue > max ? max : newValue;
  }

  return newValue;
};

// prepare the input value by removing the user-defined append characters
const preProcessInput = (input: string, append: string): string => {
  // Remove whitespaces
  const newInput = input.replace(/\s+/gu, '');

  // Pre-process by removing user-defined append characters and space
  const appendRegex = append.replace(/\s+/gu, '');
  const re = new RegExp(`${appendRegex}$`, 'gu');

  // Remove any space as well
  return newInput.replace(re, '');
};

// post-process the input value by appending the user-defined append characters
const postProcess = (input: string, option: NumberInputOption): string => {
  const { append } = option;

  return `${input}${append}`;
};

// process the input value. If externalValue is provided, it will be used instead of the internal state
const process = (state: NumberInputState, option: NumberInputOption, externalValue?: number): NumberInputState => {
  const { input, valid } = state;
  const { append, precision } = option;

  let newInput: string;

  if (isNil(externalValue)) {
    newInput = preProcessInput(input, append);
  } else {
    newInput = preProcessInput(externalValue.toString(), append);
  }

  const newState: NumberInputState = { ...state };
  let finalValue: number;

  // Compare whether input is same as last rounded valid value, if same, don't process the value
  const isSame = formatNumber(valid, precision) !== Number(newInput);

  if (isNumber(newInput) && !isEmpty(newInput) && isSame) {
    const result = processValue(Number(newInput), option);

    finalValue = result;
    newState.valid = result;
  } else {
    // Restore from the previous valid value
    finalValue = valid;
  }

  // Round the result to the defined precision
  const roundedNumber = formatNumber(finalValue, precision);
  const inputString = roundedNumber.toString();

  // post-process by adding user-defined append characters
  newState.input = postProcess(inputString, option);

  return newState;
};

interface NumberInputState {
  input: string;
  valid: number;
}

interface PayloadType {
  option: NumberInputOption;
  value?: number;
}

type ActionType =
  | { payload: PayloadType; type: 'increment' }
  | { payload: PayloadType; type: 'decrement' }
  | { payload: PayloadType; type: 'confirm' }
  | { payload: string; type: 'input_changed' }
  | { payload: PayloadType; type: 'value_props_changed' };

const reducer = (state: NumberInputState, action: ActionType): NumberInputState => {
  switch (action.type) {
    case 'increment': {
      const { option } = action.payload;
      const newState = process(state, option, state.valid + option.step);

      return newState;
    }

    case 'decrement': {
      const { option } = action.payload;
      const newState = process(state, option, state.valid - option.step);

      return newState;
    }

    case 'confirm': {
      const { option } = action.payload;
      const newState = process(state, option);

      return newState;
    }

    case 'input_changed': {
      return { ...state, input: action.payload };
    }

    case 'value_props_changed': {
      const { option, value } = action.payload;

      const newState = process(state, option, value);

      return newState;
    }

    default:
      throw new Error();
  }
};

interface UseNumberInputProps {
  append?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  max: number | null;
  min: number | null;
  name?: string;
  onChange?: ((result: NumberResult) => void) | null;
  precision: number;
  step?: number;
  value: number;
}

type UseNumberInputReturn = [
  string,
  {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputStop: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleOnBlur: () => void;
  },
];

const initialState: NumberInputState = {
  input: '',
  valid: 0,
};

const init = (obj: PayloadType): NumberInputState => {
  const newState = process(initialState, obj.option, obj.value);

  return newState;
};

// useNumberInput contains the logic for handling the input value for NumberInput component
export const useNumberInput = ({
  append = '',
  inputRef,
  max = null,
  min = null,
  name = '',
  onChange,
  precision,
  step = 1,
  value: valueProp,
}: UseNumberInputProps): UseNumberInputReturn => {
  // Create a ref that stores handler to prevent rerender
  const onChangeHandler = useRef(onChange);
  const valueRef = useRef(valueProp);
  const nameRef = useRef(name);
  const precisionRef = useRef(precision);

  const payload = useMemo(() => {
    return {
      value: valueProp,
      option: {
        max,
        min,
        append,
        precision,
        step,
      },
    };
  }, [min, max, precision, step, append, valueProp]);

  const [state, dispatch] = useReducer(reducer, initialState, (_: NumberInputState) => init(payload));
  const [onStop, setOnStop] = useState(false);
  const [onEnter, setOnEnter] = useState(false);
  const [draggingEvent, setDraggingEvent] = useState(false);

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler without us needing to pass it in effect deps array
  // and potentially cause effect to re-run every render.
  useEffect(() => {
    onChangeHandler.current = onChange;
  }, [onChange]);

  // When the value prop changes, update the state
  useEffect(() => {
    // Store in valueRef to prevent rerender
    valueRef.current = valueProp;
    dispatch({ type: 'value_props_changed', payload });
  }, [valueProp, payload]);

  // Store in nameRef to prevent rerender
  useEffect(() => {
    nameRef.current = name;
    precisionRef.current = precision;
  }, [name, precision]);

  // Trigger onChange callback when input value changes
  useEffect(() => {
    if (
      valueRef.current !== state.valid &&
      !onStop &&
      valueRef.current !== Infinity &&
      valueRef.current !== -Infinity
    ) {
      if (onChangeHandler.current) {
        if (!draggingEvent) {
          stateHistory.beginAction();
        }
        onChangeHandler.current({
          name: nameRef.current,
          value: formatNumber(state.valid, precisionRef.current),
          trueValue: state.valid,
        });
        if (onEnter) {
          stateHistory.endAction();
          setOnEnter(false);
        } else {
          setDraggingEvent(true);
        }
      }
    }
  }, [state.valid, draggingEvent, setDraggingEvent, onStop, setOnEnter, onEnter]);

  // Handle input change
  const handleInputStop = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setOnStop(true);
      setDraggingEvent(false);

      stateHistory.endAction();
      dispatch({ type: 'input_changed', payload: evt.target.value });
    },
    [setOnStop, setDraggingEvent],
  );

  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setOnStop(false);
      dispatch({ type: 'input_changed', payload: evt.target.value });
    },
    [setOnStop],
  );

  // processInput function
  const handleOnBlur = useCallback((): void => {
    dispatch({ type: 'confirm', payload });
  }, [payload]);

  // handle keyboard interaction event
  const handleKeyDown = useCallback(
    (evt: KeyboardEvent): void => {
      const { ctrlKey, key, metaKey, shiftKey } = evt;

      if (key === 'ArrowUp') {
        evt.preventDefault();
        dispatch({ type: 'increment', payload });
        inputRef.current?.select();
      } else if (key === 'ArrowDown') {
        evt.preventDefault();
        dispatch({ type: 'decrement', payload });
        inputRef.current?.select();
      } else if (key === 'Enter') {
        setOnEnter(true);
        // Automatically trigger onBlur
        inputRef.current?.blur();
      } else if (key === 'z' && (metaKey || ctrlKey) && !shiftKey) {
        undoHistory(EmitterEvent.UI_UNDO);
      } else if (key === 'z' && (metaKey || ctrlKey) && shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      } else if (key === 'y' && ctrlKey && !shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      }
    },
    [payload, inputRef, setOnEnter],
  );

  // Subscribe to keydown events
  useEventListener('keydown', handleKeyDown, inputRef);

  return [state.input, { handleInputChange, handleOnBlur, handleInputStop }];
};
