/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StrokeShape as StrokeShapeType, PercentageJSON, Scene } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { StrokeWidth as StrokeWidthIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, setAnimatedStrokeWidth, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedStrokeWidth: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  // const { pct } = animatedProp.value as PercentageJSON;

  // temp fix
  const animatedValue = animatedProp.value as PercentageJSON | null;

  const { value } = animatedValue ? animatedValue : { value: 0 };
  const [sceneIndex] = useCreatorStore((state) => [state.toolkit.sceneIndex], shallow);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id) as StrokeShapeType;

      if (node.parent) {
        setAnimatedStrokeWidth(node.parent as StrokeShapeType, [result.value]);
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED);
      }
    },
    [sceneIndex, animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="strokeWidth"
        onChange={handleOnChange}
        value={value}
        precision={0}
        label={<StrokeWidthIcon className="h-1.5 w-2.5" />}
        styleClass={styleClass}
        min={0}
      />
    </div>
  );
};
