/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset, Scene } from '@lottiefiles/toolkit-js';

export const setDuration = (scene: Scene, duration: number): void => {
  scene.timeline.setDuration(duration);
};

export const setSceneFPS = (scene: Scene, fps: number): void => {
  scene.timeline.setFrameRate(fps);
};

export const setStartFrame = (scene: Scene, startFrame: number): void => {
  scene.timeline.setStartFrame(startFrame);
};

export const setEndFrame = (scene: Scene, endFrame: number): void => {
  scene.timeline.setEndFrame(endFrame);
};

export const setPrecompFPS = (scene: Scene, precompId: string, fps: number): void => {
  (scene.getNodeById(precompId) as PrecompositionAsset).timeline.setFrameRate(fps);
};

export const setPrecompDuration = (scene: Scene, precompId: string, duration: number): void => {
  (scene.getNodeById(precompId) as PrecompositionAsset).timeline.setDuration(duration);
};
