/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { getTransformableMethods } from '../shape/transformable';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getAvLayerMethods } from './av-layer';
import { getLayerMethods } from './layer';

const methodNames = {
  createGroupShape: 'createGroupShape',
};

export function getShapeLayerMethods(plugin: Plugin, layer: Layer): ObjectMethods {
  return getObjectMethods(plugin, methodNames, layer);
}

export function createShapeLayerObject(plugin: Plugin, layer: Layer): object {
  const layerObj = {
    nodeId: layer.nodeId,
    nodeType: layer.nodeType,
    type: layer.type,
  };

  registerObjectMethods(plugin, layer, layerObj, [
    getNodeMethods,
    getLayerMethods,
    getTransformableMethods,
    getAvLayerMethods,
    getShapeLayerMethods,
  ]);

  return layerObj;
}
