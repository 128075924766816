/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const Interactive: React.FC<Props> = ({ children }) => {
  return <div className="ml-3 flex h-6 w-6 items-center justify-center rounded hover:bg-gray-700">{children}</div>;
};
