/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PathShape } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { registerObjectMethods, getObjectMethods } from '../vmInterface/wrapper';

import { getDrawableShapeMethods } from './drawable-shape';

const methodNames = {
  direction: 'direction',
  position: 'position',
  positionAtKeyFrame: 'positionAtKeyFrame',
  shape: 'shape',
  setDirection: 'setDirection',
  setPosition: 'setPosition',
  setShape: 'setShape',
};

function getPathShapeMethods(plugin: Plugin, path: PathShape): ObjectMethods {
  return getObjectMethods(plugin, methodNames, path);
}

export function createPathShapeObject(plugin: Plugin, path: PathShape): object {
  const pathShapeObj = {
    nodeId: path.nodeId,
    nodeType: path.nodeType,
    type: path.type,
  };

  registerObjectMethods(plugin, path, pathShapeObj, [getNodeMethods, getDrawableShapeMethods, getPathShapeMethods]);

  return pathShapeObj;
}
