/**
 * Copyright 2022 Design Barn Inc.
 */

import { Toolkit, StateHistory } from '@lottiefiles/toolkit-js';
import { DotlottiePlugin } from '@lottiefiles/toolkit-plugin-dotlottie';
import { LottiePlugin } from '@lottiefiles/toolkit-plugin-lottie';
import { SvgPlugin } from '@lottiefiles/toolkit-plugin-svg';

const toolkit = new Toolkit({
  enableNodeIds: true,
  useFlatHierarchy: true,
  hydratePrecompositions: true,
  immutableState: true,
  enableState: true,
});

const stateHistory = new StateHistory(toolkit.events, 100);

toolkit.addPlugin(
  new LottiePlugin({
    importConfig: {
      enableNodeIds: true,
      standardizeTimeline: true,
      defaultNames: true,
    },
  }),
);

const plugin = new DotlottiePlugin({
  importConfig: {
    enableNodeIds: true,
    standardizeTimeline: true,
    defaultNames: true,
  },
});

toolkit.addPlugin(new SvgPlugin());

toolkit.addPlugin(plugin);

export { toolkit, stateHistory };
