/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  PrecompositionLayer,
  Scene,
  ShapeLayerJSON,
  SizeJSON,
  PropertiesJSON,
  PrecompositionAsset,
  ShapeLayer,
  PrecompositionLayerJSON,
} from '@lottiefiles/toolkit-js';
import { Vector, Size } from '@lottiefiles/toolkit-js';
import type { SvgImportOptions } from '@lottiefiles/toolkit-plugin-svg';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit, importSVG, renameNestedScenes, stateHistory, getNodeById } from '~/lib/toolkit';
import { getSelectedDrawOrder } from '~/lib/toolkit/helper';
import { useCreatorStore } from '~/store';

const setSelectedIdAfterCreated = useCreatorStore.getState().ui.setSelectedIdAfterCreated;
const setProjectInfo = useCreatorStore.getState().project.setInfo;

export const reAdjustPrecomScale = async (
  scene: Scene,
  precomLayer: PrecompositionLayer | ShapeLayer,
): Promise<void> => {
  const precomWidth = (precomLayer.state.properties.sz as SizeJSON).w;
  const precomHeight = (precomLayer.state.properties.sz as SizeJSON).h;

  const width = (scene.state.properties.sz as SizeJSON).w;
  const height = (scene.state.properties.sz as SizeJSON).h;

  let widthScale = Math.ceil((width * 100) / precomWidth);
  let heightScale = Math.ceil((height * 100) / precomHeight);

  if (precomWidth > width) {
    heightScale = Math.ceil((precomLayer.transform.scale.valueAtKeyFrame(0).y * widthScale) / 100);
    precomLayer.setScale(new Vector(widthScale, heightScale));
  } else if (precomHeight > height) {
    widthScale = Math.ceil((precomLayer.transform.scale.valueAtKeyFrame(0).x * heightScale) / 100);
    precomLayer.setScale(new Vector(widthScale, heightScale));
  }
};

const adjustPrecompAssetSize = (scene: Scene, precompLayer: PrecompositionLayer): void => {
  const { h: precompHeight, w: precompWidth } = precompLayer.state.properties.sz as SizeJSON;

  const precompAssetId = precompLayer.precomposition?.nodeId as string;
  const precompAsset = getNodeById(scene, precompAssetId) as PrecompositionAsset;

  precompAsset.setData('canvasCustom', false);

  precompAsset.setData('canvasWidth', precompWidth);
  precompAsset.setData('canvasHeight', precompHeight);

  precompAsset.setData('canvasMinWidth', 0);
  precompAsset.setData('canvasMinHeight', 0);
};

export const processImportedLotties = async (
  precomLayer: PrecompositionLayer,
  jsonFileName?: string,
  defaultDrawOrder?: number,
): Promise<void> => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  reAdjustPrecomScale(toolkit.scenes[sceneIndex] as Scene, precomLayer as PrecompositionLayer);
  const newDrawOrder = defaultDrawOrder || (getSelectedDrawOrder() as number);
  const precompDrawOrder = Math.max(newDrawOrder - 1, 0);

  if (precomLayer.state.composition.allLayers.length === 1) {
    const firstLayer = precomLayer.state.composition.layers[0];
    let parentLayer = null;

    if (firstLayer?.type === 'PRECOMPOSITION') {
      parentLayer = (toolkit.scenes[sceneIndex] as Scene)
        .createPrecompositionLayer()
        .setDrawOrder(precompDrawOrder, newDrawOrder > 0);
      parentLayer.fromJSON(precomLayer.state.composition.layers[0] as PrecompositionLayerJSON);
    } else {
      parentLayer = (toolkit.scenes[sceneIndex] as Scene)
        .createShapeLayer()
        .setDrawOrder(precompDrawOrder, newDrawOrder > 0);
      parentLayer.fromJSON(precomLayer.state.composition.layers[0] as ShapeLayerJSON);
    }

    parentLayer.setScale(
      new Vector(
        parentLayer.scale.value.x * (precomLayer.scale.value.x / 100),
        parentLayer.scale.value.y * (precomLayer.scale.value.y / 100),
      ),
    );
    parentLayer.setPosition(
      new Vector(
        parentLayer.position.value.x * (precomLayer.scale.value.x / 100),
        parentLayer.position.value.y * (precomLayer.scale.value.y / 100),
      ),
    );

    const assetID = precomLayer.state.composition.id;
    const assetNode = (toolkit.scenes[sceneIndex] as Scene).getNodeById(assetID);

    assetNode?.removeFromGraph();
    precomLayer.exciseLayer();
    parentLayer.setDrawOrder(precompDrawOrder, newDrawOrder > 0);

    setSelectedIdAfterCreated(parentLayer.nodeId);
  } else {
    precomLayer.setName(precomLayer.name.replace(/^Precomp Layer/u, ''));
    renameNestedScenes();

    precomLayer.setDrawOrder(newDrawOrder);

    setSelectedIdAfterCreated(precomLayer.nodeId);
    adjustPrecompAssetSize(toolkit.scenes[sceneIndex] as Scene, precomLayer);
  }

  setProjectInfo({ name: jsonFileName as string });

  emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
};
export const uploadJSON = async (json: unknown, name?: string): Promise<void> => {
  stateHistory.beginAction();
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const jsonFileName = name || useCreatorStore.getState().project.info.name;

  const options = {
    animation: json,
    enableNodeIds: true,
    standardizeTimeline: true,
  };

  const precomLayer = await (toolkit.scenes[sceneIndex] as Scene).importAsPrecompositionLayer(
    'com.lottiefiles.lottie',
    options,
  );

  await processImportedLotties(precomLayer, jsonFileName);
  stateHistory.endAction();
};

export const uploadDotLottie = async (dotLottie: unknown, name?: string): Promise<void> => {
  stateHistory.beginAction();

  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const jsonFileName = name || useCreatorStore.getState().project.info.name;

  const options = {
    dotlottie: dotLottie,
    enableNodeIds: true,
    standardizeTimeline: true,
  };

  const precomLayer = await (toolkit.scenes[sceneIndex] as Scene).importAsPrecompositionLayer(
    'com.lottiefiles.dotlottie',
    options,
  );

  await processImportedLotties(precomLayer, jsonFileName);

  stateHistory.endAction();
};

export const uploadSVG = async (svgString: string, name?: string): Promise<void> => {
  stateHistory.beginAction();
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (!scene) return;

  const jsonFileName = name || useCreatorStore.getState().project.info.name;

  const width = (scene.state.properties.sz as SizeJSON).w;
  const height = (scene.state.properties.sz as SizeJSON).h;

  const { fr, op } = useCreatorStore.getState().toolkit.json?.timeline.properties as PropertiesJSON;
  const [frameRate, endFrame] = [fr, op];
  const options: SvgImportOptions = {
    svgString,
    importOptions: {
      parsingOptions: {
        dpi: 72,
        maxFrames: endFrame as number,
        minFrames: endFrame as number,
        frameRate: frameRate as number,
        screenSize: new Size(width / 2, height / 2),
        removeRedundantRoot: true,
      },
      sceneOptions: {
        author: '',
        description: '',
        keywords: [],
        name: 'Shape Layer - SVG',
      },
      defaultNames: true,
      reduceLayers: true,
    },
  };

  const svgScene = await importSVG(toolkit, options);
  const sceneSize = svgScene.size;
  const svgLayers = svgScene.layers;

  svgLayers.forEach((svgLayer) => {
    const newDrawOrder = getSelectedDrawOrder() as number;

    // set draw order initially after creating shape layer
    const parentLayer = scene.createShapeLayer().setDrawOrder(newDrawOrder).setName(svgScene.name);

    parentLayer.fromJSON(svgLayer.state as ShapeLayerJSON);

    parentLayer.setDrawOrder(newDrawOrder);
    parentLayer.setPosition(new Vector(width / 2 - sceneSize.width / 2, height / 2 - sceneSize.height / 2));

    const svgId = parentLayer.nodeId;

    setSelectedIdAfterCreated(svgId);
  });

  toolkit.removeScene(svgScene);

  useCreatorStore.getState().ui.resetSelection();

  // Reset layer map
  useCreatorStore.getState().ui.resetLayerUI();
  useCreatorStore.getState().timeline.clearTabState();

  setProjectInfo({ name: jsonFileName as string });

  emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
  stateHistory.endAction();
};
