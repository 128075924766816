/**
 * Copyright 2022 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';
import React from 'react';
import { createRoot } from 'react-dom/client';

import type { Plugin } from '../Plugin';
import { PluginUIType } from '../Plugin';
import { getPluginManager } from '../PluginManager';

import { PluginUI, SidebarPluginUI } from '~/features/plugin-ui';

export function showUi(plugin: Plugin): void {
  // Inject the source of the HTML page as a string into the QuickJS environment
  const uiSource = plugin.scope.manage(plugin.vm.newString(plugin.uiSource));

  plugin.vm.setProp(plugin.vm.global, 'HTML', uiSource);

  // Displays the UI
  // TODO: only allow one instance of UI per plugin
  const showUiHandle = plugin.scope.manage(
    plugin.vm.newFunction('show', (html, opt?: QuickJSHandle) => {
      if (plugin.vm.typeof(html) !== 'string') {
        plugin.vm.throw(new Error('argument should be of type string'));

        return;
      }

      const options = {
        width: 0,
        height: 0,
      };

      if (opt) {
        const newOptions = plugin.vm.dump(opt);

        if (typeof newOptions === 'object') {
          const width = newOptions.width;
          const height = newOptions.height;

          let finalWidth = 0;

          if (typeof width === 'number' && !Number.isNaN(width)) {
            finalWidth = width;
          }

          let finalHeight = 0;

          if (typeof height === 'number' && !Number.isNaN(height)) {
            finalHeight = height;
          }

          if (finalWidth > 0) {
            options.width = finalWidth;
          }

          if (finalHeight > 0) {
            options.height = finalHeight;
          }
        }
      }

      // Max limits
      options.width = Math.min(options.width, 900);
      options.height = Math.min(options.height, 900);

      const src = plugin.vm.getString(html);

      html.dispose();

      // Setting up callbacks for the ready message
      const callback = (message: MessageEvent): void => {
        if (message.source !== plugin._uiIframe?.contentWindow) {
          return;
        }

        if (message.data.type === 'ready') {
          message.source?.postMessage(
            {
              type: 'bootstrap',
              html: src,
            },
            '*',
          );
        }
        // Cleanup after ourselves.
        window.removeEventListener('message', callback);
      };

      window.addEventListener('message', callback);

      const comp = React.createElement(
        plugin.pluginOptions.uiType === PluginUIType.FloatingUI ? PluginUI : SidebarPluginUI,
        {
          id: plugin.manifest.id,
          title: plugin.manifest.name,
          src,
          isDevPlugin: plugin.devPlugin,
          uiType: plugin.pluginOptions.uiType,
          onReload: () => {
            if (!plugin.devPlugin) {
              return;
            }
            getPluginManager().reloadDevPlugin();
          },
          options,
          onClose: () => {
            const pluginManager = getPluginManager();

            if (plugin.devPlugin) {
              pluginManager.unloadDevPlugin();
            } else {
              pluginManager.unload(plugin.manifest.id);
            }
          },
          host: plugin.metadata.host,
          withIframe: (iframe) => {
            plugin._uiIframe = iframe;
          },
        },
      );

      const domRoot = document.createElement('div');

      plugin._uiRootElement = domRoot;

      if (
        plugin.pluginOptions.uiType === PluginUIType.SidebarUI ||
        plugin.pluginOptions.uiType === PluginUIType.PresetsPanelUI
      ) {
        domRoot.style.height = '100%';
      }

      // Note: This is also where we'll have to check for sidebar vs floating window vs other UI types. TBD

      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      document.querySelector(plugin.pluginOptions.uiType)!.appendChild(domRoot);

      const root = createRoot(domRoot);

      plugin._reactRoot = root;

      root.render(comp);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'show', { value: showUiHandle, configurable: false });
}

export function exitPlugin(plugin: Plugin): void {
  const exitPluginHandle = plugin.scope.manage(
    plugin.vm.newFunction('exitPlugin', () => {
      const pluginManager = getPluginManager();

      // allow message listener to finish dispatching message from the iframe
      setTimeout(() => {
        if (plugin.devPlugin) {
          pluginManager.unloadDevPlugin();
        } else {
          pluginManager.unload(plugin.manifest.id);
        }
      }, 100);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'exitPlugin', {
    value: exitPluginHandle,
    configurable: false,
  });
}
