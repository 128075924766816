/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useRef, useEffect, useCallback } from 'react';

import { TooltipWrapper } from '../Tooltip/TooltipWrapper';

import { AnchorBox } from '~/assets/icons';

interface AnchorToggleProps {
  anchorVisible?: boolean;
  onClick?: () => void;
}

export const AnchorToggle: React.FC<AnchorToggleProps> = ({ anchorVisible, onClick }) => {
  const onClickHandler = useRef(onClick);

  // store onclick handler
  useEffect(() => {
    onClickHandler.current = onClick;
  }, [onClick]);

  const handleOnClick = useCallback(() => {
    if (onClickHandler.current) {
      onClickHandler.current();
    }
  }, []);

  const color = anchorVisible ? 'text-white' : 'text-gray-500';

  return (
    <button onClick={handleOnClick} className="flex h-6 w-6 items-center justify-center" id="anchor-toggle">
      <TooltipWrapper label={'Anchor Point'} offset={{ mainAxis: 7, crossAxis: -40 }} shortcut="Y">
        <AnchorBox className={`h-4 w-4 text-white ${color}`} />
      </TooltipWrapper>
    </button>
  );
};
