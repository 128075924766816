/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

interface FrameProps {
  currentFrame: number;
  totalFrames: number;
}

export const FrameDisplay: React.FC<FrameProps> = ({ currentFrame, totalFrames }: FrameProps) => {
  return (
    <div className="ml-8 flex h-4 items-center self-center rounded-[5px] bg-gray-600 p-2 text-[10px] leading-[11.69px] text-gray-100">
      <div>#</div>
      <div className="pl-1 ">{`${currentFrame} / ${totalFrames}`}</div>
    </div>
  );
};
