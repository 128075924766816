/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Scalar as ToolkitScalar } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from '.';

const methodNames = {
  clone: 'clone',
  equals: 'equals',
  interpolate: 'interpolate',
  set: 'set',
  setValue: 'setValue',
  toArray: 'toArray',
  // toJSON: 'toJSON',
  toObject: 'toObject',
  toString: 'toString',
};

function getScalarMethods(plugin: Plugin, scalar: ToolkitScalar): ObjectMethods {
  return getObjectMethods(plugin, methodNames, scalar);
}

interface ScalarObject extends ValueObject {
  value: number;
}

export function createScalarObject(plugin: Plugin, scalar: ToolkitScalar): ScalarObject {
  const scalarObject = {
    value: scalar.value,
    valueType: 'Scalar',
  };

  registerObjectMethods(plugin, scalar, scalarObject, [getScalarMethods]);

  return scalarObject;
}
