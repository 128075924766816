/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, MutableRefObject, MouseEvent, TouchEvent } from 'react';
import React, { useEffect, useRef } from 'react';

import { TinyColor } from '../../utils';

import type { TPropsComp, TCoords } from './types';

const WIDTH = 200;
const HEIGHT = 150;

const Board: FC<TPropsComp> = ({ color, colorBoardHeight, onChange, onStop, rootPrefixCls, setSaturation }) => {
  const node = useRef() as MutableRefObject<HTMLDivElement>;

  const removeListeners = (): void => {
    window.removeEventListener('mousemove', onBoardDrag);
    window.removeEventListener('mouseup', onBoardDragEnd);
  };

  const removeTouchListeners = (): void => {
    window.removeEventListener('touchmove', onBoardTouchMove);
    window.removeEventListener('touchend', onBoardTouchEnd);
  };

  useEffect(() => {
    return () => {
      removeListeners();
      removeTouchListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBoardMouseDown = (event: MouseEvent): void => {
    event.preventDefault();

    const buttons = event.buttons;

    if (buttons !== 1) return;

    removeListeners();

    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({ x, y });

    window.addEventListener('mousemove', onBoardDrag);
    window.addEventListener('mouseup', onBoardDragEnd);
  };

  const onBoardTouchStart = (event: TouchEvent): void => {
    if (event.cancelable) {
      event.preventDefault();
    }

    if (event.touches.length !== 1) {
      return;
    }

    removeTouchListeners();

    const x = event.targetTouches[0]?.clientX;
    const y = event.targetTouches[0]?.clientY;

    if (x && y) pointMoveTo({ x, y });

    window.addEventListener('touchmove', onBoardTouchMove, { passive: false });
    window.addEventListener('touchend', onBoardTouchEnd, { passive: false });
  };

  const onBoardTouchMove = (event: any): void => {
    if (event.cancelable) {
      event.preventDefault();
    }

    const x = event.targetTouches[0].clientX;
    const y = event.targetTouches[0].clientY;

    pointMoveTo({
      x,
      y,
    });
    onChange(color);
  };

  const onBoardTouchEnd = (): void => {
    onStop(color);
    removeTouchListeners();
  };

  const onBoardDrag = (event: any): void => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({
      x,
      y,
    });
    onChange(color);
  };

  const onBoardDragEnd = (event: any): void => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;

    pointMoveTo({
      x,
      y,
    });
    onStop(color);
    removeListeners();
  };

  const getPrefixCls = (): string => {
    return `${rootPrefixCls}-board`;
  };

  const pointMoveTo = (pos: TCoords): void => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!node.current) return;
    const rect = node.current.getBoundingClientRect();
    let left = pos.x - rect.left;
    let top = pos.y - rect.top;

    const rWidth = rect.width || WIDTH;
    const rHeight = rect.height || HEIGHT;

    left = Math.max(0, left);
    left = Math.min(left, rWidth);
    top = Math.max(0, top);
    top = Math.min(top, rHeight);

    color.saturation = left / rWidth;
    color.brightness = 1 - top / rHeight;
    if (setSaturation) setSaturation(color.saturation);
  };

  const prefixCls = getPrefixCls();

  const hueHsv = {
    h: color.hue,
    s: 1,
    v: 1,
  };

  const hueColor = new TinyColor(hueHsv).toHexString();

  return (
    <div className={prefixCls} ref={node}>
      <div
        className={`${prefixCls}-hsv`}
        style={{
          backgroundColor: hueColor,
          height: `${colorBoardHeight}px`,
          minHeight: `${colorBoardHeight}px`,
        }}
      >
        <div className={`${prefixCls}-value`} />
        <div className={`${prefixCls}-saturation`} />
      </div>
      <span
        onMouseDown={onBoardMouseDown}
        onTouchStart={onBoardTouchStart}
        style={{
          left: `calc(${color.saturation * 100}% - 7px)`,
          top: `calc(${(1 - color.brightness) * 100}% - 7px)`,
          backgroundColor: color.toHexString(),
        }}
      />

      <div className={`${prefixCls}-handler`} onMouseDown={onBoardMouseDown} onTouchStart={onBoardTouchStart} />
    </div>
  );
};

export default Board;
