/**
 * Copyright 2022 Design Barn Inc.
 */

import { get } from 'lodash-es';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PropertyRow } from './ui/PropertyRow';

import { GradientStartPoint, GradientEndPoint } from '~/assets/icons';
import { NumberInput, defaultStyle } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { GradientPoint } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const GradientPoints: React.FC = () => {
  const [startX, startY, endX, endY] = useCreatorStore(
    (state) => [
      get(state.toolkit.currentGFillShape, 'gsX'),
      get(state.toolkit.currentGFillShape, 'gsY'),
      get(state.toolkit.currentGFillShape, 'geX'),
      get(state.toolkit.currentGFillShape, 'geY'),
    ],
    shallow,
  );

  const handlePoints = useCallback((result: NumberResult) => {
    useCreatorStore.getState().toolkit.setGradientPoint(result.name, result.value);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
  }, []);

  return (
    <>
      <div className="mt-2 flex w-[176px]">
        <Tooltip
          bgColorClass="bg-[#000000]"
          offset={{ mainAxis: 7, crossAxis: -9 }}
          label="Start point (X)"
          placement="bottom"
        >
          <div className="w-[87px] rounded-r">
            <NumberInput
              styleClass={{ label: `${defaultStyle.label} rounded-r-none` }}
              name={GradientPoint.StartX}
              value={startX}
              label={<GradientStartPoint className="h-4 w-4" />}
              min={0}
              precision={1}
              onChange={handlePoints}
            />
          </div>
        </Tooltip>
        <div className="my-[-1px] border border-gray-800"></div>

        <Tooltip
          bgColorClass="bg-[#000000]"
          offset={{ mainAxis: 7, crossAxis: -9 }}
          label="Start point (Y)"
          placement="bottom"
        >
          <div className="w-[87px] rounded-r">
            <NumberInput
              styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
              name={GradientPoint.StartY}
              value={startY}
              min={0}
              precision={1}
              onChange={handlePoints}
              showKeyframe
            />
          </div>
        </Tooltip>
      </div>
      <div className="mt-2 flex w-[176px]">
        <Tooltip
          bgColorClass="bg-[#000000]"
          offset={{ mainAxis: 7, crossAxis: -9 }}
          label="End point (X)"
          placement="bottom"
        >
          <div className="w-[87px] rounded-r">
            <NumberInput
              styleClass={{ label: `${defaultStyle.label} rounded-r-none` }}
              name={GradientPoint.EndX}
              value={endX}
              label={<GradientEndPoint className="h-4 w-4" />}
              min={0}
              precision={1}
              onChange={handlePoints}
            />
          </div>
        </Tooltip>
        <div className="my-[-1px] border border-gray-800"></div>

        <Tooltip
          bgColorClass="bg-[#000000]"
          offset={{ mainAxis: 7, crossAxis: -9 }}
          label="End point (Y)"
          placement="bottom"
        >
          <div className="w-[87px] rounded-r">
            <NumberInput
              styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
              name={GradientPoint.EndY}
              value={endY}
              min={0}
              precision={1}
              onChange={handlePoints}
              showKeyframe
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export const GradientPointsProperty: React.FC = () => {
  return (
    <PropertyRow title={'Gradient Points'}>
      <GradientPoints />
    </PropertyRow>
  );
};
