/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import { useJitsu } from '@jitsu/react';
import React, { Fragment, useRef } from 'react';
// import { shallow } from 'zustand/shallow';

import { CreatorMenuList } from '../constant';

import { MenuInteraction } from './MenuInteraction';
import { SubMenu } from './SubMenu';

import { LottieDropdownExpand, LottieDropdownArrow, LottieFilesLight } from '~/assets/icons';
// import { MenuType } from '~/data/constant';
import { useClickOutside } from '~/hooks';
// import { useCreatorStore } from '~/store';
// import { SavingState } from '~/store/projectSlice';
import type { EventStorePayload } from '~/utils';

export interface MenuDropdownProp {
  callback: (arg?: unknown) => void;
  children?: MenuDropdownProp[];
  disabled?: boolean;
  isDivider?: boolean;
  label: string;
  shortcut?: string;
  trackParams?: EventStorePayload;
  type: string;
}

export const CreatorMenu: React.FC = () => {
  const { track } = useJitsu();
  // const [savingState, isDraft] = useCreatorStore(
  //   (state) => [state.project.info.savingState, state.project.info.isDraft],
  //   shallow,
  // );

  const [isOpen, setOpen] = React.useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => setOpen(false));

  const handleClick = (callback: () => void, eventParams?: EventStorePayload): void => {
    if (eventParams) {
      track(eventParams.event_type, {
        ...eventParams,
      });
    }
    callback();
  };

  // Note: No need to change label/shortcut for Save anymore.
  // The save from here is always to save to a new location
  // const displayLabel = useCallback(
  //   (label: string, dropdownType: string) => {
  //     if (dropdownType === MenuType.SaveToCreator && !isDraft) {
  //       if ([SavingState.SAVED, SavingState.SAVING].includes(savingState as string)) return 'Save';
  //       else return label;
  //     } else return label;
  //   },
  //   [savingState, isDraft],
  // );

  // const displayShortcut = useCallback(
  //   (shortcut: string, dropdownType: string) => {
  //     if (dropdownType === MenuType.SaveToCreator && !isDraft) {
  //       if (savingState === SavingState.SAVED) return 'All changes saved';
  //       else if (savingState === SavingState.SAVING) return 'Saving changes';
  //       else return shortcut;
  //     } else return shortcut;
  //   },
  //   [savingState, isDraft],
  // );

  return (
    <>
      <Menu as="div" className="relative ml-1" ref={menuRef}>
        <Menu.Button
          data-testid="creator-menu-dropdown"
          onClick={() => setOpen(!isOpen)}
          className="group flex cursor-pointer items-center justify-center rounded stroke-gray-500 hover:bg-gray-700  "
        >
          <LottieFilesLight className="mr-1 h-full w-[24px]" />
          <LottieDropdownArrow
            className={`h-3 w-3 cursor-pointer stroke-current text-gray-500 hover:text-white group-hover:stroke-white ${
              isOpen ? '!text-white' : ''
            }`}
          />
        </Menu.Button>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-dropdown mt-1 w-[240px] cursor-pointer rounded-lg border border-gray-600 bg-gray-800 p-2 shadow-dropdown focus:outline-none">
            {CreatorMenuList.map((dropdown, index) => {
              const { callback, isDivider, label, trackParams } = dropdown;

              return (
                <div
                  key={`${label}_${index}`}
                  className={`relative rounded hover:bg-gray-600 ${isDivider ? 'left-[-8px] w-[104%]' : ''}`}
                >
                  <Menu.Item>
                    <MenuInteraction>
                      {({ hover }) => (
                        <>
                          {!isDivider && (
                            <>
                              <button
                                data-testid={`creator-menu-dropdown-${dropdown.type}`}
                                className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                                  index === CreatorMenuList.length - 1 && 'rounded-b'
                                } p-2 text-xs text-white `}
                                onClick={() => handleClick(callback, trackParams)}
                              >
                                {/* {displayLabel(dropdown.label, type)} */}
                                {dropdown.label}
                                {dropdown.children && dropdown.children.length > 0 && (
                                  <>
                                    <LottieDropdownExpand className="absolute right-[7px] mt-[1.5px] h-4 w-4" />
                                  </>
                                )}
                                {!dropdown.children && (
                                  <span className="absolute right-[7px] text-gray-500">
                                    {dropdown.shortcut}
                                    {/* {displayShortcut(dropdown.shortcut as string, type)} */}
                                  </span>
                                )}
                              </button>
                              {hover && dropdown.children && dropdown.children.length > 0 && (
                                <>
                                  <SubMenu subItems={dropdown.children} />
                                </>
                              )}
                            </>
                          )}

                          {isDivider && index !== CreatorMenuList.length - 1 && (
                            <>
                              <div
                                className="my-2 h-[1px] w-full bg-gray-700"
                                style={{
                                  width: '100%',
                                }}
                              ></div>
                            </>
                          )}
                        </>
                      )}
                    </MenuInteraction>
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
