/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

/** Custom Dnd **/
// Wrap dnd core events, to prevent auto dragging when interacts with inputs, modals, etc.
// Usage: Add data-no-dnd="true" to the parent node and all the descendant nodes will not handle the dnd events.

import {
  MouseSensor as LibMouseSensor,
  KeyboardSensor as LibKeyboardSensor,
  PointerSensor,
  TouchSensor,
} from '@dnd-kit/core';
import type { MouseEvent, KeyboardEvent, PointerEvent, TouchEvent } from 'react';

import { useCreatorStore } from '~/store';

const shouldHandleEvent = (element: HTMLElement | null): boolean => {
  let cur = element;

  while (cur) {
    const { noDnd } = cur.dataset;

    if (noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
};

export class CustomMouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class CustomKeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: ({ nativeEvent: event }: KeyboardEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export const findNearestWithDataLayerId = (element: HTMLElement, selector: string): string | null => {
  const nearestWithDataLayerId = element.querySelector(`[${selector}]`);

  if (nearestWithDataLayerId) {
    return nearestWithDataLayerId.getAttribute(selector);
  } else if (element.parentElement) {
    return findNearestWithDataLayerId(element.parentElement) as string | null;
  } else {
    return null;
  }
};

export class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;
        const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

        const marginTop = 0;

        if (event.pointerType === 'mouse' && event.button === 2 && event.target) {
          const nearestWithDataLayerId =
            event.target.dataset?.layerid || findNearestWithDataLayerId(event.target, 'data-layerid');

          addToSelectedNodes([nearestWithDataLayerId], true);

          if (nearestWithDataLayerId) {
            const { height, top } = event.target.getBoundingClientRect();

            const timelineContainer = document.getElementById('timeline-container');

            const timelineTop = timelineContainer?.getBoundingClientRect();

            if (timelineTop) {
              const positionY = top - timelineTop.top;

              const refId = event.target.dataset?.refid || findNearestWithDataLayerId(event.target, 'data-layerid');

              setTimelineContext({
                selectedId: nearestWithDataLayerId,
                referenceId: refId,
                menuOpened: true,
                mousePos: {
                  x: event.clientX,
                  y: Number(positionY) + Number(height) + marginTop,
                  mousePosY: event.clientY,
                },
              });
            }
          }
        } else if (event.target) {
          const { height, top } = event.target.getBoundingClientRect();

          const timelineContainer = document.getElementById('timeline-container');

          const timelineTop = timelineContainer?.getBoundingClientRect();

          if (timelineTop) {
            const positionY = top - timelineTop.top;

            setTimelineContext({
              mousePos: {
                x: event.clientX,
                y: Number(positionY) + Number(height) + marginTop,
                mousePosY: event.clientY,
              },
            });
          }
        }

        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class CustomTouchSensor extends TouchSensor {
  static activators = [
    {
      eventName: 'onTouchStart' as const,
      handler: ({ nativeEvent: event }: TouchEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}
