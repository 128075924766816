/**
 * Copyright 2022 Design Barn Inc.
 */

import { BufferAttribute, BufferGeometry, Float32BufferAttribute, LineBasicMaterial, LineSegments, DoubleSide } from 'three';

import type { Box3 } from './Box3';

class Box3Helper extends LineSegments {
  public box: Box3;

	public constructor( box: Box3, color = 0xffff00 ) {

		const indices = new Uint16Array( [ 0, 1, 1, 2, 2, 3, 3, 0, 4, 5, 5, 6, 6, 7, 7, 4, 0, 4, 1, 5, 2, 6, 3, 7 ] );

		const positions = [ 1, 1, 1, - 1, 1, 1, - 1, - 1, 1, 1, - 1, 1, 1, 1, - 1, - 1, 1, - 1, - 1, - 1, - 1, 1, - 1, - 1 ];

		const geometry = new BufferGeometry();

		geometry.setIndex( new BufferAttribute( indices, 1 ) );

		geometry.setAttribute( 'position', new Float32BufferAttribute( positions, 3 ) );

		super( geometry, new LineBasicMaterial( { color, side: DoubleSide, transparent: true, depthTest: false, depthWrite: false, fog: false, toneMapped: false } ) );
		this.renderOrder = Infinity;

		this.frustumCulled = false;
		this.box = box;

		this.type = 'Box3Helper';

		this.geometry.computeBoundingSphere();

	}

	public update(): void {
		const box = this.box;

    const {max, maxXminY, min, minXmaxY} = box;
    const positions = [max.x, max.y, max.z, minXmaxY.x, minXmaxY.y, minXmaxY.z, min.x, min.y, min.z, maxXminY.x, maxXminY.y, maxXminY.z];

    this.geometry.setAttribute('position', new Float32BufferAttribute( [...positions, ...positions], 3 ))
	}

	public override updateMatrixWorld( force: boolean ): void {

		super.updateMatrixWorld( force );

	}

}

export { Box3Helper };
