/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Cross } from '~/assets/icons';

export interface TextInputProps {
  icon?: React.ReactNode;
  onChange: (text: string) => void;
  placeholder?: string;
  value: string;
}

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const [isFocus, setIsFocus] = React.useState(false);

  return (
    <div className="relative dark:text-white">
      {props.icon}
      <input
        className={`w-full rounded-md border bg-gray-600 py-[2px] pr-7 ${
          props.icon ? 'pl-8' : ''
        } border-transparent text-xs focus:border-lf-teal focus:ring-0`}
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(ev) => props.onChange(ev.target.value)}
      ></input>
      {props.value.length > 0 && isFocus && (
        <Cross
          className="absolute right-0 top-[1px] my-1 mr-1 h-5 w-6 cursor-pointer fill-current dark:text-white"
          onMouseDown={(ev) => ev.preventDefault()}
          onClick={() => props.onChange('')}
          data-testid="cross-icon"
        ></Cross>
      )}
    </div>
  );
};
