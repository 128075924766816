/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import React from 'react';

import { Tooltip } from '../Tooltip';

export interface BarButtonProps {
  activeIcon: React.ReactElement;
  inactiveIcon: React.ReactElement;
  isSelected?: boolean;
  offset?: { crossAxis: number; mainAxis: number };
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  testID?: string;
  tooltipProps: {
    label: string;
    placement: Placement;
    shortcut?: string;
  };
}

export const BarButton: React.FC<BarButtonProps> = ({
  activeIcon,
  inactiveIcon,
  isSelected,
  onClick,
  testID,
  tooltipProps,
}) => {
  return (
    <div className="relative">
      <Tooltip offset={{ mainAxis: 7, crossAxis: 0 }} {...tooltipProps}>
        <button
          data-testid={testID}
          className="group flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded hover:bg-gray-700"
          onClick={onClick}
        >
          {React.cloneElement(isSelected ? activeIcon : inactiveIcon, {
            className: 'h-[16px] w-[16px] fill-current text-gray-400 group-hover:text-white group-hover:bg-gray-700',
          })}
        </button>
      </Tooltip>
    </div>
  );
};

export const PanelButton: React.FC<BarButtonProps> = (props) => {
  return (
    <div>
      <BarButton {...props} />
    </div>
  );
};
