/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Scene, PrecompositionLayer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  height: 'height',
  size: 'size',
  width: 'width',
  setHeight: 'setHeight',
  setSize: 'setSize',
  setWidth: 'setWidth',
};

export function getSizableMethods(plugin: Plugin, scene: Scene | PrecompositionLayer): ObjectMethods {
  return getObjectMethods(plugin, methodNames, scene);
}
