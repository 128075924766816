/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useRef } from 'react';
import { Vector3 } from 'three';
import { shallow } from 'zustand/shallow';

import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberResult, NumberInputOption } from '~/components/Elements/Input';
import { MIN_X, MAX_X, MIN_Y, MAX_Y } from '~/data/range';
import { updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { TransformType } from '~/lib/threejs/TransformControls';
import { AnimatedType, removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const positionOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: 'x',
    label: 'X',
    min: MIN_X,
    max: MAX_X,
  },
  y: {
    name: 'y',
    label: 'Y',
    min: MIN_Y,
    max: MAX_Y,
  },
};

export const TransformPositionField: React.FC = () => {
  const [
    x,
    y,
    currentKeyframe,
    setAnimatedValue,
    addAnimatedValue,
    setStaticValue,
    { position },
    selectedNodesInfo,
    groupTransform,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentTransform.position[0],
      state.toolkit.currentTransform.position[1],
      state.toolkit.currentTransform.positionCurrentKeyframe,
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
      state.toolkit.setStaticValue,
      state.ui.selectedNodeTransform,
      state.ui.selectedNodesInfo,
      state.ui.selectedGroupTransform,
    ],
    shallow,
  );

  const multiSelect = selectedNodesInfo.length > 1;
  const prevX = useRef<null | number>(null);
  const prevY = useRef<null | number>(null);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      if (result.name === 'x') {
        if (prevX.current === null) prevX.current = result.value;
        if (multiSelect) {
          updateGroupFromUI(TransformType.Translation, new Vector3(result.value - prevX.current, 0, 0));
          prevX.current = result.value;
        } else {
          setAnimatedValue(AnimatedType.POSITION, [result.value, y], selectedNodesInfo[0]?.nodeId);
        }
      } else if (result.name === 'y') {
        if (prevY.current === null) prevY.current = result.value;
        if (multiSelect) {
          updateGroupFromUI(TransformType.Translation, new Vector3(0, result.value - prevY.current, 0));
          prevY.current = result.value;
        } else {
          setAnimatedValue(AnimatedType.POSITION, [x, result.value], selectedNodesInfo[0]?.nodeId);
        }
      }
      emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
    },
    [multiSelect, y, selectedNodesInfo, setAnimatedValue, x],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyframe) {
      removeKeyFrame(currentKeyframe);
      setStaticValue(AnimatedType.POSITION, [x, y]);
    } else {
      addAnimatedValue(AnimatedType.POSITION, [x, y]);
    }
    stateHistory.endAction();
    emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
  }, [x, y, addAnimatedValue, currentKeyframe, setStaticValue]);

  let leftValue = position ? position.x : x;
  let rightValue = position ? position.y : y;

  if (multiSelect && groupTransform.position) {
    leftValue = groupTransform.position.x;
    rightValue = groupTransform.position.y;
  }

  prevX.current = leftValue;
  prevY.current = rightValue;

  return (
    <>
      <div className="mr-6 flex">
        <DualNumberInput
          showKeyframe
          onChange={handleOnChange}
          onKeyframeClick={handleKeyframeClick}
          hasKeyframe={currentKeyframe !== ''}
          left={leftValue}
          right={rightValue}
          leftMin={positionOption.x.min || MIN_X}
          leftMax={positionOption.x.max || MAX_X}
          rightMax={positionOption.y.max || MAX_Y}
          rightMin={positionOption.y.min || MIN_Y}
          leftOption={positionOption.x}
          rightOption={positionOption.y}
          tooltip={{ left: 'Position (X)', right: 'Position (Y)' }}
        />
      </div>
    </>
  );
};
