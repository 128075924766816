/**
 * Copyright 2022 Design Barn Inc.
 */

import { useFeature } from '@growthbook/growthbook-react';
import type React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { GB_CONSTANT } from '~/config/growthbook';
import { ToolType } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { saveToWorkSpace, exportJSON, saveToCreator } from '~/lib/function/menu';
import { useCreatorStore } from '~/store';
import { isMacOS } from '~/utils';

const setCurrentTool = useCreatorStore.getState().ui.setCurrentTool;
const setPivotVisibility = useCreatorStore.getState().ui.setPivotVisibility;

let pivotVisible = false;

useCreatorStore.subscribe((state) => {
  pivotVisible = state.ui.pivotVisible;
});
// Define the hotkey using window format. Use key(hotkey) to determine the correct format
enum HotKey {
  BACKTICK = '`',
  COPY = 'ctrl+c',
  CUT = 'ctrl+x',
  DELETE = 'backspace, del',
  DESELECT_ALL = 'esc',
  DOWNLOAD_LOTTIE_JSON = 'ctrl+e',
  DUPLICATE = 'ctrl+d',
  ELLIPSE = 'o',
  ESCAPE = 'escape',
  GOTO_FIRST_FRAME = 'shift+alt+,,pageup',
  GOTO_LAST_FRAME = 'shift+alt+.,pagedown',
  GOTO_NEXT_FRAME = '.',
  GOTO_PREVIOUS_FRAME = ',',
  GO_BACK_TEN_FRAMES = 'shift+,',
  GO_FORWARD_TEN_FRAMES = 'shift+.',
  HAND = 'h',
  LAYER_DOWN = '[',
  LAYER_UP = ']',
  LINE = 'l',
  MOVE = 'v',
  NUDGE_DOWN = 'down',
  NUDGE_LEFT = 'left',
  NUDGE_RIGHT = 'right',
  NUDGE_UP = 'up',
  PASTE = 'ctrl+v',
  PEN = 'p',
  PIVOT = 'y',
  RECTANGLE = 'r',
  REDO = 'ctrl+y,ctrl+shift+z',
  RENAME = 'enter',
  SAVE_TO_CREATOR = 'ctrl+s',
  SAVE_TO_WORKFLOW = 'shift+ctrl+s',
  SELECT_ALL_LAYERS = 'ctrl+a',
  SHOW_ALL_KEYFRAMES = 'u',
  SHOW_ANIMATED_PROPERTIES = 'shift+k',
  SPACE = 'space',
  TEXT = 't',
  TOGGLE_ANIMATE_ALL_PROPERTIES = 'ctrl+shift+k',
  UNDO = 'ctrl+z',
  ZOOMIN = 'ctrl+=',
  ZOOMOUT = 'ctrl+-',
  ZOOMTOFIT = 'ctrl+0',
}

function key(shortcut: string): string {
  if (!isMacOS) {
    return shortcut;
  }

  return shortcut.replace(/ctrl/gu, 'cmd');
}

export const HotkeyListener: React.FC = () => {
  const showPenTool = useFeature(GB_CONSTANT.SHOW_PEN_TOOL).on;

  // IMPORTANT: use event emitter or zustand action to trigger action
  // Don't call perform state/props change here to prevent rerendering as this is place at the top of the component tree
  // Changing state will cause rerender of the whole component tree
  useHotkeys(key(HotKey.UNDO), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.UI_UNDO);
  });
  useHotkeys(key(HotKey.REDO), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.UI_REDO);
  });
  useHotkeys(HotKey.DELETE, () => emitter.emit(EmitterEvent.UI_DELETE));
  useHotkeys(key(HotKey.DESELECT_ALL), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_DESELECT_ALL);
  });
  useHotkeys(key(HotKey.ELLIPSE), () => emitter.emit(EmitterEvent.ELLIPSE_CREATED));
  useHotkeys(key(HotKey.HAND), () => setCurrentTool(ToolType.Hand));
  useHotkeys(key(HotKey.LINE), () => emitter.emit(EmitterEvent.LINE_CREATED));
  useHotkeys(key(HotKey.MOVE), () => setCurrentTool(ToolType.Move));
  useHotkeys(
    key(HotKey.PEN),
    () => {
      if (showPenTool) {
        setCurrentTool(ToolType.Pen);
      }
    },
    [showPenTool],
  );
  useHotkeys(key(HotKey.RECTANGLE), () => emitter.emit(EmitterEvent.RECTANGLE_CREATED));
  useHotkeys(key(HotKey.TEXT), () => setCurrentTool(ToolType.Text));
  useHotkeys(key(HotKey.ESCAPE), () => emitter.emit(EmitterEvent.CANVAS_DISABLE_PENCONTROL));
  useHotkeys(key(HotKey.ZOOMIN), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_ZOOM_IN);
  });
  useHotkeys(key(HotKey.ZOOMOUT), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_ZOOM_OUT);
  });
  useHotkeys(key(HotKey.ZOOMTOFIT), () => emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT));

  useHotkeys(key(HotKey.SAVE_TO_WORKFLOW), (event: KeyboardEvent) => {
    event.preventDefault();
    saveToWorkSpace();
  });

  useHotkeys(key(HotKey.SAVE_TO_CREATOR), (event: KeyboardEvent) => {
    event.preventDefault();
    saveToCreator({ saveWithoutPopup: true });
  });

  useHotkeys(key(HotKey.SELECT_ALL_LAYERS), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_SELECT_ALL_LAYERS);
  });

  useHotkeys(key(HotKey.DOWNLOAD_LOTTIE_JSON), (event: KeyboardEvent) => {
    event.preventDefault();
    exportJSON();
  });

  useHotkeys(key(HotKey.SPACE), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TOGGLE_PLAY);
  });

  useHotkeys(key(HotKey.GOTO_FIRST_FRAME), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_FIRST_FRAME);
  });

  useHotkeys(key(HotKey.GOTO_LAST_FRAME), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_LAST_FRAME);
  });

  useHotkeys(key(HotKey.GOTO_NEXT_FRAME), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_NEXT_FRAME);
  });

  useHotkeys(key(HotKey.GOTO_PREVIOUS_FRAME), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_FRAME);
  });

  useHotkeys(key(HotKey.GO_BACK_TEN_FRAMES), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GO_BACK_TEN_FRAME);
  });

  useHotkeys(key(HotKey.GO_FORWARD_TEN_FRAMES), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GO_FORWARD_TEN_FRAME);
  });

  useHotkeys(key(HotKey.CUT), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_CUT);
  });
  useHotkeys(key(HotKey.COPY), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_COPY);
  });
  useHotkeys(key(HotKey.DUPLICATE), (event: KeyboardEvent) => {
    // ctrl + D opens a browser window to edit the bookmarks by default.
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_DUPLICATE);
  });
  useHotkeys(key(HotKey.PASTE), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_PASTE);
  });
  useHotkeys(key(HotKey.PIVOT), (event: KeyboardEvent) => {
    event.preventDefault();
    setPivotVisibility(!pivotVisible);
  });
  useHotkeys(key(HotKey.NUDGE_DOWN), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.SHAPE_MOVE_DOWN);
  });
  useHotkeys(key(HotKey.NUDGE_LEFT), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.SHAPE_MOVE_LEFT);
  });
  useHotkeys(key(HotKey.NUDGE_RIGHT), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.SHAPE_MOVE_RIGHT);
  });
  useHotkeys(key(HotKey.NUDGE_UP), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.SHAPE_MOVE_UP);
  });
  useHotkeys(key(HotKey.LAYER_DOWN), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_DOWN);
  });
  useHotkeys(key(HotKey.LAYER_UP), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_UP);
  });
  useHotkeys(key(HotKey.SHOW_ANIMATED_PROPERTIES), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_SHOW_ANIMATED_PROPERTIES);
  });
  useHotkeys(key(HotKey.SHOW_ALL_KEYFRAMES), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES);
  });
  useHotkeys(key(HotKey.TOGGLE_ANIMATE_ALL_PROPERTIES), (event: KeyboardEvent) => {
    event.preventDefault();

    const setAnimatedPropertyPopupUI = useCreatorStore.getState().ui.setAnimatedPropertyPopupUI;
    const toggleAll = useCreatorStore.getState().ui.animatedPropertyPopupUI.enableAll;

    setAnimatedPropertyPopupUI({ enableAll: !toggleAll });

    emitter.emit(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES);
  });
  useHotkeys(key(HotKey.RENAME), (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_RENAME_LAYER_START);
  });

  useHotkeys(key(HotKey.BACKTICK), (event: KeyboardEvent) => {
    event.preventDefault();

    emitter.emit(EmitterEvent.TIMELINE_TOGGLE_VISIBLE);
  });

  return null;
};
