/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';
import type { Viewport, Editor } from '~/features/canvas';
import type { PathControls } from '~/lib/threejs/PathControls';

export interface CanvasSlice {
  canvas: {
    editor: Editor | null;
    pathControls: PathControls | null;
    setEditor: (editor: Editor | null) => void;
    setViewport: (viewport: Viewport | null) => void;
    viewport: Viewport | null;
  };
}

export const createCanvasSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  CanvasSlice
> = (set) => ({
  canvas: {
    editor: null,
    pathControls: null,
    viewport: null,
    setEditor: (editor: Editor | null) => {
      set(() => {
        return {
          editor,
        };
      });
    },
    setViewport: (viewport: Viewport | null) => {
      set(() => {
        return {
          viewport,
        };
      });
    },
  },
});
