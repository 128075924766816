/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PrecompositionAsset, Scene, PrecompositionLayer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  compositionType: 'compositionType',
  hasTimeline: 'hasTimeline',
};

export function getHasTimelineMethods(
  plugin: Plugin,
  scene: Scene | PrecompositionLayer | PrecompositionAsset,
): ObjectMethods {
  return getObjectMethods(plugin, methodNames, scene);
}
