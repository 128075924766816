/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PrecompositionLayer, Scene, SizeJSON } from '@lottiefiles/toolkit-js';
import { Vector } from '@lottiefiles/toolkit-js';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit, renameNestedScenes, stateHistory } from '~/lib/toolkit';
import { getSelectedDrawOrder } from '~/lib/toolkit/helper';
import { useCreatorStore } from '~/store';
import type { AnimationLoader } from '~/store/uiSlice';
import { AnimationLoaderStatus } from '~/store/uiSlice';

export const removeAnimationLoader = (status: AnimationLoaderStatus): void => {
  const animationLoader = useCreatorStore.getState().ui.animationLoader;

  const setAnimationLoader = useCreatorStore.getState().ui.setAnimationLoader;
  const removeSelectedNode = useCreatorStore.getState().toolkit.removeSelectedNode;

  removeSelectedNode(animationLoader.objectId as string);

  setAnimationLoader({
    status,
  } as AnimationLoader);
};

export const resizePrecompLayer = (scene: Scene, precompLayer: PrecompositionLayer): void => {
  let precompWidth = (precompLayer.state.properties.sz as SizeJSON).w;
  let precompHeight = (precompLayer.state.properties.sz as SizeJSON).h;

  const width = (scene.state.properties.sz as SizeJSON).w;
  const height = (scene.state.properties.sz as SizeJSON).h;

  let widthScale = Math.ceil((width * 100) / precompWidth);
  let heightScale = Math.ceil((height * 100) / precompHeight);

  if (precompWidth > width || precompHeight > height) {
    heightScale = Math.ceil((precompLayer.transform.scale.valueAtKeyFrame(0).y * widthScale) / 100);
    widthScale = Math.ceil((precompLayer.transform.scale.valueAtKeyFrame(0).x * heightScale) / 100);

    const scale = Math.min(widthScale, heightScale);

    precompLayer.setScale(new Vector(scale, scale));

    precompWidth = (precompWidth * widthScale) / 100;
    precompHeight = (precompHeight * heightScale) / 100;
  }

  precompLayer.setPosition(new Vector(width / 2 - precompWidth / 2, height / 2 - precompHeight / 2));
};

export const importToCanvas = async (newDrawOrder?: number): Promise<void> => {
  const setSelectedIdAfterCreated = useCreatorStore.getState().ui.setSelectedIdAfterCreated;
  const animationLoader = useCreatorStore.getState().ui.animationLoader;

  const title = useCreatorStore.getState().ui.setSelectedIdAfterCreated.name;
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (!scene) return;
  const lottieUrl = useCreatorStore.getState().ui.animationLoader.url;

  if (!lottieUrl) return;

  const fileType = lottieUrl.split('.').pop();
  let precomLayer;

  if (fileType === 'lottie') {
    const options = {
      dotlottie: lottieUrl,
      enableNodeIds: true,
      standardizeTimeline: true,
    };

    precomLayer = await scene.importAsPrecompositionLayer('com.lottiefiles.dotlottie', options);
  } else {
    const options = {
      animation: lottieUrl,
      enableNodeIds: true,
      standardizeTimeline: true,
    };

    precomLayer = await scene.importAsPrecompositionLayer('com.lottiefiles.lottie', options);
  }

  resizePrecompLayer(scene, precomLayer);

  // Use the animation title as the precomp layer's name
  renameNestedScenes();

  // const newDrawOrder = animationLoaderStatus === AnimationLoaderStatus.Loading ? 0 : (getSelectedDrawOrder() as number);
  let precompDrawOrder = 0;

  if (animationLoader.status === AnimationLoaderStatus.Loading) {
    removeAnimationLoader(AnimationLoaderStatus.Loaded);

    precomLayer.setName(animationLoader.name as string);
  } else {
    precompDrawOrder = getSelectedDrawOrder() as number;
    precomLayer.setName(title);
  }

  if (newDrawOrder) {
    precompDrawOrder = Math.max(newDrawOrder - 1, 0);
    precomLayer.setDrawOrder(precompDrawOrder, newDrawOrder > 0);
  } else {
    precomLayer.setDrawOrder(precompDrawOrder);
  }

  setSelectedIdAfterCreated(precomLayer.nodeId);
  emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event: EmitterEvent.LOADED_ANIMATION });
  stateHistory.endAction();
};
