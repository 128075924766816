/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useState, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { INTERVAL_AUTO_SAVE } from '~/config';
import { saveToCreator } from '~/lib/function/menu';
import { useCreatorStore } from '~/store';

// Run autosave every 1 minute
const AUTO_SAVE_INTERVAL = Number(INTERVAL_AUTO_SAVE);

const AutoSave: React.FC = () => {
  const [count, setCount] = useState(0);
  const localSaveChangeId = useRef<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      // Always get the latest changeId for every iteration
      const newChangedId = useCreatorStore.getState().project.info.localChangeId;

      let runSave = false;

      if (count > 0) {
        // Auto saving attempts: 2,3,4...
        const hadChanged = newChangedId !== localSaveChangeId.current;

        if (hadChanged) runSave = true;
      } else {
        // First saving attempt
        runSave = true;
      }

      if (runSave) {
        saveToCreator({ saveWithoutPopup: true });

        // Assign latest changeid to localChangeId
        localSaveChangeId.current = newChangedId as string;
      }

      setCount(count + 1);
    }, AUTO_SAVE_INTERVAL);

    return () => clearInterval(interval);
  }, [count]);

  return null;
};

export const AutoSaveListener: React.FC = () => {
  const autoSaveStart = useCreatorStore((state) => state.project.autoSaveStart, shallow);

  if (autoSaveStart) {
    return (
      <>
        <AutoSave />
      </>
    );
  }

  return null;
};
