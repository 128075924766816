/**
 * Copyright 2022 Design Barn Inc.
 */

// Reference: https://codesandbox.io/s/icy-water-ktwif6?file=/src/Tooltip.tsx:0-2124
import type { Placement } from '@floating-ui/react-dom-interactions';
import { offset, autoUpdate, useFloating, useInteractions, useRole, arrow } from '@floating-ui/react-dom-interactions';
import { AnimatePresence } from 'framer-motion';
import React, { cloneElement, useMemo, useRef } from 'react';

interface FixedTooltipProps {
  children: JSX.Element;
  label: string;
  offset: { crossAxis: number; mainAxis: number };
  open: boolean;
  placement?: Placement;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subLabel?: string;
}

export const FixedTooltip: React.FC<FixedTooltipProps> = ({
  children,
  label,
  offset: offsetProps,
  open,
  placement = 'right',
  setOpen,
  subLabel,
}) => {
  const arrowRef = useRef(null);

  const { context, floating, reference, strategy, x, y } = useFloating({
    placement,
    open,
    onOpenChange(_open) {
      setOpen(_open);
    },
    middleware: [offset(offsetProps), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps } = useInteractions([useRole(context, { role: 'tooltip' })]);

  const arrowStyle = useMemo(() => {
    if (placement === 'bottom') return { top: -4, left: '45%' };
    if (placement === 'right') return { top: '45%', left: -4 };
    if (placement === 'left') return { top: '45%', right: -4 };
    if (placement === 'top') return { bottom: -4, left: '45%' };

    return { bottom: -4, left: -4 };
  }, [placement]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
      <AnimatePresence>
        {open && (
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              filter: 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.56))',
            }}
            className={`z-tooltip h-24 w-[298px] whitespace-nowrap rounded-md bg-gray-800 p-4 text-xs text-white`}
          >
            <div className="leading-[18px]">{label}</div>
            <div className="text-slate-400">{subLabel}</div>
            <div className={`absolute h-2 w-2 rotate-45 bg-gray-800`} style={arrowStyle} ref={arrowRef} />
            <button className="absolute bottom-4 right-4 float-right" onClick={() => setOpen(false)}>
              Got it
            </button>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};
