/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PropertyAccordion } from './ui/PropertyAccordion';

import { Opacity } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import { TooltipWrapper } from '~/components/Elements/Tooltip/TooltipWrapper';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, removeKeyFrame } from '~/lib/toolkit';
import type { StoreSlice } from '~/store';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

interface LayerProps {
  type:
    | PropertyPanelType.Group
    | PropertyPanelType.Fill
    | PropertyPanelType.Stroke
    | PropertyPanelType.ShapeLayer
    | PropertyPanelType.Precomposition;
}

// const BlendModeOptions: Option[] = [
//   {
//     label: 'Normal',
//     value: 'normal',
//   },
// ];

export const LayerProperty: React.FC<LayerProps> = ({ type }) => {
  // const [visible, setVisible] = React.useState(true);

  let currentType: 'currentTransform' | 'currentFillShape' | 'currentStrokeShape';
  let animatedType: number | undefined;
  let event: string | undefined;

  switch (type) {
    case PropertyPanelType.Precomposition:
    case PropertyPanelType.ShapeLayer:
    case PropertyPanelType.Group:
      currentType = 'currentTransform';
      animatedType = AnimatedType.OPACITY;
      event = EmitterEvent.ANIMATED_OPACITY_UPDATED;
      break;

    case PropertyPanelType.Fill:
      currentType = 'currentFillShape';
      animatedType = AnimatedType.FILL_OPACITY;
      event = EmitterEvent.SHAPE_FILL_OPACITY_UPDATED;
      break;

    case PropertyPanelType.Stroke:
      currentType = 'currentStrokeShape';
      animatedType = AnimatedType.STROKE_OPACITY;
      event = EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED;
      break;

    default:
      break;
  }

  // const handleOnChangeBlendMode = useCallback((option: Option) => {
  //   // eslint-disable-next-line no-console
  //   console.log(option);
  // }, []);

  const handleOpacityType = (
    state: StoreSlice,
    propType:
      | PropertyPanelType.Fill
      | PropertyPanelType.Group
      | PropertyPanelType.ShapeLayer
      | PropertyPanelType.Stroke
      | PropertyPanelType.Precomposition,
  ): number => {
    if (propType === PropertyPanelType.Stroke) {
      return state.toolkit[currentType as 'currentStrokeShape'].o;
    } else {
      return state.toolkit[currentType as 'currentFillShape' | 'currentGFillShape' | 'currentTransform'].opacity;
    }
  };

  const [
    opacity,
    currentKeyframe,
    setAnimatedValue,
    addAnimatedValue,
    setStaticValue,
    selectedNodesInfo,
    groupTransform,
  ] = useCreatorStore(
    (state) => [
      handleOpacityType(state, type),
      state.toolkit[currentType].opacityCurrentKeyframe,
      state.toolkit.setAnimatedValue,
      state.toolkit.addAnimatedValue,
      state.toolkit.setStaticValue,
      state.ui.selectedNodesInfo,
      state.ui.selectedGroupTransform,
    ],
    shallow,
  );

  const multiSelect = selectedNodesInfo.length > 1;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      selectedNodesInfo.forEach((nodeInfo) => {
        setAnimatedValue(animatedType as number, [result.value], nodeInfo.nodeId);
      });
      emitter.emit(event as string);
    },
    [animatedType, event, selectedNodesInfo, setAnimatedValue],
  );

  const handleKeyframeClick = useCallback(() => {
    if (currentKeyframe) {
      removeKeyFrame(currentKeyframe);
      setStaticValue(animatedType as number, [opacity]);
    } else {
      addAnimatedValue(animatedType as number, [opacity]);
    }

    emitter.emit(event as string);
  }, [addAnimatedValue, animatedType, currentKeyframe, event, opacity, setStaticValue]);

  return (
    <PropertyAccordion title="Layer" withDisclosure={false} disableAccordionButton={true}>
      <div className="mt-2 flex">
        {/* <TooltipWrapper label={'Blending Mode'}>
          <div className="w-[84px] ml-0.5">
            <Select
              options={BlendModeOptions}
              selected={BlendModeOptions[0] as Option}
              onChange={handleOnChangeBlendMode}
            />
          </div>
        </TooltipWrapper> */}
        <TooltipWrapper label={'Opacity'}>
          <div className="mt-[1px] w-[84px]">
            <NumberInput
              name="layer-opacity"
              label={<Opacity className="h-4 w-4" />}
              value={multiSelect && groupTransform.opacity ? groupTransform.opacity * 100 : opacity}
              onChange={handleOnChange}
              onKeyframeClick={handleKeyframeClick}
              hasKeyframe={currentKeyframe !== ''}
              showKeyframe
              append="%"
              min={0}
              max={100}
            />
          </div>
        </TooltipWrapper>
        {/* <Interactive>
          <VisibilityToggle visible={visible} onClick={() => setVisible(!visible)} />
        </Interactive> */}
      </div>
    </PropertyAccordion>
  );
};
