/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Layer, Meta, PrecompositionAsset, Scene } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import { getNodeById } from '../utils';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

import { emitter, EmitterEvent } from '~/lib/emitter';

const methodNames = {
  createPrecompositionLayer: 'createPrecompositionLayer',
  createShapeLayer: 'createShapeLayer',
  allLayers: 'allLayers',
  getAllLayersByType: 'getAllLayersByType',
  backgroundColor: 'backgroundColor',
  layers: 'layers',
  name: 'name',
  meta: 'meta',
  getNextDrawOrder: 'getNextDrawOrder',
  refreshChildrenOrder: 'refreshChildrenOrder',
  refreshDrawOrder: 'refreshDrawOrder',
  setBackgroundColor: 'setBackgroundColor',
  setDrawOrder: 'setDrawOrder',
  setName: 'setName',
  setOffset: 'setOffset',
};

const composableModifiers = {
  meta: (meta: Meta) => {
    const metaObject = {
      author: meta.author,
      description: meta.description,
      generator: meta.generator,
      keywords: meta.keywords,
      version: meta.version,
    };

    return metaObject;
  },
};

function setDrawOrderWithChildren(
  scene: Scene | PrecompositionAsset,
  vmLayer: Layer,
  drawOrder: number,
): Scene | PrecompositionAsset {
  const layer = getNodeById(vmLayer.nodeId) as Layer;

  scene.setDrawOrderWithChildren(layer, drawOrder);
  emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

  return scene;
}

const additionalMethods = {
  setDrawOrderWithChildren,
};

export function getComposableMethods(plugin: Plugin, scene: Scene | PrecompositionAsset): ObjectMethods {
  return getObjectMethods(plugin, methodNames, scene, composableModifiers, additionalMethods);
}
