/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  DagNode,
  ColorJSON,
  ScalarJSON,
  PropertiesJSON,
  PercentageJSON,
  StrokeDashJSON,
  AnimatedPropertiesJSON,
} from '@lottiefiles/toolkit-js';
import { ShapeType, GroupShape, StrokeShape, StrokeDashType } from '@lottiefiles/toolkit-js';
import { remove, cloneDeep, capitalize } from 'lodash-es';

import { createGetCurrentKeyframe } from '~/lib/toolkit';

export interface StrokeDashProp {
  id: string;
  name: string;
  type: string;
  value: number;
}

export interface CurrentStrokeShape {
  alpha: number;
  animatedProperties: AnimatedPropertiesJSON | null;
  b: number;
  bm: number;
  colorCurrentKeyframe: string;
  g: number;
  id: string | null;
  lc: number;
  lj: number;
  miterCurrentKeyframe: string;
  ml: number;
  o: number;
  opacityCurrentKeyframe: string;
  r: number;
  strokeDashes: StrokeDashProp[] | [];
  width: number;
  widthCurrentKeyframe: string;
}

export const getStrokeShape = (node: DagNode | null): StrokeShape | null => {
  if (node instanceof GroupShape) {
    const shape = node.shapes.find((sh) => sh.type === ShapeType.STROKE);

    if (shape instanceof StrokeShape) {
      return shape;
    }
  } else if (node instanceof StrokeShape) {
    return node;
  }

  return null;
};

export const defaultCurrentStrokeShape: CurrentStrokeShape = {
  id: null,
  r: 0,
  g: 0,
  b: 0,
  alpha: 1,
  width: 10,
  lc: 1,
  lj: 1,
  ml: 1,
  o: 1,
  strokeDashes: [],
  animatedProperties: null,
  bm: 0,
  miterCurrentKeyframe: '',
  opacityCurrentKeyframe: '',
  widthCurrentKeyframe: '',
  colorCurrentKeyframe: '',
};

const sortStrokeDashOrder = (strokeDashes: StrokeDashJSON[]): StrokeDashJSON[] => {
  const findIndexes = (indexArr: string[], expected: string): number[] => {
    return indexArr.reduce((arr: number[], val: string, valIndex: number) => {
      if (val === expected) arr.push(valIndex);

      return arr;
    }, []);
  };
  const count = strokeDashes.length;

  if (count === 0) return strokeDashes;

  const strokeDashesTypes = strokeDashes.map((strokeD) => strokeD.properties.n) as string[];

  const dashIndexes = findIndexes(strokeDashesTypes, StrokeDashType.Dash);
  const offsetIndexes = findIndexes(strokeDashesTypes, StrokeDashType.Offset);
  const gapIndexes = findIndexes(strokeDashesTypes, StrokeDashType.Gap);

  const offsetIndex = offsetIndexes.length > 0 ? offsetIndexes[0] : -1;
  const orderedStrokeDashes: StrokeDashJSON[] = [];

  // Order: Dash -> Offset -> Gap -> Dash -> Gap -> Dash...
  strokeDashes.map((strokeD, strokeIndex) => {
    if (strokeIndex === 0 && dashIndexes.length > 0) {
      const ind = dashIndexes[0] as number;

      orderedStrokeDashes.push(strokeDashes[ind] as StrokeDashJSON);
      dashIndexes.shift();
    } else if (strokeIndex === 1 && offsetIndexes.length > 0) {
      const ind = offsetIndexes[0] as number;

      orderedStrokeDashes.push(strokeDashes[ind] as StrokeDashJSON);
      offsetIndexes.shift();
    } else if (strokeIndex % 2 === 0 && gapIndexes.length > 0) {
      const ind = gapIndexes[0] as number;

      orderedStrokeDashes.push(strokeDashes[ind] as StrokeDashJSON);
      gapIndexes.shift();
    } else if (dashIndexes.length > 0) {
      const ind = dashIndexes[0] as number;

      orderedStrokeDashes.push(strokeDashes[ind] as StrokeDashJSON);
      dashIndexes.shift();
    } else {
      orderedStrokeDashes.push(strokeD);
    }

    return strokeD;
  });

  // Move Offset to the Last
  // Order: Dash -> Gap -> Dash -> Gap -> Dash... -> Offset
  if (offsetIndex && offsetIndex > -1) {
    const offsetStroke = strokeDashes[offsetIndex] as StrokeDashJSON;

    remove(orderedStrokeDashes, (_, sdIndex) => {
      return sdIndex === offsetIndex;
    });
    orderedStrokeDashes.push(offsetStroke);
  }

  return orderedStrokeDashes;
};

export const getCurrentStrokeShape = (node: StrokeShape | null): CurrentStrokeShape => {
  const currentStrokeShape: CurrentStrokeShape = { ...defaultCurrentStrokeShape };

  if (node instanceof StrokeShape) {
    const strokeState = node.state;

    const currentFrame = node.scene.timeline.currentFrame;
    const getKeyFrame = createGetCurrentKeyframe(currentFrame);
    const color = strokeState.animatedProperties.sc;

    currentStrokeShape.miterCurrentKeyframe = getKeyFrame(strokeState.animatedProperties.ml);
    currentStrokeShape.opacityCurrentKeyframe = getKeyFrame(strokeState.animatedProperties.o);
    currentStrokeShape.widthCurrentKeyframe = getKeyFrame(strokeState.animatedProperties.sw);
    currentStrokeShape.colorCurrentKeyframe = getKeyFrame(strokeState.animatedProperties.sc);
    currentStrokeShape.id = strokeState.id as string;

    // currentStrokeShape.r = (strokeState.animatedProperties.sc.value as ColorJSON).r;
    // currentStrokeShape.g = (strokeState.animatedProperties.sc.value as ColorJSON).g;
    // currentStrokeShape.b = (strokeState.animatedProperties.sc.value as ColorJSON).b;
    // currentStrokeShape.alpha = (strokeState.animatedProperties.sc.value as ColorJSON).a;
    // currentStrokeShape.width = (strokeState.animatedProperties.sw.value as ScalarJSON).value;

    // When keyframes are removed, 'value' shouldn't return undefined. Toolkit will resolve this on next update.
    // TODO: Revert to 'value' when toolkit updates. Temporary fix:
    currentStrokeShape.r = ((color.value ?? color.staticValue) as ColorJSON).r;
    currentStrokeShape.g = ((color.value ?? color.staticValue) as ColorJSON).g;
    currentStrokeShape.b = ((color.value ?? color.staticValue) as ColorJSON).b;
    currentStrokeShape.alpha = ((color.value ?? color.staticValue) as ColorJSON).a;
    currentStrokeShape.width = ((strokeState.animatedProperties.sw.value ??
      strokeState.animatedProperties.sw.staticValue) as ScalarJSON).value;

    currentStrokeShape.o = (strokeState.animatedProperties.o.value as PercentageJSON).pct as number;
    currentStrokeShape.lc = (strokeState.properties as PropertiesJSON).lc as number;
    currentStrokeShape.lj = (strokeState.properties as PropertiesJSON).lj as number;
    currentStrokeShape.ml = (strokeState.animatedProperties.ml.value as ScalarJSON).value;
    currentStrokeShape.bm = strokeState.properties.bm as number;

    currentStrokeShape.strokeDashes = sortStrokeDashOrder(strokeState.strokeDashes as StrokeDashJSON[]).reduce(
      (finalStroke: StrokeDashProp[], currentStroke: StrokeDashJSON, _) => {
        finalStroke.push({
          id: currentStroke.id,
          type: currentStroke.properties.n as string,
          name: capitalize((currentStroke.properties as PropertiesJSON).nm as string),
          value: (currentStroke.animatedProperties.dl.value as ScalarJSON).value,
        });

        return finalStroke;
      },
      [],
    );

    // Display for thumbnail on property panel
    // CloneDeep used to fix toolkit referencing issue
    currentStrokeShape.animatedProperties = cloneDeep(strokeState.animatedProperties as AnimatedPropertiesJSON);
  }

  return currentStrokeShape;
};
