/**
 * Copyright 2021 Design Barn Inc.
 */

import React, { useEffect } from 'react';
// TODO: Remove this import and wrap it in a react component for readability, and then import where needed
import '@dotlottie/player-component';

import { LibraryType } from './data/constant';
import { useURLQuery } from './path';
import { AppProvider } from './providers/app';
import { UrlType } from './store/projectSlice';

import { Creator } from '~/components/Layout';
import { getPluginManager } from '~/plugins';
import { useCreatorStore } from '~/store';

// TODO(miljau): to be removed
window.getPluginManager = getPluginManager;

const setFetchExistingFile = useCreatorStore.getState().project.setFetchExistingFile;
const setLoader = useCreatorStore.getState().ui.setLoader;
const setUrlType = useCreatorStore.getState().project.setUrlType;
const setCurrentLibrary = useCreatorStore.getState().ui.setCurrentLibrary;

const App: React.FC = () => {
  const query = useURLQuery();

  const fileId = query.get('fileId');

  useEffect(() => {
    if (fileId) {
      setLoader({
        isLoading: true,
        title: 'Loading Creator...',
      });
      setUrlType(UrlType.Existing);
      setFetchExistingFile({ status: true, fileId });
    } else {
      setCurrentLibrary(LibraryType.GettingStarted);
    }
  }, [fileId]);

  return (
    <AppProvider>
      <Creator />
    </AppProvider>
  );
};

export default App;
