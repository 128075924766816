/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Scene, SceneJSON } from '@lottiefiles/toolkit-js';
import React, { useContext, useCallback, useRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import type { DraggableEvent, DraggableData } from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { TIMELINE_BAR_BEGIN_OFFSET_PX, TIMELINE_SCRUBBER_DEBOUNCE_MS } from '../constant';

import { DragContext } from './Context/DragContext';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface Props {}

export const TimelineTimeScale: React.FC<Props> = () => {
  const ttsDraggableRef = useRef(null);
  const ttsDraggableNodeRef = useRef(null);
  const { playheadNodeRef, setPlayheadPos } = useContext(DragContext);

  const [
    start,
    fps,
    duration,
    setCurrentFrame,
    precomFps,
    precomDuration,
    selectedPrecompositionId,
    setSelectedPrecompositionJson,
    sceneIndex,
    setScrubberDrag,
  ] = useCreatorStore(
    (state) => [
      (state.toolkit.json?.timeline.properties.ip as number) || 0,
      (state.toolkit.json?.timeline.properties.fr as number) || 30,
      (state.toolkit.json?.timeline.duration as number) || 0,
      state.toolkit.setCurrentFrame,
      (state.toolkit.selectedPrecompositionJson?.timeline.properties.fr as number) || 20,
      (state.toolkit.selectedPrecompositionJson?.timeline.duration as number) || 0,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.setSelectedPrecompositionJson,
      state.toolkit.sceneIndex,
      state.timeline.setScrubberDrag,
    ],
    shallow,
  );

  const width = document.getElementById('keyframe-scrubber')?.clientWidth ?? 1;
  const ref = useRef<HTMLDivElement>(null);

  const executeClick = useCallback(
    async (evt: React.MouseEvent<HTMLElement>) => {
      evt.preventDefault();

      const timescale = ref.current;

      setScrubberDrag(true);

      if (timescale) {
        const bounds = timescale.getBoundingClientRect();

        const finalWidth = width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX;

        const x = evt.clientX - bounds.left;

        const widthRatio = Math.abs(x / finalWidth);

        // Compute Current Frame
        const totalCalculatedFrames =
          (selectedPrecompositionId && precomFps ? precomFps : fps) *
          (selectedPrecompositionId && precomDuration ? precomDuration : duration);

        const cf = Math.round(widthRatio * totalCalculatedFrames);

        if (selectedPrecompositionId) {
          const assetNode = getNodeById(toolkit.scenes[sceneIndex] as Scene, selectedPrecompositionId);

          if (assetNode) {
            assetNode.timeline.setCurrentFrame(cf);
            setSelectedPrecompositionJson(assetNode.state as SceneJSON);
          }
        }
        // Update store
        setCurrentFrame(cf);

        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
        emitter.emit(EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED);
      }
    },
    [
      setCurrentFrame,
      width,
      duration,
      selectedPrecompositionId,
      fps,
      precomFps,
      precomDuration,
      sceneIndex,
      setSelectedPrecompositionJson,
      setScrubberDrag,
    ],
  );

  const [blockWidth, setBlockWidth] = useState(100);

  useEffect(() => {
    setBlockWidth(
      (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX) /
        (selectedPrecompositionId && precomDuration ? precomDuration : duration),
    );
  }, [width, duration, selectedPrecompositionId, precomDuration]);

  const handleOnDrag = (evt: DraggableEvent, data: DraggableData): void => {
    evt.preventDefault();

    if (playheadNodeRef?.current) {
      const currentPlayheadPosX = playheadNodeRef.current.state.x;
      const newPosX = data.x;

      const newX = Number(currentPlayheadPosX) + Number(newPosX);

      const simulatedEvent = {
        node: playheadNodeRef.current.props.children.ref.current,
        lastX: 0,
        lastY: 0,
        deltaY: NaN,
        y: NaN,
        deltaX: data.deltaX,
        x: newX,
      };

      setPlayheadPos(newX);

      // Simulate drag
      playheadNodeRef.current.onDrag(evt, simulatedEvent);
    }
  };

  const handleOnStart = async (evt: DraggableEvent): Promise<void> => {
    const executeOnStart = (): void => {
      if (playheadNodeRef?.current) {
        const nodeData = playheadNodeRef.current.props.children.ref.current;

        const simulatedEvent = {
          node: nodeData,
        };

        playheadNodeRef.current.onDragStart(evt, simulatedEvent);
      }
    };

    executeClick(evt);

    // Wait for execute scrubber done first, before dragStart
    await new Promise((resolve) => setTimeout(resolve, TIMELINE_SCRUBBER_DEBOUNCE_MS));

    executeOnStart();
  };

  const handleOnStop = (evt: DraggableEvent): void => {
    if (playheadNodeRef?.current) {
      const simulatedEvent = {
        node: playheadNodeRef.current.props.children.ref.current,
      };

      playheadNodeRef.current.onDragStop(evt, simulatedEvent);
    }
  };

  // The reason why use margin-left instead of padding left for timescale make calculation easier
  // by avoiding using constant like TIMELINE_OFFSET_LEFT_X in calculation
  return (
    <div className="flex h-4 w-full rounded bg-gray-800">
      <div ref={ref} className={'relative ml-[15px] flex h-full w-full flex-nowrap'}>
        <Draggable
          ref={ttsDraggableRef}
          axis="none"
          onStart={handleOnStart}
          onDrag={handleOnDrag}
          onStop={handleOnStop}
        >
          <div ref={ttsDraggableNodeRef} className="relative flex h-full w-full flex-nowrap">
            {duration &&
              [...Array(Math.ceil((selectedPrecompositionId ? precomDuration : duration) + 1))].map((_value, key) => {
                return (
                  <div key={key}>
                    <div
                      className="absolute top-[2px] select-none text-[8px] text-gray-300"
                      style={{ left: `${blockWidth * key - 3}px` }}
                    >
                      {start / (selectedPrecompositionId ? precomFps : fps) + key}s
                      <div className="absolute  text-[8px]"></div>
                    </div>
                    {[...Array(4)].map((_subValue, subKey) => (
                      <div
                        key={`${subKey}`}
                        className="absolute select-none text-[10px]"
                        style={{ left: `${blockWidth * key + (blockWidth / 5) * (subKey + 1)}px` }}
                      >
                        <div className="absolute left-[0px] mt-[0.55em] h-[5px] w-[1px] bg-gray-600"></div>
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        </Draggable>
      </div>
    </div>
  );
};
