/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  DagNode,
  BaseLayerJSON,
  AVLayer,
  AnimatedProperty,
  TangentKeyframe,
  ShapeLayer,
  DagNodeJSON,
  Interpolator,
  PropertyType,
  Value,
  Vector,
  ShapeJSON,
  ShapeLayerJSON,
  Scene as ToolkitScene,
  PrecompositionAssetJSON,
} from '@lottiefiles/toolkit-js';
import { Shape } from '@lottiefiles/toolkit-js';
import type { Mesh, Object3D, Scene } from 'three';

import type { CObject3D } from '../types/object';
import { CMesh } from '../types/object';

import { emitter, EmitterEvent } from '~/lib/emitter';
import type { TransformControls } from '~/lib/threejs/TransformControls';
import { stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
const setIsPastable = useCreatorStore.getState().ui.setIsPastable;

// Describes the constructor of a generic toolkit node
interface GenericNodeConstructor {
  new (parent?: DagNode): DagNode;
}

// This interface describes all the information required to recreate a node
// after it has been copied/cut It does not apply to keyframes as they require
// different information.
//
// NOTE(miljau): simply cloning and detaching the node with
// removeFromGraph/removeChild did not work as detaching it removes the
// keyframes. We want to preserve the keyframes. Another issue with this
// approach was that detached nodes couldn't be cloned any further until it
// gets attached. So all of this voodoo is to get around those limitations
interface NodeClipboard {
  ctor: GenericNodeConstructor;
  // Required for draw order shenanigans
  // will be null for cuts since the original node gets removed
  nodeID: string | null;
  // node json
  nodeJSON: DagNodeJSON;
  // parent to attach to
  parentID: string;
}

interface KeyframeClipboard {
  inTangent: Vector | undefined;
  interpolator: Interpolator;
  outTangent: Vector | undefined;
  sourceType: PropertyType;
  value: Value;
}

export default class Editor {
  private _keyframeClipboard: KeyframeClipboard | null = null;

  private _nodeClipboard: NodeClipboard[] = [];

  private readonly _objectContainer: CObject3D;

  private readonly _scene: Scene;

  private _toolkitScene: ToolkitScene | null = null;

  private readonly _transformControls: TransformControls | null = null;

  public constructor(scene: Scene, objectContainer: CObject3D, transformControls: TransformControls | null) {
    this._scene = scene;
    this._objectContainer = objectContainer;
    this._transformControls = transformControls;

    this._addEventListeners();
  }

  public addObject(object: CObject3D | CMesh | Object3D | Mesh, parent?: CObject3D | Scene): void {
    if (parent) {
      parent.add(object);
      object.parent = parent;
    } else this._objectContainer.add(object);
  }

  public copyObjects(): void {
    const creatorState = useCreatorStore.getState();
    const selectedKeyframes = creatorState.timeline.selectedKeyframes;

    if (selectedKeyframes.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedKeyframeId = selectedKeyframes[0]!;

      const selectedKeyframe = toolkit.getKeyframeById(selectedKeyframeId);

      if (selectedKeyframe && selectedKeyframe.parentProperty) {
        this._keyframeClipboard = {
          value: selectedKeyframe.value,
          interpolator: selectedKeyframe.interpolator,
          inTangent: (selectedKeyframe as TangentKeyframe).inTangent,
          outTangent: (selectedKeyframe as TangentKeyframe).outTangent,
          sourceType: selectedKeyframe.parentProperty.type,
        };
      }
    } else {
      this._keyframeClipboard = null;
      this._nodeClipboard = [];
      const copiedToolkitIDs = this._transformControls?.lastSelectedObjectIDs as string[];

      copiedToolkitIDs.forEach((id) => {
        const targetNode = this._toolkitScene?.getNodeById(id);

        if (targetNode && targetNode.parent) {
          this._nodeClipboard.push({
            parentID: targetNode.parent.nodeId,
            nodeJSON: targetNode.toJSON(),
            nodeID: targetNode.nodeId,
            ctor: targetNode.constructor as GenericNodeConstructor,
          });
        }
      });
    }

    setIsPastable(this.isPastable());
  }

  public cutObjects(): void {
    if (!this._transformControls) return;

    this.copyObjects();

    emitter.emit(EmitterEvent.UI_DELETE);
  }

  public duplicateObjects(): void {
    const selectedNodeIDs = this._transformControls?.lastSelectedObjectIDs as string[];

    if (!selectedNodeIDs.length) {
      return;
    }

    stateHistory.beginAction();

    const clonedNodeIDs: string[] = [];

    selectedNodeIDs.forEach((nodeID) => {
      const node = this._toolkitScene?.getNodeById(nodeID) as DagNode;
      const clonedNode = node.clone();

      clonedNodeIDs.push(clonedNode.nodeId);

      this.updateDrawOrder(node, clonedNode, selectedNodeIDs.length);
    });

    // attach the transformControl to the newly created object
    if (this._transformControls && clonedNodeIDs.length) {
      this._transformControls.lastSelectedObjectIDs = clonedNodeIDs;
    }

    emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
    addToSelectedNodes(clonedNodeIDs, true);

    stateHistory.endAction();
  }

  public isPastable(): boolean {
    return Boolean(this._keyframeClipboard || this._nodeClipboard);
  }

  public pasteKeyframe(): void {
    if (this._keyframeClipboard) {
      const selectedNodeInfo = useCreatorStore.getState().ui.selectedNodesInfo[0];

      if (!selectedNodeInfo) {
        return;
      }

      // TODO: handle keyframe paste when with multiple layers
      const currentNode = toolkit.getNodeById(useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string);

      if (!currentNode) return;

      // find the appropriate AnimatedProperty to add the new keyframe to
      const destination = currentNode.children.find((node) => node.type === this._keyframeClipboard?.sourceType) as
        | AnimatedProperty
        | undefined;

      if (!destination) return;

      // Mark the property as animated.
      if (!destination.isAnimated) {
        destination.setIsAnimated(true);
      }

      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

      destination.track.addKeyFrame(
        currentFrame,
        this._keyframeClipboard.value,
        this._keyframeClipboard.interpolator,
        // let toolkit generate a new frameid
        '',
        this._keyframeClipboard.outTangent,
        this._keyframeClipboard.inTangent,
      );

      // Send event to get latest toolkit state, to update canvas + timeline
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
    }
  }

  public pasteObjects(): void {
    if (this._keyframeClipboard) {
      this.pasteKeyframe();
    } else if (this._nodeClipboard.length) {
      stateHistory.beginAction();

      // if the nodes have mixed hierarchies,
      // find the topmost hierarchy in the group
      // and make all the nodes match that hierarchy

      // TODO: use layermap to get hierarchy instead?
      // TODO: revisit when layers are simplified
      let nodesToPaste: NodeClipboard[] = this._nodeClipboard;
      const isEqualHierarchy = this._nodeClipboard.every(
        (node) => (node.nodeJSON as ShapeJSON).type === (this._nodeClipboard[0]?.nodeJSON as ShapeJSON).type,
      );

      if (!isEqualHierarchy) {
        nodesToPaste = [];

        const topmostLevel = this._nodeClipboard.find((node) => (node.nodeJSON as ShapeLayerJSON).type === 'SHAPE')
          ? 'SHAPE'
          : 'gr';

        this._nodeClipboard.forEach((node) => {
          if (
            (node.nodeJSON as ShapeJSON).type === topmostLevel ||
            (node.nodeJSON as PrecompositionAssetJSON).type === 'PRECOMPOSITION'
          ) {
            nodesToPaste.push(node);
          } else {
            let currentNode = this._toolkitScene?.getNodeById(node.parentID as string) as Shape;

            while (currentNode.type !== topmostLevel) {
              currentNode = this._toolkitScene?.getNodeById(currentNode.parent?.nodeId as string) as Shape;
            }

            nodesToPaste.push({
              parentID: currentNode.parent?.nodeId as string,
              nodeJSON: currentNode.toJSON(),
              nodeID: currentNode.nodeId,
              ctor: currentNode.constructor as GenericNodeConstructor,
            });
          }
        });
      }

      const clonedNodeIDs: string[] = [];
      const selectedNodeIDs = this._transformControls?.lastSelectedObjectIDs as string[];

      nodesToPaste.forEach((nodeInClipboard) => {
        // the destination parent depends on its original parent type
        const parentsToAttachTo: DagNode[] = [];
        const parent = this._toolkitScene?.getNodeById(nodeInClipboard.parentID) as DagNode;

        if (parent.nodeType === 'Scene') {
          // 1. if parent is the scene, paste the nodes onto the scene
          parentsToAttachTo.push(parent);
        } else {
          // 2. if parent is not the scene
          selectedNodeIDs.forEach((selectedNodeID) => {
            const selectedNode = this._toolkitScene?.getNodeById(selectedNodeID);

            // 2.1 paste it on compatible selected layers
            if (selectedNode?.nodeType === parent.nodeType && (selectedNode as Shape).type === (parent as Shape).type) {
              parentsToAttachTo.push(selectedNode);
              // 2.2 if the node to be duplicated is still selected, paste on its parent
            } else if (selectedNodeID === nodeInClipboard.nodeID) {
              parentsToAttachTo.push(parent);
            }
          });
        }

        parentsToAttachTo.forEach((parentToAttachTo) => {
          // eslint-disable-next-line new-cap
          const clonedNode = new nodeInClipboard.ctor(parentToAttachTo);

          clonedNode.fromJSON(nodeInClipboard.nodeJSON);
          clonedNodeIDs.push(clonedNode.nodeId);

          const node = nodeInClipboard.nodeID ? this._toolkitScene?.getNodeById(nodeInClipboard.nodeID) : null;

          if (node) this.updateDrawOrder(node, clonedNode, this._nodeClipboard.length);
        });
      });

      // attach the transformControl to the newly created object
      if (this._transformControls && clonedNodeIDs.length) {
        this._transformControls.lastSelectedObjectIDs = clonedNodeIDs;
      }

      emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
      addToSelectedNodes(clonedNodeIDs, true);

      stateHistory.endAction();
    }

    setIsPastable(this.isPastable());
  }

  public removeObject(objectID: string): void {
    const canvasMap = useCreatorStore.getState().ui.canvasMap;
    const removeCanvasMapItem = useCreatorStore.getState().ui.removeCanvasMapItem;

    const object = canvasMap.get(objectID);

    if (!object) return;

    removeCanvasMapItem(objectID);
    object.traverse((child) => {
      if (child instanceof CMesh) {
        child.geometry.dispose();
        child.material.dispose();
      }
    });

    if (object.parent) object.parent.remove(object);
    else this._scene.remove(object);
    // detach from transformControl
    if (this._transformControls) {
      this._transformControls.detach();
      this._transformControls.hideBoundingBox();
    }
  }

  public updateDrawOrder(originalNode: DagNode, clonedNode: DagNode, drawOrderOffset: number): void {
    const originalDrawOrder = (originalNode.state as BaseLayerJSON).properties.do;

    if (originalDrawOrder !== null && typeof originalDrawOrder === 'number') {
      (originalNode as ShapeLayer).setDrawOrder(originalDrawOrder + drawOrderOffset);
    } else if (clonedNode instanceof Shape) {
      const setLayerShapeIndex = useCreatorStore.getState().toolkit.setLayerShapeIndex;
      const index = (originalNode.parent as ShapeLayer).shapes.findIndex((node) => node.nodeId === originalNode.nodeId);

      setLayerShapeIndex(index, clonedNode.nodeId, (clonedNode.parent as AVLayer).nodeId);
    }
  }

  private _addEventListeners(): void {
    // initialize the scene
    const selectedPrecomp = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    this._toolkitScene = selectedPrecomp
      ? (toolkit.getNodeById(selectedPrecomp) as ToolkitScene)
      : (toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene);

    // if opening a saved file, update the scene after the layer data has loaded
    useCreatorStore.subscribe(
      (state) => state.ui.loader.isLoading,
      (isLoading) => {
        if (!isLoading) {
          this._toolkitScene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene;
        }
      },
    );

    // when switching to a precomp scene, update the scene type
    useCreatorStore.subscribe(
      (state) => state.toolkit.selectedPrecompositionId,
      (selectedPrecompositionId) => {
        if (selectedPrecompositionId) {
          this._toolkitScene = toolkit.getNodeById(selectedPrecompositionId) as ToolkitScene;
        } else {
          this._toolkitScene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene;
        }
      },
    );
  }
}
