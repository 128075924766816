/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Vector as ToolkitVector } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { ValueObject } from '.';

const methodNames = {
  angle: 'angle',
  is2D: 'is2D',
  is3D: 'is3D',
  magnitude: 'magnitude',
  add: 'add',
  addToClone: 'addToClone',
  clone: 'clone',
  divide: 'divide',
  equals: 'equals',
  interpolate: 'interpolate',
  multiplyToClone: 'multiplyToClone',
  normalize: 'normalize',
  scale: 'scale',
  scaleToClone: 'scaleToClone',
  set: 'set',
  setAngle: 'setAngle',
  setX: 'setX',
  setY: 'setY',
  setZ: 'setZ',
  setZero: 'setZero',
  subtract: 'subtract',
  subtractToClone: 'subtractToClone',
  toArray: 'toArray',
  // toJSON: 'toJSON',
  toObject: 'toObject',
  toString: 'toString',
};

function getVectorMethods(plugin: Plugin, vector: ToolkitVector): ObjectMethods {
  return getObjectMethods(plugin, methodNames, vector);
}

export interface VectorObject extends ValueObject {
  x: number;
  y: number;
  z?: number;
}

export function createVectorObject(plugin: Plugin, vector: ToolkitVector): VectorObject {
  const vectorObject: VectorObject = {
    x: vector.x as number,
    y: vector.y as number,
    valueType: 'Vector',
  };

  registerObjectMethods(plugin, vector, vectorObject, [getVectorMethods]);

  return vectorObject;
}
