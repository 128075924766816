/**
 * Copyright 2022 Design Barn Inc.
 */

import EventEmitter2 from 'eventemitter2';

export const emitter = new EventEmitter2({
  wildcard: true,
  delimiter: ':',
});

export enum EmitterEvent {
  ALIGNMENT_BOTTOM = 'alignment:bottom',
  ALIGNMENT_CENTER = 'alignment:center',
  ALIGNMENT_LEFT = 'alignment:left',
  ALIGNMENT_MIDDLE = 'alignment:middle',
  ALIGNMENT_RIGHT = 'alignment:right',
  ALIGNMENT_TOP = 'alignment:top',

  ANIMATED_ANCHOR_UPDATED = 'animated:anchor-updated',
  ANIMATED_KEYFRAME_UPDATED = 'animated:key-frame-updated',
  ANIMATED_OPACITY_UPDATED = 'animated:opacity-updated',
  ANIMATED_POSITION_UPDATED = 'animated:position-updated',
  ANIMATED_ROTATION_UPDATED = 'animated:rotation-updated',
  ANIMATED_SCALE_UPDATED = 'animated:scale-updated',
  ANIMATED_SHAPE_BLENDING_MODE_UPDATED = 'animated:shape-blending-mode-updated',
  ANIMATED_SHAPE_COMPOSITION_UPDATED = 'animated:shape-composition-updated',
  ANIMATED_SHAPE_FILL_COLOR_ALPHA_UPDATED = 'animated:fill-color-alpha-updated',
  ANIMATED_SHAPE_FILL_COLOR_UPDATED = 'animated:fill-color-updated',
  ANIMATED_SHAPE_FILL_RULE_UPDATED = 'animated:shape-fill-rule-updated',
  ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED = 'animated:gradient-color-updated',
  ANIMATED_SHAPE_GRADIENT_OPACITY_UPDATED = 'animated:gradient-opacity-updated',
  ANIMATED_SHAPE_GRADIENT_POINT_UPDATED = 'animated:gradient-point-updated',
  ANIMATED_SHAPE_PATH_UPDATED = 'animated:path-updated',
  ANIMATED_SHAPE_STROKE_COLOR_ALPHA_UPDATED = 'animated:stroke-color-alpha-updated',
  ANIMATED_SHAPE_STROKE_COLOR_UPDATED = 'animated:stroke-color-updated',
  ANIMATED_SHAPE_STROKE_DASH_ADDED = 'animated:stroke-dash-added',
  ANIMATED_SHAPE_STROKE_DASH_REMOVED = 'animated:stroke-dash-removed',
  ANIMATED_SHAPE_STROKE_DASH_UPDATED = 'animated:stroke-dash-updated',
  ANIMATED_SHAPE_STROKE_LINECAP_UPDATED = 'animated:stroke-linecap-updated',
  ANIMATED_SHAPE_STROKE_LINEJOIN_UPDATED = 'animated:stroke-linejoin-updated',
  ANIMATED_SHAPE_STROKE_MITER_UPDATED = 'animated:stroke-miter-updated',
  ANIMATED_SHAPE_STROKE_OPACITY_UPDATED = 'animated:stroke-opacity-updated',
  ANIMATED_SHAPE_STROKE_WIDTH_UPDATED = 'animated:stroke-width-updated',
  ANIMATED_SHAPE_TRIM_END_UPDATED = 'animated:trim-end-updated',
  ANIMATED_SHAPE_TRIM_OFFSET_UPDATED = 'animated:trim-offset-updated',
  ANIMATED_SHAPE_TRIM_START_UPDATED = 'animated:trim-start-updated',

  APPEARANCE_CREATED = 'appearance:created',
  CANVAS_ADJUST = 'canvas:adjust',
  CANVAS_ADJUST_CAMERA = 'canvas:adjust-camera',
  CANVAS_COPY = 'canvas:copy',
  CANVAS_CUT = 'canvas:cut',
  CANVAS_DESELECT_ALL = 'canvas:deselect-all',
  CANVAS_DISABLE_PENCONTROL = 'canvas:disable-pen-control',
  CANVAS_DUPLICATE = 'canvas:duplicate',
  CANVAS_NULLIFY_LAST_OBJECT = 'canvas:nullify-last-selected-object',
  CANVAS_OBJECT_REMOVED = 'canvas:object-removed',
  CANVAS_OBJECT_ROTATE_LEFT = 'canvas:object-rotate-left',
  CANVAS_OBJECT_ROTATE_RIGHT = 'canvas:object-rotate-right',
  CANVAS_PASTE = 'canvas:paste',
  CANVAS_RESIZE = 'canvas:resize',
  CANVAS_SELECT_ALL_LAYERS = 'canvas:select-all-layers',
  CANVAS_SELECT_OBJECT = 'canvas:select-object',
  CANVAS_SELECT_OBJECT_MULTIPLE = 'canvas:select-object-multiple',
  CANVAS_TRANSFORMCONTROL_DETACHED = 'canvas:transformcontrol-detached',
  CANVAS_TRANSFORMCONTROL_UPDATED = 'canvas:transformcontrol-updated',
  CANVAS_ZOOM_IN = 'canvas:zoom-in',
  CANVAS_ZOOM_OUT = 'canvas:zoom-out',
  CANVAS_ZOOM_TO_FIT = 'canvas:zoom-to-fit',
  ELLIPSE_CREATED = 'ellipse:created',
  LINE_CREATED = 'line:created',
  LOADED_ANIMATION = 'animation:loaded',
  LOADING_ANIMATION = 'animation:loading',

  MAIN_SCENE_SELECTED = 'scene:main-scene-selected',

  PLAYBACK_GOTO_FIRST_FRAME = 'playback:goto-first-frame',
  PLAYBACK_GOTO_LAST_FRAME = 'playback:goxto-last-frame',
  PLAYBACK_GOTO_NEXT_FRAME = 'playback:goto-next-frame',
  PLAYBACK_GOTO_PREVIOUS_FRAME = 'playback:goto-previous-frame',
  PLAYBACK_GO_BACK_TEN_FRAME = 'playback:go-back-ten-frame',
  PLAYBACK_GO_FORWARD_TEN_FRAME = 'playback:go-forward-ten-frame',

  PLUGIN_CANVAS_UPDATE = 'plugin:canvas-update',

  POLYSTAR_INNER_RADIUS_UPDATED = 'polystar:inner-radius-updated',
  POLYSTAR_INNER_ROUNDNESS_UPDATED = 'polystar:inner-roundness-updated',
  POLYSTAR_OUTER_RADIUS_UPDATED = 'polystar:outer-radius-updated',
  POLYSTAR_OUTER_ROUNDNESS_UPDATED = 'polystar:outer-roundness-updated',
  POLYSTAR_POINTS_UPDATED = 'polystar:points-updated',
  POLYSTAR_ROTATION_UPDATED = 'polystar:rotation-updated',
  PRECOMP_SCENE_SIZE_INIT = 'precompScene:size-init',
  PRECOMP_SCENE_SIZE_UPDATED = 'precompScene:size-updated',
  RECTANGLE_CREATED = 'rect:created',
  RECT_ROUNDNESS_UPDATED = 'rect:roundness-updated',
  RESET = 'reset',
  SCENE_SIZE_UPDATED = 'scene:size-updated',
  SHAPE_CREATED = 'shape:created',
  SHAPE_FILL_COLOR_OPACITY_UPDATED = 'shape:fill-color-opacity-updated',
  SHAPE_FILL_COLOR_UPDATED = 'shape:fill-color-updated',
  SHAPE_FILL_OPACITY_UPDATED = 'shape:fill-opacity-updated',

  SHAPE_FILL_UPDATED = 'shape:fill-updated',
  SHAPE_MOVE_DOWN = 'shape:move-down',
  SHAPE_MOVE_LEFT = 'shape:move-left',
  SHAPE_MOVE_RIGHT = 'shape:move-right',
  SHAPE_MOVE_UP = 'shape:move-up',
  SHAPE_POSITION_UPDATED = 'shape:position-updated',
  SHAPE_SIZE_UPDATED = 'shape:size-updated',
  TIMELINE_BAR_DRAGGING_UPDATED = 'timeline:bar-dragging-updated',
  TIMELINE_BAR_END_TIME_UPDATED = 'timeline:bar-end-time-updated',
  TIMELINE_BAR_RESIZE_LEFT_UPDATED = 'timeline:bar-resize=left-updated',
  TIMELINE_BAR_RESIZE_RIGHT_UPDATED = 'timeline:bar-resize-right-updated',
  TIMELINE_BAR_START_TIME_UPDATED = 'timeline:bar-start-time-updated',
  TIMELINE_CURRENT_FRAME_UPDATED = 'timeline:current-frame-updated',
  TIMELINE_DURATION_UPDATED = 'timeline:duration-updated',
  TIMELINE_FPS_UPDATED = 'timeline:fps-updated',
  TIMELINE_FRAME_UPDATE_ENDED = 'timeline:frame-update-ended',
  TIMELINE_LAYER_MOVE_DIFF_LEVEL = 'timeline:layer-move-diff-level',
  TIMELINE_LAYER_MOVE_DOWN = 'timeline:layer-move-down',
  TIMELINE_LAYER_MOVE_SAME_LEVEL = 'timeline:layer-move-same-level',
  TIMELINE_LAYER_MOVE_UP = 'timeline:layer-move-up',
  TIMELINE_PAUSE = 'timeline:pause',
  TIMELINE_PLAY = 'timeline:play',
  TIMELINE_PRECOMP_EDIT_SCENE = 'timeline:edit-scene',
  TIMELINE_RENAME_LAYER_END = 'timeline:rename-layer-end',
  TIMELINE_RENAME_LAYER_START = 'timeline:rename-layer-start',
  TIMELINE_SHOW_ALL_KEYFRAMES = 'timeline:show-all-keyframes',
  TIMELINE_SHOW_ANIMATED_PROPERTIES = 'timeline:show-animated-properties',
  TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES = 'timeline:layer-animate-all-properties',
  TIMELINE_TOGGLE_PLAY = 'timeline:toggle-play',
  TIMELINE_TOGGLE_VISIBLE = 'timeline:toggle-visible',
  TOOLKIT_GET_LATEST = 'toolkit:get-latest',
  TOOLKIT_JSON_IMPORTED = 'toolkit:json-imported',
  TOOLKIT_NODE_SCALE_UPDATED = 'toolkit:node-scale-updated',
  TOOLKIT_NODE_SIZE_UPDATED = 'toolkit:node-size-updated',
  TOOLKIT_STATE_HYDRATED = 'toolkit:state-hydrated',
  TOOLKIT_STATE_UPDATED = 'toolkit:state-updated',
  UI_ANIMATED_DELETE = 'ui:animated-delete',
  UI_DELETE = 'ui:delete',
  UI_REDO = 'ui:redo',
  UI_UNDO = 'ui:undo',
}
