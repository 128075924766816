/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

interface RenderVisibilityProp {
  children: React.ReactNode;
  hidden: boolean;
}

export const RenderVisibility: React.FC<RenderVisibilityProp> = ({ children, hidden }: RenderVisibilityProp) => {
  return (
    <>
      {hidden && <div className="pointer-events-none absolute	opacity-0">{children}</div>}
      {!hidden && children}
    </>
  );
};
