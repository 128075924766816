/**
 * Copyright 2022 Design Barn Inc.
 */

import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { CanvasSlice } from './canvasSlice';
import { createCanvasSlice } from './canvasSlice';
import type { PluginSlice } from './pluginSlice';
import { createPluginSlice } from './pluginSlice';
import type { ProjectSlice } from './projectSlice';
import { createProjectSlice } from './projectSlice';
import type { TimelineSlice } from './timelineSlice';
import { createTimelineSlice } from './timelineSlice';
import type { ToolkitSlice } from './toolkitSlice';
import { createToolkitSlice } from './toolkitSlice';
import type { UiSlice } from './uiSlice';
import { createUiSlice } from './uiSlice';
import type { UserSlice } from './userSlice';
import { createUserSlice } from './userSlice';

export type StoreSlice = ToolkitSlice & UiSlice & TimelineSlice & CanvasSlice & UserSlice & ProjectSlice & PluginSlice;

export const useCreatorStore = create<StoreSlice>()(
  devtools(
    subscribeWithSelector(
      immer((...args) => ({
        ...createToolkitSlice(...args),
        ...createUiSlice(...args),
        ...createTimelineSlice(...args),
        ...createCanvasSlice(...args),
        ...createUserSlice(...args),
        ...createProjectSlice(...args),
        ...createPluginSlice(...args)
      })),
    ),
    { enabled: import.meta.env.DEV },
  ),
);
