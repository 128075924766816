/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Keyframe, Value } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit } from '~/lib/toolkit';

const methodNames = {
  interpolator: 'interpolator',
  isStatic: 'isStatic',
  frame: 'frame',
  parentProperty: 'parentProperty',
  parentTrack: 'parentTrack',
  value: 'value',
  setFrameNo: 'setFrameNo',
  setInterpolator: 'setInterpolator',
  setIsStatic: 'setIsStatic',
  setValue: 'setValue',
};

// TODO: remove modifiers after editing 'getObjectMethods' function to
// accept additionalMethods without first passing in modifiers
const keyframeModifiers = {
  setFrameNo: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  setInterpolator: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  setIsStatic: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
};

function interpolate(keyframe: Keyframe, currentFrame: number, vmNextKeyframe: Keyframe): Value {
  const nextKeyframe = toolkit.getKeyframeById(vmNextKeyframe.frameId as string);

  return keyframe.interpolate(currentFrame, nextKeyframe as Keyframe<Value, never>);
}

const additionalMethods = {
  interpolate,
};

function getKeyframeMethods(plugin: Plugin, keyframe: Keyframe): ObjectMethods {
  return getObjectMethods(plugin, methodNames, keyframe, keyframeModifiers, additionalMethods);
}

export function createKeyframeObj(plugin: Plugin, keyframe: Keyframe): object {
  const keyframeObj = {
    frameId: keyframe.frameId,
    valueType: 'Keyframe',
  };

  registerObjectMethods(plugin, keyframe, keyframeObj, [getKeyframeMethods]);

  return keyframeObj;
}
