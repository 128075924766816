/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, Scene, VectorJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';

import { AnimatedPositionX, AnimatedPositionY } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { TimelineDualNumberInput } from '~/components/Elements/Input/TimelineDualNumberInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById, toolkit, setNodePosition } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedPosition: React.FC<AnimatedInputProp> = ({ animatedProp, parentId }) => {
  // const { x, y } = animatedProp.value as VectorJSON;
  // temp fix
  const animatedValue = animatedProp.value as VectorJSON | null;
  const { x, y } = animatedValue ? animatedValue : { x: 250, y: 250 };
  const [sceneIndex, selectedNodeId, selectedNodePosition] = useCreatorStore(
    (state) => [
      state.toolkit.sceneIndex,
      // TODO: handle multi select
      state.ui.selectedNodesInfo[0]?.nodeId as string,
      state.ui.selectedNodeTransform.position,
    ],
    shallow,
  );

  const { posX, posY } = useMemo(() => {
    if (selectedNodeId === parentId && selectedNodePosition) {
      return { posX: selectedNodePosition.x, posY: selectedNodePosition.y };
    }

    return { posX: x, posY: y };
  }, [parentId, selectedNodeId, selectedNodePosition, x, y]);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeById(toolkit.scenes[sceneIndex] as Scene, animatedProp.id) as AVLayer;

      if (node.parent) {
        if (result.name === 'posX') {
          setNodePosition(node.parent as AVLayer, [result.value, y]);
        } else if (result.name === 'posY') {
          setNodePosition(node.parent as AVLayer, [x, result.value]);
        }
        emitter.emit(EmitterEvent.SHAPE_POSITION_UPDATED);
      }
    },
    [x, y, sceneIndex, animatedProp],
  );

  return (
    <div className="flex items-center">
      <TimelineDualNumberInput
        onChange={handleOnChange}
        left={posX}
        right={posY}
        leftOption={{
          name: 'posX',
          label: <AnimatedPositionX className="h-3 w-3" />,
        }}
        rightOption={{
          name: 'posY',
          label: <AnimatedPositionY className="h-3 w-3" />,
        }}
      />
    </div>
  );
};
