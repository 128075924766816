/**
 * Copyright 2023 Design Barn Inc.
 */

import { useCallback } from 'react';

export const wrapCallbacks = (fns: unknown, dependencies: unknown[]): unknown => {
  const functionNames = Object.keys(fns as Record<string, string>);

  return Object.values(fns as Record<string, string>).reduce(
    (final: Record<string, unknown>, fn: unknown, index: number) => {
      const funcName = functionNames[index];

      if (funcName) {
        final[funcName] = useCallback(fn as () => unknown, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
      }

      return final;
    },
    {},
  );
};
