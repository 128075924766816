/**
 * Copyright 2022 Design Barn Inc.
 */

import type { LayerType } from '@lottiefiles/lottie-js/dist';
import type { DagNode, Scene } from '@lottiefiles/toolkit-js';
import produce from 'immer';
import type { Vector3 } from 'three';
import type { StateCreator } from 'zustand';

import { PropertyPanelType } from './constant';
import type { GlobalModalConstant } from './constant/modal';

import type { StoreSlice } from '.';
import type { LibraryType } from '~/data/constant';
import { ToolType } from '~/data/constant';
import type { CMesh, CObject3D } from '~/features/canvas';
import type { LayerUI, LayerUIMap } from '~/features/timeline';
import {
  getNodeById,
  toolkit,
  getCurrentShape,
  getCurrentTransform,
  getPropertyPanelType,
  getCurrentGFillShapes,
  getCurrentFillShape,
  getCurrentStrokeShape,
  getFillShape,
  getStrokeShape,
  getAppearanceList,
  updateLayerHighlight,
  getCurrentGFillShape,
  getGradientFillShape,
  getCurrentAppearance,
  removeLayerHighlight,
} from '~/lib/toolkit';
import type {
  AppStroke,
  CurrentGFillShape,
  CurrentFillShape,
  AppFill,
  CurrentStrokeShape,
  CurrentShape,
  AppGFill,
  CurrentTransformProperty,
} from '~/lib/toolkit';

export enum ZoomDirection {
  LEFT = 'LEFT',
  NONE = 'NONE',
  RIGHT = 'RIGHT',
}

export interface LoaderProps {
  animationSrc?: Record<string, never> | null;
  description?: string | null;
  isLoading: boolean;
  title?: string | null;
}

export interface AlertProps {
  advanced?: {
    alertId: string;
    runOnceEveryPageRefresh?: boolean;
  };
  alertColor?: string | null;
  handle?: (...args: any[]) => unknown;
  handleText?: string | null;
  text: string | null;
  timeout?: number | null;
}

export interface IGlobalModal {
  options?: Record<string, string | boolean>;
  state: GlobalModalConstant | null;
}

export interface TransformInfo {
  opacity?: number;
  pivot?: Vector3;
  position?: Vector3;
  rotation?: number;
  scale?: Vector3;
}

export interface FeatureCheckerProp {
  skip?: boolean;
  unsupportedFeatures?: string[];
}

export enum AnimationLoaderStatus {
  Empty = 'Empty',
  Loaded = 'Loaded',
  Loading = 'Loading',
  Reverted = 'Reverted',
}

export interface AnimationLoader {
  avatarUrl?: string | null;
  name?: string | null;
  objectId?: string;
  status: AnimationLoaderStatus;
  url: string | null;
  username?: string | null;
}

export interface NodeInfoProps {
  depth: number;
  id: string;
}

export interface SelectedNodeInfo {
  fillShape: CurrentFillShape;
  fillShapes: AppFill;
  gFillShape: CurrentGFillShape;
  gFillShapes: AppGFill;
  nodeId: string;
  nodeTransform: CurrentTransformProperty;
  nodeType: LayerType;
  propertyPanel: PropertyPanelType;
  shape: CurrentShape;
  strokeShape: CurrentStrokeShape;
  strokeShapes: AppStroke;
}

export interface UiSlice {
  ui: {
    addToSelectedNodes(toolkitIds: string[], replaceCurrentSelection?: boolean): void;
    alert: AlertProps;
    animatedPropertyPopupUI: Record<string, string>;
    animationLoader: AnimationLoader;
    appearanceList: Record<string, string>;
    canvasHoveredNodeId: string;
    canvasMap: Map<string, CObject3D | CMesh>;
    currentLibrary: LibraryType | null;
    currentPropertyPanel: PropertyPanelType;
    currentTool: ToolType;
    featureChecker: FeatureCheckerProp;
    getLayerSimplifiedUI(id: string): unknown;
    getLayerUI(id: string): LayerUI | undefined;

    globalModal: IGlobalModal | null;
    hasShownOnetimeTooltip: boolean;
    isAddingToolkitListener: boolean;
    isPastable: boolean;
    layerMap: LayerUIMap;
    layerSimplifiedMap: LayerUIMap | null;
    loader: LoaderProps;
    newPluginModalOpen: boolean;
    openHiddenPlayer: boolean;
    pivotVisible: boolean;
    pluginBrowserOpen: boolean;
    removeCanvasMapItem(toolkitId: string): void;
    removeSelectedNodes(toolkitIds?: string[]): void;
    resetLayerUI(): void;
    resetSelection(): void;
    scaleRatioLocked: boolean;
    selectedGroupTransform: TransformInfo;
    selectedIdAfterCreated: string | null;
    selectedNestedNodesInfo: SelectedNodeInfo[];
    selectedNodeTransform: TransformInfo;
    selectedNodesInfo: SelectedNodeInfo[];
    setAlert(data: AlertProps): void;
    (data: Record<string, string> | null): void;
    setAnimatedPropertyPopupUI(data: Record<string, string> | null): void;
    setAnimationLoader(data: AnimationLoader): void;
    setCanvasHoveredNodeId(id: string): void;
    setCanvasMap(toolkitId: string, object: CObject3D | CMesh): void;
    setCurrentLibrary(lib: LibraryType | null): void;
    setCurrentTool(tool: ToolType): void;
    setFeatureChecker(data: FeatureCheckerProp): void;
    setGlobalModal(modalState: GlobalModalConstant | null, options?: unknown): void;
    setHasShownOnetimeTooltip(): void;
    setIsAddingToolkitListener(status: boolean): void;
    setIsPastable(pastable: boolean): void;

    setLayerSimplifiedUI(id: string, key: string, value: unknown): void;
    setLayerUI(id: string, key: string, value: unknown): void;
    setLoader(info: LoaderProps): void;
    setNewPluginModalOpen(open: boolean): void;
    setOpenHiddenPlayer(open: boolean): void;
    setPivotVisibility(visible: boolean): void;
    setPluginBrowserOpen(open: boolean): void;
    setPropertyPanel(type: PropertyPanelType): void;
    setScaleRatioLocked(isLocked: boolean): void;
    setSelectedGroupTransformation(transformInfo: TransformInfo): void;
    setSelectedIdAfterCreated(id: string): void;
    setSelectedNodeTransformation(transformInfo: TransformInfo): void;
    setSizeRatioLocked(isLocked: boolean): void;
    setTestAnimationOpen(open: boolean): void;
    setZoomPercentage(percentage: number): void;
    sizeRatioLocked: boolean;
    testAnimationOpen: boolean;
    zoomPercentage: number;
  };
}

export const createUiSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  UiSlice
> = (set, get) => ({
  ui: {
    animatedPropertyPopupUI: {},

    globalModal: null,
    alert: {
      text: null,
    },
    loader: {
      isLoading: false,
    },

    layerMap: new Map<string, LayerUI>(),
    layerSimplifiedMap: new Map<string, LayerUI>(),
    canvasMap: new Map<string, CObject3D>(),
    currentPropertyPanel: PropertyPanelType.Composition,
    currentLibrary: null,
    currentTool: ToolType.Move,
    canvasHoveredNodeId: '',
    selectedGroupTransform: {},
    selectedNodeTransform: {},
    selectedNodesInfo: [],
    selectedNestedNodesInfo: [],
    selectedIdAfterCreated: null,
    testAnimationOpen: false,
    newPluginModalOpen: false,
    pluginBrowserOpen: false,
    zoomPercentage: 100,
    appearanceList: {},
    hasShownOnetimeTooltip: false,
    scaleRatioLocked: false,
    sizeRatioLocked: false,
    pivotVisible: true,
    openHiddenPlayer: false,
    isAddingToolkitListener: false,
    isPastable: false,
    featureChecker: {
      skip: false,
      unsupportedFeatures: [],
    },
    animationLoader: {
      status: AnimationLoaderStatus.Empty,
      url: null,
    },
    setAnimatedPropertyPopupUI: (data: Record<string, string> | null): void => {
      set((state) => {
        state.ui.animatedPropertyPopupUI = {
          ...state.ui.animatedPropertyPopupUI,
          ...data,
        };
      });
    },
    addToSelectedNodes: (toolkitIds: string[], removeCurrentSelection: boolean): void => {
      const sceneIndex = get().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) {
        return;
      }

      // Remove old selection
      // note: calling get().ui.removeSelectedNodes() doesn't seem to update state properly
      if (removeCurrentSelection && get().ui.selectedNodesInfo.length) {
        set((state) => {
          state.ui = produce(state.ui, (draft) => {
            const selectedNodes = draft.selectedNodesInfo;

            selectedNodes.forEach((node) => removeLayerHighlight(draft.layerMap, node.nodeId));

            draft.selectedNodesInfo = [];
            draft.selectedNestedNodesInfo = [];
          });
        });
      }

      const getNodeInfo = (node: DagNode | null, id: string): unknown => {
        const shape = getCurrentShape(node);

        // GNI
        const nodeTransform = getCurrentTransform(node);
        const fillShape = getCurrentFillShape(getFillShape(node));
        const strokeShape = getCurrentStrokeShape(getStrokeShape(node));
        const gFillShape = getCurrentGFillShape(getGradientFillShape(node));
        const gFillShapes = getCurrentGFillShapes(node);
        const propertyPanel = getPropertyPanelType(scene, id);

        const [appearanceList] = getAppearanceList(get, node);
        const { currentFillShapes: fillShapes, currentStrokeShapes: strokeShapes } = getCurrentAppearance(
          get,
          appearanceList,
        );

        return {
          fillShape,
          fillShapes,
          gFillShape,
          gFillShapes,
          nodeId: id,
          nodeTransform,
          nodeType: (node?.nodeType as unknown) as LayerType,
          propertyPanel,
          shape,
          strokeShape,
          strokeShapes,
          appearanceList,
        };
      };

      set((state) => {
        return produce(state, (draft) => {
          // Remove existing nodes with the same ids
          draft.ui.selectedNodesInfo = draft.ui.selectedNodesInfo.filter((node) => {
            return !toolkitIds.includes(node.nodeId);
          });

          // For each id, create a SelectedNodeInfo object
          for (const id of toolkitIds) {
            updateLayerHighlight(draft.ui.layerMap, id);

            const node = getNodeById(scene, id);

            if (!node) return;
            let secondaryNode: unknown | null = null;

            if (node.type === 'SHAPE') {
              const getSimplifiedMap = draft.ui.getLayerSimplifiedUI;
              const nodeSimplified = getSimplifiedMap(id);

              if (nodeSimplified.descendant.length > 0) {
                // Handle one regular shape only
                nodeSimplified.descendant.forEach((descId: string): void => {
                  const descNode = getNodeById(scene, descId);

                  if (['el', 'rc', 'sr'].includes(descNode.type)) {
                    secondaryNode = descNode;
                  }
                });
              }
            }

            const nodeInfo = getNodeInfo(node, id);

            draft.ui.selectedNodesInfo.push(nodeInfo);

            const {
              appearanceList,
              fillShape,
              fillShapes,
              gFillShape,
              gFillShapes,
              nodeTransform,
              shape,
              strokeShape,
              strokeShapes,
            } = nodeInfo;

            // TODO: update the following states to account for multiple nodes
            // as part of the ticket to update the property panel for multi selection
            draft.toolkit.currentTransform = nodeTransform;
            draft.toolkit.currentStrokeShapes = strokeShapes;
            draft.toolkit.currentGFillShape = gFillShape;
            draft.toolkit.currentFillShapes = fillShapes;
            draft.toolkit.currentFillShape = fillShape;
            draft.toolkit.currentStrokeShape = strokeShape;
            draft.toolkit.currentGFillShapes = gFillShapes;
            draft.toolkit.currentShape = shape;
            draft.ui.currentPropertyPanel =
              draft.ui.selectedNodesInfo.length > 1 ? PropertyPanelType.MultiObjects : getPropertyPanelType(scene, id);
            draft.ui.appearanceList = appearanceList;

            if (secondaryNode) {
              const secondaryNodeInfo: unknown | null = getNodeInfo(secondaryNode, secondaryNode.nodeId);

              if (secondaryNodeInfo) {
                draft.ui.selectedNestedNodesInfo.push(secondaryNodeInfo);
                draft.toolkit.currentSecondary.currentShape = secondaryNodeInfo.shape;
              }
            }
          }
        });
      });
    },

    removeSelectedNodes: (toolkitIds?: string[]) => {
      if (get().ui.selectedNodesInfo.length === 0) {
        return;
      }

      //   const getNodeInfo = (id, scene) => {
      const getNodeInfo = (id: string, scene: Scene): unknown => {
        const node = getNodeById(scene, id);

        const shape = getCurrentShape(node);

        const nodeTransform = getCurrentTransform(node);
        const fillShape = getCurrentFillShape(getFillShape(node));
        const strokeShape = getCurrentStrokeShape(getStrokeShape(node));
        const gFillShape = getCurrentGFillShape(getGradientFillShape(node));
        const gFillShapes = getCurrentGFillShapes(node);

        const [appearanceList] = getAppearanceList(get, node);
        const { currentFillShapes: fillShapes, currentStrokeShapes: strokeShapes } = getCurrentAppearance(
          get,
          appearanceList,
        );

        return {
          nodeTransform,
          strokeShapes,
          gFillShape,
          fillShapes,
          fillShape,
          strokeShape,
          gFillShapes,
          shape,
          appearanceList,
        };

        // draft.toolkit.currentTransform = nodeTransform;
        //   draft.toolkit.currentStrokeShapes = strokeShapes;
        //   draft.toolkit.currentGFillShape = gFillShape;
        //   draft.toolkit.currentFillShapes = fillShapes;
        //   draft.toolkit.currentFillShape = fillShape;
        //   draft.toolkit.currentStrokeShape = strokeShape;
        //   draft.toolkit.currentGFillShapes = gFillShapes;
        //   draft.toolkit.currentShape = shape;
        //   draft.ui.currentPropertyPanel = getPropertyPanelType(scene, id);
        //   draft.ui.appearanceList = appearanceList;
      };

      set((state) => {
        return produce(state, (draft) => {
          const selectedNodes = draft.ui.selectedNodesInfo;
          //   const selectedNodes = draft.ui.selectedNestedNodesInfo;

          const selectedNodeIds = selectedNodes.map((node) => node.nodeId);

          const idsToRemove = toolkitIds ?? selectedNodeIds;

          idsToRemove.forEach((id) => removeLayerHighlight(draft.ui.layerMap, id));
          draft.ui.selectedNodesInfo = selectedNodes.filter((node) => !idsToRemove.includes(node.nodeId));

          // Update the property panel
          const scene = toolkit.scenes[get().toolkit.sceneIndex];

          if (!scene) {
            return;
          }

          // TODO: temporarily assigning a single id - edit to account for multiple nodes
          // as part of the ticket to update the property panel for multi selection
          const id = draft.ui.selectedNodesInfo[0]?.nodeId ?? '';

          const {
            appearanceList,
            fillShape,
            fillShapes,
            gFillShape,
            gFillShapes,
            nodeTransform,
            shape,
            strokeShape,
            strokeShapes,
          } = getNodeInfo(id, scene);

          // TODO: update the following states to account for multiple nodes
          // as part of the ticket to update the property panel for multi selection
          draft.toolkit.currentTransform = nodeTransform;
          draft.toolkit.currentStrokeShapes = strokeShapes;
          draft.toolkit.currentGFillShape = gFillShape;
          draft.toolkit.currentFillShapes = fillShapes;
          draft.toolkit.currentFillShape = fillShape;
          draft.toolkit.currentStrokeShape = strokeShape;
          draft.toolkit.currentGFillShapes = gFillShapes;
          draft.toolkit.currentShape = shape;
          draft.ui.currentPropertyPanel = getPropertyPanelType(scene, id);
          draft.ui.appearanceList = appearanceList;

          const nestedId = draft.ui.selectedNestedNodesInfo[0]?.nodeId ?? '';

          if (nestedId) {
            const nestedNodeInfo = getNodeInfo(nestedId, scene);

            draft.toolkit.currentSecondary.currentShape = nestedNodeInfo.shape;
          }
        });
      });
    },

    setFeatureChecker: (data: FeatureCheckerProp) => {
      set((state) => {
        state.ui.featureChecker = {
          ...state.ui.featureChecker,
          ...data,
        };
      });
    },
    setAnimationLoader: (data: AnimationLoader) => {
      set((state) => {
        state.ui.animationLoader = {
          ...state.ui.animationLoader,
          ...data,
        };
      });
    },
    setIsAddingToolkitListener: (status: boolean) => {
      set((state) => {
        state.ui.isAddingToolkitListener = status;
      });
    },
    setIsPastable: (pastable: boolean) => {
      set((state) => {
        state.ui.isPastable = pastable;
      });
    },
    setGlobalModal: (modalState: GlobalModalConstant | null, options: Record<string, string | boolean>) => {
      set((state) => {
        state.ui.globalModal = {
          state: modalState,
          options,
        };
      });
    },
    getLayerUI: (id: string) => {
      return get().ui.layerMap.get(id);
    },
    resetLayerUI: () => {
      set((state) => {
        state.ui.layerMap = new Map<string, LayerUI>();
        state.ui.layerSimplifiedMap = new Map<string, LayerUI>();
      });
    },
    setLayerUI: (id: string, key: string, value: unknown) => {
      set((state) => {
        const layerUI = state.ui.layerMap.get(id);

        if (layerUI && key in layerUI) {
          layerUI[key] = value;
        }
      });
    },
    getLayerSimplifiedUI: (id: string) => {
      return {
        ...get().ui.layerMap.get(id),
        ...(get().ui.layerSimplifiedMap?.get(id) || {}),
      };
    },
    setLayerSimplifiedUI: (id: string, key: string, value: unknown) => {
      set((state) => {
        const layerSimplifiedMap = state.ui.layerSimplifiedMap;
        const layerUI = layerSimplifiedMap?.get(id) || null;

        if (layerUI) {
          layerUI[key] = value;
        } else {
          layerSimplifiedMap?.set(id, { [key]: value });
        }
      });
    },
    setCanvasMap: (toolkitId: string, object: CObject3D | CMesh) => {
      set((state) => {
        const canvasMap = state.ui.canvasMap;

        canvasMap.set(toolkitId, object);
      });
    },
    removeCanvasMapItem: (toolkitId: string) => {
      set((state) => {
        const canvasMap = state.ui.canvasMap;

        canvasMap.delete(toolkitId);
      });
    },
    setCurrentLibrary: (lib: LibraryType | null) =>
      set((state) => {
        state.ui.currentLibrary = lib;
      }),
    setCurrentTool: (tool: ToolType) =>
      set((state) => {
        state.ui.currentTool = tool;
      }),
    setZoomPercentage: (percentage: number) =>
      set((state) => {
        state.ui.zoomPercentage = percentage;
      }),
    setPropertyPanel: (type: PropertyPanelType) => {
      set((state) => {
        state.ui.currentPropertyPanel = type;
      });
    },
    setNewPluginModalOpen: (open: boolean) => {
      set((state) => {
        state.ui.newPluginModalOpen = open;
      });
    },
    setPluginBrowserOpen: (open: boolean) => {
      set((state) => {
        state.ui.pluginBrowserOpen = open;
      });
    },
    setTestAnimationOpen: (open: boolean) => {
      set((state) => {
        state.ui.testAnimationOpen = open;
      });
    },
    setCanvasHoveredNodeId: (id: string) => {
      set((state) => {
        state.ui.canvasHoveredNodeId = id;
      });
    },
    setSizeRatioLocked: (isLocked: boolean) => {
      set((state) => {
        state.ui.sizeRatioLocked = isLocked;
      });
    },
    setSelectedGroupTransformation: (transformInfo: TransformInfo) => {
      set((state) => {
        state.ui.selectedGroupTransform = { ...state.ui.selectedGroupTransform, ...transformInfo };
      });
    },
    setSelectedNodeTransformation: (transformInfo: TransformInfo) => {
      set((state) => {
        state.ui.selectedNodeTransform = transformInfo;
      });
    },
    setSelectedIdAfterCreated: (id: string) => {
      set((state) => {
        state.ui.selectedIdAfterCreated = id;
      });
    },
    setScaleRatioLocked: (isLocked: boolean) => {
      set((state) => {
        state.ui.scaleRatioLocked = isLocked;
      });
    },
    setPivotVisibility: (visible: boolean) => {
      set((state) => {
        state.ui.pivotVisible = visible;
      });
    },
    resetSelection: () => {
      set((state) => {
        state.toolkit.currentFrame = 0;
        state.ui.currentPropertyPanel = PropertyPanelType.Composition;
        state.ui.removeSelectedNodes();
      });
    },
    setLoader: (obj: LoaderProps) => {
      set((state) => {
        state.ui.loader = {
          ...{
            isLoading: false,
            animationSrc: null,
            title: null,
            description: null,
          },
          ...obj,
        };
      });
    },
    setAlert: (obj: AlertProps) => {
      set((state) => {
        state.ui.alert = {
          ...{
            alertColor: null,
            handleText: null,
            text: null,
            timeout: null,
          },
          ...obj,
        };
      });
    },
    setHasShownOnetimeTooltip: () => {
      set((state) => {
        state.ui.hasShownOnetimeTooltip = true;
      });
    },
    setOpenHiddenPlayer: (open: boolean) => {
      set((state) => {
        state.ui.openHiddenPlayer = open;
      });
    },
  },
});
