/**
 * Copyright 2023 Design Barn Inc.
 */

import type { LinearInterpolator } from '@lottiefiles/toolkit-js';

import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import type { Plugin } from '~/plugins/Plugin';

const methodNames = {
  interpolate: 'interpolate',
  toJSON: 'toJSON',
};

function getLinearInterpolatorMethods(plugin: Plugin, interpolator: LinearInterpolator): ObjectMethods {
  return getObjectMethods(plugin, methodNames, interpolator);
}

interface LinearInterpolatorJSON {
  interpolatorType: string;
}

export function createLinearInterpolatorObject(
  plugin: Plugin,
  interpolator: LinearInterpolator,
): LinearInterpolatorJSON {
  const interpolatorObject = {
    interpolatorType: 'Linear',
  };

  registerObjectMethods(plugin, interpolator, interpolatorObject, [getLinearInterpolatorMethods]);

  return interpolatorObject;
}
