/**
 * Copyright 2022 Design Barn Inc.
 */

// Reference: https://codesandbox.io/s/icy-water-ktwif6?file=/src/Tooltip.tsx:0-2124
import type { Placement } from '@floating-ui/react-dom-interactions';
import {
  offset,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  useDelayGroupContext,
  useDelayGroup,
  arrow,
} from '@floating-ui/react-dom-interactions';
import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import React, { cloneElement, useMemo, useRef, useState, useCallback } from 'react';

interface TooltipProps {
  bgColorClass?: string;
  children: JSX.Element;
  label: string;
  offset: { crossAxis: number; mainAxis: number };
  placement?: Placement;
  shortcut?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  bgColorClass,
  children,
  label,
  offset: offsetProps,
  placement = 'right',
  shortcut,
}) => {
  const { delay, setCurrentId } = useDelayGroupContext();
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const { context, floating, reference, strategy, x, y } = useFloating({
    placement,
    open,
    onOpenChange(_open) {
      setOpen(_open);

      if (_open) {
        setCurrentId(label);
      }
    },
    middleware: [offset(offsetProps), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useHover(context, { delay, restMs: 100 }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
    useDelayGroup(context, { id: label }),
  ]);

  const framerTransition = useMemo((): Transition => {
    let transition: Transition = { type: 'spring', damping: 20, stiffness: 300 };

    if (typeof delay === 'object') {
      // When in "grouped phase", make the transition faster
      if (delay.open === 1) {
        transition = { duration: 0.1 };
      } else {
        transition = { ...transition, delay: (delay.open || 0) / 1000 };
      }
    }

    return transition;
  }, [delay]);

  const arrowStyle = useMemo(() => {
    if (placement === 'bottom') return { top: -4, left: `calc(46% - ${offsetProps.crossAxis}px)` };
    if (placement === 'right') return { top: '45%', left: -4 };
    if (placement === 'left') return { top: '45%', right: -4 };
    if (placement === 'top') return { bottom: -4, left: `45% - ${offsetProps.crossAxis}px)` };

    return { bottom: -4, left: -4 };
  }, [offsetProps.crossAxis, placement]);

  let defaultBG = 'bg-gray-600';

  if (bgColorClass) {
    defaultBG = bgColorClass;
  }

  const handleMultiKeyShortCut = useCallback((): string[] | null => {
    return shortcut ? shortcut.split(' ') : null;
  }, [shortcut]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, scale: 0.85 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={framerTransition}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
            className={`z-tooltip flex whitespace-nowrap rounded-md px-[10px] py-[6px] text-caption text-white ${defaultBG}`}
          >
            {label}

            <div className="ml-1 flex">
              {shortcut &&
                handleMultiKeyShortCut()?.map((key) => {
                  return (
                    <div key={key} className="ml-1 h-[18px] min-w-[15px] rounded-sm bg-gray-700 px-1 text-center">
                      {key}
                    </div>
                  );
                })}
            </div>

            <div className={`absolute h-2 w-2 rotate-45 ${defaultBG}`} style={arrowStyle} ref={arrowRef} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
