/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StarShape } from '@lottiefiles/toolkit-js/dist';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { PolygonRadius, StarOuterRadius } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MIN_POLYSTAR_RADIUS, MAX_POLYSTAR_RADIUS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface PolyStarOuterRadiusProps {
  isStar?: boolean;
}

export const PolystarOuterRadiusField: React.FC<PolyStarOuterRadiusProps> = ({ isStar = false }) => {
  const labelIcon = useMemo(
    () => (isStar ? <StarOuterRadius className="h-4 w-4" /> : <PolygonRadius className="h-4 w-4" />),
    [isStar],
  );

  const [
    currentShapeNode,
    outerRadius,
    setOuterRadius,
    setPolystarVariableIsAnimated,
    currentKeyFrame,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentSecondary?.currentShapeNode,
      state.toolkit.currentSecondary?.currentShape.polystar.outerRadius,
      state.toolkit.setPolystarOuterRadius,
      state.toolkit.setPolystarVariableIsAnimated,
      state.toolkit.currentSecondary?.currentShape.polystar.outerRadiusCurrentKeyFrame,
    ],
    shallow,
  );

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setOuterRadius(result.value);
      emitter.emit(EmitterEvent.POLYSTAR_OUTER_RADIUS_UPDATED);
    },
    [setOuterRadius],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (currentKeyFrame === '') {
      setPolystarVariableIsAnimated('outerRadius', true);
      setOuterRadius(outerRadius);
    } else if ((currentShapeNode as StarShape).outerRadius.keyFrames.length === 1) {
      setPolystarVariableIsAnimated('outerRadius', false);
      setOuterRadius(outerRadius);
    } else {
      removeKeyFrame(currentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_OUTER_RADIUS_UPDATED);
    stateHistory.endAction();
  }, [setOuterRadius, setPolystarVariableIsAnimated, currentShapeNode, currentKeyFrame, outerRadius]);

  return (
    <div className="w-full">
      <NumberInput
        name="outer-radius"
        label={labelIcon}
        value={(currentShapeNode as StarShape)?.outerRadius?.value.value ?? outerRadius}
        onChange={handleOnChange}
        min={MIN_POLYSTAR_RADIUS}
        max={MAX_POLYSTAR_RADIUS}
        showKeyframe
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={Boolean(currentKeyFrame)}
      />
    </div>
  );
};
