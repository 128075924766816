/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Plugin } from '../Plugin';

import { registerClasses } from './vmInterface/marshal/class';

import { shimFunctions } from './index';

export function setupShim(plugin: Plugin): void {
  // set up the shim lib
  plugin.creatorHandle = plugin.vm.newObject();
  plugin.classesHandle = plugin.vm.newObject();
  // registers the shim functions in the /shim folder
  Object.values(shimFunctions).forEach((fn) => {
    if (fn.constructor.name === 'AsyncFunction') {
      fn(plugin) as Promise<void>;
    } else {
      fn(plugin);
    }
  });

  registerClasses(plugin);

  // Sends a message to the UI iframe
  const sendMessage = plugin.scope.manage(
    plugin.vm.newFunction('sendMessage', (data) => {
      const vmJSON = plugin.vm.getProp(plugin.vm.global, 'JSON');
      const stringify = plugin.vm.getProp(vmJSON, 'stringify');
      const stringifyResult = plugin.vm.callFunction(stringify, vmJSON, data);

      stringify.dispose();
      vmJSON.dispose();

      data.dispose();

      if (stringifyResult.error) {
        stringifyResult.error.dispose();
        plugin.vm.throw(new Error('failed to stringify data'));

        return;
      }

      const message = plugin.vm.getString(stringifyResult.value);

      stringifyResult.value.dispose();

      // send message to UI here
      // TODO: handle message based on message type (obj, string, etc)
      setTimeout(() => {
        if (plugin._uiIframe?.contentWindow) {
          plugin._uiIframe.contentWindow.postMessage(JSON.parse(message), '*');
        } else {
          plugin.vm.throw(new Error('No plugin UI to send message to.'));
        }
      }, 100);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle, 'sendMessage', { value: sendMessage, configurable: false });
  plugin.vm.setProp(plugin.vm.global, 'creator', plugin.creatorHandle);
  plugin.vm.setProp(plugin.vm.global, 'classes', plugin.classesHandle);

  plugin._messageListener = (event: MessageEvent) => {
    // only handles messages if coming from the plugin's iframe
    if (plugin._uiIframe && event.source === plugin._uiIframe.contentWindow) {
      // do something with message
      const message = event.data;

      plugin.dispatchMessage(message);
    }
  };
  window.addEventListener('message', plugin._messageListener);
}
