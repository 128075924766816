/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Shape } from '@lottiefiles/toolkit-js/dist';
import React, { useCallback } from 'react';
import type { IUniform } from 'three';
import { shallow } from 'zustand/shallow';

import { Roundness } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const RectangleRoundnessField: React.FC = () => {
  const [
    currentShapeNode,
    roundness,
    setRoundness,
    setRectangleRoundnessIsAnimated,
    size,
    currentKeyFrame,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentSecondary?.currentShapeNode,
      state.toolkit.currentSecondary?.currentShape.rect.roundness,
      state.toolkit.setRectangleRoundness,
      state.toolkit.setRectangleRoundnessIsAnimated,
      state.toolkit.currentSecondary?.currentShape.size,
      state.toolkit.currentSecondary?.currentShape.rect.roundnessCurrentKeyFrame,
    ],
    shallow,
  );

  const handleOnChange = useCallback(
    (result: IUniform<number>) => {
      stateHistory.beginAction();
      setRoundness(result.value);
      emitter.emit(EmitterEvent.RECT_ROUNDNESS_UPDATED, result);
    },
    [setRoundness],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();

    if (currentKeyFrame === '') {
      setRectangleRoundnessIsAnimated(true);
      setRoundness(roundness);
    } else if ((currentShapeNode as Shape).roundness.keyFrames.length === 1) {
      setRectangleRoundnessIsAnimated(false);
      setRoundness(roundness);
    } else {
      removeKeyFrame(currentKeyFrame);
    }
    stateHistory.endAction();
    emitter.emit(EmitterEvent.RECT_ROUNDNESS_UPDATED);
  }, [setRoundness, setRectangleRoundnessIsAnimated, currentShapeNode, currentKeyFrame, roundness]);

  return (
    <NumberInput
      name="roundness"
      label={<Roundness className="h-4 w-4" />}
      min={0}
      max={Math.min(...size) / 2}
      value={(currentShapeNode as Shape)?.roundness?.value.value ?? roundness}
      onChange={handleOnChange}
      showKeyframe
      onKeyframeClick={handleKeyframeClick}
      hasKeyframe={Boolean(currentKeyFrame)}
    />
  );
};
