/**
 * Copyright 2022 Design Barn Inc.
 */

import { consoleLog } from './console';
import { getCurrentScene, getKeyFrameById, getSelectedKeyFrame, beginAction, endAction, batchAction } from './creator';
import { customSceneData } from './custom-data';
import { addEventListener } from './event-listeners/event-listener';
import { insertLottieJson, insertSvg } from './import';
import { showUi, exitPlugin } from './plugin';
import { remove } from './remove';
import { getNodesByType } from './scene';
import { getNodeById, getSelectedNode, getSelectedNodes } from './translate-node';

export const shimFunctions = {
  consoleLog,
  customSceneData,
  insertLottieJson,
  insertSvg,
  getNodeById,
  getSelectedNode,
  getSelectedNodes,
  showUi,
  exitPlugin,
  remove,
  getNodesByType,
  addEventListener,
  getCurrentScene,
  getKeyFrameById,
  getSelectedKeyFrame,
  beginAction,
  endAction,
  batchAction,
};
