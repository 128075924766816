/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Track } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { createKeyframeObj } from './keyframe';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit } from '~/lib/toolkit';

const methodNames = {
  isActive: 'isActive',
  isAnimated: 'isAnimated',
  isAtKeyFrame: 'isAtKeyFrame',
  keyFrames: 'keyFrames',
  maxFrame: 'maxFrame',
  minFrame: 'minFrame',
  property: 'property',
  timeline: 'timeline',
  addKeyFrame: 'addKeyFrame',
  clear: 'clear',
  getKeyFrame: 'getKeyFrame',
  getValueAt: 'getValueAt',
  removeKeyFrame: 'removeKeyFrame',
  setKeyframes: 'setKeyframes',
  setTimeline: 'setTimeline',
  shiftLeft: 'shiftLeft',
  shiftRight: 'shiftRight',
  splitKeyFrameAt: 'splitKeyFrameAt',
  stretchByFactor: 'stretchByFactor',
  valueChangesFrom: 'valueChangesFrom',
};

// TODO: remove modifiers after editing 'getObjectMethods' function to
// accept additionalMethods without first passing in modifiers
const timelineModifiers = {
  addKeyFrame: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  clear: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  removeKeyFrame: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  setKeyframes: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  setTimeline: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  shiftLeft: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  shiftRight: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  splitKeyFrameAt: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  stretchByFactor: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
  valueChangesFrom: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
};

function updateFrameNumber(track: Track, vmKeyframe: Keyframe, frame: number, replace?: boolean): void {
  const keyframe = toolkit.getKeyframeById(vmKeyframe['frameId'] as string);

  track.updateFrameNumber(keyframe, frame, replace);
  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
}

function setKeyframes(track: Track, vmKeyframeArray: Keyframe[]): void {
  const keyframeArray = [];

  for (const vmKeyframe of vmKeyframeArray) {
    const keyframe = toolkit.getKeyframeById(vmKeyframe['frameId'] as string);

    keyframeArray.push(keyframe);
  }
  track.setKeyframes(keyframeArray);

  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
}

const additionalMethods = {
  updateFrameNumber,
  setKeyframes,
};

function getTrackMethods(plugin: Plugin, track: Track): ObjectMethods {
  return getObjectMethods(plugin, methodNames, track, timelineModifiers, additionalMethods);
}

export function createTrackObject(plugin: Plugin, track: Track): object {
  const keyframeArray = [];

  for (const keyframe of track.keyFrames) {
    keyframeArray.push(createKeyframeObj(plugin, keyframe));
  }

  const trackObj = {
    keyframes: keyframeArray,
    type: 'Track',
  };

  registerObjectMethods(plugin, track, trackObj, [getTrackMethods]);

  return trackObj;
}
