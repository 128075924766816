/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

export const Bolt: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.6673 6.66667H8.66732V2L3.33398 9.33333H7.33398V14L12.6673 6.66667Z"
        stroke="currentColor"
        fillOpacity="0"
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const BoltActive: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.6673 6.66667H8.66732V2L3.33398 9.33333H7.33398V14L12.6673 6.66667Z"
        fill="#00C1A2"
        stroke="#00C1A2"
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const Shape: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.0473 15.5837C10.107 16.5301 10.4296 17.4412 10.9789 18.2142C11.5281 18.9873 12.2823 19.5917 13.1564 19.9595C14.0305 20.3272 14.9899 20.4437 15.9266 20.2959C16.8634 20.148 17.7402 19.7416 18.4586 19.1225C19.1769 18.5034 19.7082 17.6961 19.9926 16.7914C20.2771 15.8867 20.3034 14.9206 20.0686 14.0018C19.8339 13.083 19.3473 12.248 18.6637 11.5906C17.9802 10.9333 17.1267 10.4798 16.1994 10.2812"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.61339 3.99999L3.78186 13.3952L15.421 13.3952L9.61339 3.99999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
});

export const ShapeActive: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.687 9.17134C10.758 9.02926 10.758 8.81614 10.687 8.67406L6.81524 2.42247C6.7442 2.28039 6.5666 2.17383 6.38899 2.17383C6.21139 2.17383 6.06931 2.28039 5.96275 2.42247L2.09103 8.67406C1.98447 8.81614 1.98447 9.02926 2.09103 9.17134C2.19759 9.31342 2.33967 9.41998 2.51727 9.41998H10.2607C10.4383 9.41998 10.6159 9.31342 10.687 9.17134Z"
        fill="#00C1A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2553 8.70958C11.5394 9.20686 11.1842 9.81071 10.6159 9.81071L6.67316 9.88175C6.38899 9.88175 6.17587 10.1304 6.21139 10.4146C6.24691 11.125 6.49556 11.8354 6.9218 12.4392C7.34804 13.0431 7.91637 13.5048 8.59126 13.789C9.26614 14.0732 9.97655 14.1442 10.7225 14.0376C11.4329 13.9311 12.1078 13.6114 12.6406 13.1496C13.1734 12.6879 13.5996 12.0485 13.8128 11.3736C14.0259 10.6987 14.0614 9.95279 13.8838 9.24238C13.7062 8.53198 13.3155 7.89261 12.8182 7.39532C12.2854 6.89804 11.646 6.54283 10.9356 6.40075C10.6514 6.32971 10.4028 6.50731 10.3318 6.79148C10.2962 6.89804 10.3318 7.0046 10.3673 7.11116"
        fill="#00C1A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.687 9.17134C10.758 9.02926 10.758 8.81614 10.687 8.67406L6.81524 2.42247C6.7442 2.28039 6.5666 2.17383 6.38899 2.17383C6.21139 2.17383 6.06931 2.28039 5.96275 2.42247L2.09103 8.67406C1.98447 8.81614 1.98447 9.02926 2.09103 9.17134C2.19759 9.31342 2.33967 9.41998 2.51727 9.41998H10.2607C10.4383 9.41998 10.6159 9.31342 10.687 9.17134Z"
        stroke="#00C1A2"
        fill="#00C1A2"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2553 8.70958C11.5394 9.20686 11.1842 9.81071 10.6159 9.81071L6.67316 9.88175C6.38899 9.88175 6.17587 10.1304 6.21139 10.4146C6.24691 11.125 6.49556 11.8354 6.9218 12.4392C7.34804 13.0431 7.91637 13.5048 8.59126 13.789C9.26614 14.0732 9.97655 14.1442 10.7225 14.0376C11.4329 13.9311 12.1078 13.6114 12.6406 13.1496C13.1734 12.6879 13.5996 12.0485 13.8128 11.3736C14.0259 10.6987 14.0614 9.95279 13.8838 9.24238C13.7062 8.53198 13.3155 7.89261 12.8182 7.39532C12.2854 6.89804 11.646 6.54283 10.9356 6.40075C10.6514 6.32971 10.4028 6.50731 10.3318 6.79148C10.2962 6.89804 10.3318 7.0046 10.3673 7.11116"
        stroke="#00C1A2"
        fill="#00C1A2"
        strokeWidth="0.2"
      />
    </svg>
  );
});

export const Line: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18 6 6 18" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

export const Star: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.25a.75.75 0 0 0-.68.432L9.02 8.59l-5.133.78a.75.75 0 0 0-.424 1.266l3.736 3.832-.881 5.411a.75.75 0 0 0 1.102.778l4.58-2.53 4.58 2.53a.75.75 0 0 0 1.102-.778l-.881-5.411 3.736-3.832a.75.75 0 0 0-.424-1.265L14.98 8.59l-2.3-4.908A.75.75 0 0 0 12 3.25Zm0 2.517 1.792 3.822a.75.75 0 0 0 .566.423l4.086.622-2.981 3.057a.75.75 0 0 0-.203.644l.694 4.262-3.591-1.983a.75.75 0 0 0-.726 0l-3.59 1.983.693-4.262a.75.75 0 0 0-.203-.644l-2.98-3.057 4.085-.622a.75.75 0 0 0 .566-.423L12 5.767Z"
        fill="#fff"
      />
    </svg>
  );
});

export const ThumbnailShape: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.5" width="16" height="16" rx="1.66667" fill="#22292F" />
      <path
        d="M7.13254 10.0933C7.15906 10.5139 7.30244 10.9188 7.54657 11.2624C7.79069 11.606 8.12586 11.8746 8.51435 12.0381C8.90285 12.2015 9.32925 12.2533 9.74558 12.1876C10.1619 12.1219 10.5516 11.9413 10.8709 11.6661C11.1901 11.3909 11.4263 11.0321 11.5527 10.6301C11.6791 10.228 11.6908 9.7986 11.5865 9.39024C11.4821 8.98188 11.2659 8.61075 10.9621 8.31861C10.6583 8.02646 10.279 7.82491 9.86682 7.73663"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.94086 4.94487L4.34907 9.12052L9.52202 9.12052L6.94086 4.94487Z"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const ThumbnailPath: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.5" width="16" height="16" rx="1.66667" fill="#22292F" />
      <rect
        width="2"
        height="2"
        transform="matrix(-1 0 0 1 12.2246 9.83594)"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.3359 9.83701C11.2565 8.44073 10.3527 7.27011 9.11372 6.82422"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
      <rect
        x="3.7793"
        y="9.83594"
        width="2"
        height="2"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 9.83701C4.74736 8.44073 5.65117 7.27011 6.89019 6.82422"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
      <rect
        x="7.00586"
        y="5.39258"
        width="2"
        height="2"
        stroke="#F6F8F9"
        strokeWidth="0.833333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M11.4503 6.50391H9.00586" stroke="#F6F8F9" strokeWidth="0.833333" />
      <circle cx="11.5614" cy="6.48134" r="0.555555" fill="#F6F8F9" />
      <path d="M4.55751 6.50391H7.00195" stroke="#F6F8F9" strokeWidth="0.833333" />
      <ellipse rx="0.555556" ry="0.555555" transform="matrix(-1 0 0 1 4.4464 6.48134)" fill="#F6F8F9" />
    </svg>
  );
});

export const Polygon: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          d="m12.001 4 9 14.5H3.038L12.001 4Z"
          stroke="#fff"
          strokeWidth={1.514}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="matrix(-1 0 0 1 24 0)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const Circle: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const Square: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 4h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const PolystarPoints: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.227 4.47 8.242 8.5l-3.984 4.03" stroke="#606F7B" strokeLinecap="round" />
      <path
        d="M12.203 12.55 8.241 8.5 4.279 4.449M13.91 8.5H2.577M8.242 2.834v11.333"
        stroke="#606F7B"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const PolystarInnerRadius: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx={8.242}
        cy={8.834}
        r={2}
        stroke="#B8C2CC"
        strokeWidth={0.4}
        strokeLinecap="round"
        strokeDasharray="0.8 0.8"
      />
      <path
        d="m8.242 11.967-3.706 1.946.706-4.126-3-2.927 4.147-.6L8.242 2.5l1.853 3.76 4.147.6-3 2.927.707 4.126-3.707-1.946Z"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const PolygonRadius: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44499 15.0946C12.1269 15.0946 15.1117 12.1098 15.1117 8.4279C15.1117 4.746 12.1269 1.76123 8.44499 1.76123C4.76309 1.76123 1.77832 4.746 1.77832 8.4279C1.77832 12.1098 4.76309 15.0946 8.44499 15.0946ZM14.1513 6.57363L8.44501 2.42773L2.73867 6.57363L4.9183 13.2818H11.9717L14.1513 6.57363Z"
        fill="#3D4852"
      />
      <circle
        cx="8.44499"
        cy="8.4279"
        r="6.66667"
        stroke="#606F7B"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeDasharray="0.6 1.2"
      />
      <path
        d="M8.44507 2.42773L14.1514 6.57363L11.9718 13.2818H4.91836L2.73873 6.57363L8.44507 2.42773Z"
        stroke="#606F7B"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const PolystarInnerRoundness: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.5 4C11.5 4.91925 11.3189 5.82951 10.9672 6.67878C10.6154 7.52806 10.0998 8.29974 9.44975 8.94975C8.79974 9.59976 8.02806 10.1154 7.17878 10.4672C6.3295 10.8189 5.41925 11 4.5 11L4.5 7C4.5 5.34315 5.84315 4 7.5 4H11.5Z"
        fill="#3D4852"
      />
      <path
        d="M11.1865 2.50015C11.39 3.13022 11.5 3.80234 11.5 4.50015C11.5 8.09 8.58985 11.0002 5 11.0002C4.30219 11.0002 3.63006 10.8902 3 10.6867"
        stroke="#606F7B"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeDasharray="0.6 1.2"
      />
      <path
        d="M13.8334 2.66666H7.16675C4.95761 2.66666 3.16675 4.45752 3.16675 6.66666V13.3333"
        stroke="#606F7B"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const PolygonRoundness: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.31 15.0002C13.6241 15.0002 15.5 13.0997 15.5 10.7552C15.5 9.1265 14.5946 7.71201 13.2659 7.00018C13.4539 9.81093 12.5235 11.0094 12.1498 11.3724C12.0553 11.4642 11.9538 11.5472 11.8504 11.6287C11.4031 11.9815 9.95296 12.9133 7.5 12.524C8.16202 13.9853 9.61905 15.0002 11.31 15.0002Z"
        fill="#3D4852"
      />
      <path
        d="M13.2659 7.00018C14.5946 7.71201 15.5 9.1265 15.5 10.7552C15.5 13.0997 13.6241 15.0002 11.31 15.0002C9.61905 15.0002 8.16202 13.9853 7.5 12.524"
        stroke="#606F7B"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.6 1.2"
      />
      <path
        d="M1.50008 11.6667L8.16675 11.6667C10.3759 11.6667 12.1667 9.87583 12.1667 7.66669L12.1667 1.00002"
        stroke="#606F7B"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const StarOuterRadius: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49992 14.6668C12.1818 14.6668 15.1666 11.6821 15.1666 8.00016C15.1666 4.31826 12.1818 1.3335 8.49992 1.3335C4.81802 1.3335 1.83325 4.31826 1.83325 8.00016C1.83325 11.6821 4.81802 14.6668 8.49992 14.6668ZM4.79327 13.4133L8.49994 11.4667L12.2066 13.4133L11.4999 9.28667L14.4999 6.36L10.3533 5.76L8.49994 2L6.64661 5.76L2.49994 6.36L5.49994 9.28667L4.79327 13.4133Z"
        fill="#3D4852"
      />
      <circle
        cx="8.49992"
        cy="8.00016"
        r="6.66667"
        stroke="#606F7B"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeDasharray="0.6 1.2"
      />
      <path
        d="M8.5 11.4667L4.79333 13.4133L5.5 9.28667L2.5 6.36L6.64667 5.76L8.5 2L10.3533 5.76L14.5 6.36L11.5 9.28667L12.2067 13.4133L8.5 11.4667Z"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const StrokeWidth: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line x1="1.1665" y1="0.833984" x2="10.8332" y2="0.833985" stroke="#606F7B" strokeLinecap="round" />
      <line
        x1="1.33317"
        y1="4.0013"
        x2="10.6665"
        y2="4.0013"
        stroke="#606F7B"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <line
        x1="1.49984"
        y1="7.16667"
        x2="10.4998"
        y2="7.16667"
        stroke="#606F7B"
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const GradientStartPoint: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.66974 8.56187L5.82708 7.19993C6.19504 6.76692 5.8873 6.10156 5.31907 6.10156L3.00438 6.10156C2.43614 6.10156 2.1284 6.76692 2.49636 7.19993L3.65371 8.56187C3.91993 8.87516 4.40351 8.87515 4.66974 8.56187Z"
        fill="#606F7B"
      />
      <line x1="2.5" y1="10.3359" x2="6.83333" y2="10.3359" stroke="#606F7B" strokeLinecap="round" />
      <line
        x1="3.8335"
        y1="10.3359"
        x2="13.5002"
        y2="10.3359"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeDasharray="1.5 1.5"
      />
    </svg>
  );
});

export const GradientEndPoint: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.2888 8.56187L10.1314 7.19993C9.76345 6.76692 10.0712 6.10156 10.6394 6.10156L12.9541 6.10156C13.5224 6.10156 13.8301 6.76692 13.4621 7.19993L12.3048 8.56187C12.0386 8.87516 11.555 8.87515 11.2888 8.56187Z"
        fill="#606F7B"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="4.83333"
        y2="-0.5"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 13.9585 10.8359)"
        stroke="#606F7B"
        strokeLinecap="round"
      />
      <line
        x1="2.4585"
        y1="10.3359"
        x2="12.1252"
        y2="10.3359"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeDasharray="1.5 1.5"
      />
    </svg>
  );
});
