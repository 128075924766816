/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-plusplus */
/* eslint-disable line-comment-position */
/* eslint-disable id-length */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */

import type { BufferAttribute, Object3D, Plane, Sphere } from 'three';
import { Matrix4, Quaternion, Euler, Vector3 } from 'three';

import type { CMesh, CObject3D } from '~/features/canvas';
import { aggregatedZRotation } from '~/features/canvas';

const _points = [
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
  /* @__PURE__*/ new Vector3(),
];

const _vector = /* @__PURE__*/ new Vector3();

// TODO: If the distance between two vertices is greater than delta, skip the current one for evaluating the bounding box
// This should be set depending on the size of the object.
const delta = 2;

const zAxis = new Vector3(0, 0, -1);

class Box3 {
  public center: Vector3;

  public emptyObject = true;

  public isBox3: boolean;

  public min: Vector3;

  public max: Vector3;

  public minXmaxY: Vector3;

  public maxXminY: Vector3;

  public zRotation: number;

  public zEuler: Euler;

  public constructor(
    min = new Vector3(Number(Infinity), Number(Infinity), Number(Infinity)),
    max = new Vector3(-Infinity, -Infinity, -Infinity),
  ) {
    this.isBox3 = true;

    this.min = min;
    this.max = max;
    this.minXmaxY = new Vector3(min.x, max.y, min.z);
    this.maxXminY = new Vector3(max.x, min.y, max.z);
    this.center = new Vector3();
    this.zRotation = 0;
    this.zEuler = new Euler();
  }

  public set(min: Vector3, max: Vector3): Box3 {
    this.min.copy(min);
    this.max.copy(max);

    return this;
  }

  public setFromArray(array: number[]): Box3 {
    let minX = Number(Infinity);
    let minY = Number(Infinity);
    let minZ = Number(Infinity);

    let maxX = -Infinity;
    let maxY = -Infinity;
    let maxZ = -Infinity;

    for (let i = 0, l = array.length; i < l; i += 3) {
      const x = array[i];
      const y = array[i + 1];
      const z = array[i + 2];

      if (x && x < minX) minX = x;
      if (y && y < minY) minY = y;
      if (z && z < minZ) minZ = z;

      if (x && x > maxX) maxX = x;
      if (y && y > maxY) maxY = y;
      if (z && z > maxZ) maxZ = z;
    }

    this.min.set(minX, minY, minZ);
    this.max.set(maxX, maxY, maxZ);

    return this;
  }

  public setFromBufferAttribute(attribute: BufferAttribute): Box3 {
    let minX = Number(Infinity);
    let minY = Number(Infinity);
    let minZ = Number(Infinity);

    let maxX = -Infinity;
    let maxY = -Infinity;
    let maxZ = -Infinity;

    for (let i = 0, l = attribute.count; i < l; i++) {
      const x = attribute.getX(i);
      const y = attribute.getY(i);
      const z = attribute.getZ(i);

      if (x < minX) minX = x;
      if (y < minY) minY = y;
      if (z < minZ) minZ = z;

      if (x > maxX) maxX = x;
      if (y > maxY) maxY = y;
      if (z > maxZ) maxZ = z;
    }

    this.min.set(minX, minY, minZ);
    this.max.set(maxX, maxY, maxZ);

    return this;
  }

  public setFromPoints(points: Vector3[]): Box3 {
    this.makeEmpty();

    for (let i = 0, il = points.length; i < il; i++) {
      this.expandByPoint(points[i] ?? new Vector3(0, 0, 0));
    }

    return this;
  }

  public setFromCenterAndSize(center: Vector3, size: Vector3): Box3 {
    const halfSize = _vector.copy(size).multiplyScalar(0.5);

    this.min.copy(center).sub(halfSize);
    this.max.copy(center).add(halfSize);

    return this;
  }

  public setFromObject(object: CObject3D | CMesh, precise = false): Box3 {
    this.makeEmpty();

    this.emptyObject = true;
    const aggZ = aggregatedZRotation(object);

    this.zRotation = aggZ;
    const rotationM = new Matrix4().makeRotationZ(aggZ);

    this.expandByObject(object, precise);

    this.min.applyMatrix4(rotationM);
    this.max.applyMatrix4(rotationM);
    this.minXmaxY.applyMatrix4(rotationM);
    this.maxXminY.applyMatrix4(rotationM);
    this.center.applyMatrix4(rotationM);

    return this;
  }

  public setFromObjects(objects: Array<CObject3D | CMesh>, precise = false, initializeRotation = false): Box3 {
    this.makeEmpty();

    this.emptyObject = true;

    this.zRotation = 0;
    if (initializeRotation || objects.length === 1) {
      const aggZAngles = objects.map((obj) => aggregatedZRotation(obj));

      this.zRotation = Math.min(...aggZAngles);
      objects.forEach((obj) => {
        obj.groupRotation = this.zRotation === aggregatedZRotation(obj);
      });
    } else {
      const groupRotationObject = objects.find((obj) => obj.groupRotation);

      if (groupRotationObject) this.zRotation = Math.min(aggregatedZRotation(groupRotationObject));
    }

    const rotationM = new Matrix4().makeRotationZ(this.zRotation);

    objects.forEach((object) => {
      this.expandByObject(object, precise);
    });

    this.min.applyMatrix4(rotationM);
    this.max.applyMatrix4(rotationM);
    this.minXmaxY.applyMatrix4(rotationM);
    this.maxXminY.applyMatrix4(rotationM);
    this.center.applyMatrix4(rotationM);

    return this;
  }

  public copy(box: Box3): Box3 {
    this.min.copy(box.min);
    this.max.copy(box.max);

    return this;
  }

  public makeEmpty(): Box3 {
    this.min.x = this.min.y = this.min.z = Number(Infinity);
    this.max.x = this.max.y = this.max.z = -Infinity;

    return this;
  }

  public isEmpty(): boolean {
    // this is a more robust check for empty than ( volume <= 0 ) because volume can get positive with two negative axes

    return this.max.x < this.min.x || this.max.y < this.min.y || this.max.z < this.min.z;
  }

  public getCenter(target: Vector3): Vector3 {
    return this.isEmpty() ? target.set(0, 0, 0) : target.addVectors(this.min, this.max).multiplyScalar(0.5);
  }

  public getSize(target: Vector3): Vector3 {
    return this.isEmpty() ? target.set(0, 0, 0) : target.subVectors(this.max, this.min);
  }

  public expandByPoint(point: Vector3): Box3 {
    // const transformedPoint = new Vector3().copy(point).applyEuler(new Euler(0, 0, -this.zRotation));
    const transformedPoint = new Vector3()
      .copy(point)
      .applyQuaternion(new Quaternion().setFromAxisAngle(zAxis, this.zRotation));

    this.min.min(transformedPoint);
    this.max.max(transformedPoint);

    this.minXmaxY.set(this.min.x, this.max.y, this.min.z);
    this.maxXminY.set(this.max.x, this.min.y, this.max.z);
    this.center.set((this.min.x + this.max.x) / 2, (this.min.y + this.max.y) / 2, (this.min.z + this.max.z) / 2);

    return this;
  }

  public expandByVector(vector: Vector3): Box3 {
    this.min.sub(vector);
    this.max.add(vector);

    return this;
  }

  public expandByScalar(scalar: number): Box3 {
    this.min.addScalar(-scalar);
    this.max.addScalar(scalar);

    return this;
  }

  public expandByObject(object: CObject3D | Object3D | CMesh, precise = false): Box3 {
    // Computes the world-axis-aligned bounding box of an object (including its children),
    // accounting for both the object's, and children's, world transforms

    object.updateWorldMatrix(true, false);

    // @ts-ignore
    const geometry = object.geometry;

    if (geometry !== undefined) {
      if (precise && geometry.attributes !== undefined && geometry.attributes.position !== undefined) {
        const position = geometry.attributes.position;

        const prevVertex = new Vector3();

        for (let i = 0, l = position.count; i < l; i += 1) {
          _vector.fromBufferAttribute(position, i).applyMatrix4(object.matrixWorld);

          const distanceToPrevVertex = _vector.distanceTo(prevVertex);

          // if the next vertex is close to the previous vertex, skip this
          // this is especially efficient when the vertices are too compact
          if (distanceToPrevVertex < delta) continue;

          this.expandByPoint(_vector);
          prevVertex.copy(_vector);
        }
        this.emptyObject = false;
      } else {
        console.error('attributes not found');
      }
    }

    const children = object.children;

    for (let i = 0, l = children.length; i < l; i++) {
      this.expandByObject(children[i] as CObject3D, precise);
    }

    return this;
  }

  public containsPoint(point: Vector3): boolean {
    return !(
      point.x < this.min.x ||
      point.x > this.max.x ||
      point.y < this.min.y ||
      point.y > this.max.y ||
      point.z < this.min.z ||
      point.z > this.max.z
    );
  }

  public containsBox(box: Box3): boolean {
    return (
      this.min.x <= box.min.x &&
      box.max.x <= this.max.x &&
      this.min.y <= box.min.y &&
      box.max.y <= this.max.y &&
      this.min.z <= box.min.z &&
      box.max.z <= this.max.z
    );
  }

  public getParameter(point: Vector3, target: Vector3): Vector3 {
    // This can potentially have a divide by zero if the box
    // has a size dimension of 0.

    return target.set(
      (point.x - this.min.x) / (this.max.x - this.min.x),
      (point.y - this.min.y) / (this.max.y - this.min.y),
      (point.z - this.min.z) / (this.max.z - this.min.z),
    );
  }

  public intersectsBox(box: Box3): boolean {
    // using 6 splitting planes to rule out intersections.
    return !(
      box.max.x < this.min.x ||
      box.min.x > this.max.x ||
      box.max.y < this.min.y ||
      box.min.y > this.max.y ||
      box.max.z < this.min.z ||
      box.min.z > this.max.z
    );
  }

  public intersectsSphere(sphere: Sphere): boolean {
    // Find the point on the AABB closest to the sphere center.
    this.clampPoint(sphere.center, _vector);

    // If that point is inside the sphere, the AABB and sphere intersect.
    return _vector.distanceToSquared(sphere.center) <= sphere.radius * sphere.radius;
  }

  public intersectsPlane(plane: Plane): boolean {
    // We compute the minimum and maximum dot product values. If those values
    // are on the same side (back or front) of the plane, then there is no intersection.

    let min;
    let max;

    if (plane.normal.x > 0) {
      min = plane.normal.x * this.min.x;
      max = plane.normal.x * this.max.x;
    } else {
      min = plane.normal.x * this.max.x;
      max = plane.normal.x * this.min.x;
    }

    if (plane.normal.y > 0) {
      min += plane.normal.y * this.min.y;
      max += plane.normal.y * this.max.y;
    } else {
      min += plane.normal.y * this.max.y;
      max += plane.normal.y * this.min.y;
    }

    if (plane.normal.z > 0) {
      min += plane.normal.z * this.min.z;
      max += plane.normal.z * this.max.z;
    } else {
      min += plane.normal.z * this.max.z;
      max += plane.normal.z * this.min.z;
    }

    return min <= -plane.constant && max >= -plane.constant;
  }

  public clampPoint(point: Vector3, target: Vector3): Vector3 {
    return target.copy(point).clamp(this.min, this.max);
  }

  public distanceToPoint(point: Vector3): number {
    const clampedPoint = _vector.copy(point).clamp(this.min, this.max);

    return clampedPoint.sub(point).length();
  }

  // public getBoundingSphere( target ):  {

  // 	this.getCenter( target.center );

  // 	target.radius = this.getSize( _vector ).length() * 0.5;

  // 	return target;

  // }

  public intersect(box: Box3): Box3 {
    this.min.max(box.min);
    this.max.min(box.max);

    // ensure that if there is no overlap, the result is fully empty, not slightly empty with non-inf/+inf values that will cause subsequence intersects to erroneously return valid values.
    if (this.isEmpty()) this.makeEmpty();

    return this;
  }

  public union(box: Box3): Box3 {
    this.min.min(box.min);
    this.max.max(box.max);

    return this;
  }

  public applyMatrix4(matrix: Matrix4): Box3 {
    // transform of empty box is an empty box.
    if (this.isEmpty()) return this;

    // NOTE: I am using a binary pattern to specify all 2^3 combinations below
    _points[0]?.set(this.min.x, this.min.y, this.min.z).applyMatrix4(matrix); // 000
    _points[1]?.set(this.min.x, this.min.y, this.max.z).applyMatrix4(matrix); // 001
    _points[2]?.set(this.minXmaxY.x, this.minXmaxY.y, this.min.z).applyMatrix4(matrix); // 010
    _points[3]?.set(this.minXmaxY.x, this.minXmaxY.y, this.max.z).applyMatrix4(matrix); // 011
    _points[4]?.set(this.maxXminY.x, this.maxXminY.y, this.min.z).applyMatrix4(matrix); // 100
    _points[5]?.set(this.maxXminY.x, this.maxXminY.y, this.max.z).applyMatrix4(matrix); // 101
    _points[6]?.set(this.max.x, this.max.y, this.min.z).applyMatrix4(matrix); // 110
    _points[7]?.set(this.max.x, this.max.y, this.max.z).applyMatrix4(matrix); // 111

    this.setFromPoints(_points);

    return this;
  }

  public translate(offset: Vector3): Box3 {
    this.min.add(offset);
    this.max.add(offset);

    return this;
  }

  public equals(box: Box3): boolean {
    return box.min.equals(this.min) && box.max.equals(this.max);
  }
}

export { Box3 };
