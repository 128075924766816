/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ThumbnailPath } from '~/assets/icons';
import { useCreatorStore } from '~/store';

export const PathProperty: React.FC = () => {
  const [currentShapeNode, addToSelectedNodes] = useCreatorStore(
    (state) => [state.toolkit.currentShapeNode, state.ui.addToSelectedNodes],
    shallow,
  );

  const onEditClick = useCallback(() => {
    addToSelectedNodes([currentShapeNode?.nodeId as string], true);
  }, [currentShapeNode, addToSelectedNodes]);

  return (
    <>
      <div
        onClick={onEditClick}
        className="flex cursor-pointer justify-center rounded border border-gray-600 py-1 hover:border-gray-400"
      >
        <ThumbnailPath className="h-5 w-5 " />

        <div className="py-[2px] text-xs">Edit Path</div>
      </div>
    </>
  );
};
