/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import type { OrthographicCamera} from "three";
import { BufferGeometry, DoubleSide, Float32BufferAttribute, LineBasicMaterial, MeshBasicMaterial, PlaneGeometry, TorusGeometry, Vector3 } from "three";

import { TransformType } from "./constant";

import { CMesh, CObject3D } from "~/features/canvas";

export class TransformControlsGizmo extends CObject3D {
  public _gizmo: any;

  public _picker: any;

  public override type = 'TransformControlsGizmo'

  public constructor() {
    super();

    // shared materials

    const gizmoMaterial = new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      fog: false,
      toneMapped: false,
      transparent: true,
      side: DoubleSide,
    });

    const gizmoLineMaterial = new LineBasicMaterial({
      depthTest: false,
      depthWrite: false,
      fog: false,
      toneMapped: false,
      transparent: true,
    });

    // Make unique material for each axis/color

    const matInvisible = gizmoMaterial.clone();

    matInvisible.opacity = 0.15;

    const matHelper = gizmoLineMaterial.clone();

    matHelper.opacity = 0.5;

    const matBlue = gizmoMaterial.clone();

    matBlue.color.setHex(0x00b6fe);

    const matWhite = gizmoMaterial.clone();

    matWhite.color.setHex(0xffffff);

    // reusable geometry

    const lineGeometry = new BufferGeometry();

    lineGeometry.setAttribute('position', new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3));

    // Gizmo definitions - custom hierarchy definitions for setupGizmo() function

    const pickerTranslate = {
      XY: [[new CMesh(new PlaneGeometry(1, 1), matBlue), [0, 0, 0], null, null, 'translateXY', TransformType.Translation]],
    };

    const pickerRotate = {
      topRight: [
        [
          new CMesh(new TorusGeometry(0.1, 0.05, 2, 24), matBlue),
          [0, 0, 0],
          [0, 0, 0],
          null,
          'pickerRotateTR',
          TransformType.Rotation,
        ],
      ],
      topLeft: [
        [
          new CMesh(new TorusGeometry(0.1, 0.05, 2, 24), matBlue),
          [0, 0, 0],
          [0, 0, 0],
          null,
          'pickerRotateTL',
          TransformType.Rotation,
        ],
      ],
      bottomLeft: [
        [
          new CMesh(new TorusGeometry(0.1, 0.05, 2, 24), matBlue),
          [0, 0, 0],
          [0, 0, 0],
          null,
          'pickerRotateBL',
          TransformType.Rotation,
        ],
      ],
      bottomRight: [
        [
          new CMesh(new TorusGeometry(0.1, 0.05, 2, 24), matBlue),
          [0, 0, 0],
          [0, 0, 0],
          null,
          'pickerRotateBR',
          TransformType.Rotation,
        ],
      ],
    };

    const scaleGizmo = new CMesh(new PlaneGeometry(0.05, 0.05), matBlue);
    const innerScaleGizmo = new CMesh(new PlaneGeometry(0.03, 0.03), matWhite);

    innerScaleGizmo.renderOrder = Infinity

    const scaleGizmoObj = new CObject3D();

    scaleGizmoObj.add(scaleGizmo);
    scaleGizmoObj.add(innerScaleGizmo);

    const gizmoScale = {
      topRightXY: [[scaleGizmoObj, [0, 0, 0], null, null, 'ScaleTR', TransformType.Scale]],
      topLeftXY: [[scaleGizmoObj, [0, 0, 0], null, null, 'ScaleTL', TransformType.Scale]],
      bottomLeftXY: [
        [scaleGizmoObj, [0, 0, 0], null, null, 'ScaleBL', TransformType.Scale],
      ],
      bottomRightXY: [
        [scaleGizmoObj, [0, 0, 0], null, null, 'ScaleBR', TransformType.Scale],
      ],
    };

    const pickerScale = {
      X: [
        [new CMesh(new PlaneGeometry(0.1, 1), matBlue), [0, 0, 0], [0, 0, 0], null, 'ScaleX1', TransformType.Scale],
        [new CMesh(new PlaneGeometry(0.1, 1), matBlue), [0, 0, 0], [0, 0, 0], null, 'ScaleX2', TransformType.Scale],
      ],
      Y: [
        [new CMesh(new PlaneGeometry(1, 0.1), matBlue), [0, 0, 0], [0, 0, 0], null, 'ScaleY1', TransformType.Scale],
        [new CMesh(new PlaneGeometry(1, 0.1), matInvisible), [0, 0, 0], [0, 0, 0], null, 'ScaleY2', TransformType.Scale],
      ],
      topRightXY: [[new CMesh(new PlaneGeometry(0.1, 0.1), matBlue), [0, 0, 0], null, null, 'ScaleTR', TransformType.Scale]],
      topLeftXY: [[new CMesh(new PlaneGeometry(0.1, 0.1), matBlue), [0, 0, 0], null, null, 'ScaleTL', TransformType.Scale]],
      bottomLeftXY: [[new CMesh(new PlaneGeometry(0.1, 0.1), matBlue), [0, 0, 0], null, null, 'ScaleBL', TransformType.Scale]],
      bottomRightXY: [[new CMesh(new PlaneGeometry(0.1, 0.1), matBlue), [0, 0, 0], null, null, 'ScaleBR', TransformType.Scale]],
    };

    // Creates an CObject3D with gizmos described in custom hierarchy definition.

    const setupGizmo = (gizmoMap: any): CObject3D => {
      const gizmo = new CObject3D();

      Object.entries(gizmoMap).forEach(([name, gizmoShapes]: [string, any]) => {
        // eslint-disable-next-line no-plusplus
        for (let i = gizmoShapes.length; i--; ) {
          const object = gizmoShapes[i][0].clone();
          const position = gizmoShapes[i][1];
          const rotation = gizmoShapes[i][2];
          const scale = gizmoShapes[i][3];
          const tag = gizmoShapes[i][4];
          const mode = gizmoShapes[i][5];

          // name and tag properties are essential for picking and updating logic.
          object.name = name;
          object.userData.tag = tag;
          object.userData.mode = mode;

          if (position) {
            object.position.set(position[0], position[1], position[2]);
          }

          if (rotation) {
            object.rotation.set(rotation[0], rotation[1], rotation[2]);
          }

          if (scale) {
            object.scale.set(scale[0], scale[1], scale[2]);
          }

          object.updateMatrix();

          object.renderOrder = Infinity;

          gizmo.add(object);
        }
      });

      return gizmo;
    };

    // Gizmo creation

    this._gizmo = {};
    this._picker = {};

    this.add((this._gizmo.scale = setupGizmo(gizmoScale)));
    this.add((this._picker.translate = setupGizmo(pickerTranslate)));
    this.add((this._picker.rotate = setupGizmo(pickerRotate)));
    this.add((this._picker.scale = setupGizmo(pickerScale)));

    // Pickers should be hidden always

    this._picker.translate.visible = false;
    this._picker.rotate.visible = false;
    this._picker.scale.visible = false;
  }

  // updateMatrixWorld will update transformations and appearance of individual handles

  public update(camera: OrthographicCamera, size?: number): void {
    // scale always oriented to local rotation
    // Show only gizmos for current transform mode

    this._gizmo.scale.visible = true;

    let handles: any[] = [];

    handles = handles.concat(this._picker.translate.children);
    handles = handles.concat(this._picker.rotate.children);
    handles = handles.concat(this._picker.scale.children);
    handles = handles.concat(this._gizmo.scale.children);

    handles.map((handle) => {
      // hide aligned to camera

      handle.visible = true;

      const factor = (camera.top - camera.bottom) / camera.zoom;
      const originalScale = new Vector3().copy(handle.scale);

        handle.scale
          .set(1, 1, 1)
          .multiplyScalar((factor * (size ?? 1)) / 4)

      if (handle.userData.tag === 'ScaleX1' || handle.userData.tag === 'ScaleX2') handle.scale.y = originalScale.y;
      else if (
        handle.userData.tag === 'ScaleY1' || handle.userData.tag === 'ScaleY2'
      )
        handle.scale.x = originalScale.x;
        else if (handle.userData.tag === 'translateXY') {
          handle.scale.x = originalScale.x;
          handle.scale.y = originalScale.y;
        }

      return true;
    });

  }

  public override updateMatrixWorld(force?: boolean | undefined): void {
    super.updateMatrixWorld(force);
  }
}