/**
 * Copyright 2022 Design Barn Inc.
 */

export enum ImportType {
  New = 'new',
  Scene = 'scene',
  Upload = 'upload',
  Url = 'url',
}

export enum ExportType {
  DotCreator = 'DotCreator',
  DotLottie = '.lottie',
  Gif = '.gif',
  JSON = '.json',
  MP4 = '.mp4',
  SaveToCreator = 'SaveToCreator',
  SaveToWorkspace = 'SaveToWorkspace',
  TelegramSticker = 'Telegram sticker (TGS)',
  ZipArchive = '.zip',
}

export enum SidebarBottomType {
  Plugin = 'Plugin',
  QuickSearch = 'Quick Search',
}

export enum ToolType {
  ConvertVertex = 'ConvertVertex',
  Hand = 'Hand',
  Move = 'Move',
  Pen = 'Pen',
  Text = 'Text',
}

export enum LibraryType {
  GettingStarted = 'GettingStarted',
  Iconscout = 'Iconscout',
  Lottie = 'Lottie',
  MyAsset = 'MyAsset',
  Plugin = 'Plugin',
  Shape = 'Shape'
}

export enum SceneNameType {
  MainScene = 'Main Scene',
}

export const MenuType = {
  ...ImportType,
  ...ExportType,
  ...{
    GoToDashboard: 'GoToDashboard',
  },
};
